import * as React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import Select from "components/select";

type DefaultValue = string;

export const useInputPopper = (defaultValue?: DefaultValue) => {
  const [status, setStatus] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  const [
    referenceElement,
    setReferenceElement,
  ] = React.useState<HTMLInputElement | null>(null);

  const [
    popperElement,
    setPopperElement,
  ] = React.useState<HTMLDivElement | null>(null);

  const disable = () => {
    // setStatus(false);
    referenceElement && referenceElement.blur();
  };

  return {
    disable,
    setStatus,
    status,
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
    value,
    setValue,
  };
};

type Props = {
  reference: React.ReactElement;
  clickable?: boolean;
  children?: React.ReactNode;
  close?: (e: any) => void;
  style?: object;
  inputPopper: any;
  onClick: (row: any) => void;
  rowFormat: (row: any) => React.ReactNode;
  data: any;
  placement?:
    | "bottom-start"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end"
    | undefined;
};

export default function InputPopper({
  reference,
  children,
  style,
  placement,
  data,
  onClick,
  rowFormat,
  inputPopper,
}: Props) {
  const {
    status,
    setStatus,
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
  } = inputPopper;

  const modifiers: any = React.useMemo(
    () => [
      {
        name: "sameWidth",
        enabled: true,
        fn: ({ state }: any) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: "beforeWrite",
        requires: ["computeStyles"],
      },
    ],
    []
  );

  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers,
      placement: placement || "bottom-start",
    }
  );

  React.useEffect(() => {
    if (status) {
      const handleMouseDown = (e: any) => {
        if (popperElement && popperElement.contains(e.target)) {
          e.preventDefault();
        }
      };
      window.addEventListener("mousedown", handleMouseDown);
      return () => {
        window.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, [status]);

  const trigger = React.cloneElement(reference, {
    ref: setReferenceElement,
    value: inputPopper.value,
    onChange: (e: any) => inputPopper.setValue(e.target.value),
    onFocus: (e: any) => {
      e.target.select();
      inputPopper.setStatus(true);
    },
    onBlur: () => inputPopper.setStatus(false),
  });

  return (
    <div {...{ style }}>
      {trigger}
      {ReactDOM.createPortal(
        <Con
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <CSSTransition
            in={status}
            timeout={100}
            classNames="alert"
            unmountOnExit
          >
            <Popper>
              <Select {...{ ...inputPopper, data, rowFormat, onClick }} />
            </Popper>
          </CSSTransition>
        </Con>,
        document.querySelector("#popper")!
      )}
    </div>
  );
}

const Con = styled.div`
  z-index: 999;
`;

const Popper = styled.div`
  background: white;
  overflow: hidden;
  border: 1px solid #ebedef;
  border-radius: 5px;
  box-shadow: 0 0 10px -5px grey;
  overflow: auto;
  max-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.alert-enter {
    opacity: 0;
  }
  &.alert-enter-active {
    opacity: 1;
    transition: all 0.1s ease-out;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transition: all 0.1s ease-out;
  }
`;
