import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { Size } from "types/theme";

type Props = {
  color?: string;
  size?: Size;
  children: React.ReactNode;
};
export default function StatusTag({ size, color, children }: Props) {
  return (
    <SCon color={color} size={size || "regular"}>
      {children}
    </SCon>
  );
}

const SCon = styled.span<{ color?: string; size: Size }>`
  font-size: ${(props) => theme.fontSize[props.size]};
  padding: 0.25em 0.5em;
  border-radius: 4px;
  font-weight: bold;
  color: ${(props) => (props.color ? `rgb(${props.color})` : "black")};
  background: ${(props) =>
    props.color ? `rgba(${props.color}, 0.15)` : theme.bg.lightblue};
`;
