const format = (str: string | "" | number | undefined) => {
  const input = str?.toString() || "";

  let dollarAmount = input.replace(/[^\d.]/g, "");

  if (!input.includes(".") && input.includes("$")) {
    return dollarAmount
      ? new Intl.NumberFormat("ja-JP", {
          style: "currency",
          currency: "USD",
        }).format(parseFloat(dollarAmount) / 100)
      : "";
  }

  let [dollar, cent] = dollarAmount.split(".");

  let centArray: string[] | null | "" = cent && cent.match(/[\d]{2}/g);

  let parsedCent = centArray
    ? parseInt(centArray[0], 10)
    : cent
    ? parseInt(cent, 10)
    : null;

  let toBeFormatted = parsedCent
    ? parseFloat(parseInt(dollar, 10) + "." + parsedCent)
    : parseInt(dollar, 10);

  return dollar
    ? new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "USD",
      }).format(toBeFormatted)
    : "";
};

const parse = (str: string | number | null) => {
  if (!str) return 0;
  if (typeof str === "number") return str;
  return parseInt(str.replace(/[^\d]/g, ""));
};

export default {
  format,
  parse,
};
