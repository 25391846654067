import axios from "axios";

const downloadInventoryUsage = async (startDate: string, endDate: string) => {
  return axios({
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/reports/inventoryUsage`,
    params: {
      startDate: startDate,
      endDate: endDate,
    },
    method: "GET",
    responseType: "blob",
  });
};

export { downloadInventoryUsage };
