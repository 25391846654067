import React from "react";
import { Button } from "components/buttonV2";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const FindDuplicatesButton = () => {
  const { t } = useTranslation();
  const tBase = "views.customers.find_duplicates_button.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <>
      <Link 
        to="/duplicates/customers"
        className="no-underline"
      >
        <Button>
          {tr("View Duplicates")}
        </Button>
      </Link>
    </>
  );
};

export default FindDuplicatesButton;
