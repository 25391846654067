import { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from "components/button";
import DollarInputV2 from "components/input/dollarInputV2";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import axios from "axios";
import StripeCheckout from "./StripeCheckout";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  type: "request" | "project" | "invoice" | "customer";
  is_deposit: boolean;
  stripeAccountId: null | string;
  amountDue?: number;
};

const StripeContainer = ({ id, type, is_deposit, stripeAccountId, amountDue }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.payment.components.StripeContainer";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [total, setTotal] = useState<number | null>(null);
  const [secret, setSecret] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isDirect, setIsDirect] = useState(true);

  const createPaymentIntent  = useMutation(
    async () =>
      await axios
        .post(
          `
            ${
              process.env.REACT_APP_SERVER_URL
            }/api/v1/company/payments/stripe_checkout${
            isDirect ? `_direct` : ``
          }/${id}
          `,
          {
            type,
            total: total && total * 100,
            is_deposit,
            amountDue,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (isDirect) {
          // console.log("data", data)
          setBillingDetails(data.billingDetails);
          setSecret(data.secret.clientSecret);
        } else {
          setSecret(data);
        }
      },
      onError: (e) => {
        console.error(e);
      },
    }
  );

  const handleClickCheckout = () => {
    createPaymentIntent.mutate();
  };

  const handleChangeIsDirect = () => {
    setIsDirect((prev) => !prev);
  };
  if(createPaymentIntent.isLoading) return <p>{tr("Loading")}...</p>
  if (!stripeAccountId) return null;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

  const stripePromiseDirect = loadStripe(process.env.REACT_APP_STRIPE || "", {
    stripeAccount: stripeAccountId,
  });
  if (!secret) {
    return (
      <div className="space-y-4">
        {/* <div className="flex flex-row items-center">
          <Switch checked={isDirect} onChange={handleChangeIsDirect} />
          <p className="ml-2 font-thin text-sm">Use New Direct Charge</p>
        </div> */}
        <DollarInputV2
          value={total}
          label={tr("Total Amount")}
          onValueChange={(e) => e.floatValue && setTotal(e.floatValue)}
        />
        <Button onClick={handleClickCheckout} primary disabled={!total}>
          {tr("Checkout")}
        </Button>
      </div>
    );
  }

  const options = {
    clientSecret: secret,
  };
  
  return (
    <Elements
      stripe={isDirect ? stripePromiseDirect : stripePromise}
      options={options}
    >
      <StripeCheckout
        id={id}
        type={type}
        billingDetails={billingDetails}
        isDirect={isDirect}
      />
    </Elements>
  );
};

export { StripeContainer };
