export const formatMmDdYyyy = (yyyyMmDdString) => {
  const original = yyyyMmDdString;
  try {
    const yyyy = yyyyMmDdString.substring(0, 4);
    const mm = yyyyMmDdString.substring(5, 7);
    const dd = yyyyMmDdString.substring(8, 10);

    return `${mm}/${dd}/${yyyy}`;
  } catch (error) {
    console.error(error);
    return original;
  }
};
