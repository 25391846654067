import { useQuery } from "react-query";

import getProductionItems from "../api/getProductionItems";
import IProductionItem from "../types/IProductionItem";
import IFormValues from "../types/IFormValues";

const useProductionItems = (formData: IFormValues) => {
  const { data, isLoading, isError, error, refetch, isFetching } = useQuery<
    IProductionItem[]
  >(["productionItems", JSON.stringify(formData)], () =>
    getProductionItems(formData)
  );

  let sum = 0;

  if (data) {
    sum = data.reduce((acc, item) => {
      return acc + item.appointment_production;
    }, 0);
  }

  return {
    data,
    sum,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useProductionItems;
