import axios from "axios";
import { Button, OutlineButton, SubtleButton } from "components/button";
import Field from "components/field";
import MultiInput from "components/input/multi";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import CustomDocModal from "containers/customDocModal";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TProperty } from "types/property";
import { useTranslation } from 'react-i18next';

type Props = {
  invoiceId: string;
};

export const MarkInvoiceAsSentModal = (props: Props) => {
  const { t } = useTranslation();
  const tBase = "views.invoice.markInvoiceAsSentModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const snackbar = useSnackbarContext(),
    queryClient = useQueryClient(),
    modal = useModalContext(),
    snackBar = useSnackbarContext();

  const [templateId, setTemplateId] = React.useState<string>(),
    [completion_notes, setCompletionNotes] = React.useState(""),
    [billing, setBilling] = React.useState<TProperty>();

  const query = useQuery<string>(
    ["completion notes"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/completion_notes
  `
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (str) => {
        setCompletionNotes(str);
      },
    }
  );

  const billingAddress = useQuery<TProperty[] | null>(
    ["billing address"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/billing_address
  `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/markSent
  `,
          { templateId, completion_notes, billing }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        snackbar.showSnackbar(tr("Invoice Marked Sent!"));
        queryClient.invalidateQueries();
        modal.setModal();
      },
    }
  );

  const preview = useMutation(
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/preview`,
          {
            params: {
              templateId,
              completion_notes,
              billing,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
    }
  );

  const handlePreview = () => {
    preview.mutate();
  };

  const populateBillingAddresses = billingAddress.data?.map((billing) => {
    return (
      <label key={billing.id} className="flex space-x-2 items-center">
        <input
          type="radio"
          value={billing.id}
          name="billing_address"
          onChange={(e: any) => setBilling(billing)}
        />
        <span> {billing.full_address}</span>
      </label>
    );
  });

  return (
    <div className="max-w-[500px] space-y-4">
      <CustomDocModal
        id={templateId}
        type="invoice"
        onClick={(id) => setTemplateId((tId) => (tId === id ? undefined : id))}
      />
      <div>
        <h3>{tr("Billing Address")}</h3>
        <div className="flex flex-col">{populateBillingAddresses}</div>
      </div>
      <div>
        <h3>{tr("Completion Notes")}</h3>
        <div className="flex flex-col">
          <Field
            type="textarea"
            value={completion_notes}
            onChange={(e) => setCompletionNotes(e.target.value)}
            minRows={5}
          />
        </div>
      </div>
      <div></div>
      <div className="flex justify-end space-x-2">
        <SubtleButton
          isLoading={preview.isLoading}
          disabled={!templateId}
          onClick={handlePreview}
        >
          {tr("Preview")}
        </SubtleButton>
        <Button
          primary
          type="submit"
          isLoading={mutation.isLoading}
          disabled={!templateId}
          onClick={() => mutation.mutate()}
        >
          {tr("Mark Sent")}
        </Button>
        <OutlineButton onClick={() => modal.setModal()}>{tr("Cancel")}</OutlineButton>
      </div>
    </div>
  );
};

// const { mutate: markSent } = useMutation(
//   async () =>
//     await axios
//       .post(
//         `${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/markSent
//   `
//       )
//       .then((res) => res.data),
//   {
//     onSuccess: () => {
//       snackBar.showSnackbar("Invoice Marked Sent!");
//       queryClient.invalidateQueries();
//     },
//   }
// );

// const handleMarkSent = () => {
//   markSent();
// };
