import { Button, NoStyleButton } from "components/button";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";
import { Size } from "types/theme";

const colors = [theme.color.main, "#1f618d", ``];

const ButtonStyles = css<{ size: Size }>`
  width: ${(props) => theme.height[props.size]};
  min-width: ${(props) => theme.height[props.size]};
  height: ${(props) => theme.height[props.size]};
  min-height: ${(props) => theme.height[props.size]};
  font-size: calc(${(props) => theme.fontSize[props.size]} + 2px);
  position: relative;
  border: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: white;
  cursor: pointer;
  font-weight: 500;
  background: linear-gradient(
    157deg,
    rgba(255, 79, 0, 1) 0%,
    rgba(82, 13, 99, 1) 63%,
    rgba(2, 0, 36, 1)100%
  );
`;

const ConStyle = css`
  background: ${theme.bg.blue};
`;

export const StyledAvatarButton = styled.button`
  ${ButtonStyles};
`;

export const StyledAvatarA = styled(Link)`
  ${ButtonStyles};
`;

export const StyledAvatarDiv = styled.div`
  ${ButtonStyles}
`;

export const StyledContainerDiv = styled.div``;

export const StyledContainerButton = styled(Button)``;

export const Label = styled.span<{ size: Size }>`
  font-size: calc(${(props) => theme.fontSize[props.size]} + 2px);
  /* font-size: 16px; */
  white-space: nowrap;
`;
