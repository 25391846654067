/* Created by Hannah Green on 4/27/2021 for ES-147 */
import RawForm from "components/form";
import styled from "styled-components";
import RawSelect from "components/react-select";
const { Button } = require('components/button');
const { Input } = require('components/input');

export const Con = styled.div`
    @media only screen and (max-width: 900px) {
        display: flex;
        padding: 1rem;
    }

    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const Form = styled(RawForm)`
    @media only screen and (max-width: 900px){
        max-width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 0;
    }

    max-width: 45%;
    min-width: 25%;
    margin: 2rem;
`;

export const Select = styled(RawSelect)`
    margin: 5px;
`;

export const SubmitButton = styled(Button)`
    @media only screen and (max-width: 900px) {
        width: 100%;
        margin-top: 1rem;
    }

    margin-top: 0.5rem;
`;

export const TextInput = styled(Input)`
    margin: 5px;
`;

export const Header = styled.div`
    @media only screen and (max-width: 900px){
        padding: 0.5rem;
    }
    text-align: center;
`;

export const ImageCon = styled.div`
    @media only screen and (max-width: 900px) {
        display: flex;
        width: 100%;
        margin-top: 1rem;
    }

    margin-top: 3%;
    display: flex;
    width: 200px;
`;

export const ImageButton2 = styled.div`
  @media only screen and (max-width: 900px) {
    display: flex;
    width: 100%;
  }
  width: 200px;
  height: 300px;
  overflow: hidden;
  border-radius: 5px;

  border: 1px solid #f0f0f0;
  position: relative;
  cursor: pointer;
  position: sticky;
  top: 0;
  > svg {
    position: absolute;
    left: 50%;
    font-size: 5rem;
    color: #f0f0f0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
`;

export const Images = styled.div`
    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
    display: flex;
    margin-top: 2.5rem;
`;

export const Content = styled.div`
    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
`;