import { useQuery } from "react-query";
import { useState } from "react";
import { RiFileWarningLine }from "react-icons/ri"
import { TiThMenu } from "react-icons/ti";
import axios from "axios";
import { ContainerWithSidebar } from "components/layout";
import Spinner from "components/spinner";
import { getVendors } from "../../../api/vendorsApi";
import { VendorProvider } from "contexts/VendorContext";
import VendorsList from "../VendorsList/VendorsList";
import VendorsToolBar from "../VendorsToolBar/VendorsToolBar";
import VendorBody from "../VendorBody/VendorBody";
import { useModalContext } from "components/modal";
import { Button } from "components/button";


import styles from "./VendorsIndex.module.scss";

const VendorsIndex = () => {
  const {setModal} = useModalContext();
  const vendorsQuery = useQuery("vendors", getVendors);
//   const unmatched = useQuery("unmatched",  async () =>
//     await axios
//       .get(
//         `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/unmatched`
//       )
//       .then((res) => res.data)
//   );
  // const unmatched = useQuery("unmatched",  async () =>
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/unmatched`
  //     )
  //     .then((res) => res.data)
  // );
//  const Mergeitemsmodal = () => {
//   const [withId, setWithId] = useState('');
//   const [withVPN, setWithVPN] = useState('');
//   const unmatched = useQuery("unmatched",  async () =>
//   await axios
//     .get(
//       `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/unmatched`
//     )
//     .then((res) => res.data)
//   );
//   return(
//     <div style={{display:'flex', flexDirection:'row', padding:'20px'}}>
//     <select onChange={(e)=>{
//       setWithVPN(e.target.value)
//     }} style={{display:'flex',flex:'50%', flexDirection:'column'}}>
//       <option style = {{display:'flex'}} value={''}>
//             {'Vendor confirmed items'}</option>
//       {unmatched?.data?.noId?.map((item:any) => {
//         return(
//           <option style = {{display:'flex'}} value={item?.id}>
//             {'('+item?.id +') '+item?.notes?.split(',')[1]}
//           </option>
//         )
//       })}
//     </select>
//     <select onChange={(e)=>{
//       setWithId(e.target.value)
//     }} style={{display:'flex',flex:'50%', flexDirection:'column'}}>
//        <option style = {{display:'flex'}} value={''}>
//             {'Items without vendor IDs'}</option>
//     {unmatched?.data?.noVPN?.map((item:any) => {
//       return(
//         <option style = {{display:'flex'}} value={item?.item_id}>
//           {'('+item?.item_id +') '+item?.name}
//         </option>
//       )
//     })}</select>
//     <Button primary style={{display:'flex', flexDirection:'column'}} onClick={()=>{
//       axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/merge`,{
//         withId:withId,
//         withVPN:withVPN
//       }).then((res)=>{
//         console.log(res)
//       })
//     }}>
//       {'Merge'}
//     </Button>
//     </div>
//   )
//  } 
//   const Unmatchedwarning = () => {
//     if (unmatched?.data?.noId?.length > 0) {
//       return <div onClick={()=>{
//         setModal({
//           component:<Mergeitemsmodal/>,
//           label:'combine items'})
//       }} style={{color:'#f00', cursor:'pointer', display:'flex',margin:'auto'}}><RiFileWarningLine style={{display:'inline'}}/>{' items without VPN '}<RiFileWarningLine style={{display:'inline'}}/></div>;
//     } else {
//       return <></>;
//     }
//   }
  if (vendorsQuery?.isLoading) return <Spinner />;

//   if (vendorsQuery?.isError) return <div>Error</div>;

  const handleRefetch = () => {
    vendorsQuery.refetch();
  }

  // TODO: organize all the inline and module css styles in VendorToolBar, VendorBody, VendorDetails, and VendorDetailItem

  return (
    <VendorProvider>
      <ContainerWithSidebar>
        <div className={styles.sidebarContainer}>
          <VendorsList vendors={vendorsQuery?.data} />
          {/* <Unmatchedwarning/> */}
          
        </div>
        <div className={styles.bodyContainer}>
          <VendorsToolBar refetch={handleRefetch} />
          <VendorBody refetch={handleRefetch} />
        </div>
      </ContainerWithSidebar>
    </VendorProvider>
  );
};

export default VendorsIndex;
