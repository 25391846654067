import SafeArea from "components/safe-area";
import Tab from "components/tab";
import { RiArrowDownSLine, RiDeleteBin7Line } from "react-icons/ri";
import axios from "axios";
import Overview from "./overview";
import Requests from "./requests";
import Appointments from "./appointment-tab";
import { Button } from "components/button";
import Dropdown from "components/dropdown";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbarContext } from "components/snackbar";
import { useModalContext } from "components/modal";
import NewPropertyModal from "views/properties/newPropertyModal";
import Spinner from "components/spinner";
import { useAreYouSure } from "components/areYouSure";
import Map from "./map-tab";
import WarrantyTab from "containers/warrantyTab";
import PropertyFiles from "./property-files";
import PropertyTagList from "./property-tag-list";
import { useTranslation } from 'react-i18next';

function PropertyDetail(props) {
  const { t } = useTranslation();
  const tBase = "views.property.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient(),
    snackbar = useSnackbarContext(),
    modal = useModalContext(),
    areYouSure = useAreYouSure();

  const propertyId = props.match.params.id;

  const query = useQuery(
    ["property", propertyId],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${propertyId}`
        )
        .then((res) => res.data)
  );

  const deleteProp = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${propertyId}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        snackbar.showSnackbar(tr("Property Deleted!"));
      },
      onError: () => {
        modal.setModal({
          component: (
            <div>
              {tr("The property has information attached that can't be removed")}
            </div>
          ),
          label: tr("Error"),
        });
      },
    }
  );

  if (query.isLoading) return <Spinner />;
  if (!query.data) return null;

  const data = {
    overview: {
      tab: tr("Overview"),
      content: <Overview propertyId={propertyId} />,
    },
    requests: {
      tab: tr("Requests"),
      count: query.data.tab_counts.request_count,
      content: <Requests propertyId={propertyId} />,
    },
    appointments: {
      tab: tr("Appointments"),
      count: query.data.tab_counts.appointment_count,
      content: <Appointments propertyId={propertyId} />,
    },
    map: {
      tab: tr("Map"),
      content: <Map propertyId={propertyId} />,
    },
    warranty: {
      tab: tr("Warranty"),
      // count: query.data.warranty_count,
      count: query.data.tab_counts.warranty_count,
      content: <WarrantyTab id={propertyId} type="property" />,
    },
    files: {
      tab: tr("Files"),
      count: query.data.tab_counts.file_count,
      content: <PropertyFiles id={propertyId} type="property" />,
    },
  };

  // console.log(query.data.tags, "query.data.tags");

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="flex items-center justify-between py-4">
          <div className="flex flex-row">
            <h2>{query.data.full_address}</h2>
            <PropertyTagList tags={query.data.tags} />
          </div>
          <div>
            <Dropdown
              reference={
                <Button>
                  {tr("Action")} <RiArrowDownSLine />
                </Button>
              }
              data={[
                {
                  button: [
                    {
                      label: tr("Edit"),
                      onClick: () =>
                        modal.setModal({
                          component: <NewPropertyModal property={query.data} />,
                          label: `${tr("Property")} #${query.data.id}`,
                        }),
                    },
                    {
                      label: (
                        <span className="flex items-center space-x-2">
                          <RiDeleteBin7Line />
                          <span>{tr("Delete")}</span>
                        </span>
                      ),
                      className: "!text-red-500",
                      onClick: () =>
                        areYouSure.activate(() => deleteProp.mutate()),
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </SafeArea>
      <SafeArea>
        <div className="py-4">
          <Tab data={data} defaultTab={"overview"} />
        </div>
      </SafeArea>
    </div>
  );
}

export default PropertyDetail;
