import * as React from "react";
import * as Formik from "formik";
import { Input } from "components/input";
import Select from "components/react-select";
import TextArea from "components/textarea";
import AssigneeSelect from "components/react-select/assignees";
import { cloneDeep } from "lodash";

type Props = {
  children: ((props: any) => any) | React.ReactNode;
  className?: string;
  style?: object;
  onChange?: (e: any, action: object) => void;
};

type ChildProps = {
  onChange?: (a: any) => void;
  onBlur?: (a: any) => void;
  error?: any;
  children?: React.ReactNode;
  value?: any;
  checked?: any;
  formatter?: (a: any) => any;
};

export default function FormComponent<T>({
  initialValues,
  children,
  onSubmit,
  validationSchema,
  enableReinitialize,
  style,
  className,
  onChange,
  ...rest
}: Formik.FormikConfig<T> & Props) {
  const populateChildren = (props: Formik.FormikProps<any>) => {
    const ChildrenRecursion = (parent: React.ReactNode): React.ReactNode => {
      return React.Children.map(parent, (child) => {
        if (React.isValidElement(child)) {
          let childProps: ChildProps;

          if (
            child.type === Input ||
            child.type === Select ||
            child.type === AssigneeSelect ||
            child.type === TextArea
          ) {
            childProps = {
              onChange: (e) => {
                /**
                 * running handleChange on the props. This is to change the handleSubmit value
                 */
                if (
                  child.props.formatOn === "change" &&
                  child.props.formatter
                ) {
                  let temp = cloneDeep(e);
                  temp.target.value = child.props.formatter(temp.target.value);
                  props.handleChange(temp);
                } else {
                  props.handleChange(e);
                }

                /**running on Change in the form element */
                /* onChange &&
                   onChange(
                     { name: child.props.name, value: e.target.value },
                     { ...props }
                  );

                 child.props.onChange &&
                   child.props.onChange(
                     { name: child.props.name, value: e.target.value },
                     { ...props }
                   ); */
              },
              onBlur: (e) => {
                props.handleBlur(e);

                if (child.props.formatOn === "blur" && child.props.formatter) {
                  props.setFieldValue(
                    e.target.name,
                    child.props.formatter(e.target.value)
                  );
                }
              },
              value: props.values[child.props.name] || "",
              error:
                props.touched[child.props.name] &&
                props.errors[child.props.name],
            };

            if (child.props.type === "checkbox") {
              childProps.checked = props.values[child.props.name];
            }

            if (child.type === Select || child.type === AssigneeSelect) {
              childProps.onChange = (e) => {
                onChange &&
                  onChange({ name: child.props.name, value: e }, { ...props });
                props.setFieldValue(child.props.name, e);
              };
            }
            return React.cloneElement(child, childProps);
          } else if (
            child.props.children &&
            typeof child.props.children !== "string"
          ) {
            childProps = {
              children: ChildrenRecursion(child.props.children),
            };
            return React.cloneElement(child, childProps);
          }
          return child;
        }
        return null;
      });
    };

    return ChildrenRecursion(children);
  };

  return (
    // @ts-ignore
    <Formik.Formik
      {...{ initialValues, onSubmit, validationSchema, enableReinitialize }}
      {...rest}
    >
      {(props) => {
        return (
          <Formik.Form {...{ style, className }}>
            {/* {typeof children === "function" && children(props)} */}
            {populateChildren(props)}
          </Formik.Form>
        );
      }}
    </Formik.Formik>
  );
}
