/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

const Con = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

`

const Month = styled.div`
    height: 150px;
    font-size: 2rem;
    text-align: center;
    line-height: 150px;
    border: 1px solid black;

`


export default function () {
    const { t } = useTranslation();
    const tBase = "views.team.sick.year";
    const tr = (key) => t(`${tBase}.${key}`);
    
    return (
        <Con>
            <Month>{tr("January")}</Month>
            <Month>{tr("February")}</Month>
            <Month>{tr("March")}</Month>
            <Month>{tr("April")}</Month>
            <Month>{tr("May")}</Month>
            <Month>{tr("June")}</Month>
            <Month>{tr("July")}</Month>
            <Month>{tr("August")}</Month>
            <Month>{tr("September")}</Month>
            <Month>{tr("October")}</Month>
            <Month>{tr("November")}</Month>
            <Month>{tr("December")}</Month>
        </Con>
    )
}