/* Created by Hannah Green for ES-143 on 04/08/2021 */
import React from "react";
import { useModalContext } from "components/modal";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useCartCountDispatch } from "components/cart";
import axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
const { Button } = require('components/button');


const Con = styled.div`
    width: 100%;
`;

const Message = styled.div`

`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-top: 20px;

  button {
    margin-right: 10px;
  }
`;

export default function ConfirmationPopup(props) {
  const { t } = useTranslation();
  const tBase = "views.cart.components.confirmationPopup";
  const tr = (key) => t(`${tBase}.${key}`);
  const { items, values } = props;
  const { showSnackbar } = useSnackbarContext();
  const history = useHistory();
  const dispatch = useCartCountDispatch();
  const { setModal } = useModalContext();

  const mutation = useMutation((form) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/specialOrder/checkout`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }, {
    onSuccess: () => {
      showSnackbar(tr("Completed!"));
      history.push("/order");
      setModal();
    },
  });

  const checkOut = async () => {
    const { ...form } = values

    form.items = items;

    const formData = new FormData();

    formData.append("form", JSON.stringify(form));
    mutation.mutate(formData);

    dispatch({ type: 'CHECKOUT' });
  };

  return (
    <Con>
      <Message> {tr("Are you ready to checkout?")} </Message>

      <Buttons>
        <Button type="submit" primary onClick={() => { checkOut() }}>{tr("Yes")}</Button>
        <Button type="submit" primary onClick={() => setModal()}> No </Button>
      </Buttons>
    </Con>
  )
}