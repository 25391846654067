import * as React from "react";
import { StyledMenu } from "./style";
import { StyledComponent } from "styled-components";

type Props = {
  children?: React.ReactNode;
};
const Wrapper = (Component: StyledComponent<"ul", any, {}, never>) => {
  return React.forwardRef<HTMLUListElement, React.HTMLProps<HTMLUListElement>>(
    (props: Props, ref) => {
      let { children } = props;
      // children = children && Array.isArray(children) ? children : [children];
      return (
        <Component {...props} ref={ref}>
          {React.Children.map(children, (e: any, i: number) => (
            <li key={i}>{e}</li>
          ))}
        </Component>
      );
    }
  );
};
export const Menu = Wrapper(StyledMenu);
