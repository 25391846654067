export const __businessUrl__ = `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business`;

export const __companyUrl__ = `${process.env.REACT_APP_SERVER_URL}/api/v1/company`;

export const __fieldUrl__ = `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-field-2`;

export const __mapBoxToken__ = process.env.REACT_APP_MAPBOX_TOKEN;

export const CREATE = "CREATE";
export const EDIT = "EDIT";
