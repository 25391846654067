import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

type Props = {
	userId: number;
};

// Modal for selecting a permissions template to apply to a user
export const GroupTemplateModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.permission.components.GroupTemplateModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const [group, setGroup] = useState("1");
	const queryClient = useQueryClient();

  const groups = useQuery(
    ["Glist", props.userId],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutestemplates`
        )
        .then((res) => res.data)
  );

  const applyGroup = useMutation(
    ["applygroup"],
    async (props: any) => {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutestemplateapply`,
        {
          userId: props.userId,
          templateId: props.templateId,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("routes");
      },
    }
  );

  const writtenList: any = [];

  const groupsList = groups.data
    ?.map((a: any) => {
      if (writtenList.includes(a.gid)) {
        return null;
      } else {
        writtenList.push(a.gid);
        return a;
      }
    })
    .filter((a: any) => a !== null);

  return (
    <div>
      
      <div>
        <select
          className="mx-auto mt-auto mb-5 border-2 border-gray-300 rounded-md w-1/2 h-8"
          onChange={(e) => {
            setGroup(e.target.value);
          }}
        >
          {groupsList?.map((groupId: any) => {
            return (
              <option value={groupId.gid} key={groupId.gid}>
                {groupId.templatename}
              </option>
            );
          })}
        </select>
      </div>

      <Button
        onClick={() => {
          setModal();
        }}
      >
        {tr("Cancel")}
      </Button>

      <Button
				className="float-right"
        primary
        onClick={() => {
          applyGroup.mutateAsync({
            userId: props.userId,
            templateId: group,
          });
          setModal();
        }}
      >
        {tr("Apply")}
      </Button>
    </div>
  );
};
