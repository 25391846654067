import React from "react";
import styled from "styled-components";
import { Input } from "components/input";
import { Button } from "components/button";
import { useMutation } from "react-query";
import Axios from "axios";
import { Formik, Form } from "formik";
import { useSnackbarContext } from "components/snackbar";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

export default function Personal() {
  const { t } = useTranslation();
  const tBase = "views.profile.personal";
  const tr = (key) => t(`${tBase}.${key}`);

  const { showSnackbar } = useSnackbarContext();
  const initialValues = {
    old_pass: "",
    new_pass: "",
    confirm_pass: "",
  };
  const mutation = useMutation(
    (e) =>
      Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/password`,
        e
      ),
    {
      onSuccess: () => {
        showSnackbar(tr("Password updated!"));
      },
    }
  );

  const handleSubmit = async (e, { resetForm, setFieldError }) => {
    try {
      if (e.new_pass === e.confirm_pass) {
        await mutation.mutateAsync({
          newPassword: e.new_pass,
          oldPassword: e.old_pass,
        });
        resetForm(initialValues);
      } else {
        setFieldError("confirm_pass", "Password does not match");
      }
    } catch (err) {
      if (err.data === "Incorrect Password") {
        setFieldError("old_pass", err.data);
      }
    }
  };

  return (
    <Con>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          old_pass: Yup.string().required(tr("Required")),
          new_pass: Yup.string().required(tr("Required")),
          confirm_pass: Yup.string()
            .required(tr("Required"))
            .oneOf([Yup.ref("new_pass")], "Password does not match"),
        })}
      >
        {({
          touched,
          errors,
          dirty,
          isValid,
          values,
          handleBlur,
          handleChange,
        }) => {
          return (
            <Form>
              <Input
                name="old_pass"
                label={tr("Old Password")}
                type="password"
                required
                error={touched.old_pass && errors.old_pass}
                value={values.old_pass}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="new_pass"
                label={tr("New Password")}
                type="password"
                required
                error={touched.new_pass && errors.new_pass}
                value={values.new_pass}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Input
                name="confirm_pass"
                label={tr("Confirm Password")}
                type="password"
                error={touched.confirm_pass && errors.confirm_pass}
                required
                value={values.confirm_pass}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Button primary type="submit" disabled={!dirty || !isValid}>
                {tr("Submit")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Con>
  );
}

const Con = styled.div`
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  > button {
    margin-top: 2rem;
  }
`;
