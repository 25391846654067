import React, { useEffect, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import Popup from "components/popup";
import socket from "../socket";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	margin-top: 1rem;
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	// color: dark-grey;
	border: 1px solid #c9c9c9;
	// overflow: hidden;
	border-spacing: 0;
	border-radius: 20px 20px 0 0;
	> thead,
	tbody {
		> tr {
			border-bottom: 1px solid #c9c9c9;

			> th,
			td {
				padding: 1rem 0 1rem 0;
				// border: 1px solid grey;
			}
			> th {
				font-weight: 500;
			}
			> td {
				font-weight: lighter;
			}
		}
	}
`;

const Confirmation = styled(Popup)`
	width: 100%;
	max-width: 500px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	> div {
		display: flex;
		flex-direction: row;
		font-size: 1.2rem;
		align-items: center;
		&:first-child {
			justify-content: center;
		}
		&:nth-child(2) {
			justify-content: space-around;
			> button {
				font-size: 1.2rem;
				background: white;
				border-radius: 4px;
				border: 1px solid grey;
				padding: 1rem 2.5rem 1rem 2.5rem;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
`;

const Delete = styled(AiOutlineDelete)`
	&:hover {
		cursor: pointer;
		color: red;
		transform: scale(1.2);
	}
`;

const SickHistory = (props) => {
	const { t } = useTranslation();
	const tBase = "views.team.viewUser.sickHistory";
	const tr = (key) => t(`${tBase}.${key}`);

	const mounted = useRef(true);
	const tabRef = useRef(null);
	const [show, setShow] = useState(false);
	const { id } = props;
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState();

	const fetchData = useCallback(async () => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent?type=USER&user=${id}`
			);
			mounted.current && setData(res.data);
			mounted.current &&
				window.scrollTo({
					top: tabRef.current.offsetTop - 100,
					behavior: "smooth",
				});
		} catch (e) {
			console.error(e);
		}
	}, [id]);

	useEffect(() => {
		fetchData();

		return () => (mounted.current = false);
	}, [fetchData]);

	const populateList = data.map((e, i) => {
		let {
			start_date: start,
			end_date: end,
			sick_reason: reason,
			sick_id: sickId,
		} = e;
		start = moment(new Date(start));
		end = moment(new Date(end));
		let diff = end.diff(start, "days") + 1;
		start = start.format("MM/DD/YY");
		end = end.format("MM/DD/YY");
		return (
			<tr key={i}>
				<td>{diff}</td>
				<td>{start}</td>
				<td>{end}</td>
				<td>{reason}</td>
				<td>
					<Delete
						onClick={() => {
							setShow(true);
							setSelected({ id, sickId, start, end });
						}}
					/>
				</td>
			</tr>
		);
	});

	const deleted = async () => {
		let { id, sickId, start, end } = selected;
		try {
			await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent`,
				{
					type: "delete",
					data: sickId,
				}
			);
			if (moment().isBetween(start, end, "days", "[]")) {
				socket.emit("applysick", {
					category: "Unassigned",
					employeeId: id,
				});
			}
			fetchData();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Con ref={tabRef}>
			<Table>
				<thead>
					<tr>
						<th># {tr("Days")}</th>
						<th>{tr("From")}</th>
						<th>{tr("To")}</th>
						<th>{tr("Reason")}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>{populateList}</tbody>
			</Table>
			{show && (
				<Confirmation cancelDisplay={() => setShow(false)}>
					<div>{tr("Are you sure you want to proceed?")}</div>
					<div>
						<button
							onClick={(e) => {
								e.stopPropagation();
								setShow(false);
								deleted();
							}}
						>
							{tr("Yes")}
						</button>
						<button
							onClick={(e) => {
								e.stopPropagation();
								setShow(false);
							}}
						>
							{tr("Cancel")}
						</button>
					</div>
				</Confirmation>
			)}
		</Con>
	);
};

export default SickHistory;
