import axios from "api/axios";
import { IconButton, SubtleA } from "components/button";
import { Input } from "components/input";
import Spinner from "components/spinner";
import { cloneDeep } from "lodash";
import * as React from "react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { TTargetCustomField } from "types/custom-field";
import { v4 as uuid } from "uuid";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';

type Props = {
  type: "request" | "project";
  id: string;
};

export default function CustomFieldsTab(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.CustomFieldsTab.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const queryClient = useQueryClient();

  const [state, setState] = React.useState<TTargetCustomField[]>();

  const query = useQuery<TTargetCustomField[]>(
    ["custom fields", props.id, props.type],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-fields/${props.type}/${props.id}
    `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setState(data.map((cf) => ({ ...cf, uuid: uuid() })));
      },
    }
  );

  const mutation = useMutation(
    async (e: TTargetCustomField) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-fields/${props.type}/${props.id}
  `,
          e
        )
        .then((res) => res.data),
    {
      onError: () => {
        query.refetch();
      },
    }
  );

  if (query.isLoading) return <Spinner />;

  const populateCustomFields =
    state && state.length > 0 ? (
      state.map((cf, i) => {
        const handleChange = (e: any) => {
          let value = e.target.value;
          setState((s) => {
            let temp = cloneDeep(s);
            if (temp) {
              temp[i].custom_value = value;
            }
            return temp;
          });
          mutation.mutate({ ...cf, custom_value: value });
        };

        const handleViewableChange = () => {
          setState((s) => {
            let temp = cloneDeep(s);
            if (temp) {
              temp[i].viewable = !temp[i].viewable;
              // mutation.mutate(temp[i]);
            }
            return temp;
          });

          mutation.mutate({ ...cf, viewable: !cf.viewable });
        };

        return (
          <SCFCon key={cf.id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0.25rem",
              }}
            >
              <span>{cf.custom_key}</span>
              <IconButton
                size="xsmall"
                onClick={handleViewableChange}
                style={
                  cf.viewable ? { background: "#ff4f00", color: "white" } : {}
                }
              >
                {cf.viewable ? <RiEyeFill /> : <RiEyeOffFill />}
              </IconButton>
            </div>
            <Input value={cf.custom_value} onChange={handleChange} />
          </SCFCon>
        );
      })
    ) : (
      <Placeholder
        type="custom_fields"
        header={tr("Custom Fields")}
        label={tr("Please assign a division to the project to populate custom fields.")}
      />
    );

  return (
    <Con className="divide-y">
      <div className="flex py-2 justify-between items-center">
        <h2>{tr("Custom Fields")}</h2>
        <SubtleA style={{ padding: 0 }} primary to="/admin/custom-field">
          {tr("Modify custom fields")}
        </SubtleA>{" "}
      </div>
      <div className="py-2">{populateCustomFields}</div>
    </Con>
  );
}

const Con = styled.div``;

const SCFCon = styled.div`
  margin-bottom: 0.5rem;
`;
