import axios from "api/axios";
import { Button } from "components/button";
import ImageUploadButton, {
  UploadImage,
} from "components/button/imageUploadButton";
import Field from "components/field";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { useFormik } from "formik";
import * as React from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { TItemGroup } from "types/item_group";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function EditItemGroupModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.item_group.edit";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory(),
    snackbar = useSnackbarContext(),
    modal = useModalContext(),
    queryClient = useQueryClient();

  const [image, setImage] = React.useState<any>();

  const query = useQuery<TItemGroup>(
    ["item_groups", props.id],
    async () =>
      props.id !== "new" &&
      (await axios
        .get(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/item_groups/${props.id}
      `
        )
        .then((res) => res.data))
  );

  const mutation = useMutation(
    async (e: FormData) =>
      await axios
        .post(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/item_groups/${props.id}
      `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: (newId) => {
        if (props.id === "new") {
          history.push(`/item_groups/${newId}`);
          snackbar.showSnackbar(tr("Item Group Created!"));
        } else {
          queryClient.invalidateQueries();
          snackbar.showSnackbar(tr("Item updated!"));
        }
        modal.setModal();
      },
    }
  );

  const initialValues = {
    label: query.data?.label || "",
    image: false,
  };

  const validationSchema = Yup.object({
    label: Yup.string().required(),
  });
  const formik = useFormik<{ label: string }>({
    initialValues,
    onSubmit: (e) => {
      const formData = new FormData();
      formData.append("form", JSON.stringify(e));

      if (image) {
        formData.append("image", image.blob);
      }
      mutation.mutate(formData);
    },
    validationSchema,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-4">
      <ImageUploadButton
        uploadImages={(e: UploadImage) => {
          setImage(e);
          formik.setFieldValue("image", true);
        }}
        Component={
          <div
            className="w-[200px] border h-[200px] text-3xl flex items-center justify-center bg-white rounded overflow-hidden"
            style={{
              alignSelf: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={image?.dataUrl || query.data?.imageUrl}
              style={{ width: "100%", maxWidth: "100%" }}
            />
            <AiOutlineCamera style={{ position: "absolute" }} />
          </div>
        }
      />
      <Field
        autoFocus
        required
        onFocus={(e: any) => e.target.select()}
        formik={formik}
        label={tr("Label")}
        type="input"
        name="label"
      />
      <Button
        isLoading={mutation.isLoading}
        disabled={!formik.dirty || !formik.isValid}
        primary
        type="submit"
      >
        {tr("Submit")}
      </Button>
    </form>
  );
}
