import React from "react";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

import styles from "./UserDisplayControl.module.scss";

type Props = {
  users: any;
  showMarkerDisplayList: Array<any>;
  handleUpdateDisplayList: (userId: number, type: string) => void;
  handleChangeAllMarkerDisplay: (type: string) => void;
  showUnassigned: boolean;
  handleChangeShowUnassigned: () => void;
};

const UserDisplayControl: React.FC<Props> = ({
  users,
  showMarkerDisplayList,
  handleUpdateDisplayList,
  handleChangeAllMarkerDisplay,
  showUnassigned,
  handleChangeShowUnassigned,
}) => {
  const { t } = useTranslation();
  const tBase = "views.appointments.MapComponents.UserDisplayControl";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className={styles.container}>
      <div className={styles.buttonRow}>
        <Button
          onClick={() => handleChangeAllMarkerDisplay("all")}
          size="xsmall"
          className={styles.button}
        >
          {tr("Show All")}
        </Button>
        <Button
          onClick={() => handleChangeAllMarkerDisplay("none")}
          size="xsmall"
          className={styles.button}
        >
          {tr("Show None")}
        </Button>
      </div>
      <div className={styles.userList}>
        <div
          className={styles.userRow}
          onClick={() => {
            handleChangeShowUnassigned();
          }}
        >
          <input
            checked={showUnassigned}
            type="checkbox"
            className={styles.checkBox}
          />
          <div
            className={styles.colorBlock}
            style={{ backgroundColor: "rgba(10, 10, 10, 0.5)" }}
          />
          <div>{tr("Unassigned")}</div>
        </div>
        {users &&
          users.length > 0 &&
          users?.map((user: any) => {
            return (
              <div
                className={styles.userRow}
                onClick={() => {
                  handleUpdateDisplayList(
                    parseInt(user.value),
                    showMarkerDisplayList.findIndex((u) => u == user.value) >= 0
                      ? "remove"
                      : "add"
                  );
                }}
              >
                <input
                  checked={
                    showMarkerDisplayList.findIndex((u) => u == user.value) >= 0
                  }
                  type="checkbox"
                  className={styles.checkBox}
                />
                <div
                  className={styles.colorBlock}
                  style={{ backgroundColor: user.color }}
                />
                <div>{user.label}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserDisplayControl;
