import axios from "axios";
import { Button, OutlineButton } from "components/button";
import Field from "components/field";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as React from "react";
import { RiCloseLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { TWarrantyTargetItem, TWarrantyTargetItemEdit } from "types/warranty";
import { useTranslation } from 'react-i18next';

type TEditWarrantyProps = {
  item: TWarrantyTargetItem;
};

const options = {
  day: { value: "day", label: "Day" },
  week: { value: "week", label: "Week" },
  month: { value: "month", label: "Month" },
  year: { value: "year", label: "Year" },
};

export default function EditWarrantyModal(props: TEditWarrantyProps) {
  const { t } = useTranslation();
  const tBase = "containers.warrantyTab.editWarrantyLineModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const snackbar = useSnackbarContext(),
    modal = useModalContext(),
    queryClient = useQueryClient();

  const mutation = useMutation(
    async (e: TWarrantyTargetItemEdit) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/item/${props.item.id}
  `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        modal.setModal();
        snackbar.showSnackbar(tr("Warranty line updated!"));
        queryClient.invalidateQueries();
      },
    }
  );

  const deleteItem = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/item/${props.item.id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        modal.setModal();
        snackbar.showSnackbar(tr("Warranty line deleted!"));
        queryClient.invalidateQueries();
      },
    }
  );
  const onSubmit = (e: TWarrantyTargetItemEdit) => {
    mutation.mutate({
      ...e,
      start_date: e.start_date
        ? new Date(e.start_date + " 0:00").toISOString()
        : null,
    });
  };

  const initialValues = {
    start_date: props.item.start_date
      ? format(new Date(props.item.start_date), "yyyy-MM-dd")
      : null,
    duration: props.item.duration || 0,
    label: props.item.label || "",
    duration_type: props.item.duration_type || null,
  };

  const formik = useFormik<TWarrantyTargetItemEdit>({
    onSubmit,
    initialValues,
  });

  return (
    <div className="py-2 space-y-2 w-[500px] flex flex-col">
      <Field name="start_date" formik={formik} type="date" label={tr("Start Date")} />
      <div className="flex justify-between items-end">
        <Field
          name={`duration`}
          formik={formik}
          placeholder={tr("Date")}
          label={tr("Duration")}
        />
        <Field
          className={"w-[200px]"}
          label={tr("Duration Type")}
          type="select"
          options={[
            { value: "day", label: tr("Day") },
            { value: "week", label: tr("Week") },
            { value: "month", label: tr("Month") },
            { value: "year", label: tr("Year") },
          ]}
          name={`duration_type`}
          value={options[formik.values.duration_type]}
          onChange={(e: any) => {
            formik.setFieldValue("duration_type", e.value);
          }}
        />
      </div>

      <Field
        label={tr("Description")}
        type="textarea"
        name={`label`}
        formik={formik}
        placeholder={tr("Label")}
        minRows={4}
      />
      <div className="flex justify-end space-x-4 items-center my-2">
        <OutlineButton
          className="!border-red-600 !text-red-600"
          onClick={() => deleteItem.mutate()}
        >
          <RiCloseLine /> {tr("Delete")}{" "}
        </OutlineButton>
        <Button primary onClick={() => formik.handleSubmit()} type="submit">
          {tr("Save Changes")}
        </Button>
      </div>
    </div>
  );
}
