import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";
import { ITags } from "../components/interfaces/ITags";

export const getTags = async () => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data.sort((a: ITags, b: ITags) =>
      a.label.localeCompare(b.label)
    );
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: "/tags",
  })
    .then(onSuccess)
    .catch(onError);
};
