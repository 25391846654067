import { MapProvider } from "react-map-gl";
import { NewPropertyMap } from "./NewPropertyMap";

type Props = {
  formik: any; // TODO: type this formik
};

const NewPropertyMapContainer = ({ formik }: Props) => {
  return (
    <MapProvider>
      <NewPropertyMap formik={formik} />
    </MapProvider>
  );
};

export { NewPropertyMapContainer };
