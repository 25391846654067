import { Button, OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import Spinner from "components/spinner";
import { useAppointmentChecklists } from "features/requests/hooks/useAppointmentChecklists";
import { IChecklistWithResponses } from "features/requests/types/checklists";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { ChecklistModalContent } from "./checklist-modal-content";
import { useTranslation } from 'react-i18next';

interface IProps {
  appointmentId: number;
}

export const AppointmentChecklists = ({ appointmentId }: IProps) => {
  const { t } = useTranslation();
  const tBase = "features.requests";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const { data, isError, isLoading } = useAppointmentChecklists(appointmentId);

  const labels = [
    ...new Set<string>(
      data?.data.map((checklist: IChecklistWithResponses) => checklist.type)
    ),
  ];

  const handleClickChecklist = (checklistLabel: string) => {
    setModal({
      component: (
        <ChecklistModalContent
          checklist={data?.data?.filter(
            (c: IChecklistWithResponses) => c.type === checklistLabel
          )}
        />
      ),
      label: capitalizeFirstLetter(checklistLabel) || "",
    });
  };

  if (isLoading)
    return (
      <>
        <h2>{tr("Checklists")}</h2>
        <div>
          <Spinner />
        </div>
        <div className="mt-2" />
        <hr />
      </>
    );

  // TODO: return error.message with proper typing
  if (isError)
    return (
      <>
        <h2>{tr("Checklists")}</h2>
        <div>{tr("Something went wrong.")}</div>
        <div className="mt-2" />
        <hr />
      </>
    );

  return (
    <>
      <h2>{tr("Checklists")}</h2>

      <div className="flex flex-row flex-wrap">
        {labels?.map((label: string) => {
          return (
            <div className="mr-2 mb-2">
              <OutlineButton onClick={() => handleClickChecklist(label)}>
                {capitalizeFirstLetter(label) || ""}
              </OutlineButton>
            </div>
          );
        })}
      </div>
      {data?.data?.length === 0 ? (
        <div className="mt-2">{tr("No Checklists Entered")}</div>
      ) : null}
      <div className="mt-2" />
      <hr />
    </>
  );
};
