import React from "react";

import { useMutation } from "react-query";
import axios from "axios";

import { useParams } from "react-router-dom";


import PermittedFeatures from "./components/PermittedFeatures";
import OtherSettings from "./components/OtherSettings";
import FieldPermissions from "./components/FieldPermissions";

export default function Permission (props: any) {
  const { id } = useParams<any>();

  const applyTemplate = useMutation(
    async (templateId: number) =>
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/templates/${templateId}/apply`,
        {
          id,
        }
      ),
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  const handleClick = (templateId: number) => {
    applyTemplate.mutate(templateId);
  };

  return (
    <div className="p-4">
      <PermittedFeatures userId={id} />
      <div className="py-2"></div>
      <OtherSettings userId={id} />
      <div className="py-2"></div>
      <FieldPermissions userId={id} />
    </div>
  );
}
