// utils/formatCentsToUSD.ts

/**
 * Convert cents to a formatted USD string.
 *
 * @param cents The amount in cents.
 * @returns The formatted USD string.
 */
const formatCentsToUSD = (cents: number): string => {
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export default formatCentsToUSD;
