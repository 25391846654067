/* Created by Hannah Green on 02/09/2021 for ES-133 */

import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

/* Components */
import AddEditWhseForm from "./addEditWhseForm";
import DeleteForm from "./deleteWarehouse";
import { useModalContext } from "components/modal";

/* Icons */
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

/* Table Imports */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";

/* Table Utils */
const { EnhancedTableHead, sort, getComparator } = require("./table_utils");

export default function WarehouseTable(props) {
	const { t } = useTranslation();
	const tBase = "views.warehouse_management.components.warehouseTable";
	const tr = (key) => t(`${tBase}.${key}`);

	const { classes, noResults, items } = props;

	const { setModal } = useModalContext();

	//State Variables
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("warehouse_id");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const headCells = [
		{
			id: "warehouse_name",
			numeric: false,
			disablePadding: true,
			label: tr("Name"),
		},
		{
			id: "group_name",
			numeric: false,
			disablePadding: true,
			label: tr("Group Name"),
		},
		{
			id: "vehicle_name",
			numeric: false,
			disablePadding: true,
			label: tr("Vehicle Name"),
		},
		{
			id: "default_warehouse",
			numeric: false,
			disablePadding: true,
			label: tr("Default Warehouse"),
		},
		{
			id: "edit_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
		{
			id: "delete_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
	];

	//sort request handler
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	//Change page handler
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	//change rows handler
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

	const EditToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<AddEditWhseForm
						formType="edit"
						warehouseId={row.warehouse_id}
					/>
				),
				label: tr("Edit Warehouse"),
			});
		};

		return (
			<Tooltip title={tr("Edit")}>
				<IconButton aria-label="edit" onClick={handleClick}>
					<EditIcon />
				</IconButton>
			</Tooltip>
		);
	};

	const DeleteToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<DeleteForm
						id={row.warehouse_id}
						name={row.warehouse_name}
						closePopup={() => {
							setModal();
						}}
					/>
				),
				label: tr("Delete Warehouse"),
			});
		};

		return (
			<Tooltip title={tr("Delete")}>
				<IconButton aria-label="delete" onClick={handleClick}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		);
	};

	return (
		<>
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby="warehouse_management"
					size="medium"
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={items.length}
						headCells={headCells}
					/>
					{noResults && (
						<TableBody>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
							<TableCell align="left">{tr("No Results")}</TableCell>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableBody>
					)}
					{!noResults && (
						<TableBody>
							{sort(items, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									return (
										<TableRow
											hover
											tabIndex={-1}
											key={row.warehouse_id}
										>
											<TableCell align="left">
												{row.warehouse_name}
											</TableCell>
											<TableCell align="left">
												{row.group_name}
											</TableCell>
											<TableCell align="left">
												{row.vehicle_name}
											</TableCell>
											<TableCell align="left">
												{row.default_warehouse}
											</TableCell>

											<TableCell align="right">
												<EditToolTip
													{...{ row, setModal }}
												/>
											</TableCell>

											<TableCell align="left">
												<DeleteToolTip
													{...{ row, setModal }}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					)}
					<TablePagination
						rowsPerPageOptions={[25, 50, 100]}
						component="div"
						count={items.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Table>
			</TableContainer>
		</>
	);
}
