import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Components
import Pagination from "./components/Pagination";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import Customers from "./components/Customers";
import Spinner from "components/spinner";

// Types
import { IAccordionStates } from "./types";

function DuplicateCustomers() {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false); // used for scan / rescan
  const [initialLoad, setInitialLoad] = useState(true); // used for scan / rescan
  const [duplicates, setDuplicates] = useState([] as any[]);
  const [customerData, setCustomerData] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const savedItemsPerPage = localStorage.getItem("itemsPerPage");
    return savedItemsPerPage ? JSON.parse(savedItemsPerPage) : 20;
  });
  const [accordionStates, setAccordionStates] = useState<IAccordionStates>({});
  const [duplicateCount, setDuplicateCount] = useState(0);
  const [showSpinnerMessage, setShowSpinnerMessage] = useState(false);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("");
  const [clickedArea, setClickedArea] = useState(""); // used to determine which area was clicked (scan or rescan)
  const [scanComplete, setScanComplete] = useState(false); // used to determine if scan is complete
  const [yesScanned, setYesScanned] = useState(false); // used to determine if scan has been done before
  const [duplicatesExist, setDuplicatesExist] = useState(false);
  const [lastScanned, setLastScanned] = useState<Date | null>(null);

  // Ensure immediate state changes are reflected before the browser paints
  useLayoutEffect(() => {
    setLoading(true);
    checkIfScanned();
    checkIfExists();
    setLoading(false);
  }, []);

  // Accordion
  const toggleAccordion = (id: string) => {
    setAccordionStates(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const updateItemsPerPage = (newItemsPerPage: number) => {
    const currentFirstItemIndex = (currentPage - 1) * itemsPerPage; // Index of the first item on the current page
    const newCurrentPage = Math.ceil((currentFirstItemIndex + 1) / newItemsPerPage); // Compute new page number
    
    localStorage.setItem("itemsPerPage", JSON.stringify(newItemsPerPage));
    setCurrentPage(newCurrentPage); // Update page number
    setItemsPerPage(newItemsPerPage); // Update items per page

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', newCurrentPage.toString());
    searchParams.set('itemsPerPage', newItemsPerPage.toString());
    history.push(`${location.pathname}?${searchParams.toString()}`); // Update the URL with new query parameters
  };

  // Calculate the current duplicates to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDuplicates = duplicates.slice(indexOfFirstItem, indexOfLastItem);

  const getLastScanned = async () => {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/lastScanned`);
    if (!res.data) return;
    const date = new Date(res.data);
    setLastScanned(date);
  };

  // Used for scan / re-scan button (REAL TIME) SSE
  const findDuplicates = ( whereClicked: string ) => {
    setScanComplete(false);
    setProgress(0);
    setProgressMessage("");
    if (whereClicked === "rescan") {
      setClickedArea("rescan");
    } else if (whereClicked === "scan") {
      setClickedArea("scan"); 
    }
    setLoading2(true); 
 
    if (eventSource) {
      console.log("Closing existing EventSource");
      eventSource.close();
      setEventSource(null);  // Immediately nullify the old EventSource reference  
    }

    console.log("connecting to event source")
    const source = new EventSource(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/findDuplicates`,
      { withCredentials: true }
    );
    setEventSource(source);

    source.onmessage = (event) => {
      const { progress, message, type, finished } = JSON.parse(event.data);
      if (type === "progress1") {
        setProgress(progress * 0.05);
      } else if (type === "progress2") {
        setProgress(5 + progress * 0.95);
        setProgressMessage(message);
      }

      if (finished) { 
        console.log("Processing complete");
        source.close();
        eventSource?.close();
        console.log("even source closed");
        setLoading2(false);
        setProgress(0);
        setProgressMessage("");
        setClickedArea("");
        setScanComplete(true); // follow the logic to the useEffect next
      }
    };

    source.onerror = error => {
      console.error("EventSource failed:", error);
      source.close();
      eventSource?.close();
      setLoading2(false);
      setProgress(0);
      setProgressMessage("");
      setScanComplete(true);
    };
    
  };

  // Used to get ids from the db tables 
  const fetchDuplicates = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/fetchDuplicatesOnLoad`);
      setDuplicates(res.data.duplicates);
      setDuplicateCount(res.data.duplicates.length);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching duplicates:", error);
      setLoading(false);
    }
  };

  // Gets customer data from list of duplicate ids (needs fetchDuplicates to be called first)
  const getCustomerData = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/getCustomerData`, {
        duplicates: currentDuplicates
      });
      setCustomerData(res.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setLoading(false);
    }
  };

  const checkIfScanned = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/HasBeenScanned`);
      setYesScanned(res.data);
    } catch (error) {
      console.error("Error checking if scanned: ", error);
    }
  };

  const checkIfExists = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/CheckIfDuplicatesExist`);
      setDuplicatesExist(res.data);
    } catch (error) {
      console.error("Error checking if scanned: ", error);
    }
  };
  
  // Fetch customer data when duplicates change and after fetchDuplicates
  useEffect(() => {
    getCustomerData();
  }, [currentPage, itemsPerPage, duplicates]);

  // Change page
  const paginate = (pageNumber: number) => {
    // setCurrentPage(pageNumber);
    // history.push(`?page=${pageNumber}`);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', pageNumber.toString());
    searchParams.set('itemsPerPage', itemsPerPage.toString());  // Preserve the current itemsPerPage value
    history.push(`${location.pathname}?${searchParams.toString()}`);
  };

  // Create page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(duplicates.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const totalPages = Math.ceil(duplicates.length / itemsPerPage);

  useEffect(() => { 
    getLastScanned();
    checkIfExists();
    checkIfScanned();
    fetchDuplicates();
    setProgress(0);
    setProgressMessage("");
    setClickedArea("");
    setInitialLoad(false)
  }, []);

  useEffect(() => { // after findDuplicates finishes
    if (scanComplete) {
      getLastScanned();
      checkIfExists();
      checkIfScanned();
      fetchDuplicates();
      getCustomerData();
    }
  }, [scanComplete]);

  useEffect(() => { // Get page number from URL
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') || '1', 10);
    const itemsCount = parseInt(searchParams.get('itemsPerPage') || '20', 10);
    setCurrentPage(page);
    setItemsPerPage(itemsCount);
  }, [location]);

  useEffect(() => { // Scan in body, rescan in header
    if (clickedArea === "scan" || clickedArea === "rescan") {
      setShowSpinnerMessage(true);
    }
  }, [clickedArea]);

  return (
    <div className="p-4">

      {/* Header */}
      <Header {...{
        duplicateCount,
        accordionStates,
        setAccordionStates,
        customerData,
        findDuplicates,
        loading2,
        itemsPerPage,
        setItemsPerPage,
        currentPage,
        updateItemsPerPage,
        scanComplete,
        yesScanned,
        lastScanned
      }} />

      {/* Header Row */}
      <div className="grid grid-cols-12 gap-4 text-[#000000B3] text-sm font-bold mt-6 mb-2">
        <div className="col-span-1 pl-2">% Match</div>
        <div className="col-span-2">{tr("Name")}</div>
        <div className="col-span-3">{tr("Email")}</div>
        <div className="col-span-3">{tr("Address")}</div>
        <div className="col-span-2">{tr("Phone")} #</div>
        <div className="col-span-1">{tr("Tools")}</div>
      </div>
      <div className="border-t border-primary "/>

      {/* Content */}
      {initialLoad || loading ? (
        <div className="text-center mt-8">
          <Spinner size={32} />
        </div>
      ) : (!yesScanned || clickedArea === "scan" || clickedArea === "rescan")  ? (
        <ProgressBar 
          {...{
            showSpinnerMessage,
            loading2,
            findDuplicates,
            progress,
            progressMessage,
            clickedArea,
            yesScanned
          }}
        />
      ) : yesScanned && duplicatesExist ? (
        <Customers 
        setCustomerData={setCustomerData} {...{
          customerData,
          toggleAccordion,
          accordionStates,
          setDuplicateCount,
          fetchDuplicates,
          loading
        }}        
      />

      ) : (
        ""
      )}

      {/* Pagination */}
      {duplicates && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
        />
      )}

    </div>
  );
}

export default DuplicateCustomers;