import { Button, MenuButton, OutlineA } from "components/button";
import * as React from "react";
import Quote from "./components/quote";
import AppointmentCard from "containers/appointmentCard";
import styled from "styled-components";
import { theme } from "styles/theme";
import Popper from "components/react-popperv2";
import { RiArrowDownSLine, RiArrowRightLine } from "react-icons/ri";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import axios from "api/axios";
import { Project } from "..";
import { useSnackbarContext } from "components/snackbar";
import Progress from "./components/progress";
import { useAreYouSure } from "components/areYouSure";
import { useHistory } from "react-router";
import Estimates from "./components/estimates";
import Segment, { SContent, SHeader } from "components/segment";
import Tab from "components/tab";
import LineItemsContainer from "containers/lineItems";
import SubmitQuoteModal from "./quote/components/submitQuoteModal";
import { useModalContext } from "components/modal";
import BreadCrumbs from "components/breadcrumbs";
import { Status } from "views/request/component/status";
import useWindowDimensions from "hooks/useWindowDimensions";
import ProjectInfo from "../components/projectInfo";
import RequestNotes from "views/request/container/notes";
import RequestReminders from "views/request/container/reminders";
import RequestFilesTab from "views/request/container/files";
import CustomFieldsTab from "containers/CustomFieldsTab";
import PaymentsList from "containers/payments";
import { dollarFormatter } from "utils";
import { TPayment } from "types/payment";
import InvoicesContainer from "containers/invoicesContainer";
import ProductionContainer from "containers/productionContainer";
import WarrantyTab from "containers/warrantyTab";
import { useTranslation } from 'react-i18next';

export default function ProjectDefault({
  id,
  project,
}: {
  id: string;
  project: UseQueryResult<Project, unknown>;
}) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const isCompleted = project.data?.status.id === 29;
  const isLost = project.data?.status.id === 27;

  const { activate } = useAreYouSure();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { setModal } = useModalContext(),
    dimensions = useWindowDimensions();
  const editable = !isCompleted && !isLost;

  const canMarkAsLost =
    project.data?.status.id === 24 || project.data?.status.id === 25;

  const { showSnackbar } = useSnackbarContext();

  const payments = useQuery<TPayment[] | null>(
    ["payments", "project", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments?id=${id}&type=project
  `
        )
        .then((res) => res.data)
  );

  const markAsCompleted = useMutation(
    async (status: string) =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${id}/${status}
  `),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("project");
      },
    }
  );

  const markAsLost = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${id}/lost
  `),
    {
      onSuccess: () => {
        project.refetch();
        queryClient.invalidateQueries("project");
        queryClient.invalidateQueries("quotes");
        showSnackbar(tr("Project marked as Lost!"));
      },
    }
  );

  const deleteProject = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${id}/delete
  `),
    {
      onSuccess: () => {
        history.replace("/projects");
        showSnackbar(tr("Project is deleted!"));
      },
    }
  );

  const handleStatus = async (popper: any, status: string) => {
    await markAsCompleted.mutateAsync(status);
    popper.close();
    showSnackbar(
      status === "complete"
        ? tr("Project marked as Completed!")
        : tr("Project reverted to In Progress!")
    );
  };

  const handleDelete = (popper: any) => {
    popper.close();
    activate(() => deleteProject.mutate());
  };

  return (
    <div>
      <div className="flex justify-between py-4">
        <div className="flex flex-col flex-wrap">
          <BreadCrumbs link={[{ to: "/projects", label: tr("Projects") }]} />
          <h1>{tr("Project")} #{id}</h1>
          <h2>
            {project.data?.label}

            {project.data && (
              <Status
                style={{ marginLeft: "1rem" }}
                color={project.data.status.color}
              >
                {project.data.status.label}
              </Status>
            )}
          </h2>
        </div>

        <Popper
          container={(popper) => (
            <>
              {!isCompleted && !isLost && (
                <MenuButton
                  onClick={() =>
                    setModal({
                      component: <SubmitQuoteModal projectId={id} />,
                      label: tr("Submit Quote"),
                    })
                  }
                >
                  {tr("Send Quote")}
                </MenuButton>
              )}
              {!isCompleted ? (
                !isLost ? (
                  <MenuButton
                    isLoading={markAsCompleted.isLoading}
                    onClick={() => handleStatus(popper, "complete")}
                  >
                    {tr("Mark as Completed")}
                  </MenuButton>
                ) : null
              ) : (
                <MenuButton
                  isLoading={markAsCompleted.isLoading}
                  onClick={() => handleStatus(popper, "in_progress")}
                >
                  {tr("Revert to In Progress")}
                </MenuButton>
              )}
              {canMarkAsLost && (
                <MenuButton
                  onClick={() => {
                    popper.close();
                    activate(() => markAsLost.mutate(), {
                      message:
                        tr("Marking project as lost will change all the quotes in the project as lost. Are you sure you want to proceed?"),
                    });
                  }}
                >
                  {tr("Mark as Lost")}
                </MenuButton>
              )}

              <MenuButton
                isLoading={deleteProject.isLoading}
                onClick={() => handleDelete(popper)}
                style={{ color: "red" }}
              >
                {tr("Delete Project")}
              </MenuButton>
            </>
          )}
          reference={({ toggle }) => (
            <Button primary style={{ marginLeft: "auto" }} onClick={toggle}>
              {tr("Actions")} <RiArrowDownSLine />
            </Button>
          )}
        />
      </div>
      <Tab
        defaultTab="overview"
        data={{
          overview: {
            tab: tr("Overview"),
            content: (
              <div className="flex flex-col space-y-6 w-full">
                <Progress data={project.data} />
                {dimensions.width < 1200 && <ProjectInfo data={project.data} />}
                <Quote {...{ editable, id }} />
                <Estimates />
                <AppointmentCard type="projects" {...{ editable, id }} />
                <div className="space-y-4">
                  <div className="border-b py-4 flex justify-between items-center">
                    <h2>{tr("Payments")}</h2>
                    {/* <OutlineButton
                  style={{ marginLeft: "auto" }}
                  onClick={() => handleAddWorkOrder("estimates")}
                >
                  <RiAddFill />
                  New Payment
                </OutlineButton> */}
                  </div>
                  <div>
                    {/* {customerCredit.data !== 0 && (
                  <div className="bg-green-100 text-green-900 p-2 rounded flex justify-between">
                    <div className="flex flex-col">
                      <span className="text-xs">Customer Credit</span>
                      <span className="text-lg">
                        {dollarFormatter(customerCredit.data)}
                      </span>
                    </div>
                    <div>
                      <OutlineA
                        primary
                        className="!bg-transparent"
                        to={`/customers/${props.customer.id}`}
                      >
                        Apply To <RiArrowRightLine />
                      </OutlineA>
                    </div>
                  </div>
                )} */}
                    <PaymentsList data={payments.data} type="project" />
                  </div>
                </div>
                <InvoicesContainer type="project" id={id} />
              </div>
            ),
          },
          line_items: {
            tab: tr("Line Items"),
            content: <LineItemsContainer type="project" id={id} />,
          },
          production: {
            tab: tr("Production"),
            content: <ProductionContainer type="project" id={id} />,
          },
          warranty: {
            tab: tr("Warranty"),
            content: <WarrantyTab id={id} type="project" />,
          },
          notes: {
            tab: <div>{tr("Notes")} </div>,
            hide: dimensions.width >= 1200,
            content: <RequestNotes id={id} type="project" />,
          },
          reminders: {
            tab: tr("Reminders"),
            hide: dimensions.width >= 1200,
            content: <RequestReminders id={+id} type="project" />,
          },
          files: {
            tab: <div>{tr("Files")}</div>,
            hide: dimensions.width >= 1200,
            content: <RequestFilesTab id={id} type="project" />,
          },
          custom_fields: {
            tab: <div>{tr("Custom Fields")}</div>,
            hide: dimensions.width >= 1200,
            content: <CustomFieldsTab type="project" id={id} />,
          },
        }}
      />
    </div>
  );
}
