import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Con = styled.div`
    width:100%
`
const Form = styled.form`

> label {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding-left: 2rem;

    > input[type=text], select, textarea{
        width: 50%;
        padding: 12px;
        margin-right: 10rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
      }
}

> div {
    width: 100%;
    padding: 12px;
    margin-right: 10rem;
    box-sizing: border-box;
    resize: vertical;
    text-align: center;

    > button {
        width: 25%;
        padding: 10px;
        margin-right: 10px;
        margin-top: 15px;
    }
}
`

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
    const { t } = useTranslation();
    const tBase = "views.category_management.components.deletePopup";
    const tr = (key) => t(`${tBase}.${key}`);

    const { category_id, category_name, close_popup } = props;
    const deleteCategory = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category`,
                {
                    type: 'DELETECATEGORY',
                    category_id
                }
            );
        } catch (e) {
            console.error("Function deleteCategory", e);
        }
    }

    return (
        <Con>
            <Form>
                <label>
                    {tr("When you delete a category all the items associated will be unassigned and all sub-categories  will become root categories. Are you sure you want to delete")}{category_name}?
                </label>
                <div>
                    <button type="submit" onClick={() => { deleteCategory() }}>{tr("Yes")}</button>
                    <button type="button" onClick={() => { close_popup() }}>No</button>
                </div>
            </Form>
        </Con>
    )
}