/* Created by Hannah Green on 02/08/2021 for ES-133 */
import styled from "styled-components";
import Popup from "../../components/closablePopup";
import { lighten, makeStyles } from "@material-ui/core/styles";

export const SearchCon = styled.div`
  padding: 0.5% 1% 0.5% 1%;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: 0px 0px 10px -3px grey;
  z-index: 2;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const SearchBar = styled.input`
  width: 50%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  background: #f3f3f3;
  border: 0;
  border-radius: 3px;
`;

export const Con = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 95%;
  margin-left: 3rem;
  background: white;
  border: 1px, solid, black;
  border-radius: 10px;
  align-self: center;
  margin-top: 1rem;
  overflow: hidden;
`;
export const AddPopup = styled(Popup)`
  padding: 1rem;
  width: 25%;
  height: 50%;
`;
export const DeletePopup = styled(Popup)`
  padding: 1rem;
  width: 30%;
  height: 23%;
`;
export const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    padding: 10rem;
    margin: 15%;
    margin-left: 40%;
  }
`;

export const FormStyle = styled.form`
form {
    display: flex;
    flex-direction: column;
    padding: 15px;
}
`
export const DeleteStyle = styled.form`
label {
  text-align: center
}
div {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
`

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    marginLeft: "8rem",
  },
}));