import axios from "axios";
import Field from "components/field";
import SafeArea from "components/safe-area";
import Spinner from "components/spinner";
import { useTable } from "components/table";
import PaymentsList from "containers/payments";
import useQueryString from "hooks/useQueryString";
import * as React from "react";
import { useQuery } from "react-query";
import { TPayment } from "types/payment";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';

export default function Payments() {
  const { t } = useTranslation();
  const tBase = "views.payments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const search = useQueryString();

  const table = useTable({
    url: true,
    onPaginate: (page) => search.update({ ...search.search, page }),
    pageIndex:
      search.search.page && !Array.isArray(search.search.page)
        ? +search.search.page
        : 1,
  });

  const query = useQuery<{ payments: TPayment[] | null; count: number }>(
    ["payments", search.search],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments`,
          {
            params: search.search,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        table.setPageCount(Math.floor(data.count / 50) || 8);
      },
    }
  );
  // let usedPaymentIDs=query.data?.payments?.map((element)=>{return element.stripe_id})
  // console.log("usedPaymentIDs",usedPaymentIDs)
  // const displayData = query.data?.payments?.map((a)=>{ 
  //   if(usedPaymentIDs?.includes(a.stripe_id)){
  //     usedPaymentIDs = usedPaymentIDs?.filter(element=>element!==a.stripe_id)
  //     console.log("filtered usedPaymentIDs",usedPaymentIDs)
  //     return a}
  //     return null
  //   }).filter(element=>element!==null)
  return (
    <div className="divide-y">
      <SafeArea>
        <div className="py-4">
          <h1>{tr("Payments")}</h1>
          <Field
            onKeyDown={(e: any) => e.key === "Enter" && e.target.blur()}
            onBlur={(e) =>
              search.update({ ...search.search, search: e.target.value })
            }
          />
        </div>
      </SafeArea>
      <SafeArea>
        <div className="py-4">
          {query.isLoading ? (
            <Spinner />
          ) : query.data ? (
            <PaymentsList data={query.data.payments} type="all" table={table} />
          ) : (
            <Placeholder
              type="payment"
              header={tr("No payments yet")}
              label={tr("Payments will appear here when added.")}
            />
          )}
        </div>
      </SafeArea>
    </div>
  );
}
