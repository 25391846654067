import axios from "api/axios";
import Field from "components/field";
import { TSelectProps } from "components/react-selectv2";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { TDivision } from "types/division";
import ReactSelect from ".";

type Props = {
  value?: string | null; //{ value: string; label: string }
  // onChange: (e: { value: string; label: string }) => void;
} & Omit<TSelectProps, "value">;

export default function AppointmentType(props: Props) {
  let { value, ...rest } = props;
  const categories = useQuery<{ value: string; label: string; active: boolean }[] | null>(
    "appointment categories",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/categories
    `
        )
        .then((res) => res.data)
  );

  const activeCategories = categories.data?.filter((category) => category.active);

  const alphabetizedCategories = activeCategories?.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  let formatVal = props.value
    ? activeCategories?.find((category) => category.value === props.value)
    : null;

  return (
    <Field
      type="select"
      options={alphabetizedCategories}
      value={formatVal}
      {...rest}
    />
  );
}
