/* eslint-disable import/no-anonymous-default-export */
/* Created by Hannah Green on 01/22/2021 for ES-126 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import axios from "axios";
import { cloneDeep } from 'lodash';

//Components
import Popup from "components/closablePopup";
import Form from "./form";
import DeleteForm from "./deletePopup";

//Icons
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReorderIcon from "@material-ui/icons/Reorder";

//Table imports
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import { useTranslation } from 'react-i18next';

//Draggable
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    ResponderProvided,
    DraggableProvided,
    DroppableProvided,
    DraggableStateSnapshot
} from "react-beautiful-dnd";

const Spinner = require("react-spinkit");

const AddEditPopup = styled(Popup)`
  padding: 1rem;
  width: 35%;
  height: 45%;
`;
const DeletePopup = styled(Popup)`
  padding: 1rem;
  width: 35%;
  height: 17%;
`;

export default function (props) {
    const { t } = useTranslation();
    const tBase = "views.category_management.components.rootTable";
    const tr = (key) => t(`${tBase}.${key}`);
    
    const { classes, noResults } = props

    const useStickyState = (key = "sticky", initialState = null) => {
        const [state, setState] = useState(() => {
            let fixedState = 0;
            const storedState = localStorage.getItem(key);

            if (storedState !== null) {
                fixedState = JSON.parse(storedState);
            } else {
                fixedState = storedState;
            }

            return fixedState ?? initialState;
        });

        useEffect(() => {
            localStorage.setItem(key, state);
        }, [state]);

        const clearState = () => localStorage.removeItem(key);

        return [state, setState, clearState];
    };

    //State
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("category_id");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [categories, setCategories] = useState();

    const [isSomethingClicked, setIsSomethingClicked] = useState({
        type: null,
        rowCategoryId: null,
        rowName: null,
    });

    const fetchData = async () => {
        const cats = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category?type=GETALLROOTANDSUBCATEGORIES`
        );
        setCategories(cats.data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    //Table Header
    const headCells = [
        { id: "category_name", numeric: false, disablePadding: true, label: tr("Name") },
        { id: "parent_category", numeric: false, disablePadding: true, label: tr("Parent Category" )},
        { id: "active", numeric: false, disablePadding: true, label: tr("Active"), },
        { id: "edit_item", numeric: false, disablePadding: true, label: "" },
        { id: "delete_item", numeric: false, disablePadding: true, label: "" },
    ];

    //Header for the collapsed tables
    const collapseHeadCells = [
        { id: "reorder", numeric: false, disablePadding: true, lable: "" },
        { id: "category_name", numeric: false, disablePadding: true, label: tr("Name") },
        { id: "active", numeric: false, disablePadding: true, label: tr("Active") },
        { id: "edit_item", numeric: false, disablePadding: true, label: "" },
        { id: "delete_item", numeric: false, disablePadding: true, label: "" }
    ];

    try {

        if (categories.length > 0) {
            //Compare items in the cell to order by desc or asc
            const orderDescComparanator = (itm1, itm2, orderBy) => {
                if (itm1[orderBy] < itm2[orderBy]) {
                    return -1;
                }
                if (itm1[orderBy] > itm2[orderBy]) {
                    return 1;
                }
                return 0;
            };

            //Compare items in the cell and order them by by boolean
            const boolComparanator = (itm1, itm2, orderBy) => {
                if (itm1[orderBy] - itm2[orderBy]) {
                    return 1;
                }
                if (!itm1[orderBy] - itm2[orderBy]) {
                    return -1;
                }
                return 0;
            };

            //get the comparanator
            const getComparator = (order, orderBy) => {
                if (orderBy === "special_order" || orderBy === "bulk_order") {
                    return (itm1, itm2) => boolComparanator(itm1, itm2, orderBy);
                }

                return order === "desc"
                    ? (itm1, itm2) => -orderDescComparanator(itm1, itm2, orderBy)
                    : (itm1, itm2) => orderDescComparanator(itm1, itm2, orderBy);
            };

            //sort the cells
            const sort = (array, comparanator) => {

                const stabilizeThis = array.map((el, index) => [el, index]);

                stabilizeThis.sort((itm1, itm2) => {
                    const order = comparanator(itm1[0], itm2[0]);
                    if (order !== 0) {
                        return order;
                    }
                    return itm1[1] - itm2[1];
                });

                return stabilizeThis.map((el) => el[0]);
            };

            //sort request handler
            const handleRequestSort = (event, property) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
            };

            //Change page handler
            const handleChangePage = (event, newPage) => {
                setPage(newPage);
            };

            //change rows handler
            const handleChangeRowsPerPage = (event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
            };

            //Database call
            const changeOrder = async (categoryId, newOrder, oldOrder, parentCategoryId) => {
                try {

                    await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category`,
                        {
                            type: 'CHANGEORDER',
                            categoryId,
                            newOrder,
                            oldOrder,
                            parentCategoryId
                        }
                    );

                } catch (e) {
                    console.error(e);
                }
            }

            //Change the order of the subCategories
            const handleDragEnd = async (result, provided) => {

                if (!result.destination) {
                    return;
                }

                if (result.destination.index === result.source.index) {
                    return;
                }

                const categoryId = result.draggableId;
                const newOrder = result.destination.index;
                const oldOrder = result.source.index;
                const parentCategoryId = result.destination.droppableId

                //Switch the orders of the categories in the list
                setCategories((state) => {
                    const tempCategories = cloneDeep(state);

                    tempCategories.forEach((cat) => {

                        const replacedSubIndex = cat.sub_categories.findIndex((sub) => {
                            return sub.category_order === newOrder;
                        });

                        const movedSubIndex = cat.sub_categories.findIndex((sub) => {
                            return sub.category_id === parseInt(categoryId, 10);
                        });

                        if (movedSubIndex !== -1 && replacedSubIndex !== -1) {

                            cat.sub_categories[replacedSubIndex].category_order = oldOrder;
                            cat.sub_categories[movedSubIndex].category_order = newOrder;

                            cat.sub_categories.sort((a, b) => {
                                const num1 = a.category_order;
                                const num2 = b.category_order;

                                return num1 - num2;
                            });
                        }
                    });

                    return tempCategories;
                });

                await changeOrder(categoryId, newOrder, oldOrder, parentCategoryId);

            }

            //Collapsed table header
            const CollapseEnhancedTableHead = (props) => {
                const { classes, order, orderBy, onRequestSort, headCells } = props;
                const createSortHandler = (property) => (event) => {
                    onRequestSort(event, property);
                };

                return (
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                    padding={headCells.disablePadding ? "none" : "default"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "desc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === "desc"
                                                    ? tr("sorted descending")
                                                    : tr("sorted ascending")}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                );
            };

            //table header
            const EnhancedTableHead = (props) => {
                const { classes, order, orderBy, onRequestSort, headCells } = props;
                const createSortHandler = (property) => (event) => {
                    onRequestSort(event, property);
                };

                return (
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? "right" : "left"}
                                    padding={headCells.disablePadding ? "none" : "default"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : "desc"}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === "desc"
                                                    ? tr("sorted descending")
                                                    : tr("sorted ascending")}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                );
            };

            EnhancedTableHead.propTypes = {
                classes: PropTypes.object.isRequired,
                onRequestSort: PropTypes.func.isRequired,
                order: PropTypes.oneOf(["asc", "desc"]).isRequired,
                orderBy: PropTypes.string.isRequired,
                headCells: PropTypes.array.isRequired
            };

            //Find the third layer categories and add them to the main array
            const findLayeredCategories = () => {
                let subSubCategories = [];
                let pCategories = [];

                let rootCategories = categories.find((cat) => {
                    if (subSubCategories.length === 0) {
                        subSubCategories = cat.parent_categories;
                    }
                    return cat.parent_category_id === "root_categories"
                });

                if (subSubCategories.length > 0) {
                    pCategories = subSubCategories.concat(rootCategories.sub_categories);
                } else {
                    pCategories = rootCategories;
                }

                return pCategories;
            }

            //Find the name of the root category of a parent category
            const getParentCategoryName = (cat_id) => {
                const cats = parentCategories;
                if (cat_id !== null) {
                    const root_cat = cats.find((item) => {
                        return item.category_id === cat_id
                    });
                    return root_cat.category_name;
                } else {
                    return null;
                }
            }

            //A collapsable table row
            const CollapsableTableRow = (props) => {
                const { row, classes } = props;
                const [open, setOpen, clearOpen] = useStickyState(row.category_id.toString(), false);

                const hasSubCats = categories.find((cats) => {
                    const intCatId = parseInt(cats.parent_category_id, 10);
                    return intCatId === row.category_id
                });

                return (
                    <>
                        <TableRow hover tabIndex={-1} key={row.category_id}>
                            <TableCell align="left">
                                {hasSubCats && <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>}
                            </TableCell>
                            <TableCell align="left">{row.category_name}</TableCell>
                            <TableCell align="left">{getParentCategoryName(row.parent_category_id)}</TableCell>
                            <TableCell align="left">
                                {row.active ? <CheckIcon /> : <CloseIcon />}
                            </TableCell>
                            <TableCell align="right">
                                {
                                    <Tooltip title={tr("Edit")}>
                                        <IconButton
                                            aria-label="edit"
                                            onClick={() =>
                                                setIsSomethingClicked((state) => {
                                                    const tempIsSomethingClicked = {
                                                        ...state,
                                                    };
                                                    tempIsSomethingClicked.type =
                                                        "edit";
                                                    tempIsSomethingClicked.rowCategoryId =
                                                        row.category_id;
                                                    return tempIsSomethingClicked;
                                                })
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </TableCell>

                            <TableCell align="left">
                                <Tooltip title={tr("Delete")}>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            setIsSomethingClicked((state) => {
                                                const tempIsSomethingClicked = {
                                                    ...state,
                                                };
                                                tempIsSomethingClicked.type =
                                                    "delete";
                                                tempIsSomethingClicked.rowCategoryId =
                                                    row.category_id;
                                                tempIsSomethingClicked.rowName =
                                                    row.category_name;
                                                return tempIsSomethingClicked;
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>

                        {hasSubCats && <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Table size="small" aria-label="collapsible table">
                                            <CollapseEnhancedTableHead
                                                classes={classes}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={handleRequestSort}
                                                rowCount={hasSubCats.sub_categories.length}
                                                headCells={collapseHeadCells}
                                            />
                                            <DragDropContext onDragEnd={handleDragEnd}>
                                                <Droppable droppableId={row.category_id.toString()} direction="vertical">
                                                    {(DroppableProvided) => (
                                                        <TableBody
                                                            innerRef={DroppableProvided.innerRef}
                                                            {...DroppableProvided.droppableProps}
                                                        >

                                                            {hasSubCats.sub_categories.map((subCategoryRow, index) => (
                                                                <Draggable
                                                                    key={subCategoryRow.category_id}
                                                                    draggableId={subCategoryRow.category_id.toString()}
                                                                    index={parseInt(subCategoryRow.category_order, 10)}
                                                                >
                                                                    {(
                                                                        DraggableProvided,
                                                                        DraggableStateSnapshot
                                                                    ) => {
                                                                        return (
                                                                            <TableRow
                                                                                innerRef={DraggableProvided.innerRef}
                                                                                {...DraggableProvided.draggableProps}
                                                                                style={{
                                                                                    ...DraggableProvided.draggableProps.style,
                                                                                    background: DraggableStateSnapshot.isDragging
                                                                                        ? "rgba(245,245,245, 0.75)"
                                                                                        : "none"
                                                                                }}
                                                                            >
                                                                                <TableCell align="justify">
                                                                                    <div {...DraggableProvided.dragHandleProps}>
                                                                                        <ReorderIcon />
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="justify">{subCategoryRow.category_name}</TableCell>
                                                                                <TableCell align="justify">
                                                                                    {subCategoryRow.active ? <CheckIcon /> : <CloseIcon />}
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    {
                                                                                        <Tooltip title="Edit">
                                                                                            <IconButton
                                                                                                aria-label="edit"
                                                                                                onClick={() =>
                                                                                                    setIsSomethingClicked((state) => {
                                                                                                        const tempIsSomethingClicked = {
                                                                                                            ...state,
                                                                                                        };
                                                                                                        tempIsSomethingClicked.type =
                                                                                                            "edit";
                                                                                                        tempIsSomethingClicked.rowCategoryId =
                                                                                                            subCategoryRow.category_id;
                                                                                                        return tempIsSomethingClicked;
                                                                                                    })
                                                                                                }
                                                                                            >
                                                                                                <EditIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </TableCell>

                                                                                <TableCell align="left">
                                                                                    <Tooltip title={tr("Delete")}>
                                                                                        <IconButton
                                                                                            aria-label="delete"
                                                                                            onClick={() => {
                                                                                                setIsSomethingClicked((state) => {
                                                                                                    const tempIsSomethingClicked = {
                                                                                                        ...state,
                                                                                                    };
                                                                                                    tempIsSomethingClicked.type =
                                                                                                        "delete";
                                                                                                    tempIsSomethingClicked.rowCategoryId =
                                                                                                        subCategoryRow.category_id;
                                                                                                    tempIsSomethingClicked.rowName =
                                                                                                        subCategoryRow.category_name;
                                                                                                    return tempIsSomethingClicked;
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            ))}
                                                            {DroppableProvided.placeholder}
                                                        </TableBody>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>}

                    </>
                )
            }

            const emptyRows =
                rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage);

            const parentCategories = findLayeredCategories();

            return (
                <>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="medium"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={categories.length}
                                headCells={headCells}
                            />
                            {noResults && (
                                <TableBody>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell>{tr("No Results")}</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableBody>
                            )}
                            {!noResults && (
                                <TableBody>
                                    {sort(parentCategories, getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        ).map((row, index) => {
                                            return (
                                                <CollapsableTableRow row={row} classes={classes} />
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={5} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={categories.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    {isSomethingClicked.type === "edit" && (
                        <AddEditPopup
                            cancelDisplay={() => {
                                setIsSomethingClicked((state) => {
                                    const tempIsSomethingClicked = { ...state };
                                    tempIsSomethingClicked.type = null;
                                    tempIsSomethingClicked.rowCategoryId = null;
                                    return tempIsSomethingClicked;
                                });
                            }}
                        >
                            <Form formType="edit" categoryId={isSomethingClicked.rowCategoryId} closePopup={() => {
                                setIsSomethingClicked((state) => {
                                    const tempIsSomethingClicked = { ...state };
                                    tempIsSomethingClicked.type = null;
                                    tempIsSomethingClicked.rowCategoryId = null;
                                    return tempIsSomethingClicked;
                                });
                            }} />
                        </AddEditPopup>
                    )}
                    {isSomethingClicked.type === "delete" && (
                        <DeletePopup
                            cancelDisplay={() => {
                                setIsSomethingClicked((state) => {
                                    const tempIsSomethingClicked = { ...state };
                                    tempIsSomethingClicked.type = null;
                                    tempIsSomethingClicked.rowCategoryId = null;
                                    tempIsSomethingClicked.rowName = null;
                                    return tempIsSomethingClicked;
                                });
                            }}
                        >
                            <DeleteForm category_id={isSomethingClicked.rowCategoryId} category_name={isSomethingClicked.rowName} close_popup={() => {
                                setIsSomethingClicked((state) => {
                                    const tempIsSomethingClicked = { ...state };
                                    tempIsSomethingClicked.type = null;
                                    tempIsSomethingClicked.rowCategoryId = null;
                                    tempIsSomethingClicked.rowName = null;
                                    return tempIsSomethingClicked;
                                });
                            }} />
                        </DeletePopup>
                    )}
                </>
            )
        }

    } catch (e) {
        return (
            <>
                <TableContainer>
                    <Spinner name="wandering-cubes" color="grey" />
                </TableContainer>
            </>
        )
    }


}