import React from 'react';
import axios from 'axios';
import Spinner from 'components/spinner';
import { history } from 'routes';
import { useTranslation } from 'react-i18next';

const SubscriptionAdmin = () => {
    const { t } = useTranslation();
    const tBase = "views.subscription.subscriptionAdmin";
    const tr = (key: string) => t(`${tBase}.${key}`);

    const [subscriptions, setSubscriptions] = React.useState<any>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/`,
        {
            params: {
                type: "all_subscriptions"
            }
        })
        .then((res) => {
            setSubscriptions(res.data)
            setIsLoading(false)
            // console.log(res.data)
        })
    }, [])

    if (isLoading) 
        return <Spinner />

    // console.log(subscriptions)
    const handleClick = (sub:object) => {
        history.push(`/admin/subscription/admin/updateSubscription`, {subscription: sub})
    }

    const SubscriptionBoxStyle = {
        display:"inline-block",
        padding:"10px",
        backgroundColor:"lightgrey",
        borderRadius:"5px",
        border:"black 5px solid",
        cursor:"pointer",
    }

    const GridStyle = {
        display:"inline-grid",
        gridTemplateRows:"repeat(4, 30px)",
        gap:"10px",
        justifyItems:"start"
    }

    return (
        <div>                
            {   
                subscriptions.length > 0 ?
                    subscriptions.map((sub: any) => {
                        return (
                            <div>
                                <button style={SubscriptionBoxStyle} onClick={() => handleClick(sub)}>
                                    <h3 style={{textAlign:"center", borderBottom:"1px solid black", marginBottom:"25px"}}>{sub.business_name}</h3>
                                    <div style={{...GridStyle, marginRight:"10px"}}>
                                        <p>{tr("Plan")}: </p>
                                        <p>{tr("Most Recent Invoice")}: </p> 
                                        <p>{tr("Start Date")}: </p>
                                        <p>{tr("End Date")}: </p>
                                    </div>
                                    <div style={GridStyle}>
                                        <p>{sub.plan_name}</p>
                                        <p>{sub.most_recent_invoice}</p>
                                        <p>{new Date(sub.date_start).toLocaleDateString()}</p>
                                        <p>{new Date(sub.date_end).toLocaleDateString()}</p>
                                    </div>
                                </button>

                            </div>
                        )
                    })
                :
                    <h1>{tr("No Subscriptions Present")}</h1>
            }
            
        </div>
    )
}

export default SubscriptionAdmin