import axios from "axios";
import { Avatar } from "components/avatar";
import { Button, NoStyleButton, OutlineButton } from "components/button";
import Field from "components/field";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import TableComponent from "components/table";
import { format } from "date-fns";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TCommission } from "types/commission";
import { TCustomer } from "types/customer";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  userId: string;
};
export default function Commission(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.commission";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const modal = useModalContext();

  const query = useQuery<TCommission[] | null>(
    ["commission", props.userId],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/commissions/${props.userId}
  `
        )
        .then((res) => res.data)
  );

  const columns = {
    id: {
      label: "ID",
    },
    total: {
      label: tr("Commission Total"),
      cell: (row: TCommission) => dollarFormatter(row.total),
    },
    invoice_total: {
      label: tr("Total"),
      cell: (row: TCommission) => dollarFormatter(row.invoice_total),
    },
    created_by: {
      label: tr("Created By"),
      width: "100%",
      cell: (row: TCommission) => (
        <Avatar size="xsmall" showLabel user_id={row.created_by.id?.toString()}>
          {row.created_by.name}
        </Avatar>
      ),
    },
    timestamp: {
      label: tr("Created On"),
      cell: (row: TCommission) =>
        format(new Date(row.created_on), "MMM dd, yyyy HH:mm aa", {
          locale: locale,
        }),
    },
  };

  return (
    <div className="px-2">
      <div className="flex justify-between items-center">
        <h2>{tr("Commissions")}</h2>
        <Button
          primary
          onClick={() =>
            modal.setModal({
              component: <NewCommission userId={props.userId} />,
              label: tr("New Commission"),
            })
          }
        >
          {tr("Generate")}
        </Button>
      </div>
      <TableComponent
        data={query.data}
        columns={columns}
        to={(row) => `./${row.id}/`}
        type="a"
      />
    </div>
  );
}

const NewCommission = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.commission";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [selected, setSelected] = React.useState<{
    [key: string]: { id: string; total: number; percent_rate: number | null };
  }>({});

  const [defaultPercent, setPercent] = React.useState(0);

  const modal = useModalContext(),
    snackbar = useSnackbarContext();
  const queryClient = useQueryClient();

  const query = useQuery<
    | {
        id: string;
        customer: TCustomer;
        actual_total: number;
        creation_date: string;
        fk_request_id: string | null;
        fk_project_id: string | null;
      }[]
    | null
  >(
    ["invoices", "user", userId],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/commission?userId=${userId}
    `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/commissions?userId=${userId}
  `,
          selected
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        modal.setModal();
        snackbar.showSnackbar(tr("Commission created!"));
      },
    }
  );

  const populateInvoices = query.data?.map((invoice) => {
    const isSelected = selected[invoice.id];

    return (
      <NoStyleButton
        key={invoice.id}
        onClick={() =>
          setSelected((s) => {
            let temp = { ...s };

            if (temp[invoice.id]) {
              delete temp[invoice.id];
            } else {
              temp[invoice.id] = {
                id: invoice.id,
                total: invoice.actual_total,
                percent_rate: defaultPercent,
              };
            }

            return temp;
          })
        }
      >
        <div
          className={`border-2 rounded p-2 flex flex-col ${
            isSelected && "border-[#ff4f00]"
          }`}
        >
          <span>
            {tr("Invoice")} #{invoice.id} |{" "}
            {invoice.customer.name +
              (invoice.customer.company_name
                ? "(" + invoice.customer.company_name + ")"
                : "")}
          </span>
          <span>{dollarFormatter(invoice.actual_total)}</span>
          {isSelected && (
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Field
                  value={selected[invoice.id].percent_rate}
                  autoFocus
                  size="small"
                  className="!w-[100px]"
                  onFocus={(e) => e.target.select()}
                  onChange={(e: any) => {
                    let val = e.target.value;
                    setSelected((s) => ({
                      ...s,
                      [invoice.id]: {
                        ...s[invoice.id],
                        percent_rate: val,
                      },
                    }));
                  }}
                />
                %
              </div>
              <span className="t">
                {dollarFormatter(
                  invoice.actual_total *
                    ((selected[invoice.id].percent_rate || 0) / 100)
                )}
              </span>
            </div>
          )}
        </div>
      </NoStyleButton>
    );
  });

  return (
    <div className="space-y-4">
      <Field
        label={tr("Default Commission (%)")}
        value={defaultPercent}
        onChange={(e: any) => setPercent(e.target.value)}
      />
      <p>{tr("Select invoices")}</p>
      <div className="flex flex-col space-y-2">{populateInvoices}</div>
      <div>
        <h3>
          {tr("Total")}:
          {dollarFormatter(
            Object.entries(selected).reduce(
              (sum, curr) =>
                sum + ((curr[1].percent_rate || 0) / 100) * curr[1].total,
              0
            )
          )}
        </h3>
      </div>
      <div className="flex justify-end">
        <Button
          primary
          className="!w-full"
          isLoading={mutation.isLoading}
          onClick={() => mutation.mutate()}
        >
          {tr("Generate Commission")}
        </Button>
      </div>
    </div>
  );
};
