import React, { useContext } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { useDrag } from "react-dnd";
import { parseDuration } from "utils/duration";
import { NoStyleButton } from "components/button";
import { useLocationContext } from "../hooks/useLocationContext";
import { IAppointmentCard } from "../types/IAppointmentCard";
import ViewByTypeContext from "../context/ViewByTypeContext";
import {
  getContrastTextColorHEX,
  getContrastTextColorRGB,
} from "utils/getContrastTextColor";
import { DistanceIndicator } from "./DistanceIndicator";
import { MdBlock } from "react-icons/md";
import Tooltip from "components/tooltip";
import { useModalContext } from "components/modal";
import EditBlock from "../editBlock";
import { useTranslation } from 'react-i18next';

type Type = {
  id: string;
  company_id: string;
  label: string;
  color: string;
};

export const AppointmentCard: React.FC<IAppointmentCard> = ({
  id,
  note,
  status,
  address,
  latitude,
  longitude,
  start,
  end,
  duration,
  handleToolTip,
  toolTipModal,
  userId,
  date,
  name,
  appointment_category,
  attention = false,
  type,
  billed_to,
  canDrag = true,
  block_type,
}) => {
  const { t } = useTranslation();
  const tBase = "views.calendar.components.AppointmentCard";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { location, setLocation } = useLocationContext();
  const { viewByType } = useContext(ViewByTypeContext);
  const { setModal } = useModalContext();

  const startTime = start ? format(new Date(start), "h:mma") : "";
  const endTime = end ? format(new Date(end), "h:mm a") : "";
  const selected = toolTipModal?.id === id;

  // Query for appointment type
  const appointmentType = useQuery<Type[]>(
    "appointment type",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-types/`
        )
        .then((res) => {
          return res.data;
        })
  );

  // Defines typeColor after appointmentType query is complete
  let typeColor;
  if (appointmentType.data) {
    const typeData = appointmentType.data.find(
      (t) => t.label === appointment_category
    );
    if (typeData) {
      typeColor = typeData.color;
    }
  }

  // Conditionally changes border and background color based on type or status
  let background, border;
  if (viewByType && typeColor) {
    background = `${typeColor}10`; // 10% opacity
    border = typeColor;
  } else if (!viewByType && status) {
    background = `rgba(${status.color},0.1)`;
    border = `rgb(${status.color})`;
  } else {
    background = "rgba(0,0,0,0.1)";
    border = "black";
  }

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "BOX",
    item: { type: "BOX", id, date: date && new Date(date), prevUserId: userId },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    end: (item, monitor) => {},
    canDrag: canDrag,
  }));

  const partiallydate = () => {
    if (status && status.id === 32) {
      return date?.toString();
    } else {
      return "";
    }
  };

  const min = parseDuration(duration).mins;
  const hr = parseDuration(duration).hr;

  const appointmentTitleTextColor = (typeColor: any, status: any) => {
    if (viewByType && typeColor) {
      return "text-" + getContrastTextColorHEX(typeColor);
    } else if (!viewByType && status) {
      return "text-" + getContrastTextColorRGB(status.color);
    } else {
      return "text-white";
    }
  };

  const appointment = {
    id: id,
    note: note,
    status: status,
    address: address,
    latitude: latitude,
    longitude: longitude,
    start: start,
    end: end,
    duration: duration,
    handleToolTip: handleToolTip,
    toolTipModal: toolTipModal,
    userId: userId,
    date: date,
    name: name,
    appointment_category: appointment_category,
    attention: attention,
    type: type,
    billed_to: billed_to,
    canDrag: canDrag,
    block_type: block_type,
  };

  return (
    <NoStyleButton
      ref={drag}
      className="!w-full !mb-1 !overflow-hidden
      !flex !flex-col !rounded !text-left !items-stretch"
      onClick={() => {
        // console.log("Setting location" + latitude + " " + longitude);
        if (latitude && longitude) {
          setLocation({
            latitude: latitude,
            longitude: longitude,
          });
        }
        block_type === "block"
          ? setModal({
              component: <EditBlock block={appointment} />,
              label: "Edit Block",
            })
          : handleToolTip({ id });
      }}
      style={{
        border: `1px solid ${border}`,
        background,
        boxShadow: selected ? `0 0 10px 0 ${border}` : undefined,
      }}
    >
      <div
        className={`!text-[0.7rem] px-1 flex justify-between items-center ${appointmentTitleTextColor(
          typeColor,
          status
        )}`}
        style={{ background: border }}
      >
        <span
          className={`!whitespace-nowrap !overflow-hidden !text-ellipsis`}
        >{`${startTime || endTime}
          ${
            duration ? ` | ${hr}h${min === "00" ? "" : ` ${min}m`}` : ""
          }`}</span>
        <span>{partiallydate()}</span>
        <span>{type === "estimate" ? "Est" : tr("Job")}</span>
      </div>
      {(name || note || address) && (
        <div className="flex flex-col p-1 text-xs">
          <span className="font-bold truncate mb-[1px] text-[0.8rem]">
            {name}{" "}
            <span className="text-red-600"> {attention ? "!!!" : null}</span>
          </span>
          <span className="truncate text-slate-700">{note}</span>
          <p className="text-slate-700 ">{address}</p>

          <div
            style={{
              position: "relative",
              bottom: 0,
              right: 0,
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            {!latitude || !longitude ? (
              <MdBlock
                color="red"
                size={20}
                className="absolute bottom-0 right-0 z-10 rounded-full"
                // title="Coordinate Error: Invalid Latitude/Longitude"
                title={`${tr("Coordinate Error")}: ${tr("Invalid Latitude/Longitude")}`}
              />
            ) : (
              <>
                {!isNaN(Number(latitude)) && !isNaN(Number(longitude)) && (
                  <DistanceIndicator
                    tileLocation={{
                      latitude: Number(latitude),
                      longitude: Number(longitude),
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </NoStyleButton>
  );
};
