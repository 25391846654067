import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { IoIosClose } from "react-icons/io";
import axios from "axios";
import socket from "./socket";

const Con = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 0;
  margin: 0 1vw 0 1vw;
  border-radius: 5px;
  max-width: 400px;
  width: calc(100% - 1vw);
  font-size: 2rem;
  text-align: left;
  height: 150px;
  padding: 1vw;
  color: rgb(255, 255, 255, 0.7);
  background: rgb(0, 0, 0, 0.5);
  ${(props) =>
    props.background &&
    css`
      background: black;
    `}
`;

const Close = styled(IoIosClose)`
  position: absolute;
  right: 0.5rem;

  text-align: center;
  font-weight: 300;
  background: black;
  border-radius: 50%;
  padding: 0.1rem;
  top: 0.5rem;
  color: white;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  background: transparent;
  border: 0;
  margin-top: 1rem;
  width: 100%;
  font-size: 1.5rem;
  color: white;
  outline: none;
`;

const Char = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  font-size: 1rem;
`;

export default function (props) {
  let { close } = props;
  const textRef = useRef();
  const conRef = useRef();
  const [message, setMessage] = useState();
  const [isFocus, setFocus] = useState(false);
  // const [ position, setPosition ] = useState({x: 0, y: 0})
  // const [ isPressed, setPress ] = useState(false)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team/message`
      );
      setMessage(res.data ? res.data.message_description : "");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    socket.on("updatemessage", (res) => {
      setMessage(res);
    });

    return () => {
      socket.off("updatemessage");
    };
  }, []);

  const blurred = async () => {
    if (textRef.current !== message) {
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
          {
            type: "UPDATEMESSAGE",
            message: message,
          }
        );
        socket.emit("updatemessage", message);
      } catch (e) {
        console.error(e);
      }
    }
    setFocus(false);
  };

  return (
    <Con
      background={isFocus}
      ref={conRef}
      // // onMouseMove={mouseMove}
      // onMouseDown={() => mouseDown()}
      // onMouseUp={() => mouseUp()}
    >
      <TextArea
        spellCheck="false"
        onBlur={() => blurred()}
        onFocus={() => {
          textRef.current = message;
          setFocus(true);
        }}
        value={message}
        onChange={(e) =>
          e.target.value.length <= 100 && setMessage(e.target.value)
        }
      ></TextArea>
      <Char>{message ? message.length : "-"}/100</Char>
      <Close onClick={() => close()} />
    </Con>
  );
}

export const Button = () => {
  return <div>hi</div>;
};
