import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { __RouterContext as RouterContext } from "react-router";
import { ISetChecked } from "./interface";
import { LockIcon, HideIcon } from "images/icons/icons";
import { FaCheck } from "react-icons/fa";
import Phase1 from "./components/Phase1";
import Phase2 from "./components/Phase2";
import Phase3 from "./components/Phase3";
import CircularProgressBar from "./components/CircularProgressBar";
import wizard from "images/onboarding/wizard.png";
import { Button } from "components/button";
import Confetti from "react-confetti";
import { useAuthContext } from "contexts/auth";
import { useOnboardingWizard } from "contexts/onboarding-wizard";
import { useTranslation } from 'react-i18next';

const OnboardingWizard = () => {
  const { t, i18n } = useTranslation();
  const tBase = "features.onboarding-wizard";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const routerContext = useContext(RouterContext);
  const authContext = useAuthContext();
  const { iconHidden, setIconHidden } = useOnboardingWizard();

  const setCheckedObj: ISetChecked = {
    phase1: {
      divisions: false,
      teamInfo: false,
      questionBuilder: false,
      websiteAccess: false,
    },
    phase2: {
      customerList: false,
      inventoryList: false,
      vendorList: false,
      servicesList: false,
      termsAndConditions: false,
    },
    phase3: {
      qbSync: false,
      stripeConnection: false,
    },
  };

  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [lock, setLock] = useState(false);
  const [phase, setPhase] = useState<keyof ISetChecked>("phase1"); // [1, 2, 3]
  const [checked, setChecked] = useState<ISetChecked>(setCheckedObj);
  const [companyId, setCompanyId] = useState("");
  const [showWarning, setShowWarning] = useState(false); // Show warning if phase is not complete
  const [allPhasesComplete, setAllPhasesComplete] = useState(false);
  const [hoverHideIcon, setHoverHideIcon] = useState(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Initial check from /authStatus to see if the user is the owner and onboarding is not complete
  useEffect(() => {
    if (authContext && authContext.owner && !authContext.onboarding_complete) {
      setShowOnboardingWizard(true); // show wizard
      setupOnboardingWizard(); // setup onboarding wizard record in database
    }
  }, []);

  // If the wizard is shown, get the status of the onboarding wizard
  useEffect(() => {
    if (showOnboardingWizard) {
      getStatusOnboardingWizard();
    }
  }, [showOnboardingWizard]);

  // Reset the scroll position every time the phase changes
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [phase]);

  const isPhaseComplete = (phase: keyof ISetChecked) => {
    return Object.values(checked[phase]).every((status) => status === true);
  };

  const isPhase1Complete = isPhaseComplete("phase1");
  const isPhase2Complete = isPhaseComplete("phase2");
  const isPhase3Complete = isPhaseComplete("phase3");

  // Get phase number from phase string
  const phaseMatch = phase.match(/\d+/);
  const phaseNumber = phaseMatch ? phaseMatch[0] : "";

  const handleClickPhase = async (newPhase: keyof ISetChecked) => {
    // Hide warning initially
    setShowWarning(false);

    // Check if the user can access the new phase
    const canAccessPhase =
      newPhase === "phase1" ||
      (newPhase === "phase2" && isPhase1Complete) ||
      (newPhase === "phase3" && isPhase1Complete && isPhase2Complete);

    if (canAccessPhase) {
      setPhase(newPhase);
      await handlePhaseCompletion(phaseNumber);
    } else {
      // Show warning and hide it after 3 seconds
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 5000);
    }
  };

  // Update checked field in state and database
  const updateCheckedField = (
    phase: keyof ISetChecked,
    fieldName: string,
    value: boolean
  ) => {
    setChecked((prev) => ({
      ...prev,
      [phase]: {
        ...prev[phase],
        [fieldName]: value,
      },
    }));

    // DB update
    updateOnboardingWizard(fieldName, value);
  };

  // Handles mouse enter and leave for icon and content
  const handleMouseEnter1 = () => setShow(true);
  const handleMouseLeave1 = () =>
    setTimeout(() => !show2 && setShow(false), 100); // Delay to allow mouse to move between icon and content
  const handleMouseEnter2 = () => setShow2(true);
  const handleMouseLeave2 = () => setShow2(false);

  // Initial setup to create onboarding wizard record in database
  const setupOnboardingWizard = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/setup`
      );
      const { id } = res.data;
      setCompanyId(id); // Set company id to be used in Phase 1
    } catch (e) {
      console.error("Error setting up onboarding wizard", e);
    }
  };

  // Gets checked box true or false values from database
  const getStatusOnboardingWizard = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/status`
      );
      const cols = res.data;
      const checkedObj = {
        phase1: {
          divisions: cols.divisions,
          teamInfo: cols.team_info,
          questionBuilder: cols.question_builder,
          websiteAccess: cols.website_access,
        },
        phase2: {
          customerList: cols.customers_imported,
          inventoryList: cols.inventory_imported,
          vendorList: cols.vendors_added,
          servicesList: cols.services_imported,
          termsAndConditions: cols.terms_and_conditions,
        },
        phase3: {
          qbSync: cols.qb_sync,
          stripeConnection: cols.stripe_connect,
        },
      };
      setChecked(checkedObj);
    } catch (e) {
      console.error("Error getting onboarding wizard status", e);
    }
  };

  // Update onboarding wizard record in database
  const updateOnboardingWizard = async (fieldName: string, value: boolean) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/update`,
        {
          fieldName: fieldName,
          value: value,
        }
      );
    } catch (e) {
      console.error("Error updating onboarding status", e);
    }
  };

  // Updates eservbusiness.users col onboarding_complete to true when finished
  const updateUserOnboardingComplete = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/complete`
      );
    } catch (e) {
      console.error("Error updating onboarding status", e);
    }
  };

  // Send email to user after phase completion
  const sendEmailAfterPhaseCompletion = async (phaseNumber: string) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/send-email`,
        {
          phaseNum: phaseNumber,
        }
      );
      // console.log("Email sent!");
    } catch (e) {
      console.error("Error sending email", e);
    }
  };

  // Check if the current phase is complete and send email after completion (handled in the handleClickPhase()s)
  const handlePhaseCompletion = async (phaseNumber: string) => {
    // Determine if the current phase is complete
    const isCurrentPhaseComplete =
      phaseNumber === "1"
        ? isPhase1Complete
        : phaseNumber === "2"
        ? isPhase2Complete
        : isPhase3Complete;

    if (isCurrentPhaseComplete) {
      const { data: phaseCompleteStatus } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/is-phase-complete/${phaseNumber}`
      );

      // Check if the phase has not been marked complete in the DB
      if (!phaseCompleteStatus[`phase_${phaseNumber}_complete`]) {
        await sendEmailAfterPhaseCompletion(phaseNumber);
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/onboarding-wizard/phase-complete/${phaseNumber}`
        );
      }
    }
  };

  // Check for phase completion to show button for wizard dismissal
  useEffect(() => {
    const allComplete =
      isPhase1Complete && isPhase2Complete && isPhase3Complete;
    setAllPhasesComplete(allComplete);
  }, [isPhase1Complete, isPhase2Complete, isPhase3Complete]);

  const handleContinue = () => {
    setShowOnboardingWizard(false);
    updateUserOnboardingComplete();
    handlePhaseCompletion(phaseNumber);
  };

  // Progress bar percentage
  const calculateProgress = (
    phaseTasks: { [s: string]: unknown } | ArrayLike<unknown>
  ) => {
    const totalTasks = Object.keys(phaseTasks).length;
    const completedTasks = Object.values(phaseTasks).filter(
      (status) => status
    ).length;
    return (completedTasks / totalTasks) * 100;
  };

  const progressPhase1 = calculateProgress(checked.phase1);
  const progressPhase2 = calculateProgress(checked.phase2);
  const progressPhase3 = calculateProgress(checked.phase3);

  const progress =
    phase === "phase1"
      ? progressPhase1
      : phase === "phase2"
      ? progressPhase2
      : progressPhase3;

  // Phase sidebar styles
  const active =
    "col-span-1 text-center bg-blue-100 cursor-pointer shadow-[inset_0_0_5px] shadow-blue-300 hover:shadow-blue-600 active:shadow-blue-800 select-none";
  const inactive =
    "col-span-1 text-center bg-gray-100 cursor-pointer shadow-[inset_0_0_5px] shadow-gray-300 hover:shadow-gray-600 active:shadow-gray-800 select-none";
  const incomplete =
    "col-span-1 text-center bg-gray-100 cursor-pointer shadow-[inset_0_0_5px] shadow-gray-300 hover:shadow-gray-600 active:shadow-red-800 select-none";

  // Check if router is initialized before rendering to avoid OnboardingWizard rendering before the router is initialized
  if (!routerContext) return null;

  // Hide onboarding wizard if user is not owner or onboarding is complete
  if (!showOnboardingWizard) return null;

  if (iconHidden) return null;

  const ES = i18n.language === "es" ? true : false;

  return (
    <div>
      {/* Icon */}
      <div className="fixed bottom-7 right-7 z-[20] select-none">
        <div
          onMouseOver={handleMouseEnter1}
          onMouseLeave={handleMouseLeave1}
          onClick={() => setLock(!lock)}
        >
          <div
            className={`relative bg-white border rounded-full  cursor-pointer p-0.5 
             shadow-[0_0_10px_#1e90ff] hover:shadow-[0_0_15px_#1e90ff]
             ${progress === 100 ? "animate-glow" : ""}
             `}
          >
            <img src={wizard} alt="" className="h-[90px] rounded-full" />
            {/* <CircularProgressBar progress={90} /> */}
            <CircularProgressBar progress={progress} />
          </div>
        </div>
      </div>
      {/* Content */}
      <div
        className={`
        ${show || show2 || lock ? "block" : "hidden"} 
        fixed bottom-[66px] right-[66px] z-[10] shadow-lg
      `}
      >
        <div
          className="relative bg-white h-[500px] w-[407px]  border rounded-md"
          onMouseEnter={handleMouseEnter2}
          onMouseLeave={handleMouseLeave2}
        >
          <div
            className="w-[407px] h-full overflow-auto"
            ref={scrollContainerRef}
          >
            {/* Header */}
            <div className="fixed z-10 w-[389px]">
              <div className="flex justify-between pt-2 pl-4 bg-gray-100 border-b border-gray-200 shadow-md rounded-tl-md items-top">
                <div className={`flex items-center font-bold ${ES ? " text-[18px]" : "text-xl"}`}>
                  <div className="text-primary">{tr("EServ Onboarding")}</div>
                  <div className="text-gray-600">
                    &nbsp;- {tr("Phase")} {phaseNumber}
                  </div>
                  <div className="">
                    {progress === 100 ? (
                      <FaCheck className={`ml-2 text-primary`} />
                    ) : (
                      <div className="w-7" />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIconHidden(!iconHidden);
                    setHoverHideIcon(false);
                  }}
                  className="cursor-pointer -scale-x-100"
                  onMouseOver={() => setHoverHideIcon(true)}
                  onMouseLeave={() => setHoverHideIcon(false)}
                >
                  <HideIcon strikeColor={hoverHideIcon ? "#ff4f00" : "#000"} />
                </div>
                <div
                  onClick={() => setLock(!lock)}
                  className="cursor-pointer -scale-x-100"
                >
                  <LockIcon
                    classNameTop={`origin-bottom-left ${
                      lock ? "animate-lock" : "animate-unlock"
                    }`}
                    colorTop={lock ? "#ff4f00" : "#9b9b9b"}
                  />
                </div>
              </div>

              {/* Dismiss button */}
              {allPhasesComplete && (
                <div className="p-4 bg-gray-100">
                  <Confetti width={407} numberOfPieces={300} recycle={false} />
                  <div className="my-1 text-xl">{tr("Congratulations!")}</div>
                  <div className="text-sm">
                    {tr("You've successfully completed your onboarding.")}
                  </div>
                  <div className="flex justify-end mt-2">
                    <Button primary onClick={handleContinue}>
                      {tr("Continue")}
                    </Button>
                  </div>
                </div>
              )}

              {/* Warning Message */}
              {showWarning && (
                <div className="pl-4 text-sm bg-gray-100 text-red-500 -mr-0.5 border-b">
                  {tr("Please complete the current phase before advancing.")}
                </div>
              )}
            </div>

            {/* Phases */}
            <div className="p-4">
              <Phase1
                className={`${phase === "phase1" ? "block" : "hidden"}`}
                {...{
                  updateCheckedField,
                  checked,
                  handleClickPhase,
                  isPhase1Complete,
                  companyId,
                }}
              />
              <Phase2
                className={`${phase === "phase2" ? "block" : "hidden"}`}
                {...{
                  updateCheckedField,
                  checked,
                  handleClickPhase,
                  isPhase2Complete,
                }}
              />
              <Phase3
                className={`${phase === "phase3" ? "block" : "hidden"}`}
                {...{ updateCheckedField, checked }}
              />
            </div>
          </div>

          {/* Sidebar */}
          <div className="absolute bg-gray-100 top-0 left-[-22px] h-[25px] origin-top-left -rotate-90 w-[500.5px]  translate-y-[500px] z-40 border rounded-t-md">
            <div className="grid items-center h-full grid-cols-3">
              <div
                className={
                  (phase === "phase1" ? active : inactive) + " order-3"
                }
                onClick={() => handleClickPhase("phase1")}
              >
                {tr("Phase")} 1
              </div>
              <div
                className={
                  (phase === "phase2"
                    ? active
                    : !isPhase1Complete
                    ? incomplete
                    : inactive) + " order-2"
                }
                onClick={() => handleClickPhase("phase2")}
              >
                {tr("Phase")} 2
              </div>
              <div
                className={
                  (phase === "phase3"
                    ? active
                    : !isPhase2Complete
                    ? incomplete
                    : inactive) + " order-1"
                }
                onClick={() => handleClickPhase("phase3")}
              >
                {tr("Phase")} 3
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWizard;
