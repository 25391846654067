import * as React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

type Props = {
  reference: React.ReactElement;
  clickable?: boolean;
  children: React.ReactNode;
  close?: (e: any) => void;
  style?: object;
  placement?:
    | "bottom-start"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
};
export default function Tooltip({
  reference,
  clickable,
  children,
  close,
  style,
  placement,
}: Props) {
  const [status, setStatus] = React.useState(false);
  const [referenceElement, setReferenceElement] =
    React.useState<Element | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: "computeStyles",
          options: {
            adaptive: false,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 5],
          },
        },
      ],
      placement: placement || "bottom-start",
    }
  );
  close = () => {
    setStatus(false);
  };

  const handleMouseEnter = () => {
    update && update();
    setStatus(true);
  };

  const handleMouseLeave = () => {
    setStatus(false);
  };

  React.useEffect(() => {
    if (clickable) {
      update && update();
      const handleClick = (e: any) => {
        if (
          !referenceElement?.contains(e.target) &&
          !popperElement?.contains(e.target)
        ) {
          setStatus(false);
        }
      };
      if (status) {
        window.addEventListener("mousedown", handleClick);
        return () => {
          window.removeEventListener("mousedown", handleClick);
        };
      }
    }
  }, [status, update, clickable]);

  const trigger = React.cloneElement(reference, {
    ref: setReferenceElement,
    onClick: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      clickable && setStatus((s) => !s);
    },
    // onMouseEnter: handleMouseEnter,
    // onMouseLeave: handleMouseLeave,
  });

  const disable = () => {
    setStatus(false);
  };

  return (
    <Container {...{ style, clickable, handleMouseEnter, handleMouseLeave }}>
      {trigger}
      {ReactDOM.createPortal(
        <Con
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <CSSTransition
            in={status}
            timeout={100}
            classNames="alert"
            unmountOnExit
          >
            <Popper>
              {typeof children === "function"
                ? children({ disable })
                : children}
            </Popper>
          </CSSTransition>
        </Con>,
        document.querySelector("#popper")!
      )}
    </Container>
  );
}

type ContainerProps = {
  clickable?: boolean;
  style?: object;
  children: React.ReactNode;
  handleMouseEnter: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleMouseLeave: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};
const Container = ({
  clickable,
  children,
  style,
  handleMouseEnter,
  handleMouseLeave,
}: ContainerProps) => {
  if (clickable) {
    return <>{children}</>;
  }

  return (
    <div
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

const Con = styled.div`
  z-index: 999;
`;

const Popper = styled.div`
  background: white;
  overflow: hidden;
  // border: 1px solid #ebedef;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 20px -5px grey;
  overflow: auto;
  max-height: 70vh;
  min-width: 100px;
  display: flex;
  flex-direction: column;

  &.alert-enter {
    opacity: 0;
    // transform: translateY(-10%);
  }
  &.alert-enter-active {
    opacity: 1;
    // transform: translateY(0);
    transition: all 0.1s ease-out;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    // transform: translateY(10%);
    transition: all 0.1s ease-out;
  }
`;
