import { UploadProvider } from "./context/UploadContext";
import Header from "./components/Header";
import Main from "./components/Main";
import ViewImports from "./components/ViewImports";
import { useTranslation } from 'react-i18next';

const UploadIndex = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <UploadProvider>
      <div className="p-4 w-full h-full">
        <h1>{tr("Import")}</h1>
        <div className="flex h-[calc(100%-32px)] flex-col relative">
          <Header />
          <ViewImports />
          <Main />
        </div>
      </div>
    </UploadProvider>
  );
};

export default UploadIndex;
