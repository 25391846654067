import { ILocation } from "../types/ILocation";
import { haversineDistance } from "./calculateDistance";

const locationsToDistances = (locations: ILocation[]): number[] => {
	const distances = [];
	for (let i = 0; i < locations.length - 1; i++) {
		const location1 = locations[i];
		const location2 = locations[i + 1];
		const distance = haversineDistance(location1, location2);
		distances.push(distance);
	}
	return distances;
};

export { locationsToDistances };
