import axios from "api/axios";
import { Button, IconButton, OutlineButton } from "components/button";
import FlatContainer from "components/container/flat";
import Field from "components/field";
import Form from "components/form2";
import { Input } from "components/input";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import {
  RiCamera2Fill,
  RiDeleteBin2Fill,
  RiDeleteBin3Fill,
  RiDeleteBinFill,
  RiEyeFill,
  RiEyeOffFill,
} from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TDivisionCustomField } from "types/custom-field";
import { priceType } from "dictionary/price_type";
import BreadCrumbs from "components/breadcrumbs";
import * as Yup from "yup";
import { useAreYouSure } from "components/areYouSure";
import { useHistory } from "react-router-dom";
import { productionType } from "dictionary/production_type";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';

type TDivision = {
  id: string;
  label: string;
  price_type: string;
  custom_fields: TDivisionCustomField[];
  show_production: boolean;
  production: "estimate" | "install" | "invoice";
};

export default function Division() {
  const { id } = useParams<{ id: string }>(),
    { showSnackbar } = useSnackbarContext(),
    { activate } = useAreYouSure(),
    history = useHistory();

  const { t } = useTranslation();
  const tBase = "views.admin.categories.category";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<TDivision>(
    ["division", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/divisions/${id}
  `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async (e: Omit<TDivision, "id">) =>
      await axios
        .post(
          `
${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/divisions/${id}
`,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        showSnackbar(tr("Division updated!"));
        if (id === "new") history.push(`/admin/divisions/${data}`);
        else query.refetch();
      },
    }
  );

  const deleteDivision = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/divisions/${id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Division updated!"));
        history.push(`/admin/divisions`);
      },
    }
  );

  const initialValues = {
    price_type: query.data?.price_type || "install",
    label: query.data?.label || "",
    custom_fields: query.data?.custom_fields || [],
    production: query.data?.production || "estimate",
    show_production: query.data?.show_production ?? true,
  };

  const formik = useFormik<Omit<TDivision, "id">>({
    initialValues,
    onSubmit: (e) => mutation.mutate(e),
    enableReinitialize: true,
    validationSchema: Yup.object({
      label: Yup.string().required(),
    }),
  });

  if (query.isLoading) return <Spinner />;

  const populateCustomFields = formik.values.custom_fields?.map((cf, i) => {
    const handleChange = () => {
      formik.setValues((v) => {
        let temp = cloneDeep(v);
        temp.custom_fields[i].viewable = false;
        temp.custom_fields[i].selected = !temp.custom_fields[i].selected;
        return temp;
      });
    };
    return (
      <React.Fragment key={cf.id}>
        <Input
          type="checkbox"
          checked={cf.selected}
          onChange={handleChange}
          name={`custom_fields[${i}].selected`}
          formik={formik}
        />
        <span style={{ flex: "30%" }}>{cf.custom_key}</span>
        <Input
          style={{ flex: "70%" }}
          // value={cf.custom_value}
          disabled={!cf.selected}
          formik={formik}
          name={`custom_fields[${i}].custom_value`}
        />
        <IconButton
          disabled={!cf.selected}
          style={{
            background: cf.viewable ? theme.bg.blue : theme.bg.hoverblue,
            color: cf.viewable ? "black" : "grey",
            justifySelf: "center",
          }}
          onClick={() =>
            formik.setValues((v) => {
              let temp = cloneDeep(v);
              temp.custom_fields[i].viewable = !temp.custom_fields[i].viewable;
              return temp;
            })
          }
        >
          {cf.viewable ? <RiEyeFill /> : <RiEyeOffFill />}
        </IconButton>
      </React.Fragment>
    );
  });
  return (
    <SCon>
      <BreadCrumbs link={[{ label: tr("Divisions"), to: "/admin/divisions" }]} />
      <form onSubmit={formik.handleSubmit}>
        <FlatContainer
          title={
            <Input
              name="label"
              required
              label={tr("Division Name")}
              style={{ maxWidth: "500px" }}
              formik={formik}
              autoFocus={id === "new"}
            />
          }
          buttons={[
            id !== "new" && (
              <OutlineButton
                onClick={() => activate(deleteDivision.mutate)}
                primary
                style={{ color: "red", border: "2px solid red" }}
              >
                <RiDeleteBinFill /> {tr("Delete")}
              </OutlineButton>
            ),
          ]}
        />

        <FlatContainer title={tr("Settings")}>
          <div className="space-y-2">
            <div
              style={{
                display: "flex",
              }}
            >
              <span
                style={{
                  width: "50%",
                  textAlign: "right",
                  alignSelf: "center",
                  marginRight: "1rem",
                }}
              >
                {tr("Price Type")}
              </span>
              <Field
                name="production"
                value={priceType.find(
                  (p) => p.value === formik.values.price_type
                )}
                onChange={(e: any) =>
                  formik.setValues((v) => ({ ...v, price_type: e.value }))
                }
                formik={formik}
                style={{ width: "50%" }}
                type="select"
                options={priceType}
              />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <span
                style={{
                  width: "50%",
                  textAlign: "right",
                  alignSelf: "center",
                  marginRight: "1rem",
                }}
              >
                {tr("Production Based On")}
              </span>
              <Field
                name="production_type"
                value={productionType.find(
                  (p) => p.value === formik.values.production
                )}
                onChange={(e: any) =>
                  formik.setValues((v) => ({ ...v, production: e.value }))
                }
                formik={formik}
                style={{ width: "50%" }}
                type="select"
                options={productionType}
              />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <span
                style={{
                  width: "50%",
                  textAlign: "right",
                  alignSelf: "center",
                  marginRight: "1rem",
                }}
              >
                {tr("Show Production")}
              </span>
              <Switch
                checked={formik.values.show_production}
                onChange={(e) => formik.setFieldValue("show_production", e)}
              />
            </div>
          </div>
        </FlatContainer>
        <FlatContainer title={tr("Custom Fields")}>
          <SGridCon>
            <div />
            <span>{tr("Key")}</span>
            <span>{tr("Default Value")}</span>
            <span style={{ justifySelf: "center" }}>{tr("Viewable by customer")}</span>
            {populateCustomFields}
          </SGridCon>

          <Button
            type="submit"
            primary
            disabled={!formik.dirty || !formik.isValid}
            isLoading={mutation.isLoading}
          >
            {id === "new" ? tr("Create Division") : tr("Save Changes")}
          </Button>
        </FlatContainer>
      </form>
    </SCon>
  );
}

const SGridCon = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 2fr 3fr 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
`;

const SCon = styled.div``;

const SCustomCon = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;
