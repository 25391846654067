import styled from "styled-components";
import { theme } from "styles/theme";

export const Popper = styled.div`
  background: white;
  overflow: auto;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 20px -5px grey;
  // overflow: auto;
  max-height: 30vh;
  min-width: 250px;
  display: flex;
  flex-direction: column;

  &.alert-enter {
    opacity: 0;
    transform: translateY(-10%);
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.1s ease-out;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transform: translateY(10%);
    transition: all 0.2s ease-out;
  }
`;

export const PopperNoWidth = styled.div`
  margin-right: 2px;
  background: white;
  overflow: auto;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 20px -5px grey;
  // overflow: auto;
  max-height: 30vh;

  display: flex;
  flex-direction: column;

  &.alert-enter {
    opacity: 0;
    transform: translateY(-10%);
  }
  &.alert-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.1s ease-out;
  }
  &.alert-exit {
    opacity: 1;
  }
  &.alert-exit-active {
    opacity: 0;
    transform: translateY(10%);
    transition: all 0.2s ease-out;
  }
`;

export const Con = styled.div`
  z-index: 999;
`;
