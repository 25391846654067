import * as React from "react";
import { theme } from "styles/theme";
import { Button } from ".";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode;
};

export default function GroupedButton(props: Props) {
  const count = React.Children.count(props.children);
  const children = React.Children.map(props.children, (child, i) => {
    const isFirst = i === 0;
    const isLast = i === count - 1;

    const borderTopLeftRadius = isFirst ? "6px" : "0";
    const borderBottomLeftRadius = isFirst ? "6px" : "0";
    const borderTopRightRadius = isLast ? "6px" : "0";
    const borderBottomRightRadius = isLast ? "6px" : "0";

    // const borderRight = isLast ? `1px solid ${theme.border.light}` : "0";

    const borderLeft = isLast && "0";
    return (
      React.isValidElement(child) &&
      React.cloneElement(child, {
        ...child.props,
        style: {
          ...child.props.style,
          borderRadius: 0,
          borderTopLeftRadius,
          borderBottomLeftRadius,
          borderTopRightRadius,
          borderBottomRightRadius,
          borderLeft,
          // borderRight,
        },
      })
    );
  });

  return <div {...props}>{children}</div>;
}
