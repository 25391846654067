import axios from "api/axios";
import BreadCrumbs from "components/breadcrumbs";
import { Button } from "components/button";
import Dropdown from "components/dropdown";
import { Input } from "components/input";
import { useSnackbarContext } from "components/snackbar";
import LineItemsContainer from "containers/lineItems";
import * as React from "react";
import { RiArrowDownSFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {};

export default function QuoteTemplate(props: Props) {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { showSnackbar } = useSnackbarContext();
  const { t } = useTranslation();
  const tBase = "views.admin.quote-template.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [value, setValue] = React.useState("");

  const query = useQuery<string>(
    ["query template", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template/${id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setValue(data);
      },
    }
  );

  const mutation = useMutation(
    async (e: { label: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template/${id}
  `,
          e
        )
        .then((res) => res.data),
    {
      onError: () => {
        query.refetch();
      },
    }
  );

  const handleDelete = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template/${id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        history.push("/admin/quote-template");
        showSnackbar(tr("Template deleted!"));
      },
    }
  );

  const handleChange = (e: any) => {
    setValue(e.target.value);
    mutation.mutate({ label: e.target.value });
  };

  return (
    <Con>
      <BreadCrumbs
        link={[{ label: tr("Quote Template"), to: "/admin/quote-template" }]}
      />
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={value}
          label={tr("Label")}
          style={{ width: "500px" }}
          onChange={handleChange}
        />
        <Dropdown
          reference={
            <Button>
              {tr("Action")} <RiArrowDownSFill />
            </Button>
          }
          data={[
            {
              button: [
                {
                  label: tr("Delete Template"),
                  onClick: () => handleDelete.mutate(),
                },
              ],
            },
          ]}
        />
      </div>
      <LineItemsContainer
        style={{ padding: 0, marginTop: "1rem" }}
        id={id}
        type={"template"}
      />
    </Con>
  );
}

const Con = styled.div``;
