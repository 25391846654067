import { useContext } from "react";
import { VendorContext } from "contexts/VendorContext";
import VendorDetails from "./VendorDetails/VendorDetails";
import VendorItems from "./VendorItems/VendorItems";
import VendorPriceStructure from "./VendorPriceStructure/VendorPriceStructure";
import { useTranslation } from 'react-i18next';

import styles from "./VendorBody.module.scss";

const VendorBody = ({ refetch }: any) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorBody";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { currentVendor } = useContext(VendorContext);

  if (!currentVendor)
    return (
      <div className={styles.noVendorMessage}>
        {tr("Choose a vendor from the list.")}
      </div>
    );

  return (
    <div className={styles.container}>
      <VendorDetails vendor={currentVendor} refetch={refetch} />
      <div className={styles.priceItemContainer}>
        {/* <VendorPriceStructure /> */}
        <VendorItems />
      </div>
    </div>
  );
};

export default VendorBody;
