export const parseDuration = (
  min: number | null | undefined
): { fullStr: string; hr: string; mins: string } => {
  const hr = min
    ? Math.floor(min / 60) < 10
      ? "0" + Math.floor(min / 60)
      : Math.floor(min / 60)
    : "";

  const mins = min
    ? Math.floor(min % 60) < 10
      ? "0" + Math.floor(min % 60)
      : Math.floor(min % 60)
    : "";

  return {
    fullStr: hr.toString() + mins.toString(),
    hr: hr.toString(),
    mins: mins.toString(),
  };
};
