/* Created by Hannah Green for ES-169 on 6/9/2021 */
import RawForm from "components/form2";
import styled from "styled-components";
const { Button } = require("components/button");

export const Con = styled.div`
  @media only screen and (max-width: 1000px) {
    align-items: center;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Form = styled(RawForm)`
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  /*  @media only screen and (max-width: 1000px) {
        width: 100%;
    } */
  width: 15%;
  margin-bottom: 2rem;
`;

export const Group = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

export const Header = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
`;

export const Asterisk = styled.span`
  color: red;
`;

export const CheckGroup = styled.div`
  font-weight: bold;
  font-size: 1em;
  margin: 1em;

  input[type="radio"] {
    transform: scale(1.3);
  }

  > div {
    font-size: 0.9em;
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: column;

      > label {
        padding-top: 5px;
        margin-right: 5px;
      }

      > span {
        margin-top: 5px;
        margin-right: 5px;
      }

      >label: last-child {
        margin-right: 0;
      }
    }
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem;
`;

export const Info = styled.div`
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  > div {
    @media only screen and (max-width: 1000px) {
      margin-right: 0;
      width: 100%;
    }

    width: 40%;
    margin-right: 15px;
  }

  >div: last-child {
    margin-right: 0;
  }
`;

export const Tire = styled.div`
  @media only screen and (max-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
    align-items: flex-end;

    > div {
      margin: 2rem;
      flex: 0 0 33.3333%;
    }
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: space-around;
  justify-content: space-between;
  align-items: flex-end;

  > div {
    margin: 1em;
  }
`;

export const Area = styled.div`
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
  width: 100%;
  margin-bottom: 2rem;
`;
