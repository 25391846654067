import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatTimeHAM } from "utils/formatTimeHAM";
import { useTranslation } from 'react-i18next';

const AppointmentStatuses = (props: {appointmentId: number}) => {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCardMini.components.AppointmentStatuses";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const appointmentId = props.appointmentId;
  const [onTheWayTime, setOnTheWayTime] = useState("");
  const [inProgressTime, setInProgressTime] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const summarizeStatusById = (statusId: number, data: any) => {
    let arrTemp = [];

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (
        element.type === "appointment-status" &&
        (parseInt(element.previous_value) === statusId ||
          parseInt(element.new_value) === statusId)
      ) {
        arrTemp.push(element);
      }
    }

    arrTemp = arrTemp.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    let onTheWaySum = 0;

    if (arrTemp.length === 0) {
      onTheWaySum = 0;
    } else if (arrTemp.length % 2 !== 0) {
      onTheWaySum += new Date().getTime() - new Date(arrTemp[0].timestamp).getTime();
      if (arrTemp.length > 1) {
        for (let i = 1; i < arrTemp.length; i += 2) {
          onTheWaySum +=
            new Date(arrTemp[i].timestamp).getTime() - new Date(arrTemp[i + 1].timestamp).getTime();
        }
      }
    } else {
      if (arrTemp.length > 1) {
        for (let i = 0; i < arrTemp.length; i += 2) {
          onTheWaySum +=
            new Date(arrTemp[i].timestamp).getTime() - new Date(arrTemp[i + 1].timestamp).getTime();
        }
      }
    }

    return onTheWaySum / 60000;
  };

  const fetchAppointmentStatuses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/statuses/${appointmentId}`);
      const onTheWayMinutes = Math.floor(summarizeStatusById(31, response.data));
      const inProgressMinutes = Math.floor(summarizeStatusById(13, response.data));

      setOnTheWayTime(formatTimeHAM(onTheWayMinutes));
      setInProgressTime(formatTimeHAM(inProgressMinutes));
      setTotalTime(formatTimeHAM(onTheWayMinutes + inProgressMinutes));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAppointmentStatuses();
  }, []);

  return (
    <div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2">{tr("Status Summary")}</div>
        <div className="col-span-1">Total</div>
        <div className="rounded flex text-white flex-col p-2 bg-[#9c9900]">
          <span className="text-xs">{tr("ON THE WAY")}</span>
          <span className="leading-none">{onTheWayTime}</span>
        </div>
        <div className="rounded flex text-white flex-col p-2 bg-[#2371a3]">
          <span className="text-xs">{tr("IN PROGRESS")}</span>
          <span className="leading-none">{inProgressTime}</span>
        </div>
        <div className="rounded flex text-white flex-col p-2 bg-primary opacity-90">
          <span className="text-xs">{tr("TOTAL TIME")}</span>
          <span className="leading-none">{totalTime}</span>
        </div>
      </div>
    </div>
  );
};

export default AppointmentStatuses;
