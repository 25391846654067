/* Made by Hannah Green for ES-143 on 04/09/2021 */
import React from "react";
import { useModalContext } from "components/modal";
import { useCartCountDispatch } from "components/cart";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
const { Button } = require('components/button');

const Con = styled.div`
    width: 100%;
`;

const Message = styled.div`

`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-top: 20px;

  button {
    margin-right: 10px;
  }
`;

export default function DeletePopup(props) {

    const { t } = useTranslation();
    const tBase = "views.cart.components.deletePopup";
    const tr = (key) => t(`${tBase}.${key}`);

    const dispatch = useCartCountDispatch();
    const { setModal } = useModalContext();

    const deleteClick = () => {
        dispatch({ type: 'REMOVEITEM', items: props.item, qty: props.item.qty });
        setModal();
    };

    return (
        <Con>
            <Message>{tr("Are you sure you want to delete")} {props.item.item_name}?</Message>
            <Buttons>
                <Button type="submit" primary onClick={() => { deleteClick() }}>{tr("Yes")}</Button>
                <Button type="submit" primary onClick={() => setModal()}> No </Button>
            </Buttons>
        </Con>
    )
}