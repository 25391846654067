import { createContext, useState } from "react";

interface IPropertyMapContext {
  showDataWindow: boolean;
  setShowDataWindow: (value: boolean) => void;
  selectedMarkerId: number;
  setSelectedMarkerId: (id: number) => void;
  selectedBasemapId: number;
  setSelectedBasemapId: (id: number) => void;
}

interface IProps {
  children: React.ReactNode;
}

const PropertyMapContext = createContext({} as IPropertyMapContext);

const PropertyMapProvider = ({ children }: IProps) => {
  const [selectedMarkerId, setSelectedMarkerId] = useState(0);
  const [showDataWindow, setShowDataWindow] = useState(false);
  const [selectedBasemapId, setSelectedBasemapId] = useState(1);

  return (
    <PropertyMapContext.Provider
      value={{
        showDataWindow,
        setShowDataWindow,
        selectedMarkerId,
        setSelectedMarkerId,
        selectedBasemapId,
        setSelectedBasemapId,
      }}
    >
      {children}
    </PropertyMapContext.Provider>
  );
};

export { PropertyMapContext, PropertyMapProvider };
