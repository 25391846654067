import React from "react";
import { useTranslation } from 'react-i18next';

interface IImportProps {
  data: Array<{ [key: string]: string | number | boolean | null | undefined }>
  type: string;
  name: string;
  inventory: any;
  services: any;
  customers: any;
};

const Import = ({ data, type, name, inventory, customers, services }: IImportProps) => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.Import";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const getHeaders = (type: string) => {
    let headers: string[] = [];
    
    if (type === "customers") {
      headers = customers;
      const excludeFields = [
        "City, State, Zip (Owner)", 
        "City, State, Zip (Billing)"
      ];
      headers = headers.filter((header: string) => !excludeFields.includes(header));
      if (!headers.includes("ID")) headers.unshift("ID");
    } else if (type === "inventory") {
      headers = inventory;
      if (!headers.includes("ID")) headers.unshift("ID"); 
    } else if (type === "services") {
      headers = services;
      if (!headers.includes("ID")) headers.unshift("ID"); 
    }

    // Determine the maximum number of additional addresses and contacts
    let maxAdditionalAddresses = 0;
    let maxAdditionalContacts = 0;

    data.forEach(item => {
      if (Array.isArray(item.additional_addresses)) {
        const addAddresses = item.additional_addresses.filter(adAddress => adAddress.address !== item.owner_address && adAddress.address !== item.billing_address);
        maxAdditionalAddresses = Math.max(maxAdditionalAddresses, addAddresses.length);
      }
      if (Array.isArray(item.additional_contacts)) {
        maxAdditionalContacts = Math.max(maxAdditionalContacts, item.additional_contacts.length);
      }
    });

    // Add headers for additional addresses
    for (let i = 0; i < maxAdditionalAddresses; i++) {
      headers.push(`Address (Additional ${i + 1})`);
      headers.push(`City (Additional ${i + 1})`);
      headers.push(`State (Additional ${i + 1})`);
      headers.push(`Zip (Additional ${i + 1})`);
    }

    // Add headers for additional contacts
    for (let i = 0; i < maxAdditionalContacts; i++) {
      headers.push(`First Name (Contact ${i + 1})`);
      headers.push(`Last Name (Contact ${i + 1})`);
      headers.push(`Title (Contact ${i + 1})`);
      headers.push(`Phone (Contact ${i + 1})`);
      headers.push(`Extension (Contact ${i + 1})`);
      headers.push(`Email (Contact ${i + 1})`);
    }

    return headers;
  };

  let additionalAddressLength = 0;
  let additionalContactLength = 0;
  
  data.forEach((item) => {
    if (Array.isArray(item.additional_addresses)) {
      const addAddresses = item.additional_addresses.filter(adAddress => adAddress.address !== item.owner_address && adAddress.address !== item.billing_address);
      additionalAddressLength = addAddresses.length;
    }
  
    if (Array.isArray(item.additional_contacts)) {
      additionalContactLength = item.additional_contacts.length;
    }
  });

  const colNum = type === "customers" ? (18 + (additionalAddressLength * 4) + (additionalContactLength * 6)) : 18;

  const mapData = (item: any, type: string) => {
    let rowData: any[] = [];
    if (type === "customers") {
      rowData = [
        item.id,
        item.first_name,
        item.last_name,
        item.company_name,
        item.title,
        item.email,
        item.phone,
        item.extension,
        item.owner_address,
        item.owner_city,
        item.owner_state,
        item.owner_zip,
        item.billing_name,
        item.billing_nickname,
        item.billing_address,
        item.billing_city,
        item.billing_state,
        item.billing_zip,
      ];

      // Add dynamic data for additional addresses
      if (Array.isArray(item.additional_addresses)) {
        const addAddresses = item.additional_addresses.filter((adAddress: any) => adAddress.address !== item.owner_address && adAddress.address !== item.billing_address);
        addAddresses.forEach((address: any) => {
          rowData.push(address.address);
          rowData.push(address.city);
          rowData.push(address.state);
          rowData.push(address.zipcode);
        });
      }

      // Add dynamic data for additional contacts
      if (Array.isArray(item.additional_contacts)) {
        item.additional_contacts.forEach((contact: any) => {
          rowData.push(contact.first_name);
          rowData.push(contact.last_name);
          rowData.push(contact.title);
          rowData.push(contact.phone);
          rowData.push(contact.extension);
          rowData.push(contact.email);
        });
      }

      // Fills in empty cells for additional addresses and contacts (used for styling purposes)
      while (rowData.length < colNum) {
        rowData.push("");
      }
    } else if (type === "inventory") {
      rowData = [
        item.id,
        item.item_name,
        item.short_name,
        item.short_description,
        item.long_description,
        item.model,
        item.sku,
        item.category,
        item.subcategory,
        item.sale_price,
        item.list_price,
        item.install_price,
        item.sale_is_fixed?.toString(),
        item.sale_multiplier,
        item.install_is_fixed?.toString(),
        item.install_multiplier,
        item.special_order_item,
      ];
    } else if (type === "services") {
      rowData = [
        item.id,
        item.service_name,
        item.description,
        item.sale_price,
        item.list_price,
        item.install_price,
        item.sale_is_fixed?.toString(),
        item.sale_multiplier,
        item.install_is_fixed?.toString(),
        item.install_multiplier,
      ];
    } 
    
    return rowData;
  };

  // Filter out duplicates based on a unique key 'id' (ENSURES no dups from CTE query)
  const uniqueData = data.filter((item, index, self) =>
    index === self.findIndex((t) => t.id === item.id)
  );

  const combinedData = [
    getHeaders(type),
    ...uniqueData.map(item => mapData(item, type)).filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ""))
  ];

  const headerRow = combinedData[0];
  const dataRows = combinedData.slice(1);

  return (
    <div className="max-w-[calc(100vw-100px)]">
      <div className="absolute top-[18px] left-[25px] text-[20px]">
        {tr("Preview Import")}: <span className="text-secondary">{name}</span>
      </div>

      {/* Header Row */}
      <div className="mb-5">
        <div className="flex sticky top-0">
          {headerRow.map((cell: string, cellIndex: number) => (
            <div key={cellIndex} className=" min-w-[115px] p-2 border border-slate-300 break-words text-gray-700 font-bold bg-blue-50 shadow-md">
              {cell}
            </div>
          ))}
        </div>
        
        {/* Data Rows */}
        {dataRows.map((row, rowIndex) => (
          <div key={rowIndex} className="flex">
            {row?.map((cell: string, cellIndex: number) => (
              <div key={cellIndex} className={`min-w-[115px]
                  p-2 border border-slate-300 break-words text-gray-500
                  ${rowIndex % 2 === 1 ? "bg-slate-100" : "bg-none"}
                `}
              >
                {cell}
              </div>
            ))}
          </div>
        ))}
      </div>
      
    </div>
  )
}

export default Import;