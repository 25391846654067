import { ProductionDashboard } from "./components/dashboard/ProductionDashboard";
import { SelectedDivisionProvider } from "./context/DivisionContext";

const Production = () => {
  return (
    <SelectedDivisionProvider>
      <ProductionDashboard />
      <div>
        <div>{console.log(`This is Production`)}</div>
      </div>
    </SelectedDivisionProvider>
  );
};

export { Production };
