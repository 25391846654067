import { useForm, SubmitHandler } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useSnackbarContext } from "components/snackbar";
import { Form } from "components/_ui/react-hook-form/Form";
import { InputSubmit } from "components/_ui/react-hook-form/InputSubmit";
import { InputText } from "components/_ui/react-hook-form/InputText";
import { SubmitButtonRow } from "components/_ui/react-hook-form/SubmitButtonRow";
import { ITags } from "./interfaces/ITags";
import { useTagUpdateMutation } from "../hooks/useTagsUpdateMutation";
import { useModalContext } from "components/modal";
import Tab from "components/tab";
import { CirclePicker } from "react-color";
import { getContrastTextColorHEX } from "utils/getContrastTextColor";
import { useTranslation } from 'react-i18next';
import {
  blockColors,
  circleColors,
  compactColors,
  ghColors,
  sketchColors,
  twitterColors,
} from "styles/theme/colors";

interface IEditTagProps {
  tag: ITags;
}

// Form for editing an editing tag
const EditTag = ({
  tag,
}: IEditTagProps) => {
  const { showSnackbar } = useSnackbarContext();
  const { setModal } = useModalContext();
  const { mutate, isLoading, isError, isSuccess } = useTagUpdateMutation();
  const { id, label, color } = tag;
  const [, setColor] = useState("gray");
  const [selectedColors, setSelectedColors] = useState(blockColors);
  const { t } = useTranslation();
  const tBase = "views.admin.tags.components.EditTag";
  const tr = (key: string) => t(`${tBase}.${key}`);

  // Bring in the hook for react-hook-form
  const editTagForm = useForm<ITags>({
    defaultValues: {
      created_at: new Date().toLocaleDateString(),
      fk_business_id: 0,
      id,
      label: label || "",
      updated_at: new Date().toLocaleDateString(),
      color: color || "",
    },
  });

  const { setValue, watch } = editTagForm; // destructure from hook
  const colorValue = watch("color"); // watch color input
  const labelText = watch("label"); // watch label input

  // Function to handle color change
  const handleChangeColor = (newColor: any) => {
    setColor(newColor.hex);
    setValue("color", newColor.hex);
  };

  // Function to handle form submission
  const onSubmit: SubmitHandler<ITags> = (
    newTag: ITags
  ) => {
    // Submit form changes to the API
    mutate(newTag);
  };

  // Show a snackbar when the tag is successfully updated
  const onSuccessChange = () => {
    if (isSuccess) {
      showSnackbar(tr("Tag updated successfully."));
      setModal();
    }
  };

  // Run the onSuccessChange function when the isSuccess value changes
  useEffect(() => {
    onSuccessChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // Handles tab click
  const handleTabClick = (key: string) => {
    switch (key) {
      case "block":
        setSelectedColors(blockColors);
        break;
      case "circle":
        setSelectedColors(circleColors);
        break;
      case "compact":
        setSelectedColors(compactColors);
        break;
      case "gh":
        setSelectedColors(ghColors);
        break;
      case "sketch":
        setSelectedColors(sketchColors);
        break;
      case "twitter":
        setSelectedColors(twitterColors);
        break;
      default:
        setSelectedColors(blockColors);
    }
  };

  const tabData = {
    block: { tab: tr("Block Colors") },
    circle: { tab: tr("Circle Colors") },
    compact: { tab: tr("Compact Colors") },
    gh: { tab: tr("GH Colors") },
    sketch: { tab: tr("Sketch Colors") },
    twitter: { tab: tr("Twitter Colors") },
  };

  return (
    <div className="w-[660px]">
      <Form form={editTagForm} onSubmit={onSubmit}>
        <div className="flex space-x-10">
          <div className="">
            <InputText name={tr("Label")} form={editTagForm} required />
          </div>

          <div>
            <div className="ml-[2px] text-slate-600 text-sm">{tr("Preview")}</div>
            <div
              className="inline-block p-1 space-x-2 text-xs rounded"
              style={{
                background: colorValue || color,
                color:
                  getContrastTextColorHEX(colorValue) ||
                  getContrastTextColorHEX(color),
              }}
            >
              {colorValue || color ? (
                labelText || "Example tag"
              ) : (
                <span className="italic text-slate-400">{tr("No color selected")}</span>
              )}
            </div>
          </div>
        </div>

        <Tab data={tabData} onClick={handleTabClick} defaultTab="block" />

        <CirclePicker
          color={colorValue}
          colors={selectedColors}
          onChangeComplete={handleChangeColor}
          className="mt-4 min-h-[130px]"
          width="100%"
        />

        <SubmitButtonRow>
          <InputSubmit isError={isError} isLoading={isLoading} />
        </SubmitButtonRow>
      </Form>
    </div>
  );
};

export { EditTag };
