import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	display: flex;
	align-items: center;
	margin-top: 1rem;
	justify-content: center;
`;
const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
	// color: dark-grey;
	border: 1px solid #c9c9c9;
	// overflow: hidden;
	border-spacing: 0;
	border-radius: 20px 20px 0 0;
	> thead,
	tbody {
		> tr {
			border-bottom: 1px solid #c9c9c9;

			> th,
			td {
				padding: 1rem 0 1rem 0;
				// border: 1px solid grey;
			}
			> th {
				font-weight: 500;
			}
			> td {
				font-weight: lighter;
			}
		}
	}
`;

const List = (props) => {
	const { t, i18n } = useTranslation();
	const tBase = "views.team.viewUser.teamHistory";
	const tr = (key) => t(`${tBase}.${key}`);
	const locale = i18n.language === "es" ? "es" : "en";

	let { label, start, end, parent } = props;
	start = moment(start).locale(locale).format("MMM DD hh:mm A");
	end = moment(end).locale(locale).format("MMM DD hh:mm A");
	return (
		<tr>
			<td>{label}</td>
			<td>{start}</td>
			<td>{end === "Invalid date" ? tr("Now") : end}</td>
			<td>{parent || "-"}</td>
		</tr>
	);
};

const TeamHistory = (props) => {
	const { t } = useTranslation();
	const tBase = "views.team.viewUser.teamHistory";
	const tr = (key) => t(`${tBase}.${key}`);

	const mounted = useRef(true);
	const tabRef = useRef(null);

	const { id } = props;

	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/historicalteam?user=${id}`
				);
				if (mounted.current) {
					setData(res.data);
					window.scrollTo({
						top: tabRef.current.offsetTop - 100,
						behavior: "smooth",
					});
				}
			} catch (e) {
				console.error(e);
			}
		};

		fetchData();

		return () => (mounted.current = false);
	}, [id]);

	const populateData = data.length
		? data.map((e, i) => {
				return (
					<List
						key={i}
						label={e["category_label"]}
						start={e["start_date"]}
						end={e["end_date"]}
						parent={e["parent_name"]}
					/>
				);
		  })
		: null;

	return (
		<Con ref={tabRef}>
			<Table>
				<thead>
					<tr>
						<th>{tr("Category")}</th>
						<th>{tr("From")}</th>
						<th>{tr("To")}</th>
						<th>{tr("Leader")}</th>
					</tr>
				</thead>
				<tbody>{populateData}</tbody>
			</Table>
		</Con>
	);
};

export default TeamHistory;
