import React, {useEffect } from "react";
import { useModalContext } from "components/modal";
import Table from "components/table";
import {
	RiEdit2Line,
	RiErrorWarningLine,
	RiQuestionLine,
	RiStickyNoteLine,
} from "react-icons/ri";
import {Avatar} from "components/avatar";
import {
	RiCheckLine,
	RiTimeLine,
	RiUser2Line,
} from "react-icons/ri";
import TooltippedElement from "components/TooltippedElement";
import EditPunchModal from "./EditPunchModal";
import { useTranslation } from 'react-i18next';

/**
 * 
 * @param {*} props
 * @param {function} props.refreshinfo A function that refreshes the punch history
 * @param {Array} props.HistoryList A list of punch history objects should have the following properties:
 * @param {string} props.HistoryList.name The name of the employee
 * @param {string} props.HistoryList.note The note of the punch
 * @param {string} props.HistoryList.time The time of the punch
 * @param {string} props.HistoryList.event_type The type of the punch
 * @param {string} props.HistoryList.completed Whether the punch is completed or not
 * @param {string} props.HistoryList.pin The pin of the employee
 * @param {string} props.HistoryList.userId The id of the employee
 * @param {string} props.HistoryList.id The id of the punch
 *  
 * @returns {JSX.Element} A table of punch history
 * @category Views
 * @subcategory TimeDashboard
 * @example
 * <Punchtable refreshinfo={()=>{}} HistoryList={[{
 * name: "John Doe",
 * note: "This is a note",
 * time: "2021-02-02T12:19",
 * event_type: "CLOCK IN",
 * completed: "true",
 * pin: "1234",
 * userId: "1234",
 * id: "1234",
 * }]} />
 * 
 */
const Punchtable = (props) => {
	const { t } = useTranslation();
	const tBase = "views.time_dashboard.PunchTable";
	const tr = (key) => t(`${tBase}.${key}`);

    const { setModal } = useModalContext();
    const refreshinfo = props.refreshinfo;
    const HistoryList = props.HistoryList
	let sortedhistory = props.HistoryList.filter((row)=>{
		if(row.time.includes('----')===false){
			return true
		}
		return false
	}).sort((a,b)=>{
		let atime = new Date(a.time);
		let btime = new Date(b.time);
		return atime.getTime()-btime.getTime()
	});

    const has12hrgap=(row)=>{
		if(row.event_type==='CLOCK IN'){
			const nextpunch=sortedhistory.find((punch)=>{
				const punchtime = new Date(punch.time);
				const rowtime = new Date(row.time);
				return (punch.pin===row.pin && punch.event_type==='CLOCK OUT' && punchtime.getTime()>rowtime.getTime())
			})
			if(nextpunch){
                let punchtime = new Date(row.time);
                let nextpunchtime = new Date(nextpunch.time);
				const timediff=nextpunchtime.getTime()-punchtime.getTime()
				if(timediff>43200000){
					return true
				}
			}
		}
		if(row.event_type==='CLOCK OUT'){
			const prevpunch=sortedhistory.findLast((punch)=>{
				const punchtime = new Date(punch.time);
				const rowtime = new Date(row.time);
				return (punch.pin===row.pin && punch.event_type==='CLOCK IN' && punchtime.getTime()<rowtime.getTime())
			})
			if(prevpunch){
                let punchtime = new Date(row.time);
                let prevpunchtime = new Date(prevpunch.time);
				const timediff=punchtime.getTime()-prevpunchtime.getTime()
				if(timediff>43200000){
					return true
				}
			}
		}
		return false
	};
    const DeleteColomnelement = (info) => {
		const row = info.row;
		if (row.time.includes("----")) {
			return (
				<p style={{ margin: "auto", textAlign: "center" }}>
					------------
				</p>
			);
		} else {
			return (
				<RiEdit2Line
					style={{ margin: "auto" }}
					onClick={() => {
						setModal({
							component: <EditPunchModal row={row} refreshinfo = {()=>refreshinfo()} />,
							label: tr("Edit Punch"),
							props: {
								shouldCloseOnOverlayClick: true,
							},
						});
					}}
				/>
			);
		}
	};
    const columns = {
		name: {
			label: (
				<p>
					<RiUser2Line style={{ display: "inline" }} />
					{tr("Name")}:
				</p>
			),
			cell:(row)=>{
				if (row.name.includes("----") === false) {
					
				return <div style={{width:'50%', display:'inline-block'}}>
				<Avatar user_id={row.userId} showLabel={true} size='small'  >
					{row.name}
				</Avatar>
				</div>
				}else{
					return row.name
				}
			},
			isClickable: false,
		},
		note: {
			label: (
				<p>
					<RiStickyNoteLine style={{ display: "inline" }} />
					{tr("Notes")}:
				</p>
			),
			isClickable: false,
			style: {width: "15em"}
		},
		time: {
			label: (
				<p>
					<RiTimeLine style={{ display: "inline" }} />
					{tr("Time")}:
				</p>
			),
			isClickable: false,
		},
		event_type: {
			label: (
				<p>
					<RiCheckLine style={{ display: "inline" }} />
					{tr("Punch")}:
				</p>
			),
			isClickable: false,
		},
		completed: {
			label: (
				<p>
					<RiQuestionLine style={{ display: "inline" }} />
					{tr("Condition")}:
				</p>
			),
			isClickable: false,
			cell: (row) => {
				if(row.event_type.includes('--')===true){
					return (
						<p>------------</p>
					)
				}
				if (row.completed === "false") {
					if(row.event_type==='CLOCK OUT'){
						return <TooltippedElement
							message={tr("This punch has no corresponding punch in")}
							element=<RiErrorWarningLine style={{ margin: "auto", color: 'red' }} />
						/>;
					}else{
						return <TooltippedElement
							message={tr("This punch has no corresponding punch out")}
							element=<RiErrorWarningLine style={{ margin: "auto", color: 'red' }} />
						/>;
					}
				} else {
					if(has12hrgap(row)===true){
						return <TooltippedElement
						message={tr("12hr gap between punches")}
						element=<RiErrorWarningLine style={{ margin: "auto", color: 'red' }} />
						/>
						
					}
					return (
						<TooltippedElement
						message={tr("No errors")}
						element= <RiCheckLine style={{ margin: "auto", color: 'green' }} />
						/>
					);
				}
			}
		},
		remove: {
			isClickable: true,
			label: (
				<p style={{ margin: "auto" }}>
					<RiEdit2Line style={{ display: "inline" }} />
					{tr("Edit")}:
				</p>
			),
			cell: (row) => <DeleteColomnelement row={row} />,
		},
	};

    return 	<div
    style={{
                 width: "90%",
                 margin:'auto'
            }}
>
    <Table
        columns={columns}
        data={HistoryList?.map((a) => {
            a.completed = a.completed?.toString();
            if (a.time.includes("----") === false) {
                let newtime = new Date(a.time).toLocaleString();
                a.time = newtime;
            }
            return a;
        })}
        bubbleId="descriptionBubble"
        isTimedashboard={true}
		pageCount={props.pageCount}
		pageIndex={props.pageIndex}
		handlePage={props.handlePageChange}
    />
</div>
}
export default Punchtable;