const companyGoal = 650000000;

const teamData = [
  {
    team: "Team A",
    yesterday: 100000,
    today: 120000,
    yearToDate: 5000000,
    average: 110000,
    lastYear: 4000000,
    goal: 6000000,
  },
  {
    team: "Team B",
    yesterday: 80000,
    today: 90000,
    yearToDate: 2000000,
    average: 85000,
    lastYear: 2500000,
    goal: 4000000,
  },
  {
    team: "Team C",
    yesterday: 120000,
    today: 130000,
    yearToDate: 6000000,
    average: 125000,
    lastYear: 5000000,
    goal: 7000000,
  },
];

export { companyGoal, teamData };
