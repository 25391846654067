import axios from "axios";
import { Avatar } from "components/avatar";
import TableComponent from "components/table";
import Switch from "rc-switch";
import * as React from "react";
import { useQuery } from "react-query";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type Props = {
  type: "project";
  id: string;
};

type TQuery = {
  items:
    | {
        label: string;
        id: number;
        estimate_qty: number;
        used_qty: number;
        price: number;
        product_id: number;
        item_id: number;
      }[]
    | null;
  users: { user: string; fk_user_id: number; sum: number }[] | null;
};

export default function ProductionContainer(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.productionContainer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [hide, setHide] = React.useState(false);

  const query = useQuery<TQuery>(
    ["production", props.type, props.id],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/production/${props.id}?type=${props.type}
    `
        )
        .then((res) => res.data)
  );

  const columns = {
    label: {
      label: tr("Label"),
      width: "100%",
      cell: (row: any) => (
        <div>
          <span className="text-bold"> {row.label}</span>
          <span className="text-xs text-slate-500 font-thin ml-2">
            (
            {row.item_id
              ? `${tr("Part ID")}: ${row.item_id}`
              : `${tr("Service ID")}: ${row.product_id}`}
            )
          </span>
        </div>
      ),
    },
    estimate_price: {
      label: tr("Estimate Total"),
      cell: (row: any) => dollarFormatter(row.estimate_qty * row.price),
    },
    estimate_qty: {
      label: tr("Estimate Qty"),
    },
    used_price: {
      label: tr("Used Total"),
      cell: (row: any) => dollarFormatter(row.used_qty * row.price),
    },
    used_qty: {
      label: tr("Used Qty"),
      cell: (row: any) => {
        const color =
          row.used_qty === 0
            ? "text-slate-400"
            : row.used_qty <= row.estimate_qty
            ? "text-green-600"
            : "text-red-600";
        return <span className={`${color}`}>{row.used_qty}</span>;
      },
    },
    price: {
      label: tr("Remaining"),
      cell: (row: any) =>
        dollarFormatter(
          Math.max(0, row.estimate_qty - row.used_qty) * row.price
        ),
    },
  };

  const userColumns = {
    fk_user_id: {
      label: "ID",
    },
    user: {
      label: tr("Crew"),
      width: "100%",
      cell: (row: any) => (
        <Avatar showLabel size="small" user_id={row.fk_user_id}>
          {row.user}
        </Avatar>
      ),
    },
    sum: {
      label: tr("Production Total"),
      cell: (row: any) => dollarFormatter(row.sum),
    },
  };

  let prodTotal =
    query.data?.users?.reduce((sum, user) => sum + user.sum, 0) || 0;
  let estimateTotal =
    query.data?.items?.reduce(
      (sum, item) => sum + item.estimate_qty * item.price,
      0
    ) || 0;
  let completion = (prodTotal / (estimateTotal || 1)) * 100;

  return (
    <div className="space-y-8">
      <div className="divide-y">
        <div className="py-2">
          <h2>{tr("Crew Production")}</h2>
        </div>
        <div className="py-2">
          <TableComponent columns={userColumns} data={query.data?.users} />
        </div>
      </div>
      <div>
        <div className="py-2">
          <h2>{tr("Completed")}</h2>
        </div>
        <div className="w-full h-[5px] bg-slate-200 rounded-full relative overflow-hidden">
          <div
            className={`absolute h-full bg-[#ff4f00]`}
            style={{ width: `${completion}%` }}
          />
        </div>
        {dollarFormatter(prodTotal)} / {dollarFormatter(estimateTotal)}(
        {completion.toFixed(2)}%)
      </div>
      <div className="divide-y">
        <div className="py-2 flex justify-between">
          <h2>{tr("Items")}</h2>
          <div className="space-x-2">
            <Switch checked={hide} onChange={(e) => setHide(e)} />
            <span>{tr("Hide Non-Production Items")}</span>
          </div>
        </div>
        <TableComponent
          columns={columns}
          data={query.data?.items?.filter(
            (item) => !hide || item.estimate_qty !== 0
          )}
        />
      </div>
    </div>
  );
}
