import * as React from "react";
import { IconButton } from "components/button";
import { Link } from "components/link";
import { MdWork } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

import styled from "styled-components";
import axios from "axios";

type Payload = {
  id: number;
  label: string;
  description: string;
  entry_date: string;
  is_viewed: boolean;
  action: string;
};
type Props = {
  payload?: Payload[];
  disable: () => void;
};

const diffString = ["years", "months", "days", "hours", "minutes", "seconds"];

export default function Notification({ payload, disable }: Props) {
  const { t } = useTranslation();
  const tBase = "views.notification.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: { id: number }) =>
      axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/notification/viewed
    `,
        data
      ),
    { onSuccess: () => queryClient.invalidateQueries("notifications") }
  );

  const handleClick = (e: number) => {
    mutation.mutate({ id: e });
  };

  const populateNotifications = payload ? (
    payload.map(({ is_viewed, description, action, id, label, entry_date }) => {
      var icon;
      switch (label) {
        case "Request":
          icon = "this";
          break;
        default:
          icon = <MdWork />;
          break;
      }

      const formatEntryDate = entry_date.split(" ");
      const notNullIndex = formatEntryDate.findIndex((n) => n !== "00");

      return (
        <NotificationCon
          to={action}
          key={id}
          onClick={() => {
            handleClick(id);
            disable();
          }}
        >
          <IconButton size="large">{icon}</IconButton>
          <Details {...{ is_viewed }}>
            <span>{description}</span>
            <span>
              {formatEntryDate[notNullIndex] +
                " " +
                diffString[notNullIndex] +
                tr(" ago")}
            </span>
          </Details>
          {!is_viewed && <Status />}
        </NotificationCon>
      );
    })
  ) : (
    <Placeholder>{tr("Empty")}</Placeholder>
  );

  return (
    <Con>
      <h1>{tr("Notifications")}</h1>
      {populateNotifications}
    </Con>
  );
}

const Status = styled.div`
  background: #ff4f00;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-left: 0.5rem;
`;
const Details = styled.div<{ is_viewed?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  > span {
    &:first-child {
      font-weight: ${(props) => (props.is_viewed ? "500" : "bold")};
    }
    &:last-child {
      font-weight: ${(props) => (props.is_viewed ? "500" : "bold")};
      color: ${(props) => (props.is_viewed ? "grey" : "#ff4f00")};
    }
  }
`;

const Con = styled.div`
  width: 350px;
  > h1 {
    padding: 0.5rem 1rem;
  }
`;

const NotificationCon = styled(Link)`
  display: flex;
  border-radius: 5px;
`;

const Placeholder = styled.span`
  color: grey;
`;
