import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { theme } from "styles/theme";

export const STextArea = styled(TextareaAutosize)`
  /* background: #f0f0f0; */
  border: 1px solid ${theme.border.light};
  border-radius: 5px;
  padding: 0.5em;
`;
