import axios from "axios";
import RequestsList from "containers/requestsList";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { TRequest } from "types/request";

type Props = {
  id: string;
};

export default function Requests(props: Props) {
  const requests = useQuery<TRequest[] | null>(
    ["requests", props.id],
    async () =>
      await axios(`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.id}/requests
    `).then((res) => res.data)
  );

  if (requests.isFetching) return null;

  return (
    <Con>
      <RequestsList data={requests.data} />
    </Con>
  );
}

const Con = styled.div``;
