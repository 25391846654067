import { differenceInDays, startOfDay } from "date-fns";
import * as React from "react";
import { useTranslation } from 'react-i18next';

export default function DueIn(props: { date: string }) {
  const { t } = useTranslation();
  const tBase = "components.due_date";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const diff = differenceInDays(
    startOfDay(new Date(props.date + "T00:00")),
    startOfDay(new Date())
  );

  if (diff === 0) return <span style={{ color: "blue" }}>{tr("Today")}</span>;
  else if (diff === 1) return <span style={{ color: "green" }}>{tr("Tomorrow")}</span>;
  else if (diff < 0)
    return (
      <span style={{ color: "red" }}>
        {tr("Past")} {Math.abs(diff)} {diff === -1 ? "day" : "days"}
      </span>
    );
  else return <span style={{ color: "green" }}>{tr("In")} {diff} {tr("days")}</span>;
}
