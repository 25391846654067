import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import Tag from "./Tag.tsx";

function TagManagement(props) {
  const customerId = props.customerId;
  const { t } = useTranslation();
  const tBase = "views.customers.new-customer.tags";
  const tr = (key) => t(`${tBase}.${key}`);
  const [tags, setTags] = useState([]);

  const fetchTags = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/tags/${customerId}`
      );

      setTags(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateTag = async (customerId, tagId, type) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/updateTags`,
        { customerId, tagId, type }
      );

      if (response.status === 200) {
        fetchTags();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "49%" }}>
        <h4>{tr("Added")}</h4>
        <div
          style={{
            border: "1px solid lightgray",
            padding: 5,
            borderRadius: 4,
          }}
        >
          {tags &&
            tags?.map((tag) => {
              if (parseInt(tag.id) === parseInt(tag.fk_tag_id)) {
                return (
                  <Tag
                    tag={tag}
                    customerId={customerId}
                    type="remove"
                    key={tag.id}
                    handleUpdateTag={handleUpdateTag}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
      <div style={{ width: "2%" }} />
      <div style={{ width: "49%" }}>
        <h4>{tr("Available")}</h4>
        <div
          style={{
            border: "1px solid lightgray",
            padding: 5,
            borderRadius: 4,
          }}
        >
          {tags &&
            tags?.map((tag) => {
              if (parseInt(tag.id) !== parseInt(tag.fk_tag_id)) {
                return (
                  <Tag
                    tag={tag}
                    customerId={customerId}
                    type="add"
                    key={tag.id}
                    handleUpdateTag={handleUpdateTag}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
}

export default TagManagement;
