import React from "react";
import styled from "styled-components";
import { Avatar } from "components/avatar";
import { Menu } from "components/menu";
import { Link } from "components/link";
import { Switch, Route } from "react-router-dom";
import Personal from "./personal";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { name } = useAuthContext();
  return (
    <Con>
      <Avatar size="large" showLabel>
        {name}
      </Avatar>
      <div style={{ display: "flex" }}>
        <MenuCon />
        <Switch>
          <Route exact path="/profile/personal" component={Personal} />
        </Switch>
      </div>
    </Con>
  );
}

const Con = styled.div`
  padding: 1rem;
`;

const MenuCon = () => {
  const { t } = useTranslation();
  const tBase = "views.profile.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <Menu style={{ flex: "0 0 250px", marginRight: "1rem" }}>
      <Link to="/profile/personal" selected>
        {tr("Personal")}
      </Link>
    </Menu>
  );
};
