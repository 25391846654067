import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { ContactEdit, TCustomer } from "types/customer";
import Field from "components/field";
import {
  Button,
  NoStyleButton,
  OutlineA,
  OutlineButton,
} from "components/button";
import FlatContainer from "components/container/flat";
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import CustomerTagList from "views/customer/customer-tag-list";
import NewPropertyModal from "views/properties/newPropertyModal";
import { useTranslation } from 'react-i18next';

type Props = {
  id?: string | null;
  handleNext: VoidFunction;
  handleBack: VoidFunction;
  handleChangeAddress: (addressId: number) => void;
  addressId?: number;
  requestId?: string;
};

export default function NewCustomerSimplified(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.newCustomerSimplified.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const customerQuery = useQuery<TCustomer>(
    ["customer", "simplified", props.id],
    async () =>
      props.id
        ? await axios
            .get(
              `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.id}
  `
            )
            .then((res) => res.data.info)
        : null
  );

  return (
    <div className="flex flex-col ">
      <ViewingCustomer
        data={customerQuery.data}
        address={props.addressId}
        handleSelect={
          (id) => props.handleChangeAddress(id)
          // setAddress((ids) => (ids !== id ? id : undefined))
        }
      />
      <div className="flex justify-end space-x-2">
        <OutlineButton onClick={props.handleBack}>
          {props.requestId ? tr("Change Customer") : tr("Back")}
        </OutlineButton>
        <Button
          primary
          disabled={!props.addressId}
          onClick={() => props.addressId && props.handleNext()}
        >
          {props.requestId ? tr("Save Changes") : tr("Next")}
        </Button>
      </div>
    </div>
  );
}

type TViewingProps = {
  data?: TCustomer;
  address?: number;
  handleSelect: (e: number) => void;
};
const ViewingCustomer = (props: TViewingProps) => {
  const { t } = useTranslation();
  const tBase = "containers.newCustomerSimplified.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [isAddProp, setIsAddProp] = React.useState(false);

  return (
    <div className="space-y-8 relative">
      <div className="space-y-4">
        <div className="absolute top-0 right-0 flex justify-end">
          <OutlineA
            to={`/customers/edit/${props.data?.id}`}
            target="_blank"
            // onClick={handleEdit}
            primary
            size="small"
          >
            {tr("Edit")}
          </OutlineA>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col grow space-x-1">
            <span className="text-xs text-slate-700">{tr("First Name")}</span>
            <span>{props.data?.first_name}</span>
          </div>
          <div className="flex flex-col grow">
            <span className="text-xs text-slate-700">{tr("Last Name")}</span>
            <span>{props.data?.last_name}</span>
          </div>
        </div>

        {props.data?.company_name && (
          <div className="flex flex-col grow">
            <span className="text-xs text-slate-700">{tr("Company Name")}</span>
            <span>{props.data?.company_name}</span>
          </div>
        )}
        <div>
          <div className="flex flex-col grow">
            <span className="text-xs text-slate-700">{tr("Email")}</span>
            <span>{props.data?.email}</span>
          </div>
        </div>
        <div>
          <div className="flex flex-col grow">
            <span className="text-xs text-slate-700">{tr("Phone Number")}</span>
            <span>{props.data?.phone}</span>
          </div>
        </div>
        {props.data?.customer_tags && props.data?.customer_tags.length > 0 ? (
          <div className="flex flex-col grow">
            <span className="text-xs text-slate-700">{tr("Tags")}</span>
            <span className="">
              <CustomerTagList tags={props.data} />
            </span>
          </div>
        ) : null}
      </div>
      <FlatContainer
        title={tr("Pick an Address")}
        buttons={[
          <OutlineButton
            size="small"
            primary
            onClick={() => setIsAddProp(true)}
          >
            <RiAddLine /> {tr("Add")}
          </OutlineButton>,
        ]}
      >
        {isAddProp ? (
          <div className=" py-4">
            <NewPropertyModal
              customerInfo={props.data}
              customerId={props.data?.id}
              notModal
              onCancel={() => setIsAddProp(false)}
              onSubmit={() => setIsAddProp(false)}
            />
          </div>
        ) : (
          props.data?.addresses?.map((address) => {
            const selected = props.address === address.id;
            return (
              <NoStyleButton
                onClick={() => props.handleSelect(+address.id)}
                key={address.id}
                className={`flex !space-x-2 !items-center py-2 ${
                  selected && "!text-primary"
                }`}
              >
                {selected ? (
                  <RiCheckboxCircleFill className="mr-2" />
                ) : (
                  <RiCheckboxBlankCircleLine className="mr-2" />
                )}
                <div className="flex flex-col">
                  <span className="italic font-semibold text-sm">
                    {address.name}
                  </span>
                  <span>{`${address.address}, ${address.city} ${address.state}, ${address.zipcode}`}</span>
                </div>
              </NoStyleButton>
            );
          }) || <span className="text-slate-500 text-sm ">{tr("No Address")}</span>
        )}
      </FlatContainer>
    </div>
  );
};

type TEditingProps = {
  id?: string | null;
  onSubmit: (id: string) => void;
  data?: TCustomer;
  handleCancel: VoidFunction;
};
const EditingCustomer = (props: TEditingProps) => {
  const { t } = useTranslation();
  const tBase = "containers.newCustomerSimplified.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const initialValues = {
    first_name: props.data?.first_name || "",
    last_name: props.data?.last_name || "",
    email: props.data?.email || "",
    phone: props.data?.phone || "",
    customer_tags: props.data?.customer_tags || [],
  };

  const mutate = useMutation(
    async (e: ContactEdit) =>
      await axios
        .post(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.id}`,
          e,
          {
            params: {
              simplified: true,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        props.onSubmit(id);
      },
    }
  );

  const onSubmit = (e: ContactEdit) => mutate.mutate(e);

  const formik = useFormik<ContactEdit>({ initialValues, onSubmit });
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-4">
      <div className="flex space-x-2">
        <Field
          className="grow"
          name="first_name"
          formik={formik}
          label={tr("First Name")}
        />
        <Field
          className="grow"
          name="last_name"
          formik={formik}
          label={tr("Last Name")}
        />
      </div>
      <Field name="email" formik={formik} label={tr("Email")} />
      <Field name="phone" formik={formik} label={tr("Phone")} />
      <div className="flex space-x-2 justify-end">
        <OutlineButton size="small" onClick={props.handleCancel}>
          {tr("Cancel")}
        </OutlineButton>
        <Button size="small" primary type="submit">
          {tr("Save Changes")}
        </Button>
      </div>
    </form>
  );
};
