/* Create by Hannah Green on 1/20/2021 for ES-58 */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import clsx from "clsx";

//Components
import Popup from "../../components/closablePopup";
import Form from "./components/form";
import AllTable from "./components/allTable";
import RootTable from "./components/rootTable";

//Icons
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { BiSearchAlt } from "react-icons/bi";
import IconButton from "@material-ui/core/IconButton";

//Table imports
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button'

import { useTranslation } from 'react-i18next';

const Spinner = require("react-spinkit");

//Styles
const Con = styled.div`
  width: 100%;
`;
const Content = styled.div`
  width: 95%;
  margin-left: 3rem;
  background: white;
  border: 1px, solid, black;
  border-radius: 10px;
  align-self: center;
  margin-top: 1rem;
  overflow: hidden;
`;
const SearchCon = styled.div`
  padding: 0.5% 1% 0.5% 1%;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: 0px 0px 10px -3px grey;
  z-index: 2;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SearchBar = styled.input`
  width: 50%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  background: #f3f3f3;
  border: 0;
  border-radius: 3px;
`;
const AddEditPopup = styled(Popup)`
  padding: 1rem;
  width: 35%;
  height: 45%;
`;
const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    padding: 10rem;
    margin: 15%;
    margin-left: 40%;
  }
`;

export default function CategoryManagement() {
    const { t } = useTranslation();
    const tBase = "views.category_management.index";
    const tr = (key) => t(`${tBase}.${key}`);
    
    const useStickyState = (key = "sticky", initialState = null) => {
        const [state, setState] = useState(() => {
            const storedState = localStorage.getItem(key);
            return storedState ?? initialState;
        });

        useEffect(() => {
            localStorage.setItem(key, state);
        }, [state]);

        const clearState = () => localStorage.removeItem(key);

        return [state, setState, clearState];
    };

    const [tableView, setTableView, clearTableView] = useStickyState("tableView", "All");

    //table styles
    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    }));

    //toolbar styles
    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === "light"
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: "1 1 100%",
            marginLeft: "8rem",
        },
    }));

    //State Variables
    const [isSomethingClicked, setIsSomethingClicked] = useState({
        type: null,
        rowCategoryId: null,
        rowName: null,
    });

    //table variables
    const classes = useStyles();
    const [items, setItems] = useState();

    //search variables
    const [noResults, setNoResults] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    //get items from the database
    useEffect(() => {
        if (searchTerm === "") {
            fetchData();
        }
    }, []);

    const fetchData = async () => {
        try {
            const categories = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category?type=ALLCATEGORIES`
            );
            setItems(categories.data);

        } catch (e) {
            console.error(e);
        }
    };

    const tableViewButtonClick = async (view) => {
        if (view === 'All') {
            await fetchData();
            setTableView('All');
        } else {
            setTableView("Root");
        }
    }

    try {
        if (items.length !== 0) {

            //Toolbar
            const EnhancedTableToolbar = () => {
                const classes = useToolbarStyles();
                return (
                    <Toolbar
                        className={clsx(classes.root)}
                    >
                        <ButtonGroup disableElevation variant="contained">
                            <Button
                                onClick={() => {
                                    tableViewButtonClick("All");
                                }}
                            >{tr("All Categories")}</Button>
                            <Button
                                onClick={() => {
                                    tableViewButtonClick("Root");
                                }}
                            >{tr("Parent Categories")}</Button>
                        </ButtonGroup>

                        <Typography
                            className={classes.title}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                        </Typography>

                        <Tooltip title={tr("Add Item")}>
                            <IconButton
                                aria-label="add"
                                onClick={() => {
                                    setIsSomethingClicked((state) => {
                                        const tempIsSomethingClicked = { ...state };
                                        tempIsSomethingClicked.type = "add";
                                        return tempIsSomethingClicked;
                                    });
                                }}
                            >
                                <AddCircleIcon size={25} />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                );
            };

            //submit search
            const submitSearch = async () => {
                try {
                    const searchResults = await axios.get(
                        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category?type=SEARCH&searchTerm=${searchTerm}`
                    );
                    if (searchResults.data.length > 0) {
                        setItems(searchResults.data);
                        setSearchTerm("");
                    } else {
                        setNoResults(true);
                    }
                    return;
                } catch (e) {
                    console.error("Function submitSearch", e);
                }
            };

            //handle search box
            const handleSearch = (event) => {
                const target = event.target;
                const value = target.value;

                setSearchTerm((state) => {
                    let tempSearchTerm = { ...state };
                    tempSearchTerm = value;
                    return tempSearchTerm;
                });
            };

            //submit search on enter keypress
            const handleKeyPress = (event) => {
                if (event.which === 13) {
                    submitSearch();
                }
            };

            return (
                <Con>
                    <SearchCon>
                        <BiSearchAlt size={25} />
                        <SearchBar
                            className="search-item"
                            placeholder={tr("Search by Name")}
                            name="search"
                            value={searchTerm}
                            onChange={handleSearch}
                            onKeyPress={handleKeyPress}
                        ></SearchBar>
                    </SearchCon>
                    <Content>
                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                <EnhancedTableToolbar />
                                {tableView === "All" && <AllTable classes={classes} items={items} noResults={noResults} />}
                                {tableView === "Root" && <RootTable classes={classes} noResults={noResults} />}
                            </Paper>
                            {isSomethingClicked.type === "add" && (
                                <AddEditPopup
                                    cancelDisplay={() => {
                                        setIsSomethingClicked((state) => {
                                            const tempIsSomethingClicked = { ...state };
                                            tempIsSomethingClicked.type = null;
                                            return tempIsSomethingClicked;
                                        });
                                    }}
                                >
                                    <Form formType="add" closePopup={() => {
                                        setIsSomethingClicked((state) => {
                                            const tempIsSomethingClicked = { ...state };
                                            tempIsSomethingClicked.type = null;
                                            tempIsSomethingClicked.rowCategoryId = null;
                                            return tempIsSomethingClicked;
                                        });
                                    }} />
                                </AddEditPopup>
                            )}
                        </div>
                    </Content>
                </Con>
            );
        }
    } catch (e) {
        return (
            <Con>
                <SearchCon>
                    <BiSearchAlt size={25} />
                    <SearchBar
                        className="search-item"
                        placeholder={tr("Search by Name or Tag")}
                    ></SearchBar>
                </SearchCon>

                <Content>
                    <div className={classes.root}>
                        <Loading>
                            <div>
                                <Spinner name="wandering-cubes" color="grey" />
                            </div>
                        </Loading>
                    </div>
                </Content>
            </Con>
        );
    }
}