import React from "react";
import styled, { css } from "styled-components";
import {
	Questions,
	useQuestionBuilderDispatch,
	useQuestionBuilderState,
} from "../context";
import QuestionFooter from "./footer";
import Select from "components/react-select";
import MultiChoice from "./multiChoice";
import BookitChoice from "./bookitChoice";
import TextareaAutosize from "react-textarea-autosize";
import { useTour } from "contexts/tour";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const QuestionCon = styled.div<{ link: string; selected?: boolean }>`
	background: white;
	border-radius: 5px;
	padding: 1rem;
	overflow: hidden;
	margin: 0.5rem 0 0.5rem 0;
	position: relative;
	display: flex;
	width: 800px;
	flex-direction: column;
	border: ${(p) => {
		return p.link;
	}};
	${(props) =>
		props.selected &&
		css`
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 5px;
				background: #ff4f00;
			}
		`}
`;

const QuestionTitle = styled.div`
	display: flex;
	align-items: flex-start;
	> textarea {
		padding: 0.5rem;
		background: #f8f8f8;
		border: 0;
		font-size: 1rem;
		border-bottom: 1px solid grey;
		resize: none;
		flex: 1;
	}
`;

let ranOnce = false;

export default function EachQuestion({
	index,
	choices,
	selected,
	title,
	type,
	required,
	id,
	datePicker,
}: Questions & { index: number; selected: boolean; datePicker: boolean }) {
	const { t } = useTranslation();
	const tBase = "views.settings.questionBuilder.question.index";
	const tr = (key: string) => t(`${tBase}.${key}`);

	let options = [
		{ value: "SINGLE", label: tr("Single Choice") },
		{ value: "MULTIPLE", label: tr("Multiple Choice") },
		{ value: "TEXT", label: tr("Short Answer") },
		{ value: "IMAGE", label: tr("Image Upload") },
	];

	const dispatch = useQuestionBuilderDispatch(),
		{
			groupLinkage: { questionId },
			subquestions,
		} = useQuestionBuilderState(),
		tour = useTour(),
		params = useParams<{ id: string }>(),
		isGroupLinkageQuestion = questionId ? questionId === id : false,
		link = isGroupLinkageQuestion
			? "1px solid #ff4f00"
			: "1px solid #c0c0c0";

	let date = new Date();
	date.setDate(date.getDate());

	const [leadDate, setLeadDate] = React.useState(date.toLocaleDateString());
	const [leadNumber, setLeadNumber] = React.useState(0);

	const Leads = { leadDate, setLeadDate, leadNumber, setLeadNumber };

	React.useEffect(() => {
		// since only one question should use the date picker, this is needed.
		// additionally it should run only once otherwise the bookit option gets duplicated per question
		if (!datePicker && !ranOnce) {
			options = [
				...options,
				{ value: "CALENDAR", label: tr("Date Selector (Book It)") },
			];
			ranOnce = true;
		} else if (datePicker && !ranOnce) {
			if (type === "CALENDAR" && choices) {
				let futureDate = new Date(Date.now());
				futureDate.setDate(
					futureDate.getDate() + parseInt(choices[0].label)
				);
				choices
					? setLeadDate(futureDate.toLocaleDateString())
					: setLeadDate(new Date().toLocaleDateString());
				let date1 = new Date().getDate() as any;
				let date2 = new Date(leadDate).getDate() as any;
				let dayDiff = Math.abs(date1 - date2);
				setLeadNumber(dayDiff);
				ranOnce = true;
			}
		}
	}, [choices]);

	const renewDatePicker = (type: string) => {
		if (
			type === "CALENDAR" &&
			!options.find((option) => option.value === "CALENDAR")
		) {
			options = [
				...options,
				{ value: "CALENDAR", label: tr("Date Selector (Book It)") },
			];
			// console.log("added option")
		}
	};

	const is_subquestion = subquestions
		? subquestions?.findIndex((cat) => cat.id === +params.id) !== -1
		: false;

	if (selected) {
		return (
			<QuestionCon
				{...{ selected, link }}
				onClick={(e) => {
					e.stopPropagation();
					dispatch({ type: "changeSelectedQuestion", index });
				}}
			>
				<QuestionTitle>
					<TextareaAutosize
						placeholder={tr("Untitled Question")}
						value={title}
						onChange={(e) =>
							dispatch &&
							dispatch({
								type: "changeQuestionTitle",
								payload: e.target.value,
							})
						}
					/>
					<QuestionOptions {...{ index, type }} />
				</QuestionTitle>
				{type !== "TEXT" && type !== "IMAGE" && type !== "CALENDAR" ? (
					<MultiChoice
						{...{
							type,
							selected,
							choices,
							index,
							isGroupLinkageQuestion,
							is_subquestion,
						}}
						id={id.toString()}
					/>
				) : type === "TEXT" ? (
					<ShortAnswerCon>Short Answer Text</ShortAnswerCon>
				) : type === "IMAGE" ? (
					<div>Image Upload</div>
				) : type === "CALENDAR" ? (
					<BookitChoice {...Leads} />
				) : null}
				<QuestionFooter
					{...{
						required,
						index,
						isGroupLinkageQuestion,
						renewDatePicker,
						type,
					}}
				/>
			</QuestionCon>
		);
	}

	return (
		<QuestionCon
			link={link}
			ref={(ref) => index === 0 && tour?.setStep(ref, 2)}
			onClick={(e) => {
				e.stopPropagation();
				dispatch({ type: "changeSelectedQuestion", index });
			}}
		>
			<QuestionLabel>
				<span>Q{index + 1}</span>
				{/* this is a work around for the date picker description */}
				<span>
					{type === "CALENDAR"
						? "Date Picker (Book it)"
						: options?.find((option) => option.value === type)
								?.label}
				</span>
				{isGroupLinkageQuestion && (
					<GroupLinkageTag>{tr("Link")}</GroupLinkageTag>
				)}
			</QuestionLabel>
			<QuestionTitle>
				{title}
				{required && <span style={{ color: "red" }}>*</span>}
			</QuestionTitle>
			{type !== "TEXT" && type !== "IMAGE" && type !== "CALENDAR" ? (
				<MultiChoice
					{...{
						type,
						selected,
						choices,
						index,
						isGroupLinkageQuestion,
						is_subquestion,
					}}
					id={id.toString()}
				/>
			) : type === "TEXT" ? (
				<ShortAnswerCon>{tr("Short Answer Text")}</ShortAnswerCon>
			) : type === "CALENDAR" ? (
				<div>{tr("Minimum lead time")}: {leadDate}</div>
			) : (
				<div>{tr("Image Upload")}</div>
			)}
		</QuestionCon>
	);
}

const GroupLinkageTag = styled.span`
	padding: 0 0.5rem;
	margin-left: 0.5rem;
	border-radius: 5px;
	background: #ff4f00;
	color: white;
`;

const QuestionLabel = styled.div`
	font-size: 0.8rem;
	font-weight: bold;
	color: grey;
	> span:first-child {
		margin-right: 0.5rem;
	}
`;

const ShortAnswerCon = styled.div`
	margin: 1rem 0 0.5rem 0;
	color: grey;
	border-bottom: 1px dotted grey;
`;

const QuestionOptions = ({ type, index }: { type: string; index: number }) => {
	const dispatch = useQuestionBuilderDispatch();
	const tour = useTour();
	const { t } = useTranslation();
	const tBase = "views.settings.questionBuilder.question.index";
	const tr = (key: string) => t(`${tBase}.${key}`);

	let options = [
		{ value: "SINGLE", label: tr("Single Choice") },
		{ value: "MULTIPLE", label: tr("Multiple Choice") },
		{ value: "TEXT", label: tr("Short Answer") },
		{ value: "IMAGE", label: tr("Image Upload") },
	];

	interface iOption {
		value: string;
		label: string;
	}

	const changeOption = (v: iOption | iOption[] | null) => {
		let value = v && !Array.isArray(v) && v.value;
		if (value) {
			dispatch({ type: "changeQuestionType", index, value });
		}

		// removes date picker option from options list if a date picker question exists
		// if (value === "CALENDAR") {
		//   const index = options.findIndex(option => option.value === "CALENDAR")
		//   if (index != -1) {
		//     options.splice(index, 1)
		//   }
		// }
	};
	return (
		<div
			ref={(ref) => tour?.setStep(ref, 3)}
			style={{ flex: "0 0 180px", marginLeft: "1rem" }}
		>
			<Select
				isClearable={false}
				name="placeholders"
				{...{ options }}
				value={options.find((e) => e.value === type) || ""}
				onChange={changeOption}
			/>
		</div>
	);
};
