/* Created by Hannah Green on 4/29/2021 for ES-147 */
import React from "react";
import RequestCard from "./request-card";
import styled from "styled-components";

const Con = styled.div`
    width: 100%;
    padding: 5px;

    @media only screen and (max-width: 1000px) {
        width: 98%;
        padding: 5px;
    }
`;

export default function Requests(props) {
    const requests = props.requestList;

    const requestList = requests && requests.map((request) => {
        return (
            <RequestCard
                key={request.request_id}
                request={request} />
        );
    });

    return (
        <Con>
            {requestList}
        </Con>
    );
}