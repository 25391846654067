import axios from "axios";
import { Button, SubtleButton } from "components/button";
import { Input } from "components/input";
import * as React from "react";
import { RiCheckboxCircleFill, RiCheckFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import XLSX from "xlsx";
import { useParams } from "react-router-dom";
import type { Item, Connection, Settings } from "types/quote";
import { useSnackbarContext } from "components/snackbar";
import { useTranslation } from 'react-i18next';

export default function QuoteGenerator(props: {
  refetch: () => void;
  id: string;
}) {
  const [jsonData, setJsonData] = React.useState<any>();

  const settings = useQuery<Settings>(
    "quote generator settings",
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/settings
  `
        )
        .then((res) => res.data)
  );

  const handleChange = (e: any) => {
    let file = e.target.files;
    if (file && file[0]) {
      file = file[0];
    }

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 0 });
      setJsonData(data);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const reset = () => {
    setJsonData(undefined);
  };

  return (
    <Con>
      {!jsonData || !settings.data ? (
        <Input type="file" onChange={handleChange} />
      ) : (
        <Result {...props} {...{ reset, jsonData, settings: settings.data }} />
      )}
    </Con>
  );
}

const Con = styled.div`
  width: 600px;
  height: 600px;
`;

type ResultProps = {
  jsonData: any;
  settings: Settings;
  reset: () => void;
  id: string;
  refetch: () => void;
};
const Result = (props: ResultProps) => {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.quoteGenerator";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { qty_column, label_column } = props.settings.info;

  const { showSnackbar } = useSnackbarContext();
  const result: { [key: string]: Item & { quantity: number } } =
    props.jsonData.reduce((prev: any, data: any) => {
      if (
        typeof data[qty_column] !== "number" ||
        typeof data[label_column] !== "string"
      ) {
        return prev;
      }

      const referString = data[label_column].trim();
      const qty = data[qty_column];
      let tempPrev = { ...prev };

      props.settings.connections.forEach((connection) => {
        switch (connection.match_type) {
          case "exact":
            if (connection.string.trim() === referString) {
              connection.items.forEach((item) => {
                if (!item) return;
                if (!tempPrev[item.id]) {
                  tempPrev = {
                    ...tempPrev,
                    [item.id]: {
                      ...item,
                      quantity: qty,
                    },
                  };
                  return;
                }
                tempPrev[item.id].quantity = tempPrev[item.id].quantity + qty;
                return;
              });
            }
            return;
          case "partial":
            if (referString.includes(connection.string)) {
              connection.items.forEach((item) => {
                if (!item) return;
                if (!tempPrev[item.id]) {
                  tempPrev = {
                    ...tempPrev,
                    [item.id]: {
                      ...item,
                      quantity: qty,
                    },
                  };
                  return;
                }
                tempPrev[item.id].quantity = tempPrev[item.id].quantity + qty;
                return;
              });
            }
            return;
        }
      });

      return tempPrev;
    }, {});

  const submitData = useMutation(
    async (body: { [key: string]: Item }) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/generator
  `,
        body
      ),
    {
      onSuccess: () => {
        props.refetch();
        showSnackbar(tr("Quote Generated!"));
      },
    }
  );

  const populateResult = Object.entries(result).map(([key, value]) => {
    return (
      <ResultLineItem key={key}>
        <RiCheckboxCircleFill />
        <div>{value.label}</div>
        <div>{value.quantity}</div>
      </ResultLineItem>
    );
  });

  const handleSubmit = () => {
    submitData.mutate(result);
  };

  return (
    <ResultCon>
      <SubtleButton onClick={props.reset}>{"<"}{tr("Back")}</SubtleButton>
      {populateResult}
      <Button primary onClick={handleSubmit}>
        {tr("Add to Quote")}
      </Button>
    </ResultCon>
  );
};

const ResultCon = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  > button:first-child {
    align-self: flex-start;
  }
  > button:last-child {
    margin-top: auto;
  }
`;

const ResultLineItem = styled.div`
  color: green;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  > div:last-child {
    margin-left: auto;
  }
  > svg {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
`;
