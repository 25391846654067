import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// const Input = styled.input`
//   background: #F0F0F0;
//   border: 0;
//   font-size: 1rem;
//   padding: 0.5rem;
//   border-radius: 5px;
// `

const Con = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  > div {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    color: red;
    text-align: right;
    font-size: 0.8rem;
  }
  > input {
    background: #F0F0F0;
    width: calc(100% - 1rem);
    border: 0;
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
  }
`

const Prefix = styled.span`
  position: absolute;
  left: -0.2rem;
`


const FormInput = ({
    name,
    type,
    placeholder,
    onChange,
    className,
    value,
    error,
    children,
    label,
    pattern,
    ...props
  }) => {
    
    return (
      <Con className={className}>
        <label htmlFor={name}>
          {label}
        </label>
        {/* <Prefix>$</Prefix> */}
        <input
          // id={name}
          name={name}
          pattern={pattern}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          style={error && {border: 'solid 1px red'}}
        />
        {/* { error && <div>*{ error }</div>} */}
      </Con>
    )
  }
  
  FormInput.defaultProps = {
    type: "text",
    className: ""
  }
  
  FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'date']),
    className: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
}

export default FormInput