import IProductionItem from "views/admin/production/types/IProductionItem";
import formatCentsToUSD from "views/admin/production/utils/formatCentsToUSD";
import formatDateForDisplay from "views/admin/production/utils/formatDateForDisplay";

type IProps = {
  productionItems: IProductionItem[];
};

const Body = ({ productionItems }: IProps) => {
  return (
    <tbody className="divide-y">
      {productionItems?.map((prodItem: IProductionItem) => (
        <tr key={prodItem.id} className="hover:bg-gray-50">
          <td className="px-4 py-2">{prodItem.id}</td>
          <td className="px-4 py-2">{prodItem.fk_appointment_id}</td>
          <td className="px-4 py-2">{prodItem.fk_user_id}</td>
          <td className="px-4 py-2">{prodItem.fk_estimate_id}</td>
          <td className="px-4 py-2">{prodItem.fk_invoice_id}</td>
          <td className="px-4 py-2">
            {formatCentsToUSD(prodItem.appointment_production)}
          </td>
          <td className="px-4 py-2">
            {formatCentsToUSD(prodItem.estimate_production)}
          </td>
          <td className="px-4 py-2">
            {formatCentsToUSD(prodItem.invoice_production)}
          </td>
          <td className="px-4 py-2">
            {formatDateForDisplay(prodItem.updated_at)}
          </td>
          <td className="px-4 py-2">
            {formatDateForDisplay(prodItem.created_at)}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default Body;
