import * as React from "react";
import styled, { css } from "styled-components";
import { OutlineButton, SubtleButton } from "components/button";
import { Count, Category, Query } from "./index";
import { theme } from "styles/theme";
import Switch from "rc-switch";
import useQueryString from "hooks/useQueryString";
import { useQuery } from "react-query";
import axios from "axios";
import { useTranslation } from 'react-i18next';

interface Props {
  count?: Count[];
  query?: Query;
  categories?: Category[];
  selected?: string;
  handleCategoryClick: (link: string, type: string) => void;
  handleStatusClick: (status: string) => void;
  setAttention: (a: any) => any;
  attention: boolean;
}
export default function RequestFilter({
  count,
  handleCategoryClick,
  handleStatusClick,
  setAttention,
  attention,
  query,
  selected,
  categories,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.requests.requestFilter";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const statuses = ["new", "pending", "in progress", "completed", "canceled"];
  const queryString = useQueryString();

  const { invoice } = queryString.search;

  const attentionCounts = useQuery<number>(
    ["attention counts"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/attention-counts
  `
        )
        .then((res) => res.data)
  );

  const populateStatus = statuses.map((status) => {
    const findCount = count?.find((count) => status === count.status);

    return (
      <List
        key={status}
        clicked={status === selected}
        onClick={() => {
          handleStatusClick(status);
        }}
      >
        <div>{tr(status)}</div>
        <div>{findCount?.count || 0}</div>
      </List>
    );
  });

  const populateCategory = categories?.map(({ link }) => {
    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      let { checked } = e.target;
      if (checked) {
        return handleCategoryClick(link, "ADD");
      }
      handleCategoryClick(link, "DELETE");
    };

    if (!link) return null;

    return (
      <CheckboxRow key={link}>
        <input
          type="checkbox"
          onChange={handleCheckbox}
          checked={
            query
              ? query.status
                ? query.status.findIndex((status) => status === link) !== -1
                : false
              : false
          }
        />
        <span>{link || tr("General")}</span>
      </CheckboxRow>
    );
  });

  const handleInvoiceChange = (e: string) => {
    if (!invoice) {
      queryString.update({ ...queryString.search, invoice: e });
    } else {
      if (Array.isArray(invoice)) {
        let idx = invoice.findIndex((inv) => inv === e);
        if (idx === -1) {
          queryString.update({
            ...queryString.search,
            invoice: [...invoice, e],
          });
        } else {
          let temp = [...invoice];
          temp.splice(idx, 1);
          queryString.update({ ...queryString.search, invoice: temp });
        }
      } else {
        if (invoice === e) {
          queryString.update({ ...queryString.search, invoice: undefined });
        } else {
          queryString.update({ ...queryString.search, invoice: [invoice, e] });
        }
      }
    }
    // queryString.update({ ...queryString.search, invoice: ["that"] });
  };

  return (
    <Con className="border-r">
      <OutlineButton
        onClick={() => {
          setAttention((a: any) => !a);
          queryString.update({});
        }}
        className={`${
          attention ? "!bg-red-600 !text-white" : "!bg-red-100 !text-red-600"
        }  !border-red-600 !my-2 !p-2 !w-[90%] !self-center !justify-between`}
      >
        <span>{tr("Requires Attention")}</span>
        <span className="bg-red-600 text-white px-2 rounded-full">
          {attentionCounts?.data}
        </span>
      </OutlineButton>
      <ListCategory>
        <p>
          {tr("Status")}
        </p>
        <p onClick={() => handleStatusClick("showAll")} style={{ color: "#FF4F00", cursor: "pointer" }}>
          {tr("Show All")}
        </p>
      </ListCategory>
      {populateStatus}
      <ListCategory>
        {tr("Category")}
        <SubtleButton
          size="small"
          style={{ marginLeft: "auto" }}
          onClick={() => handleCategoryClick("_", "CLEAR")}
        >
          {tr("Clear")}
        </SubtleButton>
      </ListCategory>
      {populateCategory}
      <ListCategory>Invoice</ListCategory>
      <CheckboxRow>
        <input
          type="checkbox"
          onChange={() => handleInvoiceChange("not-invoiced")}
          checked={
            queryString.search.invoice
              ? Array.isArray(queryString.search.invoice)
                ? queryString.search.invoice.findIndex(
                    (upd) => upd === "not-invoiced"
                  ) !== -1
                : queryString.search.invoice === "not-invoiced"
              : false
          }
        />
        <span>{tr("Not yet invoiced")}</span>
      </CheckboxRow>
      <CheckboxRow>
        <input
          type="checkbox"
          onChange={() => handleInvoiceChange("invoiced")}
          checked={
            queryString.search.invoice
              ? Array.isArray(queryString.search.invoice)
                ? queryString.search.invoice.findIndex(
                    (upd) => upd === "invoiced"
                  ) !== -1
                : queryString.search.invoice === "invoiced"
              : false
          }
        />
        <span>{tr("Invoiced")}</span>
      </CheckboxRow>
      <ListCategory>{tr("Appointments")}</ListCategory>
      <CheckboxRow>
        <input
          type="checkbox"
          onChange={() =>
            queryString.update({
              ...queryString.search,
              appt: queryString.search.appt ? undefined : true,
            })
          }
          checked={queryString.search.appt ? true : false}
        />
        <span>{tr("All completed")}</span>
      </CheckboxRow>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListCategory = styled.h4`
  padding: 0;
  margin: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const List = styled.div<{ clicked?: boolean }>`
  font-size: 0.9rem;
  display: flex;
  color: #2e2e2e;
  text-transform: capitalize;
  // border-bottom: 1px solid #d6dbdf;
  padding: 0.7rem 1rem;
  cursor: pointer;
  &:hover {
    background: #f1f4f9;
  }
  > div:last-child {
    padding: 0 0.5rem;
    border-radius: 10px;
    margin-left: auto;
  }
  ${(props) =>
    props.clicked &&
    css`
      font-weight: bold;
      background: ${theme.bg.blue};
      color: ${theme.color.main};
    `}
`;

const CheckboxRow = styled.li`
  padding: 0 1rem;
  display: flex;
  align-items: center;
  > input {
    margin-right: 0.5rem;
  }
`;
