import * as React from "react";

type Props = boolean;
export default function useSeeMore(props: Props) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    props && setShow(props);
  }, [props]);

  const toggle = () => {
    setShow((s) => !s);
  };

  return { show, toggle };
}
