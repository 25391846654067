import { ILocation } from "../types/ILocation";

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

const haversineDistance = (point1: ILocation, point2: ILocation) => {
	// console.log("haversineDistance", point1, point2);

	const R = 6371e3; // Earth radius in meters
	const lat1 = toRadians(point1.latitude);
	const lat2 = toRadians(point2.latitude);
	const deltaLat = toRadians(point2.latitude - point1.latitude);
	const deltaLng = toRadians(point2.longitude - point1.longitude);

	const a =
		Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
		Math.cos(lat1) *
			Math.cos(lat2) *
			Math.sin(deltaLng / 2) *
			Math.sin(deltaLng / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	// console.log("haversineDistance", R * c);
	return R * c;
};

export { haversineDistance };
