import axios from "axios";

import { TMapMarker } from "../types/TPropertyMapData";
import constructUrl from "utils/constructUrl";
import clients from "constants/clients";
import domains from "constants/domains";
import mapEndpoints from "constants/routes/map";

const client = clients.COMPANY;
const domain = domains.PROPERTY_MAP;
const endpoint = mapEndpoints.MARKER;

export const postMarker = async (newMarker: TMapMarker) => {
  const url = constructUrl({
    client,
    domain,
    endpoint: `${endpoint}/${newMarker.fk_property_id}`,
  });
  const response = await axios.post(url, newMarker);
  return response.data;
};
