import styled from "styled-components";

export const Status = styled.span<{ color: string }>`
  background: rgba(${(props) => props.color}, 0.15);
  color: rgb(${(props) => props.color});
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  font-weight: bold;
  /* line-height: 1em; */
  margin-left: 0.5em;
  white-space: nowrap;
  width: auto;
`;
