import { Input } from "components/input";
import * as React from "react";
import { SButtonCon, SButtonGroup } from "./style";

import {
  Button,
  MenuButton,
  OutlineButton,
  SubtleButton,
} from "components/button";
import { useModalContext } from "components/modal";
import AddCategory from "./components/addCategory";

import AddMultiplier from "./components/addMultiplier";
import Total from "./components/total";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Category from "./components/category";
import BreadCrumbs from "components/breadcrumbs";
import QuoteGenerator from "./components/quoteGenerator";
import { Status } from "views/request/component/status";
import FinalPriceReview from "./components/reviewFinalPrice";
import { RiArrowDownSLine } from "react-icons/ri";
import { useSnackbarContext } from "components/snackbar";
import { useHistory, useLocation } from "react-router-dom";
import { generatePDF } from "./components/generatePDF";
import Popper from "components/react-popperv2";
import { MenuGroup } from "components/menu/group";
import CustomFields, { CustomField } from "./components/customFields";
import {
  getCategoryTotal,
  getMultiplersValues,
  getMultipliersTotal,
  getProductTotal,
} from "utils/quote";
import { Customer } from "views/requests/newRequestModal/customerForm";
import SendQuoteForReview from "./components/sendQuoteForReview";
import useWindowDimensions from "hooks/useWindowDimensions";
import Segment, { SHeader, SContent } from "components/segment";
import SubmitQuoteModal from "./components/submitQuoteModal";
import Parts from "containers/parts";
import DuplicateQuote from "./duplicateQuote";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  projectId: string;
};

export type QuoteItem = {
  label: string;
  id: number;
  list_price: number;
  sale_price: number;
  quantity: any;
};

export type QuoteCategory = {
  label: string;
  type: "list" | "dropdown";
  items: {
    [id: string]: QuoteItem;
  };
};

export type Multiplier = {
  label: string;
  id: string;
  rate: number;
  is_list: boolean;
  is_sale: boolean;
  fk_category_id: [number] | null;
  all_category: boolean;
};

export type Info = {
  label: string;
  id: string;
  project_label: string;
  total_amount: number;
  status: {
    id: number;
    label: string;
    color: string;
  };
  name: string;
};

export type BidClient = Customer & { price?: string | number | null };

export type Quote = {
  category: {
    [catId: string]: QuoteCategory;
  };
  multiplier: Multiplier[] | null;
  customFields: CustomField[] | null;
  quote: Info;
  clients: BidClient[] | null;
};

export default function Quote(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  /* hooks */
  const { setModal } = useModalContext(),
    { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient(),
    history = useHistory(),
    location = useLocation<{ focusOnLabel?: boolean }>(),
    dimensions = useWindowDimensions();

  // state
  const [dataUrl, setDataUrl] = React.useState(""),
    [quotesData, setQuotesData] = React.useState<Quote | null>(null),
    [customFields, setCustomFields] = React.useState<CustomField[] | null>(
      null
    ),
    [expandAll, setExpandAll] = React.useState(2),
    [editable, setEditable] = React.useState(false);

  // ref
  const quoteLabelRef = React.useRef<HTMLInputElement | null>(null);

  const focusOnLabel = location.state?.focusOnLabel;

  const quotes = useQuery<Quote>(
    ["quote", props.id],
    async () =>
      await axios
        .get(
          `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}
          `
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setEditable(
          (res && res.quote.status.id === 19) || editable ? true : false
        );
        setQuotesData(res);
        setCustomFields(res.customFields);
      },
    }
  );

  const deleteQuote = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/delete
  `),
    {
      onSuccess: () => {
        history.replace(`/projects/${props.projectId}`);
        showSnackbar(tr("Quote Deleted!"));
      },
    }
  );

  const updateQuote = useMutation(
    async (body: any) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/update
  `,
          body
        )
        .then((res) => res.data),
    {
      onMutate: async (body) => {
        // await queryClient.cancelQueries(["quote", props.id])
        // const previousData = queryClient.getQueryData(["quote", props.id])
        // await queryClient.cancelQueries(["quote", props.id]);
        // let prevQuote = queryClient.getQueryData<Quote>(["quote", props.id]);
      },
      onError: () => {
        showSnackbar(
          <div
            style={{
              display: "flex",
              flex: "1",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{tr("An error has occurred.")}</span>
            <SubtleButton primary onClick={() => window.location.reload()}>
              {tr("Refresh")}
            </SubtleButton>
          </div>
        );
      },
    }
  );

  const submitQuote = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/submit
  `),
    {
      onSuccess: () => {
        quotes.refetch();
        showSnackbar(tr("Quote updated!"));
      },
    }
  );

  React.useEffect(() => {
    focusOnLabel && quoteLabelRef.current?.focus();
  }, [focusOnLabel, quoteLabelRef]);

  const duplicateQuote = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/duplicate
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        history.replace(`/projects/${props.projectId}/quote/${id}`, {
          focusOnLabel: true,
        });
        showSnackbar(tr("Quote duplicated!"));
      },
    }
  );

  const convertQuote = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/convert
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        window.location.reload();
        showSnackbar(tr("Quote updated!"));
      },
    }
  );

  const quoteLost = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/lost
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Quote marked as Lost!"));
        // history.replace(`/project/${props.projectId}`)
      },
    }
  );

  const deletable =
    quotesData && ![21, 22, 23].includes(quotesData.quote.status.id);

  const handleChange = ({ type, value, ...body }: any) => {
    setQuotesData((prevQuote) => {
      if (!prevQuote) return prevQuote;
      switch (type) {
        case "label":
          return {
            ...prevQuote,
            quote: { ...prevQuote.quote, label: value },
          };
        case "list_price":
          return {
            ...prevQuote,
            category: {
              ...prevQuote?.category,
              [body.catId]: {
                ...prevQuote?.category[body.catId],
                items: {
                  ...prevQuote?.category[body.catId].items,
                  [body.id]: {
                    ...prevQuote?.category[body.catId].items[body.id],
                    list_price: value,
                  },
                },
              },
            },
          };
        case "sale_price":
          return {
            ...prevQuote,
            category: {
              ...prevQuote?.category,
              [body.catId]: {
                ...prevQuote?.category[body.catId],
                items: {
                  ...prevQuote?.category[body.catId].items,
                  [body.id]: {
                    ...prevQuote?.category[body.catId].items[body.id],
                    sale_price: value,
                  },
                },
              },
            },
          };
        case "quantity":
          return {
            ...prevQuote,
            category: {
              ...prevQuote?.category,
              [body.catId]: {
                ...prevQuote?.category[body.catId],
                items: {
                  ...prevQuote?.category[body.catId].items,
                  [body.id]: {
                    ...prevQuote?.category[body.catId].items[body.id],
                    quantity: value,
                  },
                },
              },
            },
          };
        default:
          throw new Error("unhandled");
      }
    });

    updateQuote.mutate({ type, value, ...body });
  };

  const populateCategory =
    quotesData?.category &&
    Object.entries(quotesData.category).map(([catId, category]) => {
      return (
        <Category
          key={catId}
          {...{
            editable,
            setExpandAll,
            expandAll,
            catId,
            category,
            updateQuote,
            handleChange,
          }}
          id={props.id}
        />
      );
    });

  const total = React.useMemo(
    () => getProductTotal(quotesData?.category),
    [quotesData]
  );

  const categoryTotal = React.useMemo(
    () => getCategoryTotal(quotesData?.category),
    [quotesData]
  );

  const multipliers = React.useMemo(
    () => getMultiplersValues(categoryTotal, total, quotesData?.multiplier),
    [quotesData, categoryTotal, total]
  );

  const multiplierTotal = React.useMemo(
    () => getMultipliersTotal(multipliers),
    [multipliers]
  );

  if (!quotesData) return null;

  const handleBlur = () => quotes.refetch();

  return (
    <>
      <Segment>
        <SHeader>
          <div>
            <BreadCrumbs
              link={[
                {
                  label: `${tr("Project")} #${props.projectId}`,
                  to: `/projects/${props.projectId}`,
                },
              ]}
            />
            {editable ? (
              <Input
                style={{ width: "500px" }}
                size="large"
                ref={quoteLabelRef}
                autoFocus={focusOnLabel}
                value={quotesData.quote.label}
                onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange({
                    type: "label",
                    value: e.target.value,
                  });
                }}
              />
            ) : (
              <>
                <h2>
                  {quotesData?.quote.label}
                  <Status color={quotesData?.quote.status.color}>
                    {quotesData?.quote.status.label}
                  </Status>
                </h2>
              </>
            )}
          </div>
          <SButtonGroup>
            {quotesData.quote.status.id === 19 && (
              <Button
                // isLoading={sendQuote.isLoading}
                onClick={() =>
                  setModal({
                    component: <SendQuoteForReview id={props.id} />,
                    label: tr("Review"),
                  })
                }
                primary
              >
                {tr("Review")}
              </Button>
            )}

            {quotesData.quote.status.id === 20 &&
              (editable ? (
                <>
                  <Button primary onClick={() => setEditable(false)}>
                    {tr("Save Changes")}
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={() => setEditable(true)}>{tr("Modify")}</Button>
                </>
              ))}

            {quotesData.quote.status.id === 30 && (
              <Button
                primary
                onClick={() =>
                  setModal({
                    component: <SubmitQuoteModal projectId={props.projectId} />,
                    label: tr("Submit Quote"),
                    props: {
                      preventScroll: true,
                    },
                  })
                }
              >
                {tr("Submit Quote")}
              </Button>
            )}
            {quotesData.quote.status.id === 21 && (
              <Button primary onClick={() => convertQuote.mutate()}>
                {tr("Mark as Won")}
              </Button>
            )}

            <Popper
              container={(popper: any) => (
                <>
                  <MenuGroup>
                    {editable && (
                      <>
                        <MenuButton
                          onClick={() => {
                            popper.close();
                            setModal({
                              component: (
                                <QuoteGenerator
                                  id={props.id}
                                  refetch={() => {
                                    setModal();
                                    quotes.refetch();
                                  }}
                                />
                              ),
                              label: tr("Generator"),
                            });
                          }}
                        >
                          {tr("Generator")}
                        </MenuButton>
                        <MenuButton
                          onClick={() => {
                            setModal({
                              component: <Parts />,
                              label: tr("Parts"),
                              props: {
                                fullScreen: true,
                              },
                            });
                          }}
                        >
                          {tr("Add Part")}
                        </MenuButton>
                      </>
                    )}
                    {!editable && (
                      <MenuButton
                        onClick={() =>
                          setModal({
                            component: (
                              <SubmitQuoteModal projectId={props.projectId} />
                            ),
                            label: tr("Resubmit Quote"),
                            props: {
                              preventScroll: true,
                            },
                          })
                        }
                      >
                        {tr("Resubmit Quote")}
                      </MenuButton>
                    )}
                    {/* <MenuButton
                      onClick={() => {
                        popper.close();
                        generatePDF({
                          multipliers,
                          data: quotesData,
                          clients: quotesData.clients,
                          total,
                          multiplierTotal,
                          category: quotesData?.category,
                          customFields: customFields,
                          output: "file",
                        });
                      }}
                    >
                      Print
                    </MenuButton> */}
                    <MenuButton
                      onClick={() => {
                        setModal({
                          label: tr("Duplicate Quote"),
                          component: <DuplicateQuote id={props.id} />,
                        });
                        popper.close();

                        // duplicateQuote.mutate();
                      }}
                    >
                      {tr("Duplicate Quote")}
                    </MenuButton>
                    {deletable && (
                      <MenuButton
                        style={{ color: "red" }}
                        onClick={() => {
                          popper.close();
                          deleteQuote.mutate();
                        }}
                      >
                        {tr("Delete Quote")}
                      </MenuButton>
                    )}
                    {quotesData.quote.status.id === 21 && (
                      <MenuButton
                        style={{ color: "red" }}
                        onClick={() => {
                          popper.close();
                          quoteLost.mutate();
                        }}
                      >
                        {tr("Mark as Lost")}
                      </MenuButton>
                    )}
                  </MenuGroup>
                  <MenuGroup>
                    <MenuButton
                      primary
                      onClick={() => {
                        popper.close();
                        setModal({
                          component: <AddCategory />,
                          label: tr("Add Category"),
                        });
                      }}
                    >
                      {tr("Add Category")}
                    </MenuButton>
                    <MenuButton
                      primary
                      onClick={() => {
                        popper.close();
                        setModal({
                          component: <AddMultiplier />,
                          label: tr("Add Multiplier"),
                        });
                      }}
                    >
                      {tr("Add Multiplier")}
                    </MenuButton>
                  </MenuGroup>
                </>
              )}
              reference={({ toggle }) => (
                <Button onClick={toggle}>
                  {tr("Action")} <RiArrowDownSLine />
                </Button>
              )}
            />
          </SButtonGroup>
        </SHeader>
        <SContent>
          {editable && (
            <SButtonCon {...{ expandAll }}>
              <OutlineButton onClick={() => setExpandAll(1)}>
                {tr("Expand All")}
              </OutlineButton>
              <OutlineButton onClick={() => setExpandAll(2)}>
                {tr("Collapse All")}
              </OutlineButton>
            </SButtonCon>
          )}
          <div>
            {populateCategory}
            <Total
              data={quotesData?.multiplier}
              category={quotesData?.category}
              {...{
                multipliers,
                total,
                multiplierTotal,
              }}
            />
          </div>
        </SContent>
      </Segment>

      {/* <CustomFields
        {...{ editable, customFields, setCustomFields }}
        id={props.id}
      /> */}
      {/* {quotesData.quote.status.id !== 19 && (
        <FinalPriceReview
          {...{
            clients: quotesData.clients,
            data: quotesData.quote,
            id: props.id,
            editable: quotesData.quote.status.id === 20,
            total_amount: quotesData?.quote.total_amount,
          }}
        />
      )} */}
    </>
  );
}
