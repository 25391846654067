import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button } from "components/button";
import { Input } from "components/input";
import { Avatar } from "components/avatar";
import { useMutation, useQueryClient } from "react-query";
import { useModalContext } from "components/modal";
import { tohrs } from "utils/tohrs";
import { FaTrash } from "react-icons/fa";
import { 
  MdSubdirectoryArrowRight,
  MdKeyboardArrowRight
 } from "react-icons/md";


const Punchrow = styled.div`
  margin-top: 10px;
  border: solid;
  border-width: 1.5px;
  border-radius: 5px;
  border-color: #ff4f00;
  display: flex;
  justify-content: center;
  min-height: 10px;
  padding: 10px;
`;

const Container = styled.div`
  margin-top: 15px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Select = styled.select`
  display: inline-block;
  width: 100%;
  height: 3rem;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 1rem;
  font-size: 16px;
  background-color: #fff;
  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  float: right;
  font-size: 16px;
  padding: 10px 20px;
`;

const WorkerList = styled.div`
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;

  /* Custom Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Scrollbar styles for Internet Explorer and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;


const RemoveButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #ff4f00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #ff7043;
  }
`;


const CheckboxContainer = styled(Button)`
  display: flex;
  background-color: #FF4F00;
  align-items: center;
  margin-top: 10px;
  height: 40px;
  width:200px;
  border-radius: 5px;
  padding:10px;
  color: #fff;
`;

const StyledCheckbox = styled.input`
  margin-left: 10px;
  accent-color: #007BFF; /* Customize the color */
  width: 20px;
  height: 20px;
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  color: #fff;
`;

const PendingStatus = ({selectedStatus, currentStatus, isMainStatus = false}) => {

  // console.log('rerendered')
  // console.log("selectedStatus: ", selectedStatus, "currentStatus: ", currentStatus)
  const alternateStatuses = ["In-Progress", "On-The-Way"]

  return (
    selectedStatus === "Clock In" && currentStatus === "Clocked-Out" | currentStatus === "Clocked-Out-Lunch" | currentStatus === "Clocked-Out-Break" ?
    <p style={{ display:"flex" }}>
      <MdKeyboardArrowRight style={{ fontSize: isMainStatus ? "30px" : "20px" }} />
      <p style={{ color: "green", fontSize: isMainStatus ? "20px" : "15px" }}> ( Clock In ) </p>
    </p>
    : selectedStatus === "Clock Out For Day" && (currentStatus === "Clocked-In-Idle" || alternateStatuses.includes(currentStatus)) ?
    <p style={{ display:"flex" }}>
      <MdKeyboardArrowRight style={{ fontSize: isMainStatus ? "30px" : "20px" }} />
      <p style={{ color: "red", fontSize: isMainStatus ? "20px" : "15px" }}> ( Clock Out For Day ) </p>
    </p>
    : selectedStatus === "Clock Out For Lunch" && (currentStatus === "Clocked-In-Idle" || alternateStatuses.includes(currentStatus)) ?
    <p style={{ display:"flex" }}>
      <MdKeyboardArrowRight style={{ fontSize: isMainStatus ? "30px" : "20px" }} />
      <p style={{ color: "red", fontSize: isMainStatus ? "20px" : "15px" }}> ( Clock Out For Lunch ) </p>
    </p>
    : selectedStatus === "Clock Out For Break" && (currentStatus === "Clocked-In-Idle" || alternateStatuses.includes(currentStatus)) ?
    <p style={{ display:"flex" }}>
      <MdKeyboardArrowRight style={{ fontSize: isMainStatus ? "30px" : "20px" }} />
      <p style={{ color: "red", fontSize: isMainStatus ? "20px" : "15px" }}> ( Clock Out For Break ) </p>
    </p>
    : null
  )
}

const WorkersPanel = (props) => {
  const [workers, setWorkers] = useState([]);
  const [selectedWorker, setSelectedWorker] = useState("");
  const [teamNote, setTeamNote] = useState(null);
  const workerListRef = useRef(null);
  const bottomRef = useRef(null);
  let { isApplyToTeamLeader, setIsApplyToTeamLeader, employeeData, setEmployeeData, selectedWorkerClockType, setSelectedWorkerClockType } = props;
  let clockStatusValuePair = { "Clock In": "Clocked-In-Idle", "Clock Out For Day": "Clocked-Out", "Clock Out For Lunch": "Clocked-Out-Lunch", "Clock Out For Break": "Clocked-Out-Break" };

  let isConfirmButton = true
  let isTeamLeaderButton = true
  
  const workersOptions = employeeData.workers.filter((worker) => {
    return !workers.some((w) => w.user_id === worker.user_id);
  });
  
  useEffect(() => {
    if (workersOptions.length > 0 && !selectedWorker) {
      setSelectedWorker(workersOptions[0].user_id);
    }
  }, [workersOptions, selectedWorker]);
  
  useEffect(() => {
    if (workers.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [workers]);

  
  const handleRemoveWorker = (workerId) => {
    setWorkers((prevWorkers) => prevWorkers.filter((worker) => worker.user_id !== workerId));
    setSelectedWorker(workerId);
  };

  const handleWorkerTimeSubmit = () => {

    setIsApplyToTeamLeader(false);
    let currentTime = new Date().toLocaleString();
    currentTime = new Date(currentTime);
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business-unprotected/time_clock/workerPunches`, 
      {
        data: workers.map((worker) => ({ ...worker, event_type: selectedWorkerClockType.toString(), time: currentTime })),
        teamLeaderData: isApplyToTeamLeader ? employeeData : null,
        teamNote: teamNote,
      }).then((res) => {
        // modify the worker status in the workers array
        // console.log("res: ", res.data.teamLeaderObj)

        setWorkers(res.data.data)
        setTeamNote(null)
        if (isApplyToTeamLeader) {
          // console.log("setting team leader data")
          setEmployeeData(res.data.teamLeaderObj)
        }
      }).catch((err) => {
        // console.log(err.response.data)
      }
      
    )
  }

  const allStatusesMatch = (isConfirmButton=false, isTeamLeaderButton=false) => {
    if (workers.length === 0) return false;
    let clockStatusValuePair = { "Clock In": "Clocked-In-Idle", "Clock Out For Day": "Clocked-Out", "Clock Out For Lunch": "Clocked-Out-Lunch", "Clock Out For Break": "Clocked-Out-Break" };
    let clockedOutStatusTypes = ["Clocked-Out", "Clocked-Out-Lunch", "Clocked-Out-Break"];
    let clockedInStatusTypes = ["Clocked-In-Idle"]
    // console.log("status check")

    if (!isConfirmButton) {
      let localWorkers = [...workers, employeeData];

      // console.log(employeeData.currentstatus, clockStatusValuePair[selectedWorkerClockType])

      if (clockedOutStatusTypes.includes(employeeData.currentstatus) && clockedOutStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType])) {
        return true
      } else if (clockedInStatusTypes.includes(employeeData.currentstatus) && clockedInStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType])) {
        return true
      }

      let isAllClockOutType = localWorkers.every((worker) => clockedOutStatusTypes.includes(worker.currentstatus));
      let selectedIsClockOutType = clockedOutStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType]);

      let isAllClockInType = localWorkers.every((worker) => clockedInStatusTypes.includes(worker.currentstatus));
      let selectedIsClockInType = clockedInStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType]);

      if (isAllClockOutType && selectedIsClockOutType) {
        return true
      } else if (isAllClockInType && selectedIsClockInType) {
        return true
      } else {
        return false
      }

    } else {
      let localWorkers = [...workers]

      if (isApplyToTeamLeader) {
        localWorkers = [...workers, employeeData];
      }

      let isAllClockOutType = localWorkers.every((worker) => clockedOutStatusTypes.includes(worker.currentstatus));
      let selectedIsClockOutType = clockedOutStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType]);

      let isAllClockInType = localWorkers.every((worker) => clockedInStatusTypes.includes(worker.currentstatus));
      let selectedIsClockInType = clockedInStatusTypes.includes(clockStatusValuePair[selectedWorkerClockType]);

      if (isAllClockOutType && selectedIsClockOutType) {
        return true
      } else if (isAllClockInType && selectedIsClockInType) {
        return true
      } else {
        return false
      }
    }

  };
  
  return (
    <Container>
    <Title>Team Panel</Title>
    <div style={{ display: "flex", justifyContent: "space-between", margin: "10px", alignItems: "center", gap: "10px" }}>
      <Select
        value={selectedWorker}
        onChange={(e) => {
          setSelectedWorker(e.target.value);
        }}
        >
        {workersOptions.length > 0 ? (
          workersOptions.map((worker) => (
          <option key={worker.user_id} value={worker.user_id}>
            {worker.first_name + " " + worker.last_name}
          </option>
          ))
        ) : (
          <option value="" disabled>
            No members available
          </option>
        )}
      </Select>
      <StyledButton
        primary
        onClick={() => {
          const workerToAdd = workersOptions.find((worker) => worker.user_id === selectedWorker);
          if (workerToAdd) {
            setWorkers([...workers, workerToAdd]);
            setSelectedWorker("");
          }
        }}
      >
      Add
      </StyledButton>
      <StyledButton
        primary
        onClick={() => {
          setWorkers([...workers, ...workersOptions]);
          setSelectedWorker("");
        }}
      >
        Add All
      </StyledButton>
    </div>
    
    <WorkerList ref={workerListRef}>
      {workers.length > 0 &&
      workers.map((worker) => (
        <div style={{ borderBottom: "1px solid #eee", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div key={worker.user_id} style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <RemoveButton onClick={() => handleRemoveWorker(worker.user_id)} style={{ marginLeft: "auto" }}>
              <FaTrash />
            </RemoveButton>
            <p style={{ textAlign: "center", flex: 1 }}>
              {worker.first_name + " " + worker.last_name}
            </p>
          </div>
          <div style={{ display:"flex", marginLeft: "auto" }}>
            <MdSubdirectoryArrowRight />
            <p>{worker.currentstatus}</p>
            <PendingStatus selectedStatus={selectedWorkerClockType} currentStatus={worker.currentstatus} />
          </div>
        </div>

      ))}
      <div ref={bottomRef}></div>
    </WorkerList>

    { workers.length > 0 && 
      <div style={{ height:"fit-content" }}>
        <Select
          onChange={(e) => {
            e.persist();
            setSelectedWorkerClockType(e.target.value);
            setIsApplyToTeamLeader(false);
          }}
          value={selectedWorkerClockType}
        >
          <option value="" selected disabled>
            Please Select Clock Type
          </option>
          <option value="Clock In">Clock In</option>
          <option value="Clock Out For Day">Clock Out For Day</option>
          <option value="Clock Out For Lunch">Clock Out For Lunch</option>
          <option value="Clock Out For Break">Clock Out For Break</option>
        </Select>

        <p style={{ textAlign: "center" }}>Add Note:</p>
        <div>
          <Input
            type="text"
            onChange={(e) => {
              setTeamNote(e.target.value);
            }}
            // disabled={workersPanel}
            value={teamNote}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px", gap: "10px" }}>

          <CheckboxContainer onClick={() => setIsApplyToTeamLeader(!isApplyToTeamLeader)} disabled={!selectedWorkerClockType || allStatusesMatch(false, isTeamLeaderButton)}>

            <StyledCheckbox
              type="checkbox"
              onClick={(e) => {
                e.stopPropagation();
                setIsApplyToTeamLeader(!isApplyToTeamLeader);
              }}
              checked={isApplyToTeamLeader}
              disabled={!selectedWorkerClockType || allStatusesMatch(false, isTeamLeaderButton)}
            />          
            Apply to Team Leader
          </CheckboxContainer>
          
          <StyledButton
            primary
            onClick={handleWorkerTimeSubmit}
            disabled={!selectedWorkerClockType || allStatusesMatch(isConfirmButton)}
          >
            Confirm
          </StyledButton>
        </div>
      </div>
    }

    </Container>
  );
};


const TimeConfirmModal = (a) => {
  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const [workersPanel, setWorkersPanel] = useState(false);
  const [selectedWorkerClockType, setSelectedWorkerClockType] = useState()
  const [isApplyToTeamLeader, setIsApplyToTeamLeader] = useState(false);
  const [employeeData, setEmployeeData] = useState(a.data);

  const [note, setnote] = useState("");

  let clockedOutStatusTypes = ["Clocked-Out", "Clocked-Out-Lunch", "Clocked-Out-Break"];

  const [punchType, setPunchType] = useState(
    clockedOutStatusTypes.includes(employeeData.currentstatus) ? "Clock In" : "Clock Out For Day"
  );
  
  const SendTime = useMutation((info) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business-unprotected/time_clock`, {
        data: info.clocktime,
        pin: employeeData.pin,
        note: info.note,
        punchType: info.punchType,
        isfromadmin: info.isfromadmin,
      })
      .then((res) => {
          queryClient.invalidateQueries();
          if (a.setpin) {
            a.setpin("");
          }
        // }
      });
  });




  // React.useEffect(() => {
  //   console.log("employeeData: ", employeeData)
  //   setPunchType(clockedOutStatusTypes.includes(employeeData.currentStatus) ? "Clock In" : "Clock Out For Day");
  // }, [employeeData])

  // React.useEffect(() => {
  //   console.log("set to punch type: ", punchType)
  // }, [punchType])

  const EmployeeClockStatus = () => {
    let clockedOutStatusTypes = ["Clocked-Out", "Clocked-Out-Lunch", "Clocked-Out-Break"];

    let color = clockedOutStatusTypes.includes(employeeData.currentstatus) ? "red" : "green";
    let clockTypeText = clockedOutStatusTypes.includes(employeeData.currentstatus) ? "Clocked out at: " : "Clocked in at: ";

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "10px", alignItems: "center" }}>
          <div style={{ fontSize: "15px" }}>You are currently: </div>
          <div style={{ fontSize: "20px", color: color }}>{employeeData.currentstatus}</div>
          {isApplyToTeamLeader && <PendingStatus selectedStatus={selectedWorkerClockType} currentStatus={employeeData.currentstatus} isMainStatus />}
        </div>
        {
          employeeData.lastPunch &&
            <div style={{ display: "flex", justifyContent: "space-between", margin: "10px" }}>
              <div style={{ fontSize: "15px" }}>{clockTypeText}</div>
              <div style={{ fontSize: "15px" }}>{new Date(employeeData.lastPunch).toLocaleString()}</div>
            </div>
        }
      </>
    );
  };

  const ClockInOutOptions = () => {
    let clockedOutStatusTypes = ["Clocked-Out", "Clocked-Out-Lunch", "Clocked-Out-Break"];

    return (
      <Select
        onChange={(e) => {
          e.persist();
          setPunchType(e.target.value);
        }}
        value={punchType}
        disabled={workersPanel}
      >
        {!clockedOutStatusTypes.includes(employeeData.currentstatus) ? (
          <>
            <option key="Clock Out For Day" value="Clock Out For Day">
              Clock Out For Day
            </option>
            <option key="Clock Out For Lunch" value="Clock Out For Lunch">
              Clock Out For Lunch
            </option>
          </>
        ) : (
          <option key="Clock In" value="Clock In">
            Clock In
          </option>
        )}
      </Select>
    );
  };

  const addBreakAlertModal = () => {
    const containerStyle = {
      textAlign: "center",
      padding: "20px"
    };

    const messageStyle = {
      fontSize: "16px",
      color: "#555",
      lineHeight: "1.5",
      marginBottom: "20px"
    };

    const buttonStyle = {
      fontSize: "16px",
      color: "white",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "5px",
      padding: "10px 20px",
      cursor: "pointer",
      margin: "10px"
    };

    return (
      <div style={containerStyle}>
        <p style={messageStyle}>
          You've worked a shift longer than 6 hours without a break.
        </p>
        <p style={messageStyle}>
          Would you like to add a break before clocking out?
        </p>
        <Button
          style={buttonStyle}
          onClick={() => {
            setModal();

            const clocktimelocal = new Date().toLocaleString();
            const clocktime = new Date(clocktimelocal);
            const breakTimeStart = new Date(clocktime.getTime() - 60 * 60000);
            SendTime.mutate({ clocktime: breakTimeStart, note: note, punchType: "Clock Out For Break", isfromadmin: true });

            const breakTimeEnd = new Date(clocktime.getTime() - 30 * 60000);
            SendTime.mutate({ clocktime: breakTimeEnd, note: note, punchType: "Clock In", isfromadmin: true });

            SendTime.mutate({ clocktime: clocktime, note: note, punchType: "Clock Out For Day", isfromadmin: true });
          }}
        >
          Yes
        </Button>
        <Button
          style={buttonStyle}
          onClick={() => {
            setModal();
            const clocktimelocal = new Date().toLocaleString();
            const clocktime = new Date(clocktimelocal);
            SendTime.mutate({ clocktime: clocktime, note: note, punchType: punchType, isfromadmin: true });
          }}
        >
          No
        </Button>
      </div>
    );
  }

  const handleSubmit = () => {
    if (punchType === "Clock Out For Day" && employeeData.addBreak) {
      setModal({
        component: addBreakAlertModal(),
        label: "Add Break",
      });
      return;
    }
    setModal();
    const clocktimelocal = new Date().toLocaleString();
    const clocktime = new Date(clocktimelocal);
    SendTime.mutate({ clocktime: clocktime, note: note, punchType: punchType });
  }



  return (
    <Container>
      <Avatar
        size={100}
        src={a.data.avatar}
        user_id={employeeData.pin.slice(2)}
        style={{ margin: "auto", width: "fit-content", textAlign: "center" }}
      />
      <p style={{ textAlign: "center" }}>{employeeData.name}</p>
      <EmployeeClockStatus />
      <ClockInOutOptions />
      <p style={{ textAlign: "center" }}>Add Note:</p>
      <div>
        <Input
          type="text"
          onBlur={(e) => {
            setnote(e.target.value);
          }}
          disabled={workersPanel}
        />
      </div>
      {employeeData.workers && (
        <Button primary onClick={() => setWorkersPanel(!workersPanel)} style={{ marginTop: "15px" }}>
          {workersPanel ? "Hide Team" : "Show Team"}
        </Button>
      )}
      <StyledButton
        primary
        onClick={handleSubmit}
        disabled={workersPanel}
      >
        Confirm
      </StyledButton>
      {workersPanel && <WorkersPanel {...{isApplyToTeamLeader, setIsApplyToTeamLeader, employeeData, setEmployeeData, selectedWorkerClockType, setSelectedWorkerClockType}} />}
    </Container>
  );
};

export default TimeConfirmModal;
