import Select, {
  GroupTypeBase,
  StylesConfig,
  Props,
  ControlProps,
  IndicatorProps,
  IndicatorContainerProps,
  SingleValueProps,
  ValueContainerProps,
  InputProps,
} from "react-select";
import { MenuPortalProps } from "react-select/src/components/Menu";
import { theme } from "styles/theme";
import AsyncSelect from "react-select/async";
import { get } from "lodash";
import { CSSObject } from "styled-components";
import { useTranslation } from 'react-i18next';

const customStyles: StylesConfig<
  { label: string; value: string },
  false,
  GroupTypeBase<{ label: string; value: string }>
> = {
  control: (
    base: CSSObject,
    state: ControlProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: "0.5em",
    minHeight: 0,
    fontSize: "0.9rem",
    boxShadow: "none",
    margin: 0,
    "&:hover": {
      background: "transparent",
      border: "1px solid #f0f0f0",
    },
    "&:focus": {
      padding: 0,
    },
    border: `1px solid ${state.isFocused ? "#3498db" : theme.border.light}`,
  }),
  indicatorSeparator: (
    base: CSSObject,
    props: IndicatorProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: 0,
    margin: "0 0.1em",
  }),
  indicatorsContainer: (
    base: CSSObject,
    props: IndicatorContainerProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: 0,
  }),
  menuPortal: (
    base: CSSObject,
    props: MenuPortalProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    zIndex: 99999,
    minWidth: "200px",
  }),
  dropdownIndicator: (
    base: CSSObject,
    props: IndicatorProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (
    base: CSSObject,
    props: SingleValueProps<{ label: string; value: string }>
  ) => ({
    ...base,
    padding: 0,
    lineHeight: 1.4,
  }),
  valueContainer: (
    base: CSSObject,
    props: ValueContainerProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: 0,
    lineHeight: 1.4,
    // If you are referencing theme.height.regular, uncomment and use it as needed
    // height: theme.height.regular,
  }),
  input: (base: CSSObject, props: InputProps) => ({
    ...base,
    padding: 0,
    margin: 0,
    lineHeight: 1.4,
  }),
  clearIndicator: (
    base: CSSObject,
    props: IndicatorProps<{ label: string; value: string }, false>
  ) => ({
    ...base,
    padding: 0,
  }),
};

export type TSelectProps = { isAsync?: boolean } & Props;

export default function SelectV2(props: TSelectProps) {
  const { t } = useTranslation();
  const tBase = "components.react-selectv2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const value =
    props.value ??
    (props.name && get(props.formik?.values, props.name)) ??
    (props.formik && "") ??
    undefined;

  if (props.isAsync)
    return (
      <AsyncSelect
        cacheOptions
        styles={customStyles}
        value={value}
        classNamePrefix={"Select"}
        menuPortalTarget={document.getElementById("react-select")}
        menuPosition={"absolute"}
        menuPlacement={props.menuPlacement || "auto"}
        // menuShouldScrollIntoView={false}
        placeholder={props.placeholder || tr("None")}
        {...props}
      />
    );
  return (
    <Select
      styles={customStyles}
      classNamePrefix={"Select"}
      menuPortalTarget={document.getElementById("react-select")}
      // menuPosition={"absolute"}
      menuPlacement={"auto"}
      placeholder={props.placeholder || tr("None")}
      value={value}
      {...props}
    />
  );
}
