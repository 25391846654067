// Curtains that are used in the animation when opening the upload page
import React, { useEffect } from 'react';
import { useUpload } from '../context/UploadContext';

interface CurtainsProps {
  isHovered: boolean;
  selected: boolean;
  animEnd: boolean;
}

const Curtains: React.FC<CurtainsProps> = ({ isHovered, selected, animEnd }) => {
  const {
    triggerCurtains,
    setTriggerCurtains,
    animationStep,
    setAnimationStep,
    setOrder,
    importComplete,
    setImportComplete,
    setImportCompleteContentShow,
    hasDuplicates,
    duplicatesComplete,
    triggerCurtainsClose,
    triggerCurtainsOpen,
    setTriggerCurtainsOpen,
    setTriggerCurtainsClose,
    pauseCurtains,
    order,
    headers
  } = useUpload();

  const onAnimationEnd = (pauseCurtains: boolean, order: number) => {
    if (pauseCurtains && headers) {
      setOrder(2.5);
      return;
    }
    if (order === 1 && triggerCurtainsOpen && importComplete) {
      setOrder(2);  // Move to the next order when curtains open animation ends and import is complete
    }
    if (order === 2 && triggerCurtainsClose && headers) {
      setOrder(3); 
    }
    if (order !== 0) { 
      setTriggerCurtainsClose(false)
      setTriggerCurtainsOpen(true);
    }
  };

  return (
    <>
      <div className={`
          ${isHovered ? "block" : "hidden"} 
          ${triggerCurtainsClose ? "animate-curtainTopDown" : triggerCurtainsOpen ? "animate-curtainTopUp" : ""}
          absolute bg-gradient-to-r from-cyan-500 to-blue-500 
          ease-in-out -top-[188px] w-full h-full
        `}
        onAnimationEnd={() => onAnimationEnd(pauseCurtains, order)}
      />
      <div className={`
          ${isHovered ? "block" : "hidden"}
          ${triggerCurtainsClose ? "animate-curtainBottomUp" : triggerCurtainsOpen ? "animate-curtainBottomDown" : ""}
          absolute bg-gradient-to-r from-cyan-500 to-blue-500 
          ease-in-out -bottom-[188px] w-full h-full
        `}

      />
    </>
  );
};

export default Curtains;