import * as React from "react";
import { STextArea } from "./style";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import { FormikProps } from "formik";

export type TTextAreaProps = TextareaAutosizeProps & {
  error?: string;
  formik?: FormikProps<any>;
};
export default function TextArea(props: TTextAreaProps) {
  return (
    <STextArea
      onChange={props.formik?.handleChange}
      onBlur={props.formik?.handleBlur}
      {...props}
    />
  );
}
