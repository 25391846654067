import Spinner from "components/spinner";
import { useTranslation } from 'react-i18next';

interface IInputSubmitProps {
  isLoading: boolean;
  isError: boolean;
}

const InputSubmit = ({ isLoading, isError }: IInputSubmitProps) => {
  const { t } = useTranslation();
  const tBase = "components._ui";
  const tr = (key: string) => t(`${tBase}.${key}`);

  if (isLoading) {
    return (
      <div className="p-2 pl-4 pr-4 rounded-md bg-slate-200 hover:bg-slate-300 cursor-pointer items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-2 pl-4 pr-4 rounded-md bg-slate-200 hover:bg-slate-300 cursor-pointer items-center justify-center">
        <p className="text-red-400 text-sm">{tr("Error")}</p>
      </div>
    );
  }

  return (
    <input
      className="p-2 pl-4 pr-4 rounded-md bg-slate-200 hover:bg-slate-300 cursor-pointer items-center justify-center"
      type="submit"
    />
  );
};

export { InputSubmit };
