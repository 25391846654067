import Axios from "axios";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { getContrastTextColorRGB } from "utils/getContrastTextColor";
import { useTranslation } from 'react-i18next';

const statusStyle = {
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	control: (provided, state) => {
		let background = `rgba(${state.getValue()[0].color})`;
		return {
			...provided,
			margin: 0,
			padding: "0.1rem",
			border: 0,
			minHeight: 20,
			width: "100%",
			minWidth: "120px",
			maxWidth: "150px",
			height: "100%",
			background,
			borderRadius: "5px",
			cursor: "pointer",
			fontWeight: "bold",
			textTransform: "capitalize",
		};
	},
	dropdownIndicator: (base, state) => ({
		...base,
		color: getContrastTextColorRGB(state.getValue()[0].color),
		padding: 0,
	}),
	valueContainer: (base) => ({
		...base,
		padding: "0 0.2rem",
		fontSize: "0.8rem",
	}),
	singleValue: (base, state) => ({
		...base,
		color: getContrastTextColorRGB(state.getValue()[0].color),
	}),
	menu: (base) => ({
		...base,
		// width: "250px",
		textAlign: "left",
	}),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ handleStatusChange, value, type }) {
  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key) => t(`${tBase}.${key}`);

	const [options, setOptions] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await Axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/company/status/all?type=${type}`
				);
				setOptions(res.data);
			} catch (e) {
				console.error(e);
			}
		};
		fetchData();
	}, [type]);

	return (
		<Select
			styles={statusStyle}
			components={{ Control }}
			onChange={handleStatusChange}
			menuPortalTarget={document.getElementById("react-select")}
			menuPosition={"absolute"}
			menuPlacement={"auto"}
			blurInputOnSelect
			isSearchable={false}
			value={value}
			// options={options.map((option) => ({ ...option, value: option.id }))}
			options={options.map((option) => ({
				...option,
				value: option.id,
				label: tr(option.label) // Translating the label here
			}))}
		/>
	);
}

const ControlCon = styled.div`
	display: flex;
	background: blue;
`;

const Control = ({ children, ...props }) => (
	<components.Control {...props}>{children}</components.Control>
);
