import { useCallback, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import debounce from "lodash/debounce";
import { FaSortAlphaDown } from "react-icons/fa";

import { Button } from "components/button";
import OverviewData from "./types/OverviewData";
import InvoiceCard from "./components/InvoiceCard";
import CustomerCard from "./components/CustomerCard";
import PaymentCard from "./components/PaymentCard";
import Customer from "./types/Customer";
import Invoice from "./types/Invoice";

import { useTranslation } from "react-i18next";

type Props = {};

type CustomerFilter = {
  search: string;
  hasMessage: boolean | null;
  hasQboId: boolean | null;
  sortColumn: string;
  sortDirection: string;
};

type InvoiceFilter = {
  search: string;
  hasMessage: boolean | null;
  hasQboId: boolean | null;
  sortColumn: string;
  sortDirection: string;
};

type PaymentFilter = {
  search: string;
  hasMessage: boolean | null;
  hasQboId: boolean | null;
  sortColumn: string;
  sortDirection: string;
};

const customerSortOptions = [
  { label: "Creation Date", value: "creation_date" },
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Company Name", value: "company_name" },
];

const invoiceSortOptions = [
  { label: "Creation Date", value: "creation_date" },
  { label: "Customer First Name", value: "first_name" },
  { label: "Customer Last Name", value: "last_name" },
  { label: "Company Name", value: "company_name" },
  { label: "Total Amount", value: "total_amount" },
];

const paymentSortOptions = [
  { label: "Creation Date", value: "creation_date" },
  { label: "Customer First Name", value: "first_name" },
  { label: "Customer Last Name", value: "last_name" },
  { label: "Company Name", value: "company_name" },
  { label: "Total Amount", value: "amount" },
];

const authenticate = async () => {
  window.location.href = `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/oauth/start`;
};

const QuickbooksOnline = (props: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.online.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [customerFilter, setCustomerFilter] = useState<CustomerFilter>({
    search: "",
    hasMessage: null,
    hasQboId: null,
    sortColumn: "ID",
    sortDirection: "DESC",
  });
  const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilter>({
    search: "",
    hasMessage: null,
    hasQboId: null,
    sortColumn: "ID",
    sortDirection: "DESC",
  });
  const [paymentFilter, setPaymentFilter] = useState<PaymentFilter>({
    search: "",
    hasMessage: null,
    hasQboId: null,
    sortColumn: "ID",
    sortDirection: "DESC",
  });

  const [showCustomerSortPopup, setShowCustomerSortPopup] = useState(false);
  const [showInvoiceSortPopup, setShowInvoiceSortPopup] = useState(false);
  const [showPaymentSortPopup, setShowPaymentSortPopup] = useState(false);

  const fetchOverview = async () => {
    const response = await axios.get<OverviewData>(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/overview`,
      {
        params: {
          customerFilter: JSON.stringify(customerFilter),
          invoiceFilter: JSON.stringify(invoiceFilter),
          paymentFilter: JSON.stringify(paymentFilter),
        },
      }
    );
    return response.data;
  };

  const refreshToken = async () => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/oauth/refresh`
    );
    invalidateQueries();
  };

  const { data, isError, error } = useQuery(
    ["quickbooks-online", customerFilter, invoiceFilter, paymentFilter],
    fetchOverview
  );

  const debouncedCustomerSearch = useCallback(
    debounce((value: string) => {
      setCustomerFilter((prevFilter) => ({ ...prevFilter, search: value }));
    }, 300),
    []
  );

  const debouncedInvoiceSearch = useCallback(
    debounce((value: string) => {
      setInvoiceFilter((prevFilter) => ({ ...prevFilter, search: value }));
    }, 300),
    []
  );

  const debouncedPaymentSearch = useCallback(
    debounce((value: string) => {
      setPaymentFilter((prevFilter) => ({ ...prevFilter, search: value }));
    }, 300),
    []
  );

  const handleCustomerSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedCustomerSearch(e.target.value);
  };

  const handleInvoiceSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedInvoiceSearch(e.target.value);
  };

  const handlePaymentSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedPaymentSearch(e.target.value);
  };

  const toggleFilterValue = (currentValue: boolean | null) => {
    if (currentValue === null) return true;
    if (currentValue === true) return false;
    return null;
  };

  const handleCustomerFilterChange = (filter: keyof CustomerFilter) => {
    setCustomerFilter((prevFilter) => {
      const currentValue = prevFilter[filter];

      if (typeof currentValue === "boolean" || currentValue === null) {
        return {
          ...prevFilter,
          [filter]: toggleFilterValue(currentValue),
        };
      }
      return prevFilter;
    });
  };

  const handleInvoiceFilterChange = (filter: keyof InvoiceFilter) => {
    setInvoiceFilter((prevFilter) => {
      const currentValue = prevFilter[filter];

      if (typeof currentValue === "boolean" || currentValue === null) {
        return {
          ...prevFilter,
          [filter]: toggleFilterValue(currentValue),
        };
      }
      return prevFilter;
    });
  };

  const handlePaymentFilterChange = (filter: keyof PaymentFilter) => {
    setPaymentFilter((prevFilter) => {
      const currentValue = prevFilter[filter];

      if (typeof currentValue === "boolean" || currentValue === null) {
        return {
          ...prevFilter,
          [filter]: toggleFilterValue(currentValue),
        };
      }
      return prevFilter;
    });
  };

  const handleSortCustomerChange = (column: string, direction: string) => {
    setCustomerFilter((prevFilter) => ({
      ...prevFilter,
      sortColumn: column,
      sortDirection: direction,
    }));
  };

  const handleSortInvoiceChange = (column: string, direction: string) => {
    setInvoiceFilter((prevFilter) => ({
      ...prevFilter,
      sortColumn: column,
      sortDirection: direction,
    }));
  };

  const handleSortPaymentChange = (column: string, direction: string) => {
    setPaymentFilter((prevFilter) => ({
      ...prevFilter,
      sortColumn: column,
      sortDirection: direction,
    }));
  };

  const customers = useMemo(() => data?.customers || [], [data]);
  const invoices = useMemo(() => data?.invoices || [], [data]);
  const payments = useMemo(() => data?.payments || [], [data]);

  const connectionStatus = useMemo(() => {
    if (!data || !data.connectionDetails) return null;
    const { access_token_expiry, expires_in, refresh_token_expiry } =
      data.connectionDetails;
    const expiryDate = new Date(
      new Date(access_token_expiry).getTime() + expires_in * 1000
    );
    const isExpired = new Date() > expiryDate;

    return {
      isExpired,
      message: isExpired
        ? tr("Connection to QuickBooks has expired. Please reconnect.")
        : tr("Connected to QuickBooks."),
      expiryDate: expiryDate.toLocaleString(),
      access_token_expiry: new Date(access_token_expiry).toLocaleString(),
      refresh_token_expiry: new Date(refresh_token_expiry).toLocaleString(),
    };
  }, [data]);

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries("quickbooks-online");
  };

  return (
    <div className="text-sm h-[calc(100vh-184px)] flex flex-col">
      <div className="flex flex-row items-center justify-between mb-2">
        <h3>Quickbooks Online</h3>
        <div>
          <Button onClick={refreshToken}>{tr("Refresh Token")}</Button>
          <Button
            className="ml-2"
            onClick={authenticate}
            primary={connectionStatus?.isExpired}
          >
            {connectionStatus?.isExpired
              ? tr("Reconnect to QuickBooks Online")
              : tr("Connect to QuickBooks Online")}
          </Button>
        </div>
      </div>
      <div className="border rounded bg-secondary bg-opacity-10 h-14 p-1">
        {/* {isLoading && <div>{tr("Loading")}...</div>} */}
        {isError && (
          <div>
            Error: {error ? (error as Error).message : "Failed to fetch data"}
          </div>
        )}
        {connectionStatus && (
          <div>
            <p>Access Token Expires: {connectionStatus?.access_token_expiry}</p>
            <p>
              Refresh Token Expires: {connectionStatus?.refresh_token_expiry}
            </p>
          </div>
        )}
      </div>
      <div className="h-4" />
      <div className="flex flex-row flex-grow overflow-hidden">
        <div className="border border-black rounded-md p-2 bg-slate-200 mb-2 pt-2 w-[32%] mr-1 flex flex-col">
          <div className="flex flex-row justify-between items-center mb-2">
            <h4 className="mr-2">{tr("Customers")}</h4>
            <div className="flex flex-row items-center">
              <button
                onClick={() => handleCustomerFilterChange("hasMessage")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  customerFilter.hasMessage === null
                    ? "bg-gray-300"
                    : customerFilter.hasMessage
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {customerFilter.hasMessage === null
                  ? tr("Any Message")
                  : customerFilter.hasMessage
                  ? tr("Has Message")
                  : tr("No Message")}
              </button>
              <button
                onClick={() => handleCustomerFilterChange("hasQboId")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  customerFilter.hasQboId === null
                    ? "bg-gray-300"
                    : customerFilter.hasQboId
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {customerFilter.hasQboId === null
                  ? tr("Any QBO ID")
                  : customerFilter.hasQboId
                  ? tr("Has QBO ID")
                  : "No QBO ID"}
              </button>
              <div className="relative">
                <button
                  className="p-2 rounded text-[11px] mr-2 bg-gray-300"
                  onClick={() =>
                    setShowCustomerSortPopup(!showCustomerSortPopup)
                  }
                >
                  <FaSortAlphaDown color="black" />
                </button>
                {showCustomerSortPopup && (
                  <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg border rounded">
                    {customerSortOptions.map((option) => (
                      <div key={option.value}>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortCustomerChange(option.value, "ASC")
                          }
                        >
                          {tr(option.label)} (ASC)
                        </button>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortCustomerChange(option.value, "DESC")
                          }
                        >
                          {tr(option.label)} (DESC)
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <input
              onChange={handleCustomerSearchChange}
              className="rounded-md border w-32 border-black"
            />
          </div>
          <div className="overflow-y-scroll flex-grow">
            {customers &&
              customers.map((customer: Customer) => (
                <CustomerCard
                  key={customer.id}
                  customer={customer}
                  invalidateQueries={invalidateQueries}
                />
              ))}
          </div>
        </div>
        <div className="border border-black rounded-md p-2 bg-slate-200 mb-2 pt-2 w-[32%] mr-1 flex flex-col">
          <div className="flex flex-row justify-between items-center mb-2">
            <h4 className="mr-2">{tr("Invoices")}</h4>
            <div className="flex flex-row items-center">
              <button
                onClick={() => handleInvoiceFilterChange("hasMessage")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  invoiceFilter.hasMessage === null
                    ? "bg-gray-300"
                    : invoiceFilter.hasMessage
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {invoiceFilter.hasMessage === null
                  ? tr("Any Message")
                  : invoiceFilter.hasMessage
                  ? tr("Has Message")
                  : tr("No Message")}
              </button>
              <button
                onClick={() => handleInvoiceFilterChange("hasQboId")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  invoiceFilter.hasQboId === null
                    ? "bg-gray-300"
                    : invoiceFilter.hasQboId
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {invoiceFilter.hasQboId === null
                  ? tr("Any QBO ID")
                  : invoiceFilter.hasQboId
                  ? tr("Has QBO ID")
                  : tr("No QBO ID")}
              </button>
              <div className="relative">
                <button
                  className="p-2 rounded text-[11px] mr-2 bg-gray-300"
                  onClick={() => setShowInvoiceSortPopup(!showInvoiceSortPopup)}
                >
                  <FaSortAlphaDown color="black" />
                </button>
                {showInvoiceSortPopup && (
                  <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg border rounded">
                    {invoiceSortOptions.map((option) => (
                      <div key={option.value}>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortInvoiceChange(option.value, "ASC")
                          }
                        >
                          {tr(option.label)} (ASC)
                        </button>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortInvoiceChange(option.value, "DESC")
                          }
                        >
                          {tr(option.label)} (DESC)
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <input
              onChange={handleInvoiceSearchChange}
              className="rounded-md border w-32 border-black"
            />
          </div>
          <div className="overflow-y-scroll flex-grow ">
            {invoices &&
              invoices.map((invoice: Invoice) => (
                <InvoiceCard
                  key={invoice.id}
                  invoice={invoice}
                  invalidateQueries={invalidateQueries}
                />
              ))}
          </div>
        </div>
        <div className="border border-black rounded-md p-2 bg-slate-200 mb-2 pt-2 w-[32%] mr-1 flex flex-col">
          <div className="flex flex-row justify-between items-center mb-2">
            <h4 className="mr-2">{tr("Payments")}</h4>
            <div className="flex flex-row items-center">
              <button
                onClick={() => handlePaymentFilterChange("hasMessage")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  paymentFilter.hasMessage === null
                    ? "bg-gray-300"
                    : paymentFilter.hasMessage
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {paymentFilter.hasMessage === null
                  ? tr("Any Message")
                  : paymentFilter.hasMessage
                  ? tr("Has Message")
                  : tr("No Message")}
              </button>
              <button
                onClick={() => handlePaymentFilterChange("hasQboId")}
                className={`p-1 rounded text-[11px] mr-2 ${
                  paymentFilter.hasQboId === null
                    ? "bg-gray-300"
                    : paymentFilter.hasQboId
                    ? "bg-green-300"
                    : "bg-red-300"
                }`}
              >
                {paymentFilter.hasQboId === null
                  ? tr("Any QBO ID")
                  : paymentFilter.hasQboId
                  ? tr("Has QBO ID")
                  : "No QBO ID"}
              </button>
              <div className="relative">
                <button
                  className="p-2 rounded text-[11px] mr-2 bg-gray-300"
                  onClick={() => setShowPaymentSortPopup(!showPaymentSortPopup)}
                >
                  <FaSortAlphaDown color="black" />
                </button>
                {showPaymentSortPopup && (
                  <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg border rounded">
                    {paymentSortOptions.map((option) => (
                      <div key={option.value}>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortPaymentChange(option.value, "ASC")
                          }
                        >
                          {tr(option.label)} (ASC)
                        </button>
                        <button
                          className="block w-full text-left p-2 hover:bg-gray-200"
                          onClick={() =>
                            handleSortPaymentChange(option.value, "DESC")
                          }
                        >
                          {tr(option.label)} (DESC)
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <input
              onChange={handlePaymentSearchChange}
              className="rounded-md border w-32 border-black"
            />
          </div>
          <div className="overflow-y-scroll flex-grow">
            {payments &&
              payments.map((payment: any) => (
                <PaymentCard
                  key={payment.id}
                  payment={payment}
                  invalidateQueries={invalidateQueries}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickbooksOnline;
