import Segment from "components/segment";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import { Project } from "../..";
import { useTranslation } from 'react-i18next';

type Props = {
  data?: Project;
};
export default function Progress({ data }: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.components.progress";
  const tr = (key: string) => t(`${tBase}.${key}`);

  if (!data) return null;

  if (data.status.id !== 28 && data.status.id !== 29) return null;

  const total = dollarFormatter(data.total_amount);

  const donePercent = data.total_amount
    ? `${Math.round(((data.actual_amount || 0) / data.total_amount) * 100)}%`
    : "0%";

  const invoicePercent = `${data.billed || 0}%`;

  const invoiceAmount = dollarFormatter(
    ((data.billed || 0) / 100) * (data.total_amount || 0)
  );

  return (
    <div className={`bg-orange-100/50 rounded p-4`}>
      <div>
        <h2>Total: {total}</h2>
      </div>
      <SEachCon color={"#8E44AD"}>
        <div>
          <h4>{tr("Work Done")}</h4>
        </div>
        <div>
          {donePercent !== "0%" && (
            <SBar color={"#8E44AD"} percent={donePercent} />
          )}

          <span>
            {donePercent} ({dollarFormatter(data.actual_amount)})
          </span>
        </div>
      </SEachCon>
      <SEachCon color={"#3498DB"}>
        <div>
          <h4>{tr("Invoice")}</h4>
        </div>
        <div>
          {invoicePercent !== "0%" && (
            <SBar color={"#3498DB"} percent={invoicePercent} />
          )}
          <span>
            {invoicePercent} ({invoiceAmount})
          </span>
        </div>
      </SEachCon>
    </div>
  );
}

const Con = styled(Segment)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 5px;
  //   border: 1px solid ${theme.border.dark};
  background: ${theme.bg.lightorange};
`;

const SEachCon = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.color};
  margin-top: 1rem;
  > div {
    display: flex;
    align-items: center;

    > h3 {
      margin: 0;
      padding: 0;
    }
    > span {
      font-weight: bold;
      white-space: nowrap;
    }
  }
`;

const SBar = styled.div<{ color: string; percent: string }>`
  width: ${(props) => props.percent};
  background: ${(props) => props.color};
  height: 7px;
  border-radius: 5px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-right: 1rem;
`;
