import * as React from "react";
import styled from "styled-components";
import CustomerForm from "./customerForm";
import Questions from "./questions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { cloneDeep } from "lodash";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { useHistory } from "react-router-dom";
import NewCustomerSimplified from "containers/newCustomerSimplified";
import CustomerForm2 from "./customerForm2";
import { Button } from "components/button";
import { IoMdAdd } from "react-icons/io";
import { TInitialData, TQuestions, TQuestionsCategory } from "types/request";
import { useTranslation } from 'react-i18next';


const initialValues = {
  first: "",
  last: "",
  address: "",
  city: "",
  state: "",
  zipcode: null,
  email: "",
  phone: "",
};

type Props = {
  // refetch: () => void;
  requestId?: string;
  customerId?: string;
  addressId?: number;
};

export default function RequestModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.requests.newRequestModal.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const initialData = React.useRef<TInitialData>({
    questions: undefined,
    link: undefined,
  });
  const { setModal } = useModalContext(),
    queryClient = useQueryClient(),
    { showSnackbar } = useSnackbarContext(),
    history = useHistory();
  const [page, setPage] = React.useState<0 | 1 | 2>(0),
    [answers, setAnswers] = React.useState<TQuestions[] | undefined>(),
    [link, setLink] = React.useState<string | undefined>(),
    [customerId, setCustomerId] = React.useState<string | null>(),
    [address, setAddress] = React.useState<number>(),
    [managers, setManagers] = React.useState<
      { value: string; label: string }[] | null
    >();

  const initialPopulate = (questions: TQuestionsCategory[]) => {
    const categoryIndex = questions.findIndex(
      (category) => category.is_primary
    );
    setAnswers(questions[categoryIndex]?.questions || []);
  };

  React.useEffect(() => {
    if (props.customerId) {
      setPage(1);
      setCustomerId(props.customerId);
    }
    if (props.addressId) {
      setPage(1);
      setAddress(props.addressId);
    }
    // if (props.requestId && props.customerId && props.addressId) {
    //   setPage(1);
    //   setCustomerId(props.customerId);
    //   setAddress(props.addressId);
    // }
  }, [props.requestId, props.customerId, props.addressId]);
  React.useEffect(() => {
    setAnswers((temp) => {
      let answers = temp?.filter((ans) => ans.is_primary);
      let newQuestions = initialData.current.questions?.find(
        (category: TQuestionsCategory) => category.fk_category_id === link
      );
      if (newQuestions?.questions && answers) {
        let questions = newQuestions.questions;
        return [...answers, ...questions];
      }
      return answers;
    });
  }, [link]);
  const [PropDate,setPropDate] = React.useState(()=>{
    return Date()
  })
  const initialFetch = useQuery(
    "newRequestQuestions",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/question
    `
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        initialData.current = res;
        initialPopulate(res.questions);
      },
    }
  );

  const submitForm = useMutation(
    async () =>
      await axios
        .post(
          `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request
          `,
          { link, address, customerId, answers, managers, PropDate }
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        showSnackbar(tr("Request Added!"));
        setModal();
        history.push(`/requests/${res}/`);
      },
    }
  );
  const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-self: flex-end;
`;

  const updateForm = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/${props.requestId}
  `,
          { customerId, address }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Request Information updated!"));
        queryClient.invalidateQueries();
      },
    }
  );

  const handleNext = () => {
    if (props.requestId) {
      updateForm.mutate();
    } else {
      setPage(2);
    }
  };

  const handleCustomerSelect = (e: any) => {
    setCustomerId(e);
    setPage(1);
  };
  return (
    <Con>
      {page === 0 ? (
        <CustomerForm2 onChange={handleCustomerSelect} />
      ) : page === 1 ? (
        <NewCustomerSimplified
          id={customerId}
          requestId={props.requestId}
          addressId={address}
          handleChangeAddress={(a) => setAddress(a)}
          handleBack={() => setPage(0)}
          handleNext={() => handleNext()}
        />
      ) : (
        <Questions
          initialData={initialData.current}
          submitForm={submitForm}
          isRequestModal
          {...{ managers, setManagers, answers, setAnswers, setPage, setLink }}
        />
      )}
      {/* <Row>
        Please select the best day for us to schedule this new request on.      
        <input
          type="date"
          value={PropDate}
          onChange={(e) => {setPropDate(e.target.value)}}
        ></input>
      </Row> */}
      {/* <Button
          style={{}}
          onClick={() => {
            history.push("requests/mass-schedule");
            setModal();
          }}
        >
        <IoMdAdd /> Mass Schedule
      </Button> */}
    </Con>
    
  );
}

const Con = styled.div`
  width: 30vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
`;
