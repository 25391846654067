import { companyClient } from "api/api";
import { AxiosError, AxiosResponse } from "axios";

export const geoLocateAddress = async (address: string) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: `/property-map/geocode/${address}`,
  })
    .then(onSuccess)
    .catch(onError);
};

export const geoLocatePermanentAddress = async (address: string) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: `/property-map/geocode-permanent/${address}`,
  })
    .then(onSuccess)
    .catch(onError);
};
