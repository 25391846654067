import { Avatar } from "components/avatar";
import { NoStyleA } from "components/button";
import TableComponent, { useTable } from "components/table";
import { format } from "date-fns";
import * as React from "react";
import { TInvoice } from "types/invoice";
import { dollarFormatter } from "utils";
import { Status } from "views/request/component/status";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

export default function InvoiceTable(props: {
  // since this component is now used for both company and single customers, any is used here, but this will need to be properly typed later
  data: (TInvoice[] | undefined) | any;
  handleClick?: (row: TInvoice) => void;
  pageIndex?: number;
  // pageCount?: number;
  table?: any;
}) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.invoiceTable.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  // const table = useTable({
  //   url: true,
  //   // sor
  //   onSort: (sort) => {
  //
  //   },
  // });
  const columns = {
    id: {
      label: tr("Invoice #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: TInvoice) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    acutal: {
      label: tr("Total"),
      style: {
        fontWeight: "bold",
      },
      cell: (row: TInvoice) => dollarFormatter(row.actual_total),
    },
    remaining: {
      label: tr("Due"),
      cell: (row: TInvoice) => dollarFormatter(row.amount_due),
    },
    fk_request_id:{
      label: tr("Request #")
    },
    name: {
      label: tr("Customer"),
      width: "100%",
      cell: (row: TInvoice) => (
        // <NoStyleA to={`/customers/${row.customer.id}`}>
        <Avatar showLabel size="xsmall">
          {row.customer.name}
        </Avatar>
        // </NoStyleA>
      ),
    },
    

    creation_date: {
      label: tr("Created"),
      cell: (row: TInvoice) =>
        format(new Date(row.creation_date), "MMM dd, yyyy", {
          locale: locale,
        }),
    },
  };

  const handleClick = props.handleClick
    ? props.handleClick
    : (row: TInvoice) => {};

  return (
    <TableComponent
      columns={columns}
      data={props.data}
      type="a"
      to={(row: any) => {
        return `/invoices/${row.id}`;
      }}
      {...(props.table || {})}
      pageIndex={props.pageIndex}
    />
  );
}
