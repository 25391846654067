import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";

export const getMapDataByPropertyId = async (propertyId: number) => {
  const onSuccess = (response: AxiosResponse) => {
    console.log(response.data);
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: `/property-map/data/${propertyId}`,
  })
    .then(onSuccess)
    .catch(onError);
};
