import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiUser2Fill,
} from "react-icons/ri";
import {
  FiBriefcase,
  FiCalendar,
  FiClipboard,
  FiDollarSign,
  FiSettings,
  FiTruck,
  FiUser,
  FiPenTool,
  FiTool,
} from "react-icons/fi";
import { SubtleIconButton } from "components/button";
import { AiOutlineTool } from "react-icons/ai";

const NavList = styled(List)`
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  font-size: 20px;
  background-color: #00121d;
  color: "#aaa";
  overflow: auto;
`;

const Divider = styled.div`
  border: 1px solid white;
  width: 100%;
  height: 1px;
`;

export default function DrawerCon({ anchor, setAnchor, linkClicked }) {
  const { t } = useTranslation();
  const tBase = "views.navigation.drawer";
  const tr = (key) => t(`${tBase}.${key}`);

  const { permissions } = useAuthContext();
  const allowedpages = permissions.pages || [""];

  const navshow = (page) => {
    if (typeof page === "string" || page instanceof String) {
      if (allowedpages.includes("/" + page.split("/")[1])) {
        return "flex";
      } else {
        return "none";
      }
    } else {
      if (allowedpages.includes("/" + page.pathname.split("/")[1])) {
        return "flex";
      } else {
        return "none";
      }
    }
  };
  const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 0.25rem 1rem 0.25rem 2.5rem;
    display: ${(props) => navshow(props.to)};
    font-size: 1rem;
    :hover {
      background-color: #017195;
    }
  `;
  const { company_id } = useAuthContext();
  return (
    <Drawer anchor="left" open={anchor} onClose={() => setAnchor(false)}>
      <div className="scrollbar">
        <div className="min-h-screen pb-12 text-white bg-slate-900">
          <div className="flex p-4 space-x-2">
            <div className="text-xl text-[#ff4f00]">EServ</div>
          </div>
          <div className="scrollbar:!w-1.5 w-[300px] flex flex-col space-y-4">
            <div>
              <div className="flex justify-between">
                <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                  <FiUser />
                  <span>{tr("CLIENT")}</span>
                </div>
                <div></div>
              </div>

              <NavLink
                name="Customers"
                to={"/customers"}
                onClick={() => linkClicked("Customers")}
              >
                {tr("Customers")}
              </NavLink>
              <NavLink
                name="Properties"
                to={"/property"}
                onClick={() => linkClicked("Properties")}
              >
                {tr("Properties")}
              </NavLink>
            </div>
            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiCalendar />
                <span>{tr("SCHEDULING")}</span>
              </div>

              <NavLink
                name="Appointments"
                to={"/appointments"}
                onClick={() => linkClicked("Appointments")}
              >
                {tr("Appointments")}
              </NavLink>
              <NavLink
                name="Calendar"
                to={"/calendar"}
                onClick={() => linkClicked("Calendar")}
              >
                {tr("Calendar")}
              </NavLink>
            </div>
            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiClipboard />
                <span>{tr("SALES")}</span>
              </div>
              <NavLink
                name="Requests"
                to={"/requests"}
                onClick={() => linkClicked("Requests")}
              >
                {tr("Requests")}
              </NavLink>
              <NavLink
                name="Estimates"
                to={"/estimates"}
                onClick={() => linkClicked("Estimates")}
              >
                {tr("Estimates")}
              </NavLink>
            </div>

            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiDollarSign />
                <span>{tr("FINANCE")}</span>
              </div>
              <NavLink
                name="Invoices"
                to={"/invoices"}
                onClick={() => linkClicked("Invoices")}
              >
                {tr("Invoices")}
              </NavLink>

              <NavLink
                name="Payments"
                to={"/payments"}
                onClick={() => linkClicked("Payments")}
              >
                {tr("Payments")}
              </NavLink>
            </div>

            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiPenTool />
                <span>{tr("COMMERCIAL")}</span>
              </div>
              <NavLink
                name="Projects"
                to={"/projects"}
                onClick={() => linkClicked("Projects")}
              >
                {tr("Projects")}
              </NavLink>
              <NavLink
                name="Quotes"
                to={"/quotes"}
                onClick={() => linkClicked("Quotes")}
              >
                {tr("Quotes")}
              </NavLink>
            </div>
            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiTruck />
                <span>{tr("WAREHOUSE")}</span>
              </div>

              <NavLink
                name="Order"
                to={"/order"}
                onClick={() => linkClicked("Order")}
              >
                {tr("Order")}
              </NavLink>
              {/* <NavLink
										onClick={() => linkClicked("Dashboard")}
										to={"/dashboard"}
									>
										Dashboard
									</NavLink>
									<NavLink
										to={"/parts"}
										onClick={() => linkClicked("Parts")}
									>
										Parts
									</NavLink> */}

              <NavLink
                to={"/warehouse"}
                onClick={() => linkClicked("Warehouse")}
              >
                {tr("Warehouse")}
              </NavLink>
              <NavLink
                to={"/purchasing"}
                onClick={() => linkClicked("Purchasing")}
              >
                {tr("Purchasing")}
              </NavLink>
              <NavLink to={"/vendors"} onClick={() => linkClicked("Vendors")}>
                {tr("Vendors")}
              </NavLink>

              <NavLink to={"/products"} onClick={() => linkClicked("Products")}>
                {tr("Products")}
              </NavLink>
            </div>

            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <AiOutlineTool />
                <span>{tr("EQUIPMENT")}</span>
              </div>

              <NavLink
                to={"/equipment"}
                onClick={() => linkClicked("Equipment")}
              >
                {tr("Manage Equipment")}
              </NavLink>
              <NavLink
                to={"/vehicleCheckout"}
                onClick={() => linkClicked("VehicleCheckout")}
              >
                {tr("Vehicle Check-Out")}
              </NavLink>
              <NavLink
                to={"/repair_request"}
                onClick={() => linkClicked("RepairRequest")}
              >
                {tr("Repair Request Management")}
              </NavLink>
              <NavLink
                to={"/vehicle_inspection/dashboard"}
                onClick={() => linkClicked("VehicleInspectionDashboard")}
              >
                {tr("Vehicle Inspection Dashboard")}
              </NavLink>
            </div>

            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiUser />
                <span>{tr("EMPLOYEES")}</span>
              </div>

              <NavLink
                to={{ pathname: "/team", state: "notDirect" }}
                onClick={() => linkClicked("Team")}
              >
                {tr("Team Dashboard")}
              </NavLink>

              <NavLink
                to={"/admin/employees"}
                onClick={() => linkClicked("Employees")}
              >
                {tr("Employees")}
              </NavLink>

              <NavLink to={"/absent"} onClick={() => linkClicked("Absent")}>
                {tr("Absent")}
              </NavLink>

              <NavLink
                to={"/historicalteam"}
                onClick={() => linkClicked("Historical")}
              >
                {tr("Historical Team")}
              </NavLink>

              <NavLink
                to={"/time-clock"}
                onClick={() => linkClicked("TimeClock")}
              >
                {tr("Time Clock")}
              </NavLink>

              <NavLink
                to={"/production"}
                onClick={() => linkClicked("Production")}
              >
                {tr("Production")}
              </NavLink>
            </div>

            <div>
              <div className="flex items-center px-4 py-1 space-x-2 text-sm text-slate-300">
                <FiSettings />
                <span>{tr("MANAGE")}</span>
              </div>

              <NavLink
                to={"/settings/questions"}
                onClick={() => linkClicked("Question Builder")}
              >
                {tr("Question Builder")}
              </NavLink>

              <NavLink
                to={"/warehouseManagement"}
                onClick={() => linkClicked("WarehouseManagement")}
              >
                {tr("Warehouse Management")}
              </NavLink>
              <NavLink
                to={"/category_management"}
                onClick={() => linkClicked("CategoryManagement")}
              >
                {tr("Category Management")}
              </NavLink>

              <NavLink to={"/reports"} onClick={() => linkClicked("Settings")}>
                {tr("Reports")}
              </NavLink>
              <NavLink
                to={"/admin/requests"}
                onClick={() => linkClicked("Settings")}
              >
                {tr("Settings")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
