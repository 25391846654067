import * as React from "react";
import styled from "styled-components";
import Select from "components/react-select";
import {
  Button,
  OutlineButton,
  SubtleIconButton,
  SubtleButton,
} from "components/button";
import { RiCloseLine } from "react-icons/ri";
import { cloneDeep } from "lodash";
import Form from "components/form2";
import { Input } from "components/input";
import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryObserverResult,
} from "react-query";
import Axios from "axios";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as Yup from "yup";
import Switch from "rc-switch";
import { useFormik } from "formik";
import { TTargetTax, TTax } from "types/tax";
import ReactSelect from "components/react-select";
import { TTargetType } from "types/target-type";
import TaxSelect from "components/react-select/taxes";
import TaxTypeSelect from "components/react-select/taxTypes";
import { useTranslation } from "react-i18next";
import Tax from "views/admin/quickbooks/online/types/Tax";

type Props = {
  type: Exclude<TTargetType, "appointment">;
  id: string;
  data?: TTargetTax[];
};

type TaxGroup = {
  id: number;
  label: string;
  taxes: Tax[];
};

export default function TaxModal({ data, type, id }: Props) {
  const { t } = useTranslation();
  const tBase = "views.requests.components.taxModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [taxes, setTaxes] = React.useState<TTax[]>([]);
  const [taxGroups, setTaxGroups] = React.useState<TaxGroup[]>([]);
  const [view, setView] = React.useState<"create" | "add">("add");
  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient();

  // get tax groups
  const {
    data: taxGroupData,
    isLoading,
    isError,
    error,
  } = useQuery(
    "tax-groups",
    async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax_groups`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {},
    }
  );

  React.useEffect(() => {
    let taxes =
      data?.map((tax) => ({
        label: tax.label,
        type: tax.type,
        id: tax.tax_id,
        value: tax.tax_id,
        list_rate: tax.list_rate,
        list_type: tax.list_type,
        sale_rate: tax.sale_rate,
        sale_type: tax.sale_type,
        install_rate: tax.install_rate,
        install_type: tax.install_type,
      })) || [];

    setTaxes(taxes);
  }, [data]);

  React.useEffect(() => {
    let taxGroups = taxGroupData?.map((group: any) => ({
      id: group.id,
      label: group.label,
      taxes: group.taxes,
    }));
    setTaxGroups(taxGroups);
  }, [taxGroupData]);

  const submitData = useMutation(
    async () =>
      await Axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax/${type}/${id}
  `,
        taxes
      ),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Tax updated!"));
        queryClient.invalidateQueries();
      },
    }
  );

  const populateSelect = taxes?.map((tax, i) => (
    <div key={i}>
      <TaxSelect
        style={{ width: "100%" }}
        value={tax.id.toString()}
        name="select"
        placeholder={tr("Select a tax")}
        onChange={(e: any) =>
          setTaxes((taxes) => {
            let tempTaxes = cloneDeep(taxes);
            tempTaxes[i] = e;

            return tempTaxes;
          })
        }
      />
      <SubtleIconButton
        onClick={() =>
          setTaxes((taxes) => {
            let tempTaxes = cloneDeep(taxes);

            if (tempTaxes) {
              tempTaxes.splice(i, 1);
            }

            return tempTaxes;
          })
        }
      >
        <RiCloseLine />
      </SubtleIconButton>
    </div>
  ));

  return (
    <Con>
      {view === "add" ? (
        <>
          {populateSelect}

          <TaxSelect
            onChange={(e: any) => setTaxes((taxes) => [...taxes, e])}
          />

          <div>
            {/* <SubtleButton style={{ color: "blue" }} onClick={handleAddNewTax}>
              Add tax
            </SubtleButton> */}
            <SubtleButton primary onClick={() => setView("create")}>
              {tr("Create new tax")}
            </SubtleButton>
          </div>

          <ButtonCon>
            <OutlineButton onClick={() => setModal()}>
              {tr("Cancel")}
            </OutlineButton>
            <Button
              primary
              onClick={() => submitData.mutate()}
              isLoading={submitData.isLoading}
            >
              {tr("Update Tax")}
            </Button>
          </ButtonCon>
        </>
      ) : (
        <CreateNewTax {...{ setView }} />
      )}
    </Con>
  );
}

const Con = styled.div`
  width: 400px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  > div {
    display: flex;
    gap: 0.5rem;
  }
`;

const ButtonCon = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 0.5rem;
`;

type CreateNewProps = {
  setView: (e: "create" | "add") => void;
};

type TTaxForm = Omit<TTax, "id">;

const CreateNewTax = ({ setView }: CreateNewProps) => {
  const { t } = useTranslation();
  const tBase = "views.requests.components.taxModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [sameForAll, setSameForAll] = React.useState(false);

  const queryClient = useQueryClient();

  const initialValues: TTaxForm = {
    label: "",
    type: "tax",
    list_rate: 0,
    list_type: "fixed",
    sale_rate: 0,
    sale_type: "fixed",
    install_rate: 0,
    install_type: "fixed",
  };

  const onSubmit = (e: TTaxForm) => {
    addNewTax.mutate(e);
  };

  const validationSchema = Yup.object({
    label: Yup.string().required(),
    type: Yup.string().required(),
    list_rate: Yup.number().required(),
    list_type: Yup.string().required(),
    sale_rate: Yup.number().required(),
    sale_type: Yup.string().required(),
    install_rate: Yup.number().required(),
    install_type: Yup.string().required(),
  });
  const formik = useFormik<TTaxForm>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const { showSnackbar } = useSnackbarContext();
  const addNewTax = useMutation(
    async (body: TTaxForm) => {
      await Axios.post(
        `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax
        `,
        body
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        showSnackbar(tr("Tax added!"));
        setView("add");
      },
    }
  );

  const handleSingleInputChange = (e: any) => {
    const value = +e.target.value;
    formik.setValues((v) => ({
      ...v,
      list_rate: value,
      sale_rate: value,
      install_rate: value,
    }));
  };

  const handePriceTypeChange = (e: any, type?: string) => {
    if (!type) {
      formik.setValues((v) => ({
        ...v,
        list_type: e.value,
        sale_type: e.value,
        install_type: e.value,
      }));
    } else {
      formik.setValues((v) => ({
        ...v,
        [type]: e.value,
      }));
    }
  };

  const options = [
    { value: "fixed", label: tr("Fixed") },
    { value: "percent", label: tr("Percentage") },
  ];

  const findOptions = (value: string | null) =>
    options.find((option) => option.value === value);
  return (
    <Form formik={formik} style={{ display: "flex", flexDirection: "column" }}>
      <Input name="label" label={tr("Tax Label")} autoFocus />
      <TaxTypeSelect
        value={formik.values.type}
        label={tr("Type")}
        name="type"
        onChange={(e: any) => formik.setFieldValue("type", e.value)}
      />
      <div style={{ margin: "1rem 0" }}>
        <Switch
          checked={sameForAll}
          onChange={() => setSameForAll((s) => !s)}
        />
        <label style={{ marginLeft: "1rem" }}>{tr("Same Rate")}</label>
      </div>

      {sameForAll ? (
        <STaxLine>
          <ReactSelect
            label={tr("Type")}
            isClearable={false}
            value={findOptions(formik.values.list_type)}
            options={options}
            onChange={(e) => handePriceTypeChange(e)}
          />
          <Input
            name="list_rate"
            label={tr("Rate")}
            type="number"
            onChange={handleSingleInputChange}
          />
        </STaxLine>
      ) : (
        <>
          <h4 style={{ marginTop: "0.5rem" }}>{tr("List")}</h4>
          <STaxLine>
            <ReactSelect
              label={tr("Type")}
              isClearable={false}
              value={findOptions(formik.values.list_type)}
              options={options}
              onChange={(e) => handePriceTypeChange(e, "list_type")}
            />
            <Input name="list_rate" label={tr("Rate")} type="number" />
          </STaxLine>
          <h4 style={{ marginTop: "0.5rem" }}>{tr("Sale")}</h4>
          <STaxLine>
            <ReactSelect
              label={tr("Type")}
              isClearable={false}
              value={findOptions(formik.values.sale_type)}
              options={options}
              onChange={(e) => handePriceTypeChange(e, "sale_type")}
            />
            <Input name="sale_rate" label={tr("Rate")} type="number" />
          </STaxLine>
          <h4 style={{ marginTop: "0.5rem" }}>{tr("Install")}</h4>
          <STaxLine>
            <ReactSelect
              label={tr("Type")}
              isClearable={false}
              value={findOptions(formik.values.install_type)}
              options={options}
              onChange={(e) => handePriceTypeChange(e, "install_type")}
            />
            <Input name="install_rate" label={tr("Rate")} type="number" />
          </STaxLine>
        </>
      )}

      <ButtonCon>
        <OutlineButton onClick={() => setView("add")}>
          {tr("Back")}
        </OutlineButton>
        <Button primary type="submit" isLoading={addNewTax.isLoading}>
          {tr("Create New Tax")}
        </Button>
      </ButtonCon>
    </Form>
  );
};

const STaxLine = styled.div`
  display: flex;
  align-items: flex-end;
  > div:first-child {
    flex: 0 30%;
  }
  > div:last-child {
    flex: 1;
  }
`;
