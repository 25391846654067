import { utcToZonedTime, format } from "date-fns-tz";
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaHardHat,
  FaMapMarkerAlt,
  FaUser,
} from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import TMapAppointment from "features/map/types/TMapAppointment";
import useSelectedAppointments from "features/map/hooks/useSelectedAppointments";
import TAssignee from "features/map/types/TAssignee";

type TProps = {
  appointment: TMapAppointment;
};

const Card = ({ appointment }: TProps) => {
  const { t } = useTranslation();
  const tBase = "features.map";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { selectedAppointments, setSelectedAppointments } =
    useSelectedAppointments();

  const {
    appointment_id,
    address,
    city,
    color,
    company_name,
    date,
    first_name,
    label,
    last_name,
    latitude,
    longitude,
    start_time,
    state,
    zipcode,
    assignees,
  } = appointment;

  const isSelected = selectedAppointments.includes(appointment_id);

  let formattedDate = "";
  if (date) {
    formattedDate = new Date(date).toLocaleDateString();
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let formattedTime = "";
  if (start_time) {
    const zonedDate = utcToZonedTime(start_time, timeZone);
    formattedTime = format(zonedDate, "h:mm a");
  }

  let nameParts = [];
  if (first_name) {
    nameParts.push(first_name);
  }
  if (last_name) {
    nameParts.push(last_name);
  }
  if (appointment.label) {
    nameParts.push(`${label}`);
  }
  let name = nameParts.join(" ");

  let addressParts = [];
  if (address) {
    addressParts.push(address);
  }
  if (city) {
    addressParts.push(city);
  }
  if (state) {
    if (city) {
      addressParts.push(",");
    }
    addressParts.push(state);
  }
  if (zipcode) {
    addressParts.push(zipcode);
  }
  let fullAddress = addressParts.join(" ");

  const handleCardClick = () => {
    if (isSelected) {
      setSelectedAppointments(
        selectedAppointments.filter((id) => id !== appointment_id)
      );
    } else {
      setSelectedAppointments([...selectedAppointments, appointment_id]);
    }
  };

  return (
    <div
      onClick={handleCardClick}
      className={`w-full mb-4 text-[11px] transition-shadow duration-200 border-2 cursor-pointer hover:shadow-md`}
      style={{
        borderColor: isSelected ? `rgba(${color}, 0.9)` : `rgba(${color}, 0.3)`,
        borderRadius: "10px",
      }}
    >
      <div
        className="flex flex-row justify-between pt-1 pb-1 pl-2 pr-2 rounded-t-md"
        style={{
          backgroundColor: isSelected
            ? `rgba(${color}, 0.9)`
            : `rgba(${color}, 0.3)`,
          color: isSelected ? "white" : "gray",
        }}
      >
        <div className="flex flex-row items-center">
          <div>
            {isSelected ? (
              <FaCheckCircle className="mr-2" color="lightgreen" />
            ) : (
              ""
            )}
          </div>
          <div>{formattedDate}</div>
        </div>
        <div className="flex flex-row">
          <div>{formattedTime}</div>
        </div>
      </div>

      <hr />

      <div
        className="grid grid-cols-3 gap-4 p-2 rounded-b-md"
        style={{
          backgroundColor: isSelected
            ? `rgba(${color}, 0.7)`
            : `rgba(${color}, 0.2)`,
          color: "gray",
        }}
      >
        <div className="col-span-2">
          <div
            className="flex flex-row items-center p-1 rounded"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}
          >
            <FaUser className="mr-1" />
            {name && <div className="mr-1">{name}</div>}
            {company_name && (
              <>
                <div className="mr-2">
                  {name && "/ "}
                  {company_name}
                </div>
              </>
            )}
          </div>
          <div
            className="flex flex-row items-center p-1 mt-2 bg-gray-100 rounded"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}
          >
            <FaMapMarkerAlt className="mr-1" />
            <div>{fullAddress}</div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-between col-span-1 ">
          <div
            className="flex flex-row items-center p-1 bg-gray-100 rounded"
            style={{ backgroundColor: "rgba(240, 240, 240, 0.8)" }}
          >
            <FaHardHat className="mr-1" />
            {assignees.map((assignee: TAssignee) => (
              <div key={assignee.user_id}>{assignee.name}</div>
            ))}
          </div>
          <div>
            {!latitude || !longitude ? (
              <FaExclamationTriangle
                color="darkorange"
                title={tr("Location Missing")}
                size={14}
                style={{ position: "relative", bottom: "-2px", right: "-2px" }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
