/* Created by Hannah Green on 5/18/2021 for ES-147 */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Axios from "axios";
import ReportRow from "./report-row";
import ReactToPrint from "react-to-print";
import BothTables from "./both-tables";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 3%;
`;

export default function Reports() {
    const { t } = useTranslation();
    const tBase = "views.repair_request.components.reports.reports";
    const tr = (key) => t(`${tBase}.${key}`);
    const [lastFiveDays, setLastFiveDays] = useState();
    const [openRequests, setOpenRequests] = useState();
    const componentRef = useRef();

    const lastFive = [
        tr("Start Date"),
        tr("Closing Date"),
        tr("Duration"),
        tr("Request"),
        tr("Equipment"),
        tr("Priority"),
        tr("Comments"),
        tr("Maintenance Notes"),
    ]
    
    const openRequest = [
        tr("Start Date"),
        tr("Last Updated"),
        tr("Request"),
        tr("Equipment"),
        tr("Priority"),
        tr("Comments"),
    ]

    const fetchData = async () => {
        try {
            const lastFive = await Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/last_five_days`);
            setLastFiveDays(lastFive);

            const openRequests = await Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/open_requests`);
            setOpenRequests(openRequests);

        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Con>
            <div>
                {lastFiveDays &&
                    <ReportRow label={tr("Completed in the Past 5 Days")} data={lastFiveDays.data} tableHeaders={lastFive} />
                }
            </div>
            <div>
                {openRequests &&
                    <ReportRow label={tr("Open Requests")} data={openRequests.data} tableHeaders={openRequest} />
                }
            </div>
            <div>
                {lastFiveDays && openRequests &&
                    <>
                        <ReactToPrint
                            trigger={() => <Button primary>Print Both</Button>}
                            content={() => componentRef.current}
                        />
                        <div style={{ display: 'none' }}>
                            <BothTables ref={componentRef} data={{ lastFive: lastFiveDays.data, openRequests: openRequests.data }} />
                        </div>
                    </>
                }
            </div>
        </Con>
    )
}