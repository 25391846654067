import { createContext, useState } from "react";

type SelectedDivisionContextType = {
	selectedDivisionId: number | null;
	setSelectedDivisionId: (divisionId: number) => void;
};

const SelectedDivisionContext = createContext<SelectedDivisionContextType>({
	selectedDivisionId: null,
	setSelectedDivisionId: () => {},
});

const SelectedDivisionProvider: React.FC = ({ children }) => {
	const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
		null
	);

	return (
		<SelectedDivisionContext.Provider
			value={{ selectedDivisionId, setSelectedDivisionId }}
		>
			{children}
		</SelectedDivisionContext.Provider>
	);
};

export { SelectedDivisionContext, SelectedDivisionProvider };
