import { useState, useEffect } from "react";
import { useUpload } from "../context/UploadContext";
import { FaClipboardCheck, FaUsers, FaTools, FaCheck } from "react-icons/fa";
import Curtains from "./Curtains";
import UploadForm from "./UploadForm";
import axios from "axios";
import TooltippedElement from "components/TooltippedElement";
import ColumnMatcher from "./ColumnMatcher";
import ProgressBarContainer from "./ProgressBarContainer";
import { useTranslation } from 'react-i18next';

const Main = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.Main";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { 
    selected, 
    setSelected,
    buttonHover,
    setButtonHover, 
    animEnd, 
    setAnimEnd,
    order,
    viewImports,
    fetchImportStatus,
    hasBeenImported,
  } = useUpload();

  useEffect(() => {
    fetchImportStatus();
  }, []);

  const handleSelect = (type: string) => {
    setSelected(type);
  };

  const onMouseEnter = (type: any) => {
    if (selected) return; 
    setButtonHover(type);
  };

  const onMouseExit = (type: any) => {
    if (selected) return; 
    setButtonHover("");
  };

  const getStyle = (type: string) => {
    let style = baseStyle;
    if (selected === type) {
      style += " animate-uploadA";
    } else if (selected && selected !== type) {
      style += " animate-uploadB"
    } else {
      style += " ";
    }
    return style;
  };

  const baseStyle = `
    w-[300px] h-[200px] border border-gray-400 rounded-xl z-[2]
    flex justify-center items-center mx-10
    ${selected ? "shadow-xl cursor-default" : "hover:shadow-xl cursor-pointer"}
    will-change-auto will-change-transform overflow-hidden 
  `;

  const container = "flex flex-col justify-center items-center px-6 py-3 rounded-full w-full h-full";

  const types = [
    "inventory",
    "services",
    "customers"
  ];

  const buttons = types.map((type: string) => {
    return (
      <div
        onClick={() => {handleSelect(type)}}
        className={getStyle(type)}
        onMouseEnter={() => onMouseEnter(type)}
        onMouseLeave={() => onMouseExit(type)}
        onAnimationEnd={() => setAnimEnd(true)} 
      >
        {order === 1 ? (
          <div className={container}>
            <UploadForm />
          </div>
        ) : (order === 2) ? (
          <div className={container}>
            <ColumnMatcher />
          </div>
        ) : (order === 2.5) ? ( // during the pauseCurtains = true
          <div className={container + " z-10"}>
            <ProgressBarContainer />
          </div>
        ) : (order === 3) ? (
          <div className={container}>
            3
          </div>
        ) : (
          <div className={container}>
            <span className="mb-2 capitalize">
              {tr(type)}
            </span>
            {type === "inventory" && <FaClipboardCheck className="text-6xl text-primary" />}
            {type === "services" && <FaTools className="mt-[8.5px] text-5xl text-primary" />}
            {type === "customers" && <FaUsers className="text-6xl text-primary" />}
          </div>
        )}
        <Curtains 
          isHovered={buttonHover === type} 
          selected={selected === type}
          animEnd={animEnd} 
        />
        {hasBeenImported[type] && (
          <div className="z-10">
            <div className="group absolute top-3 right-3">
            <TooltippedElement
              message="Imported"
              placement="top"
              element={
                <FaCheck className="h-9 w-9 bg-white p-0.5 text-green-600 rounded-full border-2 border-[#3b82f6]" />
              } 
            />
            </div>
          </div>
        )}
      </div>
    );
  });
  return (
    <div className="flex grow justify-center items-center my-4 p-[0.1px] relative overflow-hidden">
      {!viewImports && buttons}
    </div>
  )
}

export default Main;