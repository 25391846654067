export const capitalizeFirstLetter = (string: string) => {
  try {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  } catch (error) {
    console.error(error);
  }
};
