/* Created by Hannah Green for ES-147 on 4/20/2021 */
import React, { useState, useEffect } from "react";
import ImageButton from "components/button/imageUploadButton";
import { AiOutlineCamera } from "react-icons/ai";
import { useMutation } from "react-query";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import {
  Form,
  Select,
  SubmitButton,
  TextInput,
  Con,
  Header,
  ImageCon,
  ImageButton2,
  Images,
  Content,
  MobileButton,
} from "../components/form/form-style";
import useFormUtils from "../components/form/form-utils";
const { TextArea } = require("components/input");

export default function RepairRequestForm() {
  const { t } = useTranslation();
  const tBase = "views.repair_request.public_form.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { lockoutTagout, requestPriority } = useFormUtils();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [success, setSuccess] = useState(false);
  const [portrait, setPortrait] = useState(false);
  const width = window.outerWidth;

  const urlString = window.location.href;
  const url = new URL(urlString);
  const companyId = url.searchParams.get("companyId");

  const mutation = useMutation(
    (form) => {
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/public_repair_request/submit`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  /* useEffect(() => {
        switch (window.orientation) {
            case -90:
            case 90:
                setPortrait(false);
                break;
            default:
                if (width < 1000) {
                    setPortrait(true);
                } else {
                    setPortrait(false);
                }
                break;
        }
    }, [width]); */

  const submitRequest = async (values) => {
    const { ...form } = values;

    const formData = new FormData();

    if (image1) {
      const imageBlob1 = await (await fetch(image1)).blob();
      formData.append("image1", imageBlob1);
    }

    if (image2) {
      const imageBlob2 = await (await fetch(image2)).blob();
      formData.append("image2", imageBlob2);
    }

    formData.append("form", JSON.stringify(form));

    mutation.mutate(formData);
  };

  const [initialForm, setInitialForm] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    equip_num: undefined,
    equip_type: undefined,
    description: undefined,
    companyId,
    lock_tag: { label: "No", value: false },
    priority: { label: tr("Low - When you get a chance"), value: "1" },
  });

  const updateForm = (input) => {
    setInitialForm((state) => {
      const tempForm = { ...state };
      tempForm[input.target.name] = input.target.value;
      return tempForm;
    });
  };

  return (
    <Con>
      <Header>
        <div>
          {success && (
            <>
              <h1>{tr("Thank You For Your Submission")}</h1>
              <h2>{tr("Your request will be reviewed as soon as possible")}</h2>
            </>
          )}
          {!success && (
            <>
              <h1>{tr("Equipment Repair Request")}</h1>
              <h2>
                {tr("Please Report Damage or Maintenance Needs for Equipment or Vehicles")}
              </h2>
              <p>
                {tr("All requests will be reviewed as soon as possible. Please be as descriptive as possible, and provide pictures when appropriate to help ensure the quickest completion of your request.")}
              </p>
            </>
          )}
        </div>
      </Header>

      {!success && (
        <>
          <Content>
            <Form
              enableReinitialize={true}
              initialValues={initialForm}
              onSubmit={(values) => {
                submitRequest(values);
              }}
            >
              <TextInput
                label={tr("First Name")}
                required={true}
                name="firstName"
                value={initialForm.firstName}
                onChange={(value) => {
                  updateForm(value);
                }}
              />

              <TextInput
                label={tr("Last Name")}
                required={true}
                name="lastName"
                value={initialForm.lastName}
                onChange={(value) => {
                  updateForm(value);
                }}
              />

              <TextInput
                label={tr("Email")}
                required={false}
                name="email"
                value={initialForm.email}
                onChange={(value) => {
                  updateForm(value);
                }}
              />

              <TextInput
                label={tr("Equipment Number and Type(Year, Make, Model)")}
                required={true}
                name="equip_type"
                value={initialForm.equip_type}
                onChange={(value) => {
                  updateForm(value);
                }}
              />

              <TextArea
                label={tr("Detailed Description of Work/Repair")}
                required={true}
                name="description"
                value={initialForm.description}
                onChange={(value) => {
                  updateForm(value);
                }}
              />

              <Select
                label={tr("Lockout/Tagout")}
                required={false}
                name="lock_tag"
                options={lockoutTagout}
                onChange={(value) => {
                  setInitialForm((state) => {
                    const tempForm = { ...state };
                    tempForm.lock_tag = value;
                    return tempForm;
                  });
                }}
                value={initialForm.lock_tag}
              />

              <Select
                label={tr("Request Priority")}
                required={false}
                name="priority"
                options={requestPriority}
                onChange={(value) => {
                  setInitialForm((state) => {
                    const tempForm = { ...state };
                    tempForm.priority = value;
                    return tempForm;
                  });
                }}
                value={initialForm.priority}
              />

              <Images>
                <ImageCon>
                  <ImageButton
                    uploadImages={async ({ dataUrl }) => {
                      setImage1(dataUrl);
                    }}
                    Component={
                      <ImageButton2>
                        {image1 && (
                          <img
                            src={image1}
                            style={{ width: "100%", maxWidth: "100%" }}
                          />
                        )}
                        <AiOutlineCamera />
                      </ImageButton2>
                    }
                  />
                </ImageCon>
                <ImageCon>
                  <ImageButton
                    uploadImages={async ({ dataUrl }) => {
                      setImage2(dataUrl);
                    }}
                    Component={
                      <ImageButton2>
                        {image2 && (
                          <img
                            src={image2}
                            style={{ width: "100%", maxWidth: "100%" }}
                          />
                        )}
                        <AiOutlineCamera />
                      </ImageButton2>
                    }
                  />
                </ImageCon>
              </Images>

              <SubmitButton type="submit" primary>
                {tr("Submit")}
              </SubmitButton>
            </Form>
          </Content>
        </>
      )}
    </Con>
  );
}
