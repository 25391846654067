import * as S from "./style";
import * as React from "react";
import { theme } from "styles/theme";
import { SubtleButton } from "components/button";

type Tab = {
  tab: React.ReactNode;
  count?: string | number | null;
  content?: React.ReactElement;
  hide?: boolean;
  unSelectable?: boolean;
  buttons?: React.ReactNode;
};

type Props = {
  data: { [key: string]: Tab } | null;
  defaultTab?: string;
  onClick?: (key: string) => void;
  content?: React.ReactNode;
  headerOnly?: boolean;
  style?: {
    main?: {};
    tab?: {};
    content?: {};
  };
  buttons?: React.ReactNode;
  setExportType?: (key: string) => void;
};
export default function Tab({
  data,
  defaultTab,
  onClick,
  content,
  style,
  ...props
}: Props) {
  const [selected, setSelected] = React.useState<string | undefined>(
    defaultTab
  );

  const contentRender =
    !props.headerOnly &&
    ((selected && data && data[selected]?.content) || content);

  React.useEffect(() => {
    if (defaultTab) {
      setSelected(defaultTab);
    } else if (data) {
      let key = Object.keys(data)[0];
      setSelected(key);
      onClick && onClick(key);
    }
  }, [defaultTab /* //?data*/]);

  const populateTabs =
    data &&
    Object.entries(data)
      .filter(([key, value]) => !value.hide)
      .map(([key, value]) => {
        if (value.unSelectable) {
          return value.tab;
        }

        const handleClick = (key: string) => {
          setSelected(key);
          props.setExportType && props.setExportType(key);
          onClick && onClick(key);
        };

        const isSelected = selected === key;
        return (
          <S.TabButton
            {...{ key, isSelected }}
            onClick={() => handleClick(key)}
            className=""
          >
            <SubtleButton
              className={`!p-0 !px-3 ${isSelected && "!text-[#ff4f00]"}`}
            >
              {value.tab}
              {(value.count || value.count === 0) && (
                <span
                  style={{
                    color: theme.color.main,
                    fontSize: "0.8rem",
                    marginLeft: "0.4rem",
                  }}
                >
                  {value.count}
                </span>
              )}
            </SubtleButton>
            {value.buttons || null}
          </S.TabButton>
        );
      });

  return (
    <S.Con style={{ ...style?.main }}>
      <S.TabCon
        style={{ ...style?.tab }}
        className={`${props.headerOnly && "!border-b-0"}`}
      >
        {populateTabs}
        {props.buttons && <div className="ml-auto">{props.buttons}</div>}
      </S.TabCon>
      {contentRender && (
        <S.ContentCon style={{ ...style?.content }}>
          {contentRender}
        </S.ContentCon>
      )}
    </S.Con>
  );
}
