import { Button, SubtleButton } from "components/button";
import Tooltip from "components/react-popper";
import useQueryString from "hooks/useQueryString";
import { cloneDeep, isEqual } from "lodash";
import * as React from "react";
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxBlankLine,
  RiCheckboxFill,
  RiFilter3Line,
  RiRadioButtonLine,
} from "react-icons/ri";
import styled from "styled-components";
import { theme } from "styles/theme";
import { useTranslation } from 'react-i18next';

type Data = {
  [catId: string]: {
    label: string;
    type: "multiple" | "single";
    key: string;
    optionLabel: string;
    options: [];
    // options: {
    //   [optionId: string]: {
    //     label: React.ReactNode;
    //   }
    // } | null
  };
} | null;

type Props = {
  style?: {};
  buttonStyle?: {};
  defaultFilter?: TFilter;
  onSubmit?: (filter: TFilter) => void;
  data: Data;
  handleClick: (props: HandleClick) => void;
  handleClearCatFilter: (catId: string) => void;
  filters: TFilter;
};

type TFilter = {
  [catId: string]: string[] | null;
} | null;

// const data = {
//   status: {
//     label: "Status",
//     type: "multiple",
//     options: {
//       new: {
//         label: "New",
//       },
//       in_progress: {
//         label: "In Progress",
//       },
//       completed: {
//         label: "Completed",
//       },
//     },
//   },
//   category: {
//     label: "Category",
//     type: "single",
//     options: {
//       commercial: {
//         label: "Commercial",
//       },
//       resi: {
//         label: "Resi",
//       },
//       test: {
//         label: "Test",
//       },
//     },
//   },
// };

export default function Filter(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.filter";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <Tooltip
      style={props.style}
      clickable
      reference={
        <Button style={props.buttonStyle}>
          <RiFilter3Line />
          {tr("Filter")}
        </Button>
      }
    >
      {({ disable }: any) => <TooltipCon {...props} disable={disable} />}
    </Tooltip>
  );
}

const TooltipCon = ({
  filters,
  ...props
}: {
  disable: any;
  filters: TFilter;
  handleClick: (props: HandleClick) => void;
} & Props) => {
  const { t } = useTranslation();
  const tBase = "components.filter";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleSubmit = () => {
    props.onSubmit && props.onSubmit(filters);
    props.disable();
  };

  const populateFilters =
    props.data &&
    Object.entries(props.data).map(([catId, category]) => {
      const type = category.type;

      const catIdExists = filters && filters[catId] ? true : false;

      const populateOptions = category.options?.map((option) => {
        const key = option[category.key];
        const label = option[category.optionLabel];

        const selected =
          catIdExists &&
          filters &&
          filters[catId] !== null &&
          filters[catId]?.includes(key)
            ? true
            : false;

        const rowIcon =
          type === "single" ? (
            selected ? (
              <RiRadioButtonLine />
            ) : (
              <RiCheckboxBlankCircleLine />
            )
          ) : selected ? (
            <RiCheckboxFill />
          ) : (
            <RiCheckboxBlankLine />
          );

        return (
          <SubtleButton
            square
            style={{
              color: selected ? "#ff4f00" : "black",
              justifyContent: "flex-start",
            }}
            key={key}
            onClick={() =>
              props.handleClick({
                selected,
                catId,
                type,
                optionId: key,
                catIdExists,
              })
            }
          >
            {rowIcon}
            {label}
          </SubtleButton>
        );
      });
      return (
        <SCategory key={catId}>
          <SCatHeader>
            <h4>{category.label}</h4>
            {catIdExists && (
              <SubtleButton
                size="small"
                primary
                onClick={() => props.handleClearCatFilter(catId)}
              >
                {tr("Clear Filter")}
              </SubtleButton>
            )}
          </SCatHeader>
          <SOptions>{populateOptions}</SOptions>
        </SCategory>
      );
    });

  return (
    <Con>
      {populateFilters}
      <Button onClick={handleSubmit} primary size="small">
        {tr("Done")}
      </Button>
    </Con>
  );
};

const SCategory = styled.div``;

const SCatHeader = styled.div`
  display: flex;
  > h4 {
    margin: 0;
    color: ${theme.color.subtext2};
    padding: 0;
  }
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid ${theme.border.light};
  > button {
    margin-left: auto;
    padding: 0;
    height: auto;
  }
`;

const SOptions = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    border-bottom: 1px solid ${theme.border.light};
  }
`;

const Con = styled.div`
  min-width: 300px;
  > button:last-child {
    margin: 0.5rem;
  }
`;

type UseFilterProps = {
  data: Data;
  defaultFilter: TFilter;
};

type SettingsProps = {
  setUrl?: boolean;
};

type HandleClick = {
  selected: boolean;
  catId: string;
  type: "multiple" | "single";
  optionId: string;
  catIdExists: boolean;
};
export const useFilter = (
  { data, defaultFilter }: UseFilterProps,
  settings: SettingsProps
) => {
  const [filters, setFilters] = React.useState<TFilter>(null);

  const queryString = useQueryString();

  React.useEffect(() => {
    if (!filters && defaultFilter && !isEqual(filters, defaultFilter)) {
      setFilters(defaultFilter);
    }
  }, [defaultFilter, setFilters, filters]);

  const handleClick = ({
    selected,
    catId,
    type,
    optionId,
    catIdExists,
  }: HandleClick) => {
    setFilters((filters) => {
      let temp = cloneDeep(filters);

      if (selected) {
        if (type === "single") {
          // delete temp![catId];
          temp![catId] = null;
        } else if (type === "multiple") {
          if (Array.isArray(temp![catId])) {
            let index =
              temp![catId]?.findIndex((option) => option === optionId) || -1;
            if (temp![catId]?.length === 1) {
              temp![catId] = null;
              // delete temp![catId];
            } else {
              temp![catId]?.splice(index, 1);
            }
          } else {
            temp![catId] = null;
          }
        }
      } else {
        if (catIdExists) {
          if (type === "single") {
            temp![catId] = [optionId];
          } else {
            temp![catId]?.push(optionId);
          }
        } else {
          if (temp) {
            temp[catId] = [optionId];
          } else {
            temp = {
              [catId]: [optionId],
            };
          }
        }
      }
      settings.setUrl && queryString.update({ ...queryString.search, ...temp });

      return temp;
    });
  };

  const handleClearCatFilter = (catId: string) => {
    setFilters((filters) => {
      let temp = cloneDeep(filters);
      temp![catId] = null;
      settings.setUrl && queryString.update({ ...queryString.search, ...temp });

      return temp;
    });
  };
  // const handleClick = (type: string, option: string) => {
  //   setFilters((filters) => ({ ...filters, [type]: option }));
  // };

  // const handleSubmit = (filters: any) => {
  //   setFilters(filters);
  // };

  // const clear = (type: string) => {
  //   setFilters((filters: any) => {
  //     let tempFilters = { ...filters };
  //     delete tempFilters[type];
  //     return tempFilters;
  //   });
  // };

  return {
    filters,
    data,
    handleClick,
    handleClearCatFilter,
    // handleSubmit, handleClick, clear
  };
};
