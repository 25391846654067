import { Input } from "components/input";
import axios from "api/axios";
import { Button, SubtleIconButton } from "components/button";
import ImageUploadButton from "components/button/imageUploadButton";
import Container from "components/container";
import { StyledLabel } from "components/input/style";
import * as React from "react";
import { RiCamera2Line } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import InlineEdit from "components/inline_edit";
import DataLabel from "components/dataLabel";
import TaxSelect from "components/react-select/taxes";
import Field from "components/field";
import TimezoneSelect from "components/react-select/timezones";
import { useTranslation } from 'react-i18next';

type Props = {};

type Settings = {
  companyLogo: string;
  companySettings: {
    default_tax: string | null;
    phone: string | null;
    email: string | null;
    name: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    zipcode: string | null;
    default_timezone: string | null;
  };
};

export default function CompanySettings(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.company_settings.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient();

  const emailverified = useQuery(
    "vemail",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/emailverified`
        )
        .then((res) => res.data)
  );
  const settings = useQuery<Settings>(
    ["company-settings"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings
  `
        )
        .then((res) => res.data)
  );

  const getSignedURL = useMutation(
    async () =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/company-logo
    `
        )
        .then((res) => res.data)
  );

  const updateDefaultTax = useMutation(
    async (body: { val: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/default-tax
  `,
          body
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const updateDefaultTimezone = useMutation(
    async (body: { val: string }) => 
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/default-timezone`,
          body
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const uploadToS3 = useMutation(
    async ({ file, url }: { file: any; url: any }) =>
      await axios.put(url, file).then((res) => res.data),
    {
      onSuccess: () => {
        // settings.refetch();
        window.location.reload();
        // queryClient.invalidateQueries("company-settings");
      },
    }
  );

  const upload = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append("logo", e.blob);
      const url = await getSignedURL.mutateAsync();
      uploadToS3.mutate({ file: e.blob, url: url.uploadURL });
    } catch (e) {
      console.error(e);
    }
  };
  const [emailMessage, setemailMessage] = React.useState(() => {
    if (emailverified.data === "info@eserv.com") {
      return "The email on file has not been verified through AWS we will use info@eserv.com until it is verified";
    } else {
      return "The email on file has been verified through AWS.";
    }
  });
  const Emailmessagetext = () => {
    return (
      <div>
        <p>{tr(emailMessage)}</p>
      </div>
    );
  };

  return (
    <Con>
      <h1>{tr("Company Settings")}</h1>
      <br/>
      <Container
        title={tr("Company Details")}
        content={
          <div className="flex flex-row-reverse justify-between p-5">
            
            {/* Logo */}
            <div className="max-w-[600px] w-full">
              <div className="flex">
                <div className="font-medium text-slate-500 text-sm">{tr("Logo")}</div>                
                <ImageUploadButton
                    Component={
                      <div className="-mt-3">
                        <SubtleIconButton size="large">
                          <RiCamera2Line />
                        </SubtleIconButton>
                      </div>
                    }
                    uploadImages={upload}
                  />
              </div>

              <ImageCon>
                <img src={settings.data?.companyLogo} />
              </ImageCon>
            </div>

            {/* Details */}
            <div className="grid grid-rows-4 grid-flow-col gap-x-6">
              <DataLabel title={tr("Company Name")} className="col-span-12">
                <InlineEdit
                  value={settings.data?.companySettings?.name}
                  updateUrl={`company/companySettings/company-name`}
                />
                <hr className="mt-2" />
              </DataLabel>

              <DataLabel title={tr("Default Tax")}>
                <TaxSelect
                  value={settings.data?.companySettings.default_tax}
                  onChange={(e: any) => {
                    updateDefaultTax.mutate({ val: e.id });
                  }}
                />

                {/* <InlineEdit value={"hi"} updateUrl={"www.hi"} editCon={<TaxSelect value={default_tax} />} /> */}
              </DataLabel>
              
              <DataLabel title={tr("Default Phone")}>
                <InlineEdit
                  value={settings.data?.companySettings.phone}
                  updateUrl={`company/companySettings/default-phone`}
                />
              </DataLabel>

              <DataLabel title={tr("Default Email")}>
                <Emailmessagetext></Emailmessagetext>
                <InlineEdit
                  onSubmit={(x: string) => {
                    setemailMessage(
                      "Please check your email inbox for an email message from AWS (amazon web services). You must click the link in that email message to confirm we have permission to send emails with your new email address."
                    );
                    return x;
                  }}
                  value={settings.data?.companySettings.email}
                  updateUrl={`company/companySettings/default-email`}
                />
              </DataLabel>

              <DataLabel title={tr("Default Address")}>
                <InlineEdit
                  value={settings.data?.companySettings.address}
                  updateUrl={`company/companySettings/default-address`}
                />
              </DataLabel>

              <DataLabel title={tr("Default City")}>
                <InlineEdit
                  value={settings.data?.companySettings.city}
                  updateUrl={`company/companySettings/default-city`}
                />
              </DataLabel>

              <DataLabel title={tr("Default State")}>
                <InlineEdit
                  value={settings.data?.companySettings.state}
                  updateUrl={`company/companySettings/default-state`}
                />
              </DataLabel>

              <DataLabel title={tr("Default Zip Code")}>
                <InlineEdit
                  value={settings.data?.companySettings.zipcode}
                  updateUrl={`company/companySettings/default-zipcode`}
                />
              </DataLabel>

              <DataLabel title={tr("Default Timezone")}>
                <TimezoneSelect
                  value={settings.data?.companySettings.default_timezone}
                  onChange={(e: any) => {
                    updateDefaultTimezone.mutate({ val: e.value });
                  }}
                />    
              </DataLabel>
            </div>
            
          </div>
        }
      />
    </Con>
  );
}

const Con = styled.div`
  > h1 {
    /* padding: 1rem 0; */
    /* border-bottom: 1px solid ${theme.border.light}; */
  }
`;

const SDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
`;

const ImageCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.bg.blue};
  height: 300px;
  width: 100%;
  position: relative;
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  > button {
    z-index: 2;
  }
`;
