import React, { useState } from "react";
import { useQuery } from "react-query";

import { getInventoryCategories, getInventoryItems } from "api/inventoryApi";
import { TInventory, TInventoryCategory } from "../../../../../types/inventory";
import Spinner from "components/spinner";

import styles from "./VendorItemLinkModalBody.module.scss";
import { Input } from "components/input";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const VendorItemLinkModalBody = () => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorItems.VendorItemLinkModal.VendorItemLinkModalBody";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [selectedCategory, setSelectedCategory] =
    useState<TInventoryCategory | null>(null);

  const [selectedItem, setSelectedItem] = useState<TInventory | null>(null);

  const categoryQuery = useQuery("categories", getInventoryCategories);

  const itemsQuery = useQuery(
    ["vendor_link_items", selectedCategory?.category_id],
    () => getInventoryItems(selectedCategory?.category_id || null)
  );

  const handleChangeCategory = (category: TInventoryCategory) => {
    setSelectedCategory(category);
    setSelectedItem(null);
  };

  const handleChangeItem = (item: TInventory) => {
    setSelectedItem(item);
  };

  if (categoryQuery?.isLoading) return <Spinner />;

  if (categoryQuery?.isError) return <div>Error</div>;

  return (
    <div className={styles.container}>
      <div className={styles.pickers}>
        <div className={styles.categoryList}>
          {categoryQuery?.data?.map((category: TInventoryCategory) => (
            <div
              className={
                selectedCategory &&
                category.category_id === selectedCategory.category_id
                  ? styles.selectedCategoryContainer
                  : styles.categoryContainer
              }
              onClick={() => handleChangeCategory(category)}
            >
              {category.category_name}
            </div>
          ))}
        </div>
        <div className={styles.itemList}>
          {itemsQuery?.isLoading ? <Spinner /> : null}
          {itemsQuery?.isError ? <div>{tr("Error")}</div> : null}
          {itemsQuery?.data?.map((item: TInventory) => {
            return (
              <div
                className={
                  selectedItem && item.item_id === selectedItem.item_id
                    ? styles.selectedItemContainer
                    : styles.itemContainer
                }
                onClick={() => handleChangeItem(item)}
              >
                {item.item_name}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.chooseLabels}>
        {!selectedCategory ? (
          <div className={styles.chooseCategory}>{tr("Choose a Category")}</div>
        ) : null}
        {selectedCategory && !selectedItem ? (
          <div className={styles.chooseItem}>{tr("Choose an Item")}</div>
        ) : null}
      </div>
      {selectedItem ? (
        <>
          <div className={styles.itemDetailsContainer}>
            {selectedItem.hasOwnProperty("imageUrl") &&
            selectedItem.imageUrl ? (
              <img
                alt={selectedItem.item_name}
                src={selectedItem?.imageUrl ? selectedItem.imageUrl : ""}
                className={styles.image}
              />
            ) : null}

            <div className={styles.detailGroup}>
              <div className={styles.itemName}>{selectedItem.item_name}</div>
              <div className={styles.description}>
                {selectedItem.long_description}
              </div>
            </div>
          </div>
          <div className={styles.formContainer}>
            <Input className={styles.inputRow} placeholder={tr("Price")} />
            <Input className={styles.inputRow} placeholder={tr("Lead Time")} />
            <Input className={styles.inputRow} placeholder={tr("Notes")} />
            <div className={styles.footerRow}>
              <Button className={styles.saveButton}>{tr("Save")}</Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default VendorItemLinkModalBody;
