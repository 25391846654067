/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from "react";
import styled from "styled-components";
import { isSameDay, format, startOfWeek, addDays, isAfter } from "date-fns";
import Axios from "axios";
import { Avatar } from "components/avatar";
import DroppableCell from "./weeklyCell";
import { useQuery } from "react-query";
import { theme } from "styles/theme";
import useQueryString from "hooks/useQueryString";
import { useLocationContext } from "../../hooks/useLocationContext";
import { extractLocations } from "../../utils/extractLocations";
import { locationsToDistances } from "../../utils/locationsToDistances";
import { useTranslation } from 'react-i18next';

import { AppointmentCard } from "../../components/AppointmentCard";

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.2rem;
  min-height: 50px;
  height: auto;
  border-bottom: 1px solid ${theme.border.light};
  border-right: 1px solid ${theme.border.light};
`;

const FirstCol = styled(Cell)`
  background: white;
  font-weight: bold;
  z-index: 1;
  position: sticky;
  left: 0;
`;

const day = [
  ("Monday"),
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

let render = 0;

export default function WeeklyGrid({
  date,
  toolTipModal,
  handleToolTip,
  refreshStatus,
  appointmentMoved,
}) {
  const { t } = useTranslation();
  const tBase = "views.calendar.containers.weekly.weeklyContainer";
  const tr = (key) => t(`${tBase}.${key}`);

  const queryString = useQueryString();
  const { setMaxDistance } = useLocationContext();

  const startWeekDate = startOfWeek(date ? new Date(date) : new Date());
  let parseDate = date ? new Date(date) : new Date();
  let formatDate = format(parseDate, "MM/dd/yyyy");

  const { data: appointments, refetch } = useQuery(
    ["appointments", "weekly", formatDate, queryString.search],
    async () =>
      await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/all`,
        {
          params: {
            date: formatDate,
            grid: "weekly",
            filters: queryString.search.division,
          },
        }
      ).then((res) => {
        const data = res.data;
        return data;
      }),
    {
      keepPreviousData: true,
      refetchInterval: 60000,
    }
  );

  useEffect(() => {
    // console.log("appointments changed");

    if (appointments) {
      // console.log("appointments", appointments);
      const distances = locationsToDistances(extractLocations(appointments));
      const max = Math.max(...distances);
      // console.log("max", max);
      setMaxDistance(max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  useEffect(() => {
    // console.log("refreshStatus changed");
    refreshStatus && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshStatus]);

  render = render + 1;

  const groupData =
    appointments &&
    Object.entries(appointments)
      .sort(([, value1], [, value2]) => {
        return value1.assignee === null
          ? -1
          : value2.assignee === null
          ? 1
          : value1.assignee > value2.assignee
          ? 1
          : value1.assignee < value2.assignee
          ? -1
          : 0;
      })
      .map(([userId, { assignee, appointments }]) => {
        const populateCells = new Array(7).fill("").map((e, i) => {
          const populateAppointments =
            appointments &&
            Object.entries(appointments)
              .filter(([apptId, apptProps]) => {
                return (
                  apptProps &&
                  isSameDay(new Date(apptProps.date), addDays(startWeekDate, i))
                );
              })
              .sort((a, b) => {
                let comp1 = a[1].start
                  ? new Date(a[1].start)
                  : a[1].end
                  ? new Date(a[1].end)
                  : null;
                let comp2 = b[1].start
                  ? new Date(b[1].start)
                  : b[1].end
                  ? new Date(b[1].end)
                  : null;
                if (comp1 && comp2) {
                  if (isAfter(comp1, comp2)) return 1;
                  if (isAfter(comp2, comp1)) return -1;
                  return 0;
                } else if (comp1) {
                  return -1;
                } else if (comp2) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map(([apptId, appointmentProps]) => (
                <AppointmentCard
                  key={apptId + "-" + i}
                  {...appointmentProps}
                  {...{ userId, handleToolTip, toolTipModal }}
                />
              ));

          return (
            <DroppableCell
              className={`${!assignee && "!bg-red-100/40"}`}
              {...{ appointmentMoved }}
              formatDate={formatDate}
              newDate={addDays(startWeekDate, i + 1)}
              crewId={userId}
              key={userId + "-" + i}
            >
              {populateAppointments}
            </DroppableCell>
          );
        });

        return (
          <React.Fragment key={userId}>
            <FirstCol className={`${!assignee && "!bg-red-100/40"}`}>
              {assignee ? (
                <Avatar showLabel={true} user_id={userId}>
                  {assignee}
                </Avatar>
              ) : (
                tr("Unassigned")
              )}
            </FirstCol>
            {populateCells}
          </React.Fragment>
        );
      });

  return (
    <div className="grid h-full grid-cols-8 overflow-auto border-t auto-rows-max">
      <div
        style={{
          alignItems: "center",
          position: "sticky",
          top: 0,
          background: "white",
          minHeight: "50px",
        }}
      />
      {day.map((_, i) => {
        const date = addDays(startWeekDate, i + 1);
        const isFirst = i === 0;
        return (
          <Cell
            key={i}
            style={{
              // alignItems: "center",
              position: "sticky",
              top: 0,
              background: "white",
              borderLeft: isFirst ? "1px solid #d9d9d9" : "", // Apply border to the first element
              marginLeft: isFirst ? "-1px" : "", // Remove the left margin from the first element
            }}
          >
            {tr(format(date, "eeee"))}
            <span style={{ color: "grey" }}>{format(date, "MM/dd")}</span>
          </Cell>
        );
      })}
      {groupData}
    </div>
  );
}
