import { Button } from "components/button";
import { useAppointments } from "features/map/hooks/useAppointments";
import useSelectedAppointments from "features/map/hooks/useSelectedAppointments";
import { useTranslation } from 'react-i18next';

const Controls = () => {
  const { t } = useTranslation();
  const tBase = "features.map";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { data } = useAppointments();
  const { setSelectedAppointments } = useSelectedAppointments();

  const onAllButtonClick = () => {
    if (data && data.appointments && data.appointments.length > 0) {
      const appointmentIds = data.appointments.map(
        (appointment) => appointment.appointment_id
      );
      setSelectedAppointments(appointmentIds);
    }
  };

  const onClearButtonClick = () => {
    setSelectedAppointments([]);
  };

  const onSortButtonClick = () => {};

  return (
    <div className="flex flex-row items-center justify-between p-4 pb-0">
      <Button
        onClick={onAllButtonClick}
        className="flex-grow mr-2 border-gray-800 border-1"
        size="xsmall"
      >
        {tr("All")}
      </Button>
      <Button
        onClick={onClearButtonClick}
        className="flex-grow mr-2 border-gray-800 border-1"
        size="xsmall"
      >
        {tr("Clear")}
      </Button>
      <Button
        onClick={onSortButtonClick}
        className="flex-grow border-gray-800 border-1"
        size="xsmall"
      >
        {tr("Sort")}
      </Button>
    </div>
  );
};

export default Controls;
