import { Label } from "components/font";
import { Input } from "components/input";
import Assignee from "components/react-select/assignees";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { useTranslation } from 'react-i18next';

type Props = {};

export default function ProjectSettings(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.admin.projects.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <Con>
      <SCon>
        <STitleCon>
          <h2>{tr("Project Settings")}</h2>
        </STitleCon>
        <SSectionCon>
          <div>{tr("Notifications")}</div>
          <div>
            <div>
              <Label>{tr("Email Recipients Upon Approval")}</Label>
              <Assignee assigneeType="all" />
            </div>
          </div>
        </SSectionCon>
      </SCon>
    </Con>
  );
}

const Con = styled.div`
  padding: 1rem;
  width: 100%;
  max-width: 1000px;
`;

const SCon = styled.div`
  display: flex;
  flex-direction: column;
`;

const STitleCon = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.border.black};
  padding: 1em 0;
`;

const SSectionCon = styled.div`
  display: flex;
  padding: 1rem 0;
  > div:first-child {
    flex: 1 1 25%;
    font-weight: 600;
  }
  > div:last-child {
    flex: 1 1 75%;
  }
`;
