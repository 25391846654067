import { IconButton, SubtleIconButton } from "components/button";
import { Input } from "components/input";
import * as React from "react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseFill } from "react-icons/ri";
import { dollarFormatter } from "utils";

type Props = {
  data: any;
  rateLabel: string;
  onChange: () => void;
  formik: any;
  handleDelete: () => void;
};
export default function GroupHeader({
  data,
  rateLabel,
  onChange,
  formik,
  handleDelete,
}: Props) {
  const [isShowing, setIsShowing] = React.useState(false);

  const handleChange = () => {
    onChange();
    setIsShowing((s) => !s);
  };

  const price = formik.values.products?.reduce((prev: number, current: any) => {
    let price =
      current.fk_ingroup_id === data.fk_ingroup_id
        ? current[rateLabel] * current.quantity
        : 0;
    return prev + price;
  }, 0);

  return (
    <>
      <SubtleIconButton size="small" onClick={handleChange}>
        {isShowing ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
      </SubtleIconButton>
      <IconButton size="small" onClick={handleDelete}>
        <RiCloseFill />
      </IconButton>
      <Input size="small" value={1} disabled style={{ width: "100px" }} />
      <Input size="small" value={data.group_name} disabled />
      <Input size="small" value={dollarFormatter(price * 100)} disabled />
      <Input size="small" value={dollarFormatter(price * 100)} disabled />
    </>
  );
}
