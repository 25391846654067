import axios from "api/axios";
import Field from "components/field";
import * as React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Customer } from "views/requests/newRequestModal/customerForm";
import { components, OptionProps, Props } from "react-select";
import { MdVerifiedUser } from "react-icons/md";
import { TProperty } from "types/property";

type TProps = Omit<Props, "value"> & {
  onChange: (e: any) => void;
  value: any;
  label?: string;
  //   isMulti?: boolean;
  name?: string;
  formik?: any;
  error?: string;
};

export default function PropertySelect(props: TProps) {
  const getCustomers = useMutation(
    async (e: string) =>
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/property`, {
          params: {
            search: e,
          },
        })
        .then((res) => res.data)
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: TProperty[]) => void
  ) => {
    getCustomers
      .mutateAsync(inputValue)
      .then((res) => {
        callback(
          res?.properties?.map((customer: TProperty) => ({
            ...customer,
            label: customer.full_address,
            value: customer.id,
          }))
        );
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <Field
      type="select"
      isAsync
      loadOptions={loadOptions}
      defaultOptions
      components={{ Option }}
      style={{ width: "100%" }}
      {...props}
    />
  );
}

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-col">
        <span className="text-sm">{props.data.address}</span>
        <span className="italic text-xs">{props.data.name}</span>
      </div>
    </components.Option>
  );
};
