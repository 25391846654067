import React, { useEffect, useState } from "react";
import {
  isSameDay,
  format,
  startOfMonth,
  endOfMonth,
  addDays,
  eachDayOfInterval,
	subMonths,
	lastDayOfMonth,
} from "date-fns";
import Axios from "axios";
import { useQuery } from "react-query";
import useQueryString from "hooks/useQueryString";
import { useLocationContext } from "../../hooks/useLocationContext";
import { extractLocations } from "../../utils/extractLocations";
import { locationsToDistances } from "../../utils/locationsToDistances";
import { AppointmentCard } from "../../components/AppointmentCard";
import Cell  from "./Cell";
import DroppableCell from "./MonthlyCell";
import { useTranslation } from 'react-i18next';

const daysOfTheWeek = [
	"Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function extractAppointments(data) {
  const appointments = [];

  // Iterate over each assignee object
  for (const assigneeId in data) {
    const assignee = data[assigneeId];
    for (const appointmentId in assignee.appointments) {
      const appointment = assignee.appointments[appointmentId];
      // Add the appointment info to the list
      appointments.push({
        date: appointment.date,
        title: appointment.name,
        assignee: assignee.assignee,
        address: appointment.address,
        id: appointment.id,
        note: appointment.note,
        status: appointment.status,
        latitude: appointment.latitude,
        longitude: appointment.longitude,
        start: appointment.start,
        end: appointment.end,
        duration: appointment.duration,
        userId: appointment.userId,
        name: appointment.name,
        appointment_category: appointment.appointment_category,
        attention: appointment.attention,
        type: appointment.type,
        billed_to: appointment.billed_to,
        canDrag: appointment.canDrag,
        block_type: appointment.block_type,
      });
    }
  }

  return appointments;
}


export default function MonthlyGrid ({
	date,
	toolTipModal,
	handleToolTip,
	refreshStatus,
	refreshPage,
	appointmentMoved,
	filterProps,
}) {
  const { t } = useTranslation();
  const tBase = "views.calendar.containers.monthly.monthlyContainer";
  const tr = (key) => t(`${tBase}.${key}`);

  const queryString = useQueryString();
  const { setMaxDistance } = useLocationContext();
	const [appointmentsList, setAppointmentsList] = useState([]);

  const startMonthDate = startOfMonth(date ? new Date(date) : new Date());
  const endMonthDate = endOfMonth(date ? new Date(date) : new Date());
	
  const daysInMonth = eachDayOfInterval({
    start: startMonthDate,
    end: endMonthDate,
  });
	
	const previousMonth = subMonths(startMonthDate, 1);
	const lastDateOfPreviousMonth = lastDayOfMonth(previousMonth);
	const firstDayOfWeek = new Date(startMonthDate).getDay();
	const startDisplayingFrom = lastDateOfPreviousMonth.getDate() - firstDayOfWeek + 1;
	const lastDayOfWeekCurrentMonth = new Date(endMonthDate).getDay();
	const daysNeededFromNextMonth = 6 - lastDayOfWeekCurrentMonth;
	let daysFromNextMonth = []; // keep this empty if no days needed from next month
	if (daysNeededFromNextMonth > 0) {
		daysFromNextMonth = eachDayOfInterval({
			start: addDays(endMonthDate, 1),
			end: addDays(endMonthDate, daysNeededFromNextMonth),
		});
	}

  let parseDate = date ? new Date(date) : new Date();
	let formatDate = format(parseDate, "MM/dd/yyyy");

  const { data: appointments, refetch } = useQuery(
    ["appointments", "monthly", formatDate, queryString.search],
    async () => {
      return await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/all`,
        {
          params: {
						date: formatDate,
            grid: "monthly",
            filters: queryString.search.division,
          },
        }
				).then((res) => {
					const rawData = res.data;
					setAppointmentsList(extractAppointments(rawData));  
					return rawData;
				});
    },
    {
      keepPreviousData: true,
      refetchInterval: 60000,
    }
  );

  const getUniqueAppointmentsForDay = (dayDate) => {
    const dayKey = format(dayDate, "yyyy-MM-dd");
    const appointmentsForDay = appointmentsList.filter(appointment =>
      appointment.date === dayKey
    );

    // Use a Map to track unique appointments by id
    const uniqueAppointmentsMap = new Map();
    appointmentsForDay.forEach(appointment => {
      if (!uniqueAppointmentsMap.has(appointment.id)) {
        uniqueAppointmentsMap.set(appointment.id, appointment);
      }
    });

    return Array.from(uniqueAppointmentsMap.values());
  };

  useEffect(() => {
		if (appointments) {
			const distances = locationsToDistances(
				extractLocations(appointments)
			);
			const max = Math.max(...distances);
			setMaxDistance(max);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appointments]);

	useEffect(() => {
		refreshStatus && refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshStatus]);

	if (!appointments) return (
		<>Loading...</>
	);

  return (
    <div
      className={`grid h-full grid-cols-7 grid-rows-${
        daysInMonth.length + 1
      } overflow-auto auto-rows-max border-t`}
    >
      {/* Render the days of the week as headers */}
      {daysOfTheWeek.map((dayName, i) => (
        <Cell key={i} className="sticky top-0 bg-white">
          {tr(dayName)}
        </Cell>
      ))}

      {/* Days from previous month */}
      {Array.from({ length: firstDayOfWeek }).map((_, i) => (
        <Cell
          key={"prev-" + (startDisplayingFrom + i)}
          className="text-gray-300"
        >
          {startDisplayingFrom + i}
        </Cell>
      ))}

      {/* Days from current month with DroppableCell */}
      {daysInMonth.map((dayDate, i) => {
        const dailyUniqueAppointments = getUniqueAppointmentsForDay(dayDate);

        return (
          <DroppableCell
            key={i}
            newDate={format(dayDate, "yyyy-MM-dd")}
            appointmentMoved={appointmentMoved}
          >
            {format(dayDate, "dd")}
            {dailyUniqueAppointments.map((appointment) => (
              <AppointmentCard 
                {...appointment}
                {...{ handleToolTip, toolTipModal }}
              />
            ))}
          </DroppableCell>
        );
      })}


      {/* Days from next month */}
      {daysFromNextMonth.map((dayDate, i) => (
        <Cell key={"next-" + i} className="text-gray-300">
          {format(dayDate, "dd")}
        </Cell>
      ))}
    </div>
  );
}

