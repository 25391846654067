import { companyClient } from "api/api";
import { ITags } from "../components/interfaces/ITags";

export const addTags = async (
  newTag: ITags
) => {
  return companyClient({
    method: "POST",
    url: "/tags",
    data: { newTag },
  });
};
