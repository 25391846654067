import { useMutation, useQueryClient } from "react-query";
import { addAppointmentType } from "../api/addAppointmentType";
import { IAppointmentType } from "../types/IAppointmentType";

export const useAppointmentAddMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (newAppointmentType: IAppointmentType) =>
      addAppointmentType(newAppointmentType),
    {
      onError: (error) => {
        console.error(error);
        return;
      },
      onSettled: () => {
        queryClient.invalidateQueries("appointment-types");
        return;
      },
    }
  );
};
