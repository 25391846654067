/* Created by Hannah Green on 4/28/2021 for ES-147 */
import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useModalContext } from "components/modal";
import { FaCalendarTimes, FaTag } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import ImageModal from './images';
import Tooltip from '@material-ui/core/Tooltip';
import { useDisplayModeState } from '../../../../contexts/displayMode';
import { useTranslation } from 'react-i18next';
import {
  Con,
  RepairInfo,
  UserIcon,
  UserInfo,
  Icons,
  Tag,
  Overdue,
  Status,
  Priority,
  Description,
  Comments,
  MaintNotes,
  Images,
  Middle,
  DetailsModal
} from './card-style';

const formatDate = (date) => {
  const oldDate = new Date(date);
  return oldDate.toLocaleDateString();
};

const dates = (entryDate, completeDate) => {
  let string;

  if (completeDate) {
    string = formatDate(entryDate) + " - " + formatDate(completeDate);
  } else {
    string = formatDate(entryDate);
  }

  return string;
};

const numDaysBetween = (today, entryDate) => {
  return Math.round((today - entryDate) / (1000 * 60 * 60 * 24));
};

const checkDate = (numDays, today, entryDate) => {
  const daysBetween = numDaysBetween(today, entryDate);
  return daysBetween > numDays ? true : false;
};

const descStyle = {
  fontSize: "15px",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "10px"
}

export default function RequestCard(props) {
  const { t } = useTranslation();
  const tBase = "views.repair_request.components.requests.request-card";
  const tr = (key) => t(`${tBase}.${key}`);

  const [portrait, setPortrait] = useState();
  const width = window.outerWidth;

  useEffect(() => {
    switch (window.orientation) {
      case -90:
      case 90:
        setPortrait(false);
        break;
      default:
        if (width < 1000) {
          setPortrait(true);
        } else {
          setPortrait(false);
        }
        break;
    }
  }, [width]);

  window.addEventListener('orientationchange', () => {
    switch (window.orientation) {
      case -90:
      case 90:
        setPortrait(false);
        break;
      default:
        if (width < 1000) {
          setPortrait(true);
        } else {
          setPortrait(false);
        }
        break;
    }
  });

  const request = props.request;
  const name = request.first_name + " " + request.last_name;
  const equip = request.equip_type + " " + request.equip_num;
  const equipment = request.equip_num ? equip : request.equip_type;
  const entryDateString = dates(request.request_date, request.date_completed);
  const entryDate = new Date(request.request_date);
  const today = new Date();
  const { setModal } = useModalContext();
  const displayModeState = useDisplayModeState();

  let overdue;
  let text_color;
  let background_color;

  if (request.request_priority === "1") {
    overdue = checkDate(30, today, entryDate);
  } else if (request.request_priority === "2") {
    overdue = checkDate(7, today, entryDate);
  } else {
    overdue = checkDate(1, today, entryDate);
  }

  if (request.status === "New") {
    text_color = "#4A235A";
    background_color = "#E8DAEF";
  } else if (request.status === "In Progress") {
    text_color = "brown";
    background_color = "#FAE5D3";
  } else if (request.status === "Complete") {
    text_color = "green";
    background_color = "#D4EFDF";
  }

  return (
    <Con>
      <div onClick={() => {
        setModal({
          component: <DetailsModal request={request} />,
          label: tr("Details")
        });
      }}>
        <RepairInfo>
          <UserIcon>
            <Avatar
              alt={name}
              src="/broken-image.jpg"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#1f618d"
              }}
            >
              {name.match(/\b(\w)/g).join("")}
            </Avatar>
          </UserIcon>
          <UserInfo>
            <div>{name}</div>
            <div>
              <div>{equipment}</div>
              <div>{entryDateString}</div>
            </div>
          </UserInfo>
          {!portrait &&
            <>
              <Middle>
                <Description>
                  <label>{tr("Description")}</label>
                  <div>{request.description}</div>
                </Description>

                {request.req_comments &&
                  <Comments>
                    <label>{tr("Comments")}</label>
                    <div>{request.req_comments}</div>
                  </Comments>
                }

                {request.maint_notes &&
                  <MaintNotes>
                    <label>{tr("Maintenance Notes")}</label>
                    <div>{request.maint_notes}</div>
                  </MaintNotes>
                }
              </Middle>
            </>
          }
          <Icons>
            {overdue && request.status !== "Complete" &&
              <Tooltip title={tr("Overdue")} placement="top">
                <Overdue>
                  <FaCalendarTimes size="25" color="red" />
                </Overdue>
              </Tooltip>
            }
            {request.lockout_tagout && <>
              <Tooltip title={tr("Lockout/Tagout")} placement="top">
                <Tag>
                  <FaTag size="25" color="#1f618d" />
                </Tag>
              </Tooltip>
            </>}

            {request.request_priority === "1" && <>
              <Tooltip title={tr("Low Priority")} placement="top">
                <Priority>
                  <GoAlert color="Green" size={25} />
                </Priority>
              </Tooltip>
            </>}

            {request.request_priority === "2" && <>
              <Tooltip title={tr("Medium Priority")} placement="top">
                <Priority>
                  <GoAlert color="Orange" size={25} />
                </Priority>
              </Tooltip>
            </>}

            {request.request_priority === "3" && <>
              <Tooltip title={tr("High Priority")} placement="top">
                <Priority>
                  <GoAlert color="Red" size={25} />
                </Priority>
              </Tooltip>
            </>}

            <Tooltip title={tr("Status")} placement="top">
              <Status color={text_color} background_color={background_color}>
                <p>{request.status}</p>
              </Status>
            </Tooltip>
          </Icons>
        </RepairInfo>
        {portrait &&
          <>
            <Description style={descStyle}>
              <label>{tr("Description")}</label>
              <div>{request.description}</div>
            </Description>
            {request.req_comments &&
              <Comments>
                <label>{tr("Comments")}</label>
                <div>{request.req_comments}</div>
              </Comments>
            }

            {request.maint_notes &&
              <MaintNotes>
                <label>{tr("Maintenance Notes")}</label>
                <div>{request.maint_notes}</div>
              </MaintNotes>
            }
          </>
        }
      </div>
      {!displayModeState.displayMode &&
        <Images>
          {request.images && request.images.map((image, index) => {
            return (
              <ImageModal image={image} key={index} />
            )
          })}
        </Images>
      }
    </Con>
  )
}