import axios from "axios";
import { useAreYouSure } from "components/areYouSure";
import { Avatar } from "components/avatar";
import { IconButton, NoStyleA } from "components/button";
import DataLabel from "components/dataLabel";
import Dropdown from "components/dropdown";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { formatMmDdYyyy } from "../../utils/formatDate";
import * as React from "react";
import { RiMore2Line } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { TProperty } from "types/property";
import NewPropertyModal from "./newPropertyModal";
import PropertyTagList from "views/property/property-tag-list";
import { useTranslation } from 'react-i18next';

type Props = {
  property: TProperty;
  customerId?: string;
};

export default function PropertyCon({ property, customerId }: Props) {
  const { t } = useTranslation();
  const tBase = "views.properties.propertyCon";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modal = useModalContext(),
    areYouSure = useAreYouSure(),
    queryClient = useQueryClient(),
    snackbar = useSnackbarContext();

  const deleteProp = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${property.id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        snackbar.showSnackbar(tr("Property Deleted!"));
      },
      onError: () => {
        modal.setModal({
          component: (
            <div>
              {tr("The property has information attached that can't be removed")}
            </div>
          ),
          label: tr("Error"),
        });
      },
    }
  );
  return (
    <div
      key={property.id}
      className="flex flex-col py-4 !border-solid !border-slate-200"
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="space-x-2 text-sm text-slate-500">
            <span>{tr("Property")} #{property.id}</span>
            <span>|</span>
            <span>
              {property.date
                ? `${tr("Most recent appointment")}: ${formatMmDdYyyy(property.date)}`
                : tr("No appointment")}
            </span>
          </div>
          <div className="flex flex-row">
            <NoStyleA
              className="text-lg !text-blue-700 hover:!underline !self-start"
              to={`/property/${property.id}`}
            >
              {property.full_address}
            </NoStyleA>
            <div className="text-sm">
              <PropertyTagList tags={property.tags} />
            </div>
          </div>
          <span className="italic">{property.name}</span>
        </div>
        <div>
          <Dropdown
            reference={
              <IconButton square>
                <RiMore2Line />
              </IconButton>
            }
            data={[
              {
                button: [
                  {
                    label: tr("Edit"),
                    onClick: () =>
                      modal.setModal({
                        component: (
                          <NewPropertyModal
                            customerId={customerId}
                            property={property}
                          />
                        ),
                        label: `${tr("Property")} #${property.id}`,
                      }),
                  },
                  {
                    label: tr("Delete"),
                    onClick: () =>
                      areYouSure.activate(() => deleteProp.mutate()),
                  },
                ],
              },
            ]}
          />
        </div>
      </div>

      <div className="flex mt-2 space-x-8">
        {property.owner && (
          <DataLabel title={tr("Owner")}>
            <NoStyleA to={`/customers/${property.owner?.id}`}>
              <Avatar showLabel size="xsmall">
                {property.owner?.name}
              </Avatar>
            </NoStyleA>
          </DataLabel>
        )}
        {property.billed_to && (
          <DataLabel title={tr("Billed To")}>
            <NoStyleA to={`/customers/${property.billed_to?.id}`}>
              <Avatar showLabel size="xsmall">
                {property.billed_to?.name}
              </Avatar>
            </NoStyleA>
          </DataLabel>
        )}
      </div>
    </div>
  );
}
