import React, { useState } from "react";
import { IAppointmentType } from "../types/IAppointmentType";
import { AppointmentType } from "./AppointmentType";
import {IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from 'react-i18next';

type Props = {
  appointmentTypes: IAppointmentType[];
};

const AppointmentTypeList = ({ appointmentTypes }: Props) => {
  const [showArchived, setShowArchived] = useState(false);

  const { t } = useTranslation();
  const tBase = "views.admin.appointment-types.components.AppointmentTypeList";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const activeAppointmentTypes = appointmentTypes.filter(
    (appointmentType) => appointmentType.active
  );

  const archivedAppointmentTypes = appointmentTypes.filter(
    (appointmentType) => !appointmentType.active
  );

  return (
    <div>
      {/* Appointment Types */}
      <div>
        {activeAppointmentTypes?.map((appointmentType: IAppointmentType) => (
          <AppointmentType
            key={appointmentType.id}
            appointmentType={appointmentType}
          />
        ))}
      </div>
      {/* Archived */}
      <div className="pt-3">
        <div className="flex items-center space-x-2">
          <h1>{tr("Archived")}</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className="flex items-center"
          >
            {showArchived 
              ? <IoIosArrowDown size={20} className="hover:text-primary" /> 
              : <IoIosArrowUp size={20} className="hover:text-primary" />
            }
          </button>
        </div>
        <div>
          {!showArchived && archivedAppointmentTypes?.map((appointmentType: IAppointmentType) => (
            <AppointmentType
              key={appointmentType.id}
              appointmentType={appointmentType}
              archived={true}
            />
          ))}
        </div>
      </div>
      <div className="h-[100px]"></div>
    </div>
  );
};

export { AppointmentTypeList };
