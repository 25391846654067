import { useCallback, useEffect, useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import {
  FullscreenControl,
  Map as MapboxMap,
  MapRef,
  Marker,
  NavigationControl,
} from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import {
  FaCheckCircle,
  FaHome,
  FaMapMarker,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { BiRectangle } from "react-icons/bi";

import basemaps from "features/map/data/basemaps";
import TMapAppointment from "features/map/types/TMapAppointment";
import { useAppointments } from "features/map/hooks/useAppointments";
import useSelectedAppointments from "features/map/hooks/useSelectedAppointments";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

class HomeControl {
  private _onClick: () => void;
  private _map: any;
  private _container!: HTMLButtonElement;

  constructor(onClick: () => void) {
    this._onClick = onClick;
  }

  onAdd(map: any) {
    this._map = map;
    this._container = document.createElement("button");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this._container.style.display = "block";
    this._container.style.border = "none";
    this._container.style.borderRadius = "2px";
    this._container.style.boxShadow = "0 0 0 2px rgba(0, 0, 0, 0.1)";
    this._container.style.cursor = "pointer";
    this._container.style.backgroundPosition = "center";
    this._container.style.backgroundRepeat = "no-repeat";
    this._container.style.margin = "8px";
    this._container.style.padding = "7px";
    this._container.innerHTML = ReactDOMServer.renderToString(<FaHome />);
    this._container.onclick = this._onClick;
    return this._container;
  }

  onRemove() {
    if (this._container && this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
      this._map.off("click", this._onClick);
      this._map = undefined;
    }
  }
}

class RectangleButtonControl implements mapboxgl.IControl {
  private _container: HTMLDivElement;
  private _button: HTMLButtonElement;
  private _map?: mapboxgl.Map;

  constructor(private clickCallback: () => void) {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this._button = document.createElement("button");
    this._button.className = "mapboxgl-ctrl-icon";
    const iconSvg = ReactDOMServer.renderToStaticMarkup(<BiRectangle />);
    const iconDataUrl = `data:image/svg+xml;base64,${btoa(iconSvg)}`;
    this._button.style.backgroundImage = `url(${iconDataUrl})`;
    this._button.style.backgroundRepeat = "no-repeat";
    this._button.style.backgroundPosition = "center";
    this._button.style.backgroundSize = "50%";
    this._button.addEventListener("click", clickCallback);
    this._container.appendChild(this._button);
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    this._map = map;
    return this._container;
  }

  public onRemove(): void {
    if (this._map) {
      this._button.removeEventListener("click", this.clickCallback);
      this._container.parentNode?.removeChild(this._container);
      this._map = undefined;
    }
  }
}

const modes = MapboxDraw.modes;

const Map = () => {
  const mapRef = useRef<MapRef | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  const { data, isLoading, isError } = useAppointments();
  const { selectedAppointments, setSelectedAppointments } =
    useSelectedAppointments();

  const onRectangleButtonClick = useCallback(() => {
    if (drawRef.current) {
      drawRef.current.changeMode("draw_rectangle");
    }
  }, []);

  const rectangleButtonControl = useMemo(
    () => new RectangleButtonControl(onRectangleButtonClick),
    [onRectangleButtonClick]
  );

  const onMapLoad = useCallback((event) => {
    const map = event.target;

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
      },
      modes: {
        ...modes,
        draw_rectangle: DrawRectangle,
      },
    });

    map.addControl(drawRef.current);

    map.on("draw.create", (event: any) => {
      const rectangleCoordinates = event.features[0].geometry.coordinates[0];
      const rectangle = turf.polygon([rectangleCoordinates]);
      const markersWithinRectangle = data?.appointments?.filter(
        ({ longitude, latitude }: TMapAppointment) => {
          if (longitude && latitude) {
            const point = turf.point([longitude, latitude]);
            return turf.booleanPointInPolygon(point, rectangle);
          }
          return false;
        }
      );

      // Extract the appointment_id from each selected marker
      const selectedAppointmentIds =
        markersWithinRectangle?.map((marker) => marker.appointment_id) || [];

      // Store the selected appointment IDs
      setSelectedAppointments(selectedAppointmentIds);

      // Clear the drawn shapes
      if (drawRef.current) drawRef.current.deleteAll();
    });

    const homeControl = new HomeControl(() => {
      map.flyTo({
        center: [-96.1345, 41.2565],
        zoom: 9,
      });
    });

    map.addControl(homeControl, "top-left");
    map.addControl(rectangleButtonControl, "top-right");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const map = mapRef.current;

    return () => {
      if (map && drawRef.current) {
        map.removeControl(drawRef.current);
      }
    };
  }, []);

  return (
    <MapboxMap
      ref={mapRef}
      onLoad={onMapLoad}
      initialViewState={{
        latitude: 41.2565,
        longitude: -96.1345,
        zoom: 9,
      }}
      style={{
        width: "78vw",
        height: "calc(100vh - 118px)",
        borderRadius: "5px",
      }}
      mapStyle={basemaps[2].uri}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "10px",
        }}
      >
        <NavigationControl />
      </div>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "10px",
        }}
      >
        <FullscreenControl />
      </div>
      {isLoading && <div>Loading...</div>}
      {isError && <div>Error</div>}
      {data &&
        data?.appointments?.map(
          ({ appointment_id, longitude, latitude, color }: TMapAppointment) => {
            const isSelected = selectedAppointments.includes(appointment_id);
            const Icon = isSelected ? FaMapMarker : FaMapMarkerAlt;

            const toggleSelected = () => {
              if (isSelected) {
                setSelectedAppointments(
                  selectedAppointments.filter((id) => id !== appointment_id)
                );
              } else {
                setSelectedAppointments([
                  ...selectedAppointments,
                  appointment_id,
                ]);
              }
            };

            return (
              longitude &&
              latitude && (
                <Marker
                  key={appointment_id}
                  longitude={longitude}
                  latitude={latitude}
                >
                  <div
                    style={{ position: "relative", cursor: "pointer" }}
                    onClick={toggleSelected}
                  >
                    <Icon
                      style={{
                        color: `rgba(${color},${isSelected ? "1" : "0.8"})`,
                        fontSize: "34px",
                      }}
                    />
                    {isSelected && (
                      <FaCheckCircle
                        style={{
                          position: "absolute",
                          top: "38%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "lightgreen",
                          fontSize: "12px",
                        }}
                      />
                    )}
                  </div>
                </Marker>
              )
            );
          }
        )}
    </MapboxMap>
  );
};

export default Map;
