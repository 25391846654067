// Makes text either white or black depending on contrast with background

const getColorFromPercentage = (percentage: number): string => {

	const r = percentage < 0.5 ? 2 * percentage : 1;
	const g = percentage < 0.5 ? 1 : 2 * (1 - percentage);
	const b = 0;

	const color = `rgb(${Math.round(r * 255)}, ${Math.round(
		g * 255
	)}, ${Math.round(b * 255)})`;

	return color;
};

export { getColorFromPercentage };
