import React, { createContext, useContext, useReducer } from "react";

const DropdownStateContext = createContext();
const DropdownDispatchContext = createContext();

const initialState = { status: false, item: "" };

const dropdownReducer = (state, action) => {
  switch (action.type) {
    case "ITEMCLICK":
      return { status: !state.status, item: action.item };
    case "BUTTONCLICK":
      return { status: !state.status, item: state.item };
    default:
      return { item: state.item };
  }
};

function DropdownContextProvider({ children }) {
  const [state, dispatch] = useReducer(dropdownReducer, initialState);

  return (
    <DropdownStateContext.Provider value={state}>
      <DropdownDispatchContext.Provider value={dispatch}>
        {children}
      </DropdownDispatchContext.Provider>
    </DropdownStateContext.Provider>
  );
}

function useDropdownState() {
  const context = useContext(DropdownStateContext);

  if (context === undefined) {
    console.info(
      "useDropdownState must be used within a Dropdown context provider"
    );
  }

  return context;
}

function useDropdownDispatch() {
  const context = useContext(DropdownDispatchContext);

  if (context === undefined) {
    console.info(
      "useDropdownDispatch must be used within a Dropdown context provider"
    );
  }

  return context;
}

function useDispatchAndState() {
  return [useDropdownDispatch(), useDropdownState()];
}

export {
  DropdownContextProvider,
  useDropdownState,
  useDropdownDispatch,
  useDispatchAndState,
};
