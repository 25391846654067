import React, { useState, useEffect, ChangeEvent } from "react";
import { Input } from "components/input";
import { Button } from "components/button";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { useSnackbarContext } from "components/snackbar";
import { FaInfoCircle } from "react-icons/fa";
import TooltippedElement from "components/TooltippedElement";
import Select from "react-select";
import Switch from "rc-switch";
import { useAuthContext } from "contexts/auth";
import { useModalContext } from "components/modal";
import SimpleTable from "./simple-table";
import Spinner from "components/spinner";
import { useTranslation } from "react-i18next";

interface TReminder {
    id?: number;
    type: string;
    linked_document: string;
    delay: number
    frequency: number
    duration: number
    active: boolean;
}

const getAppointmentEmailConfirmation = async () => {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/emailConfirmation`);
    return res.data;
};

const setAppointmentEmailConfirmation = async (config: boolean) => {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/emailConfirmation`, { config });
};

const CustomReminderSelect = ({ reminder, handleInputChange, index, customDocs, reminderData, setReminderData, setErrors }: any) => {
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            width: '10em',
        }),
    };

    const availableDocs = customDocs
        ? customDocs.filter((doc: any) => !reminderData.some((r: any) => r.linked_document === doc.label))
        : [];

    const filteredDocs = availableDocs ? availableDocs.filter((doc: any) => doc.type.toLowerCase() === reminder.type.toLowerCase()) : [];

    return (
        customDocs ?
            <Select
                value={{ value: reminder.linked_document, label: reminder.linked_document }}
                onChange={(e) => handleInputChange(index, "linked_document", e!.value, reminderData, setReminderData, setErrors)}
                options={filteredDocs.map((doc: any) => ({ value: doc.label, label: doc.label }))}
                styles={customStyles}
            />
            :
            <Select
                value={{ value: reminder.type, label: reminder.type }}
                onChange={(e) => handleInputChange(index, "type", e!.value, reminderData, setReminderData, setErrors)}
                options={[
                    { value: "invoice", label: "invoice" },
                ]}
                styles={customStyles}
            />
    );
};

const handleInputChange = (index: number, field: keyof TReminder, value: any, reminderData: any, setReminderData: any, setErrors: any) => {
    const updatedReminders = reminderData.map((reminder: any, i: any) => {
        if (i === index) {
            if (field === "delay") {
                const newValue = value.toString().replace(/^0|[^0-9]/g, '').replace(/^(0\d)/, '0');
                return { ...reminder, [field]: newValue !== "" ? Number(newValue) : null };
            } else if (field === "frequency") {
                const newValue = value.toString().replace(/[^0-9]/g, '');
                checkForErrors(index, { ...reminder, [field]: newValue !== "" ? Number(newValue) : null }, setErrors);
                return { ...reminder, [field]: newValue !== "" ? Number(newValue) : null };
            } else if (field === "type") {
                return { ...reminder, [field]: value.toString(), linked_document: "" };
            } else if (field === "duration") {
                const newValue = value.toString().replace(/[^0-9]/g, '');
                checkForErrors(index, { ...reminder, [field]: newValue !== "" ? Number(newValue) : null }, setErrors);
                return { ...reminder, [field]: newValue !== "" ? Number(newValue) : null };
            } else if (field === "active") {
                return { ...reminder, [field]: Boolean(value) };
            } else {
                return { ...reminder, [field]: value };
            }
        }
        return reminder;
    });
    setReminderData(updatedReminders);
};

const checkForErrors = (index: number, reminder: TReminder, setErrors: any, isSaveChangesButton = false) => {
    if (reminder.frequency !== null && reminder.duration !== null && reminder.frequency > reminder.duration && isSaveChangesButton) {
        setErrors((prevErrors: any) => ({ ...prevErrors, [index]: "Frequency cannot be greater than duration" }));
        return true;
    } else {
        setErrors((prevErrors: any) => {
            const newErrors = { ...prevErrors };
            delete newErrors[index];
            return newErrors;
        });
        return false;
    }
};

const LogModal = () => {
    const [records, setRecords] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [debouncedPage, setDebouncedPage] = useState(page);

    const getLogData = React.useCallback(async () => {
        try {
            console.log("getLogData");
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/reminderLogs`, {
                params: { page: debouncedPage, limit },
            });
    
            res.data.data.forEach((log: any) => {
                log.reminder_at = new Date(log.reminder_at).toLocaleString();
            });
    
            setRecords(res.data.data);
            setTotalPages(res.data.pageCount);
        } catch (err) {
            console.error("Error fetching log data:", err);
        }
    }, [debouncedPage, limit]);

    //? consider combining these two useEffects into one
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedPage(page);
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [page]);

    useEffect(() => {
        setPage(1);
    }, [limit]);

    useEffect(() => {
        getLogData();
    }, [debouncedPage, limit, getLogData]);

    const paginationHandler = (newPage: number) => {
        setPage(newPage);
    };

    if (!records.length) {
        return <Spinner />;
    }

    return (
        <SimpleTable 
            columns={["FIRST NAME", "LAST NAME", "EMAIL", "INVOICE ID", "LINKED DOCUMENT", "SENT AT"]}
            columnDataMapping={{
                "FIRST NAME": "first_name",
                "LAST NAME": "last_name",
                "EMAIL": "email",
                "INVOICE ID": "fk_invoice_id",
                "LINKED DOCUMENT": "linked_document",
                "SENT AT": "reminder_at",
            }}
            pagination={paginationHandler}
            {...{ records, totalPages, page, limit, setPage, setLimit }}
        />
    );
};



const Reminders = () => {
    const [reminderData, setReminderData] = useState<TReminder[]>([
        { type: "Invoice", linked_document: "", delay: 0, frequency: 0, duration: 5, active: false, id: 0 },
    ]);
    const { showSnackbar } = useSnackbarContext();
    const [isAppointmentEmailActive, setIsAppointmentEmailActive] = useState<boolean>();
    const [reminderUpdateLoading, setReminderUpdateLoading] = useState(false);
    const [incompleteData, setIncompleteData] = useState(true);
    const [errors, setErrors] = useState<{ [key: number]: string }>({});
    const { t } = useTranslation();
    const tr = (key: string) => t(`views.admin.reminders.${key}`);

    const auth = useAuthContext();
    const { setModal } = useModalContext();

    useEffect(() => {
        console.log("errors", errors)
    }, [errors, reminderData]);

    const customDocs = useQuery(
        "customDocs",
        async () => {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-doc`);
            res.data = res.data.filter((doc: any) => doc.type === "invoice");
            return res.data;
        }
    );

    const getAppointmentEmailConfirmationMut = useMutation(
        getAppointmentEmailConfirmation,
        {
            onSuccess: (data) => {
                setIsAppointmentEmailActive(data);
            },
        }
    );

    const setAppointmentEmailConfirmationMut = useMutation(
        setAppointmentEmailConfirmation,
        {
            onSuccess: () => {
                showSnackbar(tr("Appointment email configuration updated!"));
            },
        }
    );

    const getReminders = useMutation(
        async () => {
            const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/invoice`);
            return res.data;
        },
        {
            onSuccess: (data) => {
                const normalizedData = data.map((reminder: TReminder) => ({
                    db_id: reminder.id || null,
                    type: reminder.type,
                    linked_document: reminder.linked_document,
                    delay: reminder.delay || 0,
                    frequency: reminder.frequency || 0,
                    duration: reminder.duration || 5,
                    active: reminder.active || false,
                }));
                setReminderData(normalizedData);
            },
        }
    );

    useEffect(() => {
        console.log('Reminder data use effect:', reminderData);
        setIncompleteData(reminderData.some(reminder => reminder.type === "" || reminder.linked_document === "" || reminder.delay === null || reminder.frequency === null || reminder.duration === null));
    }, [reminderData]);

    const updateReminders = useMutation(
        async () => {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/invoice`, reminderData);
            return response.data;
        },
        {
            onSuccess: (data: any) => {
                setReminderUpdateLoading(false);
                setReminderData(data);
                showSnackbar(tr("Reminders updated!"));
            }
        }
    );

    useEffect(() => {
        getReminders.mutate();
        getAppointmentEmailConfirmationMut.mutate();
    }, []);

    useEffect(() => {
        if (Object.values(errors).length > 0) {
            showSnackbar("Frequency cannot be greater than duration");
        }
    }, [errors]);

    const reminderSubmitHandler = (update: boolean = false) => {
        for (let i = 0; i < reminderData.length; i++) {
            var hasErr = checkForErrors(i, reminderData[i], setErrors, true)
            if (hasErr) {
                return;
            }
        }

        for (let reminder of reminderData) {
            if (reminder.delay === null || reminder.frequency === null || reminder.duration === null) {
                showSnackbar("Please ensure all reminders have delay and frequency values.");
                return;
            }
        }
        updateReminders.mutate();
        setReminderUpdateLoading(true);
    };

    const addReminder = () => {
        setReminderData([...reminderData, { type: "", linked_document: "", delay: 1, frequency: 0, duration: 5, active: false }]);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignSelf:"flex-end", justifyContent: "space-between", columnGap: "10px" }}>
                <Button 
                    type="button" 
                    onClick={() => setModal({ component: <LogModal />, label: "Log" })}
                >
                    View Log
                </Button>
                <Button
                    primary
                    onClick={() => reminderSubmitHandler(true)}
                    disabled={reminderData.some(reminder => reminder.delay === null || reminder.frequency === null || reminder.duration === null || reminderUpdateLoading) || incompleteData || Object.values(errors).length > 0}
                >
                    Save Changes
                </Button>
            </div>
            <h2>
                {tr("Reminder Defaults")}
            </h2>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "20px", justifyContent: "center", columnGap: "10px", backgroundColor: "#cfcfcf", padding: "10px", borderRadius: "5px" }}>
                <span style={{ fontSize: "1.2em" }}>
                    {tr("Default")}: {tr("Send Email Confirmation for Appointments")}
                </span>
                <Switch
                    checked={isAppointmentEmailActive}
                    onChange={(checked) => {
                        setIsAppointmentEmailActive(checked);
                        setAppointmentEmailConfirmationMut.mutate(checked);
                    }}
                />
            </div>
            <div style={{ marginTop: "50px" }}>
                <h2>
                    {tr("Custom Document Reminders")}
                </h2>
                <div style={{ backgroundColor: "#cfcfcf", borderRadius: "5px", marginTop: "20px" }}>
                    {reminderData.map((reminder, index) => (
                        <div key={index} style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", justifyItems: "center", columnGap: "10px", rowGap: "10px", width: "75%", margin: "auto", padding: "20px" }}>
                            <p>Type</p>
                            <p>Linked Document</p>
                            <TooltippedElement
                                message={tr("Number of days before the reminder is sent")}
                                placement="top"
                                element={
                                    <p style={{ display: "flex", alignItems: "center" }}>
                                        {tr("Delay")}
                                        <FaInfoCircle className="ml-1 text-secondary" />
                                    </p>
                                }
                            />
                            <TooltippedElement
                                message="Number of days between each subsequent email"
                                placement="top"
                                element={
                                    <p style={{ display: "flex", alignItems: "center" }}>
                                        {tr("Frequency")}
                                        <FaInfoCircle className="ml-1 text-secondary" />
                                    </p>
                                }
                            />
                            <TooltippedElement
                                message="Number of days reminders will be sent, starting after delay"
                                placement="top"
                                element={
                                    <p style={{ display: "flex", alignItems: "center" }}>
                                        Duration
                                        <FaInfoCircle className="ml-1 text-secondary" />
                                    </p>
                                }
                            />
                            <p>Active</p>
                            <CustomReminderSelect
                                reminder={reminder}
                                handleInputChange={handleInputChange}
                                index={index}
                                {...{ reminderData, setReminderData, setErrors }}
                            />                            
                            <CustomReminderSelect
                                reminder={reminder}
                                handleInputChange={handleInputChange}
                                index={index}
                                customDocs={customDocs.data}
                                {...{ reminderData, setReminderData, setErrors }}
                            />
                            <Input
                                type="text"
                                placeholder={tr("Delay")}
                                value={reminder.delay === null ? '' : reminder.delay.toString()}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, "delay", e.target.value, reminderData, setReminderData, setErrors)}
                                maxLength={2}
                                backgroundColorFocus="white"
                            />
                            <Input
                                type="text"
                                placeholder={tr("Frequency")}
                                value={reminder.frequency === null ? '' : reminder.frequency.toString()}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, "frequency", e.target.value, reminderData, setReminderData, setErrors)}
                                maxLength={2}
                                backgroundColorFocus="white"
                                style={errors[index] ? { border: "1px solid red", borderRadius: "5px" } : {}}
                            />
                            <div style={{ position: "relative", width: "100%", textAlign: "center" }}>
                                <Input
                                    type="text"
                                    placeholder="Duration"
                                    value={reminder.duration === null ? '' : reminder.duration.toString()}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, "duration", e.target.value, reminderData, setReminderData, setErrors)}
                                    backgroundColorFocus="white"
                                    style={errors[index] ? { border: "1px solid red", borderRadius: "5px" } : {}}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input
                                    type="checkbox"
                                    checked={reminder.active}
                                    onChange={() => handleInputChange(index, "active", !reminder.active, reminderData, setReminderData, setErrors)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {customDocs.data && reminderData.length < customDocs.data.length &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <Button
                            primary
                            onClick={() => addReminder()}
                        >
                            {tr("Add Reminder")}
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
};

export default Reminders;
