import Spinner from "components/spinner";
import { FormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { StyledComponent, StyledComponentProps } from "styled-components";
import { Size } from "types/theme";
import { InputProps } from ".";
import { InputCon, StyledInput } from "./style";

// export type TInput = React.HTMLProps<HTMLInputElement>;

export type TInput = Omit<
  StyledComponentProps<
    "input",
    any,
    {
      size?: string | undefined;
      error?: string | undefined;
      search?: boolean | undefined;
      $size?: Size;
    },
    never
  >,
  ""
> & {
  //   search?: boolean;
  isLoading?: boolean;
  label?: string;
  error?: string;
  size?: string;
  horizontal?: boolean;
  type?: string;
  value?: string | null | number;
  name?: string;
  formik?: FormikProps<any>;
};

const InputV2 = (
  props: TInput & { forwardedRef: React.ForwardedRef<HTMLInputElement> }
) => {
  const value =
    props.value ??
    (props.name && get(props.formik?.values, props.name)) ??
    (props.formik && "") ??
    undefined;

  return (
    <InputCon hasLogo={props.search}>
      <StyledInput
        onChange={props.formik?.handleChange}
        onBlur={props.formik?.handleBlur}
        value={value}
        $error={props.error}
        ref={props.forwardedRef}
        $size={props.$size || "regular"}
        {...props}
        className="bg-black"
      />
      {props.isLoading && <Spinner />}
    </InputCon>
  );
};

export default InputV2;
