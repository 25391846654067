import React from "react";
import ItemDetails from "./item-details";
import styled from "styled-components";
import Form from "components/form";
import { useCartCountDispatch } from "components/cart";
import { useTranslation } from 'react-i18next';
const { Button } = require("components/button");
const { Input } = require("components/input");

const ItemCon = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 185px;
  font-size: 15px;
  margin: 0 20px 20px 0;
  border: 1px solid lightgray;
  padding: 10px;
  box-shadow: 4px 4px 5px lightgray;
  justify-content: space-between;
`;

const ItemImage = styled.img`
  height: 100px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  background-color: rgb(214, 214, 214);
  grid-column: 1 / span 2;
  position: relative;
  > *:last-child {
    color: grey;
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  grid-column: span 2;
  margin-top: 5px;
  padding: 0;
  > div:first-child {
    color: grey;
    font-size: 0.8rem;
  }
  > div:nth-child(3) {
    margin-top: auto;
    color: grey;
    margin-bottom: 0;
  }
`;

export default function ItemCard(props) {
  const { item, setModal } = props;
  const cartDispatch = useCartCountDispatch();
  const { t } = useTranslation();
  const tBase = "views.order.components.item-card";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <ItemCon>
      <ItemImage
        src={item.image}
        alt="Image"
        onClick={() => {
          setModal({
            component: <ItemDetails item={item} image={item.image} />,
            label: tr("Item Details"),
          });
        }}
      />
      <ItemDescription>
        <div>{item.item_name}</div>
        <div>{item.short_desc}</div>
        <Form
          initialValues={{ qty: 1, item: item }}
          onSubmit={(values) => {
            values.item.qty = values.qty;
            cartDispatch({ type: "ADD", items: values.item, qty: values.qty });
          }}
        >
          <Input name="qty" label="Qty" type="number" />
          <Button style={{ marginTop: 10 }} type="submit" primary>
            {tr("Add To Cart")}
          </Button>
        </Form>
      </ItemDescription>
    </ItemCon>
  );
}
