import { Button } from "components/button";
import Field from "components/field";
import { useState } from "react";
import Map, { Marker, useMap } from "react-map-gl";
import { geoLocatePermanentAddress } from "./api/geoLocateAddressApi";
import { useTranslation } from 'react-i18next';

type Props = {
  formik: any; // TODO: type this formik
};

const NewPropertyMap = ({ formik }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.properties.NewPropertyMap";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { newPropertyMap } = useMap();
  const [location, setLocation] = useState({
    latitude: formik.values.latitude || 39.828249,
    longitude: formik.values.longitude || -98.579418,
  });

  const handleClickLocate = async () => {
    const address = formik.values;
    if (address.address && address.city && address.state && address.zipcode) {
      const geoLocateResponse = await geoLocatePermanentAddress(
        `
        ${address.address} ${address.city} ${address.state}, ${address.zipcode}
        `
      );

      if (geoLocateResponse) {
        const newLocation = {
          longitude: geoLocateResponse[0],
          latitude: geoLocateResponse[1],
        };

        if (newPropertyMap && newLocation) {
          setLocation({
            longitude: newLocation.longitude,
            latitude: newLocation.latitude,
          });

          newPropertyMap.flyTo({
            center: [newLocation.longitude, newLocation.latitude],
            zoom: 18,
          });

          formik.setFieldValue("longitude", newLocation.longitude);
          formik.setFieldValue("latitude", newLocation.latitude);
        }
      }
    } else {
      alert(tr("Please fill out all address fields"));
    }
  };

  const handleDragEnd = (e: any) => {
    const { lng, lat } = e.lngLat;
    setLocation({ longitude: lng, latitude: lat });
    formik.setFieldValue("longitude", lng);
    formik.setFieldValue("latitude", lat);
  };

  return (
    <>
      <Map
        id="newPropertyMap"
        initialViewState={{
          longitude: location.longitude,
          latitude: location.latitude,
          zoom: formik.values.latitude && formik.values.longitude ? 18 : 2,
        }}
        style={{ width: "100%", height: 300, borderRadius: 10 }}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      >
        <div className="absolute flex top-1 left-1 z-5">
          <Button className="" onClick={handleClickLocate}>
            {tr("Locate")}
          </Button>
          <Field
            name="latitude"
            className="w-[100] ml-1"
            formik={formik}
            placeholder={tr("Latitude")}
          />
          <Field
            name="longitude"
            className="w-[100] ml-1"
            formik={formik}
            placeholder={tr("Longitude")}
          />
        </div>
        {formik.values.latitude && formik.values.longitude ? (
          <Marker
            draggable
            latitude={location.latitude}
            longitude={location.longitude}
            onDragEnd={handleDragEnd}
          />
        ) : null}
      </Map>
    </>
  );
};

export { NewPropertyMap };
