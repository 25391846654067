/* Created by Hannah Green on 02/08/2021 for ES-133 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Select from "components/react-select";
import * as yup from "yup";
import { useSnackbarContext } from "components/snackbar";
import RawForm from "components/form";
import { useMutation } from "react-query";
import { useTranslation } from 'react-i18next';
const { Input } = require("components/input");
const { Button } = require("components/button");

const Con = styled.div`
	display: flex;
	width: 400px;
	justify-content: space-between;
`;

const Form = styled(RawForm)`
	flex: 0 0 90%;
`;

export default function AddEditWhseGroup(props) {
	const { t } = useTranslation();
	const tBase = "views.warehouse_management.components.addEditWhseGroup";
	const tr = (key) => t(`${tBase}.${key}`);

	//Props variables
	const { formType, groupId } = props;
	const { showSnackbar } = useSnackbarContext();

	//State Variables
	const [defaultWhseDropdown, setDefaultWhseDropdown] = useState();
	const [form, setForm] = useState({
		group_name: "",
		group_id: null,
		default_whse: { label: null, value: null },
	});

	const mutation = useMutation(
		(form) => {
			axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse/updateGroup`,
				form,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		},
		{
			onSuccess: () => showSnackbar(tr("Completed!")),
		}
	);

	/* Database Functions */
	useEffect(() => {
		fetchData();
		if (formType === "edit") {
			fetchGroup();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const wDropdown = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=GETWHSEDROPDOWN`
			);
			setDefaultWhseDropdown(wDropdown.data);
		} catch (e) {
			console.error(e);
		}
	};

	const fetchGroup = async () => {
		try {
			const group = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=GETGROUP&groupId=${groupId}`
			);
			setForm(group.data);
		} catch (e) {
			console.error(e);
		}
	};

	/* Submit Things */
	const handleSubmit = (e) => {
		try {
			let { ...form } = e;

			form.group_id = groupId;

			const formData = new FormData();

			formData.append("form", JSON.stringify(form));

			mutation.mutate(formData);
			window.location.reload();
		} catch (e) {
			console.error(e);
		}
	};

	const schema = yup.object({
		group_name: yup.string().required(tr("Required")),
		default_whse: yup.object({
			label: yup.string().required(tr("Required")),
			value: yup.string().required(tr("Required")),
		}),
	});

	return (
		<Con>
			<Form
				initialValues={form}
				enableReinitialize={true}
				onSubmit={handleSubmit}
				validationSchema={schema}
			>
				<Input
					label={tr("Group Name")}
					required
					name="group_name"
					size="medium"
				/>
				<Select
					label={tr("Default Warehouse")}
					name="default_whse"
					options={defaultWhseDropdown}
				/>
				<Button type="submit" primary>
					{tr("Save")}
				</Button>
			</Form>
		</Con>
	);
}
