import { useEffect, useState } from "react";
import Tab from "../../components/tab";
import axios from "axios";
import Order from "./order";
import Review from "./review";
import Send from "./send";
import { useTranslation } from 'react-i18next';
import "./index.scss";
import Receive from "./receive";
import Complete from "./complete";

const Purchasing = () => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.index";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const [content, setContent] = useState("order");
  const [cartCount, setCartCount] = useState(null);
  const [reviewCount, setReviewCount] = useState(null);
  const [sendCount, setSendCount] = useState(null);
  const [receiveCount, setReceiveCount] = useState(null);
  const [completeCount, setCompleteCount] = useState(null);

  const fetchCarts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/getCarts`
    );

    setCartCount(response.data.carts.length);
  };

  const fetchReview = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReview`
    );

    setReviewCount(response.data.length);
  };

  const fetchSend = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchSend`
    );

    setSendCount(response.data.sendResult.length);
  };

  const fetchReceive = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReceive`
    );

    setReceiveCount(response.data.length);
  };

  const fetchComplete = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchComplete`
    );

    setCompleteCount(response.data.length);
  };

  const fetchCounts = async () => {
    fetchCarts();
    fetchReview();
    fetchSend();
    fetchReceive();
    fetchComplete();
  };

  useEffect(() => {
    fetchCounts();
  }, [content]);

  return (
    <Tab
      defaultTab="order"
      content={
        content === "order" ? (
          <Order fetchCounts={fetchCounts} />
        ) : content === "review" ? (
          <Review fetchCounts={fetchCounts} />
        ) : content === "send" ? (
          <Send fetchCounts={fetchCounts} />
        ) : content === "receive" ? (
          <Receive fetchCounts={fetchCounts} />
        ) : content === "complete" ? (
          <Complete fetchCounts={fetchCounts} />
        ) : null
      }
      onClick={(key) => {
        setContent(key);
      }}
      data={{
        order: {
          tab: tr("Order"),
          count: cartCount,
        },
        review: {
          tab: tr("Review"),
          count: reviewCount,
        },
        send: {
          tab: tr("Send"),
          count: sendCount,
        },
        receive: {
          tab: tr("Receive"),
          count: receiveCount,
        },
        complete: {
          tab: tr("Complete"),
          count: completeCount,
        },
      }}
    />
  );
};

export default Purchasing;
