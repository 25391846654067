import { OutlineButton, SubtleIconButton } from "components/button";
import { useModalContext } from "components/modal";
import * as React from "react";
import { RiAddLine, RiEdit2Fill, RiTimeLine } from "react-icons/ri";
import styled from "styled-components";
import NewReminder from "./newReminder";
import { useQuery } from "react-query";
import axios from "axios";
import { format, isBefore } from "date-fns";
import { Avatar } from "components/avatar";
import Placeholder from "../../component/placeholder";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type User = {
  id: number;
  name: string;
};
type TReminder = {
  id: string;
  label: string;
  reminder_at: string;
  name: string;
  users: [User];
};

export default function Reminders({ id, type }: { id: number; type: string }) {
  const { t } = useTranslation();
  const tBase = "views.request.container.reminders.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  const reminders = useQuery(
    ["reminders", id, type],
    async () =>
      await axios
        .get(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/${type}/${id}
        `
        )
        .then((res) => res.data)
  );

  const populateReminders = reminders.data?.map((reminder: TReminder) => (
    <Reminder
      key={reminder.id}
      {...reminder}
      handleEdit={() =>
        setModal({
          component: (
            <NewReminder id={id} type={type} reminderId={reminder.id} />
          ),
          label: tr("New Reminder"),
        })
      }
    />
  ));

  return (
    <div className="space-y-4">
      <div className="py-4 border-b flex justify-between items-center">
        <h2>{tr("Reminders")}</h2>
        <OutlineButton
          onClick={() =>
            setModal({
              component: <NewReminder id={id} type={type} />,
              label: tr("New Reminder"),
            })
          }
        >
          <RiAddLine /> {tr("Add new Reminder")}
        </OutlineButton>
      </div>
      <div>
        <Content>
          {reminders.data?.length > 0 ? (
            populateReminders
          ) : (
            <Placeholder
              type="reminder"
              header={tr("No reminders")}
              label={tr("Reminders will show up here when added.")}
            />
          )}
        </Content>
      </div>
    </div>
  );
}

const Content = styled.div``;

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const Reminder = (props: TReminder & { handleEdit: VoidFunction }) => {
  const { t, i18n } = useTranslation();
  const tBase = "views.request.container.reminders.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const before = isBefore(new Date(props.reminder_at), new Date());

  const modal = useModalContext();

  return (
    <ReminderCon before={before} className="relative">
      <div>
        <span>{tr("Created by")} {props.name}</span>
        <span>
          {before ? "✔️" : "🕕"}
          {format(new Date(props.reminder_at), "MMM dd, yyyy hh:mm aa", { locale: locale })}
        </span>
      </div>

      <span>{props.label}</span>

      <div>
        {props.users?.map((user) => (
          <Avatar key={user.id} user_id={user.id} size="small" showLabel>
            {user.name}
          </Avatar>
        ))}
      </div>
      <SubtleIconButton
        onClick={props.handleEdit}
        size="small"
        className="absolute top-[1rem] right-0"
      >
        <RiEdit2Fill />
      </SubtleIconButton>
    </ReminderCon>
  );
};

const ReminderCon = styled.div<{ before: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 0;
  > div:first-child {
    line-height: 1;
    font-size: 0.75rem;
    color: grey;
    align-items: center;
    > span:nth-child(2) {
      border: 1px solid #e3e8ee;
      color: ${(props) => (props.before ? "green" : "#1a73ec")};
      border-radius: 25px;
      font-weight: 600;
      font-size: 0.8rem;
      padding: 0.25em 0.5em;
      margin-left: 0.5rem;
      line-height: 1;
      align-self: flex-start;
    }
  }
  > span {
    margin-bottom: 0.5rem;
  }

  > div {
    display: flex;
    > div {
      background: #e3e8ee;
      border-radius: 25px;
      padding: 0.25em;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
`;
