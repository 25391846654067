/* Created by Hannah Green for ES-169 on 6/14/2021 */
import styled from "styled-components";
const { Button } = require('components/button');

export const Con = styled.div`
  max-width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px grey;
  position: relative;
  margin-bottom: 15px;

  &:hover {
    box-shadow: 3px 3px 6px -3px rgb(78, 78, 78);
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    height: 175px;
    width: 1.5%;
  }
`;

export const Icons = styled.div`
    @media only screen and (max-width: 1200px){
      display: flex; 
      flex-direction: row;
    }

    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
    justify-self: end;   
    width: 250px;
    max-width: 250px;

    > div {
      padding: 5px;
    }
`;

export const Description = styled.div`
@media only screen and (orientation: portrait){
  width:100%;
  max-width: 100%;
}

    font-size: 15px;
    width: 250px;
    max-width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;

    > label {
      font-size: 17px;
      padding: 5px;
    }

    > div {
      padding: 5px;
      background-color: #ebedef;
      border: 1px solid #ebedef;
      border-radius: 5px;
    }

`;

export const ReviewButton = styled(Button)`
      width: 100%;
`;

export const SubmitButton = styled(Button)`
    width: 100%;
`;

export const PrintButton = styled(Button)`
    width: 100%;
`;

export const PrintCon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 10px;
`;