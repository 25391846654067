import * as React from "react";
import * as S from "./style";

export default function Welcome() {
  return (
    <S.Con>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
      <div>hi</div>
    </S.Con>
  );
}
