import React from 'react';
import styled from 'styled-components';

const Con = styled.div`
    width:100%
`
const Form = styled.form`

> label {
    display: flex;
    text-align: left;
    flex-direction: column;
    padding-left: 2rem;

    > input[type=text], select, textarea{
        width: 50%;
        padding: 12px;
        margin-right: 10rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
      }

    > div {
        width: 50%;
        padding: 12px;
        margin-right: 10rem;
        box-sizing: border-box;
        resize: vertical;
    }
}
> div {
    > button {
        width: 25%;
        padding: 10px;
        margin-right: 10px;
        margin-top: 15px;
    }
}
`

export default function (props) {
    const { close_popup, message } = props;

    return (
        <Con>
            <Form>
                <label>
                    {message}
                </label>
                <div>
                    <button type="button" onClick={() => { close_popup() }}>Ok</button>
                </div>
            </Form>
        </Con>
    );
}