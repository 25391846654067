import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./index.css";
import Avatar from "@material-ui/core/Avatar";
import { BsChevronLeft } from "react-icons/bs";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from 'react-i18next';

import SpecialDrawer from "../spec-item";

const UserCon = styled.div`
  width: 100%;
  position: relative;
`;
const UserTitle = styled.div`
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  margin: auto;
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 1px 0 5px -3px grey;
  &:hover {
    cursor: pointer;
  }
`;
const UserDes = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  > div:first-child {
    font-size: 1.5rem;
  }
  > div:last-child {
    color: grey;
    display: flex;
    flex-direction: row;
    > div {
      padding-left: 2rem;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 15px;
        width: 1rem;
        height: 1rem;
        background: red;
        border-radius: 50%;
      }
    }
  }
`;
const SpecialOrderCon = styled.div`
  padding: 1rem;

  > h2 {
    color: grey;
    margin-left: 5%;
  }
`;
const TechCon = styled.div`
  width: 100%;
  max-width: 1625px;
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  border-radius: 5px;
  margin-left: 5%;
  color: grey;
  padding: 10px;
  display: flex;

  > label {
    font-weight: bold;
    padding: 5px;
    margin: 10px;
  }

  > label > p {
    font-weight: normal;
  }

  > h3 {
    font-weight: bold;
  }
`;
const SpecialOrderItem = styled.div`
  margin: 2rem;
`;

Modal.setAppElement("#root");

const Row = (props) => {
  const { t } = useTranslation();
  const tBase = "views.dashboard.userpage.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [isComplete, setIsComplete] = useState(props.item.is_done);

  const [anchorEl, setAnchorEl] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [dialogPage, setDialogPage] = useState(0);

  const [newQuantity, setNewQuantity] = useState("");

  const [jobs, setJobs] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editTransfer = async (transfer_item_id, new_quantity) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/edit_transfer_item`,
      data: { transfer_item_id, new_quantity },
    });

    props.fetchData();
    handleDialogClose();
  };

  const removeTransferItem = async (transfer_item_id) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/remove_transfer_item`,
      data: { transfer_item_id },
    });

    props.fetchData();
    handleDialogClose();
  };

  const setComplete = async (status) => {
    if (props.soId) {
      const result = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/specialOrder/${props.soId}/${props.item.item_id}/${status}/${props.e_id}`
      );

      props.setSpecialOrders(result.data.data);
    } else {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/${props.item.id}/${status}/${props.wt_id}`
      );

      props.setItems(res.data.data);
    }

    setIsComplete(status);
  };

  const getUserOrders = async (itemId, transferId) => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/userOrders?itemId=${itemId}&transferId=${transferId}`,
    });

    setJobs(response.data.data);
  };

  useEffect(() => {
    getUserOrders(props.item.item_id, props.wt_id);
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className={isComplete ? "doneRow" : "row"}>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogPage === 0 ? (
          <>
            <DialogTitle id="alert-dialog-title">
              {tr("Update Transfer Quantity")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>{tr("Please enter a new value for the transfer of part")}:</div>
                <div style={{ fontWeight: "bold" }}>
                  {parseInt(props.item.internal_id).toString().padStart(3, "0")}
                  {": "}
                  {props.item.parent_category}
                  {" -> "}
                  {props.item.category_name}
                  {" -> "}
                  {props.item.item_name}
                </div>

                <div>{tr("Current value")}:</div>
                <div style={{ fontWeight: "bold" }}>{props.item.quantity}</div>
              </DialogContentText>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                label={tr("New Value")}
                variant="outlined"
                value={newQuantity}
                onChange={(e) => setNewQuantity(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="default">
                {tr("Cancel")}
              </Button>
              <Button
                onClick={() => editTransfer(props.item.id, newQuantity)}
                color="primary"
                autoFocus
              >
                {tr("Save")}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {tr("Remove item from transfer?")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>{tr("Please confirm that you want to remove part")}:</div>
                <div style={{ fontWeight: "bold" }}>
                  {parseInt(props.item.internal_id).toString().padStart(3, "0")}
                  {": "}
                  {props.item.parent_category}
                  {" -> "}
                  {props.item.category_name}
                  {" -> "}
                  {props.item.item_name}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="default">
                {tr("Cancel")}
              </Button>
              <Button
                onClick={() => removeTransferItem(props.item.id)}
                color="primary"
                autoFocus
              >
                {tr("Remove")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <div className="moreLink">
        <HiDotsVertical
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setDialogPage(0);
              handleClickOpen();
              setAnchorEl(null);
            }}
          >
            {tr("Edit")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDialogPage(1);
              handleClickOpen(props.item.id);
              setAnchorEl(null);
            }}
          >
            {tr("Remove")}
          </MenuItem>
        </Menu>
      </div>
      <div
        className={props.item.special_order_item ? "to-wh colored" : "to-wh"}
      >
        {tr("Part")} #{parseInt(props.item.internal_id).toString().padStart(3, "0")}
      </div>
      <div
        className={
          props.item.special_order_item ? "item-group colored" : "item-group"
        }
      >
        <span className="category">
          {props.item.parent_category} {"->"} {props.item.category_name}
        </span>
        <span className="xfer-item">{props.item.item_name}</span>
      </div>
      <div className="row-spacer" />
      <div className="source-row">
        {jobs &&
          jobs?.map((job) => {
            return (
              <>
                {job.job ? (
                  <div className="source-item">
                    <div className="source-item-row">
                      <div>{tr("Source")}</div>
                      <div>{capitalizeFirstLetter(job.job.toString())}</div>
                    </div>

                    <div className="source-item-row">
                      <div>{tr("Quantity")}</div>
                      <div>{job.quantity}</div>
                    </div>
                  </div>
                ) : null}
              </>
            );
          })}
      </div>
      <div className="row-spacer" />
      <div
        className={
          props.item.special_order_item ? "quantity colored" : "quantity"
        }
      >
        {props.item.quantity}
      </div>
      <div
        className="checkBox"
        onClick={() => {
          setComplete(!isComplete);
        }}
      >
        {isComplete ? (
          <FiCheckCircle
            style={{
              fontSize: "2rem",
              fontWeight: "lighter",
              color: "rgb(0, 172, 0)",
            }}
          />
        ) : (
          <FiCircle
            style={{ fontSize: "2rem", fontWeight: "lighter", color: "gray" }}
          />
        )}
      </div>
    </div>
  );
};

const formatDate = (date) => {
  const oldDate = new Date(date);
  return oldDate.toLocaleDateString();
};

export default function Items() {
  const { t } = useTranslation();
  const tBase = "views.dashboard.userpage.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const { id, employee_id, employee_name, transfer_date } = useParams();

  const [items, setItems] = useState([]);
  const [special, setSpecial] = useState([]);

  const [specialOrders, setSpecialOrders] = useState([]);
  const [listCompleted, setListCompleted] = useState(false);

  const showSpecialOrders = false;
  // specialOrders &&
  // specialOrders.length > 0 &&
  // specialOrders.filter((order) => {
  //   return transfer_date >= order.job_date;
  // });

  const finishTransfer = () => {
    if (specialOrders && specialOrders.length > 0) {
      specialOrders.forEach((order) => {
        const orderItems = order.items;
        axios({
          method: "post",
          url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/specialOrder/complete/${id}/${order.special_order_id}`,
          data: {
            orderItems,
          },
        });
      });
    }

    if (items.length > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/complete/${id}`,
        data: {
          items,
        },
      });
    }

    history.goBack();
  };

  const fetchData = async () => {
    const items = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/${id}/${employee_id}`
    );

    // setSpecialOrders(items.data.specialOrders);
    setItems(items.data.data);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, employee_id]);

  useEffect(() => {
    const allItemsChecked = () => {
      let itemsCompleted = items
        ? items.filter((item) => !item.is_done).length === 0
        : true;

      let specialOrderCompleted = showSpecialOrders
        ? showSpecialOrders.filter((order) => {
            let notAllCompleted =
              order.items.filter((item) => !item.is_done).length !== 0;
            return notAllCompleted;
          }).length === 0
        : true;

      if (itemsCompleted && specialOrderCompleted) {
        setListCompleted(true);
      } else {
        setListCompleted(false);
      }
    };

    allItemsChecked();
  }, [listCompleted, items, showSpecialOrders]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <SpecialDrawer />
      <UserCon>
        <UserTitle onClick={() => goBack()}>
          <BsChevronLeft style={{ fontSize: "2rem", marginRight: "1rem" }} />
          <Avatar style={{ marginRight: "20px" }}></Avatar>
          <UserDes>
            <div>{employee_name}</div>
          </UserDes>
        </UserTitle>
        {items &&
          items.map((item, index) => {
            return (
              <Row
                key={item.id}
                item={item}
                wt_id={id}
                setItems={setItems}
                fetchData={fetchData}
                employee_id={employee_id}
              />
            );
          })}

        {/* {showSpecialOrders && showSpecialOrders.length > 0 && (
        <SpecialOrderCon>
          <h2> Special Orders </h2>
          {showSpecialOrders.map((specialOrder) => {
            return (
              <SpecialOrderItem>
                <TechCon key={specialOrder.special_order_id}>
                  <label>
                    Job Number:
                    <p>{specialOrder.job_number}</p>
                  </label>

                  <label>
                    Job Address:
                    <p>{specialOrder.job_address}</p>
                  </label>

                  <label>
                    Job Date:
                    <p>{formatDate(specialOrder.job_date)}</p>
                  </label>
                </TechCon>
                {specialOrder.items.map((item, index) => {
                  return (
                    <Row
                      key={item.id}
                      item={item}
                      soId={specialOrder.special_order_id}
                      e_id={employee_id}
                      setSpecialOrders={setSpecialOrders}
                    />
                  );
                })}
              </SpecialOrderItem>
            );
          })}
        </SpecialOrderCon>
      )} */}
        <div className="finish-btn-container">
          <button
            disabled={!listCompleted}
            onClick={() => finishTransfer()}
            className="finish-btn"
          >
            {tr("Finish")}
          </button>
        </div>
      </UserCon>
    </>
  );
}
