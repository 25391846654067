import React, { useEffect } from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { Button } from "components/button";
import ClickableTag from "./clickable-tag";
import CheckedBoxButton from "components/checkBoxButton";
import { useTranslation } from 'react-i18next';

type TProps = {
  showModal: boolean;
  toggleModal: () => void;
  onSave: (data: any) => void;
};

const TagFilterModal = ({ showModal, toggleModal, onSave } : TProps) => {
  const [unselectedTags, setUnselectedTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [filterExclusive, setFilterExclusive] = useState<boolean>(false);
  const { t } = useTranslation();
  const tBase = "views.customers.tag-filter-modal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleClose = () => {
    toggleModal();
  }

  const handleSave = () => {
    onSave({selectedTags, filterExclusive});
    toggleModal();
  };

  const getAllCustomerTags = useMutation(async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers`, {
        params: {
          type: "allCustomerTags",
        },
      })
      .then((res) => {
        // prevents duplicate tags being rendered, may need in future
        var uniqueTags = new Set(res.data)
        var combinedTags = [...selectedTags, ...unselectedTags]
        for (const sourceObj of combinedTags) {
          const sourceKey = sourceObj["id"];
          if (!uniqueTags.has(sourceKey)) {
            uniqueTags.add(sourceObj)
          }
        }
        setUnselectedTags([...uniqueTags]);
      });
  });

  React.useEffect(() => {
    getAllCustomerTags.mutate();
  }, []);

  const modalOverlayStyles: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const modalContentStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    minHeight: "400px",
    maxWidth: "400px",
    minWidth: "300px",
    border: "5px solid black",
  };

  const modalTitleStyles: React.CSSProperties = {
    marginTop: "5px",
    marginBottom: "5px",
    textAlign: "center",
  };

  const closeButtonStyles = {
    // position: 'absolute',
    display: "block",
    marginLeft: "auto",
    fontSize: "20px",
    cursor: "pointer",
    color: "#333",
  };

  const tagColumnsStyles: React.CSSProperties = {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  };

  const tagTitleStyle: React.CSSProperties = {
    textAlign: "center",
    position: "sticky",
    top: "0",
    backgroundColor: "white",
  };

  const handleSelectAllTags = () => {
    setSelectedTags([...selectedTags, ...unselectedTags]);
    setUnselectedTags([]);
  }

  const handleClearAllTags = () => {
    setUnselectedTags([...unselectedTags, ...selectedTags]);
    setSelectedTags([]);
  }

  if (showModal) {
    return (
      <div style={modalOverlayStyles}>
        <div style={modalContentStyles}>
          <Button onClick={handleClose} style={closeButtonStyles}>
            &times;
          </Button>
          <h2 style={modalTitleStyles}>{tr("Tags")}</h2>
          <div style={{minHeight: "300px", maxHeight:"300px", overflow:"auto"}}>
            <div style={{display:"flex", flexDirection:"row"}}>
              <div style={tagColumnsStyles}>
                <p style={tagTitleStyle}>{tr("Unselected")}</p>
                {
                  unselectedTags.map((tag: any) => (
                    <ClickableTag key={tag.id} tag={tag} setSelectedTags={setSelectedTags} setUnselectedTags={setUnselectedTags} unselected={true}/>
                  ))
                }
              </div>
              <div style={tagColumnsStyles}>
                <p style={tagTitleStyle}>{tr("Selected")}</p>
                {
                  selectedTags.map((tag: any) => (
                    <ClickableTag key={tag.id} tag={tag} setSelectedTags={setSelectedTags} setUnselectedTags={setUnselectedTags} unselected={false}/>
                  ))
                }
              </div>
            </div>
          </div>


          <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignContent:"middle"}}>
            <Button style={{margin:"10px"}} onClick={handleSelectAllTags}>{tr("Select All")}</Button>
            <Button style={{margin:"10px"}} onClick={handleClearAllTags}>{tr("Clear All")}</Button>
          </div>

          <div style={{display:"flex", justifyContent:"space-around"}}>
            <CheckedBoxButton 
              checkedValue={filterExclusive} 
              setCheckedValue={setFilterExclusive}
            >
            {tr("Exact Match")}
            </CheckedBoxButton>
          </div>
          
          <div style={{border:"1px solid #c9c9c9"}}>
            <Button onClick={handleSave} style={{ float: "right", marginTop:"5px" }} primary>
              {tr("Save")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
};

export default TagFilterModal;
