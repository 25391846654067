import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const EmailVerificationSplash = () => {
	const { t } = useTranslation();
	const tBase = "views.signup.emailVerificationSplash";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const history = useHistory() as any;
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('userId');
	const [count, setCount] = React.useState(10);
	let interval: any;

  React.useEffect(() => {
    async function sendEmailVerify() {
      await axios.post(
          `
            ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/setEmailVerified
          `
					,
					{
						userId
					}
      );
    }
    sendEmailVerify();

		// interval = setInterval(() => {
		// 	setCount(prevcount => prevcount - 1);
		// }, 1000);

		// return () => {
		// 	console.log("cleared")
		// 	clearInterval(interval);
		// }
  }, []);

	// if (count < 0) {
	// 	history.push("/login");
	// }

  return (
		<div
			style={{
				display: "grid",
				gap: "20px",
				width: "30em",
        marginTop: "10em",
				backgroundColor: "lightgrey",
				borderRadius: "10px",
				border: "5px #FF4F00 solid",
				textAlign: "center",
				alignSelf: "center",
				padding: "10px",
			}}
		>
			<h1>{tr("Your email has been verified")}</h1>
			<Button primary onClick={() => history.push("/login")} style={{margin:"auto", width:"50%"}}>{tr("Go to Signup Page")}</Button>
		</div>
  );
}

export default EmailVerificationSplash;