import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { TRequest } from "types/request";
import axios from "axios";
import Axios from "axios";
import { SubtleButton, SubtleIconButton } from "components/button";
import { useMutation } from "react-query";
import { generateThumbnail } from "hooks/thumbnail";
import { MdImage, MdPictureAsPdf, MdMoreVert } from "react-icons/md";
import { useSnackbarContext } from "components/snackbar";
import { SearchInput } from "components/input";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';

export default function PropertyRequestFiles({ propertyId, icon, styles }: { propertyId: string; icon?: any; styles: any }) {
	const { t } = useTranslation();
	const tBase = "views.property.component.propertyRequestFiles";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const requests = useQuery<TRequest[] | null>(
		["requests", propertyId],
		async () =>
			await axios(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/propertyRequests/${propertyId}`).then((res) => {
				return res.data;
			})
	);

	const RequestImages = ({ id, property, type, styles }: { id: string | number; property: string | number; type: string; styles: any }) => {
		const Grid = styles.Grid;
		const requestImages = useQuery(
			["requestFiles", type, id],
			async () =>
				// decide between Axios.get and axios for these calls
				await Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customer-files/${type}/${id}`).then((res) => {
					return res.data;
				}),
			{
				refetchOnWindowFocus: true,
			}
		);

		const populateFilesGrid =
			requestImages.data &&
			requestImages.data.map((file: any) => {
				return (
					<>
						<FileGrid key={file.Key} {...{ file, id, type }} refetch={() => requestImages.refetch()} />
					</>
				);
			});

		return (
			<div className="space-y-4">
				<div>
					{requestImages.data ? (
						<div style={{ marginTop: "10px", marginBottom: "10px" }}>
							<h2 className="py-4 flex justify-between items-center border-t" style={{ borderTopColor: "black", paddingTop: "30px" }}>
								<a href={`../../Requests/${id}`}> {tr("Request")} # {id}</a>
								<p>{property}</p>
							</h2>
							<Grid>{populateFilesGrid}</Grid>
						</div>
					) : null}
				</div>
			</div>
		);
	};

	const FileGrid = ({ file, id, type, refetch }: { file: any; id: string | number; type: string; refetch: () => void }) => {
		const { showSnackbar } = useSnackbarContext();
		const { setModal } = useModalContext();

		const fileType = file.Key.includes("pdf") ? "pdf" : "image";
		const FileGridCon = styles.FileGridCon;
		const Con = styles.Con;

		const maxHeight = styles.maxHeight;
		const maxWidth = styles.maxWidth;

		// TODO: consider passing in getFile from customerFiles.tsx so as to possibly eliminate getPNG and getFile from this file, to remove redundant code

		const getPNG = useMutation(async () => await Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customer-files/${type}/${id}/${encodeURIComponent(file.Key)}`).then((res) => res.data), {
			onSuccess: (data) => {
				// window.open(data, "_blank") || window.location.replace(data);
				setModal({
					component: (
						<>
							<Con>
								<img src={data} style={{ maxWidth: "500px", maxHeight: "500px" }}></img>
							</Con>
						</>
					),
					label: file.Key,
					customerFile: true,
					// icon: icon,
					editName: false,
					// displayInput: showInput,
					// formats the modal header for proper spacing between elements
					displayStyle: "inline-grid",
					displayGridStyle: "[First] 400px [Line2] auto [Line3] 5px [Line4] 40px",
				});
			},
		});

		const getFile = (filename: any) => {
			if (filename.includes("pdf")) {
				Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customer-files/${type}/${id}/${encodeURIComponent(file.Key)}`).then(
					(res) => window.open(res.data, "_blank") || window.location.replace(res.data)
				);
			} else {
				getPNG.mutate();
			}
		};

		return (
			<FileGridCon target="_blank" onClick={() => getFile(file.Key)} style={{ width: "100%", maxHeight: maxHeight, maxWidth: maxWidth, marginTop: "30px" }}>
				<div>
					<img src={file.signedUrl} style={{ width: "100%", objectFit: "cover", height: "100%" }} />
				</div>
				<div>
					{fileType === "pdf" ? <MdPictureAsPdf style={{ color: "red" }} /> : <MdImage style={{ color: "green" }} />}

					<span>{file.Key}</span>
				</div>
			</FileGridCon>
		);
	};

	const [requestSearch, setRequestSearch] = React.useState("");

	const handleInput = (e: any) => {
		setRequestSearch(e.target.value);
	};

	const [requestFiles, setRequestFiles] = React.useState([]);

	React.useEffect(() => {}, [requestFiles]);

	// TODO: if user search filters out all requests, include an "no images found" icon

	return (
		<>
			<div style={{ marginTop: "50px" }}>
				<h2 style={{ padding: "5px", maxWidth: "150px", display: "inline-block" }}>Request Files</h2>
				<SearchInput search placeholder={tr("Search...")} style={{ width: "400px", marginLeft: "auto", display: "inline-block", float: "right" }} value={requestSearch} onChange={handleInput} />
				<ul>
					{requests.data &&
						requests.data?.map(
							(request) =>
								request.id.toString().concat(request.address).includes(requestSearch) && <RequestImages id={request.id} property={request.address} type="customerRequestFiles" styles={styles} />
						)}
				</ul>
			</div>
		</>
	);
}
