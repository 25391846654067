/* Created by Hannah Green on 02/08/2021 for ES-133 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

/* Components */
import AddEditGroupForm from "./components/addEditWhseGroup";
import AddEditWhseForm from "./components/addEditWhseForm";
import WarehouseTable from "./components/warehouseTable";
import WarehouseGroupTable from "./components/warehouseGroupTable";
import { useModalContext } from "components/modal";

/* Icons */
import { BiSearchAlt } from "react-icons/bi";

/* Table Imports */
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

const Spinner = require("react-spinkit");
const { SearchCon, SearchBar, Con, Content, Loading } = require("./styles");

const { useStyles, useToolbarStyles } = require("./styles");

export default function WarehouseManagement() {
	const { t } = useTranslation();
	const tBase = "views.warehouse_management.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const { setModal } = useModalContext();

	const useStickyState = (key = "sticky", initialState = null) => {
		const [state, setState] = useState(() => {
			const storedState = localStorage.getItem(key);
			return storedState ?? initialState;
		});

		useEffect(() => {
			localStorage.setItem(key, state);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [state]);

		const clearState = () => localStorage.removeItem(key);

		return [state, setState, clearState];
	};

	//State Variables
	const classes = useStyles();
	const [selected] = useState([]);
	const [tableView, setTableView] = useStickyState(
		"warehouseView",
		"warehouse"
	);
	const [noResults, setNoResults] = useState();
	const [searchTerm, setSearchTerm] = useState("");
	const [items, setItems] = useState();
	const [groups, setGroups] = useState();
	const [warehouses, setWarehouses] = useState();

	useEffect(() => {
		if (searchTerm === "") {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Fetch warehouses
	const fetchData = async () => {
		try {
			const warehouses = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=ALLWHSES`
			);
			setWarehouses(warehouses.data);

			const groups = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=ALLGROUPS`
			);
			setGroups(groups.data);

			if (tableView === "warehouse") {
				setItems(warehouses.data);
			} else {
				setItems(groups.data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	//Change the table view
	const tableViewButtonClick = async (view) => {
		if (view === "warehouse") {
			setTableView("warehouse");
			setItems(warehouses);
		} else {
			setTableView("warehouseGroups");
			setItems(groups);
		}
	};

	//submit search
	const submitSearch = async () => {
		try {
			if (tableView === "warehouse") {
				const searchResults = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=SEARCHWAREHOUSES&searchTerm=${searchTerm}`
				);
				if (searchResults.data.length > 0) {
					setItems(searchResults.data);
					setSearchTerm("");
				} else {
					setNoResults(true);
				}
			} else {
				const results = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse?type=SEARCHGROUPS&searchTerm=${searchTerm}`
				);
				if (results.data.length) {
					setItems(results.data);
					setSearchTerm("");
				} else {
					setNoResults(true);
				}
			}
			return;
		} catch (e) {
			console.error("Function submitSearch", e);
		}
	};

	//handle search box
	const handleSearch = (event) => {
		const target = event.target;
		const value = target.value;

		setSearchTerm((state) => {
			let tempSearchTerm = { ...state };
			tempSearchTerm = value;
			return tempSearchTerm;
		});
	};

	//submit search on enter keypress
	const handleKeyPress = (event) => {
		if (event.which === 13) {
			submitSearch();
		}
	};

	//Toolbar
	const EnhancedTableToolbar = (props) => {
		const classes = useToolbarStyles();
		const { numSelected } = props;
		return (
			<Toolbar
				className={clsx(classes.root, {
					[classes.highlight]: numSelected > 0,
				})}
			>
				<ButtonGroup disableElevation variant="contained">
					<Button
						onClick={() => {
							tableViewButtonClick("warehouse");
						}}
					>
						{tr("Warehouses")}
					</Button>
					<Button
						onClick={() => {
							tableViewButtonClick("warehouseGroups");
						}}
					>
						{tr("Warehouse Groups")}
					</Button>
				</ButtonGroup>
				<Typography
					className={classes.title}
					variant="h6"
					id="tableTitle"
					component="div"
				></Typography>

				<Tooltip title={tr("Add Item")}>
					<IconButton
						aria-label="add"
						onClick={() => {
							if (tableView === "warehouse") {
								setModal({
									component: (
										<AddEditWhseForm
											formType="add"
											warehouseId={null}
										/>
									),
									label: tr("Add Warehouse"),
								});
							} else {
								setModal({
									component: (
										<AddEditGroupForm
											formType="add"
											groupId={null}
										/>
									),
									label: tr("Add Group"),
								});
							}
						}}
					>
						<AddCircleIcon size={25} />
					</IconButton>
				</Tooltip>
			</Toolbar>
		);
	};

	EnhancedTableToolbar.propTypes = {
		numSelected: PropTypes.number.isRequired,
	};

	try {
		if (items.length !== 0) {
			return (
				<Con>
					<SearchCon>
						<BiSearchAlt size={25} />
						<SearchBar
							className="search-item"
							placeholder={tr("Search by Name")}
							name="search"
							value={searchTerm}
							onChange={handleSearch}
							onKeyPress={handleKeyPress}
						></SearchBar>
					</SearchCon>
					<Content>
						<div className={classes.root}>
							<Paper className={classes.paper}>
								<EnhancedTableToolbar
									numSelected={selected.length}
								/>
								{tableView === "warehouse" && (
									<WarehouseTable
										classes={classes}
										noResults={noResults}
										items={items}
									/>
								)}
								{tableView === "warehouseGroups" && (
									<WarehouseGroupTable
										classes={classes}
										noResults={noResults}
										items={items}
									/>
								)}
							</Paper>
						</div>
					</Content>
				</Con>
			);
		}
	} catch (e) {
		return (
			<Con>
				<SearchCon>
					<BiSearchAlt size={25} />
					<SearchBar
						className="search-item"
						placeholder={tr("Search by Name or Tag")}
					></SearchBar>
				</SearchCon>

				<Content>
					<div className={classes.root}>
						<Loading>
							<div>
								<Spinner name="wandering-cubes" color="grey" />
							</div>
						</Loading>
					</div>
				</Content>
			</Con>
		);
	}
}
