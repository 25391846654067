import React, { useState, useEffect, FC } from "react";
import Switch from "rc-switch";
import axios from "axios";
import { useTranslation } from 'react-i18next';

interface FieldPermissionsProps {
  userId: string;
}

const FieldPermissions: FC<FieldPermissionsProps> = ({ userId }) => {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.permission.components.FieldPermissions";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [permissions, setPermissions] = useState<Record<string, boolean>>({});

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/field-permissions/${userId}`
      );

      setPermissions(response.data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const updateSetting = async (name: string, value: boolean) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/field-permissions/${name}`,
        { 
          userId, 
          value,
        } 
      );
      if (response.status === 200) {
        fetchUserSettings();
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    fetchUserSettings();
  }, [userId]);

  // Define permission names and labels for rendering
  const permissionNames = [
    { name: 'view_properties', label: 'View Properties' },
    { name: 'view_customers', label: 'View Customers' },
    { name: 'view_calendar_by_month', label: 'View Calendar by Month' },
    { name: 'view_calendar_by_other_days', label: 'View Calendar by Other Days' },
  ];

  return (
    <div className="mt-4 space-y-4">
      <h2>{tr("Field Permissions")}:</h2>

      {permissionNames.map(({ name, label }) => (
        <div key={name} className="flex flex-row align-middle">
          <Switch
            className="mx-3"
            checked={permissions[name]}
            onChange={(prev) => updateSetting(name, prev)}
          />
          <p>{tr(label)}</p>
        </div>
      ))}
    </div>
  );
};

export default FieldPermissions;
