import React, { useState, useEffect } from "react";
import "./index.css";
import Card from "./user-card";
import Axios from "axios";

import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TransferHistory from "./transfer-history";
import SpecialDrawer from "./spec-item";
import { useTranslation } from 'react-i18next';

export default function App() {
  const [searchInput, setSearch] = useState("");
  const [transfers, setTransfers] = useState([]);

  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();
  const tBase = "views.dashboard.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer`
      );
      setTransfers(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const transferList = transfers.map((transfer, index) => {
    return (
      <Card
        fetchData={fetchData}
        display={searchInput}
        key={index}
        jobTitle=""
        transfer={transfer}
      />
    );
  });

  return (
    <div>
      <SpecialDrawer />
      <Paper style={{ backgroundColor: "#eee" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={tr("Transfers")} />
          <Tab label={tr("History")}/>
        </Tabs>
      </Paper>
      {/* <div className="search-container">
          <input
            className="search-crew"
            placeholder="Search"
            onChange={(e) => search(e)}
          />
        </div> */}
      {tabValue === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="dashboard-container">
            <div id="dashboard" style={{ zIndex: 0 }}>
              {transferList}
            </div>
          </div>
        </div>
      ) : (
        <TransferHistory />
      )}
    </div>
  );
}
