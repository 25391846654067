import { SubtleButton } from "components/button";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  value: string;
  onClick: (row: any) => void;
  setValue: (e: string) => void;
  data: any | null | undefined;
  rowFormat: (row: any) => React.ReactNode;
};

export default function NameAutocomplete(props: Props) {
  const [index, setIndex] = React.useState(0);
  const [selected, setSelected] = React.useState<number | null>(null);

  const initialSearch = React.useRef<string | null>(null);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLButtonElement[] | null[]>([]);

  const totalLength = props.data ? props.data.length : 0;

  React.useEffect(() => {
    props.data &&
      typeof selected === "number" &&
      props.onClick(props.data[selected]);

    return () => {
      typeof selected !== "number" &&
        initialSearch.current &&
        props.setValue(initialSearch.current);
    };
  }, [selected]);

  React.useEffect(() => {
    initialSearch.current = props.value;
  }, []);

  const handleScroll = (index: number) => {
    const selectedRef = listRef.current[index];
    const containerRef = selectRef.current;
    const isFirst = index === 0;
    const isLast = index === totalLength - 1;
    if (containerRef && selectedRef) {
      if (isFirst) {
        containerRef.scrollTo(0, 0);
      } else if (isLast) {
        containerRef.scrollTo(0, containerRef.scrollHeight);
      } else if (
        selectedRef.offsetTop + selectedRef.clientHeight >
        containerRef.scrollTop + containerRef.clientHeight
      ) {
        containerRef.scrollTo(
          0,
          containerRef.scrollTop + selectedRef.clientHeight
        );
      } else if (selectedRef.offsetTop < containerRef.scrollTop) {
        containerRef.scrollTo(
          0,
          containerRef.scrollTop - selectedRef.clientHeight
        );
      }
    }
  };

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowUp":
        setIndex((i) => {
          let updatedIndex = i - 1 < 0 ? totalLength - 1 : i - 1;
          handleScroll(updatedIndex);
          return updatedIndex;
        });
        e.preventDefault();
        break;
      case "ArrowDown":
        setIndex((i) => {
          let updatedIndex = i + 1 > totalLength - 1 ? 0 : i + 1;
          handleScroll(updatedIndex);
          return updatedIndex;
        });
        e.preventDefault();
        break;
      case "Enter":
        setSelected(index);
        break;
      default:
        setIndex(0);
        return;
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.data, index]);

  const populateprops =
    props.data?.length > 0
      ? props.data.map((row: any, i: any) => (
          <SubtleButton
            key={i}
            ref={(r) => (listRef.current[i] = r)}
            onMouseEnter={() => setIndex(i)}
            onClick={() => setSelected(i)}
          >
            {props.rowFormat(row)}
          </SubtleButton>
        ))
      : "No result";

  return (
    <Con ref={selectRef} {...{ index }}>
      {populateprops}
    </Con>
  );
}

const Con = styled.div<{ index: number }>`
  padding: 0.25rem;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  > button {
    justify-content: flex-start;
    flex: 0 0 auto;
    transition: none;
    &:hover {
      background: transparent;
    }
    &:nth-child(${(props) => props.index + 1}) {
      background: ${theme.bg.blue};
    }

    > span {
      color: ${theme.color.subtext2};
      font-weight: 400;
    }
  }
`;
