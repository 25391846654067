import styled from "styled-components";
import { useQuery } from "react-query";
import axios from "axios";
import Table, { useTable } from "components/table";
import { format } from "date-fns";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import NewProject from "./components/newProject";
import { useHistory } from "react-router-dom";
import { differenceInDays, startOfDay } from "date-fns";
import { Status } from "views/request/component/status";
import { SearchInput } from "components/input";
import Tab from "components/tab";
import qs from "query-string";
import { dollarFormatter } from "utils";
import ProgressBar from "components/progressBar/circular";
import { RiAddFill } from "react-icons/ri";
import useQueryString from "hooks/useQueryString";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Project = {
  id: string;
  label: string;
  entry_date: string;
  due_date: string;
  start_date: string;
  last_modified: string;
  total_amount: string | null;
  actual_amount: string | null;
  billed: number | null;
  status: {
    id: number;
    label: string;
    color: string;
  };
};

type Query = {
  projects: Project[];
  counts: { [key: string]: number };
  total_page: number;
};

const Projects = (props: any) => {
  const { t, i18n } = useTranslation();
  const tBase = "views.projects.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  // const filters = useFilter();
  const { setModal } = useModalContext();
  const history = useHistory(),
    queryString = useQueryString();

  const params: any = qs.parse(props.location.search);
  const table = useTable({
    pageIndex: +params.page,
    url: true,
    sort: {
      column:
        queryString.search.column && !Array.isArray(queryString.search.column)
          ? queryString.search.column
          : null,
      order:
        queryString.search.order && !Array.isArray(queryString.search.order)
          ? queryString.search.order
          : null,
    },
    onSort: (e: any) => {
      queryString.update({ status: queryString.search.status, ...e });
    },
    onPaginate: (page) => {
      queryString.update({ ...queryString.search, page: page });
    },
  });

  const projects = useQuery<Query>(
    ["projects", params, table.sort],
    async () =>
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects`, {
          params: {
            ...params,
            sort: table.sort,
          },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        table.setPageCount(data.total_page);
      },
    }
  );

  const columns = {
    id: {
      label: tr("Project #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: Project) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    due_date: {
      label: tr("Due"),
      style: {
        fontWeight: "600",
      },
      cell: (row: Project) => {
        const diff = differenceInDays(
          startOfDay(new Date(row.due_date + "T00:00")),
          startOfDay(new Date())
        );

        const color = diff < 0 ? "red" : diff === 0 ? "green" : "blue";

        return row.due_date ? (
          <span style={{ color }}>
            {format(new Date(row.due_date + "T00:00"), "MMM dd, yyyy", {
              locale: locale,
            })}
          </span>
        ) : (
          "-"
        );
        // <DueIn date={row.due_date} />
      },
    },
    total_amount: {
      label: "Total",
      style: {
        fontWeight: "bold",
      },
      cell: (row: Project) => {
        return dollarFormatter(row.total_amount);
      },
    },
    label: {
      label: tr("Label"),
      width: "100%",
      style: {
        maxWidth: "400px",
      },
    },
    start_date: {
      label: tr("Projected Start Date"),
      cell: (row: Project) =>
        row.start_date ? format(new Date(row.start_date), "MMM dd, yyyy", { locale: locale }) : "-",
    },
    actual_amount: {
      label: tr("Progress"),
      cell: (row: Project) => {
        let total = row.total_amount ? +row.total_amount : 0;
        let actual = row.actual_amount ? +row.actual_amount : 0;

        let progress = Math.round((actual / total) * 100);

        if (!total || total === 0 || actual === 0 || !actual) return;

        // dollarFormatter(row.actual_amount)
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ProgressBar progress={progress} />
            <span style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
              {} {progress}%
            </span>
          </div>
        );
      },
    },
    billed: {
      label: tr("Billed"),
      cell: (row: Project) => {
        // ${dollarFormatter((row.billed / 100) * row.total_amount)}
        return row.billed && row.total_amount ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ProgressBar progress={row.billed} />
            <span style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
              {`${row.billed}%`}
            </span>
          </div>
        ) : (
          ""
        );
      },
    },
    last_modified: {
      label: tr("Last Modified"),
      cell: (row: Project) =>
        format(new Date(row.last_modified), "MMM dd, yyyy", {
          locale: locale,
        }),
    },
  };

  const to = (row: Project) => `/projects/${row.id}`;

  // TODO: This could be dynamically loaded from project statuses in the db.
  const data = {
    all: {
      tab: tr("All Projects"),
      count:
        Object.entries(projects.data?.counts || {}).reduce(
          (prev, [key, current]) => prev + current,
          0
        ) || 0,
    },
    new: {
      tab: tr("New"),
      count: projects.data?.counts ? projects.data?.counts["New"] : 0,
    },
    submitted: {
      tab: tr("Submitted"),
      count: projects.data?.counts ? projects.data?.counts["Submitted"] : 0,
    },
    in_progress: {
      tab: tr("In Progress"),
      count: projects.data?.counts ? projects.data?.counts["In Progress"] : 0,
    },
    completed: {
      tab: tr("Completed"),
      count: projects.data?.counts ? projects.data?.counts["Completed"] : 0,
    },
    won: {
      tab: tr("Won"),
      count: projects.data?.counts ? projects.data?.counts["Won"] : 0,
    },
    lost: {
      tab: tr("Lost"),
      count: projects.data?.counts ? projects.data?.counts["Lost"] : 0,
    },
  };

  const handleTabChange = (key: string) => {
    history.push({ search: qs.stringify({ ...params, page: 1, status: key }) });
  };

  const handleSearch = (value: string) => {
    let searchParams = params;
    if (!value.trim()) {
      delete searchParams.search;
    } else {
      searchParams = { ...searchParams, page: 1, search: value };
    }
    queryString.update({ ...searchParams, status: "all" });
  };

  return (
    <Con>
      <SHeader>
        <h1>{tr("Projects")}</h1>

        {/* <Filter /> */}
        <Button
          style={{ marginLeft: "auto" }}
          primary
          onClick={() =>
            setModal({ component: <NewProject />, label: tr("New Project") })
          }
        >
          <RiAddFill /> {tr("Create Project")}
        </Button>
      </SHeader>
      <SearchInput
        type="search"
        placeholder={tr("Search Project")}
        onKeyDown={(e: any) =>
          e.key === "Enter" && handleSearch(e.target.value)
        }
        search
        style={{ width: "300px" }}
      />
      <Tab
        data={data}
        defaultTab={(!Array.isArray(params.status) && params.status) || "all"}
        onClick={handleTabChange}
        content={
          <Table
            {...table}
            {...{ columns, to }}
            data={projects.data?.projects}
          />
        }
      />
    </Con>
  );
};

const Con = styled.div`
  padding: 1rem;
`;

const SHeader = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export default Projects;
