const convertColumnHeader = (col: string) => {
  const mapping: { [key: string]: string } = {
    // Default mapping
    id: "ID",
    fk_appointment_id: "Appointment ID",
    fk_user_id: "User ID",
    fk_estimate_id: "Estimate ID",
    fk_invoice_id: "Invoice ID",
    appointment_production: "Appointment Production",
    estimate_production: "Estimate Production",
    invoice_production: "Invoice Production",
    updated_at: "Updated At",
    created_at: "Created At",
  };

  if (mapping[col]) {
    return mapping[col];
  }

  return col.replace(/_/g, " ");
};

export default convertColumnHeader;
