/* Created by Hannah Green for ES-171 on 6/29/2021 */
import React, { useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { cloneDeep } from 'lodash';
import { DeleteCon, ButtonCon, DeleteButton } from "./styles";
import Select from 'react-select';
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';
const { TextArea } = require('components/input');
const { Input } = require('components/input');

export default function DeleteService(props) {
    const { t } = useTranslation();
    const tBase = "views.services.components.table.deleteModal";
    const tr = (key) => t(`${tBase}.${key}`);

    const form = props.service;
    const { setModal } = useModalContext();

    const mutation = useMutation((form) => {
        axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/deleteService`,
            form,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        ).then(() => window.location.reload());
    });

    const deleteService = (values) => {
        const { ...form } = values;
        const formData = new FormData();
        formData.append("form", JSON.stringify(form));
        mutation.mutate(formData);
    };

    return (
        <DeleteCon>
            <div>{tr("Are you sure you want to delete")}</div>
            <div>{form.product_label}?</div>
            <ButtonCon>
                <DeleteButton primary type="submit" onClick={() => deleteService(form)}>{tr("Yes")}</DeleteButton>
                <DeleteButton primary onClick={() => setModal()}>No</DeleteButton>
            </ButtonCon>
        </DeleteCon>
    )
}