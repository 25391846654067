export const increment = (x) => {
    return {
        type: 'INCREMENT',
        page: x,
    };
};

export const cartUpdate = (type, req) => {
    return {
        type: type,
        items: req
    }
}

export const itemModalStatus = (x,y) => {
    return { type: 'CHECKITEMSTATUS', item: x, itemGroup: y }
}

export const team = (employee, image) => {
    return { type: 'INITIALTEAM', employee: employee, image: image}
}