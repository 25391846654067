import axios from "axios";
import clients from "constants/clients";
import domains from "constants/domains";
import quickbooksDesktopEndpoints from "constants/routes/quickbooks/desktop";
import useDebounce from "hooks/useDebounce";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import constructUrl from "utils/constructUrl";
import { useTranslation } from 'react-i18next';

interface ISyncLog {
  id: number;
  account_id: number;
  action: string;
  resource_type: string;
  resource_id: number;
  processed: boolean;
  qb_entity: string;
  query_handler: string;
  query_params: string;
  priority: string;
  created_at: Date;
  updated_at: Date;
  edit_sequence: number;
  ticket: string;
  run_once: boolean;
  qbd_list_id: string;
  qbd_name: string;
}

const fetchSyncLog = async (
  resultCount: string,
  hasListId: string,
  processed: string,
  resourceId: string
) => {
  const client = clients.QUICKBOOKS;
  const domain = domains.DESKTOP;
  const endpoint = quickbooksDesktopEndpoints.SYNC_LOG;

  try {
    const response = await axios.get(
      constructUrl({ client, domain, endpoint }),
      {
        params: {
          resultCount,
          hasListId,
          processed,
          resourceId,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error("Error fetching QuickBooks Desktop sync log.");
  }
};

const QuickBooksDesktop = () => {
  const queryClient = useQueryClient();

  const [resultCount, setResultCount] = useState<string>("100");
  const [hasListId, setHasListId] = useState<string>("no");
  const [processed, setProcessed] = useState<string>("no");
  const [resourceId, setResourceId] = useState<string>("");
  const debouncedResourceId = useDebounce(resourceId, 1000);
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.QuickBooksDesktop.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { isLoading, error, data } = useQuery(
    ["sync-log", resultCount, hasListId, processed, debouncedResourceId],
    () => fetchSyncLog(resultCount, hasListId, processed, debouncedResourceId)
  );

  const markSynced = async (id: number) => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.MARK_SYNCED;

    try {
      await axios.post(constructUrl({ client, domain, endpoint }), {
        id,
      });
      queryClient.invalidateQueries("sync-log");
    } catch (error) {
      throw new Error(`Error marking as synced:, ${error}`);
    }
  };

  const markNotSynced = async (id: number) => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.MARK_NOT_SYNCED;

    try {
      await axios.post(constructUrl({ client, domain, endpoint }), {
        id,
      });
      queryClient.invalidateQueries("sync-log");
    } catch (error) {
      throw new Error(`Error marking as not synced:, ${error}`);
    }
  };

  const clearTicket = async (id: number) => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.CLEAR_TICKET;

    try {
      await axios.post(constructUrl({ client, domain, endpoint }), {
        id,
      });
      queryClient.invalidateQueries("sync-log");
    } catch (error) {
      throw new Error(`Error clearing ticket:, ${error}`);
    }
  };

  const deleteEntry = async (id: number) => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.DELETE_ENTRY;

    try {
      await axios.post(constructUrl({ client, domain, endpoint }), {
        id,
      });
      queryClient.invalidateQueries("sync-log");
    } catch (error) {
      throw new Error(`Error deleting entry:, ${error}`);
    }
  };

  if (isLoading) {
    return <div>{tr("Loading")}...</div>;
  }

  if (error) {
    return <div>{tr("Error fetching sync log")}.</div>;
  }

  return (
    <div>
      <h3 className="mt-3 mb-3">{tr("QuickBooks Desktop Sync Log")}</h3>

      <div className="flex flex-row">
        <div className="mb-3 mr-4">
          <label>
            {tr("Results")}:
            <select
              value={resultCount}
              onChange={(e) => setResultCount(e.target.value)}
            >
              <option value="100">100</option>
              <option value="1000">1000</option>
              <option value="all">{tr("All")}</option>
            </select>
          </label>
        </div>
        <div className="mb-3 mr-4">
          <label>
            {tr("Has List ID")}:
            <select
              value={hasListId}
              onChange={(e) => setHasListId(e.target.value)}
            >
              <option value="no">No</option>
              <option value="yes">{tr("Yes")}</option>
              <option value="all">{tr("All")}</option>
            </select>
          </label>
        </div>
        <div className="mb-3 mr-4">
          <label>
            {tr("Processed")}:
            <select
              value={processed}
              onChange={(e) => setProcessed(e.target.value)}
            >
              <option value="no">No</option>
              <option value="yes">{tr("Yes")}</option>
              <option value="all">{tr("All")}</option>
            </select>
          </label>
        </div>
        <div className="mb-3 mr-4">
          <label>
            {tr("Resource ID")}:
            <input
              type="text"
              value={resourceId}
              onChange={(e) => setResourceId(e.target.value)}
            />
          </label>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="text-xs font-bold bg-gray-200">
                  <tr>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      ID
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Action")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Resource Type")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Resource ID")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Processed")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Ticket")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("QBD List ID")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("QBD Name")}
                    </th>
                    <th className="pt-3 pb-3 text-gray-900 whitespace-nowrap">
                      {tr("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-xs bg-white divide-y divide-gray-200">
                  {data?.data?.syncLogResult?.map((syncLog: ISyncLog) => (
                    <tr key={syncLog.id}>
                      <td className="px-2 py-1">{syncLog.id}</td>
                      <td className="px-2 py-1">{syncLog.action}</td>
                      <td className="px-2 py-1">{syncLog.resource_type}</td>
                      <td className="px-2 py-1">{syncLog.resource_id}</td>
                      <td className="px-2 py-1">
                        {syncLog.processed.toString()}
                      </td>
                      <td className="px-2 py-1">{syncLog.ticket}</td>
                      <td className="px-2 py-1">{syncLog.qbd_list_id}</td>
                      <td className="px-2 py-1">{syncLog.qbd_name}</td>
                      <td className="px-2 py-1">
                        <div className="flex flex-col w-32 space-y-2">
                          <button
                            className="text-xs text-indigo-600 hover:text-indigo-900"
                            onClick={() => markSynced(syncLog.id)}
                          >
                            {tr("Mark as Synced")}
                          </button>
                          <button
                            className="text-xs text-indigo-600 hover:text-indigo-900"
                            onClick={() => markNotSynced(syncLog.id)}
                          >
                            {tr("Mark as Unsynced")}
                          </button>
                          <button
                            onClick={() => clearTicket(syncLog.id)}
                            className="ml-4 text-xs text-green-600 hover:text-green-900"
                          >
                            {tr("Clear Ticket")}
                          </button>
                          <button
                            onClick={() => deleteEntry(syncLog.id)}
                            className="ml-4 text-xs text-red-600 hover:text-red-900"
                          >
                            {tr("Delete Entry")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickBooksDesktop;
