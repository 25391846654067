import { IconButton } from "components/button";
import { isEqual } from "lodash";
import * as React from "react";
import { RiCloseFill } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "styles/theme";
// import { StyledInput } from "./style";

type Props = {
  multi?: boolean;
  values?: (string | null)[] | null;
  onChange: (e: (string | null)[]) => void;
  error?: string | boolean;
};

export default function MultiInput({ multi, onChange, ...props }: Props) {
  const [values, setValues] = React.useState<(string | null)[]>([]);
  const [value, setValue] = React.useState("");
  // const [refs, setRefs] = React.useState();
  let refs = React.useRef<(HTMLButtonElement | null)[]>().current;
  let inputRef = React.useRef<HTMLInputElement | null>();
  const [focusing, setFocusing] = React.useState<number | null>(null);
  let prevRef = React.useRef<(string | null)[] | null>(null);

  React.useEffect(() => {
    if (
      props.values &&
      (!prevRef.current || !isEqual(props.values, prevRef.current))
    ) {
      prevRef.current = props.values;
      setValues(props.values);
    }
  }, [props.values]);

  React.useEffect(() => {
    if (!prevRef.current || !isEqual(values, prevRef.current)) {
      prevRef.current = values;
      onChange(values);
    }
  }, [values]);

  const populateValues = values?.map((value, i) => {
    const doDelete = () => {
      setValues((values) => {
        let temp = [...values];
        temp.splice(i, 1);

        let index = i - 1;
        if (index < 0) {
          index = i;
        }

        if (index >= temp.length) {
          inputRef?.current && inputRef?.current.focus();
          setFocusing(null);
        } else {
          refs![index]!.focus();
          setFocusing(index);
        }

        // onChange(temp);

        return temp;
      });
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (
      e
    ) => {
      let index;
      switch (e.key) {
        case "Tab":
          e.preventDefault();
          inputRef.current!.focus();
          setFocusing(null);
          break;
        case "ArrowLeft":
          index = i - 1 < 0 ? 0 : i - 1;
          refs![index]!.focus();
          setFocusing(index);
          break;
        case "ArrowRight":
          index = i + 1;
          if (index >= refs!.length) {
            inputRef.current!.focus();
            setFocusing(null);
          } else {
            refs![index]!.focus();
            setFocusing(index);
          }
          break;
        default:
          break;
      }
    };

    return (
      <ValueCon
        key={i}
        style={{
          padding: "0.1rem 0.25rem 0.1rem 0.5rem",
          lineHeight: 1,
          borderRadius: "25px",
          display: "flex",
          alignItems: "center",
          marginRight: "0.5rem",
        }}
      >
        {value}
        <IconButton
          size="xxsmall"
          ref={(ref) => {
            if (refs) {
              refs = [...refs, ref];
            } else {
              refs = [ref];
            }
          }}
          onClick={() => doDelete()}
          style={{
            outline: "none",
            color: focusing === i ? "white" : "black",
            // padding: "0",
            // width: "20px",
            // height: "20px",
            // borderRadius: "50px",
            // margin: "0 0.25em",
            marginLeft: "0.25em",
            // border: "none",
            background: focusing === i ? "#ff4f00" : "transparent",
          }}
          onKeyDownCapture={handleKeyDown}
        >
          <RiCloseFill />
        </IconButton>
      </ValueCon>
    );
  });

  const handleAddValue = () => {
    if (value.trim() !== "") { // Only add non-empty values
      setValues((values) => [...values, value.trim()]);
      setValue(""); // Clear the input field after adding the value
      setFocusing(null); // Reset focusing state
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    const cursorPosition = target.selectionStart;
    switch (e.key) {
      case "Tab":
        e.preventDefault();
        setValues((values) => {
          const temp = [...values, value];
          return temp;
        });

        setValue("");
        setFocusing(null);
        break;
      case "ArrowLeft":
        if (cursorPosition === 0) {
          refs![refs!.length - 1]!.focus();
          setFocusing(refs!.length - 1);
        }
        break;
      case "Enter":
        e.preventDefault(); // Prevent form submission or any default action
        handleAddValue();
        break;
      default:
        break;
    }
  };
  return (
    <Con onClick={() => inputRef.current?.focus()} error={props.error}>
      {populateValues}
      <SInput
        ref={(ref) => (inputRef.current = ref)}
        value={value}
        onKeyDownCapture={handleKeyDown}
        onFocus={(e) => {
          setFocusing(null);
          e.target.select();
        }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleAddValue}
      />
    </Con>
  );
}

const Con = styled.div<{ error?: string | boolean }>`
  border: 1px solid ${(props) => (props.error ? "red" : "grey")};
  display: flex;
  padding: 0.25rem;
  flex-wrap: wrap;
`;

const SInput = styled.input`
  outline: none;
  border: none;
  flex: 1;
`;

const ValueCon = styled.span`
  margin-bottom: 0.25em;
  font-size: ${theme.fontSize["xsmall"]};
  background: ${theme.bg.blue};
`;
