
/**
 * @summary Converts an integer of milliseconds to a string of hours and minutes
 * 
 * @param {number} a time in milliseconds
 * 
 * @returns a string in the format of "h, m"
 * 
 * @example
 * tohrs(3600000) // returns "1h, 0m"
 * tohrs(3600001) // returns "1h, 0.0m"
 * 
 */
export const tohrs = (a) => {
    let hours = Math.trunc(a / 3600000);
    let mins = Math.round(Math.trunc((a - hours * 3600000) / 600) / 100);
    return hours + "h, " + mins + "m";
};
