import { FC } from "react";
import { useLocationContext } from "../hooks/useLocationContext";
import { haversineDistance } from "../utils/calculateDistance";
import { ILocation } from "../types/ILocation";
import { getColorFromPercentage } from "../utils/getColorFromPercentage";
import { useTranslation } from 'react-i18next';

interface Props {
  tileLocation: ILocation;
}

const DistanceIndicator: FC<Props> = ({ tileLocation }) => {
  const { t } = useTranslation();
  const tBase = "views.calendar.components.DistanceIndicator";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { location, maxDistance } = useLocationContext();

  if (!location || !maxDistance) return null;

  const distance = location ? haversineDistance(location, tileLocation) : null;

  const percentageOfMaximumDistance = distance ? distance / maxDistance : 0;

  const color = getColorFromPercentage(percentageOfMaximumDistance);

  if (distance === null || distance === undefined) return null;

  return (
    <div
      style={{ backgroundColor: color }}
      className={`w-7 h-4 mr-[1px] mb-[1px] rounded-full absolute bottom-0 right-0 z-10 shadow-md border border-gray-400`}
      title={
        distance !== null
          ? `${tr("Distance from selected")}: ${(distance / 1609.344).toFixed(3)} miles`
          : tr("No location selected")
      }
    >
      <div
        style={{
          fontSize: 10,
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          marginTop: -1,
          color: "white",
          textShadow: "1px 1px 2px black",
        }}
        className="text-center"
      >{`${(distance / 1609.344).toFixed(1)}`}</div>
    </div>
  );
};

export { DistanceIndicator };
