import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  title?: React.ReactNode;
  buttons?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
};
export default function Container(props: Props) {
  const label =
    typeof props.title === "string" ? <h2>{props.title}</h2> : props.title;
  return (
    <SCon>
      <STitle>
        <SLabel>{label}</SLabel>
        <SButtonCon>{props.buttons}</SButtonCon>
      </STitle>
      <SContent>{props.content}</SContent>
      <SFooter>{props.footer}</SFooter>
    </SCon>
  );
}

const SCon = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.border.light};
  border-radius: 5px;
  overflow: hidden;
`;

const STitle = styled.div`
  display: flex;
  background: ${theme.bg.lightblue};
  border-bottom: 1px solid ${theme.border.light};
  justify-content: space-between;
  align-items: center;
  padding: 1em;
`;

const SLabel = styled.div`
  display: flex;
`;

const SButtonCon = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 0.5em;
  }
`;

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

const SFooter = styled.div`
  display: flex;
`;
