import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

import "./index.scss";
import { format } from "date-fns";

const RequestActivity = ({ requestId }) => {
  const { t, i18n } = useTranslation();
  const tBase = "views.request.container.activity.index";
  const tr = (key) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const [log, setLog] = useState(null);

  const getActivity = async (requestId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/activity/${requestId}`
    );

    setLog(response.data);
  };

  useEffect(() => {
    getActivity(requestId);
  }, [requestId]);

  const translateLog = (log) => {
    if (!log) return;
    /**
     * If log includes: 
     *  - Appointment {id} created
     *  - Appointment updated by user with id ${editorId} via request menu (batch)
     *  - Appointment {id} status changed from {status} to {status}
     *  - Appointment date changed from ${previousDate} to ${date} and assignee removed by user with id ${editorId}
     *  - Appointment assignee changed from {previousAssignees} to {assignee} by user with id ${id} using the calendar sidebar
    */
    let desc = log;
    let newDesc = desc;
    if (desc.includes("Request created")) {
      newDesc = tr("Request created");
    } else if (desc.includes("Appointment") && desc.includes("created")) {
      // Appointment {id} created
      let i = desc.split(" ");
      let id = i[1];
      newDesc = `${tr(i[0])} ${id} ${tr(i[2])}`;
    } else if (desc.includes("Appointment updated by user with id")) {
      // Appointment updated by user with id ${editorId} via request menu (batch)
      let i = desc.split(" ");
      newDesc = tr("Appointment updated by user with id") + " " + i[6] + " " + tr("via request menu (batch)");
    } else if (desc.includes("status changed from")) {
      let i = desc.split(" ");
      let status1 = "";
      let status2 = "";
      
      // Determine status1
      if (["On the Way", "In Progress", "Partially Paid", "Partially Complete"].includes(i[5] + " " + i[6] + " " + i[7])) {
        status1 = i[5] + " " + i[6] + " " + i[7];
      } else if (["In Progress", "Partially Paid", "Partially Complete"].includes(i[5] + " " + i[6])) {
        status1 = i[5] + " " + i[6];
      } else {
        status1 = i[5];
      }
      
      // Find the index of the word "to"
      let toIndex = i.indexOf("to");
      
      // Determine status2 starting from the word after "to"
      if (["On the Way", "In Progress", "Partially Paid", "Partially Complete"].includes(i[toIndex + 1] + " " + i[toIndex + 2] + " " + i[toIndex + 3])) {
        status2 = i[toIndex + 1] + " " + i[toIndex + 2] + " " + i[toIndex + 3];
      } else if (["In Progress", "Partially Paid", "Partially Complete"].includes(i[toIndex + 1] + " " + i[toIndex + 2])) {
        status2 = i[toIndex + 1] + " " + i[toIndex + 2];
      } else {
        status2 = i[toIndex + 1];
      }

      newDesc = `${tr("Appointment")} ${i[1]} ${tr("status changed from")} ${tr(status1)} ${tr("to")} ${tr(status2)}`;
    } else if (desc.includes("Appointment date changed from")) {
      // - Appointment date changed from ${previousDate} to ${date} and assignee removed by user with id ${editorId}
      let i = desc.split(" ");
      let previousDate = i[4];
      let date = i[6];
      let editorId = i[i.length - 1];
      newDesc = `${tr("Appointment date changed from")} ${previousDate} ${tr("to")} ${date} ${tr("and assignee removed by user with id")} ${editorId}`;
    } else if (desc.includes("Appointment assignee changed from")) {
      // - Appointment assignee changed from {previousAssignees} to {assignee} by user with id ${id} using the calendar sidebar
      let i = desc.split(" ");
      let previousAssignees = i[4];
      let assignee = i[6];
      let editorId = i[i.length - 1];
      newDesc = `${tr("Appointment assignee changed from")} ${previousAssignees} ${tr("to")} ${assignee} ${tr("by user with id")} ${editorId} ${tr("using the calendar sidebar")}`;
    }

    return newDesc;

  };

  return (
    <div className="space-y-4">
      <div className="border-b py-4">
        <h2>{tr("Activity")}</h2>
      </div>
      <div>
        {log &&
          log?.map((entry) => {
            return (
              <React.Fragment key={entry.id}>
                <div className="entry-row">
                  <div className="activity-left-group">
                    <div className="date">
                      {format(new Date(entry.timestamp), "MMM dd, yyyy", {
                        locale: locale
                      })}
                    </div>
                    <div className="time">
                      {format(new Date(entry.timestamp), "hh:mm aa")}
                    </div>
                  </div>
                  <div className="activity-right-group">
                    <div className="description-text">{translateLog(entry.description)}</div>
                  </div>
                </div>
                {entry.user && <div className="user-text">by {entry.user}</div>}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default RequestActivity;
