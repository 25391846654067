import React, { useState, useEffect } from "react";
import BreadCrumbs from "components/breadcrumbs";
import "./styles.css";
import { history } from "routes";
import { useLocation, Redirect } from "react-router-dom";
import currentPlanTextImg from "images/current-plan-text.png";
import axios from "axios";
import Spinner from "components/spinner";
import {
  planStyle,
  planCardStyle,
  planTitleStyle,
  planPriceWrapperStyle,
  planPriceStyle,
  planPeriodStyle,
  planFeatureListStyle,
  planFeatureTextWrapperStyle,
  planFeatureTextStyle,
  planUnderscoreStyle,
  planProceedButtonStyle,
} from "./styles";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

type BasePlanProps = {
  name: string;
  price: number;
  duration: string;
};
// this file contains a lot of unused styling and functionality since it was branched off of the upgrade.tsx file
const PlanSelection = () => {
  const { t } = useTranslation();
  const tBase = "views.subscription.planSelection";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { isSubscriptionActive } = useAuthContext();

  const [basePlanInfo, setBasePlanInfo] = React.useState<BasePlanProps>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const getPlanData = async () => {
    // console.log("...................")
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription`, {
        params: {
          type: "subscription",
        },
      })
      .then((res) => {
        setBasePlanInfo(res.data.basePlan);
        // console.log(res.data);
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getPlanData();
  }, []);

  if (isLoading) return <Spinner />;

  var EssentialChosen,
    EnhancedChosen,
    ExclusiveChosen = false;
  var chosenPlanWeight = 0;
  var ChosenPlan: any;

  const gotoPlan = (planName: string, weight: number) => {
    if (planName === (ChosenPlan as any) || weight < chosenPlanWeight) return;
    const info = {
      planInfo: null,
      basePlanInfo: basePlanInfo,
      initialSignup: true,
    };
    switch (planName) {
      case "Essential":
        history.push("./plan/essential", info);
        break;
      case "Enhanced":
        history.push("./plan/enhanced", info);
        break;
      case "Exclusive":
        history.push("./plan/exclusive", info);
        break;
    }
  };

  const essentialFeatureArr = [
    "1 - 10 Users",
    "100GB Image Storage",
    "Question Builder",
    "Contact Management",
    "Estimating with Customer Interaction",
    "Field App",
    "Invoice/Payments",
    "Quickbook Sync",
    "Historical Data Reporting",
    "Inventory Management",
  ];

  const enhancedFeatureArr = [
    "EServ Essential Plus",
    "1 - 20 Users",
    "300GB Image Storage",
    "Auto Text / Email",
    "Project Management",
    "Equipment Management",
  ];

  const proFeatureArr = [
    "EServ Enhanced Plus",
    "1 - 20 Users",
    "300GB Image Storage",
    "Team Management (Dream Team)",
    "Access to Additional Features",
  ];

  return (
    <div style={{ height: "75em" }}>
      <div style={planStyle}>
        <div>
          <div style={{ height: "45px", padding: "10px" }}>
            {EssentialChosen && (
              <img
                src={currentPlanTextImg}
                style={{ margin: "auto", height: "30px" }}
              ></img>
            )}
          </div>
          <div
            style={{
              ...planCardStyle,
              border: EssentialChosen
                ? "5px solid #ff4f00"
                : "1px solid #edeff2",
            }}
          >
            <div style={planTitleStyle}>Essential</div>
            <div style={planPriceWrapperStyle}>
              <div style={planPriceStyle}>$399</div>
              <div style={planPeriodStyle}>/{tr("Month")}</div>
            </div>
            <div style={planFeatureListStyle}>
              {essentialFeatureArr.map((feature, index) => {
                return (
                  <div style={planFeatureTextWrapperStyle} key={index}>
                    <li style={planFeatureTextStyle}>{tr(feature)}</li>
                  </div>
                );
              })}
            </div>
            <div style={planUnderscoreStyle}></div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                padding: "inherit",
                width: "100%",
              }}
            >
              <button
                className={EssentialChosen ? `none` : `planBoxProceedButton`}
                style={{ ...planProceedButtonStyle, backgroundColor: "grey" }}
                onClick={() => gotoPlan("Essential", 0)}
                disabled={EssentialChosen}
              >
                {tr("Proceed")}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div style={{ height: "45px", padding: "10px" }}>
            {EnhancedChosen && (
              <img
                src={currentPlanTextImg}
                style={{ margin: "auto", height: "30px" }}
              ></img>
            )}
          </div>
          <div
            style={{
              ...planCardStyle,
              border: EnhancedChosen
                ? "5px solid #ff4f00"
                : "1px solid #edeff2",
            }}
          >
            <div style={{ ...planTitleStyle }}>Enhanced</div>
            <div style={planPriceWrapperStyle}>
              <div style={{ ...planPriceStyle }}>$699</div>
              <div style={{ ...planPeriodStyle }}>/{tr("Month")}</div>
            </div>
            <div style={{ ...planFeatureListStyle }}>
              {enhancedFeatureArr.map((feature, index) => {
                return (
                  <div style={planFeatureTextWrapperStyle} key={index}>
                    <li style={{ ...planFeatureTextStyle }}>{tr(feature)}</li>
                  </div>
                );
              })}
            </div>
            <div style={{ ...planUnderscoreStyle }}></div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                padding: "inherit",
                width: "100%",
              }}
            >
              <button
                className={EnhancedChosen ? `none` : `planBoxProceedButton`}
                style={{ ...planProceedButtonStyle, backgroundColor: "grey" }}
                onClick={() => gotoPlan("Enhanced", 1)}
              >
                {tr("Proceed")}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div style={{ height: "45px", padding: "10px" }}>
            {ExclusiveChosen && (
              <img
                src={currentPlanTextImg}
                style={{ margin: "auto", height: "30px" }}
              ></img>
            )}
          </div>
          <div
            style={{
              ...planCardStyle,
              border: ExclusiveChosen
                ? "5px solid #ff4f00"
                : "1px solid #edeff2",
            }}
          >
            <div style={planTitleStyle}>PRO</div>
            <div style={planPriceWrapperStyle}>
              <div style={planPriceStyle}>$699+</div>
              <div style={planPeriodStyle}>/{tr("Month")}</div>
            </div>
            <div style={planFeatureListStyle}>
              {proFeatureArr.map((feature, index) => {
                return (
                  <div style={planFeatureTextWrapperStyle} key={index}>
                    <li style={planFeatureTextStyle}>{tr(feature)}</li>
                  </div>
                );
              })}
            </div>
            <div style={planUnderscoreStyle}></div>
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                padding: "inherit",
                width: "100%",
              }}
            >
              <button
                className="planBoxProceedButton"
                style={planProceedButtonStyle}
                onClick={() => gotoPlan("Exclusive", 2)}
              >
                {tr("Proceed")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanSelection;
