import * as React from "react";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  component: React.ReactElement;
};

export const FileUpload = (props: Props) => {
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const { component, ...rest } = props;

  const handleClick = () => {
    fileUploadRef.current!.click();
  };

  return (
    <>
      {React.cloneElement(component, { onClick: handleClick })}
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: "none" }}
        {...rest}
      />
    </>
  );
};
