import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface IPreviewProps {
  data: Array<Record<string, string | number | boolean>>
};

function Preview({ data }: IPreviewProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="w-[600px]">
      <div className="border divide-y">
        {Object.entries(data[currentIndex]).map(([label, value], i) => (
          <div key={i} className="flex justify-between items-center border-gray-200 p-2">
            <div className="text-sm text-gray-600">{label}</div>
            <div className="text-sm text-gray-800">{String(value)}</div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4 mt-3 mb-1">
        <button onClick={handlePrevious} className="border border-white hover:border-inherit hover:shadow-md active:shadow-none p-1 rounded-full">
          <FaArrowLeft className={`text-2xl ${currentIndex === 0 ? "text-gray-400" : "text-primary"}`} />
        </button>
        <div>
          {currentIndex + 1}/{data.length}
        </div>
        <button onClick={handleNext} className="border border-white hover:border-inherit hover:shadow-md active:shadow-none p-1 rounded-full">
          <FaArrowRight className={`text-2xl ${currentIndex === (data.length - 1) ? "text-gray-400" : "text-primary"}`} />
        </button>
      </div>
    </div>
  )
}

export default Preview;