import { SubtleButton } from "components/button";
import { useModalContext } from "components/modal";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TTargetType } from "types/target-type";
import { TTargetTax } from "types/tax";
import { dollarFormatter } from "utils";
import TaxModal from "views/requests/components/taxModal";
import Tax, { TaxCon } from "./tax";
import { useTranslation } from 'react-i18next';

type Props = {
  view: "read" | "edit";
  data?: TTargetTax[];
  total: { list: number; sale: number; install: number };
  id: string;
  type: Exclude<TTargetType, "appointment">;
  priceType: "list" | "install" | "sale";
};

export default function Taxes(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.lineItems.taxes";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { setModal } = useModalContext();

  const populateTaxes = props.data?.map((tax) => (
    <Tax
      priceType={props.priceType}
      total={props.total}
      key={tax.id}
      data={tax}
    />
  ));

  const array: ("list" | "install" | "sale")[] = ["list", "sale", "install"];

  const restOfPriceType = array
    .filter((f) => f !== props.priceType)
    .reduce(
      (prev, f, i, all) =>
        prev +
        `${tr(f.toLocaleUpperCase())}: ${dollarFormatter(props.total[f])} ${
          i !== all.length - 1 ? " | " : ""
        }`,
      ""
    );

  return (
    <Con>
      <TaxCon>
        <div>
          <h5>{tr("Subtotal")}</h5>
          <h6 style={{ color: theme.color.subtext3 }}>{restOfPriceType}</h6>
        </div>
        <div>{dollarFormatter(props.total[props.priceType])}</div>
      </TaxCon>
      {populateTaxes}
      {props.view === "edit" && (
        <SubtleButton
          primary
          onClick={() =>
            setModal({
              component: (
                <TaxModal id={props.id} data={props.data} type={props.type} />
              ),
              label: tr("Tax"),
            })
          }
        >
          {tr("Add Tax")}
        </SubtleButton>
      )}
    </Con>
  );
}

const Con = styled.div`
  align-self: flex-end;
  width: 100%;
  max-width: 500px;
`;
