import StatusTag from "components/tag/indexv2";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TEstimate } from "types/estimate";
import { dollarFormatter } from "utils";
import { format } from "date-fns";
import Divider from "components/divider";
import { NoStyleA, SubtleButton } from "components/button";
import { RiCheckboxCircleFill, RiCheckFill, RiCheckLine } from "react-icons/ri";
import Placeholder from "views/request/component/placeholder";
import useSeeMore from "hooks/useSeeMore";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  data: TEstimate[] | null;
  type: "requests" | "projects";
  id: number | string;
};

export default function Estimates(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.estimatesListV2.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const seeMore = useSeeMore(false);

  if (!props.data) return null;

  const populateEstimates =
    props.data.length > 0 ? (
      props.data.slice(0, !seeMore.show ? 3 : undefined).map((estimate) => {
        const populateOptions = estimate.options?.map((option) => {
          return (
            <div
              key={option.id}
              className={`
              border relative p-1 rounded flex flex-col ${
                option.selected && "!bg-green-600 border-green-700 !text-white"
              }`}
            >
              <span className="font-bold text-xs flex">
                <span>{option.label}</span>

                {option.selected && (
                  <RiCheckboxCircleFill
                    className="text-white-700 text-sm"
                    style={{
                      zIndex: 1,
                      // transform: "translate(50%, -50%)",
                    }}
                  />
                )}
              </span>
              <span className="font-medium text-sm leading-none">
                {dollarFormatter(option.actual_total)}
              </span>
            </div>
          );
        });

        const estimateWon = estimate.status.id === 5;
        return (
          <SEstimate
            key={estimate.id}
            to={`/estimates/${estimate.id}`}
            className={`${estimateWon && "!bg-green-100/50 !border-green-600"}`}
          >
            {estimateWon && (
              <div className="absolute top-0 right-0 text-green-600 text-2xl p-2">
                <RiCheckboxCircleFill />
              </div>
            )}
            <div>
              <SId>
                #{estimate.id} ·{" "}
                {format(new Date(estimate.entry_date), "MMM dd, yyyy", {
                  locale: locale,
                })}
              </SId>
              <SLabel className="flex items-center space-x-2">
                <span>{estimate.description}</span>{" "}
                <StatusTag size="xxsmall" color={estimate.status.color}>
                  {tr(estimate.status.label)}
                </StatusTag>
              </SLabel>
              <Divider />
              <SFooter></SFooter>
              <SOptions>{populateOptions}</SOptions>
            </div>
          </SEstimate>
        );
      })
    ) : (
      <Placeholder
        type="estimate"
        header={tr("No estimates yet.")}
        label={tr("Estimates will appear here when added.")}
      />
    );
  return (
    <SCon>
      {populateEstimates}
      {props.data.length > 3 && (
        <SubtleButton primary onClick={seeMore.toggle}>
          {seeMore.show ? tr("Show Less") : tr("Show More")}
        </SubtleButton>
      )}
    </SCon>
  );
}

const SEstimate = styled(NoStyleA)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid ${theme.border.light};
  margin-bottom: 1em;
  position: relative;
  > div {
    display: flex;
    flex-direction: column;
  }
  &:hover {
    border: 1px solid ${theme.border.dark};
  }
`;

const SCon = styled.div``;

const SId = styled.span`
  text-transform: uppercase;
  font-size: ${theme.fontSize.small};
  color: ${theme.color.subtext2};
`;

const SLabel = styled.span`
  font-size: 1.2rem;
`;

const SFooter = styled.span`
  color: ${theme.color.subtext3};
`;

const SOption = styled.div<{ selected: boolean }>``;

const SOptions = styled.div`
  display: flex;
  > div:not(:last-child) {
    margin-right: 0.5em;
  }
`;
