import Switch from "rc-switch";
import { INewInvoicePayConfig } from "../index";
import { useTranslation } from 'react-i18next';

type Props = {
  is_payable: boolean;
  allow_card: boolean;
  allow_ach: boolean;
  updatePayOptions: (newConfig: INewInvoicePayConfig) => void;
};

const PayableOptions = ({
  is_payable,
  allow_card,
  allow_ach,
  updatePayOptions,
}: Props) => {
  const { t } = useTranslation();
  const tBase = "views.invoice.payable-options.payable-options";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const combinePayOptions = (type: string, e: boolean) => {
    switch (type) {
      case "payable":
        return {
          is_payable: e,
          allow_card,
          allow_ach,
        };
      case "card":
        return {
          is_payable,
          allow_card: e,
          allow_ach,
        };
      case "ach":
        return {
          is_payable,
          allow_card,
          allow_ach: e,
        };

      default:
        return {
          is_payable,
          allow_card,
          allow_ach,
        };
    }
  };

  return (
    <div>
      <div className="flex flex-row">
        <Switch
          checked={is_payable}
          onChange={(e) => updatePayOptions(combinePayOptions("payable", e))}
        />
        <div className="text-slate-400 font-medium ml-2 mb-2">
          {tr("Customer Can Pay Online")}
        </div>
      </div>
      <div className="flex flex-row">
        <Switch
          disabled={!is_payable}
          checked={allow_card}
          onChange={(e) => updatePayOptions(combinePayOptions("card", e))}
        />
        <div className="text-slate-400 font-medium ml-2 mb-2">{tr("Allow Card")}</div>
      </div>
      <div className="flex flex-row">
        <Switch
          disabled={!is_payable}
          checked={allow_ach}
          onChange={(e) => updatePayOptions(combinePayOptions("ach", e))}
        />
        <div className="text-slate-400 font-medium ml-2 mb-2">{tr("Allow ACH")}</div>
      </div>
    </div>
  );
};

export default PayableOptions;
