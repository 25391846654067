import Segment from "components/segment";
import * as React from "react";
import LineItemsContainer from "containers/lineItems";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FlatContainer from "components/container/flat";
import BreadCrumbs from "components/breadcrumbs";
import { Input } from "components/input";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "api/axios";
import Spinner from "components/spinner";
import { Status } from "views/request/component/status";
import { Quote } from ".";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import SendQuoteForReview from "./components/sendQuoteForReview";
import SubmitQuoteModal from "./components/submitQuoteModal";
import { useSnackbarContext } from "components/snackbar";
import Dropdown from "components/dropdown";
import { RiArrowDownSLine } from "react-icons/ri";
import FinalPriceReview from "./components/reviewFinalPrice";
import ApplyTemplateModal from "./applyTemplate";
import Field from "components/field";
import DuplicateQuote from "./duplicateQuote";
import { useTranslation } from 'react-i18next';

export default function QuoteV2() {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.indexv2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const params = useParams<{
      id: string;
      cat: "quote" | "option";
      catId: string;
    }>(),
    location = useLocation<{ focusOnLabel?: boolean }>(),
    history = useHistory(),
    { setModal } = useModalContext(),
    { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient();

  const [editable, setEditable] = React.useState(false);

  const quoteLabelRef = React.useRef<HTMLInputElement | null>(null);

  const focusOnLabel = location.state?.focusOnLabel;

  const quoteQuery = useQuery<Quote>(
    ["quote", params.catId],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${params.catId}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setEditable(
          (res && res.quote.status.id === 19) || editable ? true : false
        );
      },
    }
  );

  const deleteQuote = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${params.catId}/delete
  `),
    {
      onSuccess: () => {
        history.replace(`/projects/${params.id}`);
        showSnackbar(tr("Quote Deleted!"));
      },
    }
  );

  const updateQuote = useMutation(
    async (e: { type: string; value: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${params.catId}/update
  `,
          e
        )
        .then((res) => res.data)
  );

  const handleChange = (e: { type: string; value: string }) => {
    queryClient.setQueryData(["quote", params.catId], (old: any) => {
      return {
        ...old,
        quote: {
          ...old.quote,
          label: e.value,
        },
      };
    });

    updateQuote.mutate(e);
  };

  if (quoteQuery.isLoading) return <Spinner />;

  if (!quoteQuery.data) return null;

  const deletable =
    quoteQuery.data && ![21, 22, 23].includes(quoteQuery.data.quote.status.id);

  const buttons = [
    quoteQuery.data.quote.status.id === 19 && (
      <Button
        // isLoading={sendQuote.isLoading}
        onClick={() =>
          setModal({
            component: <SendQuoteForReview id={params.catId} />,
            label: tr("Review"),
          })
        }
        primary
      >
        {tr("Review")}
      </Button>
    ),
    quoteQuery.data.quote.status.id === 20 &&
      (editable ? (
        <Button primary onClick={() => setEditable(false)}>
          {tr("Save Changes")}
        </Button>
      ) : (
        <Button onClick={() => setEditable(true)}>{("Modify")}</Button>
      )),
    quoteQuery.data.quote.status.id === 30 && (
      <Button
        primary
        onClick={() =>
          setModal({
            component: <SubmitQuoteModal projectId={params.id} />,
            label: tr("Submit Quote"),
            props: {
              preventScroll: true,
            },
          })
        }
      >
        {tr("Submit Quote")}
      </Button>
    ),
    <Dropdown
      reference={
        <Button>
          {tr("Action")} <RiArrowDownSLine />
        </Button>
      }
      data={[
        {
          button: [
            {
              label: tr("Apply Template"),
              onClick: () =>
                setModal({
                  component: (
                    <ApplyTemplateModal id={params.catId} type={params.cat} />
                  ),
                  label: tr("Apply Template"),
                }),
            },
            { label: tr("Print") },
            {
              label: tr("Duplicate"),
              onClick: () =>
                setModal({
                  component: <DuplicateQuote id={params.catId} />,
                  label: tr("Duplicate Quote"),
                }),
              // duplicateQuote.mutate()
            },
            deletable && {
              label: tr("Delete"),
              onClick: () => deleteQuote.mutate(),
            },
            quoteQuery.data.quote.status.id === 21 && { label: tr("Mark as Lost") },
          ],
        },
      ]}
    />,
  ];

  return (
    <div>
      <FlatContainer
        buttons={buttons}
        title={
          <div>
            <BreadCrumbs
              link={[
                {
                  label: `${tr("Project")} #${params.id}`,
                  to: `/projects/${params.id}`,
                },
              ]}
            />
            {editable ? (
              <Field
                type="input"
                style={{ width: "500px" }}
                $size="large"
                ref={quoteLabelRef}
                autoFocus={focusOnLabel}
                value={quoteQuery.data.quote.label}
                // onBlur={handleBlur}
                onChange={(e: any) => {
                  handleChange({
                    type: "label",
                    value: e.target.value,
                  });
                }}
              />
            ) : (
              <>
                <h2>
                  {quoteQuery.data.quote.label}
                  <Status color={quoteQuery.data.quote.status.color}>
                    {quoteQuery.data.quote.status.label}
                  </Status>
                </h2>
              </>
            )}
          </div>
        }
      >
        <LineItemsContainer
          id={params.catId}
          type={"quote"}
          parentType={"project"}
          parentId={params.id}
        />
      </FlatContainer>
      {quoteQuery.data.quote.status.id !== 19 && (
        <FinalPriceReview
          {...{
            clients: quoteQuery.data.clients,
            data: quoteQuery.data.quote,
            id: params.catId,
            editable: quoteQuery.data.quote.status.id === 20,
            total_amount: quoteQuery.data.quote.total_amount,
          }}
        />
      )}
    </div>
  );
}
