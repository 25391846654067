import axios from "api/axios";
import { NoStyleA, NoStyleButton, OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { string } from "yup";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  type: "quote" | "option";
};
export default function ApplyTemplateModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.applyTemplate";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext(),
    { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient();
  const templates = useQuery<{ id: string; label: string }[]>(
    ["template"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template
    `
        )
        .then((res) => res.data)
  );

  const applyTemplate = useMutation(
    async (id: string) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template/apply/${props.id}/${props.type}
  `,
        {
          id,
        }
      ),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Template applied!"));
        queryClient.invalidateQueries();
      },
    }
  );
  if (!templates.data) return null;

  const populateTemplates = templates.data.map((template) => {
    return (
      <OutlineButton
        key={template.id}
        size="xlarge"
        // style={{
        //   fontSize: "1rem",
        //   border: `1px solid ${theme.border.light}`,
        //   padding: "1rem",
        //   margin: "0.5em 0",
        //   borderRadius: "5px",
        //   color: theme.color.subtext2,
        // }}
        isLoading={applyTemplate.isLoading}
        style={{
          margin: "0.5em 0",
        }}
        onClick={() => applyTemplate.mutate(template.id)}
      >
        {template.label}
      </OutlineButton>
    );
  });

  return <SCon>{populateTemplates}</SCon>;
}

const SButton = styled(NoStyleButton)`
  hover {
    color: green;
  }
`;

const SCon = styled.div`
  display: flex;
  flex-direction: column;
`;
