import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { getIntialState, persistState, removeState } from "./utils";

const CartStateContext = createContext();
const CartDispatchContext = createContext();

const defaultState = { count: 0, items: [], bulkForm: false, specialForm: false };

const STORAGE_KEY = 'cart';

const initialState = getIntialState(STORAGE_KEY) ?? defaultState;

const cartReducer = (state, action) => {
    switch (action.type) {

       case 'COUNTUP': {
            let index = state.items.findIndex(item => item.item_id === action.items.item_id);
            let count = parseFloat(state.count);

            if (index !== -1) {
                state.items[index].qty = action.qty;
            }

            return {
                count: count,
                items: state.items,
                bulkForm: state.bulkForm,
                specialForm: state.specialForm
            }
        }
        case 'COUNTDOWN': {
            let index = state.items.findIndex(obj => obj.item_id === action.items.item_id);
            let count = parseFloat(state.count);

            if (index !== -1) {
                state.items[index].qty = action.qty;
            };

            return {
                count: count,
                items: state.items,
                bulkForm: state.bulkForm,
                specialForm: state.specialForm
            }
        }

        case 'ADD': {
            let count = parseFloat(state.count);

            let index = state.items.findIndex(item => item.item_id === action.items.item_id);

            if (index !== -1) {
                state.items[index].qty = parseFloat(state.items[index].qty) + action.qty;
            };

            let bulk = action.items.bulk_order_item;
            let special = action.items.special_order_item;

            if (bulk) {
                state.bulkForm = true;
            }

            if (special) {
                state.specialForm = true;
            }

            return {
                count: index === -1 ? count + 1 : count,
                items: index === -1 ? state.items.concat(action.items)
                    : state.items,
                bulkForm: state.bulkForm,
                specialForm: state.specialForm
            }
        }

        case 'REMOVEITEM': {
            let index = state.items.findIndex(obj => obj.item_id === action.items.item_id);
            let count = parseFloat(state.count);

            if (index !== -1) {
                state.items.splice(index, 1);
            };

            state.count = count - 1;

            const bulk = state.items.some((item) => {
                return item.bulk_order_item === true;
            });

            const special = state.items.some((item) => {
                return item.special_order_item === true;
            });

            return {
                count: state.count,
                items: state.items,
                bulkForm: bulk,
                specialForm: special
            }
        }

        case 'CHECKOUT': {
            removeState(STORAGE_KEY);
            return {
                count: 0,
                items: [],
                bulkForm: false,
                specialForm: false
            }
        }
        default: {
            return { count: state.count, items: state.items, bulkForm: state.bulkForm, specialForm: state.specialForm }
        }
    }
}

function CartCountProvider({ children }) {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        persistState(STORAGE_KEY, state);
    }, [state]);

    return (
        <CartStateContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>
                {children}
            </CartDispatchContext.Provider>
        </CartStateContext.Provider>
    )
}

function useCartCountState() {
    const context = useContext(CartStateContext);

    if (context === undefined) {
        console.error("useCartCountState must be used within a cart count context provider");
    }

    return context;
}

function useCartCountDispatch() {
    const context = useContext(CartDispatchContext);

    if (context === undefined) {
        console.error("useCartCountDispatch must be used within a cart count context provider");
    }

    return context;
}

function useDispatchAndState() {
    return [useCartCountDispatch, useCartCountState];
}

export { CartCountProvider, useCartCountState, useCartCountDispatch, useDispatchAndState };