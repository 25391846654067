import React from "react";
import User from "./user";
import { Droppable } from "react-beautiful-dnd";
import { MdFormatListNumbered } from "react-icons/md";
import Axios from "axios";

export const listFunction = (
	id,
	child,
	direction,
	categoryIndex,
	employeeData,
	images,
	editable
) => {
	return (
		<Droppable
			type="droppableSubItem"
			droppableId={id.toString()}
			isDropDisabled={id === "-2"}
			isCombineEnabled
			direction={direction}
		>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.droppableProps}>
					{child
						? child.map((f, i) => {
								if (f)
									return (
										<User
											data={[
												f,
												id.toString(),
												categoryIndex,
											]}
											key={f["employee_id"]}
											index={i}
											isNotChild
											employeeData={employeeData}
											editable={editable}
											images={images[f["employee_id"]]}
										>
											{f["children"]
												? f["children"].map((g, k) => {
														return (
															<User
																data={[
																	g,
																	id.toString(),
																	categoryIndex,
																]}
																key={
																	g[
																		"employee_id"
																	]
																}
																index={k}
																parentId={
																	f[
																		"employee_id"
																	]
																}
																parentIndex={i}
																images={
																	images[
																		g[
																			"employee_id"
																		]
																	]
																}
																employeeData={
																	employeeData
																}
																editable={
																	editable
																}
															/>
														);
												  })
												: null}
										</User>
									);
						  })
						: null}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};

export const reorderChild = (req, employee) => {
	let {
		// type,
		source: { droppableId: rawSource },
		destination: { droppableId: rawDest, index: destIndex },
		draggableId: employeeId,
	} = req;
	let [sourceParent, sourceCat] = rawSource.split("-");
	let [destParent, destCat] = rawDest.split("-");
	let removed, destIndexes;

	let newEmployee = [...employee];

	newEmployee.forEach(({ category_id: catId, child }, i1) => {
		if (catId === sourceCat && child) {
			child.forEach(({ employee_id, children }, i2) => {
				if (employee_id === +sourceParent && children) {
					children.forEach(({ employee_id }, i3) => {
						if (employee_id === +employeeId) {
							[removed] = newEmployee[i1]["child"][i2][
								"children"
							].splice(i3, 1);
						}
					});
				}
			});
		}

		if (catId === destCat && child) {
			child.forEach(({ employee_id }, i2) => {
				if (employee_id === +destParent) {
					destIndexes = [i1, i2];
				}
			});
		}
	});

	if (removed && destIndexes) {
		newEmployee[destIndexes[0]]["child"][destIndexes[1]]["children"]
			? newEmployee[destIndexes[0]]["child"][destIndexes[1]][
					"children"
			  ].splice(destIndex, 0, removed)
			: (newEmployee[destIndexes[0]]["child"][destIndexes[1]][
					"children"
			  ] = [removed]);
	}

	return {
		type: "ADDTEAM",
		employee: newEmployee,
	};
};

export const reorder = (e, employee) => {
	let {
		// type,
		source: { droppableId: sourceId },
		destination: { droppableId: destId, index: destIndex },
		draggableId: employeeId,
	} = e;
	let removed, destCat;
	let newEmployee = [...employee];
	newEmployee.forEach(({ category_id: catId, child }, i1) => {
		if (catId === sourceId && child) {
			child.forEach(({ employee_id }, i2) => {
				if (+employeeId === employee_id) {
					[removed] = newEmployee[i1]["child"].splice(i2, 1);
				}
			});
		} else if (catId === sourceId && !child) {
		}
		if (catId === destId) {
			destCat = i1;
		}
	});

	if (removed) {
		newEmployee[destCat]["child"]
			? newEmployee[destCat]["child"].splice(destIndex, 0, removed)
			: (newEmployee[destCat]["child"] = [removed]);
	}

	return {
		type: "ADDTEAM",
		employee: newEmployee,
	};
};

export const fetching = async (
	fetchType,
	employeeId,
	sourceCat,
	destCat,
	sourceParent,
	destParent,
	sourceIndex,
	destIndex
) => {
	try {
		const res = await Axios.post(
			`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
			{
				type: fetchType,
				employeeId: employeeId,
				sourceCat: sourceCat,
				destCat: destCat,
				sourceParent: sourceParent || null,
				destParent: destParent || null,
				sourceIndex: sourceIndex,
				destIndex: destIndex,
			}
		);
		if (res.status === 500) {
			alert(res.statusText);
			window.location.reload();
		}
	} catch (e) {
		alert(e);
		throw e;
	}
};

export const fetchingCat = async (fetchType, newindexlist) => {
	try {
		const res = await Axios.post(
			`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
			{
				type: fetchType,
				newindexlist,
			}
		);
		if (res.status === 500) {
			alert(res.statusText);
			window.location.reload();
		}
	} catch (e) {
		alert(e);
		throw e;
	}
};

export const merge = (req, employee, res) => {
	let {
		combine: { draggableId: destId, droppableId: destCategory },
		source: { droppableId: sourceCategory },
		draggableId: employeeId,
	} = req;

	let newEmployee = [...employee];

	let removed, destIndex, breakable;

	// ------------------------- new code -----------------------------

	// ----------------------old code -------------------------------

	const spliceEmployeeArray = (newEmployee, sourceCategory, employeeId) => {
		const firstSourceIndex = newEmployee.findIndex(
			({ category_id, child }) => {
				return category_id === sourceCategory && child;
			}
		);

		if (firstSourceIndex === -1) {
			return [false];
		}

		const secondSourceIndex = newEmployee[firstSourceIndex][
			"child"
		].findIndex(({ employee_id, children }) => {
			return (
				employee_id === +employeeId &&
				(!children || (children && children.length === 0))
			);
		});
		if (secondSourceIndex === -1) {
			return [false];
		} //hello

		return newEmployee[firstSourceIndex]["child"].splice(
			secondSourceIndex,
			1
		);
	};

	[removed] = spliceEmployeeArray(newEmployee, sourceCategory, employeeId);

	if (!removed) {
		return res(false);
	}

	const firstDestinationIndex = newEmployee.findIndex(
		({ category_id, child }) => {
			return category_id === destCategory && child;
		}
	);

	if (firstDestinationIndex === -1) {
		return res(false);
	}

	const secondDestinationIndex = newEmployee[firstDestinationIndex][
		"child"
	].findIndex(({ employee_id }) => {
		return employee_id === +destId;
	});
	if (secondDestinationIndex === -1) {
		return res(false);
	}

	newEmployee[firstDestinationIndex]["child"][secondDestinationIndex][
		"children"
	]
		? newEmployee[firstDestinationIndex]["child"][secondDestinationIndex][
				"children"
		  ].push(removed)
		: (newEmployee[firstDestinationIndex]["child"][secondDestinationIndex][
				"children"
		  ] = [removed]);

	return res({
		type: "ADDTEAM",
		employee: newEmployee,
	});
};

export const remove = (categoryId, parentId, employeeId, employee) => {
	let newEmployee = [...employee];
	let removed;
	let finalIndex;

	// ------------------------- new code -------------------------------------

	newEmployee.some(({ category_id, child }, categoryIndex) => {
		if (category_id !== categoryId || !child) {
			return false;
		}
		return (
			child.some(({ employee_id, children }, parentIndex) => {
				if (employee_id !== parentId || !children) {
					return false;
				}
				return (
					children.some(({ employee_id }, childrenIndex) => {
						if (employee_id === employeeId) {
							[removed] = newEmployee[categoryIndex]["child"][
								parentIndex
							]["children"].splice(childrenIndex, 1);
							return true;
						}
						return false;
					}) === true
				);
			}) === true
		);
	});

	// ------------------------- old code -------------------------------------

	// newEmployee.forEach( ({category_id, child}, i1) => {
	//     if (category_id === categoryId && child) {
	//         child.forEach( ({employee_id, children}, i2) => {
	//             if (employee_id === parentId && children) {
	//                 children.forEach( ({employee_id}, i3) => {
	//                     if (employee_id === employeeId) {
	//                         [removed] = newEmployee[i1]['child'][i2]['children'].splice(i3, 1);
	//                     }
	//                 })
	//             }
	//         })
	//     }
	// })

	if (removed) {
		newEmployee[0]["child"]
			? newEmployee[0]["child"].push(removed)
			: (newEmployee[0]["child"] = [removed]);
	}

	return {
		type: "ADDTEAM",
		employee: newEmployee,
	};
};

export const reorderCategory = (sourceId, destId, employee) => {
	const sourceindex = sourceId + 1;
	const destindex = destId + 1;
	const newEmployee = [...employee];
	// console.log(newEmployee, sourceindex, destindex);
	const arraywithoutx = newEmployee.filter((a, i) => {
		if (i === sourceindex) {
			return false;
		}
		return true;
	});
	const returnval = arraywithoutx
		.slice(0, destindex)
		.concat(newEmployee[sourceindex], arraywithoutx.slice(destindex));
	// console.log(returnval);
	return {
		type: "ADDTEAM",
		employee: returnval,
	};
};

export const applySick = (category, employeeId, employee) => {
	const newEmployee = [...employee];
	let removed;

	newEmployee.forEach(({ child }, i1) => {
		if (child) {
			child.forEach(({ employee_id, children }, i2) => {
				if (employee_id === employeeId) {
					[removed] = newEmployee[i1]["child"].splice(i2, 1);
					return;
				}
				children &&
					children.forEach(({ employee_id, i3 }) => {
						if (employee_id === employeeId) {
							[removed] = newEmployee[i1]["child"][i2][
								"children"
							].splice(i3, 1);
							return;
						}
					});
			});
		}
	});

	newEmployee.forEach(({ category_label }, i) => {
		if (category_label === category && removed) {
			newEmployee[i]["child"]
				? newEmployee[i]["child"].push(removed)
				: (newEmployee[i]["child"] = [removed]);
		}
	});

	return {
		type: "ADDTEAM",
		employee: newEmployee,
	};
};
