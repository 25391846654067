/* Created by Hannah Green on 5/19/2021 for ES-147 */

/* This is a class because the react-to-print library does not work with 
functions very well and whatever is being printed HAS to be a class */

import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export default class ReportTable extends React.Component {
    render() {
        const tableHeaders = this.props.headers;
        const rows = this.props.data;

        return (
            <div>
                <Typography style={{
                    padding: '10px',
                    textAlign: 'center'
                }} variant="h6" id="tableTitle" component="div">
                    {this.props.title}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header) => {
                                    return (
                                        <TableCell>
                                            {header}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row, index) => {
                                const keys = Object.keys(row);
                                return (
                                    <TableRow key={index}>
                                        {keys.map((key) => {
                                            return (
                                                <TableCell>
                                                    {row[key]}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}