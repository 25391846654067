import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  form: any; //TODO: type this
  required?: boolean;
};

const InputText = ({ form, name, required }: Props) => {
  const { t } = useTranslation();
  const tBase = "components._ui";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { register, formState } = form;
  const { errors } = formState;

  return (
    <div className="flex flex-col mb-4">
      <label htmlFor="label" className="ml-[2px] text-slate-600 text-sm">
        {name}
      </label>
      <input
        type="text"
        className="p-2 border-2 rounded-md border-slate-200"
        {...register("label", { required })}
      />
      <div className="h-2">
        {errors.label && (
          <p className="text-sm text-red-400">* {tr("Label is required")}</p>
        )}
      </div>
    </div>
  );
};

export { InputText };
