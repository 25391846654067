/* Made by Hannah Green on 02/23/2021 for ES-137 */
import React, { useState } from "react";
import RawForm from "components/form";
import styled from "styled-components";
import { useCartCountDispatch } from "components/cart";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';
const { Button } = require('components/button');
const { Input } = require('components/input');

const Con = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    justify-content: space-between;
    > label {
        font-weight: bold;
        font-size: 0.8rem;
    }
    > div {
        border-radius: 5px;
        background: #f0f0f0;
        padding: 0.5rem;
    }
`;

const Form = styled(RawForm)`
    flex: 0 0 50%;
`;
const ItemImage = styled.img`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  background-color: rgb(214, 214, 214);
  grid-column: 1 / span 2;
  position: relative;
  > *:last-child {
    color: grey;
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export default function ItemDetails(props) {
    const { t } = useTranslation();
    const tBase = "views.order.components.item-details";
    const tr = (key) => t(`${tBase}.${key}`);
    
    const { item, image } = props;
    const { setModal } = useModalContext();
    const cartDispatch = useCartCountDispatch();

    return (
        <Con>
            <ItemImage src={image} alt="product" style={{ width: "25%", maxWidth: "25%" }} />
            <label>{tr("Item Name")}</label>
            <div>{item.item_name}</div>

            <label>{tr("Short Description")}</label>
            <div>{item.short_desc}</div>

            <label>{tr("Long Description")}</label>
            <div>{item.long_desc}</div>

            <label>SKU</label>
            <div>{item.sku}</div>

            <label>{tr("Category")}</label>
            <div>{item.category_name}</div>

            {item.bulk_item_desc && <label>{tr("Bulk Item Description")}</label>}
            {item.bulk_item_desc && <div>{item.bulk_item_desc}</div>}

            <Form initialValues={{ 'qty': 1, 'item': item }}
                onSubmit={values => {
                    values.item.qty = values.qty;
                    cartDispatch({ type: 'ADD', items: values.item, qty: values.qty });
                    setModal();
                }}>
                <Input label="Qty" type="number" name="qty" />
                <Button type="submit" primary>{tr("Add To Cart")}</Button>
            </Form>
        </Con>
    );

}