import * as React from "react";
import {
  RiFileAddLine,
  RiSendPlane2Line,
  RiToolsLine,
  RiEditLine,
} from "react-icons/ri";
import styled from "styled-components";
import { dateFormatter } from "../../estimate/info";
import type { Status } from "../../estimate";
import { TLog } from "types/log";
import { useTranslation } from 'react-i18next';

type Props = {
  entry_date: string;
  send_date: string | null;
  respond_date: string | null;
  converted_date: string | null;
  declined_note: string | null;
  status: Status;
  logs?: TLog[];
};
export default function Timeline(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.request.container.timeline";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const populateLogs = props.logs?.map((log) => {
    return (
      <EachTimeline key={log.id}>
        <RiToolsLine />
        <div>
          <span>{log.description}</span>
          <span>{dateFormatter(log.timestamp)}</span>
        </div>
      </EachTimeline>
    );
  });
  return (
    <div className="divide-y">
      <div className="py-4">
        <h2>{tr("Timeline")}</h2>
      </div>
      <div className="py-4">
        {populateLogs}
        {props.converted_date && (
          <EachTimeline>
            <RiToolsLine />
            <div>
              <span>{tr("Estimate was converted to job.")}</span>
              <span>{dateFormatter(props.converted_date)}</span>
            </div>
          </EachTimeline>
        )}

        {props.respond_date && (
          <EachTimeline>
            <RiEditLine />
            <div>
              <span>{tr("Customer responded to estimate.")}</span>
              <span>{props.declined_note}</span>
              <span>{dateFormatter(props.respond_date)}</span>
            </div>
          </EachTimeline>
        )}

        {props.send_date && (
          <EachTimeline>
            <RiSendPlane2Line />
            <div>
              <span>{tr("Estimate was sent to customer.")}</span>
              <span>{dateFormatter(props.send_date)}</span>
            </div>
          </EachTimeline>
        )}

        <EachTimeline>
          <RiFileAddLine />
          <div>
            <span>{tr("Estimate was created.")}</span>
            <span>{dateFormatter(props.entry_date)}</span>
          </div>
        </EachTimeline>
      </div>
    </div>
  );
}

const EachTimeline = styled.div`
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  > svg {
    font-size: 1.2rem;
    color: #5d6d7e;
  }
  > div {
    display: flex;
    flex-direction: column;
    > span:first-child {
      font-size: 0.9rem;
      color: black;
    }
    > span {
      color: grey;
      font-size: 0.8rem;
    }
  }
`;
