// Format Time for HAM (Hours and Minutes)
// Takes in minutes, spits out hours and minutes

export const formatTimeHAM = (totalMinutes: number) => {
  if (totalMinutes < 60) {
    return `${totalMinutes} min`;
  } else {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }
};