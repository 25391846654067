import styled from "styled-components";

import { Button } from "components/button";
import { IEquipment } from "features/equipment/types/equipment";
import { EQUIPMENT } from "features/equipment/constants";
import { CREATE } from "../../../../../constants";
import EquipmentItem from "./equipment-item/equipment-item.component";
import { useTranslation } from 'react-i18next';

export const EquipmentListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 0 10px 10px 0;
  padding: 5px;
  justify-content: flex-start;
`;

const Header = styled.h3`
  margin-left: 10px;
`;

const EquipmentListBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EquipmentList = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-bottom: 10px;
  margin: 5px;
  margin-bottom: 10px;
`;

interface IProps {
  equipment: Array<IEquipment>;
  handleOpen: (itemType: string, modalType: string) => void;
}

export const EquipmentContainer = ({ handleOpen, equipment }: IProps) => {
  const { t } = useTranslation();
  const tBase = "features.equipment";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <EquipmentListContainer>
      <Header>
        <div className="flex flex-row items-center justify-between mt-4 mb-4 mr-8">
          <h2 className="ml-2">Equipment</h2>
          <Button
            size="small"
            primary
            onClick={() => handleOpen(EQUIPMENT, CREATE)}
          >
            {tr("New")}
          </Button>
        </div>
      </Header>
      <EquipmentListBody>
        <EquipmentList>
          {equipment
            ?.sort((a, b) => {
              if (a.vehicle_label === null) return 1;
              if (b.vehicle_label === null) return -1;

              if (a.vehicle_label.toLowerCase() < b.vehicle_label.toLowerCase())
                return -1;
              if (a.vehicle_label.toLowerCase() > b.vehicle_label.toLowerCase())
                return 1;
              return 0;
            })
            ?.map(({ vehicle_id, vehicle_label }: IEquipment) => (
              <EquipmentItem
                key={vehicle_id}
                vehicle_id={vehicle_id}
                vehicle_label={vehicle_label || ""}
                handleOpen={handleOpen}
              />
            ))}
        </EquipmentList>
      </EquipmentListBody>
    </EquipmentListContainer>
  );
};
