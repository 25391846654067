export const basemaps = [
  {
    id: 1,
    uri: "mapbox://styles/mapbox/streets-v11",
    label: "Streets",
  },
  {
    id: 2,
    uri: "mapbox://styles/mapbox/satellite-streets-v11",
    label: "Satellite - Streets",
  },
  {
    id: 3,
    uri: "mapbox://styles/mapbox/outdoors-v11",
    label: "Outdoors",
  },
  { id: 4, uri: "mapbox://styles/mapbox/light-v10", label: "Light" },
  { id: 5, uri: "mapbox://styles/mapbox/dark-v10", label: "Dark" },
  {
    id: 6,
    uri: "mapbox://styles/mapbox/navigation-day-v1",
    label: "Navigation - Day",
  },
  {
    id: 7,
    uri: "mapbox://styles/mapbox/navigation-night-v1",
    label: "Navigation - Night",
  },
];
