/* Created by Hannah Green on 03/19/2021 for ES-141 */
import React, { createContext, useContext, useReducer } from "react";

const CheckoutStateContext = createContext();
const CheckoutDispatchContext = createContext();

const initialState = {
  employee_id: null,
  truck: null,
  van: null,
  default_truck: null,
  isEdit: null,
  van_checkout_id: null,
  truck_checkout_id: null,
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case "SETTRUCK":
      return {
        ...state,
        truck: action.truck,
      };
    case "SETVAN":
      return {
        ...state,
        van: action.van,
      };
    case "SETALL":
      return {
        employee_id: action.employee_id,
        truck: action.truck,
        van: action.van,
        default_truck: action.default_truck,
        isEdit: action.isEdit,
        van_checkout_id: action.van_checkout_id,
        truck_checkout_id: action.truck_checkout_id,
      };
    default:
      return {
        ...state,
      };
  }
};

function VehicleCheckoutProvider({ children }) {
  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  return (
    <CheckoutStateContext.Provider value={{ ...state }}>
      <CheckoutDispatchContext.Provider value={{ dispatch }}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  );
}

function useVehicleState() {
  const context = useContext(CheckoutStateContext);

  if (context === undefined) {
    console.info("useVehicleState must be used within a context provider");
  }

  return context;
}

function useVehicleDispatch() {
  const context = useContext(CheckoutDispatchContext);

  if (context === undefined) {
    console.info(
      "useVehicleDispatch must be used within a cart count context provider"
    );
  }

  return context;
}

export { VehicleCheckoutProvider, useVehicleState, useVehicleDispatch };
