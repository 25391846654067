import { AnnualGraphs } from "./components/AnnualGraphs";
import { CompanyGoal } from "./components/CompanyGoal";
import { CompanyGoalCompletion } from "./components/CompanyGoalCompletion";
import { CompanyYTD } from "./components/CompanyYTD";
import { DailyDivisionChart } from "./components/DailyDivisionChart";
import { DailyStats } from "./components/DailyStats";
import { Header } from "./components/Header";
import { LastYear } from "./components/LastYear";
import { ProductionTable } from "./components/ProductionTable";

import { companyGoal, teamData } from "../../data/productionData";

const ProductionDashboard = () => {
  return (
    <div className="min-h-screen p-2 bg-accentLight">
      <Header />
      <div className="p-2 mb-2 rounded-md bg-dark h-3/5">
        <ProductionTable teamData={teamData} />
      </div>
      <div className="flex flex-row items-center pt-8 pb-8 pl-12 pr-12 mb-2 rounded-md bg-dark">
        <CompanyGoalCompletion goal={100} ytd={80} />
        <CompanyGoal companyGoal={companyGoal} />
      </div>
      <div className="flex flex-row items-center p-2 rounded-md bg-dark">
        <AnnualGraphs />
        <DailyDivisionChart />
        <CompanyYTD />
        <DailyStats />
        <LastYear />
      </div>
    </div>
  );
};

export { ProductionDashboard };
