import axios from "axios";
import BreadCrumbs from "components/breadcrumbs";
import TableComponent from "components/table";
import { format } from "date-fns";
import * as React from "react";
import { useQuery } from "react-query";
import { TCommissionDetails, TCommissionInvoices } from "types/commission";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  commissionId: string;
};

export default function Commission(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.commission.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const query = useQuery<TCommissionDetails | null>(
    ["commission", props.commissionId],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/commissions/commission/${props.commissionId}
    `
        )
        .then((res) => res.data)
  );

  const columns = {
    id: {
      label: tr("Invoice #"),
    },
    commission: {
      label: tr("Commission Total"),
      cell: (row: TCommissionInvoices) => (
        <span className="font-bold">
          {dollarFormatter(
            row.fixed_rate || (row.total * (row.percent_rate || 0)) / 100
          )}{" "}
          {row.percent_rate && <span>({row.percent_rate}%)</span>}
        </span>
      ),
    },
    customer: {
      label: tr("Customer"),
      width: "100%",
      cell: (row: TCommissionInvoices) => row.customer.name,
    },
    total: {
      label: tr("Invoice Total"),
      cell: (row: TCommissionInvoices) => dollarFormatter(row.total),
    },
    creation_date: {
      label: tr("Created"),
      cell: (row: TCommissionInvoices) =>
        format(new Date(row.creation_date), "MMM dd, yyyy hh:mm aa", {
          locale: locale,
        }),
    },
  };

  return (
    <div className="py-4 divide-y">
      <div>
        <BreadCrumbs link={[{ label: "Commissions", to: `../` }]} />
        <h1>{tr("Commission")} #{props.commissionId}</h1>
      </div>
      <div className="space-y-4 my-4 py-4">
        <h2>{tr("Summary")}</h2>
        <div>
          <span className="text-sm text-slate-600">{tr("Commission Total")}</span>
          <h2>{dollarFormatter(query.data?.total)}</h2>
        </div>
        <div>
          <span className="text-sm text-slate-600">{tr("Invoice Total")}</span>
          <h2>{dollarFormatter(query.data?.invoice_total)}</h2>
        </div>
        <div>
          <span className="text-sm text-slate-600">{tr("Created By")}</span>
          <h2>{query.data?.user.name}</h2>
        </div>
      </div>
      <div className="space-y-4 my-4 py-4">
        <h2>{tr("Breakdown")}</h2>
        <TableComponent
          columns={columns}
          data={query.data?.invoices}
          to={(row) => `/invoices/${row.id}`}
        />
      </div>
    </div>
  );
}
