import React from "react";
import Dropdown from "components/dropdown";
import { Button, OutlineButton } from "components/button";
import {
  RiAddLine,
  RiArrowDownSLine,
  RiCloseCircleLine,
  RiInformationLine,
} from "react-icons/ri";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import { useHistory } from "react-router-dom";
import AssigneeSelect from "components/react-select/assignees";
import { useTranslation } from 'react-i18next';

const MassScheduler = () => {
  const { t } = useTranslation();
  const tBase = "features.mass-scheduler";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const { setModal } = useModalContext();

  //|<---------React useStates---------->|
  const [CheckedLines, setCheckedLines] = React.useState<
    { id: string; Cid: string }[]
  >([]);
  const [Criteria, setCriteria] = React.useState([
    { label: "none", value: "none" },
  ]);

  //|<---------DataBase queries---------->|
  const AppointmentTypes = useQuery(
    "applist",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-types`
        )
        .then((res) => res.data)
  );

  const RequestCats = useQuery(
    "ReqCats",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/requestCategories`
        )
        .then((res) => res.data)
  );
  const CustomerList = useQuery(
    ["CustomerList", Criteria],
    async () =>
      await axios
        .get(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/v1/company/customers?type=MSchedule&filters=${
            "{" +
            Criteria.map((item) => {
              if (item.value === "none") {
                return null;
              }
              return item.value;
            }).filter((a) => a !== null) +
            "}"
          }`
        )
        .then((res) => res.data)
  );
  const sendData = useMutation(
    async (body: any) =>
      await axios
        .post(
          `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/mass-scheduler 
          `,
          body
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setModal();
        history.push("/requests");
      },
    }
  );
  //|<---------Functions---------->|
  const getName = (item: any) => {
    let name = "";
    if (item?.first_name) {
      name += item?.first_name;
    }
    if (item?.last_name) {
      name += " " + item?.last_name;
    }
    if (item?.company_name) {
      name += " (" + item?.company_name + ")";
    }
    if (name.length < 2) {
      name = "No Name";
    }
    if (name.length > 30) {
      name = name.substring(0, 30) + "...";
    }
    return name;
  };

  //|<---------React components---------->|
  const CreateReqModal = () => {
    const [requestType, setrequestType] = React.useState("none");
    const [appointmentType, setappointmentType] = React.useState("none");
    const [reqDropdownText, setreqdropdowntext] =
      React.useState("Request Type");
    const [appDropdownText, setappdropdowntext] =
      React.useState("Appointment Type");
    const [ShowReqOps, setShowReqOps] = React.useState("none");
    const [ShowAppOps, setShowAppOps] = React.useState("none");
    const [AppDate, setAppDate] = React.useState("");
    const [Assignee, setAssignee] = React.useState("");
    const [Managers, setManagers] = React.useState("");
    const [Answers, setAnswers] = React.useState("");
    const [AppSTime, setAppSTime] = React.useState("");
    const [AppETime, setAppETime] = React.useState("");
    const [AppNotes, setAppNotes] = React.useState("");

    const RequestQuestions = useQuery(
      ["ReqQuestions", requestType],
      async () =>
        await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/requestQuestions?id=${requestType}`
          )
          .then((res) => res.data)
    );

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "center",
          width: "600px",
        }}
      >
        <div style={{ width: "50%", margin: "auto" }}>
          <h3>{tr("Request Details")}:</h3>
          <Dropdown
            reference={
              <OutlineButton style={{ margin: "auto" }}>
                <p style={{ display: "inline" }}>
                  {reqDropdownText ===  "Request Type" ? tr("Select Request Type") : reqDropdownText}
                </p>
                <RiArrowDownSLine
                  style={{
                    display: "inline",
                    borderLeftWidth: "1px",
                    marginLeft: "5px",
                    paddingLeft: "5px",
                  }}
                />
              </OutlineButton>
            }
            data={RequestCats.data?.map((item: any) => {
              return {
                button: [
                  {
                    label: item?.label,
                    onClick: () => {
                      setrequestType(item?.id);
                      setreqdropdowntext(item?.label);
                    },
                  },
                ],
              };
            })}
          />
          <h5>{tr("Managers")}:</h5>
          <div style={{ width: "60%" }}>
            <AssigneeSelect
              name="assignee"
              value={Managers}
              onChange={(e: any) => {
                setManagers(e);
              }}
            />
          </div>
        </div>
        <div style={{ width: "50%", borderLeftWidth: "1px", padding: "5px" }}>
          <h3>{tr("Appointment Details")}:</h3>

          <Dropdown
            reference={
              <OutlineButton>
                <p style={{ display: "inline" }}>
                  {appDropdownText === "Appointment Type" ? tr("Select Appointment Type") : appDropdownText}
                </p>
                <RiArrowDownSLine
                  style={{
                    display: "inline",
                    borderLeftWidth: "1px",
                    marginLeft: "5px",
                    paddingLeft: "5px",
                  }}
                />
              </OutlineButton>
            }
            data={AppointmentTypes.data?.map((item: any) => {
              return {
                button: [
                  {
                    label: item?.label,
                    onClick: () => {
                      setappointmentType(item?.id);
                      setappdropdowntext(item?.label);
                    },
                  },
                ],
              };
            })}
          />

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (ShowAppOps === "block") {
                setShowAppOps("none");
              } else {
                setShowAppOps("block");
              }
            }}
          >
            <p style={{ display: "inline" }}>{tr("More Options")}</p>
            <RiArrowDownSLine
              style={{
                display: "inline",
                borderLeftWidth: "1px",
                marginLeft: "5px",
                paddingLeft: "5px",
              }}
            />
          </div>
          <div style={{ display: ShowAppOps }}>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <p style={{ marginTop: "6px" }}>{tr("Date")}:</p>
              <input
                onChange={(e) => {
                  setAppDate(e.target.value);
                }}
                style={{ margin: "5px", borderWidth: "1px" }}
                type="date"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <p style={{ marginTop: "6px" }}>{tr("Start Time")}:</p>
              <input
                onChange={(e) => {
                  setAppSTime(e.target.value);
                }}
                style={{ margin: "5px", borderWidth: "1px" }}
                type="time"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <p style={{ marginTop: "6px" }}>{tr("End Time")}:</p>
              <input
                onChange={(e) => {
                  setAppETime(e.target.value);
                }}
                style={{ margin: "5px", borderWidth: "1px" }}
                type="time"
              />
            </div>
            <p>{tr("Description")}:</p>
            <textarea
              style={{ width: "90%", borderWidth: "1px" }}
              onChange={(e) => {
                setAppNotes(e.target.value);
              }}
            />
            <p>{tr("Assignee")}:</p>
            <AssigneeSelect
              assigneeType="appointment"
              name="assignee"
              value={Assignee}
              onChange={(e: any) => {
                setAssignee(e);
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "50px",
            padding: "5px",
            borderTopWidth: "1px",
            borderTopColor: "#000",
          }}
        >
          <Button
            onClick={() => {
              setModal();
            }}
            style={{ float: "left" }}
          >
            {tr("Cancel")}
          </Button>
          <Button
            onClick={() => {
              const TZ =
                ((new Date().getTimezoneOffset() / 60) * -1).toString() + ":00";

              const body = {
                request_type: requestType,
                appointment_type: appointmentType,
                address_ids: CheckedLines,
                date: AppDate,
                start_time: AppSTime ? AppSTime + ":00 " + TZ : null,
                end_time: AppETime ? AppETime + ":00 " + TZ : null,
                notes: AppNotes,
                assignee: Assignee.length > 0 ? Assignee : undefined,
                managers: Managers.length > 0 ? Managers : undefined,
              };
              sendData.mutate(body);
            }}
            style={{ float: "right" }}
            primary={true}
            disabled={appointmentType === "none"}
          >
            {tr("Confirm")}
          </Button>
        </div>
      </div>
    );
  };

  const AddCriteriaModal = () => {
    const [CriteriaList, setCriteriaList] = React.useState(Criteria);

    const NewCriteriaSection = () => {
      const [zipval, setZipval] = React.useState("");
      const [cityval, setCityval] = React.useState("");
      const [CriteriaType, setCriteriaType] = React.useState("");
      const [CriteriaValue, setCriteriaValue] = React.useState("");
      const [CriteriaLabel, setCriteriaLabel] = React.useState("");
      const [Appointmentselectcolor, setAppointmetselectcolor] =
        React.useState("#000");

      const [dropdowntext, setDropdowntext] = React.useState(
        "Select Appointment Type"
      );

      const submitdisabled = () => {
        if (CriteriaType === "") {
          return true;
        }
        if (CriteriaType === "Z" && CriteriaValue.includes('"Z":') === false) {
          return true;
        } else if (
          CriteriaType === "C" &&
          CriteriaValue.includes('"C":') === false
        ) {
          return true;
        } else if (
          CriteriaType === "SP" &&
          CriteriaValue.includes('"SP":') === false
        ) {
          return true;
        } else if (
          CriteriaType === "HA" &&
          (CriteriaValue.includes('"HA":') === false ||
            CriteriaLabel.includes("since") === false)
        ) {
          return true;
        } else if (
          CriteriaType === "NH" &&
          (CriteriaValue.includes('"NH":') === false ||
            CriteriaLabel.includes("since") === false)
        ) {
          return true;
        } else {
          return false;
        }
      };

      const Submitbutton = () => {
        return (
          <div style={{ margin: "10px" }}>
            <OutlineButton
              onClick={() => {
                setCriteriaType("");
              }}
              style={{
                marginRight: "5px",
              }}
            >
              {tr("Back")}
            </OutlineButton>
            <OutlineButton
              disabled={submitdisabled()}
              style={{}}
              onClick={() => {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }}
            >
              {tr("Add")}
            </OutlineButton>
          </div>
        );
      };
      if (CriteriaType === "") {
        return (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter" && submitdisabled() === false) {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }
            }}
          >
            <Dropdown
              reference={
                <OutlineButton>{tr("Select Criteria type")}</OutlineButton>
              }
              data={[
                {
                  button: [
                    {
                      label: tr("Zip Code"),
                      onClick: () => {
                        setCriteriaType("Z");
                      },
                    },
                  ],
                },
                {
                  button: [
                    {
                      label: tr("City"),
                      onClick: () => {
                        setCriteriaType("C");
                      },
                    },
                  ],
                },
                {
                  button: [
                    {
                      label: `${tr("Has Service plan with")}: *${tr("Appointment")}*`,
                      onClick: () => {
                        setCriteriaType("SP");
                      },
                    },
                  ],
                },
                {
                  button: [
                    {
                      label: `${tr("Had *Appointment* since")}:*${tr("Date")}*`,
                      onClick: () => {
                        setCriteriaType("HA");
                      },
                    },
                  ],
                },
                {
                  button: [
                    {
                      label: `${tr("Has NOT had *Appointment* since")}:*${tr("Date")}*`,
                      onClick: () => {
                        setCriteriaType("NH");
                      },
                    },
                  ],
                },
              ]}
            />
            <Submitbutton />
          </div>
        );
      } else if (CriteriaType === "Z") {
        return (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter" && submitdisabled() === false) {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }
            }}
          >
            <input
              autoFocus
              type="number"
              style={{ height: "2rem" }}
              value={zipval}
              onChange={(e) => {
                setZipval(e.target.value);
                setCriteriaLabel(
                  "Include Customers in Zip code: " + e.target.value
                );
                setCriteriaValue('"Z":"' + e.target.value + '"');
              }}
              placeholder={tr("Zip Code")}
            />
            <Submitbutton />
          </div>
        );
      } else if (CriteriaType === "C") {
        return (
          <div key={"citydiv"}>
            <input
              autoFocus
              type="text"
              key={"city"}
              value={cityval}
              onChange={(e) => {
                setCityval(e.target.value);
                setCriteriaLabel(
                  "Include Customers in City: " + e.target.value
                );
                setCriteriaValue('"C":"' + e.target.value + '"');
              }}
              placeholder={tr("City")}
            />
            <Submitbutton />
          </div>
        );
      } else if (CriteriaType === "SP") {
        return (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter" && submitdisabled() === false) {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }
            }}
          >
            <Dropdown
              reference={
                <OutlineButton>
                  {dropdowntext === "Select Appointment Type" ? tr("Select Appointment Type") : dropdowntext}
                </OutlineButton>
              }
              data={AppointmentTypes.data.map((item: any) => {
                return {
                  button: [
                    {
                      label: item?.label,
                      onClick: () => {
                        setCriteriaLabel(
                          tr("Include Customers with ") +
                            item?.label +
                            tr(" included in their service plan")
                        );
                        setCriteriaValue('"SP":"' + item?.id + '"');
                        setDropdowntext(item?.label);
                      },
                    },
                  ],
                };
              })}
            />
            <Submitbutton />
          </div>
        );
      } else if (CriteriaType === "HA") {
        return (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter" && submitdisabled() === false) {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }
            }}
          >
            <Dropdown
              reference={
                <OutlineButton style={{ borderColor: Appointmentselectcolor }}>
                  {dropdowntext === "Select Appointment Type" ? tr("Select Appointment Type") : dropdowntext}
                </OutlineButton>
              }
              data={AppointmentTypes.data.map((item: any) => {
                return {
                  button: [
                    {
                      label: item?.label,
                      onClick: () => {
                        setCriteriaLabel(
                          "Include Customers that have had a " +
                            item?.label +
                            " appointment"
                        );
                        setCriteriaValue('"HA":"' + item?.id + '"');
                        setDropdowntext(item?.label);
                      },
                    },
                  ],
                };
              })}
            />
            <input
              type="date"
              style={{
                cursor: CriteriaValue.includes('"HA":')
                  ? "pointer"
                  : "not-allowed",
              }}
              disabled={CriteriaValue.includes('"HA":') === false}
              onChange={(e) => {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                if (CriteriaLabel.includes("since")) {
                  setCriteriaLabel(
                    CriteriaLabel.split("since")[0] + "since " + e.target.value
                  );
                  setCriteriaValue(
                    CriteriaValue.split(",")[0] +
                      ',"HA' +
                      (1 + sametypeindex) +
                      'since":"' +
                      new Date(e.target.value).getTime() +
                      '"'
                  );
                } else {
                  setCriteriaLabel(CriteriaLabel + " since " + e.target.value);
                  setCriteriaValue(
                    CriteriaValue +
                      ',"HA' +
                      (1 + sametypeindex) +
                      'since":"' +
                      new Date(e.target.value).getTime() +
                      '"'
                  );
                }
              }}
            />
            <Submitbutton />
          </div>
        );
      } else if (CriteriaType === "NH") {
        return (
          <div
            onKeyDown={(e) => {
              if (e.key === "Enter" && submitdisabled() === false) {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                // console.log(sametypeindex);
                const newvalue =
                  '"' +
                  CriteriaType +
                  (sametypeindex + 1).toString() +
                  '":' +
                  CriteriaValue.split(":")[1] +
                  (CriteriaValue.split(":")[2]
                    ? ":" + CriteriaValue.split(":")[2]
                    : "");

                // console.log(newvalue);
                setCriteriaList([
                  ...CriteriaList,
                  { label: CriteriaLabel, value: newvalue },
                ]);
                setCriteriaLabel("");
                setCriteriaValue("");
                setCriteriaType("");
              }
            }}
          >
            <Dropdown
              reference={
                <OutlineButton style={{ borderColor: Appointmentselectcolor }}>
                  {dropdowntext === "Select Appointment Type" ? tr("Select Appointment Type") : dropdowntext}
                </OutlineButton>
              }
              data={AppointmentTypes.data.map((item: any) => {
                return {
                  button: [
                    {
                      label: item?.label,
                      onClick: () => {
                        setCriteriaLabel(
                          "Include Customers that have NOT had a " +
                            item?.label +
                            " appointment"
                        );
                        setCriteriaValue('"NH":"' + item?.id + '"');
                        setDropdowntext(item?.label);
                      },
                    },
                  ],
                };
              })}
            />
            <input
              type="date"
              disabled={CriteriaValue.includes('"NH"') === false}
              style={{
                cursor: CriteriaValue.includes('"NH"')
                  ? "pointer"
                  : "not-allowed",
              }}
              onChange={(e) => {
                const sametypeindex = Criteria.map((a) => {
                  return a.value;
                }).filter((a) => {
                  if (a.includes(CriteriaType)) {
                    return true;
                  } else {
                    return false;
                  }
                }).length;
                if (CriteriaLabel.includes("since")) {
                  setCriteriaLabel(
                    CriteriaLabel.split("since")[0] + "since " + e.target.value
                  );
                  setCriteriaValue(
                    CriteriaValue.split(",")[0] +
                      ',"NH' +
                      (1 + sametypeindex) +
                      'since":"' +
                      new Date(e.target.value).getTime() +
                      '"'
                  );
                } else {
                  setCriteriaLabel(CriteriaLabel + " since " + e.target.value);
                  setCriteriaValue(
                    CriteriaValue +
                      ',"NH' +
                      (sametypeindex + 1) +
                      'since":"' +
                      e.target.value +
                      '"'
                  );
                }
              }}
            />
            <Submitbutton />
          </div>
        );
      } else {
        return (
          <div>
            <h3>{tr("ERROR")}</h3>
          </div>
        );
      }
    };
    const CurrentCriteriaSection = () => {
      return (
        <div
          style={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "10px",
            borderBottomWidth: "1px",
          }}
        >
          <h3>{tr("Current Criteria")}</h3>
          {CriteriaList.filter((a) => {
            return a.label !== "none";
          }).map((item, index) => {
            return (
              <div
                style={{
                  borderWidth: "1px",
                  borderRadius: "5px",
                  minHeight: "2rem",
                  padding: "5px",
                }}
              >
                <p style={{ display: "inline" }}>{item.label}</p>
                <RiCloseCircleLine
                  style={{
                    display: "inline",
                    float: "right",
                    color: "#ff0000",
                  }}
                  onClick={() => {
                    setCriteriaList(
                      CriteriaList.filter((a) => {
                        return a.value !== item.value;
                      })
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <div style={{ minWidth: "600px", margin: "auto" }}>
        <CurrentCriteriaSection />
        <h3>{tr("Add New")}:</h3>
        <NewCriteriaSection key="newCriteriaform" />
        <Button
          onClick={() => {
            setModal();
          }}
          style={{ margin: "5px" }}
        >
          {tr("Cancel")}
        </Button>
        <Button
          primary
          onClick={() => {
            setCriteria(CriteriaList);
            setModal();
          }}
          style={{ float: "right", margin: "5px" }}
        >
          {tr("Confirm")}
        </Button>
      </div>
    );
  };

  const Criteriasection = () => {
    return (
      <div
        style={{
          borderTopWidth: "2px",
          width: "70%",
          margin: "auto",
          minHeight: (100 + Criteria.length * 40).toString() + "px",
          padding: "10px",
        }}
      >
        <h2>{tr("Search Criteria")}</h2>
        {Criteria.map((item, index) => {
          if (item.label === "none") {
            return (
              <div>
                <h5>
                  <RiInformationLine style={{ display: "inline" }} /> {tr("Add search criteria to narrow down the customer list")}
                </h5>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  cursor: "pointer",
                  borderWidth: "1px",
                  borderColor: "#000",
                  borderRadius: "5px",
                  width: "fit-content",
                  padding: "2.5px",
                  height: "35px",
                  margin: "2.5px",
                }}
              >
                <h3 style={{ display: "inline" }}>{item.label}</h3>
                <RiCloseCircleLine
                  style={{ color: "red", display: "inline", margin: "5px" }}
                  onClick={() => {
                    let temp = Criteria.filter((a) => {
                      return a.value !== item.value;
                    });
                    setCriteria(temp);
                  }}
                />
              </div>
            );
          }
        })}
        <Button
          primary
          style={{
            float: "inline-end",
            margin: "1rem",
            width: "3rem",
            height: "3rem",
            borderRadius: "100%",
          }}
          onClick={() => {
            setModal({
              component: <AddCriteriaModal />,
              label: tr("Criteria"),
            });
          }}
        >
          <RiAddLine />
        </Button>
      </div>
    );
  };
  const CustomerListSection = () => {
    if (CustomerList.isLoading) {
      return (
        <div>
          <h1>{tr("Loading Customer List...")}</h1>
        </div>
      );
    } else {
      return (
        <div
          style={{
            borderTopWidth: "2px",
            borderBottomWidth: "2px",
            width: "70%",
            margin: "auto",
            marginLeft: "15%",
            marginRight: "15%",
            minHeight: "150px",
            padding: "10px",
            maxHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <div style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
            <h2>{tr("Customer List")}</h2>
            <h2>{"(" + CustomerList.data?.length + ")"}</h2>
          </div>
          <div style={{ float: "right" }}>
            <p style={{ display: "inline", margin: "5px" }}>{tr("Select all")}</p>
            <input
              type="checkbox"
              checked={
                CheckedLines.length >= CustomerList.data?.length &&
                CustomerList.data?.length !== 0
              }
              onClick={() => {
                if (CheckedLines.length >= CustomerList.data?.length) {
                  setCheckedLines([]);
                } else {
                  setCheckedLines(
                    CustomerList.data
                      ?.map((item: any) => {
                        return item?.customer_addresses.map((addy: any) => {
                          return {
                            id: addy?.id.toString(),
                            Cid: item?.id.toString(),
                          };
                        });
                      })
                      .map((item: any) => {
                        return item;
                      })
                      .flat()
                  );
                }
              }}
            />
          </div>
          {CustomerList.data?.map((item: any) => {
            return (
              <div
                style={{
                  borderWidth: "1px",
                  background: "#ccc",
                  borderRadius: "5px",
                  width: "100%",
                  padding: "2.5px",
                  height: 15 + item?.customer_addresses.length * 25 + "px",
                  display: "flex",
                  flexWrap: "nowrap",
                  margin: "auto",
                }}
              >
                <h3 style={{ display: "inline", width: "50%" }}>
                  {getName(item)}
                </h3>
                <div style={{ width: "50%", marginTop: "6px" }}>
                  {item?.customer_addresses.map((addy: any) => {
                    return (
                      <div style={{ alignItems: "right", textAlign: "right" }}>
                        <h5 style={{ display: "inline" }}>
                          {addy?.address +
                            " " +
                            addy?.city +
                            ", " +
                            addy?.state +
                            " " +
                            addy?.zipcode}
                        </h5>
                        <input
                          style={{
                            float: "right",
                            marginRight: "20px",
                            marginLeft: "5px",
                            marginTop: "5px",
                          }}
                          checked={CheckedLines.map((a) => {
                            return a.id;
                          }).includes(addy?.id.toString())}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCheckedLines([
                                ...CheckedLines,
                                {
                                  id: addy?.id.toString(),
                                  Cid: item?.id.toString(),
                                },
                              ]);
                            } else {
                              setCheckedLines(
                                CheckedLines.filter((a) => {
                                  return a.id !== addy?.id.toString();
                                })
                              );
                            }
                          }}
                          type="checkbox"
                        ></input>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div>
      <h1 style={{ width: "70%", margin: "auto" }}>{tr("Mass Scheduler")}</h1>
      <Criteriasection />
      <CustomerListSection />
      <Button
        primary
        onClick={() => {
          setModal({
            component: <CreateReqModal />,
            label: tr("Create ") + CheckedLines.length + tr(" Requests? "),
          });
        }}
        style={{
          marginRight: "15%",
          marginTop: "1rem",
          float: "inline-end",
        }}
      >
        {tr("Create Requests")}
      </Button>
    </div>
  );
};

export default MassScheduler;
