import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TTargetTax } from "types/tax";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type Props = {
  data: TTargetTax;
  total: { list: number; sale: number; install: number };
  priceType?: "list" | "install" | "sale";
};

export default function Tax(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.lineItems.tax";
  const tr = (key: string) => t(`${tBase}.${key}`);
  let rate, type;

  switch (props.priceType) {
    case "install":
      rate = props.data.install_rate || 0;
      type = props.data.install_type;
      break;
    case "sale":
      rate = props.data.sale_rate || 0;
      type = props.data.sale_type;
      break;
    case "list":
      rate = props.data.list_rate || 0;
      type = props.data.list_type;
      break;
    default:
      rate = 0;
      break;
  }

  const total = dollarFormatter(
    type === "fixed"
      ? rate
      : (rate / 100) * props.total[props.priceType || "list"]
  );

  return (
    <TaxCon>
      <div className="flex flex-col">
        <span>{props.data.label}</span>
        <span style={{ color: theme.color.subtext3 }}>
          {type === "percent"
            ? `${tr("Percentage")} (${(rate / 100).toFixed(2)}%)`
            : tr("Fixed Amount")}
        </span>
      </div>

      <div>{total}</div>
    </TaxCon>
  );
}

export const TaxCon = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.border.light};
  padding: 0.5rem 0;
  > div:not(:last-child) {
    margin-right: 1rem;
  }
`;
