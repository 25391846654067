import { cloneDeep, remove, sortBy } from "lodash";
import { TItemsContainer } from "types/items-container";
import { TTargetType } from "types/target-type";
import EachProduct, { EachProductCon } from "./eachProduct";
import {
	calculateGrandTotal,
	calculateTaxTotal,
	calculateTotal,
} from "./utils";
import { IconA } from "components/button";
import { RiAddFill } from "react-icons/ri";
import ProductsSelect from "components/react-select/products";
import Taxes from "./taxes";
import { dollarFormatter, priceCheck } from "utils";
import * as React from "react";
import { theme } from "styles/theme";
import GroupHeader from "./components/groupHeader";
import { useTranslation } from 'react-i18next';

export default function EditLayout(props: {
	data?: TItemsContainer;
	formik: any;
	id: string;
	type: TTargetType;
}) {
	const { t } = useTranslation();
	const tBase = "containers.lineItems.editLayout";
	const tr = (key: string) => t(`${tBase}.${key}`);
	const deleteProduct = (i: number) => {
		props.formik.setValues((v: any) => {
			let temp = cloneDeep(v);
			temp.products.splice(i, 1);
			return temp;
		});
	};

	const [activeGroup, setActiveGroup] = React.useState<number[]>([]);

	const priceType: "sale" | "install" =
		props.formik?.values?.price_type || "list";
	const rateLabel = priceType === "sale" ? "unit_price" : "install_price";

	const deleteGroup = (id: number) => {
		props.formik.setValues((v: any) => {
			let temp = cloneDeep(v);
			temp.products = temp.products.filter(
				(product: any) => product.fk_ingroup_id !== id
			);
			return temp;
		});
	};

	let products =
		props.formik.values &&
		props.formik.values.products.length > 0 &&
		props.formik.values.products;

	const populateProducts =
		products &&
		products.map((products: any, i: number, f: any) => {
			const inGroupId: number = products.fk_ingroup_id;

			const newGroup =
				inGroupId && (i === 0 || inGroupId !== f[i - 1].fk_ingroup_id);

			const showHeader =
				i === 0 ||
				products.fk_ingroup_id !== f[i - 1].fk_ingroup_id ||
				(!products.fk_ingroup_id &&
					products.root_id !== f[i - 1].root_id &&
					products.fk_product_id !== f[i - 1].fk_product_id) ||
				(products.new === true && !f[i - 1].new);

			const cat =
				showHeader &&
				(products.root_name ||
					(products.new && tr("Just Added")) ||
					(products.fk_ingroup_id && tr("Group")) ||
					tr("Service"));

			return (
				<React.Fragment key={products.id}>
					{cat && (
						<>
							<h3 className="mt-4 text-sm font-bold uppercase col-span-full text-slate-500">
								{cat}{" "}
							</h3>
						</>
					)}
					{newGroup && (
						<GroupHeader
							data={products}
							formik={props.formik}
							rateLabel={rateLabel}
							onChange={() =>
								setActiveGroup((ids) =>
									ids.includes(inGroupId)
										? remove(ids, inGroupId)
										: [...ids, inGroupId]
								)
							}
							handleDelete={() => deleteGroup(inGroupId)}
						/>
					)}
					{(activeGroup.includes(inGroupId) || !inGroupId) && (
						<EachProduct
							index={i}
							{...products}
							hideEmpty={props.formik.values.hide_empty}
							view="edit"
							isChild={products.fk_ingroup_id ? true : false}
							deleteProduct={() => deleteProduct(i)}
							formik={props.formik}
							priceType={priceType}
							rateLabel={rateLabel}
						/>
					)}
				</React.Fragment>
			);
		});

	let total = calculateTotal(props.formik?.values?.products);

	total = {
		list: total.list * 100,
		sale: total.sale * 100,
		install: total.install * 100,
	};

	const afterTaxTotal = calculateTaxTotal(total, props.data?.taxes);

	const grandTotal = calculateGrandTotal(total, afterTaxTotal);

	const addProduct = (e: any) => {
		props.formik.setValues((v: any) => {
			return {
				...v,
				products: e.children
					? [
							...v.products,
							...e.children.map((child: any) => ({
								...child,
								fk_ingroup_id: e.id,
								group_name: e.label,
								new: true,
								unit_price: priceCheck(child.unit_price),
								list_price: priceCheck(child.list_price),
								install_price: priceCheck(child.install_price),
							})),
					  ]
					: [
							...v.products,
							{
								...e,
								fk_ingroup_id: null,
								new: true,
								quantity: 1,
								unit_price: priceCheck(e.unit_price),
								list_price: priceCheck(e.list_price),
								install_price: priceCheck(e.install_price),
							},
					  ],
			};
		});
	};

	const array: ("list" | "install" | "sale")[] = ["list", "sale", "install"];

	const restOfPriceType = array
		.filter((f) => f !== priceType)
		.reduce(
			(prev, f, i, all) =>
				prev +
				`${tr(f.toLocaleUpperCase())}: ${dollarFormatter(grandTotal[f])} ${
					i !== all.length - 1 ? " | " : ""
				}`,
			""
		);

	return (
		<>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "0.3fr 0.3fr 0.5fr 2fr 5fr 1fr 1fr",
					gap: "0.5rem",
					alignItems: "flex-end",
				}}
			>
				<span />
				<span />
				<span>{tr("Qty")}</span>
				<span>{tr("Label")}</span>
				<span>{tr("Long Description")}</span>
				<span>{tr("Rate")}</span>
				<span>Total</span>
				<span />
				{populateProducts}{" "}
			</div>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "0.3fr 4fr 2fr",
					gap: "0.5rem",
					alignItems: "flex-end",
					padding: "1rem 0",
					margin: "1rem 0",
					borderTop: `1px solid ${theme.border.light}`,
					borderBottom: `2px solid black`,
				}}
			>
				<IconA to="/inventory/new" target="_blank">
					<RiAddFill />
				</IconA>
				<ProductsSelect onChange={addProduct} />
				<div />
			</div>
			{props.type !== "appointment" && (
				<Taxes
					total={total}
					view="edit"
					data={props.data?.taxes}
					id={props.id}
					type={props.type}
					priceType={priceType}
				/>
			)}
			<div style={{ alignSelf: "flex-end" }}>
				<div style={{ fontSize: "0.8rem" }}>{restOfPriceType}</div>
				<div style={{ fontSize: "1.5rem", textAlign: "right" }}>
					{" "}
					Total: {dollarFormatter(grandTotal[priceType])}
				</div>
			</div>
		</>
	);
}
