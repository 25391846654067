import React from "react";
import styled from "styled-components";
import { useQuestionBuilderDispatch, useQuestionBuilderState } from "./context";
import Axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Button } from "components/button";
import { useTour } from "contexts/tour";
import DivisionDropdown from "components/react-select/division";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  text-align: left;
  position: sticky;
  top: 1rem;
  margin: 1rem 1rem 0 1rem;
  > button {
    margin-right: 1rem;
  }
`;

const TopBar = () => {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.topbar";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const dispatch = useQuestionBuilderDispatch(),
    state = useQuestionBuilderState(),
    params = useParams<{ id: string }>(),
    queryClient = useQueryClient(),
    tour = useTour();

  const { showSnackbar } = useSnackbarContext();

  const mutation = useMutation(
    async (e: { newQuestions: any; groupLinkage: any; division: any }) =>
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/${params.id}`,
        e
      )
        .then((res) => res.data)
        .catch((err) => {
          showSnackbar(tr("Error Updating Questions"));
          console.error(err);
        }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionCategories");
        queryClient.invalidateQueries("questions");
        showSnackbar(tr("Questions Updated!"));
      },
      onError: (e) => {
        console.error(e);
        showSnackbar(tr("Error Updating Questions"));
      },
    }
  );

  const updateQuestions = async () => {
    const { questions: newQuestions, groupLinkage, division } = state;
    mutation.mutate({ newQuestions, groupLinkage, division });
  };

  return (
    <Con>
      <Button
        ref={(ref) => tour?.setStep(ref, 1)}
        onClick={() => dispatch({ type: "addMultiple" })}
      >
        {tr("Add Question")}
      </Button>
      <div className="flex items-center">
        <DivisionDropdown
          className="w-[250px]"
          isClearable
          onChange={(e: any) =>
            dispatch({ type: "changeDivision", payload: e.value })
          }
          value={state.division?.toString()}
        />
      </div>
      <Button
        ref={(ref) => tour?.setStep(ref, 5)}
        onClick={updateQuestions}
        isLoading={mutation.isLoading}
        primary
        disabled={!state.incompleteForm}
        style={{
          marginLeft: "auto",
          padding: "0 3rem",
        }}
      >
        {tr("Save Changes")}
      </Button>
    </Con>
  );
};

export default TopBar;
