import axios from "axios";
import { useQuery } from "react-query";
import TPart from "./TPart";
import { useTranslation } from 'react-i18next';

type Props = {
  subCategoryId: number;
};

const CategoryParts = ({ subCategoryId }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.categories.CategoryParts";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const {
    data: parts,
    isLoading,
    isError,
  } = useQuery("sub-category-parts", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/sub-category-parts/${subCategoryId}`
    );
    return data;
  });

  if (isLoading) {
    return (
      <div className="h-[300px] w-[100%] overflow-y-scroll ml-2 mr-2 rounded-md bg-accent-100">
        {tr("Loading")}...
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-[300px] w-[100%] overflow-y-scroll ml-2 mr-2 rounded-md bg-accent-100">
        {tr("Error fetching parts")}
      </div>
    );
  }

  return (
    <div className="h-[300px] w-[100%] overflow-y-scroll ml-2 mr-2 rounded-md bg-accent-100">
      {parts?.map((part: TPart) => (
        <div key={part.item_id} className="flex p-4 space-x-4">
          <div className="bg-slate-200 w-[100px] h-[100px] rounded" />
          <div className="flex flex-col justify-between flex-1 space-y-2">
            <div>{part.item_name}</div>
            <div>{part.short_description}</div>
            <div>{part.long_description}</div>
            <div>{part.model}</div>
            <div>{part.sku}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryParts;
