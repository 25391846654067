import axios from "axios";
import { useAreYouSure } from "components/areYouSure";
import BreadCrumbs from "components/breadcrumbs";
import { Button, OutlineButton } from "components/button";
import Field from "components/field";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import WarrantyItemsCon from "containers/warrantyItems";
import { useFormik } from "formik";
import * as React from "react";
import { RiAddLine, RiCloseFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TWarranty } from "types/warranty";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

export default function Warranty() {
	const { t } = useTranslation();
	const tBase = "views.warranty.index";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const params = useParams<{ id: string }>(),
		history = useHistory(),
		snackbar = useSnackbarContext(),
		areYouSure = useAreYouSure();

	const query = useQuery<TWarranty | null>(
		["warranty", params.id],
		async () =>
			params.id === "new"
				? null
				: axios
						.get(
							`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${params.id}
    `
						)
						.then((res) => res.data)
	);

	const mutation = useMutation(
		async (e: TWarranty | null) =>
			await axios
				.post(
					`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${params.id}
    `,
					e
				)
				.then((res) => res.data),
		{
			onSuccess: (id) => {
				if (id) history.replace(`/admin/warranty/${id}`);
				else {
					query.refetch();
				}
				snackbar.showSnackbar(
					id
						? tr("Warranty template created!")
						: tr("Warranty template updated!")
				);
			},
		}
	);

	const deleteWarranty = useMutation(
		async () =>
			await axios
				.delete(
					`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${params.id}
  `
				)
				.then((res) => res.data),
		{
			onSuccess: () => {
				history.replace("/admin/warranty");
			},
		}
	);

	const initialValues = {
		label: query.data?.label || "",
		// description: query.data?.description || "",
		items: query.data?.items || null,
	};

	const onSubmit = (e: TWarranty) => {
		mutation.mutate(e);
	};

	const validationSchema = Yup.object({
		label: Yup.string().required(),
	});

	const formik = useFormik<TWarranty>({
		onSubmit,
		enableReinitialize: true,
		initialValues,
		validationSchema,
	});

	//   React.useEffect(() => {
	//     return () => {
	//       if (formik.dirty) {
	//         if (window.confirm(
	//           "Your changes have not been saved. Are you sure you want to leave?"
	//         ));
	//       }
	//     };
	//   }, [formik.dirty]);

	return (
		<div>
			<form>
				<SafeArea classNames={{ content: "space-y-8" }}>
					<div>
						<BreadCrumbs
							link={[{ label: tr("Warranties"), to: "./" }]}
						/>
						<div className="flex justify-between">
							<div className="flex-1 max-w-[500px]">
								<Field formik={formik} name="label" />
							</div>
							<div className="flex space-x-2">
								<Button
									primary
									onClick={() => formik.handleSubmit()}
									isLoading={mutation.isLoading}
									disabled={!formik.dirty || !formik.isValid}
									type="submit"
								>
									{params.id === "new" ? (
										<>
											<RiAddLine />
											{tr("Create Template")}
										</>
									) : (
										tr("Save Changes")
									)}
								</Button>
								{/* <Button onClick={() => history.push("/admin/warranty")}>
                  Cancel
                </Button> */}
								{params.id !== "new" && (
									<OutlineButton
										className="!border-red-600 !text-red-600"
										onClick={() =>
											areYouSure.activate(() =>
												deleteWarranty.mutate()
											)
										}
									>
										<RiCloseFill />
										{tr("Delete")}
									</OutlineButton>
								)}
							</div>
						</div>
					</div>

					{/* <div>
            <Field
              type="textarea"
              label="Description"
              minRows={10}
              formik={formik}
              name="description"
            />
          </div> */}

					<WarrantyItemsCon formik={formik} />
				</SafeArea>
			</form>
		</div>
	);
}
