import axios from "api/axios";
import { A, Button, NoStyleA } from "components/button";
import FlatContainer from "components/container/flat";
import { useModalContext } from "components/modal";
import SafeArea from "components/safe-area";
import Spinner from "components/spinner";
import * as React from "react";
import { RiAddFill } from "react-icons/ri";
import { useQuery } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TItemGroup } from "types/item_group";
import EditItemGroupModal from "views/item_group/edit";
import { useTranslation } from 'react-i18next';

export default function ItemGroups() {
  const { t } = useTranslation();
  const tBase = "views.item_groups.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const modal = useModalContext();

  const query = useQuery<TItemGroup[]>(
    ["item_groups"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/item_groups
    `
        )
        .then((res) => res.data)
  );

  const populateGroups = query.data?.map((group) => {
    return <EachGroup key={group.id} {...group} />;
  });
  return (
    <SafeArea>
      <FlatContainer
        title={tr("Item Groups")}
        buttons={[
          <Button
            primary
            onClick={() =>
              modal.setModal({
                component: <EditItemGroupModal id={"new"} />,
                label: tr("Item Group"),
              })
            }
          >
            <RiAddFill /> {tr("New Group")}
          </Button>,
        ]}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {populateGroups}
        </div>
      </FlatContainer>
    </SafeArea>
  );
}

const EachGroup = (props: TItemGroup) => {
  return <SGroupCon to={`/item_groups/${props.id}`}>{props.label}</SGroupCon>;
};

const SGroupCon = styled(NoStyleA)`
  padding: 1rem 0;
  border-bottom: 1px solid ${theme.border.light};
  width: 100%;
`;
