/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef } from "react";
import styled from "styled-components";
import Modal from "components/popup";
import ReactCrop from "react-image-crop";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FileUploader = styled.input`
  // background: black;
`;

const Submit = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background: black;
  color: white;
  padding: 1rem 2rem 1rem 2rem;
  margin: 1rem;
  border: 0;
  border-radius: 3px;
`;

const EditModal = styled(Modal)`
  max-width: 1500px;
  width: 100%;
  height: 100%;

  max-height: 900px;
`;

export default function (props) {
  const { t } = useTranslation();
  const tBase = "views.team.viewUser.imageModal";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
    x: 25,
    y: 25,
  });
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [blobState, setBlob] = useState();
  const id = props.id;

  const onLoad = (img) => {
    imgRef.current = img;
  };

  const cropCompletion = async () => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, "newFile.jpeg");
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        // props.updateBlob(blob);
        setBlob(blob);
      });
    });
  };

  const dataParsing = async () => {
    const file = new File([blobState], `${id}.png`);
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", "IMAGEONLY");
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      props.cancelDisplay();
      window.location.reload();
    } catch (e) {
      alert(e);
    }
  };

  const submission = async () => {
    if (imgRef.current && crop.width && crop.height) {
      try {
        createCropPreview(imgRef.current, crop, "newFile.jpeg");
        await dataParsing();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <EditModal
      cancelDisplay={() => props.cancelDisplay()}
      display={props.editable}
    >
      <Con>
        <FileUploader
          type="file"
          accept="image/*"
          onChange={(e) => onSelectFile(e)}
        />
        {upImg && (
          <ReactCrop
            ruleOfThirds
            circularCrop
            onChange={(newCrop, percentCrop) => setCrop(newCrop)}
            onImageLoaded={(img) => onLoad(img)}
            crop={crop}
            onComplete={() => cropCompletion()}
            imageStyle={{ maxHeight: "100%", maxWidth: "100%" }}
            src={upImg}
          />
        )}
        <Submit onClick={() => submission()}>{tr("SUBMIT")}</Submit>
      </Con>
    </EditModal>
  );
}
