import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import Form from "components/form2";
import { Input } from "components/input";
import { Button } from "components/button";
import Popper from "components/react-popperv2";
import { useSnackbarContext } from "components/snackbar";
import { useModalContext } from "components/modal";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

export default function AddCategory() {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.addCategory";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbarContext();

  const { setModal } = useModalContext();

  const addCategory = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/category
    `,
        body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote"]);
        setModal();
        showSnackbar(tr("Category Added"));
      },
    }
  );

  const initialValues = {
    label: "",
    type: "list",
  };

  const validationSchema = Yup.object({
    label: Yup.string().required(),
    type: Yup.string().required(),
  });

  const onSubmit = (e: any) => addCategory.mutate(e);
  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <Con>
      <Form formik={formik}>
        <Input name="label" label={tr("Category Label")} required />
        <select onChange={(e) => formik.setFieldValue("type", e.target.value)}>
          <option value="list">{tr("List")}</option>
          <option value="dropdown">{tr("Dropdown")}</option>
        </select>
        <Button
          isLoading={addCategory.isLoading}
          disabled={!formik.isValid || !formik.dirty}
          type="submit"
        >
          {tr("Add Category")}
        </Button>
      </Form>
    </Con>
  );
}

const Con = styled.div`
  > form {
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 1rem;
    }
  }
`;
