import React from "react";
import phase3video from "videos/onboarding-phase-3.mp4";
import poster from "../../../images/eserv.png";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import CheckboxInput  from "./CheckboxInput";
import { ISetChecked } from "../interface";
import { useTranslation } from 'react-i18next';

type Phase3Props = {
  className?: string;
  updateCheckedField: (
    phase: "phase1" | "phase2" | "phase3", 
    fieldName: string, 
    value: boolean
    ) => void;
  checked?: ISetChecked;
}

const Phase1: React.FC<Phase3Props> = ({ className, updateCheckedField, checked }) => {
  const { t } = useTranslation();
  const tBase = "features.onboarding-wizard.phase3";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className={`${className} mt-10 mb-6`}>
      <video className="w-full h-[200px] mb-2 z-[2]" controls poster={poster}>
        <source src={phase3video} type="video/mp4"/>
      </video>
      <div className="z-1 text-gray-600 ">
        {/* Phase title */}
        <div className="text-lg font-semibold text-gray-700">
          <span className="text-secondary">{tr("Phase 3")} </span>- {tr("Setting Up Payments")}
        </div>

        {/* Description */}
        <div className="mt-2">
          {tr("Welcome to the final phase, it's time to get paid! For this phase we will be connecting EServ to your QuickBooks account and setting up a Stripe connection for credit card and ACH payments.")}
        </div>

        {/* Content */}
        <div className="text-gray-600 mt-2">

          {/* QuickBooks Sync */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase3" 
                fieldName="qbSync" 
                onChange={updateCheckedField} 
                checked={checked?.phase3?.qbSync || false}
              />
              <Link to="/admin/quickbooks" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">QuickBooks Sync:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("Prior to setting up your sync we recommend a consultation with your Onboarding specialist. In some situations, a recommendation for a new QuickBooks file may be suggested.")}
              </div>
              <div>
                {tr("When your file is ready to be connected, click on the")}&nbsp;
                <Link to="/admin/quickbooks" className="text-secondary no-underline hover:underline">
                  QuickBooks Sync 
                </Link> 
                &nbsp;{tr("link and proceed to the tab which says instructions. Next continue by following the instructions provided to complete your QuickBooks Sync setup.")}
              </div>
            </div>
          </div>

          {/* Stripe Connection */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase3" 
                fieldName="stripeConnection" 
                onChange={updateCheckedField} 
                checked={checked?.phase3?.stripeConnection || false}
              />
                <Link to="/" className="[&>*]:inline-block">
                  <div className="text-secondary font-bold hover:underline">{tr("Stripe Connection")}:</div>
                  <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
                </Link>
            </div>
            <div className="[&>*]:mb-2">
              {tr("A short questionnaire is all that is needed to begin using Stripe as your payment processor. Along with basic company information, you will also need your tax ID and business bank account details.  The questionnaire takes less than 15 minutes and approval from Stripe is typically complete in 24 hours.")}  
            </div>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Phase1;