import * as React from "react";
import styled from "styled-components";
import { Input } from "./index";
import { StyledLabel, Asterick } from "./style";
import { useTranslation } from 'react-i18next';

type Props = {
  options: { value: string; label: string }[];
  name: string;
  value?: string;
  error?: string;
  label?: string;
  onChange: () => void;
  required?: boolean;
};

export default function RadioGroup(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.input";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const populateOptions = props.options.map((option) => (
    <SOption key={option.value}>
      <Input
        onChange={props.onChange}
        name={props.name}
        value={option.value}
        type="radio"
      />
      <label style={{ fontSize: "1.1rem", marginLeft: "0.5em" }}>
        {option.label}
      </label>
    </SOption>
  ));

  return (
    <div style={{}}>
      <StyledLabel>
        {props.label}
        {props.required && <Asterick>({tr("Required")})</Asterick>}
      </StyledLabel>
      {populateOptions}
      {props.error && <span style={{ color: "red" }}>{props.error}</span>}
    </div>
  );
}

const SOption = styled.div`
  display: flex;
  align-items: center;
`;
