import React, {useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button, NoStyleButton } from "components/button";
import { useModalContext } from "components/modal";
import {tohrs} from 'utils/tohrs'
import Table from "components/table";
import { useQuery, useMutation } from "react-query";
import  twodigitmonth  from "utils/twodigitmonth";
import {
	RiCloseLine,
	RiEdit2Line,
	RiErrorWarningLine,
	RiFileExcelLine,
	RiFilterLine,
	RiMenuAddLine,
	RiQuestionLine,
	RiStickyNoteLine,
} from "react-icons/ri";
import Dropdown from "components/dropdown";
import { Input } from "components/input";
import { Select } from "@material-ui/core";
import timeserv from "images/TIMEServ.png";
import Tab from "components/tab";
import {Avatar} from "components/avatar";
import {
	RiDeleteBinLine,
	RiNavigationLine,
	RiCheckLine,
	RiTimeLine,
	RiUser2Line,
} from "react-icons/ri";
import Switch from "rc-switch";
import DatePicker from "react-multi-date-picker";
import TooltippedElement from "components/TooltippedElement";
import EditPunchModal from "./EditPunchModal";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	margin: 10px;
	display: inline;
`;
const Row = styled.div`
	margin-top: 10px;
	border: solid;
	border-width: 1.5px;
	border-radius: 5px;
	border-color: #ff4f00;
	display: block;
	min-height: 10px;
`;
const Title = styled.div`
	text-align: left;
	margin-bottom: 1rem;
`;



const AddPunchModal = (props) => {
    const { t } = useTranslation();
    const tBase = "views.time_dashboard.AddPunchModal";
    const tr = (key) => t(`${tBase}.${key}`);

    const EmployeeList = props.EmployeeList;
    const refreshinfo = props.refreshinfo;
    const { setModal } = useModalContext();
    const [employeePin, setEmployeePin] = React.useState();
    const [type, setType] = React.useState("Clock In");
    const [time, setTime] = React.useState('');
    const [note, setNote] = React.useState();
    const [optionbg, setoptionbg] = React.useState({
        in:'#f4f4f4',
        out:'#f4f4f4',
        outLunch:'#f4f4f4',
        outBreak:'#f4f4f4'
    });
    const [Dropdowntext, setdropdowntext] = React.useState(() => {
        return (
            <div >
                <RiNavigationLine style={{ display: "inline" }} />
                <p style={{ display: "inline" }}>{tr("Select Employee")}</p>
            </div>
        );
    });
    const namelist = { names: [], pins: [] };
    
    // this should be using the /api/v1/eserv-business-proctected/time_clock endpoint since it is accessible only to authenticated users
	const SendTime = useMutation((info) => {
		axios
			.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business-unprotected/time_clock`,
				{
					data: info.clocktime,
					pin: info.pin,
					type: info.type,
					note: info.note,
                    isfromadmin: true,
                    punchType: info.type
				}
			)
			.then(() => {
                refreshinfo();
			});
	});

    EmployeeList.data.forEach((a) => {
        if (namelist.names.includes(a.name)) {
            return;
        } else {
            namelist.names.push(a.name);
            namelist.pins.push(a.pin);
        }
    });

    return (
        <div>
            <Dropdown
                reference={
                    <button
                        style={{
                            margin: "5px",
                            borderBottom: "solid",
                            borderWidth: ".2px",
                            height:'2.5rem',
                            width:'98%',
                            padding:'5px'
                        }}
                        children=<div>{Dropdowntext}</div>
                    />
                }
                data={[
                    {
                        button: namelist.names.map((a, i) => {
                            return {
                                label: (
                                    <p>
                                        <RiUser2Line
                                            style={{ display: "inline" }}
                                        />
                                        {a}
                                    </p>
                                ),
                                onClick: () => {
                                    setEmployeePin(namelist.pins[i]);
                                    setdropdowntext(
                                        <p>
                                            <RiUser2Line
                                                style={{
                                                    display: "inline",
                                                }}
                                            />
                                            {a}
                                        </p>
                                    );
                                },
                            };
                        }),
                    },
                ]}
            />
            
            <div style={{display:'flex', flexDirection:'row', aligneItems:'space-between'}}>
                <div style={{width:'45%', display:'flex', flexDirection:'column', margin:'auto'}}>
                    <p style={{fontSize:'12px',marginBottom:'5px'}}>Type</p>
                    <Select
                        type="select"
                        defaultValue="Clock In"
                        name="Type"
                        id="Type"
                        label={tr("Type")}
                        style={{ display:'flex', width: "100%", height:'2.4rem', borderRadius: "5px", borderWidth:'1px', padding:'5px', margin:'auto',cursor:'pointer' }}
                        onChange={(a) => {
                            setType(a.target.value);
                        }}
                    >
                        <option 
                            style={{cusor:'pointer', background:optionbg.in}}
                            onMouseEnter={() => {
                                setoptionbg((prev) => {
                                    return {...prev, in:'#b4b4b4'}
                                })
                            }}
                            onMouseLeave={() => {
                                setoptionbg((prev) => {
                                    return {...prev, in:'#f4f4f4'}
                                })
                            }}
                            value="Clock In"
                        >
                            {tr("Clock In")}
                        </option>
                        <option
                            style={{cusor:'pointer', background:optionbg.out}}
                            onMouseEnter={() => {
                                setoptionbg((prev) => {
                                    return {...prev, out:'#b4b4b4'}
                                })
                            }}
                            onMouseLeave={() => {
                                setoptionbg((prev) => {
                                    return {...prev, out:'#f4f4f4'}
                                })
                            }}
                            value="Clock Out For Day"
                        >
                            {tr("Clock Out For Day")}
                        </option>
                        <option
                            style={{cusor:'pointer', background:optionbg.outLunch}}
                            onMouseEnter={() => {
                                setoptionbg((prev) => {
                                    return {...prev, outLunch:'#b4b4b4'}
                                })
                            }}
                            onMouseLeave={() => {
                                setoptionbg((prev) => {
                                    return {...prev, outLunch:'#f4f4f4'}
                                })
                            }}
                            value="Clock Out For Lunch"
                        >
                            {tr("Clock Out For Lunch")}
                        </option>
                        <option
                            style={{cusor:'pointer', background:optionbg.outBreak}}
                            onMouseEnter={() => {
                                setoptionbg((prev) => {
                                    return {...prev, outBreak:'#b4b4b4'}
                                })
                            }}
                            onMouseLeave={() => {
                                setoptionbg((prev) => {
                                    return {...prev, outBreak:'#f4f4f4'}
                                })
                            }}
                            value="Clock Out For Break"
                        >
                            {tr("Clock Out For Break")}
                        </option>
                    </Select>
                </div>
                <Input 
                    label={tr("Note")}
                    style={{width:'45%', display:'flex', margin:'auto' }}
                    onChange={(a) => {
                        setNote(a.target.value)
                    }}
                />

            </div>
            <Input
                label={tr("Time")}
                type="datetime-local"
                onChange={(a) => {
                    let time = new Date(a.target.value);
                    setTime(time);
                }}
            />
            <Button
                style={{ margin: "5px", float: "right" }}
                children=<p>Add</p>
                primary
                disabled={time.length<2 || !employeePin}
                onClick={() => {	
                    SendTime.mutateAsync({
                        clocktime: time,
                        pin: employeePin,
                        type: type,
                        note: note,
                    }).then(() => {
                        setModal();
                    });
                }}
            />
        </div>
    );
};

export default AddPunchModal;