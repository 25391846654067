/* Created by Hannah Green on 5/19/2021 for ES-147 */

/* This is a class because the react-to-print library does not work with 
functions very well and whatever is being printed HAS to be a class */

import React from "react";
import styled from "styled-components";
import ReportTable from "./report-table";
import { IoIosArrowDropdown, IoIosArrowDropright } from "react-icons/io";
import ReactToPrint from "react-to-print";
import { Button } from "components/button";

const ButtonCon = styled.div`
    @media only screen and (max-width: 1200px) and (orientation: portrait){
        width: 45%;
    }

    @media only screen and (max-width: 1200px) and (orientation: landscape){
        width: 25%;
    }

    display: flex;
    flex-direction: row;
    width: 18%;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    box-shadow: 1px 1px 3px -1px grey;
    margin-bottom: 15px;
    padding: 5px;

    &:hover {
        box-shadow: 3px 3px 6px -3px rgb(78, 78, 78);
        cursor: pointer;
    }
`;

const Con = styled.div`
    width: 100%;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    box-shadow: 1px 1px 3px -1px grey;
    margin-bottom: 15px;
    padding: 5px;

    > div {
        padding: 5px;
    }

    &:hover {
        box-shadow: 3px 3px 6px -3px rgb(78, 78, 78);
        cursor: pointer;
    }
`;

const Report = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 10px;

    > label {
        margin: 5px;
        padding: 5px;
    }
`;

const Table = styled.div`
    width: 100%;
`;

const Arrow = styled.div`
    display: flex;
    width: 35px;
    max-width: 35px;
    margin: 5px;
    padding: 3px;
`;

export default class ReportRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    render() {
        return (
            <>
                <ButtonCon>
                    <Report onClick={() => {
                        if (!this.state.open) {
                            this.setState({ open: true });
                        } else {
                            this.setState({ open: false });
                        }
                    }}>
                        <label>
                            {this.props.label}
                        </label>
                        {!this.state.open &&
                            <>
                                <Arrow>
                                    <IoIosArrowDropright size="25" />
                                </Arrow>
                            </>
                        }
                        {this.state.open &&
                            <>
                                <Arrow>
                                    <IoIosArrowDropdown size="25" />
                                </Arrow>
                            </>
                        }
                    </Report>
                </ButtonCon>
                {this.state.open &&
                    <Con>
                        <div>
                            <ReactToPrint
                                trigger={() => <Button primary>Print</Button>}
                                content={() => this.componentRef}
                            />

                        </div>
                        <Table>
                            <ReportTable ref={(el) => (this.componentRef = el)} headers={this.props.tableHeaders} data={this.props.data} title={this.props.label} />
                        </Table>
                    </Con>
                }

            </>
        )
    }
}