import React, { createContext, useContext, useReducer } from "react";

const PageStateContext = createContext();
const PageDispatchContext = createContext();

const initialState = "";

const pageReducer = (state, action) => {
  switch (action.type) {
    case "INCREMENT":
      return (state = action.page);
    default:
      return state;
  }
};

function PageContextProvider({ children }) {
  const [state, dispatch] = useReducer(pageReducer, initialState);

  return (
    <PageStateContext.Provider value={state}>
      <PageDispatchContext.Provider value={dispatch}>
        {children}
      </PageDispatchContext.Provider>
    </PageStateContext.Provider>
  );
}

function usePageState() {
  const context = useContext(PageStateContext);

  if (context === undefined) {
    console.info("usePageState must be used within a Page context provider");
  }

  return context;
}

function usePageDispatch() {
  const context = useContext(PageDispatchContext);

  if (context === undefined) {
    console.info("usePageDispatch must be used within a Page context provider");
  }

  return context;
}

function useDispatchAndState() {
  return [usePageDispatch(), usePageState()];
}

export {
  PageContextProvider,
  usePageState,
  usePageDispatch,
  useDispatchAndState,
};
