import styled from "styled-components";
import { theme } from "styles/theme";

export const SCon = styled.div`
  overflow: auto;
`;

export const SFooter = styled.div`
  padding: 0.25rem;
  > button {
    width: 100%;
  }
`;

export const SListCon = styled.div<{ index: number }>`
  padding: 0.25rem;
  // max-height: 400px;
  display: flex;
  flex-direction: column;
  // overflow: auto;
  > button {
    justify-content: flex-start;
    flex: 0 0 auto;
    transition: none;
    &:hover {
      background: transparent;
    }
    &:nth-child(${(props) => props.index + 1}) {
      background: ${theme.bg.blue};
    }

    > span {
      color: ${theme.color.subtext2};
      font-weight: 400;
    }
  }
`;
