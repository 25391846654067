import { useState, useEffect } from "react";
import Select from "components/react-select";
import { components, OptionProps, GroupTypeBase } from "react-select";
import { Button } from "components/button";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Switch from "rc-switch";
import AssigneeSelect from "components/react-select/assignees";
import { Input } from "components/input";
import { useTranslation } from 'react-i18next';

type mutateProps = {
  userId: number | string;
  routeId: string | number;
};
type Options = {
  value: number;
  label: string;
};
const PermissionsIndex = () => {
  const [copying, setCopying] = useState<boolean>(false);
  const [userID, setUserID] = useState<number | null>(null);
  const [templateID, setTemplateID] = useState<string | null | string>(null);
  const { t, i18n } = useTranslation();
  const tBase = "views.admin.permissions.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [instructionMessage, setInstructionMessage] = useState<string>(
    "Select a User or Template to view and edit permissions"
  );
  const [templateName, setTemplateName] =
    useState<string>("Templates...");
  const [userName, setUserName] = useState<string>("Users...");
  const [routeArray, setRouteArray] = useState<any[]>([]);
  const queryClient = useQueryClient();

  // Update state when language changes
  useEffect(() => {
    const updateMessages = () => {
      const lang = i18n.language;
      if (lang === 'es') {
          if (templateName === "Templates...") setTemplateName("Plantillas...");
          if (userName === "Users...") setUserName("Usuarios...");
          if (instructionMessage === "Select a User or Template to view and edit permissions") {
              setInstructionMessage("Selecciona un usuario o plantilla para ver y editar permisos");
          }
          if (instructionMessage === "Select A User or Template to apply the following permissions to:") {
              setInstructionMessage("Seleccione un usuario o plantilla para aplicar los siguientes permisos a:");
          }
      } else if (lang === 'en') {
          if (templateName === "Plantillas...") setTemplateName("Templates...");
          if (userName === "Usuarios...") setUserName("Users...");
          if (instructionMessage === "Selecciona un usuario o plantilla para ver y editar permisos") {
              setInstructionMessage("Select a User or Template to view and edit permissions");
          }
          if (instructionMessage === "Seleccione un usuario o plantilla para aplicar los siguientes permisos a:") {
              setInstructionMessage("Select A User or Template to apply the following permissions to:");
          }
      }
    };
    i18n.on('languageChanged', updateMessages);
    return () => {
      i18n.off('languageChanged', updateMessages);
    };
}, [i18n, tr]);

  const getSendType = () => {
    if (userID !== null) {
      return "user";
    } else {
      return "template";
    }
  };

  const templates = useQuery(
    ["Glist"],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutestemplates`
        )
        .then((res) => res.data)
  );

  const routes = useQuery(
    ["routes", userID, templateID, i18n.language],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutes/${
            userID || templateID
          }?type=${getSendType()}`
        )
        .then((res) => {
          res.data.forEach((route: any) => {
            route.name = t(`views.admin.permissions.index.${route.name}`, { defaultValue: route.name, returnObjects: true });
          });
          if (!copying) {
            setRouteArray(
              res.data.map((a: any) => {
                return { id: a.id, allowed: a.allowed };
              })
            );
          }
          return res.data;
        })
  );

  const toggleRoute = useMutation(
    ["toggleroutes"],
    async (props: mutateProps) => {
      await axios.post(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/v1/eserv-business/permissions/webroutes?type=${getSendType()}`,
        {
          userId: props.userId,
          routeId: props.routeId,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("routes");
      },
    }
  );

  const saveTemplate = useMutation(
    ["saveTemplate"],
    async (props: any) => {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/saveTemplate`,
        {
          ...props,
        }
      );
    },
    {}
  );

  const Option = ({
    children,
    ...props
  }: OptionProps<Options, true, GroupTypeBase<Options>>) => (
    <components.Option {...props}>
      <div>{children}</div>
    </components.Option>
  );
  const writtenList: any = [];
  const templatesList =
    templates.data
      ?.map((a: any) => {
        if (writtenList.includes(a.gid)) {
          return null;
        } else {
          writtenList.push(a.gid);
          return a.gid;
        }
      })
      .filter((a: any) => a !== null) || [];

  const showNameField = () => {
    if (templateID !== null) {
      return "flex";
    } else {
      return "none";
    }
  };

  const inverseShowNameField = () => {
    if (templateID !== null) {
      return "none";
    } else {
      return "flex";
    }
  };
  return (
    <div>
      <h1>{tr("Permissions")}</h1>
      <div className="mb-4">
        <p className="py-2">{instructionMessage}</p>
        <div className="flex flex-row flex-wrap w-1/2 md:w-auto">
          <AssigneeSelect
            className="w-[33%] min-w-[200px] mr-4"
            value={userID}
            placeholder={userName}
            onChange={(e: any) => {
              if (e?.length > 0) {
                // console.log(e);
                setUserID(e[0]?.value);
                setUserName(e[0]?.label);
                setTemplateID(null);
                setTemplateName("Templates...");
              } else {
                return;
              }
            }}
          />

          <Select
            placeholder={templateName}
            // style={{width:'33%', margin:'auto'}}
            className="w-[33%] min-w-[200px]"
            onChange={(e: any) => {
              if (e !== null && e !== undefined) {
                // console.log(e);
                setTemplateID(e?.value);
                setTemplateName(e?.label);
                setUserID(null);
                setUserName("Users...");
              } else {
                return;
              }
            }}
            value={templateID}
            options={[
              { label: tr("+ New Template"), value: ":id" },
              ...templatesList?.map((a: any) => {
                return {
                  label: templates.data.find((b: any) => {
                    return b.gid === a;
                  }).templatename,
                  value: a,
                };
              }),
            ]}
            components={{ Option }}
          />

          <Input
            style={{ display: showNameField(), margin: "auto", width: "33%" }}
            type="text"
            placeholder={tr("Rename")}
            key="Name"
            value={templateName}
            onChange={(e: any) => {
              setTemplateName(e.target.value);
            }}
          />
        </div>
      </div>

      {/* Permission Switches */}
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
          {routes.data?.map((route: any) => (
            <div className="flex flex-row items-center border-b-2 border-gray-300 pb-2">
              <Switch
                className="min-w-[44px]" // 44px is the width of the switch
                checked={routeArray.find((a) => a.id === route.id)?.allowed}
                disabled={copying}
                onChange={(e) => {
                  setRouteArray(
                    routeArray.map((a) => {
                      if (a.id === route.id) {
                        return { id: route.id, allowed: e.valueOf() };
                      } else {
                        return a;
                      }
                    })
                  );
                }}
              ></Switch>
              {/* <h4 className="ml-2">{t(`views.admin.permissions.index.${route.name}`, { defaultValue: route.name, returnObjects: true })}</h4> */}
              <h4 className="ml-2">{route.name}</h4>
            </div>
          ))}
        </div>
      </div>

      <div className="py-2"></div>
      <Button
        primary
        className="p-4 m-4"
        onClick={() => {
          const doFirst = async () => {
            routeArray.forEach((route) => {
              if (
                route.allowed !==
                  routes.data?.find((a: any) => a.id === route.id)?.allowed &&
                userID !== null
              ) {
                toggleRoute.mutateAsync({ userId: userID, routeId: route.id });
              } else {
                return;
              }
            });
            if (templateID !== null) {
              await saveTemplate.mutateAsync({
                id: templateID,
                routes: routeArray,
                templatename: templateName,
              });
            }
          };
          doFirst().then(() => {
            // console.log("done");
            queryClient.invalidateQueries("routes");
            queryClient.invalidateQueries("Glist");
            queryClient.invalidateQueries();
            setCopying(false);
            setInstructionMessage(
              "Select A User or Template to view and edit permissions"
            );
          });
        }}
      >
        {tr("Apply")}
      </Button>
      <Button
        primary={!copying}
        disabled={copying}
        className="p-4"
        onClick={() => {
          setCopying(true);
          setTemplateID(null);
          setTemplateName("Templates...");
          setUserID(null);
          setUserName("Users...");
          setInstructionMessage(
            "Select A User or Template to apply the following permissions to:"
          );
        }}
      >
        {tr("Copy To...")}
      </Button>
    </div>
  );
};

export { PermissionsIndex };
