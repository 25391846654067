import * as React from "react";
import ReactSelect from ".";
import { Props } from "react-select";
import { useQuery } from "react-query";
import axios from "axios";
import { TTax } from "types/tax";
import Field from "components/field";
import { useTranslation } from 'react-i18next';

type TProps = Omit<Props, "value"> & {};

export default function TaxSelect(props: TProps) {
  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const fetchTaxOptions = useQuery(
    "taxOptions",
    async () =>
      await axios
        .get(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax
      `
        )
        .then((res) => res.data)
  );

  if (!fetchTaxOptions.isSuccess) return null;

  const formatVal =
    fetchTaxOptions.data?.find((tax: TTax) => tax.id === props.value) || "";

  return (
    <Field
      type="select"
      style={{ width: "100%" }}
      options={fetchTaxOptions.data?.map((tax: TTax) => ({
        ...tax,
        value: tax.id,
      }))}
      name="select"
      placeholder={tr("Select a tax")}
      {...props}
      value={formatVal}
    />
  );
}
