import React, { useContext } from "react";
import { format } from "date-fns";
import { RiClipboardLine, RiToolsLine } from "react-icons/ri";
import { parseDuration } from "utils/duration";
import { useModalContext } from "components/modal";
import EditBlock from "../../editBlock";
import { ViewByTypeProvider } from "../../context/ViewByTypeContext";
import ViewByTypeContext from "../../context/ViewByTypeContext";
import axios from "axios";
import { useQuery } from "react-query";
import { NoStyleButton } from "components/button";
import { useDrag } from "react-dnd";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
	note,
	address,
	latitude,
	longitude,
	handleToolTip,
	toolTipModal,
	userId,
	date,
	name,
	type,
	billed_to,
	canDrag = true,
	height,
	appointment,
	selected,
	cellWidth,
	
}) {
	const { t } = useTranslation();
	const tBase = "views.calendar.containers.daily.appointmentCard";
	const tr = (key) => t(`${tBase}.${key}`);

	const { setModal } = useModalContext();
	const { viewByType } = useContext(ViewByTypeContext);

	const { 
		start, 
		status, 
		duration, 
		id, 
		attention, 
		appointment_category, 
		value
	} = appointment;

	const startTime = start ? format(new Date(start), "h:mma") : "";

	// Calculate the hours and minutes separately
	const hoursLeft = (format(start, "H") - 7) * cellWidth * 2;
	const minutesLeft = (format(start, "m") / 60) * cellWidth * 2; // Proportionally calculate the minutes part
	const totalLeft = hoursLeft + minutesLeft + 150; 

	const left = totalLeft + "px"; 
	const width = `${duration ? (duration / 60) * cellWidth * 2 : cellWidth * 2}px`;


	// Query for appointment type
	const appointmentType = useQuery(
		"appointment type",
		async () =>
			await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-types/`
				)
				.then((res) => {
					return res.data;
				})
	);

	// Defines typeColor after appointmentType query is complete
	let typeColor;
	if (appointmentType.data) {
			const typeData = appointmentType.data.find(t => t.label === appointment_category);
			if (typeData) {
				typeColor = typeData.color;
			}
	}

	// Conditionally changes border and background color based on type or status
	let background, border;
	if (viewByType && typeColor) {
			background = `${typeColor}10`; // 10% opacity
			border = typeColor;
	} else if (!viewByType && status) {
			background = `rgba(${status.color},0.1)`;
			border = `rgb(${status.color})`;
	} else {
			background = "rgba(0,0,0,0.1)";
			border = "black";
	}

	const [{ isDragging }, drag] = useDrag(() => ({
		type: "BOX",
    item: { 
			type: "BOX", 
			id, 
			date: start, // this is where the prevDate is being passed
			prevUserId: value, 
		},
		collect: (monitor) => {
			return {
				isDragging: !!monitor.isDragging(),
			};
		},
		end: (item, monitor) => {},
		canDrag: canDrag,
	}));

	const top = `${height + 4}px`;
	const boxShadow = selected && `0 0 10px 0 ${border}`;

	const parsedDuration = parseDuration(appointment.duration);

	return (
		<ViewByTypeProvider>
			<NoStyleButton
				ref={drag}

				onClick={() => handleToolTip({ id: id })}
				style={{

				}}
			>
			<div
				className="absolute flex overflow-hidden bg-white rounded"
				style={{
					...{
						height: "63px",
						top,
						left,
						width,
						border: `1px solid ${border}`,
						color: "black",
						boxShadow,
						cursor: "pointer",
					},
				}}
				onClick={() =>
					appointment.block_type === "block"
						? setModal({
								component: <EditBlock block={appointment} />,
								label: tr("Edit Block"),
							})
						: handleToolTip({ id })
				}
			>
				<div className="flex flex-col w-full" style={{ background }}>
					<div
						className="flex justify-between items-center text-[0.7rem] text-white px-1 flex-none whitespace-nowrap"
						style={{ background: border }}
					>
						<span>
							{appointment.duration
								? (parsedDuration.hr
										? " " + +parsedDuration.hr + "h"
										: "") +
									(parsedDuration.mins
										? " " + +parsedDuration.mins + "m"
										: "")
								: ""}
							{appointment.duration &&
							appointment.appointment_category
								? " | "
								: ""}
							{appointment.appointment_category}
						</span>
						{appointment.type === "estimate" ? (
							<RiClipboardLine className="text-[0.8rem]" />
						) : (
							<RiToolsLine className="text-[0.8rem]" />
						)}
					</div>
					<div className="px-1 ">
						<div className="flex-none leading-tight font-bold text-[0.8rem] whitespace-nowrap overflow-hidden overflow-ellipsis">
							{appointment.name}
							{attention && <span className="text-red-600">!!!</span>}
						</div>
						<div className="flex-none leading-tight font-bold text-[0.7rem] whitespace-nowrap overflow-hidden overflow-ellipsis">
							{appointment.note}
						</div>
						<div className="text-xs leading-tight text-slate-700">
							{appointment?.address_obj?.zipcode}
						</div>
					</div>
				</div>
			</div>
			</NoStyleButton>
		</ViewByTypeProvider>
	);
}
