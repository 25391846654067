import axios from "axios";
import TooltippedElement from "components/TooltippedElement";
import { Button, IconButton, OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import ClientSelect from "components/react-select/clients";
import Segment, { SContent, SHeader } from "components/segment";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import InvoicesList from "containers/invoicesList";
import { cloneDeep } from "lodash";
import * as React from "react";
import {
  RiAddFill,
  RiCheckboxBlankFill,
  RiCheckboxBlankLine,
  RiCheckboxCircleFill,
  RiCheckboxFill,
  RiCheckboxMultipleFill,
  RiClipboardLine,
  RiPencilRuler2Line,
} from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { TAppointment } from "types/appointment";
import { TEstimate } from "types/estimate";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  type: "request" | "project";
};
export default function InvoicesContainer(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.invoicesContainer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext();
  return (
    <div className="space-y-4 overflow-hidden">
      <div className="py-4 border-b flex justify-between items-center">
        <h2>{tr("Invoice")}</h2>
        <TooltippedElement
          message={tr("Create Invoice")}
          element={
            <OutlineButton
              onClick={() =>
                modal.setModal({
                  component: <NewInvoice {...props} />,
                  label: tr("New Invoice"),
                })
              }
            >
              <RiAddFill />
            </OutlineButton>
          }
        />
      </div>
      <InvoicesList id={props.id} type={props.type} />
    </div>
  );
}

type NewInvoiceProps = {
  id: string;
};

const NewInvoice = (props: Props) => {
  const { t } = useTranslation();
  const tBase = "containers.invoicesContainer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const options = [
    {
      label: tr("Custom"),
      icon: <RiAddFill />,
      description: "Create an invoice from scratch",
      color: "pink-500",
    },
    {
      label: tr("Appointment"),
      icon: <RiPencilRuler2Line />,
      description: "Create an invoice from appointments",
      color: "slate-500",
    },
    {
      label: tr("Estimate"),
      icon: <RiClipboardLine />,
      description: "Create an invoice from an existing estimate",
      color: "green-500",
    },
  ];

  const [selected, setSelected] = React.useState<{
      label: string | string[];
      price_type: "sale" | "install" | undefined;
    }>(),
    [addOn, setAddOn] = React.useState(false),
    [billing_customer, setBillingCustomer] = React.useState(),
    [isMultiple, setIsMultiple] = React.useState(false);

  const history = useHistory(),
    modal = useModalContext(),
    snackBar = useSnackbarContext();

  const query = useQuery<{
    appointments: TAppointment[];
    parent: { actual_total: number; price_type: "sale" | "install" } | null;
    options: ({
      id: number;
      label: string;
      estimate_label: string;
      actual_total: number;
      price_type: "sale" | "install";
      selected: boolean;
    } | null)[];
  }>(
    ["creating invoice", props.id, props.type],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.id}/options?type=${props.type}
    `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.id}?type=${props.type}
    `,
          { selected, addOn, billing_customer }
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        history.push(`/invoices/${id}`);
        snackBar.showSnackbar(tr("Invoice created!"));
        modal.setModal();
      },
    }
  );

  const populateEstimates = query.data?.options?.map((option) => {
    if (!option) return;

    return (
      <InvoiceButton
        key={option.id}
        label={`#${option.id} ${option.label} | ${dollarFormatter(
          option.actual_total
        )}`}
        isSelected={
          Array.isArray(selected?.label)
            ? selected?.label.findIndex(
                (lab) => lab === `option-${option.id}`
              ) !== -1
            : selected?.label === `option-${option.id}`
        }
        isMultiple={isMultiple}
        disabled={
          isMultiple &&
          selected?.price_type &&
          selected?.price_type !== option.price_type
        }
        handleClick={() =>
          setSelected((s) => {
            if (isMultiple) {
              let temp = cloneDeep(s);

              if (temp && Array.isArray(temp?.label)) {
                let idx = temp.label.findIndex(
                  (label) => label === `option-${option.id}`
                );

                if (idx !== -1) {
                  temp.label.splice(idx, 1);
                  if (temp.label.length === 0) temp = undefined;
                } else {
                  temp?.label.push(`option-${option.id}`);
                }
              } else {
                return {
                  label: [`option-${option.id}`],
                  price_type: option.price_type,
                };
              }

              return temp;
            } else {
              return s?.label === `option-${option.id}`
                ? undefined
                : {
                    label: `option-${option.id}`,
                    price_type: option.price_type,
                  };
            }
          })
        }
        icon={options[2].icon}
        description={
          <span>
            {`${option.estimate_label}`}
            {option.selected && (
              <span className="mx-2 bg-green-200 px-2 text-black font-bold">
                {tr("Won")}
              </span>
            )}
          </span>
        }
      />
    );
  });

  const getTotal = query.data?.appointments?.reduce(
    (prev, appointment) => {
      const priceType = appointment.price_type;
      if (!priceType) return prev;

      prev[priceType].total += appointment.actual_total;
      prev[priceType].appointments.push(appointment);

      return prev;
    },
    {
      install: {
        total: 0,
        appointments: [],
      },
      sale: {
        total: 0,
        appointments: [],
      },
      list: {
        total: 0,
        appointments: [],
      },
    }
  );

  if (query.isLoading) return <Spinner />;

  return (
    <div className="space-y-6 w-[500px] flex flex-col">
      <p>{tr("Create an invoice form")}</p>
      <InvoiceButton
        {...options[0]}
        isSelected={selected?.label === "Custom"}
        handleClick={() => {
          setSelected((s) =>
            s?.label !== "Custom"
              ? { label: "Custom", price_type: undefined }
              : undefined
          );
          setIsMultiple(false);
        }}
      />
      {/* <div className="flex flex-col space-y-4">{populateOptions}</div> */}
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col">
          <span className="text-[0.9rem] font-bold">{tr("Appointments")}</span>
          <span className="text-slate-500 font-semibold text-xs">
            {options[1].description}
          </span>
        </div>
        {getTotal?.install?.total ? (
          <InvoiceButton
            icon={options[1].icon}
            handleClick={() => {
              setSelected((s) =>
                s?.label !== "install"
                  ? { label: "install", price_type: "install" }
                  : undefined
              );
              setIsMultiple(false);
            }}
            isSelected={selected?.label === "install"}
            label={`${tr("Install Price")} | ${dollarFormatter(getTotal.install.total)}`}
            description={
              getTotal.install.appointments.reduce(
                (str: string, appt: any) => str + `Appt #${appt.id}`,
                ""
              ) || tr("No Appointments.")
            }
          />
        ) : null}
        {getTotal?.sale?.total ? (
          <InvoiceButton
            icon={options[1].icon}
            handleClick={() =>
              setSelected((s) =>
                s?.label !== "sale"
                  ? { label: "sale", price_type: "sale" }
                  : undefined
              )
            }
            isSelected={selected?.label === "sale"}
            label={`${tr("Sale Price")} | ${dollarFormatter(getTotal.sale.total)}`}
            description={
              getTotal.sale.appointments.reduce(
                (str: string, appt: any) => str + `Appt #${appt.id} | `,
                ""
              ) || tr("No Appointments.")
            }
          />
        ) : null}
      </div>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-[0.9rem] font-bold">{tr("Estimates")}</span>
            <span className="text-slate-500 font-semibold text-xs">
              {options[2].description}
            </span>
          </div>
          <div>
            <IconButton
              size="small"
              onClick={() => {
                setIsMultiple((s) => !s);
                setSelected(undefined);
              }}
              className={`${isMultiple && "!bg-[#ff4f00] !text-white"}`}
            >
              <RiCheckboxMultipleFill />
            </IconButton>
          </div>
        </div>

        <div className="flex flex-col space-y-1">{populateEstimates}</div>
      </div>
      {props.type === "project" && (
        <div>
          <ClientSelect
            onChange={(e) => setBillingCustomer(e)}
            value={billing_customer}
          />
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="space-x-2">
          {query.data?.parent &&
            (!selected?.price_type ||
              selected?.price_type === query.data.parent.price_type) && (
              <>
                {/* <input
                  type="checkbox"
                  checked={addOn}
                  onChange={() => setAddOn((s) => !s)}
                />
                <label>
                  {tr("Include add-on of")}{" "}
                  <span className="underline">
                    {dollarFormatter(query.data.parent.actual_total)}
                  </span>
                </label> */}
              </>
            )}
        </div>
        <input />
        <Button
          primary
          disabled={
            !selected || (props.type === "project" && !billing_customer)
          }
          isLoading={mutation.isLoading}
          onClick={() => mutation.mutate()}
        >
          {tr("Create Invoice")}
        </Button>
      </div>
    </div>
  );
};

type OptionProps = {
  label: string;
  isSelected: boolean;
  handleClick: (label: string) => void;
  icon: React.ReactNode;
  description: React.ReactNode;
  isMultiple?: boolean;
  disabled?: boolean;
};

const InvoiceButton = (option: OptionProps) => {
  const { t } = useTranslation();
  const tBase = "containers.invoicesContainer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <OutlineButton
      key={option.label}
      className={`!justify-start !p-4 !h-16 space-x-2 !rounded-md ${
        option.isSelected && " !border-green-600"
      } ${option.disabled && " !border-red-600"}`}
      onClick={() => option.handleClick(option.label)}
      disabled={option.disabled}
    >
      {option.isMultiple &&
        (option.isSelected ? (
          <RiCheckboxFill className="text-green-600" />
        ) : (
          <RiCheckboxBlankLine
            className={`${option.disabled ? "text-red-500" : "text-slate-500"}`}
          />
        ))}
      <div
        className={`rounded-full bg-[#ff4f00]/10 text-[1.2rem] !text-[#ff4f00] h-8 w-8 flex justify-center items-center `}
      >
        {option.icon}
      </div>
      <div className="flex flex-col flex-grow">
        <span className="text-[0.9rem] font-bold">{option.label}</span>
        <span className="text-slate-500 font-semibold text-xs">
          {option.description}
        </span>
      </div>
      {!option.isMultiple && option.isSelected && (
        <RiCheckboxCircleFill className="ml-auto !text-2xl !text-green-600" />
      )}
    </OutlineButton>
  );
};
