import * as React from "react";
import styled from "styled-components";
import { Button, SubtleButton, SubtleIconButton } from "components/button";
import { FileUpload } from "components/file-upload";
import { useMutation, useQuery } from "react-query";
import Axios from "axios";
import { generateThumbnail } from "hooks/thumbnail";
import { MdImage, MdPictureAsPdf, MdMoreVert } from "react-icons/md";
import { useSnackbarContext } from "components/snackbar";
import Popper from "components/react-popper";
import Placeholder from "../../component/placeholder";
import Spinner from "components/spinner";
import DropBox from "views/customer/component/dropBox";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  type: string;
};
export default function RequestFilesTab(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.request.container.files.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { id, type } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const { showSnackbar } = useSnackbarContext();
  const { name } = useAuthContext();

  const files = useQuery(
    ["requestFiles", type, id],
    async () =>
      await Axios.get(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}
    `
      ).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const sendFileMutation = async (files: any) => {
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      let thumbnail;
      if (files[i].type.includes("image")) {
        thumbnail = await generateThumbnail(canvasRef, files[i], "image");
      } else if (files[i].type === "application/pdf") {
        thumbnail = await generateThumbnail(canvasRef, files[i], "pdf");
      } else {
        throw new Error("no");
      }
  
      formData.append("thumbnail", thumbnail);
      formData.append("type", "RequestFile");
      formData.append("filetype", files[i].type);
      formData.append("filesize", files[i].size);
      formData.append("user", name);

      return await Axios.post(
        `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}
          `,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  };

  const sendFile = useMutation(sendFileMutation, {
    onSuccess: () => {
      files.refetch();
      showSnackbar(tr("File Uploaded!"));
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      sendFile.mutate(files);
    }
  };


  const handleDropChange = async (file: any) => {
    let fileArr: any = [];
    let push: boolean;
    if (file) {
      // checks new files against existing files, doesn't send file if duplicate, which means a new record is no longer created in the database
      for (let i = 0; i < file.length; i++) {
        // all files are pushed to upload unless a duplicate is found
        push = true;
        for (let o = 0; o < files.data.length; o++) {
          if (file[i].name === files.data[o].Key) {
            push = false;
          }
        }
        if (push) {
          fileArr.push(file[i]);
        }
      }

      if (fileArr[0]) {
        sendFile.mutate(fileArr);
      }
    }
  };

  const populateFilesGrid =
    files.data &&
    files.data.map((file: any) => {
      return (
        <FileGrid
          key={file.Key}
          {...{ file, id, type }}
          refetch={() => files.refetch()}
        />
      );
    });

  return (
    <div className="space-y-4">
      <div className="py-4 flex justify-between items-center border-b">
        <h2>{tr("Files")}</h2>
        <FileUpload
          multiple
          accept="image/*, application/pdf"
          component={
            <Button isLoading={sendFile.isLoading}>{tr("Upload File")}</Button>
          }
          onChange={handleChange}
        />
      </div>
      <div>
        {files.isFetching ? (
          <Spinner size={40} />
        ) : files.data ? (
          <div style={{ display: "inline-flex", width: "100%", justifyContent:"space-between"}}>
            <Grid>{populateFilesGrid}</Grid>
            <DropBox onChange={handleDropChange} />
          </div>
        ) : (
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <Placeholder
              type="file"
              header={tr("No files attached")}
              label={tr("Uploaded files will be shown here.")}
            />
            <DropBox onChange={handleDropChange} />
          </div>
        )}
        <canvas style={{ display: "none" }} ref={canvasRef} />
      </div>
    </div>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-top: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
`;

const FileGridCon = styled.a`
  border: 1px solid #c0c0c0;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  > div:first-child {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  > div:last-child {
    padding: 0.25em 0.5em;
    flex: nowrap;
    flex: 0 0 auto;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    > svg {
      flex: 0 0 auto;
      font-size: 1.1rem;
      margin-right: 0.5em;
    }
    > span {
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
  }
`;

const FileGrid = ({
  file,
  id,
  type,
  refetch,
}: {
  file: any;
  id: string;
  type: string;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const tBase = "views.request.container.files.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { showSnackbar } = useSnackbarContext();
  const fileType = file.Key.includes("pdf") ? "pdf" : "image";

  const getFile = useMutation(
    async () =>
      await Axios.get(
        `
    ${
      process.env.REACT_APP_SERVER_URL
    }/api/v1/company/files/${type}/${id}/${encodeURIComponent(file.Key)}
    `
      ).then((res) => res.data),
    {
      onSuccess: (data) => {
        window.open(data, "_blank") || window.location.replace(data);
      },
    }
  );

  const deleteFile = useMutation(
    async () =>
      await Axios.delete(`
  ${
    process.env.REACT_APP_SERVER_URL
  }/api/v1/company/files/${type}/${id}/${encodeURIComponent(file.Key)}
  `),
    {
      onSuccess: () => {
        refetch();
        showSnackbar(tr("File deleted!"));
      },
    }
  );

  return (
    <FileGridCon target="_blank" onClick={() => getFile.mutate()}>
      <div>
        <img
          src={file.signedUrl}
          style={{ width: "100%", objectFit: "cover", height: "100%" }}
        />
      </div>
      <div>
        {fileType === "pdf" ? (
          <MdPictureAsPdf style={{ color: "red" }} />
        ) : (
          <MdImage style={{ color: "green" }} />
        )}

        <span>{file.Key}</span>
        <Popper
          clickable
          placement="bottom-end"
          reference={
            <SubtleIconButton size="small">
              <MdMoreVert />
            </SubtleIconButton>
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SubtleButton
              isLoading={deleteFile.isLoading}
              onClick={(e) => {
                e.stopPropagation();
                deleteFile.mutate();
              }}
            >
              {tr("Delete")}
            </SubtleButton>
          </div>
        </Popper>
      </div>
    </FileGridCon>
  );
};
