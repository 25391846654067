import axios from "axios";
import { OutlineButton } from "components/button";
import Spinner from "components/spinner";
import * as React from "react";
import { useQuery } from "react-query";
import { TCustomDoc } from "types/custom-doc";
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: (templateId: string) => void;
  id?: string;
  type: "invoice" | "estimate";
};
export default function CustomDocModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.customDocModal.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const query = useQuery<TCustomDoc[]>(
    ["custom documents"],
    async () =>
      await axios
        .get(
          `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-doc?type=${props.type}
        `
        )
        .then((res) => res.data)
  );

  if (query.isLoading) return <Spinner />;

  const populateCustomDoc = query.data?.map((doc) => {
    return (
      <OutlineButton
        $selected={props.id === doc.id}
        onClick={() => {
          props.onClick(doc.id);
        }}
        key={doc.id}
        className="!w-full"
      >
        {doc.label}
      </OutlineButton>
    );
  }) || <span>{tr("No Template to select from.")}</span>;

  return (
    <div className="flex flex-col space-y-4">
      <span>{tr("Custom Documents Template")}</span>
      <div className="space-y-2">{populateCustomDoc}</div>
    </div>
  );
}
