import axios from "axios";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import type { Users } from "../type";
import { AppointmentCard } from "../components/AppointmentCard";
import useQueryString from "hooks/useQueryString";
import { useTranslation } from 'react-i18next';

export default function Unscheduled({ 
	toolTipModal, 
	handleToolTip 
}: any) {
	const { t } = useTranslation();
	const tBase = "views.calendar.sidebar.unscheduled";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const fetch = useQuery<Users>(
		["appointments", "unscheduled"],
		async () =>
			await axios
				.get(
					`
            ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/all?grid=unscheduled
            `
				)
				.then((res) => res.data)
	);

	const populateUsers =
		fetch.data &&
		Object.entries(fetch.data).map(([userId, user]) => {
			const populateAppointments = Object.entries(user.appointments).map(
				([apptId, appointment]) => {
					// const { latitude = null, longitude = null } = appointment;
					return (
						<AppointmentCard
							// {...{ latitude, longitude }}
							{...{ handleToolTip, toolTipModal }}
							canDrag={true}
							key={apptId}
							{...appointment}
							id={appointment.id.toString()}
							userId={userId}
						/>
					);
				}
			);

			return (
				<UsersCon key={userId}>
					<span>{user.assignee || tr("Unassigned")}</span>
					<div>{populateAppointments}</div>
				</UsersCon>
			);
		});

	return <Con>{populateUsers}</Con>;
}
const Con = styled.div``;

const UsersCon = styled.div`
	margin-bottom: 0.5rem;
	> span {
		font-weight: 600;
	}
`;
