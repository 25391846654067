/* Created by Hannah Green on 5/11/2021 for ES-147 */
import styled from "styled-components";
import RequestDetails from "./request-details";

export const Con = styled.div`
  @media only screen and (max-width: 1200px){
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 5px;
    box-shadow: 1px 1px 3px -1px grey;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px grey;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 3px 3px 6px -3px rgb(78, 78, 78);
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    height: 175px;
    width: 1.5%;
    background-color: ${(props) => props.status};
  }
`;

export const RepairInfo = styled.div`
    @media only screen and (max-width: 1200px) {
      display: flex;
      flex-direction: row;
    }

    display: flex;
    width: 100%;
    flex-direction: row;
    font-size: 15px;
`;

export const UserIcon = styled.div`
    padding: 10px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: start;
  width: 255px;
  max-with: 255px;
  padding: 5px;
  font-size: 17px;

  > div: not(:last-child) {
    padding: 10px;
  }

  > div:last-child {
    padding-left: 10px;
  }
`;

export const Description = styled.div`
@media only screen and (orientation: portrait){
  width:100%;
  max-width: 100%;
}

@media only screen and (max-width: 1200px) and (orientation: landscape){
  width: 200px;
  max-width: 200px;
}

    font-size: 15px;
    width: 300px;
    max-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;

    > label {
      font-size: 17px;
      padding: 5px;
    }

    > div {
      padding: 5px;
      background-color: #ebedef;
      border: 1px solid #ebedef;
      border-radius: 5px;
    }

`;

export const Comments = styled.div`
@media only screen and (orientation: portrait){
  width:100%;
  max-width: 100%;
}

@media only screen and (max-width: 1200px) and (orientation: landscape){
  width: 200px;
  max-width: 200px;
}


  font-size: 15px;
  width: 300px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;

    > label {
      font-size: 17px;
      padding: 5px;
    }

    > div {
      padding: 5px;
      background-color: #ebedef;
      border: 1px solid #ebedef;
      border-radius: 5px;
    }
`;

export const MaintNotes = styled.div`
@media only screen and (orientation: portrait){
  width:100%;
  max-width: 100%;
}

@media only screen and (max-width: 1200px) and (orientation: landscape){
  width: 200px;
  max-width: 200px;
}

  font-size: 15px;
  width: 300px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;

    > label {
      font-size: 17px;
      padding: 5px;
    }

    > div {
      padding: 5px;
      background-color: #ebedef;
      border: 1px solid #ebedef;
      border-radius: 5px;
    }
`;

export const Icons = styled.div`
    @media only screen and (max-width: 1200px){
      display: flex; 
      flex-direction: row;
      justify-content: flex-end;
      justify-self: end;
    }

    @media only screen and (orientation: portrait){
      display: flex; 
      flex-direction: row;
      justify-content: flex-end;
      margin-left: 20%;
    }

    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
    justify-self: end;   
    width: 250px;
    max-width: 250px;
`;

export const Images = styled.div`
    width: 100px;
    display: flex;
    flex-direction: row;

    > div {
      margin: 10px;
    }

    &:hover {
      cursor: pointer;
    }
`;

export const Status = styled.div`
      display: flex;
      flex-direction: row;
      width: 125px;
      height: 25px;
      margin-right: 5px;
      border: 1px solid ${(props) => props.background_color};
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      color: ${(props) => props.color};
      background-color: ${(props) => props.background_color};
      margin-top: 5px;
`;

export const Priority = styled.div`
    padding: 5px;
`;

export const Tag = styled.div`
    padding: 5px;
`;

export const Overdue = styled.div`
    padding: 5px;
`;

export const Middle = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const DetailsModal = styled(RequestDetails)`
`;