import Tab from "components/tab";
import Unscheduled from "./unscheduled";
import { useDrop } from "react-dnd";
import PartiallyCompleted from "./partially_completed";
import { useTranslation } from 'react-i18next';

export default function Sidebar(props: any) {
  const { t } = useTranslation();
  const tBase = "views.calendar.sidebar.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "BOX",
    drop: ({ id, date: prevDate, prevUserId }: any) => {
      if (prevDate === null) return;

      props.appointmentMoved.mutate({
        prevDate,
        date: null,
        id,
        prevUserId,
        userId: prevUserId,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const data = {
    unscheduled: {
      tab: tr("Unscheduled"),
      content: <Unscheduled {...props} />,
    },
    partially_completed: {
      tab: tr("Partially Completed"),
      content: <PartiallyCompleted {...props} />,
    },
  };
  return (
    <div
      ref={drop}
      style={{ background: isOver ? "grey" : "transparent" }}
      className="flex-none p-[0.5rem] overflow-auto w-[300px] mt-14 border-t border-r"
    >
      <Tab defaultTab="unscheduled" data={data} />
    </div>
  );
}
