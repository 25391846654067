import React, { useState } from 'react';

// creates each tag filter button
const ClickableTag = ({tag, setSelectedTags, setUnselectedTags, unselected}: any) => {
  const [showTag, setShowTag] = useState(true);
  const [tagData, setTagData] = useState(tag);

  const updateTags = () => {
    setShowTag(false);
    if (unselected) {
      // adds tag to setSelectedTags
      setSelectedTags((prevState:any) => [...prevState, tagData])
      // returns every tag to setUnselectedTags that doesn't equal the clicked tag's id
      setUnselectedTags((prevState:any) => prevState.filter((tag:any) => tag.id !== tagData.id))
    }
    else {
      setUnselectedTags((prevState:any) => [...prevState, tagData])
      setSelectedTags((prevState:any) => prevState.filter((tag:any) => tag.id !== tagData.id))
    }
  }      

  return ( 
    <>
    {unselected ?
      showTag &&
        <button key={tag.id} onClick={updateTags} className={`rounded p-1 text-white`} style={{ background: tag.color, display: "block", margin: "5px", textAlign: "center" }}>
          {tag.label}
        </button>
    : 
      showTag &&
        <button key={tag.id} onClick={updateTags} className={`rounded p-1 text-white`} style={{ background: tag.color, display: "block", margin: "5px", textAlign: "center" }}>
          {tag.label}
        </button>
    }
    </>
  ) 
}

export default ClickableTag;