import axios from "axios";

export const fetchRequestByMonth = async (view: any) =>
  await axios
    .get(
      `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/request?type=${view}
    `
    )
    .then((res) => res.data);

export const fetchRequestByStatus = () =>
  axios
    .get(
      `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/request?type=status
    `
    )
    .then((res) => res.data);

export const fetchResponseCounts = () =>
  axios
    .get(
      `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/request?type=response
      `
    )
    .then((res) => res.data);

export const fetchRequestByCategory = (view: any) =>
  axios
    .get(
      `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/request?type=category&period=${view}
      `
    )
    .then((res) => res.data);

export const fetchRequestList = () =>
  axios
    .get(
      `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/request?type=list
  `
    )
    .then((res) => res.data);

export const fetchNumberOfCustomer = async (view: any) =>
  await axios
    .get(
      `
${process.env.REACT_APP_SERVER_URL}/api/v1/company/dashboard/customer?type=${view}
`
    )
    .then((res) => res.data);
