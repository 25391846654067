import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import logo from "images/eserv.png";
import foobar from "images/TIMEServ.png";
import { useMutation } from "react-query";
import { Button, OutlineA, OutlineButton, SubtleA } from "components/button";
import { Input } from "components/input";
import { useHistory, Link, useLocation } from "react-router-dom";
import Form from "components/form2";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';

const Overlay = styled.div`
  background: #ebedef;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const Con = styled.div`
  box-shadow: 10px 10px 25px -10px grey;
  background: white;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  > form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ForgetPasswordLink = styled(Link)`
  align-self: flex-end;
  font-size: 0.8rem;
`;

const ALink = styled(Link)`
  align-self: center;
  font-size: 2rem;
  color: #ff4f00;
`;

const Alert = styled.div`
  background: #ffeded;
  border-radius: 3px;
  color: red;
  border: 1px solid red;
  text-align: left;
  padding: 1rem;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ path }) {
  const { t } = useTranslation();
  const tBase = "views.login.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [alert, setAlert] = useState("");
  const history = useHistory();

  const mutation = useMutation(
    async (e) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/user
  `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (e) => {
        setAlert(e.response.data);
      },
    }
  );

  const handleChange = (e) => {
    let { username, password } = e;
    mutation.mutate({ username: username, password: password.trim() });
  };

  const formik = useFormik({
    onSubmit: handleChange,
    initialValues: { username: "", password: "" },
  });

  return (
    <Overlay>
      <Con>
        {path === "/login" ? (
          <Form formik={formik}>
            <img
              style={{
                width: "150px",
                alignSelf: "center",
                marginBottom: "3rem",
                padding: "2rem 0",
              }}
              src={logo}
              alt="Logo"
            />
            <Input label={tr("Username")} name="username" size="large" />
            <Input
              label={tr("Password")}
              name="password"
              type="password"
              size="large"
            />
            <ForgetPasswordLink to="/forgetpassword">
              {tr("Forget password?")}
            </ForgetPasswordLink>
            <Button primary type="submit" className="my-2 mt-6">
              {tr("Sign In")}
            </Button>
            <span className="self-center text-slate-500">{tr("or")}</span>
            <OutlineA className="my-2" primary to="/signup">
              {tr("Create an Account")}
            </OutlineA>
            <ALink
              to="/time-clock"
              style={{
                width: "150px",
                height: "30px",
                marginTop: "5px",
                background: `url(${foobar})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></ALink>
          </Form>
        ) : (
          <ForgetPassword />
        )}

        {alert && <Alert>{alert}</Alert>}
      </Con>
    </Overlay>
  );
}

const ForgetPassword = () => {
  const { t } = useTranslation();
  const tBase = "views.login.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const mutation = useMutation(
    async (e) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/forgetpassword
  `,
        e
      )
  );

  const handleForgetPasswordSubmit = (e) => {
    mutation.mutate(e);
  };

  const formik = useFormik({
    initialValues: { email: "" },
    onSubmit: handleForgetPasswordSubmit,
    validationSchema: Yup.object({
      email: Yup.string().required(tr("Required")),
    }),
  });
  return (
    <ForgetPasswordCon
      formik={formik}
      className="flex flex-col justify-between space-y-2 grow"
    >
      <SubtleA to="/login" size="small" primary className="self-start !px-0">
        {tr("< Back to Log In")}
      </SubtleA>
      {mutation.isSuccess ? (
        <span>
          {tr("We have sent a temporary password to the email-address you submitted. You should be receiving it shortly. You will only receive the temporary password if an account by that email exists.")}
        </span>
      ) : (
        <>
          <span>
            {tr("Please enter the email address of the account you have forgotten. If the email exists in the database, you will receive a temporary password.")}
          </span>
          <Input
            label={tr("Email")}
            size="large"
            name="email"
            required
            type="email"
          />
          <Button primary size="large" type="submit" className="!mt-auto">
            {tr("Reset Password")}
          </Button>
        </>
      )}
    </ForgetPasswordCon>
  );
};

const ForgetPasswordCon = styled(Form)``;
