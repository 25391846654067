import React, { createContext, useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import { GoThreeBars } from "react-icons/go";
import { Avatar } from "components/avatar";
import { increment, cartUpdate } from "../../redux/actions";
import { usePageDispatch } from "../../redux/reducers/pageContext";
import { Button, SubtleButton, SubtleIconButton } from "components/button";
import { Link } from "components/link";
import logoLong from "images/eserv.png";
import axios from "axios";
import Popper from "components/react-popperv2";
import DrawerCon from "./drawer";
import { Menu } from "components/menu";
import { useAuthContext } from "contexts/auth";
import { IconButton } from "components/button";
import { IoMdNotificationsOutline } from "react-icons/io";
import Notification from "components/notification";
import NotificationContainer from "views/notification";
import TimeConfirmModal from "views/time-clock/TimeConfirmModal";
import ConfirmstatusModal from "views/time-clock/ConfirmStatusModal";
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { theme } from "styles/theme";
import { RiAddFill, RiAlertFill } from "react-icons/ri";
import { useModalContext } from "components/modal";
import AlertModal from "./container/alertModal";
import { Input } from "components/input";
import CreateModal from "views/home/components/createModal";
import useScrollObserver from "hooks/useScrollObserver";
import { OnBoardingMenuItem } from "./OnBoardingMenuItem";
import TooltippedElement from "components/TooltippedElement";
import OnboardingNavIcon from "features/onboarding-wizard/components/OnboardingNavIcon";
import usIcon from "images/lang-icons/us.png"; // icon by Icon.doit from flaticon.com
import spainIcon from "images/lang-icons/spain.png"; // icon by Icon.doit from flaticon.com
import { useTranslation } from 'react-i18next';

const Navbar = styled.div`
  position: sticky;
  top: 0;
  background: white;
  flex: 0 0 ${theme.height.navigation};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // box-shadow: 0 0 10px -5px #34495e;
  padding: 0 0.5rem;
  z-index: ${theme.zIndex.navigation};
  > button:nth-last-child(1) {
    // margin-left: auto;
  }
  > div:nth-last-child(2) {
    margin: 0 1rem;
  }
`;
const Linkp = styled.div`
text-decoration: none;
cursor: pointer;
display: flex;
padding: 0.5rem 1rem;
align-items: center;
font-weight: ${(props) => (props.selected ? "bold" : "500")};
transition: all linear 0.1s;
color: ${(props) => (props.color ? props.color : "black")};
font-size: ${(props) => theme.fontSize[props.size]};
&:hover {
  background: #ebedef;
}
> svg {
  font-size: 1.1rem;
  margin-right: 0.5rem;
}
background: ${(props) => (props.selected ? "#EBEDEF" : "transparent")};
`;

const fetchNotifications = () =>
  axios
    .get(
      `
${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/notification
`
    )
    .then((res) => res.data);

function Nav() {
  const pageDispatch = usePageDispatch();
  const history = useHistory();
  const { name, pin, logout, company_id, user_id } = useAuthContext();
  const notifications = useQuery("notifications", fetchNotifications);
  const { setModal } = useModalContext();
  const [anchor, setAnchor] = useState(false),
    scrollObserver = useScrollObserver(),
    isFetching = useIsFetching();
  const node = useRef(null);

  const { t, i18n } = useTranslation();
  const tBase = "views.navigation.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const authContext = useAuthContext();
  const linkClicked = (x) => {
    localStorage.clear();
    pageDispatch(increment(x));
    setAnchor(false);
  };
  const userdata = async(x)=>{
    return await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business-unprotected/time_clock/userinfo?pin=${x}`,
    ).then((res)=>{
      return res.data
    })
  };

  // TODO: ES-1121 remove when done testing
  const [test, setTest] = useState(false);
  useEffect(() => {
    if (authContext && authContext.role === process.env.REACT_APP_ROLE) {
      setTest(true);
    }
  }, []);

  return (
    <Navbar className={`border-b ${scrollObserver > 0 && "drop-shadow-md"}`}>
      {isFetching ? (
        <>
          <LoadingBar />
        </>
      ) : null}
      <SubtleIconButton onClick={() => setAnchor(true)} size="large">
        <GoThreeBars />
      </SubtleIconButton>

      <div style={{ position: "relative" }}>
        <TooltippedElement
        message={tr("Home")}
        position="right"
        element={
        <img
          alt="ESERV"
          src={logoLong}
          style={{ margin: "0 0.5rem", height: "20px" ,cursor:'pointer'}}
          onClick={() => history.push("/")}
        />}/>
      </div>

      <SRightButtonCon>
        <OnBoardingMenuItem />
        <OnboardingNavIcon />
        
        {/* Language Selector */}
        {/* TODO: ES-1121 remove when done */}
        <TooltippedElement
          message={tr("Language")}
          element={
            <Popper
              clickable
              reference={(props) => (
                <div className="relative w-10 h-10">
                  <button onClick={props.open}>
                    {i18n.language && i18n.language === "en" ? (
                      <img alt="ESERV" src={usIcon} />
                    ) : (
                      <img alt="ESERV" src={spainIcon} />
                    )}
                  </button>
                </div>
              )}
              container={(props) => (
                <div className="flex flex-col divide-y">
                  <button 
                    className="flex items-center justify-between px-2 py-0.5 hover:bg-[#ebedef]"
                    onClick={() => i18n.changeLanguage("en")}
                  >
                    {tr("English")} <img alt="ESERV" src={usIcon} className="w-10 h-10 ml-2" />
                  </button>
                  <button 
                    className="flex items-center justify-between px-2 py-0.5 hover:bg-[#ebedef]"
                    onClick={() => i18n.changeLanguage("es")}
                  >
                    {tr("Spanish")} <img alt="ESERV" src={spainIcon} className="w-10 h-10 ml-2" />
                  </button>
                </div>
              )}
              options={{ placement: "bottom-end", noWidth: true }}
            />
          }
        /> 

        <TooltippedElement
        message={tr("New")}
        element={
        <IconButton
          primary
          onClick={() =>
            setModal({ component: <CreateModal />, label: "Create" })
          }
        >
          <RiAddFill />
        </IconButton>}/>
        {/* <IconButton
          style={{ color: theme.color.main }}
          onClick={() =>
            setModal({ component: <AlertModal />, label: "Alert" })
          }
        >
          <RiAlertFill />
        </IconButton> */}
        <Popper
          clickable
          reference={(props) => (
            // <Notification
            //   count={notifications.data?.filter((e) => !e.is_viewed).length}
            // >
            <TooltippedElement
            message={tr("Notifications")}
            element={<IconButton onClick={props.toggle}>
              <IoMdNotificationsOutline />
            </IconButton>}/>
            // </Notification>
          )}
          container={(props) => (
            <NotificationContainer
              disable={props.disable}
              payload={notifications.data}
            />
          )}
        />

        <Popper
          clickable
          reference={(props) => (
            <div style={{position:'relative'}}>
              <Avatar user_id={user_id} onClick={props.open}>
              {name}
            </Avatar>
            </div>
          )}
          container={(props) => (
            <Menu style={{ minWidth: "200px" }}>
              <Link onClick={props.close} to="/profile/personal">
                {tr("Profile")} / {tr("Edit Profile")}
              </Link>
              <Linkp
                onClick={async() => {userdata(pin).then((a)=>{
                      setModal({
                  component: <ConfirmstatusModal data={{...a[0], pin:pin}}/>,
                  label: tr("Confirm"),
                  props: { shouldCloseOnOverlayClick: false },
                })
                  })}}
                >
              {tr("Change Status")} </Linkp>
              <Linkp 
              onClick={async() => {
                    userdata(pin).then((a)=>{setModal({
                  component: <TimeConfirmModal data={{...a[0], pin:pin}}/>,
                  label: tr("Confirm"),
                  props: { shouldCloseOnOverlayClick: false },
                })
                  })}}>
                  {tr("Clock In")} / {tr("Out")}
              </Linkp>
              <Link to="/" color="red" onClick={logout}>
                {tr("Logout")}
              </Link>
              
            </Menu>
          )}
          options={{ placement: "bottom-end" }}
        />
      </SRightButtonCon>

      <DrawerCon {...{ anchor, setAnchor, linkClicked }} />
    </Navbar>
  );
}

export default Nav;

const SRightButtonCon = styled.div`
  display: flex;
  margin-left: auto;
  > *:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

const LoadingAnimation = keyframes`
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`;

const LoadingBarCon = styled.div`
  position: absolute;
  width: 100vw;
  height: 4px;
  background: black;
  top: 0;
  left: 0;
`;

const LoadingBar = styled.div`
  position: absolute;
  width: 100vw;
  height: 4px;
  background: #ff4f00;
  top: 0;
  left: 0;
  transform-origin: 0% 50%;
  animation: ${LoadingAnimation} 1s infinite linear;
`;
