import React, { useState, useEffect, useRef } from "react";
import Table from "components/table";
import {
	RiEdit2Line,
	RiErrorWarningLine,
	RiQuestionLine,
	RiStickyNoteLine,
} from "react-icons/ri";
import {Avatar} from "components/avatar";
import {
	RiCheckLine,
	RiTimeLine,
	RiUser2Line,
} from "react-icons/ri";
import TooltippedElement from "components/TooltippedElement";
import EditPunchModal from "./EditPunchModal";
import { useModalContext } from "components/modal";
import { FaRegClock } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


const GroupPunchTable = (props) => {
	const { t } = useTranslation();
	const tBase = "views.time_dashboard.GroupPunchTable";
	const tr = (key) => t(`${tBase}.${key}`);
	
    const { setModal } = useModalContext();
	const [timesToColor, setTimesToColor] = useState([]);
    const refreshinfo = props.refreshinfo;
	const [searchInput, setSearchInput] = useState("");

    const columns = {
		name: {
			label: (
				<p>
					<RiUser2Line style={{ display: "inline" }} />
					{tr("Name")}:
				</p>
			),
			cell:(row)=>{
				if (row.name.includes("----") === false) {
					
				return <div style={{width:'50%', display:'inline-block'}}>
				<Avatar user_id={row.userId} showLabel={true} size='small'  >
					{row.name}
				</Avatar>
				</div>
				}else{
					return row.name
				}
			},
			isClickable: false,
		},
		note: {
			label: (
				<p>
					<RiStickyNoteLine style={{ display: "inline" }} />
					{tr("Notes")}:
				</p>
			),
			isClickable: false,
			style: {width: "15em"}
		},
		time: {
			label: (
				<p>
					<RiTimeLine style={{ display: "inline" }} />
					{tr("Time")}:
				</p>
			),
			isClickable: false,
		},
		event_type: {
			label: (
				<p>
					<RiCheckLine style={{ display: "inline" }} />
					{tr("Punch")}:
				</p>
			),
			isClickable: false,
		},
		completed: {
			label: (
				<p>
					<RiQuestionLine style={{ display: "inline" }} />
					{tr("Condition")}:
				</p>
			),
			isClickable: false,
			cell: (row, groupPunchKey) => { 
				if(row.event_type.includes('--')===true){
					return (
						<p>------------</p>
					)
				}
				if (row.completed === "false") {
					if (row.event_type==='CLOCK OUT') {
						return <TooltippedElement
							message={tr("This punch has no corresponding punch in")}
							element=<RiErrorWarningLine style={{ margin: "auto", color: 'red' }} />
						/>;
					} else {
						return <TooltippedElement
							message={tr("This punch has no corresponding punch out")}
							element=<RiErrorWarningLine style={{ margin: "auto", color: 'red' }} />
						/>;
					}
				} else {
					if (has12hrgap(row, groupPunchKey) === true) {
						// console.log("has 6hr gap: ", has12hrgap(row, groupPunchKey))
						return <TooltippedElement
						message={tr("6 hour gap between punches")}
						element=<FaRegClock style={{ margin: "auto", color: 'red' }} />
						/>
						
					// }
					// return (
					// 	<TooltippedElement
					// 	message='No errors'
					// 	element= <RiCheckLine style={{ margin: "auto", color: 'green' }} />
					// 	/>
					// );
				}
			}
		},
	},
	};

    const has12hrgap = (row, key) => {
		if (row.event_type === 'CLOCK IN') {

			// find the index of the current punch
			const index = props.GroupedPunches[key].findIndex((punch) => new Date(punch.time).toLocaleString() === new Date(row.time).toLocaleString())
			
			if (row.event_type === 'CLOCK IN' && props.GroupedPunches[key][index - 1]) {
				// console.log("evaluated punch: ", new Date(row.time))
				// console.log("next punch: ", new Date(props.GroupedPunches[key][index - 1].time)) 
				// if the absolute value of the difference between the current punch and the previous punch is greater than 6 hours
				if (Math.abs(new Date(row.time) - new Date(props.GroupedPunches[key][index - 1].time)) > 21600000) {
					return true
				}
			}
		};
	}
    const tableRef = useRef(null);

    let scrollPos = 0
    window.addEventListener('scroll', () => {
        scrollPos = document.documentElement.scrollTop
    });

    const handlePageChange = (pageIndex, name) => {
        const newTableContent = tableContent.map((row) => {
            if (row.name === name) {
                return {
                    ...row,
                    pageIndex
                };
            }
            return row;
        });

        // const scrollPos = document.documentElement.scrollTop + 1000

        setTableContent(newTableContent);

        window.scrollTo(0, scrollPos)
    };

    const [tableContent, setTableContent] = useState(() => {
        return Object.keys(props.GroupedPunches).map(key => ({
            name: key,
            pageCount: Math.ceil(props.GroupedPunches[key].length / props.recordsDisplayed),
            pageIndex: 1
        }));
    });


    React.useEffect(() => {
        setTableContent(Object.keys(props.GroupedPunches).map(key => ({
            name: key,
            pageCount: Math.ceil(props.GroupedPunches[key].length / props.recordsDisplayed),
            pageIndex: 1
        })));
    }, [props.GroupedPunches])

    const getSlicedDataWithFillers = (key, tableContent, props) => {
        if (!tableContent.find(row => row.name === key)) return [];
        const pageIndex = tableContent.find(row => row.name === key).pageIndex;
        const start = (pageIndex - 1) * props.recordsDisplayed;
        const end = pageIndex * props.recordsDisplayed;
        let slicedData = props.GroupedPunches[key].slice(start, end);

		// TODO: consider moving this time conversion upstream to where the data is being fetched
		slicedData = slicedData.map((row) => {
			return {
				...row,
				time: new Date(row.time).toLocaleString(),
			};
		}
		)
    
        // if (slicedData.length < 10) {
        //     const numFillers = 10 - slicedData.length;
        //     for (let i = 0; i < numFillers; i++) {
        //         slicedData.push({ name: "----", time: "----", event_type: "----", completed: "----" });
        //     }
        // }
    
        return slicedData;
    };

    const getPageCount = (key, tableContent) => {
        if (!tableContent.find(row => row.name === key)) return 0;
        return tableContent.find(row => row.name === key).pageCount;
    }

    const getPageIndex = (key, tableContent) => {
        if (!tableContent.find(row => row.name === key)) return 0;
        return tableContent.find(row => row.name === key).pageIndex;
    }

	const handleSearchChange = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchInput(value);
	}

	const handleClick = (row) => {
		setModal({
			component: <EditPunchModal row={row} refreshinfo={()=>refreshinfo()} isGroupPunch {...{setTimesToColor}} />,
			label: tr("Punch Options"),
			props: {
				shouldCloseOnOverlayClick: true,
			},
		});
	}

    return (
        <div>
            <input
				style={{width: "20%", margin:"10px", float:"right", border:"1px solid black", borderRadius: "10px", padding: "10px"}}
				onChange={handleSearchChange} 
				placeholder={tr("Employee Name")}
			 />
            {Object.keys(props.GroupedPunches).map((key) => (
                props.GroupedPunches[key].length > 0 && key.toLowerCase().includes(searchInput) &&
                <div style={{padding: "40px"}} ref={tableRef}>
                    <h1 style={{marginBottom:"20px"}}>{key}</h1>
                    <Table
                        key={key}
                        data={getSlicedDataWithFillers(key, tableContent, props)}
                        columns={columns}
                        isTimedashboard={true}
                        pageCount={getPageCount(key, tableContent)}
                        pageIndex={getPageIndex(key, tableContent)}
                        bubbleId="descriptionBubble"
                        handlePage={handlePageChange}
                        groupPunchKey={key}
						handleClick={handleClick}
						timesToColor={timesToColor}
                    />
                </div>
            ))}
        </div>
    );
}

export default GroupPunchTable;
