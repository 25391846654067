import * as React from "react";
import ReactSelect from ".";
import { Props } from "react-select";
import { useQuery } from "react-query";
import axios from "axios";
import { TTax } from "types/tax";
import Field from "components/field";
import { useTranslation } from 'react-i18next';

type TProps = Omit<Props, "value"> & {};

const taxes = [
  { value: "tax", label: "Tax" },
  { value: "discount", label: "Discount" },
  { value: "fee", label: "Fee" },
];
export default function TaxTypeSelect(props: TProps) {
  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const val = taxes.find((tax) => tax.value === props.data);

  return (
    <Field
      type="select"
      style={{ width: "100%" }}
      options={taxes}
      name="select"
      placeholder={tr("Select tax type")}
      {...props}
      defaultValue={"tax"}
      value={val}
    />
  );
}
