import * as React from "react";
import styled from "styled-components";
import Switch from "rc-switch";

import "rc-switch/assets/index.css";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useAuthContext } from "contexts/auth";
import { OutlineButton } from "components/button";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from 'react-i18next';

export default function Onboarding() {
  const { t } = useTranslation();
  const tBase = "views.home.container.onboarding";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const auth = useAuthContext();
  const getPublicAccess = useQuery(
    "publicAccess",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/public-access
    `
        )
        .then((res) => res.data)
  );

  const getStripeLink = useMutation(
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/stripe/link
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        window.location.replace(data.url);
      },
    }
  );

  const changePublicAccess = useMutation(
    async (body: { value: boolean }) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/public-access
    `,
        body
      ),
    {
      onSuccess: () => {
        getPublicAccess.refetch();
      },
    }
  );

  const [doneThings, setDoneThings] = React.useState<string[]>([]);
  const totalThings = 4;

  const handleChange = (value: boolean) => {
    changePublicAccess.mutate({ value });
  };
  return (
    <Con className="overflow-hidden divide-y rounded-lg shadow-md">
      {!auth.charges_enabled && (
        <div className="flex justify-between p-4 bg-blue-100/50">
          <div>
            <span className="text-sm font-bold">{tr("Stripe Payment Processing")}</span>
            <p className="text-slate-600">
              {auth.owner
                ? tr("Complete your Stripe sign up process to start receiving payment")
                : tr("Company has not completed stripe onboarding process. Please have your admin to update the account")}
            </p>
          </div>
          {auth.owner && (
            <OutlineButton
              primary
              className="!bg-transparent"
              isLoading={getStripeLink.isLoading}
              onClick={() => getStripeLink.mutate()}
            >
              {tr("Complete Process")}
            </OutlineButton>
          )}
        </div>
      )}
      <div>
        <div className="flex justify-between p-4">
          <span>{tr("Allow public to submit request")}</span>
          {/* {console.log(auth)} */}
          <Switch
            disabled={
              !auth.permissions?.administrative?.toggle_public_access &&
              !auth.owner
            }
            checked={getPublicAccess.data}
            onChange={handleChange}
            onClick={handleChange}
          />
        </div>
      </div>
    </Con>
  );
}

const Con = styled.div`
  /* border-radius: 5px; */
  /* box-shadow: 0 10px 20px -10px #85929e; */
  margin-bottom: 1rem;
`;
