import axios from "api/axios";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { TEstimateOptions } from "types/estimate";
import { EachOption } from "views/projects/views/project/views/quote/components/submitQuoteModal";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  options: TEstimateOptions[] | null;
};

export default function MarkEstimateAsWonModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.estimate.markEstimateAsWonModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [options, setOptions] = React.useState<TEstimateOptions[]>();
  const { setModal } = useModalContext(),
    { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient();

  React.useEffect(() => {
    props.options && setOptions(props.options);
  }, [props.options]);

  const mutation = useMutation(
    async (e: any) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${props.id}/award
    `,
        e
      ),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Estimate awarded!"));
        queryClient.invalidateQueries();
      },
    }
  );

  const optionSelected =
    options &&
    options.filter((option) => option.selected).map((option) => option.id);

  // .length > 0;

  const populateOptions = options?.map((option) => {
    const optionClick = (id: number) => {
      setOptions((options) => {
        let temp = cloneDeep(options);
        let index = temp?.findIndex((option) => option.id === id) ?? -1;

        if (temp && index !== -1) {
          temp[index].selected = !temp[index].selected;
        }
        return temp;
      });
    };
    return (
      <EachOption
        id={option.id}
        selected={option.selected}
        total={
          (option.price_type === "list"
            ? option.list_net_total
            : option.price_type === "sale"
            ? option.sale_net_total
            : option.install_net_total) ?? 0
        }
        label={option.label}
        key={option.id}
        optionClick={optionClick}
      />
    );
  });

  return (
    <Con>
      {populateOptions}
      <div>
        <Button
          disabled={!optionSelected || optionSelected.length <= 0}
          primary
          isLoading={mutation.isLoading}
          onClick={() =>
            mutation.mutate({
              selectedOptions: optionSelected,
              status: "accept",
              from_business: true,
            })
          }
        >
          {tr("Submit")}
        </Button>
      </div>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;
