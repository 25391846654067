import React, { useState, useEffect, useRef } from "react";
import { Modal } from "@material-ui/core";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Checkbox from "@material-ui/core/Checkbox";

import { SearchBox } from "../../components/search-box/search-box.component";
import RowList from "../../components/row-list/row-list.component";
import "./index.css";
import { useTranslation } from 'react-i18next';

const Index = () => {
  return (
    <div className="parts-container">
      <JobsTable />
    </div>
  );
};

const Category = (props) => {
  const [checked, setChecked] = useState(
    props.category === "Service (Residential)" ? true : false
  );

  const changeChecked = () => {
    if (checked) {
      const newParams = [
        ...props.filterParams.filter((param) => param !== props.category),
      ];
      props.setFilterParams(newParams);
      setChecked(false);
    } else {
      const newParams = [
        ...props.filterParams.filter((param) => param !== props.category),
        props.category,
      ];
      props.setFilterParams(newParams);
      setChecked(true);
    }
  };

  return (
    <span className="check-row">
      <div>
        <Checkbox
          checked={checked}
          onChange={changeChecked}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
      <div className="category">{props.category}</div>
    </span>
  );
};

const PartsModal = (props) => {
  const { t } = useTranslation();
  const tBase = "views.parts.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const closeJob = async (jobId) => {
    props.changeModal();

    confirmAlert({
      title: tr("Confirm Close Job"),
      message: tr("Are you sure you want to close the job?"),
      buttons: [
        {
          label: tr("Yes"),
          onClick: () => {
            axios
              .put(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/parts/${jobId}`
              )
              .then((response) => {
                props.getJobs();
                // props.changeModal();
              });
          },
        },
        {
          label: tr("No"),
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <Modal open={props.open} onClose={props.changeModal}>
      <div className="modal">
        <div className="modal-header">
          <div className="modal-header-left">
            <div className="modal-header-row">
              <div className="modal-header-item-left">{tr("Job")}:</div>
              <div className="modal-header-item-right">
                {props.job.service_fusion_job_id}
              </div>
            </div>
            <div className="modal-header-row">
              <div className="modal-header-item-left">{tr("Customer")}:</div>
              <div className="modal-header-item-right">
                {props.job.customer_name}
              </div>
            </div>
            <div className="modal-header-row">
              <div className="modal-header-item-left">{tr("Address")}:</div>
              <div className="modal-header-item-right">{props.job.address}</div>
            </div>
          </div>
          <div className="modal-header-right">
            <CloseTwoToneIcon
              onClick={props.changeModal}
              fontSize="large"
              className="modal-close-button"
            />
          </div>
        </div>
        <div className="modal-body">
          {props.job.parts && props.job.parts.length > 0 ? (
            <>
              <div className="modal-table-head-row">
                <div className="modal-table-head-col-num">{tr("Part")} #</div>
                <div className="modal-table-head-col-sf-num">SF #</div>
                <div className="modal-table-head-col-name">{tr("Name")}</div>
                <div className="modal-table-head-col-qty">{tr("Quantity")}</div>
              </div>
              {props.job.parts?.map((part, index) => {
                let partNumber = part.part_number.toString();
                let partLength = part.part_number.toString().length;

                if (partLength < 3) {
                  partNumber = partNumber.padStart(3, "0");
                }

                return (
                  <div
                    className={index % 2 === 0 ? "part-row" : "part-row-dark"}
                  >
                    <div className="part-col-num">{partNumber}</div>
                    <div className="part-col-sf-num">{part.sf_id}</div>
                    <div className="part-col-name">
                      {part.parent_category} {" -> "} {part.category} {" -> "}
                      {part.name}
                    </div>
                    <div className="part-col-qty">{part.quantity}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <div>{tr("Submitted With No Parts")}</div>
          )}
        </div>
        <div className="modal-foot">
          <button
            onClick={() => closeJob(props.job.id)}
            className="close-job-button"
          >
            {tr("Close Job")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const JobsTable = () => {
  const { t } = useTranslation();
  const tBase = "views.parts.index";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [status, setStatus] = useState("open");
  const [currentJob, setCurrentJob] = useState(jobs[0]);
  const [filterParams, setFilterParams] = useState(["Service (Residential)"]);

  const [open, setOpen] = useState(false);

  const changeModal = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getJobs = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/parts`)
      .then((response) => {
        setJobs(response.data.jobs);
      });
  };

  const buildCategories = () => {
    const unique = jobs
      .map((job) => {
        return job.category;
      })
      .filter((value, index, self) => self.indexOf(value) === index);

    setCategories(unique);
  };

  const filterJobs = () => {
    let filtered;

    filtered = jobs.filter((job) => {
      return (
        (job.service_fusion_job_id
          .toString()
          .toLowerCase()
          .includes(searchField.toLowerCase()) ||
          job.customer_name.toLowerCase().includes(searchField.toLowerCase()) ||
          new Date(job.entry_date)
            .toLocaleDateString()
            .includes(searchField.toLowerCase()) ||
          job.technician.toLowerCase().includes(searchField.toLowerCase()) ||
          job.address.toLowerCase().includes(searchField.toLowerCase())) &&
        job.status === status &&
        filterParams.includes(job.category)
      );
    });

    setFilteredJobs(filtered);
  };

  const setModalJob = (jobId) => {
    const job = jobs.filter((job) => {
      return job.id === jobId;
    });

    setCurrentJob(job[0]);
  };

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const setFilterStatus = (status) => {
    setStatus(status);
  };

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    buildCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  useEffect(() => {
    filterJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, status, searchField, filterParams]);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useInterval(() => {
    getJobs();
  }, 10000);

  return (
    <div className="parts-container">
      {currentJob ? (
        <PartsModal
          getJobs={getJobs}
          job={currentJob}
          changeModal={changeModal}
          open={open}
        />
      ) : null}

      <h3>{tr("Jobs")}</h3>
      <div>
        <button
          onClick={() => setFilterStatus("open")}
          className="filter-button"
        >
          {tr("Open")}
        </button>
        <button
          onClick={() => setFilterStatus("closed")}
          className="filter-button"
        >
          {tr("Closed")}
        </button>
      </div>
      <SearchBox placeholder={tr("Search Jobs")} handleChange={handleChange} />
      <div className="outer">
        <div className="left-panel">
          <div className="category-txt">{tr("Category")}</div>
          <div className="radios">
            {categories.map((category) => {
              return (
                <Category
                  category={category}
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  filterJobs={filterJobs}
                />
              );
            })}
          </div>
        </div>
        <div className="list">
          <RowList
            setModalJob={setModalJob}
            getJobs={getJobs}
            changeModal={changeModal}
            open={open}
            jobs={filteredJobs}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
