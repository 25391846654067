import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { OutlineButton, SubtleButton } from "components/button";
import Axios from "axios";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { RiAddFill } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Placeholder from "../../views/request/component/placeholder";
import { theme } from "styles/theme";
import Segment, { SContent, SHeader } from "components/segment";
import Appointment from "./appointment";
import AddAppointment from "./appointment/appointmentModal";
import Spinner from "components/spinner";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';

export default function AppointmentCard ({
  editable = true,
  id,
  type,
  parentId = null,
  isBookit = false,
  responses = [],
  requestId = ""
}) {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCard.index";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();
  const [expand, setExpand] = React.useState(false);

  // console.log("responses", responses);

  const queryClient = useQueryClient();

  const query = useQuery(
    ["appointments", type, id],
    async () =>
      await Axios.get(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${type}/${id}
  `
      ).then((res) => res.data)
  );

  const statusChangeMutation = useMutation(
    async ({ appointmentId, ...e }) => {
      const response = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${appointmentId}/status`,
        {
          id: e.id,
          type: e.type,
          requestId: requestId,
        }
      );
      return response.data;
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries();
        showSnackbar(tr("Appointment Status Updated!"));
      },
    }
  );

  const deleteAppointment = useMutation(
    async (id) =>
      await Axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${id}`
      ).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        showSnackbar(tr("Appointment Deleted"));
      },
    }
  );

  const handleDelete = async (id) => deleteAppointment.mutate(id);

  const handleStatusChange = (e, appointmentId) =>
    statusChangeMutation.mutate({ ...e, appointmentId });

  if (query.isLoading) return <Spinner />;

  if (!query.data) return null;

  const populateAppointments =
    query.data &&
    query.data.appointments?.map((appointment, i) => {
      return (
        <Appointment
          parentId={id}
          {...{
            appointment,
            setModal,
            handleDelete,
            handleStatusChange,
            i,
            acceptedBid: query.data.acceptedBid,
            type,
            expand,
          }}
          length={query.data.appointments.length}
          key={appointment.id}
          isBookit={isBookit}
          responses={responses}
        />
      );
    });

  return (
    <div className="divide-y">
      <div className="flex items-center justify-between py-4">
        <h2>
          {tr("Appointments")}
          <SubtleButton primary onClick={() => setExpand((s) => !s)}>
            {expand ? "Collapse" : "Expand"}
          </SubtleButton>
        </h2>
        {editable && (
          <TooltippedElement
            message={tr("Add Appointment")}
            element={
              <OutlineButton
                style={{ marginLeft: "auto" }}
                onClick={() =>
                  setModal({
                    component: (
                      <AddAppointment
                        {...{
                          parentId,
                          type,
                          id,
                          acceptedBid: query.data.acceptedBid,
                          has_est: query.data?.has_est,
                          defaultdate: Date(),
                        }}
                        closed={() => {
                          queryClient.invalidateQueries();
                        }}
                        emailDefault={true}
                        responses={responses}
                      />
                    ),
                    label: tr("Add Appointment"),
                  })
                }
              >
                <RiAddFill />
              </OutlineButton>
            }
          />
        )}
      </div>
      <div className="py-4">
        {query.data?.appointments?.length > 0 ? (
          <SContent className="divide-y !p-0">{populateAppointments}</SContent>
        ) : (
          <Placeholder
            type="appointment"
            header={tr("No appointments yet")}
            label={tr("Preassessment appointments and job appointments will appear here when added.")}
          />
        )}
      </div>
    </div>
  );
}

export const JobNumber = styled.span`
  background: ${theme.bg.blue};
  color: blue;
  font-size: 0.8rem;
  font-weight: bold;
  width: auto;
  border-radius: 25px;
  padding: 0 0.5em;
  margin-left: 0.5rem;
`;
