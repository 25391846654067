/* Created By Hannah Green on 02/10/2021 for ES-133 */
import React from 'react';
import axios from 'axios';
import { Con, DeleteStyle } from '../styles';
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';
const { Button } = require('components/button');

export default function DeleteGroup(props) {
    const { t } = useTranslation();
    const tBase = "views.warehouse_management.components.deleteGroup";
    const tr = (key) => t(`${tBase}.${key}`);

    const { id, name, closePopup } = props;

    const { setModal } = useModalContext();

    const deleteGroup = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/warehouse`,
                {
                    type: 'DELETEGROUP',
                    id,
                }
            );
            setModal();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Con>
            <DeleteStyle>
                <div>
                    <label>
                        {tr("Are you sure you want to delete")} {name}?
                    </label>
                    <div>
                        <Button type="submit" onClick={() => { deleteGroup() }}>{tr("Yes")}</Button>
                        <Button type="button" onClick={() => { closePopup() }}>No</Button>
                    </div>
                </div>
            </DeleteStyle>
        </Con>
    );
}