/* Created by Hannah Green on 5/11/2021 for ES-147 */
import React, { useState } from "react";
import styled from "styled-components";

const Content = styled.div`
    @media only screen and (max-width: 1000px){
            width: 100%;

            > img {
                max-width: 700px;
            }
    }

    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
    max-width: 700px;
    animation-name: zoom;
    animation-duration: 0.6s;

    > img {
        max-width: 700px;
    }
`;

const Con = styled.div`
    @keyframes zoom {
        from {transform: scale(0.1)} 
        to {transform: scale(1)}
    }
`;

const Modal = styled.div`
    display: ${(props) => props.display};
    position: fixed; 
    z-index: 1; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.8); 
`;

const CloseButton = styled.span`
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

    &:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }
`;

const OverlayModal = (props) => {
    return (
        <Modal id="ImageModal" display={props.display}>
            <CloseButton onClick={() => {
                props.closeModal("none");
            }}>&times;</CloseButton>
            <Content><img src={props.image} alt="Broken Item" /></Content>
        </Modal>
    )
}

export default function ImageZoom(props) {
    const [display, setDisplay] = useState("none");
    const image = props.image;

    return (
        <Con onClick={() => {
            if (display === "block") {
                setDisplay("none");
            } else {
                setDisplay("block");
            }
        }}>
            <img src={props.image} alt="Broken Item" width="100" height="100" />
            <OverlayModal image={image} display={display} closeModal={setDisplay} />
        </Con>
    )
}