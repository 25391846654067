import * as React from "react";
import Tooltip from "./tooltip";
import styled from "styled-components";

type ContextProps = {
  setStep: (ref: any, i: number) => void;
  steps: any;
  start: () => void;
};
const TourContext = React.createContext<ContextProps | null>(null);

function TourProvider({ children }: { children: React.ReactNode }) {
  const [steps, setSteps] = React.useState<any>([
    {
      ref: null,
      placement: "right",
      label: (
        <>
          <p>
            Create new category or click existing category to edit/view
            questions.
          </p>
          <p>
            Categories allow customer requests to be grouped together to ease
            with management.
          </p>
          <p>
            Questions in "Primary" will be the general questions that every
            customers will go through when they are submitting a request.
          </p>
        </>
      ),
      next: (ref: any) => {
        ref.click();
      },
    },
    {
      ref: null,
      placement: "bottom",
      label: (
        <p>
          This is where you add new question to the specific question category
        </p>
      ),
      next: (ref: any) => {
        ref.click();
      },
    },
    {
      ref: null,
      placement: "bottom",
      label: "This is a question container",
      next: (ref: any) => {
        ref.click();
      },
    },
    {
      ref: null,
      placement: "bottom",
      label: (
        <>
          <p>
            You can change the type of questions however you like. The current
            supported options include:
          </p>
          <p>Single Choice, Multiple Choices, Image Upload, and Short Answer</p>
        </>
      ),
    },
    {
      ref: null,
      placement: "bottom",
      label: (
        <>
          <p>
            If you would like this question to categorize the customer request,
            activate this function.
          </p>
          <p>
            Ex: The title of the question is "Are you looking for a service
            repair or a new installation?" and the customer has selected
            "Service Repair" as their answer of the question. The questions from
            "Service Repair" category will be added to the request
            automatically.
          </p>
        </>
      ),
    },
    {
      ref: null,
      placement: "bottom",
      label: (
        <>
          <p>To save the changes made, click here.</p>
        </>
      ),
    },
  ]);
  const [stepIndex, setStepIndex] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const step = steps[stepIndex];

  const next = (ref: any) => {
    step.next && step.next(ref);

    setStepIndex((i) => {
      let next = i + 1;
      if (next > steps.length - 1) {
        setIsActive(false);
        return 0;
      }
      return next;
    });
  };

  React.useEffect(() => {
    if (isActive && step.ref) {
      if (step.type === "button") {
        // step.ref.onclick = () => next();
        // return () => {
        //   step.ref.onclick = null;
        // };
      } else if (step.type === "input") {
        step.ref.focus();
        return () => {};
      }
    }
  }, [steps, isActive, stepIndex]);

  const setStep = React.useCallback((ref: any, i: number) => {
    if (ref) {
      setSteps((s: any) => {
        if (s[i].ref === ref) return s;
        let temp = [...s];
        temp[i].ref = ref;
        return temp;
      });
    }
  }, []);

  const start = () => setIsActive(true);
  const close = () => {
    setIsActive(false);
    setStepIndex(0);
  };

  return (
    <TourContext.Provider value={{ steps, setStep, start }}>
      {children}
      {isActive && (
        <Tooltip
          refs={steps[stepIndex].ref}
          index={stepIndex}
          length={steps.length}
          placement={step.placement}
          next={() => next(step.ref)}
          close={close}
          content={<div>{steps[stepIndex].label}</div>}
        />
      )}
    </TourContext.Provider>
  );
}

function useTour() {
  return React.useContext(TourContext);
}

export { useTour, TourProvider };
