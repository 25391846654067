import react, { useState } from "react";
import axios from "axios";
import {
  Button,
  OutlineA,
  SubtleButton,
  SubtleIconA,
  SubtleIconButton,
} from "components/button";
import Field from "components/field";
import { StyledLabel } from "components/input/style";
import Assignee from "components/react-select/assignees";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import { useFormik } from "formik";
import Switch from "rc-switch";
import * as React from "react";
import { RiCloseLine } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TCustomDoc, TCustomDocForm } from "types/custom-doc";
import * as Yup from "yup";
import { SliderPicker } from "react-color";
import { useTranslation } from 'react-i18next';

const docTypes = [
  { value: "estimate", label: "Estimate" },
  { value: "invoice", label: "Invoice" },
];
export default function CustomDocument() {
  const { t } = useTranslation();
  const tBase = "views.custom_document.index2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [colorMsg, setColorMsg] = useState<string>("");
  const [lastColor, setLastColor] = useState<string | null>(null);
  const params = useParams<{ id: string }>(),
    history = useHistory(),
    snackbar = useSnackbarContext();

  const query = useQuery<TCustomDoc>(
    ["custom document", params.id],
    async () =>
      params.id !== "new" &&
      (await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-doc/${params.id}
  `
        )
        .then((res) => res.data)),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        formik.resetForm();
      },
    }
  );

  const mutation = useMutation(
    async (e: TCustomDocForm) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-doc/${params.id}
  `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        query.refetch();
        snackbar.showSnackbar(
          params.id === "new"
            ? tr("Custom document created!")
            : tr("Custom document updated!")
        );
        history.push(`/custom-docs/${id}`);
      },
    }
  );

  // console.log("query", query.data)

  const initialValues: TCustomDocForm = {
      label: query.data?.label ?? "",
      products: query.data?.products ?? true,
      services: query.data?.services ?? true,
      terms_conditions: query.data?.terms_conditions ?? "",
      type: query.data?.type ?? "estimate",
      total_only: query.data?.total_only ?? false,
      checklists: query.data?.checklists || [],
      long_description: query.data?.long_description ?? true,
      short_description: query.data?.short_description ?? true,
      name: query.data?.name ?? true,
      assignees: [1],
      color: query.data?.color ?? null,
      include_images: query.data?.include_images ?? false,
      include_qty: query.data?.include_qty ?? false,
      include_line_total: query.data?.include_line_total ?? false,
    },
    onSubmit = (e: TCustomDocForm) => {
      mutation.mutate(e);
    },
    validationSchema = Yup.object({
      label: Yup.string().required(),
      type: Yup.string().required(),
      products: Yup.boolean().required(),
      services: Yup.boolean().required(),
      name: Yup.boolean().required(),
      terms_conditions: Yup.string().nullable(),
      total_only: Yup.boolean(),
      checklists: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          label: Yup.string().required(),
        })
      ),
      long_description: Yup.boolean().required(),
      short_description: Yup.boolean().required(),
      color: Yup.string().nullable(),
      include_images: Yup.boolean().required(),
      include_qty: Yup.boolean().required(),
      include_line_total: Yup.boolean().required(),
    });

  const formik = useFormik<TCustomDocForm>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  if (query.isLoading) return <Spinner />;

  const populateChecklists = formik.values.checklists?.map((checklist, i) => {
    return (
      <div key={i} className="flex">
        <Field
          value={checklist.label}
          name={`checklists.${i}.label`}
          formik={formik}
          className="flex-1"
        />
        <SubtleIconButton
          className="!text-red-600"
          onClick={() => {
            let temp = [...formik.values.checklists!];
            temp.splice(i, 1);
            formik.setFieldValue("checklists", temp);
          }}
        >
          <RiCloseLine />
        </SubtleIconButton>
      </div>
    );
  });

  const handleColorChange = (color: any) => {
    formik.setFieldValue("color", color.hex);
    setLastColor(color.hex); // Update lastColor only when a color is picked
    setColorMsg("");
  };

  const handleDefaultColorSelection = (e: any) => {
    const useDefault = e.target.checked;
    if (useDefault) {
      formik.setFieldValue("color", null);
      setColorMsg(tr("You have selected the default color"));
    } else if (lastColor) {
      formik.setFieldValue("color", lastColor); // Restore the last selected color
      setColorMsg("");
    }
  };

  return (
    <div className="flex flex-col">
      <div className="sticky border-b top-0 height-[50px] z-50 bg-white flex p-2 justify-between items-center">
        <div className="space-x-4 flex items-center">
          <SubtleIconA to="/admin/custom-docs">
            <RiCloseLine />
          </SubtleIconA>
          <span>
            {params.id === "new"
              ? tr("Create Custom Document")
              : `${tr("Edit Custom Document")} #${params.id}`}
          </span>
        </div>

        <div className="space-x-2">
          <Button
            primary
            type="submit"
            isLoading={mutation.isLoading}
            onClick={() => formik.handleSubmit()}
            disabled={!formik.dirty || !formik.isValid}
          >
            {params.id === "new" ? tr("Create Custom Document") : tr("Save Changes")}
          </Button>
          <OutlineA primary to="/admin/custom-docs">
            {tr("Cancel")}
          </OutlineA>
        </div>
      </div>
      <SafeArea>
        <form onSubmit={formik.handleSubmit} className="space-y-4 py-4">
          <Field label={tr("Label")} name="label" type="input" formik={formik} />
          <Field
            type="select"
            label={tr("Type")}
            onChange={(e: any) => {
              formik.setValues((v) => ({ ...v, type: e.value }));
            }}
            value={docTypes.find((doc) => doc.value === formik.values.type)}
            name="type"
            options={docTypes}
          />
          <h3>{tr("Settings")}</h3>
          <div className="flex space-x-2 items-center">
            <Switch
              checked={formik.values.total_only}
              onChange={(e) => {
                formik.setFieldValue("total_only", e);
              }}
            />
            <label>{tr("Show Total Only")}</label>
          </div>
          {!formik.values.total_only && (
            <>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="products"
                  checked={formik.values.products}
                  onChange={formik.handleChange}
                />
                <StyledLabel>
                  {tr("Show Parts")}
                  <span className="text-gray-500 text-[13px]">
                    {" "}
                    ({tr("If unselected will show summary of parts if any exist")})
                  </span>
                </StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="services"
                  checked={formik.values.services}
                  onChange={formik.handleChange}
                />
                <StyledLabel>
                  {tr("Show Services")}
                  <span className="text-gray-500 text-[13px]">
                    {" "}
                    ({tr("If unselected will show summary of services if any exist")})
                  </span>
                </StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="name"
                  checked={formik.values.name}
                  onChange={formik.handleChange}
                />
                <StyledLabel>{tr("Line Item Name")}</StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="long_description"
                  checked={formik.values.long_description}
                  onChange={formik.handleChange}
                />
                <StyledLabel>{tr("Line Item Long Description")}</StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="short_description"
                  checked={formik.values.short_description}
                  onChange={formik.handleChange}
                />
                <StyledLabel>{tr("Line Item Short Description")}</StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="include_images"
                  checked={formik.values.include_images}
                  onChange={formik.handleChange}
                />
                <StyledLabel>{tr("Line Item Images")}</StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="include_qty"
                  checked={formik.values.include_qty}
                  onChange={formik.handleChange}
                />
                <StyledLabel>Show Line Item Rate</StyledLabel>
              </div>
              <div className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  name="include_line_total"
                  checked={formik.values.include_line_total}
                  onChange={formik.handleChange}
                />
                <StyledLabel>Show Line Item Total</StyledLabel>
              </div>
            </>
          )}
          <div className="flex items-center">
            <h3>{tr("Color")}</h3>
            <div className="ml-2 text-gray-500 text-[13px]">({tr("Select a color that best represents your company")})</div>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="checkbox"
              name="color"
              checked={formik.values.color === null}
              onChange={handleDefaultColorSelection}
            />
            <StyledLabel>
              {tr("Default")}
              {/* {colorMsg && (
                <span className="text-red-500 ml-2">
                  Please select a color
                </span>
              )} */}
            </StyledLabel>
          </div>
          <div>
            <input
              type="input"
              name="colorHex"
              className="border border-gray-300 rounded-md w-20 indent-1 text-gray-600"
              maxLength={7}
              placeholder={tr("Color Hex")}
              value={formik.values.color ?? ""}
              onChange={(e) => {
                formik.setFieldValue("color", e.target.value);
                setLastColor(e.target.value);
                setColorMsg("");
              }}
            />
          </div>
          <SliderPicker
            color={formik.values.color ?? undefined}
            onChange={handleColorChange}
          />
          <div>
            <div>
              <h3>{tr("Assignees")}</h3>
            </div>
            <div>
              <Assignee
                value={query.data?.assignees}
                onChange={(e: any) => {}}
              />
            </div>
          </div>
          <div className="space-y-2">
            <div>
              <h3>{tr("Checklists")}</h3>
            </div>
            {populateChecklists}
            <div>
              <SubtleButton
                onClick={() =>
                  formik.setFieldValue(
                    "checklists",
                    formik.values.checklists
                      ? [...formik.values.checklists, { id: "new", label: "" }]
                      : [{ id: "new", label: "" }]
                  )
                }
              >
                {tr("Add Checklist")}
              </SubtleButton>
            </div>
          </div>

          <Field
            type="textarea"
            name="terms_conditions"
            label={tr("Terms And Conditions")}
            minRows={10}
            formik={formik}
          />
        </form>
      </SafeArea>
    </div>
  );
}
