import CustomerTag from "../customer/customer-tag";

function PropertyTagList(props) {
  // console.log(props.tags, "props.tags");
  if (!props.tags) return null;

  return (
    <div style={{ display: "flex", flexDirection: "row", marginLeft: "5px" }}>
      {props.tags?.map((tag) => {
        return (
          <CustomerTag
            style={{ marginLeft: "5px" }}
            key={tag.label}
            color={tag.color}
            label={tag.label}
          />
        );
      })}
    </div>
  );
}

export default PropertyTagList;
