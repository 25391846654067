/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import styled from "styled-components";
// import TextField from '@material-ui/core/TextField'
import Button from "@material-ui/core/Button";
// import { IoMdAddCircle } from 'react-icons/io'
import { useHistory } from "react-router-dom";
import ImageUploader from "./imageuploader";
import axios from "axios";
import Input from "components/searchableDropdown";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  width: calc(100% - 1rem);
  text-align: left;
  max-width: 1200px;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5rem;
  > * {
    margin: 0.5rem;
  }
`;
const Title = styled.div`
  font-size: 1.5rem;
  color: grey;
`;

// const ImageCon = styled.div`
//     // border: 1px solid black;
//     max-width: 250px;
//     border-radius: 5px;
//     height: 500px;
//     width: 100%;
//     background: #F3F3F3;
//     align-items: center;
//     display: flex;
//     justify-content: center;
// `
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  > div {
    margin-bottom: 1rem;
    width: 100%;
  }
`;

// const InputCon = styled.div`
//     position: relative;
//     width: 100%;
//     > label {
//         position: absolute;
//     }
//     > input {
//         margin-bottom: 2rem;
//         padding: 0.5rem 1rem 0.5rem 1rem;
//         border-radius: 5px;
//         border: 1px solid black;
//         &:focus {
//             outline: none;
//         }
//     }
// `

// const Texts = styled(TextField)`
//     margin-bottom: 1rem;
//     margin: 1rem;
//     padding: 1rem;
// `

const Buttons = styled(Button)`
  width: 100%;
  max-width: 400px;
  background: black;
  color: green;
`;

// const Add = styled(IoMdAddCircle)`
//     font-size: 5rem;
//     color: grey;
// `

export default function () {
  const { t } = useTranslation();
  const tBase = "views.team.edit.newuser";
  const tr = (key) => t(`${tBase}.${key}`);
  
  // const [ first, setFirst ] = useState('')
  // const [ last, setLast ] = useState('')
  // const [ title, setTitle ] = useState('')
  // const [ birth, setBirth ] = useState('')

  const [data, setData] = useState({
    first: "",
    last: "",
    title: "",
    birth: "",
  });

  const [error, setError] = useState({});

  const [blob, setBlob] = useState();
  // const [ picStatus, setPic ] = useState(false)

  const history = useHistory();

  const submission = async (e) => {
    e.preventDefault();

    let errorCount = 0;
    let newObject = {};
    for (var key in data) {
      if (!data[key]) {
        newObject[key] = "Required";
        errorCount++;
      }
    }

    if (
      !moment(data["birth"], "YYYY-MM-DD", true).isValid() &&
      !newObject["birth"]
    ) {
      newObject["birth"] = "Invalid Date";
      errorCount++;
    }

    setError(() => newObject);

    if (errorCount) return;

    const file = new File([blob], data.first + ".png");
    const formData = new FormData();
    formData.append("image", file);
    formData.append("type", "ADDUSER");
    // formData.append('first', first)
    // formData.append('last', last)
    // formData.append('title', title)
    // formData.append('birth', birth)
    formData.append("data", JSON.stringify(data));

    // const data = {
    //     type: 'ADDUSER',
    //     first,
    //     last,
    //     title,
    //     birth
    // }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const closePic = (b) => {
    setBlob(b);
  };

  const changed = (type, req) => {
    setError((e) => {
      let newError = { ...e };
      newError[type] && delete newError[type];
      return newError;
    });

    setData((data) => {
      let newData = { ...data };
      newData[type] = req["value"];
      return newData;
    });
  };

  return (
    <Con>
      <Body>
        {/* <ImageCon> */}
        {/* <Add onClick={() => setPic(true)}/> */}
        <ImageUploader updateBlob={(b) => closePic(b)} />
        {/* </ImageCon> */}
        <Form onSubmit={(e) => submission(e)}>
          <Title>{tr("Create User")}</Title>
          <Input
            title={tr("First Name")}
            error={error.first}
            placeholder={tr("First Name")}
            changed={(e) => changed("first", e)}
          />
          <Input
            title={tr("Last Name")}
            error={error.last}
            placeholder={tr("Last Name")}
            changed={(e) => changed("last", e)}
          />
          <Input
            title={tr("Job Title")}
            error={error.title}
            placeholder={tr("Job Title")}
            changed={(e) => changed("title", e)}
          />
          <Input
            title={tr("Birthday")}
            type="date"
            error={error.birth}
            changed={(e) => changed("birth", e)}
          />
          {/* <Texts onChange={(e) => setFirst(e.target.value)} value={first} id="outlined-basic" label="First Name" variant="outlined" />
                    <Texts onChange={(e) => setLast(e.target.value)} value={last} id="outlined-basic" label="Last Name" variant="outlined" />
                    <Texts onChange={(e) => setTitle(e.target.value)} value={title} id="outlined-basic" label="Job Title" variant="outlined" />
                    <Texts type="date" onChange={(e) => setBirth(e.target.value)} value={birth} id="outlined-basic" label="Date of Birth" variant="outlined" /> */}
          <Buttons type="submit" variant="contained" color="primary">
            {tr("Submit")}
          </Buttons>
        </Form>
      </Body>
      {/* { picStatus ? <ImageUploader closeContainer={() => closePic()} /> : null} */}
    </Con>
  );
}
