import axios from "axios";
import * as React from "react";
import { useQuery } from "react-query";
import { TAppointment } from "types/appointment";
import { AppointmentCard } from "../components/AppointmentCard";

export default function PartiallyCompleted({
  toolTipModal,
  handleToolTip,
}: any) {
  const query = useQuery<TAppointment[] | null>(
    ["appointments", "partially completed"],
    async () =>
      await axios
        .get(
          `
                ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/partially_completed
                `
        )
        .then((res) => res.data)
  );

  const populateAppts = query.data?.map((appt) => {
    return (
      <AppointmentCard
        key={appt.id}
        {...appt}
        canDrag={true}
        note={appt.date}
        {...{ toolTipModal, handleToolTip }}
        start={appt.start_time}
        end={appt.end_time}
      />
    );
  });
  return <div>{populateAppts}</div>;
}
