import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  flex-direction: row;
`;

const CategoryCon = styled.div`
  color: blue;
  padding: 1rem;
  margin: 0 1rem 0 1rem;
`;

const ParentCon = styled.div`
  color: red;
  padding: 0.5rem;
`;

const ChildCon = styled.div`
  color: green;
`;

const Input = styled.div``;

export default function HistoricalTeam() {
  const { t } = useTranslation();
  const tBase = "views.historicalTeam.index";
  const tr = (key) => t(`${tBase}.${key}`);
  const [data, populateData] = useState([]);
  const [date, setDate] = useState("");

  const groupParent = (e) => {
    let parent = {};
    let toArray = [];
    e.forEach((f) => {
      let { parent_id, first_name, last_name, employee_id } = f;
      if (!parent_id) {
        parent[employee_id] = {
          name: first_name + " " + last_name,
          id: employee_id,
          children: [],
        };
      }
      if (parent_id) {
        parent[parent_id] &&
          parent[parent_id]["children"]?.push({
            name: first_name + " " + last_name,
            id: employee_id,
          });
      }
    });

    for (var key in parent) {
      toArray.push(parent[key]);
    }
    return toArray;
  };

  const tabulateData = (e) => {
    let tabulatedData = [];
    e.forEach((f) => {
      let categoryArray = {};
      let { category_label: label, category_id: id, child } = f;
      categoryArray["label"] = label;
      categoryArray["id"] = id;
      categoryArray["child"] = groupParent(child);
      tabulatedData.push(categoryArray);
    });
    populateData(tabulatedData);
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/historicalteam?date=${date}`
      );
      tabulateData(res.data);
      // populateData(res.data)
      if (res.status === 500) {
        alert(res.statusText);
      }
    } catch (e) {
      alert(e);
      console.error(e);
      // window.location.reload();
    }
  };

  useEffect(() => {}, []);

  const initialList =
    data.length !== 0
      ? data.map((e) => {
          return (
            <CategoryCon key={e["id"]}>
              {e["label"]}
              {e["child"]
                ? e["child"].map((f) => {
                    return (
                      <ParentCon key={f["id"]}>
                        {f["name"]}
                        {f["children"]
                          ? f["children"].map((g) => {
                              return (
                                <ChildCon key={g["id"]}>{g["name"]}</ChildCon>
                              );
                            })
                          : null}
                      </ParentCon>
                    );
                  })
                : null}
            </CategoryCon>
          );
        })
      : null;

  return (
    <>
      <Input>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        ></input>
        {/* <input type='time'></input> */}
        <button onClick={() => fetchData()}>{tr("Submit")}</button>
      </Input>
      <Con>{initialList}</Con>
    </>
  );
}
