import { A, Button } from "components/button";
import ClientSelect from "components/react-select/clients";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (e: any) => void;
};

export default function CustomerForm2(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.requests.newRequestModal.customerForm2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleChange = (e: any) => {
    props.onChange(e.id);
  };
  return (
    <div className="h-full flex flex-col items-center space-y-3">
      <ClientSelect
        onChange={handleChange}
        value={""}
        label={tr("Select from Existing Customer")}
      />
      <div>{tr("or")}</div>
      <A className="w-full" to={"/customers/edit/new"} target="_blank">
        {tr("Create New Customer")}
      </A>
    </div>
  );
}

const Con = styled.div``;
