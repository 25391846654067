import React, { useCallback, useRef, useEffect } from "react";
import axios from "axios";
import SafeArea from "components/safe-area";
import EstimateSection from "containers/estimatesList";
import { useQuery } from "react-query";
import { useTable } from "components/table";
import useQueryString from "hooks/useQueryString";
import { ContainerWithSidebar } from "components/layout";
import EstimateFilter from "./estimateFilter";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import { SearchInput } from "components/input";
import { debounce } from "lodash";
import { useTranslation } from 'react-i18next';

export interface Tag {
  id: number;
  label: string;
}

type WorkOrderProps = {
  id: number;
  description: string;
  entry_date: Date;
  appointment_count: number;
  completed_count: number;
  status: { label: string; color: string };
};

export interface List {
  id: number;
  entry_date: Date;
  name: string;
  address: string;
  status: string;
  tags: Tag[];
  link: string;
  children?: WorkOrderProps[];
}
export interface Count {
  status: string;
  count: number;
}
export interface Category {
  link: string;
}

export interface Prop {
  list: List[];
  count: Count[];
  categories: Category[];
}

export interface Query {
  category?: string[];
  status?: string[];
  page?: string[];
}

export default function Estimates(props: any) {
  const { t } = useTranslation();
  const tBase = "views.estimates.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [initialLoad, setInitialLoad] = React.useState(true);
  const { location } = props;
  const [queryFilter, setQueryFilter] = React.useState<Query>({});
  const [search, setSearch] = React.useState("");
  const [debouncedSearch, setDebouncedSearch] = React.useState("");
  const [attention, setAttention] = React.useState(false);
  const [beginDate, setBeginDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");

  const table = useTable(),
    queryString = useQueryString();

  useEffect(() => {
    queryString.update("");
  }, [table.sort]);

  const query = useQuery(
    ["estimates", queryString.search, table.sort, queryFilter, debouncedSearch],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates`,
          {
            params: {
              ...queryFilter,
              search: debouncedSearch.trim(),
              page: queryString.search.page || 1,
              sort: table.sort,
              date_won_begin: queryString.search.date_won_begin,
              date_won_end: queryString.search.date_won_end,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
    {
      keepPreviousData: true,
    }
  );

  const history = useHistory();

  React.useEffect(() => {
    let rawString = QueryString.parse(location.search);
    let parsedString: { [key: string]: string[] } = {};
    Object.entries(rawString).forEach(([key, value]) => {
      if (typeof value === "string") {
        parsedString[key] = value.split("|");
      }
    });
    let queryPage = parsedString.page && +parsedString.page[0];
    if (queryPage !== table.pageIndex)
      table.handlePage((parsedString?.page && +parsedString?.page[0]) || 1);
    setQueryFilter(parsedString);
    setInitialLoad(false);
  }, [location.search]);

  React.useEffect(() => {
    !initialLoad &&
      updateUrl({ ...queryFilter, page: [table.pageIndex.toString()] });
  }, [initialLoad, table.pageIndex]);

  const updateUrl = (queryFilter: Query) => {
    let stringifyQuery: { [key: string]: string } = {};

    Object.entries(queryFilter).forEach(([key, value]) => {
      stringifyQuery[key] = value.join("|");
    });

    history.replace({
      search: QueryString.stringify(stringifyQuery),
    });
  };

  const selected = queryFilter && queryFilter.category && queryFilter.category[0];

  useEffect(() => {
    if (query.data?.count) {
      const totalPage = Math.ceil(
        (selected
          ? query.data.count.find((count: any) => count.status === selected)
              ?.count || 1
          : query.data.count.reduce((prev: any, next: any) => prev + next.count, 0) ||
            1) / 20
      );

      table.setPageCount(totalPage);
    }
  }, [selected, query.data?.count]);

  const resetDateFilter = () => {
    setBeginDate("");
    setEndDate("");
  }

  const handleStatusClick = (status: string) => {
    if (status === "showAll") {
      updateUrl({});
      resetDateFilter();
    } else {
      updateUrl({ ...queryFilter, category: [status], page: ["1"] });
    }
  };

  const debouncedSetSearch = useRef(
    debounce((inputValue: string) => {
      setDebouncedSearch(inputValue);
    }, 200)
  ).current;

  const handleDebounce = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
    debouncedSetSearch(inputValue);
  };

  if (!query.data) return null;

  return (
    <ContainerWithSidebar>
      <EstimateFilter
        query={queryFilter}
        selected={selected}
        count={query.data?.count}
        categories={query.data?.categories}
        {...{ handleStatusClick, attention, setAttention, beginDate, setBeginDate, endDate, setEndDate}}
      />
      <div className="divide-y">
        <SafeArea classNames={{ content: "py-4" }}>
          <h1>{tr("Estimates")}</h1>
          <SearchInput value={search} onChange={handleDebounce} placeholder={tr("Search...")} />
        </SafeArea>
        <SafeArea classNames={{ content: "py-4" }}>
          <EstimateSection data={query.data?.estimates || null} table={table} pageCount={query.data.page_count} />
        </SafeArea>
      </div>
    </ContainerWithSidebar>
  );
}
