import React, { useState } from "react";
import styled from "styled-components";
import { IoMdCheckmark } from "react-icons/io";
import { useSelector } from "react-redux";
import Axios from "axios";

const NewCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: rgb(54, 73, 91, 0.5);
  color: white;
  > input {
    background: #002c37;
    border: 0;
    padding: 0 1em 0 1em;
    height: 2rem;
    margin-right: 10px;
    border-radius: 4px;
    color: white;
    &:focus {
      outline: none;
    }
  }
  > button {
    padding: 0.5rem 1em 0.5rem 1em;
    height: 2rem;
    background: green;
    border-radius: 4px;
    color: white;
    border: 0;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

const NewCategory = (props) => {
  const [inputText, setInput] = useState("");
  const employee = useSelector((state) => state.team.employee);

  const inputChanged = (e) => {
    setInput(e.target.value);
  };

  const addCategory = async () => {
    try {
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
        {
          type: "ADD",
          label: inputText,
          index: employee.length,
        }
      );
    } catch (e) {
      throw e;
    } finally {
      props.added();
      setInput("");
    }
  };

  return (
    <NewCategoryContainer>
      <input
        type="text"
        value={inputText}
        onChange={(e) => inputChanged(e)}
      ></input>
      <button onClick={() => addCategory()}>
        <IoMdCheckmark />
      </button>
    </NewCategoryContainer>
  );
};

// onClick={() => addCategory()}

export default NewCategory;
