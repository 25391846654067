import React from "react";

import styles from "./ParentCategory.module.scss";

function ParentCategory({ children, rootId, rootName }) {
  return (
    <div id={`pc-${rootId}`}>
      <h3 className={styles.header}>{rootName}</h3>
      <hr className={styles.rule} />
      <div>{children}</div>
    </div>
  );
}

export default ParentCategory;
