import * as React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";
import { Size } from "types/theme";

const primary = `
    color: white;
    background: #ff4f00;
`;

const warning = `
  color: white;
  background: red;
`;
const primaryOutline = `
    color: #ff4f00;
    background: white;
    border: 1px solid #ff4f00;
`;

const warningOutline = `
  color: red;
  background: white;
  border: 1px solid red;
`;

const primarySubtle = `
  color: #ff4f00;
`;

const warningSubtle = `
  color: red
`;

export interface ButtonP {
  size: Size;
  primary: boolean;
  square: boolean;
  $rounded: boolean;
  $selected: boolean;
  $warning: boolean;
}

const noStyleButton = css`
  border: 0;
  text-align: left;
  background: transparent;
  cursor: pointer;
  transition: all 0.15s linear;
  color: black;
  text-decoration: none;
`;

const buttonStyles = css<ButtonP>`
  ${noStyleButton}
  padding: 0 1em;
  /* padding: ${(props) => (props.size === "small" ? "0 0.3em" : "0 1em")}; */
  height: ${(props) => theme.height[props.size]};
  border-radius: ${(props) =>
    props.square ? "0px" : props.$rounded ? "25px" : "4px"};
  font-size: ${(props) => theme.fontSize[props.size]};
  font-weight: 600;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  &:disabled {
    background: #f8f9f9;
    color: grey;
    cursor: not-allowed;
  }
  gap: 0.3em;
  > svg {
    font-size: 1.5em;
  }
`;

const iconStyles = css<ButtonP>`
  ${buttonStyles}
  border-radius: ${(props) => (props.square ? "5px" : "50%")};
  width: ${(props) => theme.height[props.size]};
  min-width: ${(props) => theme.height[props.size]};
  height: ${(props) => theme.height[props.size]};
  padding: 0;
`;

const bgButtonStyles = css<ButtonP>`
  background: ${theme.bg.blue};
  ${(props) => props.primary && primary}
  &:hover {
    filter: brightness(80%);
  }
`;

const outlineButtonStyles = css<ButtonP>`
  border: 1px solid ${theme.border.light};
  ${(props) => props.primary && primaryOutline}
  &:hover {
    border: 1px solid black;
  }
`;

const subtleButtonStyles = css<ButtonP>`
  color: ${theme.color.subtext2};
  ${(props) => props.primary && primarySubtle}
  &:hover {
    color: black;
  }
`;

const selectedStyles = css<ButtonP>`
  background: ${(props) => props.$selected && theme.color.main};
  color: ${(props) => props.$selected && "white"};
`;

const selectedOutlineStyles = css<ButtonP>`
  ${selectedStyles}
  border: ${(props) => props.$selected && "1px solid transparent"}
`;

// NO STYLE
export const StyledNoStyleButton = styled.button`
  ${noStyleButton}
  ${selectedStyles}
`;
export const StyledNoStyleA = styled(Link)`
  ${noStyleButton}
`;

// DEFAULT STYLE
export const StyledButton = styled.button`
  ${buttonStyles}
  ${bgButtonStyles}
  ${selectedStyles}
`;
export const StyledA = styled(Link)`
  ${buttonStyles}
  ${bgButtonStyles}
`;

// OUTLINE STYLE
export const StyledOutlineButton = styled.button`
  ${buttonStyles}
  ${outlineButtonStyles}
  ${selectedOutlineStyles}
`;
export const StyledOutlineA = styled(Link)`
  ${buttonStyles}
  ${outlineButtonStyles}
`;

// SUBTLE STYLE
export const StyledSubtleButton = styled.button`
  ${buttonStyles}
  ${subtleButtonStyles}
`;
export const StyledSubtleA = styled(Link)`
  ${buttonStyles}
  ${subtleButtonStyles}
`;

// ICON
// DEFAULT ICON
export const StyledIconButton = styled.button`
  ${iconStyles}
  ${bgButtonStyles}
`;
export const StyledIconA = styled(Link)`
  ${iconStyles}
  ${bgButtonStyles}
`;

// SUBTLE ICON
export const StyledSubtleIconButton = styled.button`
  ${iconStyles}
  ${subtleButtonStyles}
`;
export const StyledSubtleIconA = styled(Link)`
  ${iconStyles}
  ${subtleButtonStyles}
`;

// OUTLINE ICON
export const StyledOutlineIconButton = styled.button`
  ${iconStyles}
  ${outlineButtonStyles}
`;
export const StyledOutlineIconA = styled(Link)`
  ${iconStyles}
  ${outlineButtonStyles}
`;

export const StyledMenuButton = styled(StyledButton)`
  border-radius: 0;
  width: 100%;
  color: ${theme.color.subtext};
  background: transparent;
  justify-content: flex-start;
  &:hover {
    background: ${theme.bg.blue};
    box-shadow: none;
    color: black;
  }
`;
