import axios from "api/axios";
import { Button, NoStyleA } from "components/button";
import { SFlatContent, SFlatHeader } from "components/segment/flat";
import * as React from "react";
import { RiAddFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TDivision } from "types/division";
import { useTranslation } from 'react-i18next';

export default function Divisions() {
  const history = useHistory();
  const { t } = useTranslation();
  const tBase = "views.admin.categories.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const divisions = useQuery<TDivision[]>(
    "divisions",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/divisions`
        )
        .then((res) => res.data)
  );

  const populateDivisions =
    divisions.data?.map((division) => (
      <Division key={division.id} {...division} />
    )) || tr("No record.");

  return (
    <SCon>
      <SFlatHeader>
        <h1>{tr("Divisions")}</h1>
        <Button primary onClick={() => history.push("/admin/divisions/new")}>
          <RiAddFill /> {tr("Divisions")}
        </Button>
      </SFlatHeader>
      <SFlatContent style={{ display: "flex", flexDirection: "column" }}>
        {populateDivisions}
      </SFlatContent>
    </SCon>
  );
}

const SCon = styled.div``;

const Division = (props: TDivision) => {
  return (
    <SDivisionCon to={`/admin/divisions/${props.id}`}>
      {props.label}
    </SDivisionCon>
  );
};

const SDivisionCon = styled(NoStyleA)`
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid ${theme.border.light};
`;
