import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { StyledImage } from "./styled";

type Props = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  "placeholder"
> & {
  placeholder?: React.ReactNode;
};

export default function Image(props: Props) {
  const { className, placeholder, style, ...rest } = props;
  return (
    <SCon style={style} className={className}>
      {rest.src ? <img {...rest} /> : placeholder}
    </SCon>
  );
}

const SCon = styled.div`
  width: 100px;
  height: 100px;
  flex: 0 0 auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.border.light};
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  font-size: 1.5em;
  overflow: hidden;
`;

// const Wrapper =
