import { FC, createContext, useState } from "react";
import { IViewByTypeContext } from "../types/IViewByTypeContext";

const defaultContextValue: IViewByTypeContext = {
  viewByType: false,
  setViewByType: () => {}
};

const ViewByTypeContext = createContext<IViewByTypeContext>(defaultContextValue);

type ViewByTypeProviderProps = {
  children: React.ReactNode;
}

export const ViewByTypeProvider: FC<ViewByTypeProviderProps> = ({ children }) => {
  const [viewByType, setViewByType] = useState<boolean>(false);

  return (
    <ViewByTypeContext.Provider value={{ viewByType, setViewByType }}>
      {children}
    </ViewByTypeContext.Provider>
  );
}

export default ViewByTypeContext;