import React, { useState } from "react";
import styled, { css } from "styled-components";
import { RiStickyNoteLine, RiTeamLine } from "react-icons/ri";
import { IoMdThermometer } from "react-icons/io";
import Team from "./teamHistory";
import Sick from "./sickHistory";
import Note from "./noteHistory";
import { useTranslation } from 'react-i18next';

const Detail = styled.div`
	// padding-bottom: 5rem;
	// min-height: 100%;
	overflow: auto;
`;

const TabBody = styled.div`
	margin: 0 0.5rem 0 0.5rem;
`;

const Tab = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
`;

const TabOption = styled.div`
	padding: 0.5rem 1rem 0.5rem 1rem;
	margin: 0 2vw 0 2vw;
	border-radius: 0 0 5px 5px;
	letter-spacing: 1px;
	position: relative;
	font-weight: 600;
	font-size: 0.9rem;
	display: flex;
	overflow: hidden;
	color: grey;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
	> * {
		margin-right: 0.1rem;
		font-size: 0.98rem;
	}
	${(props) =>
		props.selected &&
		css`
			color: ${props.selected};
			margin-top: -1px;
			border-top: 1px solid ${props.selected};
			> * {
				color: ${props.selected};
			}
			&:before {
				width: 100%;
				left: 0;
				content: "";
				height: 100%;
				position: absolute;
				// background: ${props.selected};
				opacity: 0.2;
			}
		`}
`;

const TabHeader = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	border-top: 1px solid #e7e7e7;
`;

const UserDetail = (props) => {
	const { t } = useTranslation();
	const tBase = "views.team.viewUser.userDetail";
	const tr = (key) => t(`${tBase}.${key}`);

	const { id, from } = props;

	const [tab, selectTab] = useState({
		color: from === "sick" ? "red" : "orange",
		description: from === "sick" ? "Leave" : "Note",
	});

	const tabSelect = (e) => {
		let color = e === "Note" ? "orange" : e === "Team" ? "blue" : "red";
		selectTab({ color: color, description: e });
	};

	return (
		<Detail>
			<Tab>
				<TabHeader selected={tab["color"]}>
					<TabOption
						selected={tab["description"] === "Note" && tab["color"]}
						onClick={() => tabSelect("Note")}
					>
						<RiStickyNoteLine />
						{tr("NOTE")}
					</TabOption>
					<TabOption
						selected={tab["description"] === "Team" && tab["color"]}
						onClick={() => tabSelect("Team")}
					>
						<RiTeamLine />
						{tr("TEAM")}
					</TabOption>
					{
						<TabOption
							selected={
								tab["description"] === "Leave" && tab["color"]
							}
							onClick={() => tabSelect("Leave")}
						>
							<IoMdThermometer />
							{tr("ABSENCE")}
						</TabOption>
					}
				</TabHeader>
				<TabBody>
					{tab["description"] === "Team" && <Team id={id} />}
					{tab["description"] === "Leave" && <Sick id={id} />}
					{tab["description"] === "Note" && <Note id={id} />}
				</TabBody>
			</Tab>
		</Detail>
	);
};

export default UserDetail;
