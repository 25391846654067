import * as React from "react";
import {
  Svg,
  Line,
  Path,
  Con,
  TitleCon,
  SvgCon,
  AxisCon,
  Percent,
  HoverCon,
} from "./style";

type Data = any;

type Props = {
  data: any;
  x: string;
  y: string;
  title: string;
};

export default function App(props: Props) {
  const [current, setCurrent] = React.useState(-1);
  const domRef = React.useRef<SVGSVGElement>(null);

  const y = props.y;
  const x = props.x;
  const data = props.data;
  const title = props.title;

  React.useEffect(() => {
    if (domRef.current) {
      domRef.current?.addEventListener("mousemove", handleMouseMove);

      return () => {
        domRef.current?.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [data]);

  if (!data) return null;

  const totalCount = data.length - 1;
  const mouseX = (current / totalCount) * 100;
  const hoverValue =
    current !== -1 && data[current]
      ? data[current][y]
      : data.reduce((prev: number, d: any) => prev + +d[y], 0);

  const initialValue = data[0][y] === "0" || data[0][y] === 0 ? 1 : data[0][y];

  const percentage = ((hoverValue - data[0][y]) / initialValue) * 100;

  const tabulateData = () => {
    let max = Math.max.apply(
      Math,
      data.map((d: any) => d[y])
    );
    max = max + 1;

    const dataString = data.reduce(
      (prev: { all: string; last: string }, d: any, i: number) => {
        const value = d[y];

        const pathX = (i / totalCount) * 100;
        const pathY = ((max - value) / max) * 100;

        switch (i) {
          case 0:
            return {
              ...prev,
              all: prev.all + `M ${pathX} ${pathY} `,
            };

          case data.length - 2:
            return {
              all: prev.all + `L ${pathX} ${pathY}`,
              last: `M ${pathX} ${pathY}`,
            };
          case data.length - 1:
            return {
              ...prev,
              last: prev.last + `L ${pathX} ${pathY}`,
            };
          default:
            return {
              ...prev,
              all: prev.all + `L ${pathX} ${pathY} `,
            };
        }
      },
      { all: "", last: "" }
    );

    return dataString;
  };
  const handleMouseMove = (e: any) => {
    const width = domRef.current!.clientWidth;
    const location = Math.round((e.offsetX / width) * totalCount);
    setCurrent((current) => {
      if (current === location) return current;
      return location;
    });
  };
  const handleMouseLeave = () => {
    setCurrent(-1);
  };

  return (
    <Con>
      <TitleCon>
        <span>{title}</span>
        <div>
          <span>{hoverValue}</span>
          {current !== -1 && (
            <Percent
              status={
                percentage < 0
                  ? "negative"
                  : percentage > 0
                  ? "positive"
                  : "zero"
              }
            >
              {percentage.toFixed(2)}%
            </Percent>
          )}
        </div>
      </TitleCon>
      <HoverCon left={mouseX + "%"}>
        <div>
          {current !== -1 && data[current] && <span>{data[current][x]}</span>}
        </div>
      </HoverCon>
      <SvgCon>
        <Svg
          onMouseLeave={handleMouseLeave}
          ref={domRef}
          width="100%"
          height="100%"
          viewBox="0 0 100 101"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g strokeWidth={1}>
            <Line
              stroke="#c0c0c0"
              vectorEffect="non-scaling-stroke"
              x1="0"
              x2="100"
              y1="100"
              y2="100"
            />
          </g>
          <g fill="white" stroke="#8B008B" strokeWidth="1.5">
            <Path
              vectorEffect="non-scaling-stroke"
              d={tabulateData().all}
              fill="transparent"
            />
            <path
              vectorEffect="non-scaling-stroke"
              strokeDasharray={5}
              d={tabulateData().last}
              fill="transparent"
            />
          </g>
          <g stroke="grey">
            <Line
              vectorEffect="non-scaling-stroke"
              strokeWidth={1}
              strokeDasharray={5}
              x1={mouseX}
              x2={mouseX}
              y1="0"
              y2="100"
            />
          </g>
        </Svg>
      </SvgCon>

      <AxisCon>
        <span>{data[0][x]}</span>
        <span>{data[data.length - 1][x]}</span>
      </AxisCon>
    </Con>
  );
}
