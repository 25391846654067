import React, { useEffect, useState, useRef } from "react";
import { useUpload } from "../context/UploadContext";
import axios from "axios";
import { useDrag, useDrop } from "react-dnd";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AIStars } from "images/icons/icons";
import Field from "components/field";
import { Button } from "components/buttonV2";
import { initializeProgressListener } from "./ProgressBar";
import { useModalContext } from "components/modal";
import Preview from "./Preview";
import { useTranslation } from 'react-i18next';
import { 
  DraggableHeaderProps, 
  DroppableColumnProps,
  IMatchedColumns,
  DropResult
} from "../interface";
import i18n from "i18n";

const ItemType = {
  HEADER: "header",
};

const DraggableHeader: React.FC<DraggableHeaderProps> = ({ header }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.HEADER,
    item: { header },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [header]);

  return (
    <div ref={drag} className={`bg-white border p-2 my-1.5 shadow-md rounded-[4px] hover:cursor-grab ${isDragging ? "opacity-50" : "opacity-100"}`}>
      {header}
    </div>
  );
};

const DroppableColumn: React.FC<DroppableColumnProps> = ({ column, onDrop, matchedHeader, setMatchedColumns, i }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType.HEADER,
    drop: (item: DropResult) => onDrop(item.header, column),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [, drag] = useDrag({
    type: ItemType.HEADER,
    item: { header: matchedHeader },
    canDrag: !!matchedHeader,
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && matchedHeader) {
        onDrop(matchedHeader, ''); // Remove from column if not dropped to a new column
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleRemoveHeader = (i: number) => {
    setMatchedColumns(prev => {
      const newMatches = { ...prev };
      delete newMatches[i];
      return newMatches;
    });
  };

  return (
    <div ref={drop} className={`border my-1.5 h-10 overflow-hidden rounded-[4px] ${matchedHeader ? "hover:cursor-grab" : ""} ${isOver ? "bg-blue-200" : "bg-white"}`}>
      <div ref={drag} className="relative rounded-[4px] p-2">
        {matchedHeader}
        {matchedHeader && 
          <button 
            onClick={() => handleRemoveHeader(i)}
            className="absolute top-0 right-0 text-2xl m-2 bg-white text-[#ff0000] hover:text-white rounded-full hover:cursor-pointer hover:bg-[#ff0000]"
          >
            <AiOutlineCloseCircle />
          </button>
        }
      </div>
    </div>
  );
};

const ColumnMatcher = () => {
  const [matchedColumns, setMatchedColumns] = useState<IMatchedColumns>({});
  const [flipColors, setFlipColors] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([] as any[]);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [additionalList, setAdditionalList] = useState<string[]>([]);
  const [initialCount, setInitialCount] = useState(0); // used to safe guard deletion of non new fields
  const [loading, setLoading] = useState(false);  
  const [useAdditionalOptions, setUseAdditionalOptions] = useState(false); // used to determine if additional options are needed
  const [previewData, setPreviewData] = useState([] as any[]); // used to store preview data
  const [availableHeaders, setAvailableHeaders] = useState([] as string[]); // used to store available headers
  const eventSourceRef = useRef<EventSource | null>(null);
  const { 
    selected, 
    headers, 
    selectedFileType, 
    setTriggerCurtainsClose, 
    setPauseCurtains,
    setResults,
    setUploadProgress,
    origFileName,
    inventory,
    services,
    customers
  } = useUpload();
  const { setModal } = useModalContext();
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.ColumnMatcher";
  const tr = (key: string) => t(`${tBase}.${key}`);

  useEffect(() => {
    switch (selected) {
      case "inventory":
        // setSelectedList([...inventory.map(item => item.label)]);
        setSelectedList(inventory);
        setInitialCount(inventory.length);
        setAdditionalList(addOptions[0]?.inventory || []);
        setUseAdditionalOptions(addOptions[0]?.status === "on");
        break;
      case "services":
        setSelectedList(services);
        setInitialCount(services.length);
        setAdditionalList(addOptions[1]?.services || []);
        setUseAdditionalOptions(addOptions[1]?.status === "on");
        break;
      case "customers":
        setSelectedList(customers);
        setInitialCount(customers.length);
        setAdditionalList(addOptions[2]?.customers || []);
        setUseAdditionalOptions(addOptions[2]?.status === "on");
        break;
      default:
        setSelectedList([]);
    }
  }, [selected]);

  const handleDrop = (header: string, column: string, i: number): void => {
    setMatchedColumns(prev => {
      const newMatches = { ...prev };

      Object.entries(newMatches).forEach(([key, value]) => {
        if (value === header) {
          delete newMatches[key];
        }
      });
  
      // Assign the header to the new column, if column is not empty
      if (column) {
        newMatches[i] = header;
      }

      return newMatches;
    });
  };

  const filteredHeaders = headers.filter(header => header === "" || header !== null);
  useEffect(() => {
    // Recompute available headers every time headers or matchedColumns changes
    const matchedHeaders = new Set(Object.values(matchedColumns));
    const updatedAvailableHeaders = filteredHeaders.filter(header => !matchedHeaders.has(header));
    setAvailableHeaders(updatedAvailableHeaders);
  }, [headers, matchedColumns]);

  const match = async (headers: string[], databaseColumns: string[]) => {
    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/sortColumns`, {
        headers,
        databaseColumns
      });
  
      const responseMapping = res.data;
      const mappedColumns: IMatchedColumns = {};

      selectedList.forEach((column, i) => {
        const matchKey = Object.keys(responseMapping).find(key => responseMapping[key] === column);
        if (matchKey) {
          mappedColumns[i] = matchKey;
        }
      });
  
      setMatchedColumns(mappedColumns);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching import status", error);
    }
  };

  const removeAllHeaders = () => {
    setMatchedColumns({});
  };

  // Change status to "on" to use additional options
  const addOptions = [
    { 
      inventory: [
        "Category",
        "Subcategory"
      ],
      status: "off",
    },
    { 
      services: [
        "Category",
        "Subcategory"
      ],
      status: "off"
    },
    { 
      customers: [
        "First and Last Name",
        "Last Name, First Name",
        "Phone and Extension",
        "Additional Address (Group)",
        "Additional Contact (Group)",
      ],
      status: "on"
    },
  ];

  const additionalAddress = [
    "Address (Address)",
    "City (Address)",
    "State (Address)",
    "Zip (Address)",
    "City, State, Zip (Address)",
  ];

  const additionalContact = [
    "First Name (Contact)",
    "Last Name (Contact)",
    "First and Last Name (Contact)",
    "Title (Contact)",
    "Phone (Contact)",
    "Extension (Contact)",
    "Email (Contact)",
  ];
  
  const addDynamicField = () => {
    setDynamicFields(prevFields => [...prevFields, ""]);
  };

  const handleFieldChange = (label: string, i: number) => {
    setSelectedList(prev => {
      // Remove after being used and DONT add the (n) to the label
      if (label === "First and Last Name" || label === "Last Name, First Name" || label === "Phone and Extension") {
        setAdditionalList(prev => {
          const newAdditionalList = prev.filter(item => item !== label);
          return newAdditionalList;
        });
        return [...prev, label];
      } else if (label.includes("(Group)")) {
        // Determine the specific group and its related fields
        let groupFields = [] as any[];
        if (label === "Additional Address (Group)") groupFields = additionalAddress;
        if (label === "Additional Contact (Group)") groupFields = additionalContact;

        // Calculate the suffix for each field in the group
        const newFields = groupFields.map((field) => {
          const escapedField = field.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`^${escapedField}( \\((\\d+)\\))?$`);
          const counts = prev
            .map((item) => {
              const match = item.match(regex);
              return match ? parseInt(match[2], 10) : null;
            })
            .filter(Number.isInteger) as number[];

          const nextCount = counts.length > 0 ? Math.max(...counts) + 1 : 1;
          return `${field} (${nextCount})`;
        });

        // Update additionalList to prevent re-adding the same group
        setAdditionalList(prevAdditional => prevAdditional.filter(item => !groupFields.includes(item.replace(/ \(\d+\)$/, ''))));

        return [...prev, ...newFields];
      } else {
        return [...prev, label];
      }
    });
    setDynamicFields(dynamicFields.slice(0, -1)); // removes extra <Field> that happens when adding value to previous field
  };  

  const initialPositions: { [key: string]: number } = {
    "First and Last Name": 0,
    "Last Name, First Name": 1,
    "Phone and Extension": 2
  };

  const removeField = (i: number) => {
    if (dynamicFields.length > 0) { // prevents deleting original fields 
      setDynamicFields(dynamicFields.slice(0, -1));
    } else if (selectedList.length > initialCount) {
      setSelectedList(prevSelected => {
      const indexToRemove = i !== 999 ? i : prevSelected.length - 1;
      const newSelected = [...prevSelected.slice(0, indexToRemove), ...prevSelected.slice(indexToRemove + 1)];

      setMatchedColumns(prevMatched => {
        const newMatched: { [key: string]: any } = {}; // Add index signature
        Object.keys(prevMatched).forEach(key => {
          const adjustedKey = parseInt(key);
          if (adjustedKey < indexToRemove) {
            newMatched[key] = prevMatched[key];
          } else if (adjustedKey > indexToRemove) {
            // Shift down the key to fill the gap
            newMatched[adjustedKey - 1] = prevMatched[key];
          }
          // If adjustedKey === indexToRemove, do nothing (it's being deleted)
        });
        return newMatched;
      });

      const itemToRemove = prevSelected[indexToRemove];
      const first = "First and Last Name";
      const second = "Last Name, First Name";
      const third = "Phone and Extension";
   
        const specialItems = ["First and Last Name", "Last Name, First Name", "Phone and Extension"];
        if ([first, second, third].includes(itemToRemove)) {
          setAdditionalList(prevAdditional => {
          let updatedList = prevAdditional.filter(item => item !== itemToRemove);
          updatedList.push(itemToRemove);
          // Sort based on the initial index positions of only the relevant items
          updatedList.forEach(item => {
            let specialFiltered = updatedList.filter(item => specialItems.includes(item));
            updatedList = [...specialFiltered.sort((a, b) => initialPositions[a] - initialPositions[b]), ...updatedList.filter(item => !specialItems.includes(item))];
          });
          return updatedList;
          });
        }
  
        return newSelected;
      });

      console.log("way way after", matchedColumns);
      console.log("selected list", selectedList);
    }
  };
  
  // Finish import with matched columns to headers
  const sendMatchedColumns = async () => {
    // Get value from index position of selectedList
    const dbMappedCols = Object.fromEntries(
      Object.entries(matchedColumns).map(([index, header]) => {
        const columnValue = selectedList[Number(index)];
  
        return [columnValue, header]; 
      })
    );

    try {
      // TODO: UNCOMMENT WHEN READY
      setTriggerCurtainsClose(true);
      setPauseCurtains(true);
      initializeProgressListener({ // Initialize SSE for progress updates
        setResults,
        setUploadProgress,
        eventSourceRef,
        selected: selected,
      });
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/finishImportWithMatched`, {
        matchedColumns: dbMappedCols,
        fileType: selectedFileType,
        selected: selected,
        fileName: origFileName,
      });
    } catch {
      console.error("Error sending matched columns");
    }
  };  

  // Preview the first few rows of the matched columns
  const previewMatchedColumns = async () => {
    // Get value from index position of selectedList
    const dbMappedCols = Object.fromEntries(
      Object.entries(matchedColumns).map(([index, header]) => {
        const columnValue = selectedList[Number(index)];
  
        return [columnValue, header]; 
      })
    );

    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/previewMatched`, {
        matchedColumns: dbMappedCols,
        fileType: selectedFileType,
        selected: selected,
        fileName: origFileName,
      });
      setPreviewData(res.data);
      return res.data;
    } catch {
      console.error("Error sending matched columns");
    }
  };

  // Cleanup EventSource when component unmounts
  useEffect(() => {
    const currentEventSource = eventSourceRef.current;
    return () => {
      // Use the captured value for cleanup
      if (currentEventSource && currentEventSource.readyState === 1) {
        currentEventSource.close();
        console.log("EventSource closed on cleanup");
      }
    };
  }, []);

  const previewModal = async () => {
    const previewData = await previewMatchedColumns();
    setModal({
      label: tr("Preview"),
      component: <Preview data={previewData} />,
    });
  };

  // TODO: remove tim button
  // const timButton = () => {
  //   if (selectedList.length > 45) return; 
  //   const x = 7;
  //   const xList = [
  //     "First and Last Name", 
  //     "Last Name, First Name", 
  //     "Phone and Extension", 
  //     "Additional Address (Group)",
  //     "Additional Address (Group)",  
  //     "Additional Contact (Group)",
  //     "Additional Contact (Group)"
  //   ]
  //   for (let i = 0; i < x; i++) {
  //     addDynamicField();
  //     handleFieldChange(xList[i], i);
  //   }
  //   // add to matchedColumns
  //   setMatchedColumns({
  //     0: "First Name",
  //     1: "Last Name",
  //     2: "Company Name",
  //     3: "Title",
  //     4: "Email",
  //     5: "Phone",
  //     6: "Phone Ext",
  //     7: "Owner: Address",
  //     8: "Owner: City",
  //     9: "Owner: State",
  //     10: "Owner: Zipcode",
  //     11: "Owner: City, State, Zip combined",
  //     12: "Bill To: Name",
  //     13: "Address Nickname",
  //     14: "Bill To: Address",
  //     15: "Bill To: City",
  //     16: "Bill To: State",
  //     17: "Bill To: Zipcode",
  //     18: "Bill To: City, State, Zip combined",
  //     19: "First and Last Name",
  //     20: "Last Name, First Name",
  //     21: "Phone and ext",
  //     22: "Additional Address 1",
  //     23: "Additional Address 1 City",
  //     24: "Additional Address 1 State",
  //     25: "Additional Address 1 Zip",
  //     26: "Additional Address 1 City, State, Zip (combo)",
  //     27: "Additional Address 2",
  //     28: "Additional Address 2 City",
  //     29: "Additional Address 2 State",
  //     30: "Additional Address 2 Zip",
  //     31: "Additional Address 2 City, State, Zip (combo)",
  //     32: "Additional Contact 1 First Name",
  //     33: "Additional Contact 1 Last Name",
  //     34: "Additional Contact 1 First and Last Name",
  //     35: "Additional Contact 1 Title",
  //     36: "Additional Contact 1 Phone",
  //     37: "Additional Contact 1 Phone Ext",
  //     38: "Additional Contact 1 Email",
  //     39: "Additional Contact 2 First Name",
  //     40: "Additional Contact 2 Last Name",
  //     41: "Additional Contact 2 First and Last Name",
  //     42: "Additional Contact 2 Title",
  //     43: "Additional Contact 2 Phone",
  //     44: "Additional Contact 2 Phone Ext",
  //     45: "Additional Contact 2 Email",
  //   });
  // };

  return (
    <div>
      <div className="-mt-5 mb-5 text-gray-600 text-center">
        {tr("Please match the excel headers from the imported file to the EServ values.")}
      </div>
      {/* Buttons */}
      <div className="grid grid-cols-3 mb-2">
        {/* TODO: Uncomment out AFTER changing from Seth's openai API */}
        {/* <button 
          onClick={() => match(headers, selectedList)}
          className="flex items-center w-[70px] hover:text-secondary-dark mx-auto"
          onMouseOver={() => setFlipColors(true)}
          onMouseOut={() => setFlipColors(false)}
        >
          <span>Match</span>
          <AIStars 
            className1={`-mt-1.5 ${loading && "animate-AiStars2"}`}
            className2={`${loading && "animate-AiStars"}`}
            color1={`${!flipColors ? "#166BCC" : "#a645b8"}`} 
            color2={`${!flipColors ? "#a645b8" : "#166BCC"}`} 
          />
        </button> */}
        <div className="mx-auto text-gray-700">
          {tr("Excel Headers")}
        </div>
        <button 
          onClick={() => removeAllHeaders()}
          className={`
            ${Object.keys(matchedColumns).length > 0 ? "text-primary hover:text-primary-dark hover:cursor-pointer" : "text-gray-400 hover:cursor-default"}  
            ${i18n.language === "es" ? "w-18" : "w-16"}
            mx-auto
          `}
        >
          {tr("Clear All")}
        </button>
        <div className="mx-auto text-gray-700">
          {tr("EServ Values")}
          {/* <button 
            onClick={timButton}
            className="absolute ml-3 -mt-0.5 border rounded-md px-1 py-0.5 text-white bg-secondary hover:bg-secondary-dark">
            Tim Button
          </button> */}
        </div>
      </div>
      {/* Content */}
      <div className="grid grid-cols-9 gap-x-4 rounded-md [&>*]:max-h-[53vh]">
        <div className={`col-span-3 border rounded-[3px] overflow-y-scroll px-2`}>
          {availableHeaders ? (
            availableHeaders.map((header: string, i: number) => (
              <DraggableHeader key={i} header={header} />
            ))
            ) : (
              <div>
                {tr("None found")}
              </div>
            )
          }
        </div>
        <div className="col-span-6 grid grid-cols-2 gap-x-4 border overflow-y-scroll px-2 pb-4 rounded-[3px]">
          <div className="">
            {selectedList && (
              <div>
                {selectedList.map((column: string, i: number) => (
                  <DroppableColumn 
                    key={i}
                    column={column}
                    i={i}
                    onDrop={(header) => {
                      handleDrop(header, column, i)
                    }}
                    matchedHeader={matchedColumns[i]}
                    setMatchedColumns={setMatchedColumns}
                  />
                ))}
                </div>
              )}
          </div>
          <div>
            {selectedList && (
              <div>
                {selectedList.map((column: string, i: number) => (
                  <div key={i} className="border p-2 my-1.5 h-10 rounded-[4px] relative">
                    {column.includes("(") ? 
                      (
                        <span>
                          {column.split("(")[0]}
                          <span className="text-gray-400 text-[12px]">
                            {/* Adds () conditionally*/}
                            ({column.split("(")[1]} {column.split("(")[2] ? "(" + column.split("(")[2] : ""} 
                          </span>
                        </span>
                      ) : column
                    }
                    {i > initialCount - 1 && (
                      <button 
                        onClick={() => removeField(i)}
                        className="absolute top-0 right-0 text-2xl m-2 bg-white text-[#ff0000] hover:text-white rounded-full hover:cursor-pointer hover:bg-[#ff0000]"
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    )}
                  </div>
                ))}
                <div>

                {dynamicFields.map((field, i) => (
                  <Field
                    key={i}
                    type="select"
                    options={additionalList.map((item) => ({ label: item, value: item }))}
                    value={field}
                    onChange={(e: any) => handleFieldChange(e.value, i)}
                    className="mb-1 text-sm"
                  />
                ))}
                {useAdditionalOptions && (
                  <div className="grid grid-cols-3 space-x-2 -mb-2">
                    <button onClick={addDynamicField} className="bg-secondary hover:bg-secondary-dark text-white rounded p-1">
                      {tr("Add")}
                    </button>
                    <button 
                      onClick={() => removeField(999)} // 999 is a dummy value
                      className={`text-white rounded py-1 px-2
                        ${selectedList.length > initialCount || dynamicFields.length > 0 
                          ? "hover:cursor-pointer bg-red-500 hover:bg-red-600" 
                          : "hover:cursor-default bg-gray-400"
                        }
                      `}
                    >
                      {tr("Remove")}
                    </button>
                  </div>
                )}
              </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-3 mt-2">
        <Button 
          type="secondary" 
          onClick={previewModal}
          className="text-gray-700"
        >
          {tr("Preview")}
        </Button>
        <Button onClick={sendMatchedColumns}>
          {tr("Continue")}
        </Button>
      </div>

    </div>
  )
}

export default ColumnMatcher;