/* Created by Hannah Green for ES-171 on 6/21/2021 */
import React, { useState, useEffect } from "react";
import { DeleteButton, TableCon } from "./styles";

//Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

//Table imports
import { TableHeader, TableToolbar } from "./table-utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

import Tooltip from "@material-ui/core/Tooltip";
import AddEditService from "./addEditServiceModal";
import DeleteService from "./deleteModal";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';
const { useStyles } = require("./styles");

export default function ServiceTable(props) {
  const { t } = useTranslation();
  const tBase = "views.services.components.table.services-table";
  const tr = (key) => t(`${tBase}.${key}`);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const services = props.services;
  const { setModal } = useModalContext();
  const classes = useStyles();

  //Compare items in the cell to order by desc or asc
  const orderDescComparanator = (itm1, itm2, orderBy) => {
    if (itm1[orderBy] < itm2[orderBy]) {
      return -1;
    }
    if (itm1[orderBy] > itm2[orderBy]) {
      return 1;
    }
    return 0;
  };

  //Compare items in the cell and order them by by boolean
  const boolComparanator = (itm1, itm2, orderBy) => {
    if (itm1[orderBy] - itm2[orderBy]) {
      return 1;
    }
    if (!itm1[orderBy] - itm2[orderBy]) {
      return -1;
    }
    return 0;
  };

  //get the comparanator
  const getComparator = (order, orderBy) => {
    if (orderBy === "special_order" || orderBy === "bulk_order") {
      return (itm1, itm2) => boolComparanator(itm1, itm2, orderBy);
    }

    return order === "desc"
      ? (itm1, itm2) => -orderDescComparanator(itm1, itm2, orderBy)
      : (itm1, itm2) => orderDescComparanator(itm1, itm2, orderBy);
  };

  //sort the cells
  const sort = (array, comparanator) => {
    const stabilizeThis = array.map((el, index) => [el, index]);

    stabilizeThis.sort((itm1, itm2) => {
      const order = comparanator(itm1[0], itm2[0]);
      if (order !== 0) {
        return order;
      }
      return itm1[1] - itm2[1];
    });

    return stabilizeThis.map((el) => el[0]);
  };

  //sort request handler
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Change page handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //change rows handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const populateServices =
    services &&
    sort(services, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((s, i) => {
        const status = s.status === "true" ? "Active" : "Inactive";
        const form = {
          id: s.id,
          product_label: s.product_label,
          description: s.description,
          service_category: { label: s.category_name, value: s.category_id },
          flat_rate: s.flat_rate,
          status: s.status === "true" ? true : false,
          rates: s.rates,
        };

        return (
          <TableRow key={i}>
            <TableCell>{s.product_label}</TableCell>
            <TableCell>{s.category_name}</TableCell>
            <TableCell>{tr(status)}</TableCell>
            <TableCell>
              {
                <Tooltip title={tr("Edit")} placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setModal({
                        component: (
                          <AddEditService formType="edit" service={form} />
                        ),
                        label: tr("Edit Service"),
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
            </TableCell>
            <TableCell>
              {" "}
              {
                <Tooltip title={tr("Delete")} placement="top">
                  <IconButton
                    size="small"
                    onClick={() =>
                      setModal({
                        component: <DeleteService service={form} />,
                        label: tr("Delete Service"),
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              }
            </TableCell>
          </TableRow>
        );
      });

  return (
    <TableCon>
      <Paper>
        <TableContainer>
          <TableToolbar categories={props.categories} />
          <Table size="medium">
            <TableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {services && services.length > 0 && <>{populateServices}</>}

              {services.length === 0 && (
                <>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {tr("Nothing to Show")}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </TableCon>
  );
}
