import styled, { css } from "styled-components";
import * as React from "react";
import Axios from "axios";
import { format } from "date-fns";
import { Avatar } from "../../components/avatar/index";
import { IoMdClose, IoMdAdd } from "react-icons/io";
import { useHistory, RouteChildrenProps, Link } from "react-router-dom";
import RequestFilter from "./requestFilter";
import QueryString from "query-string";
import { RequestStatusTag, StatusTag, CategoryTag } from "components/tag/index";
import { SearchInput } from "components/input";
import { Button } from "components/button";
import NewRequestModal from "./newRequestModal";
import { useModalContext } from "components/modal";
import { useQuery } from "react-query";
import Pagination from "components/pagination";
import { ContainerWithSidebar } from "components/layout";
import Table2, { useTable } from "components/table";
import { dollarFormatter } from "utils";
import ProgressBar from "components/progressBar";
import useQueryString from "hooks/useQueryString";
import { useTranslation } from 'react-i18next';

export interface Tag {
  id: number;
  label: string;
}

type WorkOrderProps = {
  id: number;
  description: string;
  entry_date: Date;
  appointment_count: number;
  completed_count: number;
  status: { label: string; color: string };
};

export interface List {
  id: number;
  entry_date: Date;
  name: string;
  address: string;
  status: string;
  tags: Tag[];
  link: string;
  children?: WorkOrderProps[];
}
export interface Count {
  status: string;
  count: number;
}
export interface Category {
  link: string;
}

export interface Prop {
  list: List[];
  count: Count[];
  categories: Category[];
}

export interface Query {
  category?: string[];
  status?: string[];
  page?: string[];
}

const RequestLists = React.memo(function RequestLists(props: RouteChildrenProps) {
  const { t } = useTranslation();
  const tBase = "views.requests.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [requestData, setRequests] = React.useState<Prop | null>();
  const [initialLoad, setInitialLoad] = React.useState(true);
  const { match, location } = props;
  const path = match?.path.split("/")[2];
  const [query, setFilter] = React.useState<Query>({});
  const [search, setSearch] = React.useState("");
  const tableRef = React.useRef<any>();
  const [attention, setAttention] = React.useState(false);

  const table = useTable(),
    queryString = useQueryString();

  const fetchData = useQuery(
    [
      "allRequests",
      initialLoad,
      query,
      search,
      attention,
      // queryString.search.invoice,
    ],
    async () => {
      if (!initialLoad)
        return await Axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/allRequests`,
          {
            params: {
              ...query,
              search: search.trim(),
              filter: "requests",
              attention,
              invoice: queryString.search.invoice,
            },
          }
        ).then((res) => res.data);
    },
    {
      // enabled: false,
      onSuccess: (data) => {
        setRequests(data);
        tableRef?.current?.scrollTo(0, 0);
      },
    }
  );

  const { setModal } = useModalContext();

  const history = useHistory();

  const handleRequestClick = (id: number) => {
    history.push(`/requests/${id}/`);
  };

  React.useEffect(() => {
    let rawString = QueryString.parse(location.search);
    let parsedString: { [key: string]: string[] } = {};
    Object.entries(rawString).forEach(([key, value]) => {
      if (typeof value === "string") {
        parsedString[key] = value.split("|");
      }
    });
    let queryPage = parsedString.page && +parsedString.page[0];
    if (queryPage !== table.pageIndex)
      table.handlePage((parsedString?.page && +parsedString?.page[0]) || 1);
    setFilter(parsedString);
    setInitialLoad(false);
  }, [location.search]);

  React.useEffect(() => {
    !initialLoad && updateUrl({ ...query, page: [table.pageIndex.toString()] });
  }, [initialLoad, table.pageIndex]);

  const updateUrl = (query: Query) => {
    let stringifyQuery: { [key: string]: string } = {};

    Object.entries(query).forEach(([key, value]) => {
      stringifyQuery[key] = value.join("|");
    });

    history.replace({
      search: QueryString.stringify(stringifyQuery),
    });
  };

  let selected = query && query.category && query.category[0];

  React.useEffect(() => {
    const totalPage = Math.ceil(
      (selected
        ? requestData?.count?.find((count) => count.status === selected)
            ?.count || 1
        : requestData?.count?.reduce((prev, next) => prev + next.count, 0) ||
          1) / 20
    );

    table.setPageCount(totalPage);
  }, [selected, requestData?.count]);

  const handleStatusClick = (status: string) => {
    if (status === "showAll") {
      updateUrl({})
    } else {
      updateUrl({ ...query, category: [status], page: ["1"] });
    } 
  };

  const handleStatusToQueryString = (
    element: string,
    status?: string[],
    action?: string
  ) => {
    if (action === "ADD") {
      if (!status) {
        return [element];
      }
      return [...status, element];
    } else if (action === "CLEAR") {
      return;
    } else {
      if (status) {
        status = status.filter((s) => s !== element);
        if (status[0]) {
          return status;
        }
      }
      return;
    }
  };

  const handleCategoryClick = (category: string, action: string) => {
    let updatedStatus = handleStatusToQueryString(
      category,
      query && query.status,
      action
    );
    const { page, status, ...updatedQuery } = query;
    if (updatedStatus) {
      updateUrl({ ...updatedQuery, status: updatedStatus, page: ["1"] });
    } else {
      updateUrl({ ...updatedQuery, page: ["1"] });
    }
  };

  const handleDebounce = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    updateUrl({ ...query, page: ["1"] });
    setSearch(target.value);
  };

  const columns = {
    id: {
      label: tr("Request #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: any) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <RequestStatusTag>{tr(row.status)}</RequestStatusTag>
        </div>
      ),
    },
    attention: {
      label: "",
      cell: (row: any) => (
        <>
          {row.attention && <span className="text-red-600 font-bold">!!!</span>}
          {}
        </>
      ),
    },
    name: {
      label: tr("Customer"),
      cell: (row: any) => (
        <Avatar size="small" showLabel>
          {row.name}
        </Avatar>
      ),
      width: "50%",
    },
    link: {
      label: tr("Category"),
      cell: (row: any) => (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <CategoryTag>{row.link || tr("General")}</CategoryTag>
        </div>
      ),
    },
    stats: {
      label: tr("Stats"),
      cell: (row: any) => {
        const max = Math.max(
          row.paid_total,
          row.actual_total,
          row.request_total,
          1
        );
        const reqW = ((row.request_total || 0) / max) * 60;
        const invW = ((row.actual_total || 0) / max) * 60;
        const paidW = ((row.paid_total || 0) / max) * 60;

        return (
          <div className="flex flex-col w-[70px]">
            {reqW !== 0 && (
              <div className="flex items-center">
                <span className="text-[0.6rem] font-bold w-[10px] flex-none leading-none">
                  W
                </span>
                <div
                  className={`rounded h-[3px] bg-red-500`}
                  style={{ width: `${reqW}px` }}
                />
              </div>
            )}
            {invW !== 0 && (
              <div className="flex items-center">
                <span className="text-[0.6rem] font-bold w-[10px] flex-none leading-none">
                  I
                </span>
                <div
                  className={`rounded h-[3px] bg-purple-500`}
                  style={{ width: `${invW}px` }}
                />
              </div>
            )}
            {paidW !== 0 && (
              <div className="flex items-center">
                <span className="text-[0.6rem] font-bold w-[10px] flex-none leading-none">
                  P
                </span>
                <div
                  className={`rounded h-[3px] bg-green-500`}
                  style={{ width: `${paidW}px` }}
                />
              </div>
            )}
          </div>
        );
      },
    },
    manager: {
      label: tr("Manager"),
    },
    address: {
      label: tr("Address"),
      width: "50%",
      style: {
        gridColumn: "span 3",
      },
    },
    info: {
      label: tr("Info"),
      cell: (row: any) => (
        <div className="flex flex-col">
          <span className="text-[0.6rem] font-bold uppercase text-slate-500">
            {row.appt_date ? tr("Last Appointment") : tr("Request Created")}
          </span>
          <span className="leading-none">
            {row.appt_date
              ? format(new Date(row.appt_date + "T00:00"), "MM/dd/yyyy")
              : format(new Date(row.entry_date), "MM/dd/yyyy hh:mm aa")}
          </span>
        </div>
      ),
    },
  };

  return (
    <ContainerWithSidebar>
      <RequestFilter
        query={query}
        selected={selected}
        count={requestData?.count}
        categories={requestData?.categories}
        {...{ handleStatusClick, handleCategoryClick, attention, setAttention }}
      />
      <div style={{ padding: "2rem" }}>
        <HeaderCon>
          <div className="flex items-center justify-between flex-1">
            <div className="flex items-center">
              <h2>{tr("Requests")}</h2>
              <SearchInput
                search
                placeholder={tr("Search...")}
                style={{ width: "400px", marginLeft: "1rem" }}
                value={search}
                onChange={handleDebounce}
              />
            </div>
            <div className="flex items-center justify-end">
              <Button
                style={{}}
                onClick={() => {
                  history.push("requests/mass-schedule");
                }}
              >
                <IoMdAdd /> {tr("Mass Schedule")}
              </Button>
              <Button
                primary
                style={{ marginLeft: "1rem" }}
                onClick={() =>
                  setModal({
                    component: <NewRequestModal />,
                    label: tr("New Request"),
                  })
                }
              >
                <IoMdAdd /> {tr("Add Request")}
              </Button>
            </div>
          </div>
        </HeaderCon>
        <Table2
          {...{ ...table, columns }}
          to={(row) => `/requests/${row.id}`}
          data={requestData?.list}
        />
      </div>
    </ContainerWithSidebar>
  );
});


const HeaderCon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  `;

export default RequestLists;