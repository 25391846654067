import React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import { CircularProgressbar } from "react-circular-progressbar";

import styled from "styled-components";
import { Button } from "components/button";
import { Link } from "components/link";
import { Checkbox } from "@mui/material";
import { useTranslation } from 'react-i18next';

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

// const OnBoardingMenu = styled.div`
//   width: 350px;
//   > h1 {
//     padding: 0.5rem 1rem;
//   }
// `;

export const OnBoardingMenuItem = () => {
  const { t } = useTranslation();
  const tBase = "views.navigation.OnBoardingMenuItem";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [doneThings, setDoneThings] = React.useState<string[]>([]);
  const totalThings = 4;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  if(((doneThings.length / totalThings) * 100)===100||doneThings.length===0){
    return null
  }else{  return (
    <IconContainer>
      <div onClick={handleClick}>
        <CircularProgressbar
          value={(doneThings.length / totalThings) * 100}
          text={`${(doneThings.length / totalThings) * 100}%`}
        />
      </div>

      {/* @ts-ignore */}
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box
          sx={{
            border: 1,
            p: 1,
            mt: 0.7,
            borderRadius: 1,
            bgcolor: "background.paper",
          }}
        >
          <h1 className="ml-4 mt-2">{tr("Set up EServ")}</h1>
          <div className="p-4 flex justify-between">
            <div
              onClick={() => {
                if (doneThings.includes("1")) {
                  setDoneThings((prev) => prev.filter((t) => t !== "1"));
                } else {
                  setDoneThings((prev) => [...prev, "1"]);
                }
              }}
            >
              <Checkbox
                checked={doneThings.includes("1")}
                aria-label="customers"
              />
            </div>
            <Button>
              <Link to="/settings/questions">{tr("Set up Questions")}</Link>
            </Button>
          </div>
          <div className="p-4 flex justify-between">
            <div
              onClick={() => {
                if (doneThings.includes("2")) {
                  setDoneThings((prev) => prev.filter((t) => t !== "2"));
                } else {
                  setDoneThings((prev) => [...prev, "2"]);
                }
              }}
            >
              <Checkbox
                checked={doneThings.includes("2")}
                aria-label="customers"
              />
            </div>
            <Button>
              <Link to="/admin/upload">{tr("Add Customers")}</Link>
            </Button>
          </div>
          <div className="p-4 flex justify-between">
            <div
              onClick={() => {
                if (doneThings.includes("3")) {
                  setDoneThings((prev) => prev.filter((t) => t !== "3"));
                } else {
                  setDoneThings((prev) => [...prev, "3"]);
                }
              }}
            >
              <Checkbox
                checked={doneThings.includes("3")}
                aria-label="customers"
              />
            </div>
            <Button>
              <Link to="/products">{tr("Add Products")}</Link>
            </Button>
          </div>
          <div className="p-4 flex justify-between">
            <div
              onClick={() => {
                if (doneThings.includes("4")) {
                  setDoneThings((prev) => prev.filter((t) => t !== "4"));
                } else {
                  setDoneThings((prev) => [...prev, "4"]);
                }
              }}
            >
              <Checkbox
                checked={doneThings.includes("4")}
                aria-label="customers"
              />
            </div>
            <Button>
              <Link to="/admin/tags">{tr("Configure Tags")}</Link>
            </Button>
          </div>
        </Box>
      </Popper>
    </IconContainer>
  );}
};
