import { AxiosError, AxiosResponse } from "axios";
import { companyClient } from "./api";

export const getVendors = async () => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: "/vendors/all",
  })
    .then(onSuccess)
    .catch(onError);
};

export const getVendorItemDetails = async (vendorId: number | null) => {
  if (vendorId) {
    const onSuccess = (response: AxiosResponse) => {
      return response.data;
    };

    const onError = (error: AxiosError) => {
      return console.error(error);
    };

    return companyClient({
      method: "GET",
      url: `/vendors/itemDetails/${vendorId}`,
    })
      .then(onSuccess)
      .catch(onError);
  } else {
    return null;
  }
};

export const addVendorItemDetails = async (vendorId: number | null) => {
  if (vendorId) {
    const onSuccess = (response: AxiosResponse) => {
      return response.data;
    };

    const onError = (error: AxiosError) => {
      return console.error(error);
    };

    return companyClient({
      method: "POST",
      url: `/vendors/itemDetails`,
    })
      .then(onSuccess)
      .catch(onError);
  } else {
    return null;
  }
};

export const updateVendorItemQuantity = async (
  vendorItemDetailId: number,
  newPrice: number
) => {
  if (vendorItemDetailId) {
    const onSuccess = (response: AxiosResponse) => {
      return response.data;
    };

    const onError = (error: AxiosError) => {
      return console.error(error);
    };

    return companyClient({
      method: "PUT",
      url: `/vendors/itemDetails`,
      data: { vendorItemDetailId, newPrice },
    })
      .then(onSuccess)
      .catch(onError);
  } else {
    return null;
  }
};
