import Axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { history } from "routes";
import { useLocation } from "react-router-dom";

Axios.defaults.withCredentials = true;

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [userLocation, setUserLocation] = useState({longitude: 0, latitude: 0});

  // gets the user's longitude and latitude from their web browser, if supported
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function(position) {
      const {latitude, longitude} = position.coords;
      setUserLocation({longitude, latitude});
  
    }, function(error) {
      console.error("Error Code = " + error.code + " - " + error.message);
    }, {
      // Options for geolocation
      enableHighAccuracy: true, // Whether to request the device's best possible location
      timeout: 5000, // Maximum time in milliseconds to wait for a location
      maximumAge: 0 // Maximum age in milliseconds of a possible cached position
    });
  } else {
    console.log("Geolocation is not supported by this browser.");
  }


  const logOut = async () => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/logout`
      );
      if (res.status === 200) {
        changeState((state) => ({
          ...state,
          isAuth: false,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const initialState = {
    name: "",
    pin: "",
    isAuth: false,
    charges_enabled: true,
    isLoading: true,
    owner: false,
    onboarding_complete: false,
    businessName: "",
    logout: logOut,
    company_id: 0,
    user_id: 0,
    permissions: {},
    userLongitude: userLocation.longitude,
    userLatitude: userLocation.latitude,
    role: "",
  };

  let url = window.location.href;
  url = new URL(url).pathname;

  const [state, changeState] = useState(initialState);

  useEffect(() => {
    changeState(currentState => ({
      ...currentState,
      userLongitude: userLocation.longitude,
      userLatitude: userLocation.latitude
    }));
  }, [userLocation]); // This effect runs when userLocation changes

  useQuery(
    "info",
    () =>
      Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/authStatus`,
        {
          params: {
            url: url,
          },
        }
      ),
    {
      retry: 0,
      onError: () => {
        changeState((state) => ({ ...state, isLoading: false, isAuth: false }));
      },

        onSuccess: (res) => {
          let {
            first_name = '',
            last_name = '',
            company_id = 0,
            user_id = 0,
            charges_enabled = true,
            isSubscriptionActive = false,
            isAuth = true,
            owner = false,
            onboarding_complete = false,
            role = "",
          } = res.data;

        changeState((state) => ({
          ...state,
          user_id,
          name: `${first_name} ${last_name}`,
          pin: res.data.pin,
          isLoading: false,
          isAuth,
          company_id,
          charges_enabled,
          permissions: res.data.permissions,
          currentstatus: res.data.currentstatus,
          owner,
          onboarding_complete,
          isSubscriptionActive,
          role,
        }));
      },
    }
  );

  return (
    <AuthContext.Provider value={state}>
      {!state.isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
