import { ChangeEvent, useEffect, useRef, useState, useCallback } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { useDropzone } from "react-dropzone";
import { useUpload } from "../context/UploadContext";

import { Button } from "components/button";
import DownloadTemplate from "./DownloadTemplate";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const UploadForm = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.UploadForm";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const {
    selected,
    setImportComplete,
    setResults,
    selectedCompany,
    setHeaders,
    selectedFileType,
    setSelectedFileType,
    setTriggerCurtainsClose,
    origFileName,
    setOrigFileName,
  } = useUpload();

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUnsupportedFormat, setIsUnsupportedFormat] = useState(false);
  const [currentStage, setCurrentStage] = useState(0);
  const [fileUploadComplete, setFileUploadComplete] = useState(false);
  const eventSourceRef = useRef<EventSource | null>(null);

  const checkFormat = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (
      fileExtension === "csv" ||
      fileExtension === "xls" ||
      fileExtension === "xlsx"
    ) {
      setSelectedFile(file);
      setSelectedFileType(fileExtension);
      setIsUnsupportedFormat(false); // Reset the warning
    } else {
      setIsUnsupportedFormat(true);
      setSelectedFile(null); // Reset the selected file
      setSelectedFileType(""); // Reset the selected file type
    }
  }, []);

  // Check file format on drop
  const onDrop = useCallback((acceptedFiles) => {
    checkFormat(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  // Check file format on file upload via browse button
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      checkFormat(e.target.files);
    }
  };

  const uploadExcel = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("company", selectedCompany);
      formData.append("fileType", selectedFileType);
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("type", selected);
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/uploadExcel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setOrigFileName(res.data.fileName);
      return res.data.fileName;
    }
  }

  // Returns headers
  const getHeaders = async (fileName: string) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/getHeaders/${selected}/${selectedFileType}/${fileName}`);
      setHeaders(Object.values(res.data.headers));
    } catch (error) {
      console.error("Error fetching import status", error);
    }
  };

  const handleUpload = async () => {
    setResults(null);
    setIsLoading(true);
    const fileName = await uploadExcel();
    await getHeaders(fileName);
    setTriggerCurtainsClose(true);
    setImportComplete(true);
    setIsLoading(false);
  };

  const handleRemove = () => {
    if (isLoading) return; // Prevent removing the file while uploading
    setFileUploadComplete(false);
    setSelectedFile(null);
    setResults(null);
    setImportComplete(false);

    // Reset the file input
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  };

  // Timing for the text animations
  useEffect(() => {
    if (selectedFile) {
      const timeouts = [
        setTimeout(() => setCurrentStage(1), 1),
        setTimeout(() => setCurrentStage(2), 3),
        setTimeout(() => setCurrentStage(3), 7),
        setTimeout(() => setCurrentStage(4), 10),
        setTimeout(() => setCurrentStage(5), 12),
      ];

      // Cleanup timeouts
      return () => timeouts.forEach(clearTimeout);
    }
  }, [selectedFile]);

  // Check if the last animation has completed
  useEffect(() => {
    if (currentStage === 4) {
      setTimeout(() => {
        // console.log("Last animation completed");
        setFileUploadComplete(true);
      }, 1000);
    }
  }, [currentStage]);

  useEffect(() => {
    return () => {
      // Check if EventSource exists and is in open state before closing
      if (eventSourceRef.current && eventSourceRef.current.readyState === 1) {
        eventSourceRef.current.close();
        console.log("EventSource closed on cleanup");
      }
    };
  }, []);

  // Conditionally render the text based on the current stage
  const renderStageText = () => {
    switch (currentStage) {
      case 1:
        return tr("Getting your file ready...");
      case 2:
        return tr("Extracting data...");
      case 2.5:
        return tr("Uploading duplicates...");
      case 3:
        return tr("Verifying data...");
      case 4:
        return tr("Upload successful");
      case 5:
        return (
          selectedFile &&
          fileUploadComplete && (
            <div className="flex justify-center items-center animate-emphasis w-[500px]">
              <span>{selectedFile?.name}</span>
              <AiOutlineCloseCircle
                className={`
                text-2xl ml-1 text-[#ff0000] hover:text-white rounded-full
                ${
                  isLoading
                    ? "hover:cursor-not-allowed hover:bg-gray-400"
                    : "hover:cursor-pointer hover:bg-[#ff0000]"
                }
              `}
                onClick={handleRemove}
              />
            </div>
          )
        );
      default:
        return "";
    }
  };

  // controls where the icon + spinner + checkmark are placed
  const iconStylePlus = "absolute top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2";

  return (
    <div className="w-full h-full">
      <div className="flex justify-evenly items-center flex-col h-full min-w-[550px]">
        <div className="text-center">
          <div className="text-lg text-secondary">{tr("Upload a file")}</div>
          <div className="text-gray-400">
            {tr("You can upload CSV or XLS files to import your")} {selected}
          </div>
          <div className="text-red-400 text-sm underline">
            {tr("Please ensure that there is only")}
            <span className="font-bold text-red-600"> {tr("one worksheet")}</span> {tr("in your excel import")}
          </div>
        </div>

        <div className="w-1/2 h-1/2 relative min-h-[300px] min-w-[270px]">
          {/* Dropzone */}
          <div
            {...getRootProps()}
            className={`
              dropzone bg-[#f9f9ff]  w-full h-full rounded-xl 
              border-dashed border-gray-400 border-2
              
              ${isDragActive ? "bg-[#dedee6] border-secondary" : ""}
              ${selectedFile ? "animate-implode duration-500" : ""}
            `}
          >
            <div className="text-center text-gray-400 absolute top-[65%] left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="text-lg ">{tr("Drag and drop your file here")}</div>
              <div>{tr("or")}</div>
              <label className="hover:text-primary cursor-pointer">
                {tr("Browse")}
                <input
                  ref={hiddenFileInput}
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          {/* Spinner */}
          <div className={iconStylePlus}>
            <div
              className={
                selectedFile && fileUploadComplete
                  ? "animate-implode duration-500"
                  : selectedFile
                  ? "animate-explode duration-500"
                  : "hidden"
              }
            >
              <svg
                className="animate-spin h-32 w-32 text-blue-400"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>

          {/* Checkmark */}
          <div className={iconStylePlus}>
            <div
              className={
                selectedFile && fileUploadComplete
                  ? "animate-emphasis"
                  : "hidden"
              }
            >
              <FaCheck className="text-3xl text-green-600" />
            </div>
          </div>

          {/* Icon */}
          <div className={iconStylePlus}>
            <BsFileEarmarkSpreadsheet className="text-6xl text-secondary opacity-50" />
          </div>

          {/* Display Text */}
          <div
            className={`absolute top-[68%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-1000 ${
              currentStage > 0 ? "scale-1" : "scale-0"
            }`}
          >
            <div className="flex text-center text-lg text-gray-400">
              {renderStageText()}
            </div>
          </div>

          {/* Import Button */}
          {selectedFile && fileUploadComplete && (
            <div className="absolute top-[83%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Button onClick={handleUpload} disabled={isLoading}>
                {tr("Import")}
                {isLoading && (
                  <svg
                    className="animate-spin h-5 w-5 text-blue-400"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </Button>
            </div>
          )}
          
          {/* Warning message */}
          <div className="pt-2">
            {isUnsupportedFormat && (
              <div className="text-center text-[16px] text-red-500">
                {tr("Unsupported file format. Please import a CSV or XLS file.")}
              </div>
            )}
            {selectedFile && isLoading && (
              <div className="text-center text-[16px] text-red-500">
                {tr("Please do not close or leave this window until the import is complete.")}
              </div>
            )}
          </div>
        </div>

        {/* Download Template */}
        {!selectedFile ? (
          // Template
          <div className="h-20">
            {selected === "inventory" && <DownloadTemplate label={tr("Inventory Import Template")} type="product" />}
            {selected === "services" && <DownloadTemplate label={tr("Services Import Template")} type="services" />}
            {selected === "customers" && <DownloadTemplate label={tr("Customers Import Template")} type="customer" />}
          </div>
        ) : (
          // Spacer
          <div className="h-20"></div>
        )}
      </div>
    </div>
  );
};

export default UploadForm;
