import React, { useEffect, useState } from "react";
import "./App.css";
import logo from "images/eserv.png";
import {
  ComposedChart,
  Area,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
  BarChart,
  Label,
  LabelList,
  RadialBarChart,
  RadialBar,
  PolarRadiusAxis,
  PieChart,
  Pie,
  Text,
  Cell,
} from "recharts";
import { useSearchParams } from "react-router-dom";
import ProgressBar from "./Progress Bar";
import DisplayBar from "./Divisioncard";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Spinner from "components/spinner";
import { GoHorizontalRule } from "react-icons/go";
import Divisioncard from "./Divisioncard";
import { useTranslation } from 'react-i18next';

export default function Production() {
  // MARK:  establishing constants and setting states
  const { t } = useTranslation();
  const tBase = "views.production.App";
  const tr = (key) => t(`${tBase}.${key}`);

  const RADIAN = Math.PI / 180;

  const COLORS = [
    "#23f0b6",
    "#ff7f7f",
    "#ffff00",
    "#00ffff",
    "#00ff00",
    "#ff00ff",
    "#ff0000",
  ];

  const URLpage = window.location.search;

  const [companydata, setcompanydata] = useState(0);

  const [bufferdata, setbufferdata] = useState(0);

  const [listofdivisions, setlistofdivisions] = useState(() => {
    return [];
  });

  const usersdata = useQuery(
    ["usersproduction"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/production/
  `
        )
        .then((res) => {
          const write = [];
          res.data.forEach((item) => {
            if (
              item.Division !== null &&
              item.Division !== undefined &&
              write.includes(item.Division) === false
            ) {
              write.push(item.Division);
            }
          });
          setlistofdivisions([...write]);
          return res.data;
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  const [page, setPage] = React.useState(() => {
    return 0;
  });

  const divisionsdata = (page) => {
    let returnval = [];
    if (page === "main") {
      returnval = listofdivisions.map((item) => {
        return {
          Name: item,
          LastWorked:
            item && usersdata
              ? makeintodollars(
                  usersdata.data
                    .filter(
                      (user) => user?.Name?.toString() === item.toString()
                    )
                    .reduce((a, b) => a + (b?.LastWorked || 0), 0)
                )
              : 0,
          weekly: makeintodollars(
            usersdata.data
              .filter((user) => user?.Name?.toString() === item.toString())
              .reduce((a, b) => a + (b?.weekly || 0), 0)
          ),
          ytd: makeintodollars(
            usersdata.data
              .filter((user) => user?.Name?.toString() === item.toString())
              .reduce((a, b) => a + (b?.ytd || 0), 0)
          ),
          goal: 100000,
        };
      });
    } else {
      returnval = usersdata.data
        ?.filter((user) => user.Division === page)
        .map((item) => {
          return {
            Name: item.Name,
            LastWorked:
              item && item.LastWorked ? makeintodollars(item.LastWorked) : 0,
            median: makeintodollars(
              Object.keys(item)
                .map((key) => {
                  if (key.includes("2023")) {
                    return item[key];
                  } else {
                    return 0;
                  }
                })
                .sort((a, b) => a - b)[
                Math.floor(
                  Object.keys(item).filter((key) => key.includes("2023"))
                    .length / 2
                )
              ] || item[Object.keys(item)[Object.keys(item).length - 1]]
            ),
            weekly: makeintodollars(item.weekly),
            ytd: item && item.ytd ? makeintodollars(item.ytd) : 0,
            goal: item.goal,
          };
        });
    }
    return returnval;
  };

  // MARK: setting up Helper functions

  const titlegen = (page) => {
    if (page === "main") {
      return "Full Company";
    } else {
      return "Division: " + page;
    }
  };

  const makeintodollars = (value) => {
    if (value === null || value === undefined) return 0;
    const stringversion = value.toString();
    const newvalue =
      stringversion.slice(0, stringversion.length - 2) +
      "." +
      stringversion.slice(stringversion.length - 2, stringversion.length);
    return +newvalue;
  };
  const getgoal = () => {
    if (page === "main") {
      return 500000;
    } else {
      return 100000;
    }
  };

  const nextpage = (page) => {
    // console.log(page)
    // console.log(listofdivisions)
    if (URLpage === "?001") {
      return "main";
    }
    if (page === 0) {
      if (+URLpage[3] - 1 <= listofdivisions.length) {
        // console.log(listofdivisions[+URLpage[3] - 2]);
        return listofdivisions[+URLpage[3] - 2];
      } else {
        return "main";
      }
    } else if (URLpage.includes("?") && !URLpage.includes("00")) {
      // console.log("URLpage.includes('?')&&!(URLpage.includes('00')");
      if (page === listofdivisions[+URLpage[1] - 2]) {
        // console.log(listofdivisions[+URLpage[3] - 2]);
        return listofdivisions[+URLpage[3] - 2];
      }
      if (page === listofdivisions[+URLpage[3] - 2]) {
        // console.log(listofdivisions[+URLpage[1] - 2]);
        return listofdivisions[+URLpage[1] - 2];
      }
    } else {
      // console.log("else");
      if (page === "main") {
        return listofdivisions[0];
      }
      if (
        listofdivisions.includes(page) &&
        page !== listofdivisions[listofdivisions.length - 1]
      ) {
        return listofdivisions[listofdivisions.indexOf(page) + 1];
      } else {
        return "main";
      }
    }
  };

  // MARK: setting hooks and UseEffects,etc

  const getCompanydata = useMutation(
    async (e) =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/production/companyprod`,
          {
            params: {
              division: e,
            },
          }
        )
        .then((res) => {
          return res.data.map((item) => {
            if (item) {
              item.ytd = item && item.ytd ? makeintodollars(item.ytd) : 0;
              item.amt = item && item.amt ? makeintodollars(item.amt) : 0;
              item.lastytd =
                item && item.amt ? makeintodollars(item.lastytd) : 0;
              return item;
            } else {
              return null;
            }
          });
        })
  );
  useEffect(() => {
    // console.log(page);
    if (page === "main") {
      setcompanydata(bufferdata);
    } else {
      if (page && page !== 0) {
        setcompanydata([
          {
            Name: page,
            lastytd: makeintodollars(
              usersdata.data
                .filter((user) => {
                  if (user.Name?.toString() === page.toString()) {
                    return true;
                  }
                  return false;
                })
                .reduce((a, b) => a + (b?.LastWorked || 0), 0)
            ),
            amt: makeintodollars(
              usersdata.data
                .filter((user) => user.Name?.toString() === page.toString())
                .reduce((a, b) => a + (b?.weekly || 0), 0)
            ),
            ytd: makeintodollars(
              usersdata.data
                ?.filter((user) => user.Name?.toString() === page.toString())
                .reduce((a, b) => a + (b?.ytd || 0), 0)
            ),
            goal: 100000,
          },
        ]);
      }
    }
  }, [page]);

  useEffect(() => {
    (async function () {
      if (page === "main") {
        setcompanydata(await getCompanydata.mutateAsync("main"));
        setbufferdata(await getCompanydata.mutateAsync("main"));
      }
    })();
    if (listofdivisions.length >= 1 && page === 0) {
      setPage(nextpage(page));
    }
  }, [usersdata.data, listofdivisions]);

  const changepage = () => {
    if (
      !(URLpage.includes("?") && URLpage.includes("0")) ||
      (URLpage.includes("?") && !URLpage.includes("00"))
    ) {
      setTimeout(() => {
        setPage(nextpage(page));
      }, 25000);
    }
  };

  // MARK: custom React components

  const Loadpage = () => {
    return (
      <div style={{ marginTop: "20%" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{
              width: "150px",
              alignSelf: "center",
            }}
            src={logo}
            alt="Logo"
          />
          <Spinner size="50px" />
        </div>
      </div>
    );
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = () => {
      if (index % 2 === 0) {
        return innerRadius - 20;
      } else {
        return outerRadius - 10;
      }
    };
    const labelstring = (Math.floor(percent * 100) || 1) + "%";
    let x = cx + radius() * Math.cos(-midAngle * RADIAN);
    let y = cy + radius() * Math.sin(-midAngle * RADIAN);
    if (x < cx - labelstring.length * 14) {
      x = x - labelstring.length * 14;
    } else if (
      x < cx + labelstring.length * 14 &&
      x > cx - labelstring.length * 14
    ) {
      x = x - labelstring.length * 7;
      if (y > cy) {
        y = y + 3;
      } else {
        y = y - 3;
      }
    }
    if (y < cy - 25.8) {
      y = y - 25.8;
    } else if (y < cy + 25.8 && y > cy - 25.8) {
      y = y - 12.5;
    }
    if (labelstring === "1%") {
      x = cx + outerRadius;
      y =
        cy +
        outerRadius * Math.sin(-midAngle * RADIAN) +
        Math.sin(-midAngle * RADIAN) * 100;
    }

    return (
      <foreignObject x={x} Anchor="middle" y={y} overflow={"visible"}>
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            backgroundColor: "#282C34",
            borderRadius: "5px",
            border: "3px solid" + COLORS[index % COLORS.length],
            paddingLeft: "4px",
            paddingRight: "4px",
            width: "fit-content",
            whiteSpace: "nowrap",
            color: COLORS[index % COLORS.length],
          }}
        >
          {labelstring}
        </div>
      </foreignObject>
    );
  };

  const Topsection = () => {
    if (page === "main") {
      let basedata = divisionsdata(page).sort((x, y) => {
        if (x.ytd > y.ytd) {
          return -1;
        }
        if (x.ytd < y.ytd) {
          return 1;
        }
        return 0;
      });
      return (
        <div className="px-1 third-container">
          <div
            className="cell-container"
            id="fourth-container"
            style={{ width: "98%" }}
          >
            <div style={{ width: "15%" }}>
              <h1
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: "#009900",
                  marginBottom: "20px",
                }}
              >
                {tr("Division Metrics")}
              </h1>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Highest Earner")}:</h2>
              <p style={{ color: "#23f0b6" }}>{basedata[0].Name}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{("Highest Earned")}:</h2>
              <p style={{ color: "#23f0b6" }}>${basedata[0].ytd}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Average Earnings")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {Math.floor(
                  basedata
                    .map((a) => {
                      return a.ytd || 0;
                    })
                    .reduce((a, b) => a + b, 0) / basedata.length
                )}
              </p>
              <hr />
            </div>
            <ResponsiveContainer debounce={20} width="85%" height="95%">
              <BarChart data={basedata}>
                <XAxis
                  stroke="#090"
                  dataKey="Name"
                  type="category"
                  scaleToFit={true}
                  label={{ scaleToFit: true }}
                  interval={0}
                />
                <YAxis
                  stroke="#090"
                  type="number"
                  domain={[0, Math.ceil(basedata[0].ytd / 1000) * 1000]}
                  tickCount={11}
                  tickFormatter={(value) => {
                    return "$" + value;
                  }}
                  scaleToFit={true}
                />
                <CartesianGrid stroke="#090" />

                <Bar
                  barSize={120}
                  isAnimationActive={false}
                  dataKey="ytd"
                  label={{
                    position: "insideTop",
                    formatter: (value) => {
                      return value;
                    },
                    fill: "#1D2026",
                  }}
                >
                  {basedata.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else {
      let basedata = divisionsdata(page).sort((x, y) => {
        if (x.LastWorked > y.LastWorked) {
          return -1;
        }
        if (x.LastWorked < y.LastWorked) {
          return 1;
        }
        return 0;
      });
      return (
        <div className="px-1 third-container">
          <div
            className="cell-container"
            id="fourth-container"
            style={{ width: "98%" }}
          >
            <div style={{ width: "15%" }}>
              <h1
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: "#009900",
                  marginBottom: "20px",
                }}
              >
                {tr("Daily totals")}
              </h1>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Highest Earner")}:</h2>
              <p style={{ color: "#23f0b6" }}>{basedata[0].Name}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Highest Earned")}:</h2>
              <p style={{ color: "#23f0b6" }}>${basedata[0].LastWorked}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Average Earnings")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {Math.floor(
                  basedata
                    .map((a) => {
                      return a.LastWorked || 0;
                    })
                    .reduce((a, b) => a + b, 0) / basedata.length
                )}
              </p>
              <hr />
            </div>

            <ResponsiveContainer debounce={20} width="85%" height="95%">
              <BarChart overflow="visible" data={basedata}>
                <XAxis
                  dataKey="Name"
                  type="category"
                  stroke="#009900"
                  scaleToFit={true}
                  label={{ scaleToFit: true }}
                  interval={0}
                />
                <YAxis
                  type="number"
                  domain={[0, Math.ceil(basedata[0].LastWorked / 100) * 100]}
                  scaleToFit={true}
                  tickCount={11}
                  stroke="#009900"
                  tickFormatter={(value) => {
                    return "$" + value;
                  }}
                />
                <CartesianGrid stroke="#009900" />

                <Bar
                  isAnimationActive={false}
                  dataKey="LastWorked"
                  label={{
                    scaleToFit: true,
                    position: "insideTop",
                    formatter: (value) => {
                      if (value > 0) {
                        return Math.floor(value);
                      } else {
                        return "";
                      }
                    },
                    fill: "#1D2026",
                  }}
                >
                  {basedata.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
                <text
                  y="93%"
                  x="4%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="end"
                  verticalAnchor="bottom"
                >
                  {tr("Name")}-
                </text>
                <text
                  y="100%"
                  x="4%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="end"
                  verticalAnchor="bottom"
                >
                  ({tr("Daily Median")})
                </text>
                {basedata.map((entry, index) => {
                  let xpos = (
                    (index + 1) * (96 / basedata.length) -
                    96 / basedata.length / 2 +
                    4 +
                    "%"
                  ).toString();
                  return (
                    <text
                      y="100%"
                      x={xpos}
                      dy={+12}
                      style={{ fill: "#009900" }}
                      width={100 / (basedata.length + 1) + "%"}
                      scaleToFit={true}
                      textAnchor="middle"
                      verticalAnchor="bottom"
                    >
                      (${entry.median})
                    </text>
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  };
  const Bottomsection = () => {
    const basedata = divisionsdata(page).sort((x, y) => {
      if (x.ytd > y.ytd) {
        return -1;
      }
      if (x.ytd < y.ytd) {
        return 1;
      }
      return 0;
    });
    if (page === "main") {
      return (
        <div className="px-1 third-container">
          <div
            className="cell-container"
            id="fourth-container"
            style={{ width: "98%" }}
          >
            <div style={{ width: "15%", marginRight: "4%" }}>
              <h1
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: "#009900",
                  marginBottom: "20px",
                }}
              >
                {tr("Weekly Metrics")}:
              </h1>
              <hr />
              <h2 style={{ color: "#ff4e23" }}>{tr("Average Week")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {Math.floor(
                  companydata.reduce((a, b) => a + (b?.amt || 0), 0) / 52
                ).toLocaleString()}
              </p>
              <hr />
              <h2 style={{ color: "#ff4e23" }}>{tr("Best Week")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {Math.max
                  .apply(
                    null,
                    companydata.map((a) => {
                      return a?.amt;
                    })
                  )
                  .toLocaleString()}
              </p>
              <hr />
              <h2 style={{ color: "#ff4e23" }}>{tr("This Week")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {(
                  companydata[
                    Object.keys(companydata)[
                      Object.keys(companydata).length - 1
                    ]
                  ]?.amt || 0
                ).toLocaleString()}
              </p>
              <hr />
              <h2 style={{ color: "#ff4e23" }}>{tr("This Week last year")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {(
                  companydata[Object.keys(companydata)[0]]?.amt || 0
                ).toLocaleString()}
              </p>
              <hr />
            </div>
            <ResponsiveContainer debounce={20} width="80%">
              <ComposedChart
                overflow={"visible"}
                margin={{ top: 5, right: 20, bottom: 5, left: 5 }}
                data={companydata}
              >
                <CartesianGrid stroke="#009900" />
                <XAxis
                  stroke="#009900"
                  name="Week"
                  Unit="weeks"
                  dataKey="week"
                />
                <YAxis
                  stroke="#009900"
                  yAxisId="left"
                  scaleToFit={true}
                  tickFormatter={(value) => {
                    return "$" + value;
                  }}
                />
                <YAxis
                  stroke="#009900"
                  yAxisId="right"
                  scaleToFit={true}
                  tickFormatter={(value) => {
                    return "$" + value;
                  }}
                  orientation="right"
                />

                <Legend />

                <Bar
                  yAxisId="left"
                  name="Year to Date Total"
                  dataKey={"ytd"}
                  barSize={5}
                  fill="#23f0b6"
                  stroke="#1D2026"
                  isAnimationActive={true}
                />
                <Bar
                  yAxisId="left"
                  name="Last Year to Date Total"
                  dataKey={"lastytd"}
                  barSize={5}
                  fill="#0077ff"
                  stroke="#1D2026"
                  isAnimationActive={true}
                />
                <Line
                  yAxisId="right"
                  name="Weekly Earnings"
                  type="monotone"
                  dot={true}
                  dataKey="amt"
                  stroke="#f04e23"
                  fill="#009900"
                  strokeWidth={2}
                  isAnimationActive={true}
                />
                <Tooltip
                  wrapperStyle={{ backgroundColor: "#282C34" }}
                  contentStyle={{ background: "#282C34" }}
                  formatter={(value) => {
                    if (isNaN(value)) {
                      return value;
                    } else {
                      return "$" + value.toLocaleString();
                    }
                  }}
                />
                <text
                  y="70%"
                  x="4%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="end"
                  verticalAnchor="bottom"
                >
                  YtD {tr("Scale")}
                </text>
                <text
                  y="70%"
                  x="94.5%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="start"
                  verticalAnchor="bottom"
                >
                  {tr("Week Scale")}
                </text>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    } else {
      return (
        <div className="px-1 third-container">
          <div
            className="cell-container"
            id="fourth-container"
            style={{ width: "98%" }}
          >
            <div style={{ width: "15%" }}>
              <h1
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: "#009900",
                  marginBottom: "20px",
                }}
              >
                {tr("Year-to-Date totals")}
              </h1>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Highest Earner")}:</h2>
              <p style={{ color: "#23f0b6" }}>{basedata[0].Name}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Highest Earned")}:</h2>
              <p style={{ color: "#23f0b6" }}>${basedata[0].ytd}</p>
              <hr />
              <h2 style={{ color: "#f04e23" }}>{tr("Average Income")}:</h2>
              <p style={{ color: "#23f0b6" }}>
                $
                {Math.floor(
                  basedata
                    .map((a) => {
                      return a.ytd || 0;
                    })
                    .reduce((a, b) => a + b, 0) /
                    (basedata.length - 1)
                )}
              </p>
              <hr />
            </div>
            <ResponsiveContainer debounce={20} width="85%" height="95%">
              <BarChart overflow="visible" data={basedata}>
                <XAxis
                  stroke="#009900"
                  dataKey="Name"
                  type="category"
                  label={{ scaleToFit: true }}
                  interval={0}
                />
                <YAxis
                  stroke="#009900"
                  type="number"
                  domain={[0, Math.ceil(basedata[0].ytd / 1000) * 1000]}
                  tickCount={11}
                  tickFormatter={(value) => {
                    return "$" + value;
                  }}
                />
                <CartesianGrid stroke="#009900" scaleToFit={true} />

                <Bar
                  isAnimationActive={false}
                  dataKey="ytd"
                  label={{
                    scaleToFit: true,
                    position: "insideTop",
                    formatter: (value) => {
                      return Math.floor(value);
                    },
                    fill: "#1D2026",
                  }}
                >
                  {basedata.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
                <text
                  y="93%"
                  x="4%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="end"
                  verticalAnchor="bottom"
                >
                  {tr("Name")}-
                </text>
                <text
                  y="100%"
                  x="4%"
                  dy={+12}
                  style={{ fill: "#009900" }}
                  width={100 / (basedata.length + 1) + "%"}
                  scaleToFit={true}
                  textAnchor="end"
                  verticalAnchor="bottom"
                >
                  ({tr("Weekly Median")})
                </text>
                {basedata.map((entry, index) => {
                  let xpos = (
                    (index + 1) * (96 / basedata.length) -
                    96 / basedata.length / 2 +
                    4 +
                    "%"
                  ).toString();
                  return (
                    <text
                      y="100%"
                      x={xpos}
                      dy={+12}
                      style={{ fill: "#009900" }}
                      width={100 / (basedata.length + 1) + "%"}
                      scaleToFit={true}
                      textAnchor="middle"
                      verticalAnchor="bottom"
                    >
                      {"(" + "$" + Math.floor(entry.median * 7) + ")"}
                    </text>
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    }
  };

  // MARK: returns and alt returns
  if (page === 0) return <Loadpage />;
  if (companydata === 0 && page === "main") return <Loadpage />;
  if (!usersdata.data) return <Loadpage />;

  return (
    <div className="space-y-1 App">
      <h1
        style={{
          textAlign: "center",
          textDecoration: "underline",
          color: "#009900",
        }}
      >
        {titlegen(page)}
      </h1>
      <ProgressBar
        data={{
          ytdtotal:
            companydata[
              Object.keys(companydata)[Object.keys(companydata).length - 1]
            ]?.ytd,
          goal: getgoal(),
          lastytd:
            companydata[
              Object.keys(companydata)[Object.keys(companydata).length - 1]
            ]?.lastytd,
        }}
      />
      <Topsection />
      <Bottomsection onLoad={changepage()} />
    </div>
  );
}
