/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import styled from 'styled-components'
import { FaLongArrowAltRight } from 'react-icons/fa'
import Avatar from '@material-ui/core/Avatar'
import { useTranslation } from 'react-i18next';

const Card = styled.div`
    // border: 1px solid #F3F3F3;
    // box-shadow: 0 0 10px -3px grey;
    background: white;
    height: 250px;
    margin-right: 1rem;
    width: 230px;
    border-radius: 10px;
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div:last-child {
        display: flex;
        margin-top: 1rem;
        justify-content: space-around;
        align-items: center;
        width: 80%;
        flex-direction: row;
    }
    > div:nth-child(2) {
        margin-bottom: -0.2rem;
    }
    > div:nth-child(3) {
        font-size: 1.2rem;
    }
    > div:nth-child(4) {
        // color: #6D6D6D;
        font-weight: lighter;
        text-align: center;
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px -7px black;
    }
`

// const Img = styled(Avatar)`
//     border-radius: 50%;
//     // border: 0.5px solid #CCEAFF;
//     width: 100px;
//     height: 100px;
//     background: #F9F9F9;
//     margin-bottom: 5rem;
// `

const Date = styled.div`
    > div:first-child {
        font-size: 1.1rem;
        font-weight: bold;
        color: red;
    }
    > div:last-child {
        
        font-size: 1.2rem;
        text-align: center;
        line-height: 1rem;
        // font-weight: lighter;
    }
`

const Dayleft = styled.div`
    position: absolute;
    color: red;
    top: 0;
    right: 0;
    font-size: 0.9rem;
    margin: 0.5rem;
`

export default function (props) {
    const { t } = useTranslation();
    const tBase = "views.team.sick.card";
    const tr = (key) => t(`${tBase}.${key}`);

    let { name, title, start, end, difference, image, clicked } = props
    start = start.split('-');
    end = end.split('-');

    return (
        <Card onClick={() => clicked()}>
            <Dayleft>{difference} {tr("Days")}</Dayleft>
            <Avatar src={image} style={{width: "5rem", height: "5rem", marginBottom: "1rem"}}></Avatar>
            <div>{name}</div>
            <div>{title}</div>
            <div>
                <Date><div>{start[0].toUpperCase()}</div><div>{start[1]}</div></Date>
                <div><FaLongArrowAltRight style={{fontSize: "1.5rem"}}/></div>
                <Date><div>{end[0].toUpperCase()}</div><div>{end[1]}</div></Date>
            </div>
            
        </Card>
    )
}