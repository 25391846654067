import { NoStyleA, SubtleA } from "components/button";
import DataLabel from "components/dataLabel";
import Payments from "containers/payments";
import * as React from "react";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';

type Props = {
  data: TPayment[] | null;
};
export default function PaymentContainer(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.invoice.payment";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { data } = props;

  return (
    <div className="divide-y">
      <div className="py-4">
        <h2>{tr("Payment Details")}</h2>
      </div>
      <div className="py-4">
        {data ? (
          <Payments data={data} type="invoice" />
        ) : (
          <Placeholder
            type="payment"
            label={tr("Payment info will be shown here if available")}
            header={tr("No Payment Info")}
          />
        )}
      </div>
    </div>
  );
}
