import axios from "axios";
import TooltippedElement from "components/TooltippedElement";
import { Button, OutlineButton, SubtleIconButton } from "components/button";
import Field, { FieldProps } from "components/field";
import * as React from "react";
import { RiPencilLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

type Props = Omit<FieldProps, "type"> & {
  value: string | number | null | undefined;
  placeholder?: string;
  updateUrl: string;
  className?: string;
  type?: string;
  onSubmit?: (e: any) => any;
};
export default function InlineEdit(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.inline_edit";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isEdit, setIsEdit] = React.useState(false);
  const [value, setValue] = React.useState<string | number>("");

  const queryClient = useQueryClient();

  React.useEffect(() => {
    props.value && setValue(props.value);
  }, [props.value]);

  const mutation = useMutation(
    async (e: { value: any }) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/${props.updateUrl}
    `,
        e
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setIsEdit(false);
      },
    }
  );
  return (
    <div style={{width:'100%'}} className={`flex items-center ${props.className}`}>
      {isEdit ? (
        <div className="space-y-1">
          <Field
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            autoFocus
          />
          <div className="flex justify-end space-x-1">
            <Button
              size="small"
              primary
              onClick={() =>
                props.onSubmit
                  ? mutation.mutate({ value: props.onSubmit(value) })
                  : mutation.mutate({ value: value })
              }
              isLoading={mutation.isLoading}
            >
              {tr("Save")}
            </Button>
            <OutlineButton size="small" onClick={() => setIsEdit(false)}>
              {t("Cancel")}
            </OutlineButton>
          </div>
        </div>
      ) : (
        <>
          {props.value ? (
            <span>{props.value}</span>
          ) : (
            <span className="text-slate-400">
              {props.placeholder || tr("Empty")}
            </span>
          )}
          <TooltippedElement 
          message={tr("Edit")}
          element={
          <SubtleIconButton style={{float:'right'}} onClick={() => setIsEdit(true)} size="small">
            <RiPencilLine />
          </SubtleIconButton>}/>
        </>
      )}
    </div>
  );
}
