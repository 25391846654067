import axios from "axios";
import Pagination from "components/table/pagination";
import SafeArea from "components/safe-area";
import useQueryString from "hooks/useQueryString";
import * as React from "react";
import { useQuery } from "react-query";
import { TProperty } from "types/property";
import { Button } from "components/button";
import Field from "components/field";
import { RiAddLine } from "react-icons/ri";
import { useModalContext } from "components/modal";
import NewPropertyModal from "./newPropertyModal";
import PropertyCon from "./propertyCon";
import Spinner from "components/spinner";
import { useTranslation } from 'react-i18next';

export default function Properties() {
  const { t } = useTranslation();
  const tBase = "views.properties.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [search, setSearch] = React.useState("");

  const queryString = useQueryString(),
    modal = useModalContext();

  const pageIndex =
    queryString.search.page && !Array.isArray(queryString.search.page)
      ? +queryString.search.page
      : 1;

  React.useEffect(() => {
    queryString.search?.search &&
      !Array.isArray(queryString.search?.search) &&
      setSearch(queryString.search.search);
  }, [queryString.search.search]);

  const query = useQuery<{
    properties: TProperty[] | null;
    page_count: number;
    total_count: number;
  }>(
    ["properties", queryString.search],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property`,
          {
            params: queryString.search,
          }
        )
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  if (query.isLoading) return <Spinner />;

  const data = query.data;

  const populateProperties = query.data?.properties?.map((property) => {
    return <PropertyCon key={property.id} property={property} />;
  });

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="py-4 space-y-2">
          <div className="flex items-center">
            <h1>{tr("Properties")}</h1>
            <span className="ml-4 text-slate-500 font-regular text-sm">
              {data?.total_count} {tr("Properties")}
            </span>
            <div className="ml-auto">
              <Button
                primary
                onClick={() =>
                  modal.setModal({
                    component: <NewPropertyModal />,
                    label: tr("New Property"),
                  })
                }
              >
                <RiAddLine /> {tr("Create Property")}
              </Button>
            </div>
          </div>

          <Field
            type="input"
            placeholder={tr("Search property")}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" && queryString.update({ search, page: 1 })
            }
          />
        </div>
      </SafeArea>
      <SafeArea>
        <div className="flex flex-col items-center">
          <div className="divide-y w-full">{populateProperties}</div>
          <Pagination
            pageCount={data?.page_count}
            pageIndex={pageIndex}
            handleButtonClick={(index) =>
              queryString.update({
                search: queryString.search.search,
                page: index,
              })
            }
          />
        </div>
      </SafeArea>
    </div>
  );
}
