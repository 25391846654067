import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Switch from "@material-ui/core/Switch";
import Avatar from "@material-ui/core/Avatar";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	margin-left: 10vw;
	align-items: flex-start;
	justify-content: center;
	padding: 3rem 0.5vw 3rem 0.5vw;
	position: relative;
	@media (max-width: 500px) {
		margin-left: 0;
		flex-direction: column;
		align-self: center;
	}
`;
// const ImageCon = styled.div`
//     justify-content: center;
//     align-items: center;
//     > div {
//         // width: 100%;
//         max-width: 300px;
//         background: #F3F3F3;
//         height: 300px;
//         // max-height: 400px;
//         border-radius: 5px;
//     }

// `

const Desc = styled.div`
	padding: 0 1rem 0 1rem;
	margin-left: 2vw;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	> div:nth-child(2) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: 0.5rem;
	}
	> div:last-child {
		color: grey;
		font-weight: 500;
		font-size: 0.85rem;
	}
	@media (max-width: 500px) {
		margin-top: 1rem;
		align-items: center;
	}
`;

const UserInfoDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 1rem;
	position: relative;
	justify-content: flex-start;
	> * {
		color: grey;
		font-weight: 600;
		font-size: 0.9rem;
	}
	&:not(:last-child) {
		&:after {
			content: "";
			height: 5px;
			width: 5px;
			border-radius: 50%;
			background: grey;
			position: absolute;
			right: -0.5rem;
			transform: translateX(2.5px);
		}
	}
`;

const UserName = styled.div`
	font-size: 1.5rem;
	font-weight: 300;
	margin-bottom: 1rem;
	color: grey;
	display: flex;
	align-items: center;
`;

const Edit = styled.button`
	font-size: 0.9rem;
	font-weight: 500;
	background: white;
	margin-left: 1vw;
	border: 1px solid #f0f0f0;
	padding: 0.5rem 1rem 0.5rem 1rem;
	border-radius: 5px;
	color: #313131;
	&:hover {
		cursor: pointer;
	}
`;

const UserInfo = (props) => {
	const { t } = useTranslation();
	const tBase = "views.team.viewUser.userInfo";
	const tr = (key) => t(`${tBase}.${key}`);

	const history = useHistory();

	const [status, setStatus] = useState(false);
	const [data, setData] = useState("");
	// const [ editable, setEdit ] = useState(true);
	// const [ blob, setBlob ] = useState();
	const [image, setImage] = useState("");
	const id = props.id;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team/edit`,
					{
						params: {
							type: "ONE",
							id: id,
						},
					}
				);

				setData(res.data[0][0]);
				setImage(res.data[1][id]);
				setStatus(
					res.data[0][0]["employee_status"] === "Inactive"
						? false
						: true
				);
			} catch (e) {
				throw e;
			}
		};

		fetchData();
	}, [id]);

	const statusChanged = async () => {
		try {
			await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
				{
					type: "CHANGESTATUS",
					status: status ? "Inactive" : "Active",
					id: id,
				}
			);
			setStatus(!status);
		} catch (e) {
			throw e;
		}
	};

	return (
		<Con>
			<Avatar
				src={image}
				style={{ alignSelf: "center", width: "120px", height: "120px" }}
			/>
			<Desc>
				<UserName>
					<div>
						{(data["first_name"] || "") +
							" " +
							(data["last_name"] || "")}
					</div>
					<Edit onClick={() => history.push(`./${id}/edit`)}>
						{tr("Edit Profile")}
					</Edit>
				</UserName>
				<div>
					<UserInfoDiv>
						<div>{data["employee_job_title"] || ""}</div>
					</UserInfoDiv>
					<UserInfoDiv>
						<div>{data["employee_birth_date"] || ""}</div>
					</UserInfoDiv>
					<UserInfoDiv>
						<div>{data["employee_status"] || ""}</div>
					</UserInfoDiv>
				</div>
				<div>
					{tr("Current Status")} :
					<Switch
						size="small"
						checked={status}
						onChange={() => statusChanged()}
					/>
					{status ? "Active" : "Inactive"}
				</div>
			</Desc>
		</Con>
	);
};

export default UserInfo;
