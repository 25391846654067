import * as React from "react";
import styled from "styled-components";
import { dollarFormatter } from "utils";
import { Status } from "../views/request/component/status";
import { SubtleIconButton } from "components/button";
import { RiDownloadCloudFill } from "react-icons/ri";
import Placeholder from "../views/request/component/placeholder";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { useQuery } from "react-query";
import axios from "axios";
import { format, isValid, parseISO } from "date-fns";
import { TInvoice } from "types/invoice";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Invoice = {
  id: number;
  stripe_id: string;
  fk_request_id: number;
  amount_due: number;
  hosted_invoice_url: string;
  invoice_pdf: string;
  status: Status;
} | null;

type Status = {
  label: string;
  color: string;
};

type Props = {
  id?: string;
  type?: "request" | "project" | "payment";
  data?: TInvoice[];
};

export default function InvoicesList({ ...props }: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.invoicesList";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory();

  const locale = i18n.language === "es" ? es : enUS;

  const query = useQuery<{ invoices: TInvoice[] | null }>(
    ["invoices", props.id, props.type],
    async () =>
      props.data
        ? { invoices: props.data }
        : await axios
            .get(
              `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoices?id=${props.id}&type=${props.type}
  `
            )
            .then((res) => res.data)
  );

  const data = query.data?.invoices;

  const handleClick = (row: any) => {
    history.push(`/invoices/${row.id}`);
  };

  const columns = {
    id: {
      label: "ID",
      cell: (row: any) => <div className="font-mono">{row.id}</div>,
    },
    total: {
      label: "Total",
      cell: (row: any) => dollarFormatter(row.actual_total),
    },
    amount_due: {
      label: tr("Amount Due"),
      cell: (row: any) => dollarFormatter(row.amount_due),
    },
    status: {
      label: tr("Status"),
      width: "100%",
      cell: (row: any) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    creation_date: {
      label: tr("Created On"),
      cell: (row: any) => {
        try {
          const parsedDate = parseISO(row.creation_date);
          if (!isValid(parsedDate)) {
            throw new Error(`Invalid date: ${row.creation_date}`);
          }
          return format(parsedDate, "MMM dd, yyyy hh:mm aa", {
            locale: locale,
          });
        } catch (error) {
          console.error(`Error formatting date: ${row.creation_date}`, error);
          return "Invalid Date";
        }
      },
    },
    action: {
      label: "",
      cell: (row: any) => (
        <SubtleIconButton
          size="xxsmall"
          onClick={() => window.open(row.invoice_pdf, "_blank")}
        >
          <RiDownloadCloudFill />
        </SubtleIconButton>
      ),
    },
  };

  return (
    <div>
      {data && data.length > 0 && data[0] !== null ? (
        <Table columns={columns} data={data} handleClick={handleClick} />
      ) : (
        <Placeholder
          type="invoice"
          header={tr("No invoices yet.")}
          label={tr("Invoices will appear here when added.")}
        />
      )}
    </div>
  );
}

const Con = styled.div``;
