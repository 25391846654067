import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";

export const getEquipment = async () => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: "/equipment",
  })
    .then(onSuccess)
    .catch(onError);
};
