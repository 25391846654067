import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  progress: number;
  type?: "percent" | "division";
  total?: number;
};

export default function ProgressBar(props: Props) {
  const width =
    props.type === "division"
      ? props.total
        ? (props.progress / props.total) * 100
        : 0
      : props.progress;
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <Con progress={width} />
      <span style={{ color: theme.color.subtext2, marginLeft: "0.5rem" }}>
        {props.type === "division"
          ? `${props.progress}/${props.total || "-"}`
          : props.progress + "%"}
      </span>
    </div>
  );
}

const Con = styled.div<{ progress: number }>`
  width: 100%;
  position: relative;
  height: 5px;
  background: ${theme.bg.blue};
  border-radius: 25px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    width: ${(props) => props.progress}%;
    background: ${theme.color.main};
  }
`;
