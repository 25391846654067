import Select, {
  StylesConfig,
  GroupTypeBase,
  ControlProps,
  IndicatorProps,
  IndicatorContainerProps,
  SingleValueProps,
  ValueContainerProps,
  InputProps,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import * as React from "react";
import styled, { CSSObject, css } from "styled-components";
import { StyledLabel, Asterick, Error } from "components/input/style";
import { theme } from "styles/theme";
import { MenuPortalProps } from "react-select/src/components/Menu";
import { useTranslation } from 'react-i18next';

const containerSize = (size?: string) => {
  switch (size) {
    case "large":
      return `
      padding: 0.6em;
      font-size: 1rem;
      `;

    case "small":
      return `
      font-size: 0.8rem;
      `;
    default:
      return `
      font-size: 0.9rem;
      `;
  }
};

const customStyles: StylesConfig<{}, false, GroupTypeBase<{}>> = {
  control: (
    base: CSSObject,
    state: ControlProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: "0.5em",
    minHeight: 0,
    fontSize: "0.9rem",
    boxShadow: "none",
    margin: 0,
    "&:hover": {
      background: "transparent",
      border: "1px solid #f0f0f0",
    },
    "&:focus": {
      padding: 0,
    },
    border: `1px solid ${state.isFocused ? "#3498db" : theme.border.light}`,
  }),
  indicatorSeparator: (
    base: CSSObject,
    props: IndicatorProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
    margin: "0 0.1em",
  }),
  indicatorsContainer: (
    base: CSSObject,
    props: IndicatorContainerProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
  }),
  menuPortal: (
    base: CSSObject,
    props: MenuPortalProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    zIndex: 99999,
    minWidth: "200px",
  }),
  dropdownIndicator: (
    base: CSSObject,
    props: IndicatorProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (
    base: CSSObject,
    props: SingleValueProps<{}, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
    lineHeight: 1.4,
  }),
  valueContainer: (
    base: CSSObject,
    props: ValueContainerProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
  }),
  input: (base: CSSObject, props: InputProps) => ({
    ...base,
    padding: 0,
    margin: 0,
    lineHeight: 1.4,
  }),
  clearIndicator: (
    base: CSSObject,
    props: IndicatorProps<{}, false, GroupTypeBase<{}>>
  ) => ({
    ...base,
    padding: 0,
  }),
};

type Props = {
  name?: string;
  onChange?: (e: any) => void;
  options: {
    label: string;
    value: string | number;
  }[];
  value?: string | number | object | null | any;
  placeholder?: string;
  label?: React.ReactNode;
  required?: boolean;
  error?: string;
  size?: string;
  creatable?: boolean;
  style?: object;
  passive?: boolean;
  isClearable?: boolean;
  components?: any;
  isMulti?: boolean;
  styles?: any;
  className?: string;
};

export default function ReactSelect(props: Props) {
  let {
    className,
    creatable,
    style,
    required,
    label,
    placeholder,
    size,
    error,
    passive,
    ...rest
  } = props;

  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key: string) => t(`${tBase}.${key}`);

  label = label && (
    <StyledLabel {...{ size }}>
      {label}
      {required && <Asterick>(Required)</Asterick>}
    </StyledLabel>
  );

  return (
    <SelectCon {...{ style }}>
      {label}
      {creatable ? (
        <StyledCreatableSelect
          styles={customStyles}
          classNamePrefix={"Select"}
          isClearable={true}
          menuPortalTarget={document.getElementById("react-select")}
          menuPosition={"absolute"}
          menuPlacement={"auto"}
          placeholder={props.placeholder || ""}
          {...props}
        />
      ) : (
        <Select
          styles={customStyles}
          classNamePrefix={"Select"}
          isClearable={true}
          menuPortalTarget={document.getElementById("react-select")}
          menuPosition={"absolute"}
          menuPlacement={"auto"}
          placeholder={props.placeholder || tr("None")}
          {...props}
        />
      )}
      {error && <Error>{error}</Error>}
    </SelectCon>
  );
}

const StyledSelect = styled(Select)<{ passive?: boolean; size?: string }>`
  & .Select__control {
    ${(props) => containerSize(props.size)};
    ${(props) =>
      props.passive &&
      css`
        background: transparent;
        padding-left: 0;
        &:hover {
          background: #f0f0f0;
          border: 1px solid transparent;
        }
      `}
  }
  & .Select__indicators {
    ${(props) =>
      props.passive &&
      css`
        display: none;
        &:focus {
          display: flex;
        }
      `}
  }
`;

const SelectCon = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const StyledCreatableSelect = styled(CreatableSelect)``;
