import axios from "axios";
import { Button, OutlineButton, SubtleButton } from "components/button";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { Action, Category, useQuestionBuilderDispatch } from "./context";
import { useTranslation } from 'react-i18next';

type TProps = {
  questionId: string;
  optionId: string;
  dispatch: React.Dispatch<Action>;
};

export default function SubQuestionModal(props: TProps) {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.sub-question-modal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modal = useModalContext(),
    snackbar = useSnackbarContext();

  const [selected, setSelected] = React.useState<string | null>(null);

  const query = useQuery<(Omit<Category, "id"> & { id: string })[] | null>(
    ["sub questions"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/sub-questions
    `
        )
        .then((res) => res.data)
  );

  const populateSubquestions = query.data?.map((subquestion) => {
    return (
      <OutlineButton
        key={subquestion.id}
        onClick={() => setSelected(subquestion.id)}
        $selected={subquestion.id === selected}
      >
        {subquestion.label}
      </OutlineButton>
    );
  });

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-2">{populateSubquestions}</div>

      <div className="flex justify-end">
        <SubtleButton>{tr("Cancel")}</SubtleButton>
        <Button
          primary
          onClick={() => {
            props.dispatch({
              type: "handleOptionToSubQuestion",
              payload: {
                subquestion: selected,
                questionId: props.questionId,
                optionId: props.optionId,
              },
            });
            modal.setModal();
          }}
        >
          {tr("Save Changes")}
        </Button>
      </div>
    </div>
  );
}
