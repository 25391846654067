import axios from "axios";
import { Avatar } from "components/avatar";
import { Button, NoStyleA, OutlineButton } from "components/button";
import GroupedButton from "components/button/grouped";
import DataLabel from "components/dataLabel";
import Field from "components/field";
import DollarInputV2 from "components/input/dollarInputV2";
import { useModalContext } from "components/modal";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import TableComponent from "components/table";
import clients from "constants/clients";
import domains from "constants/domains";
import quickbooksDesktopEndpoints from "constants/routes/quickbooks/desktop";
import InvoicesList from "containers/invoicesList";
import { format } from "date-fns";
import Switch from "rc-switch";
import * as React from "react";
import { FaMoneyCheck, FaStripe } from "react-icons/fa";
import { RiArrowDownSLine, RiErrorWarningLine } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { TPayment } from "types/payment";
import { dollarFormatter } from "utils";
import constructUrl from "utils/constructUrl";
import Placeholder from "views/request/component/placeholder";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

export default function Payment() {
  const { t, i18n } = useTranslation();
  const tBase = "views.payment.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const params = useParams<{ id: string }>();
  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient();

  const { id } = params;

  const query = useQuery<TPayment>(
    ["payment", params.id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/${params.id}
  `
        )
        .then((res) => res.data)
  );

  const transferFund = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/${params.id}/transfer
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        snackbar.showSnackbar(tr("Fund transferred to user!"));
      },
    }
  );

  const updateQBSync = useMutation(
    async (e: boolean) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/${params.id}/qbsync?status=${e}
  `
        )
        .then((res) => res.data),
    {
      onMutate: async (e) => {
        await queryClient.cancelQueries(["payment", params.id]);

        const prev = queryClient.getQueryData(["payment", params.id]);

        queryClient.setQueryData(["payment", params.id], (old: any) => ({
          ...old,
          qb_id: e ? null : "disabled",
        }));

        return { prev };
      },
      onError: (err, _, context: any) => {
        queryClient.setQueryData(["payment", params.id], context.prev);
      },
    }
  );

  const handleClickAddToSyncLog = async () => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.SYNC_LOG;

    try {
      const response = await axios.post(
        constructUrl({ client, domain, endpoint }),
        {
          action: "add_or_modify",
          resource_type: "Payment",
          resource_id: id,
        }
      );

      if (response.data) {
        snackbar.showSnackbar(tr("Added to sync log!"));
      }
    } catch (error) {
      snackbar.showSnackbar(tr("Error adding to sync log!"), error);
    }
  };

  if (query.isLoading) return <Spinner />;

  if (!query.data) return <span>{tr("No Data")}</span>;

  let data = query.data;

  const bg = data.is_voided
    ? "bg-red-200"
    : data.is_deposit
    ? "bg-blue-200"
    : "bg-green-200";
  const text = data.is_voided
    ? "text-red-900"
    : data.is_deposit
    ? "text-blue-900"
    : "text-green-900";

  const voided = data.is_voided ? "line-through" : "";

  const populateLogs = data.logs?.map((log) => {
    return (
      <div key={log.id} className="py-2 text-slate-800">
        <p>{log.description}</p>
        <span className="text-xs">
          {format(new Date(log.timestamp), "MMM dd, yyyy hh:mm aa", {
            locale: locale,
          })}
        </span>
      </div>
    );
  });
  return (
    <div>
      <SafeArea>
        <div className="flex flex-col pb-10 space-y-8">
          <div className="flex justify-between py-4 border-b">
            <h1>{tr("Payment")} #{data.id}</h1>
            <div className="space-x-2">
              {!data.is_voided &&
                data.remaining_total > 0 &&
                (data.fk_request_id || data.fk_project_id) && (
                  <Button
                    primary
                    onClick={() => transferFund.mutate()}
                    isLoading={transferFund.isLoading}
                  >
                    {tr("Transfer Fund to Account")}
                  </Button>
                )}
              {!data.is_voided && (
                <OutlineButton
                  onClick={() =>
                    modal.setModal({
                      component: (
                        <VoidPaymentModal
                          id={data.id.toString()}
                          remaining={data.remaining_total}
                        />
                      ),
                      label: tr("Void Reason"),
                    })
                  }
                  className="!border-red-600 !text-red-600 hover:!bg-red-600 hover:!text-white"
                >
                  {tr("Void and Refund")}
                </OutlineButton>
              )}
            </div>
          </div>
          <div className={`p-8 rounded flex flex-col ${bg} ${text}`}>
            <span className="flex items-center space-x-2 text-xl">
              <FaMoneyCheck />
              {data.is_deposit ? <span>Deposit</span> : <span>{tr("Payment")}</span>}
            </span>
            <span className={`flex items-center space-x-4`}>
              <span className={`text-7xl ${voided}`}>
                {dollarFormatter(data.amount)}
              </span>
              {data.is_voided && <span className="text-5xl">{tr("Voided")}</span>}
            </span>
          </div>
          {data.is_voided && (
            <div className="text-red-900">
              <span>{tr("Void Reason")}:</span>
              <p className="text-xl">{data.voided_reason}</p>
            </div>
          )}
          <div className="divide-y">
            <div className="py-4">
              <h2>{tr("Payment Info")}</h2>
            </div>
            <div className="grid grid-cols-2 gap-2 py-4">
              <DataLabel title={tr("Remaining")}>
                <span className="text-2xl">
                  {dollarFormatter(data.remaining_total)}
                </span>
              </DataLabel>
              <DataLabel title={tr("Customer")}>
                <NoStyleA to={`/customers/${data.billed_to.id}`}>
                  <Avatar showLabel>{data.billed_to.name}</Avatar>
                </NoStyleA>
              </DataLabel>
              <DataLabel title={tr("Memo")}>
                {data.note || <span className="text-slate-400">{tr("None")}</span>}
              </DataLabel>
              <DataLabel title={tr("Payment Processed")}>
                {data.stripe_id ? (
                  <FaStripe className="text-6xl text-blue-600" />
                ) : (
                  `${tr("Manual")} (${data.payment_method && tr(data.payment_method)})`
                )}
              </DataLabel>
              {data.stripe_id ? (
                <>
                  <DataLabel title={tr("Stripe Payment ID")}>
                    {data.stripe_id}
                  </DataLabel>
                  <DataLabel title={tr("Statement Descriptor")}>
                    {data.statement_descriptor}
                  </DataLabel>
                  <DataLabel title={tr("Network")}>{data.network}</DataLabel>
                  <DataLabel title={tr("Last 4")}>
                    **** **** **** {data.last4}
                  </DataLabel>
                  <DataLabel title={tr("Expiry")}>
                    {data.exp_month} / {data.exp_year}
                  </DataLabel>
                </>
              ) : (
                <>
                  <DataLabel title={tr("Check/Ref #")}>
                    {data.ref_number || (
                      <span className="text-slate-400">{tr("None")}</span>
                    )}
                  </DataLabel>
                  <DataLabel title={tr("Received By")}>
                    {data.received_by || (
                      <span className="text-slate-400">{tr("None")}</span>
                    )}
                  </DataLabel>
                  <DataLabel title={tr("Received On")}>
                    {data.received_on ? (
                      format(new Date(data.received_on), "MMM dd,yyyy", {
                        locale: locale,
                      })
                    ) : (
                      <span className="text-slate-400">{tr("None")}</span>
                    )}
                  </DataLabel>
                </>
              )}

              <DataLabel title="Quickbooks Desktop">
                {data.qb_id && data.qb_id !== "disabled" ? (
                  <span>{data.qb_id}</span>
                ) : (
                  <>
                    <span>{tr("Toggle Sync")}</span>
                    <Switch
                      checked={!data.qb_id}
                      onChange={(e) => updateQBSync.mutate(e)}
                    />
                  </>
                )}
                <br className="mt-2" />
                <OutlineButton onClick={handleClickAddToSyncLog}>
                  {tr("Add to Sync Log")}
                </OutlineButton>
              </DataLabel>

              <DataLabel title="Quickbooks Online">
                <span>{tr("Sync Status")}: </span>
                <br className="mt-2" />
                <OutlineButton onClick={() => {}}>{tr("QBO Sync")}</OutlineButton>
              </DataLabel>
            </div>
          </div>
          <div className="divide-y">
            <div className="py-4">
              <h2>{tr("Applied To")}</h2>
            </div>
            {data.invoices ? (
              <InvoicesList data={data.invoices} />
            ) : (
              <Placeholder
                type="invoice"
                header={tr("No Invoice")}
                label={tr("Invoices applied will appear here.")}
              />
            )}
          </div>
        </div>
        <div className="divide-y">
          <div className="py-4">
            <h2>{tr("Logs")}</h2>
          </div>
          <div className="py-4 divide-y">{populateLogs}</div>
        </div>
      </SafeArea>
    </div>
  );
}

const VoidPaymentModal = ({
  id,
  remaining,
}: {
  id: string;
  remaining: number;
}) => {
  const { t } = useTranslation();
  const tBase = "views.payment.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient();

  const [reason, setReason] = React.useState("");
  const [amount, setAmount] = React.useState<number | null>();
  const [option, setOption] = React.useState<"full" | "partial">("full");

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments/${id}/void?option=${option}
  `,
          {
            reason,
            amount,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        modal.setModal();
        snackbar.showSnackbar(tr("Payment voided"));
      },
    }
  );

  return (
    <div className="space-y-4 w-[500px]">
      <GroupedButton>
        <OutlineButton
          $selected={option === "full"}
          size="small"
          onClick={() => setOption("full")}
        >
          {tr("Full")}
        </OutlineButton>
        <OutlineButton
          $selected={option === "partial"}
          size="small"
          onClick={() => setOption("partial")}
        >
          {tr("Partial")}
        </OutlineButton>
      </GroupedButton>
      {option === "partial" ? (
        <>
          <div>{tr("Remaining")}: {dollarFormatter(remaining)}</div>
          <DollarInputV2
            autoFocus
            label={tr("Refund Amount")}
            value={amount}
            onValueChange={(e) => setAmount(e.floatValue)}
            error={
              (amount || 0) * 100 > remaining
                ? tr("Refund amount exceeded remaining balance.")
                : undefined
            }
          />
        </>
      ) : (
        <div className="flex items-center p-2 text-yellow-700 bg-yellow-100 border border-yellow-700 rounded">
          <RiErrorWarningLine className="mr-2" /> {tr("Invoices with this payment attached will be marked as unpaid.")}
        </div>
      )}
      <Field
        type="textarea"
        label={tr("Please state your reason of voiding this payment")}
        minRows={4}
        onChange={(e) => setReason(e.target.value)}
        placeholder={tr("Your reason....")}
      />
      <div className="flex justify-end space-x-2">
        <Button
          type="submit"
          primary
          disabled={
            !reason ||
            (option === "partial" &&
              (!amount || (amount || 0) * 100 > remaining))
          }
          isLoading={mutation.isLoading}
          onClick={() => mutation.mutate()}
        >
          {tr("Submit")}
        </Button>
        <OutlineButton>{tr("Cancel")}</OutlineButton>
      </div>
    </div>
  );
};
