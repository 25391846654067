import React from "react";
import { useModalContext } from "components/modal";
import DeletePopup from "./components/deletePopup";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useCartCountDispatch } from "../../components/cart";
import { useTranslation } from 'react-i18next';
const { Input } = require("components/input");

const ItemCon = styled.div`
  border-bottom: 1px solid #d4d4d4;
  height: 125px;
  width: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  > div:nth-child(2) {
    padding-left: 10px;
  }
`;

const ItemImage = styled.img`
  background-color: #d4d4d4;
  width: 120px;
  min-width: 120px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  > div input {
    width: 80px;
  }
`;

export default function Item(props) {
  const { t } = useTranslation();
  const tBase = "views.cart.item";
  const tr = (key) => t(`${tBase}.${key}`);

  const dispatch = useCartCountDispatch();
  const { setModal } = useModalContext();

  const deleteClick = () => {
    setModal({
      component: <DeletePopup item={props.item} />,
      label: tr("Delete Item"),
    });
  };

  const quantityChanged = (event) => {
    const newQty = event.target.value;

    if (parseFloat(newQty) > props.item.qty) {
      dispatch({ type: "COUNTUP", items: props.item, qty: newQty });
    } else {
      dispatch({ type: "COUNTDOWN", items: props.item, qty: newQty });
    }
  };

  return (
    <ItemCon>
      <ItemImage src={props.item.image} alt="product" />
      <Description>
        <div>{props.item.item_name}</div>
        <div>{props.item.short_desc}</div>
        <div>{props.item.category_name}</div>
        <div>
          <Input
            label={tr("Qty")}
            type="number"
            value={props.item.qty}
            onChange={(e) => quantityChanged(e)}
          />
        </div>
        <Tooltip title={tr("Delete")}>
          <IconButton aria-label="delete" onClick={() => deleteClick()}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Description>
    </ItemCon>
  );
}
