import { AxiosError, AxiosResponse } from "axios";
import { businessClient } from "./api";

export const reOpenPurchaseOrder = async (poId: number) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return businessClient({
    method: "PUT",
    url: `/purchasing/reOpenPurchaseOrder/${poId}`,
  })
    .then(onSuccess)
    .catch(onError);
};
