import { useState } from "react";
import Image from "components/image";
import * as React from "react";
import logo from "images/eserv.png";
import SafeArea from "components/safe-area";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import * as Yup from "yup";
import Field from "components/field";
import PhoneNumberInput from "components/input/phoneNumberInput";
import { Button, NoStyleA } from "components/button";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type FormProps = {
  company_name: string;
  industry: string;
  first: string;
  last: string;
  phone: string;
  email: string;
  username: string;
  password: string;
};
export default function Signup() {
  const { t } = useTranslation();
  const tBase = "views.signup.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isPrivacyPolicyAck, setIsPrivacyPolicyAck] = useState(false);

  const history = useHistory(),
    queryClient = useQueryClient();

  // React.useEffect(() => {
  //   async function getTempToken() {
  //     await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/getTemporaryToken`,
  //     {
  //       params: {
  //         isSignup: true
  //       }
  //     });
  //   }

  //   getTempToken();
  //   console.log("run")
  // }, [])

  const initialValues = {
    company_name: "",
    industry: "",
    first: "",
    last: "",
    phone: "",
    email: "",
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required(),
    industry: Yup.string().required(),
    first: Yup.string().required(),
    last: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().required(),
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  const onSubmit = (e: FormProps) => mutation.mutate(e);

  const formik = useFormik<FormProps>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const mutation = useMutation(
    async (e: FormProps) =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/signup
    `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (data.duplicates) {
          formik.setErrors(data.duplicates);
        } else {
          queryClient.invalidateQueries();
          history.push("/signupConfirmationSplash", { email: data.email });
          // history.push("/")
        }
      },
    }
  );

  return (
    <SafeArea classNames={{ content: "!max-w-[800px] !pb-20" }}>
      <Image
        src={logo}
        alt="EServ"
        className="!border-none !w-[200px] !h-[200px]"
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="divide-y">
          <h2 className="py-4">{tr("Company Info")}</h2>
          <div className="space-y-2 py-4">
            <Field
              required
              name="company_name"
              label={tr("Company Name")}
              formik={formik}
            />
            <Field required name="industry" label={tr("Industry")} formik={formik} />
          </div>
        </div>
        <div className="divide-y">
          <h2 className="py-4">{tr("Personal Info")}</h2>
          <div className="space-y-2 py-4">
            <div className="flex space-x-4">
              <Field
                required
                className="flex-auto"
                name="first"
                label={tr("First Name")}
                formik={formik}
              />
              <Field
                required
                className="flex-auto"
                name="last"
                label={tr("Last Name")}
                formik={formik}
              />
            </div>
            <Field required name="email" label={tr("Email")} formik={formik} />
            <PhoneNumberInput
              required
              name="phone"
              label={tr("Phone #")}
              formik={formik}
            />
          </div>
        </div>
        <div className="divide-y">
          <h2 className="py-4">{tr("Account")}</h2>
          <div className="space-y-2 py-4">
            <Field required name="username" label={tr("Username")} formik={formik} />
            <Field
              required
              name="password"
              label={tr("Password")}
              type="password"
              formik={formik}
            />
          </div>
        </div>
        {/* add privacy policy and terms of service */}
        <div className="mt-1 text-center">
          <div style={{ display: "flex", gap:"5px" }}>
          <input type="checkbox" name="privacyPolicy" onChange={(e) => setIsPrivacyPolicyAck(e.target.checked)} /> 
            {tr("I have read and agree to the")} <a href="\privacy">{tr("Privacy Policy")}</a> 
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            primary
            disabled={!formik.isValid || !formik.dirty || !isPrivacyPolicyAck}
            isLoading={mutation.isLoading}
            className="w-[150px]"
          >
            {tr("Submit")}
          </Button>
        </div>
      </form>
    </SafeArea>
  );
}
