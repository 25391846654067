import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import Note from "./note";
import Modal from "components/popup";
// import { axios } from "../../Api Calls/axios";
import axios from "axios";
import moment from "moment";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 1rem;
	padding: 0.5rem;
	> div:nth-child(2) {
		grid-column-start: 1;
	}
`;

const EditModal = styled(Modal)`
	max-width: 500px;
	width: 100%;
	height: 100%;
	max-height: 600px;

	display: flex;
	flex-direction: column;
	> * {
		border: 0;
		padding: 1rem;
		color: #202020;
		&:focus {
			outline: none;
		}
	}
	> input {
		font-size: 1.5rem;
		color: rgb(0, 0, 0, 0.7);
		font-weight: bold;
	}
	> textarea {
		font-weight: 400;
		font-size: 1.2rem;
		height: 100%;
	}
`;

// const Button = styled.button`
//     font-size: 1.2rem;
//     cursor: not-allowed;
//     ${props => props.clickable && css`
//         color: white;
//         background: blue;
//         cursor: pointer;
//     `}
// `

const NoteHistory = (props) => {
	const { t, i18n } = useTranslation();
	const tBase = "views.team.viewUser.noteHistory";
	const tr = (key) => t(`${tBase}.${key}`);
	const locale = i18n.language === "es" ? "es" : "en";

	let { id } = props;
	const mounted = useRef(true);
	const [show, setShow] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [data, setData] = useState([]);
	const [noteId, setNoteId] = useState();
	const [isChanged, setChanged] = useState(false);

	const editNote = (req) => {
		let { title, content, id } = req;
		setTitle(title);
		setDescription(content);
		setNoteId(id);
		setShow(true);
	};

	const checkForSubmission = async () => {
		let tempTitle, tempDescription, tempNoteId, tempChanged;
		tempTitle = title;
		tempDescription = description;
		tempNoteId = noteId;
		tempChanged = isChanged;

		// setTitle(title => {
		//     tempTitle = title;
		//     return title
		// })
		// setDescription(des => {
		//     tempDescription = des;
		//     return des;
		// })
		// setNoteId(note => {
		//     tempNoteId = note;
		//     return note;
		// })

		// setChanged(changed => {
		//     tempChanged = changed;
		//     return changed;
		// })

		if ((tempTitle.trim() || tempDescription.trim()) && tempChanged) {
			try {
				await axios.post(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/note`,
					{
						type: "add",
						id,
						noteId: tempNoteId || null,
						title: tempTitle,
						description: tempDescription,
					}
				);
			} catch (e) {
				console.error(e);
			}
		}
		setTitle("");
		setDescription("");
		setNoteId();
		setShow(false);
		setChanged(false);
		fetchData();
	};

	const fetchData = useCallback(async () => {
		try {
			const res = await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/note?id=${id}`
				)
				.catch((e) => alert(e));
			mounted.current && setData(res.data);
		} catch (e) {
			console.error(e);
		}
	}, [id]);

	useEffect(() => {
		fetchData();

		return () => (mounted.current = false);
	}, [fetchData]);

	const deleteNote = async (id) => {
		try {
			await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/note`,
				{
					type: "delete",
					id,
				}
			);
			fetchData();
		} catch (e) {
			console.error(e);
		}
	};

	const populateData = data.map((e) => {
		let {
			note_id: id,
			note_title: title,
			note_content: content,
			note_created: created,
		} = e;
		return (
			<Note
				key={id}
				id={id}
				title={title}
				created={moment(created).locale(locale).format("MMM DD hh:mm A")}
				content={content}
				deleted={() => deleteNote(id)}
				clicked={() => editNote({ title, content, id })}
			/>
		);
	});

	return (
		<Con>
			<Note new clicked={() => setShow(true)} />
			{data && populateData}
			{show && (
				<EditModal cancelDisplay={() => checkForSubmission()}>
					<input
						value={title}
						onChange={(e) => {
							setChanged(true);
							setTitle(e.target.value);
						}}
						placeholder={tr("Title")}
					></input>
					<textarea
						value={description}
						onChange={(e) => {
							setChanged(true);
							setDescription(e.target.value);
						}}
						placeholder={tr("Enter a note")}
					></textarea>
				</EditModal>
			)}
		</Con>
	);
};

export default NoteHistory;
