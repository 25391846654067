import { ICustomer } from "../types";
import { Button } from "../../../../components/buttonV2";
import { useTranslation } from 'react-i18next';

interface IgnoreModalProps {
  originalId: string;
  duplicateId: string;
  ignoreDuplicate: (originalId: string, duplicateId: string) => void;
  setModal: () => void;
};

function MergeModal({ originalId, duplicateId, ignoreDuplicate, setModal }: IgnoreModalProps) {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.IgnoreModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const onClick = () => {
    ignoreDuplicate(originalId, duplicateId);
    setModal();
  };

  return (
    <div>
      <div className="border mb-1.5" />
      <div>
        {tr("Are you sure you want to proceed?")} {tr("This action")} <br/>{tr("cannot be undone")}.
      </div>
      <div className="flex flex-row justify-end space-x-4 mt-2">
        <Button
        onClick={onClick}
        >
          {tr("Confirm")}
        </Button>
        <Button 
          type="secondary"
          onClick={() => setModal()}
        >
          {tr("Cancel")}
        </Button>
      </div>
    </div>
  )
}

export default MergeModal