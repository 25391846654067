import axios from "api/axios";
import { Button } from "components/button";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import permission1 from "./permission.png";
import permission2 from "./permission2.png";
import permission3 from "./permission3.png";
import connector from "./connector.png";
import manualupdate from "./manualupdate.png";
import Tab from "components/tab/TabWithUrl";
import FailedList from "./failedList";
import { QuickbooksDashboard } from "./dashboard/QuickbooksDashboard";
import QuickBooksDesktop from "./QuickBooksDesktop";
import QuickbooksOnline from "./online";
import { useTranslation } from 'react-i18next';

export default function Quickbooks() {
  const [qwcUrl, setQwcUrl] = React.useState("");
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const qbQuery = useQuery(
    ["quickbooks"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/quickbooks
    `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (data) {
          const xml = `<?xml version="1.0"?>
<QBWCXML>
    <AppName>EServ</AppName>
    <AppID></AppID>
    <AppURL>https://qbd.eserv.com/</AppURL>
    <AppDescription>Service Business Management Software</AppDescription>
    <AppSupport>https://qbd.eserv.com/</AppSupport>
    <UserName>${data.qb_username}</UserName>
    <OwnerID>{90A44FB7-33D9-4815-AC85-AC86A7E7D1EB}</OwnerID>
    <FileID>{57F3B9B6-86F1-4FCC-B1FF-967DE1813D20}</FileID>
    <QBType>QBFS</QBType>
    <Scheduler>
    <RunEveryNMinutes>10</RunEveryNMinutes>
    </Scheduler>
    <IsReadOnly>false</IsReadOnly>
</QBWCXML>`;
          const blob = new Blob([xml]);
          const fileDownloadUrl = URL.createObjectURL(blob);
          setQwcUrl(fileDownloadUrl);
        }
      },
    }
  );

  const getStartedMutation = useMutation(
    async () =>
      await axios.post(`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/quickbooks
  `),
    {
      onSuccess: () => {
        qbQuery.refetch();
      },
    }
  );

  const tabData = {
    test: {
      tab: tr("Dashboard"),
      content: <QuickBooksDesktop />,
    },
    // dashboard: {
    //   tab: "Dashboard",
    //   content: <QuickbooksDashboard />,
    // },
    instructions: {
      tab: tr("Instructions"),
      content: (
        <Con>
          <SHeader>
            <h1>Quickbooks {tr("Desktop")}</h1>
          </SHeader>
          <SContent>
            {qbQuery.data ? (
              <>
                <PreRequisites />
                <h2>{tr("Steps")}</h2>
                <Step>
                  <div>1.</div>
                  <div>
                    <span>
                      {tr("Download this")}{" "}
                      <a
                        download="EServ.qwc"
                        href={qwcUrl}
                        className="text-blue-600"
                      >
                        {tr("file")}
                      </a>
                    </span>
                    <p>{tr("When the download is completed, open it")}.</p>
                  </div>
                </Step>
                <Step>
                  <div>2.</div>
                  <div>
                    <span>{tr("Grant permission to access to quickbooks")}.</span>
                    <p>
                      {tr("Integration with Quickbooks require you to grant permissions to EServ in order to write and read your company file. When you open the file, you will see this screen")}:
                    </p>
                    <SImg src={permission1} />
                    <p>1. {tr("Click OK to grant EServ access to Quickbooks")}.</p>
                    <SImg src={permission2} />
                    <p>
                      2. {tr("Select one of the options in the box to grant EServ access to Quickbooks")}.
                    </p>
                    <p>3. {tr("Click continue to move to the next screen")}.</p>
                    <SImg src={permission3} />
                    <p>
                      4. {tr("Finally, click done! EServ now have access to share information with your quickbooks")}.
                    </p>
                  </div>
                </Step>
                <Step>
                  <div>3.</div>
                  <div>
                    <span>{tr("Insert your company quickbooks password")}</span>
                    <p>
                      {tr("You will see this page once you are done granting permission to EServ")}:
                    </p>
                    <SImg src={connector} />
                    <p>
                      1. {tr("Insert the following password to the input field. This will allow EServ to know which company is integrating with EServ, thus sending the correct information")}.
                    </p>
                    <code>{qbQuery.data.qb_password}</code>
                  </div>
                </Step>
                <Step>
                  <div>4.</div>
                  <div>
                    <span>{tr("Done!")}</span>
                    <p>
                      {("The default auto-update is set to run every 10 minutes. You may also update manually by")}:
                    </p>
                    <SImg src={manualupdate} />
                    <p>1. {tr("Check the company that you would like to update")}.</p>
                    <p>
                      2. {("Click Update Selected to initiate the updates. It will synchronize the data between EServ and Quickbooks")}.
                    </p>
                  </div>
                </Step>
                <Note />
              </>
            ) : (
              <div>
                {tr("Get Started")}
                <Button
                  isLoading={getStartedMutation.isLoading}
                  onClick={() => getStartedMutation.mutate()}
                >
                  {tr("Get Started")}
                </Button>
              </div>
            )}
          </SContent>
        </Con>
      ),
    },
    failed: {
      tab: tr("Failed List"),
      content: <FailedList />,
    },
    online: {
      tab: tr("Online"),
      content: <QuickbooksOnline />,
    },
  };

  return <Tab data={tabData} defaultTab={"test"} />;
}

const SImg = styled.img`
  border: 1px solid grey;
  margin: 1rem 0;
  width: 100%;
`;

const Con = styled.div`
  max-width: 500px;
`;

const SHeader = styled.div`
  display: flex;
`;

const SContent = styled.div``;

const Step = styled.div`
  display: flex;
  > div:first-child {
    flex: 0 0 25px;
    font-size: 1.2rem;
  }
  > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;

    span {
      font-weight: bold;
      font-size: 1.2rem;
    }

    code {
      background: ${theme.bg.blue};
      padding: 0.5rem;
      border-radius: 5px;
      margin-bottom: 1rem;
    }
  }
`;

const PreRequisites = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  return (
    <div>
      <h2>Pre-Requisites</h2>
      <div>
        <p>1. Quickbooks {tr("Desktop")}</p>
        <p>
          2. {tr("Quickbooks Web Connector (This application should automatically be downloaded if you have Quickbooks Desktop)")}
        </p>
      </div>
    </div>
  );
};
const Note = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <SNote>
      <h2>{tr("Note")}</h2>
      <div>
        <p>1. {tr("Initial update may take longer due to the higher payload")}.</p>
        <p>
          2. {tr("Refrain from sharing your company password as imposter will be able to access your company account")}.
        </p>
        <p>
          2. {tr("Currently, the information will be going one-way")}: {tr("EServ to Quickbooks Desktop. The information that will be shared to Quickbooks include")}: <b>{tr("Customers, Invoices and Payments")}</b>
        </p>
      </div>
    </SNote>
  );
};

const SNote = styled.div``;
