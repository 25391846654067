import { useState } from "react";
import axios from "axios";
import { OutlineButton } from "components/button";
import Customer from "../types/Customer";
import { MdOpenInNew } from "react-icons/md";
import { useTranslation } from "react-i18next";

type Props = {
  customer: Customer;
  invalidateQueries: () => void;
};

const CustomerCard = ({ customer, invalidateQueries }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.online.components.CustomerCard";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const resyncCustomer = async (customerId: string) => {
    setIsLoading(true);
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/sync-customer`,
      { customerId }
    );
    invalidateQueries();
    setIsLoading(false);
  };

  const resetCustomer = async (customerId: string) => {
    setIsLoading(true);
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/reset-customer`,
      { customerId }
    );
    invalidateQueries();
    setIsLoading(false);
  };

  const handleOpenCustomer = () => {
    const customerId = customer.id;
    window.open(`/customers/${customerId}`);
  };

  const cardClass =
    customer.qbol_id && customer.qbol_synced_at
      ? "border-2 border-green-500 bg-green-200"
      : !customer.qbol_id && customer.qbol_message
      ? "border-2 border-red-500 bg-red-200"
      : customer.qbol_message && (!customer.qbol_synced_at || !customer.qbol_id)
      ? "border-2 border-orange-500 bg-orange-50"
      : "border-2 border-gray-500 bg-gray-50";

  return (
    <div
      className={`bg-white p-4 rounded-lg shadow mb-1 mr-1 text-xs ${cardClass}`}
    >
      <div className="flex flex-row justify-between">
        <div className="w-[50%]">
          <p>
            <div className="flex flex-row items-center">
              <strong>ID:</strong> {customer.id}
              <MdOpenInNew
                color="blue"
                className="cursor-pointer ml-2"
                onClick={handleOpenCustomer}
              />
            </div>
          </p>
          <p>
            <strong>{tr("Name")}:</strong> {customer.first_name}{" "}
            {customer.last_name}
          </p>
          <p>
            <strong>{tr("Company")}:</strong> {customer.company_name}
          </p>
          <p>
            <strong>{tr("Email")}:</strong> {customer.email}
          </p>
          <p>
            <strong>{tr("Phone")}:</strong> {customer.phone}
          </p>
          <p>
            <strong>QB Desktop ID:</strong> {customer.qb_name}{" "}
            {/* TODO: TRANSLATION */}
          </p>
        </div>
        <div className="flex flex-col items-end w-[50%] text-right">
          <div>
            <strong>QBO ID:</strong> {customer.qbol_id}
          </div>
          <div>
            <strong>{tr("Sync Token")}:</strong>
            {customer.qbol_sync_token}
          </div>
          <div>
            <strong>{tr("Message")}:</strong> {customer.qbol_message}
          </div>
          <div>
            <strong>{tr("Synced At")}:</strong>{" "}
            {customer.qbol_synced_at
              ? new Date(customer.qbol_synced_at).toLocaleString()
              : "Not Synced"}
          </div>
          <div className="flex flex-row">
            <OutlineButton
              onClick={() => resetCustomer(customer.id.toString())}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {tr("Reset")}
            </OutlineButton>
            <OutlineButton
              className="ml-2"
              onClick={() => resyncCustomer(customer.id.toString())}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {tr("Re-sync")}
            </OutlineButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;
