import * as React from "react";
import {
  StyledAvatarButton,
  StyledAvatarA,
  StyledAvatarDiv,
  Label,
} from "./style";
import { StyledComponent } from "styled-components";
import { SubtleLink } from "components/link";
import { Size } from "types/theme";
import { A, Button } from "components/button";
import { MdVerifiedUser } from "react-icons/md";
import { useQuery } from "react-query";
import axios from "axios";
import { RiCheckboxCircleFill, RiCloseCircleFill, RiTimeFill } from "react-icons/ri";
import  Tooltip from "components/tooltip";
import TooltippedElement from "components/TooltippedElement";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  showLabel?: boolean;
  children: React.ReactNode;
  size?: Size;
  to?: string;
  onClick?: () => void;
  verified?: string | null;
  user_id?: string | number;
}

export const Avatar = React.forwardRef((props: Props, ref: any) => {
  let { children, showLabel } = props;
  


  var match =
    typeof children === "string" ? children.match(/\b(\w)/g) : children;
  let size = props.size || "regular";

  let label = showLabel && (
    <Label {...props} size={size}>
      &nbsp;
      <span>{children}</span>
      &nbsp;
    </Label>
  );

  if (!label) {
    if (props.to)
      return (
        <AvatarImageCon conType="a" {...props} size={size} innerRef={ref}>
          {match}
        </AvatarImageCon>
      );
    else if (props.onClick)
      return (
        <AvatarImageCon conType="button" {...props} size={size} innerRef={ref}>
          {match}
        </AvatarImageCon>
      );
    else
      return (
        <AvatarImageCon conType="div" {...props} size={size} innerRef={ref}>
          {match}
        </AvatarImageCon>
      );
  }

  if (props.to)
    return (
      <A
        to={props.to}
        style={{ padding: "0.25em", height: "auto" }}
        inneRef={ref}
      >
        <AvatarImageCon conType="div" {...props} size={size}>
          {match}
        </AvatarImageCon>
        {label}
      </A>
    );
  if (props.onClick)
    return (
      <Button
        style={{ padding: "0.25em", height: "auto" }}
        onClick={props.onClick}
        ref={ref}
      >
        <AvatarImageCon conType="div" {...props} size={size}>
          {match}
        </AvatarImageCon>
        {label}
      </Button>
    );
  
  return (
    <div style={{ display: "flex", alignItems: "center", width:'fit-content'}} ref={ref}>
      <AvatarImageCon conType="button" {...props} size={size}>
        {match}
      </AvatarImageCon>
      {label}
      {props.verified && (
        <MdVerifiedUser className="text-blue-500 text-[0.9rem]" />
      )}
    </div>
  );
});

const AvatarImageCon = (props: {
  conType: "div" | "button" | "a";
  user_id?: string | number;
  size: Size;
  children: React.ReactNode;
  innerRef?: any;
  onClick?: any;
  to?: any;
}) => {

  const imageQuery = useQuery(
    ["image", "employee", props.user_id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/employee/image/${props.user_id}
  `
        )
        .then((res) => res.data)
  );
  const statusQuery = useQuery(
    ["currentStatus", "employee", props.user_id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business-unprotected/employee/currentStatus/${props.user_id}
  `
        )
        .then((res) => res.data)
  );

  const StatusTooltip = () => {
    if(statusQuery.data?.length > 0) {
      return(
        <>
          <div className="relative visible z-[100] w-fit bg-gray-700 py-1 px-2 rounded-[5px] text-white text-xs">
            {statusQuery.data}
          </div>
          <span className="absolute w-2 h-2 bg-gray-700 top-1/2 left-full transform -translate-y-1/2 -translate-x-1/2  rotate-45" />
        </>
      )
    } else {
      return (
        <></>
      )
    }
  }

  const StatusIcon = () => {
    if(props.size === 'small'||props.size === 'xsmall'){
      return <></>
    }
    if(statusQuery.data === 'Clocked-Out'||statusQuery.data === 'Unknown Status') return(
      <RiCloseCircleFill style={{fontSize:'16px',fontWeight:'bolder', position:'absolute', bottom:'-1px', left:'3px', color:'#f00'}} />
    )
    else if(statusQuery?.data?.includes('Break')){ return(
      <RiTimeFill style={{fontSize:'16px',fontWeight:'bolder', position:'absolute', bottom:'-1px', left:'3px', color:'#ff0'}} />
    )
    }else if(statusQuery?.data?.length > 0){
    return(
      <RiCheckboxCircleFill style={{fontSize:'16px',fontWeight:'bolder', position:'absolute', bottom:'-1px', left:'3px', color:'#0f0'}} />
    )
    }else{
      return(
        <></>
      )
    }
  }
  const statusstyle = () => {
    if(statusQuery?.data === 'Clocked-Out'||statusQuery?.data === 'Unknown Status') return(
      {border:'solid', borderColor:'#f00'}
    )
    else if(statusQuery?.data?.includes('Break')){ return(
      {border:'solid', borderColor:'#ff0'}
    )
    }else if(statusQuery?.data?.length > 0){
      return(
        {border:'solid', borderColor:'#0f0'}
      )
    }else{
      return(
        {}
      )
    }
  }

  if (props.conType === "div") {
    return (<div>
      <Tooltip
       options={{placement:'left'}}
        displayedElement={(Tprops) => {return(
        <StyledAvatarDiv onMouseEnter={Tprops.open} onMouseLeave={Tprops.close} onMouseDownCapture={Tprops.close} style={statusstyle()} {...props} ref={props.innerRef}>
          {imageQuery.data ? <img src={imageQuery.data} /> : props.children}
          <StatusIcon />
        </StyledAvatarDiv>)}}
        tooltipmessage={() => {return(
        <StatusTooltip />
        )}}
      />
    </div>
    );
  } else if (props.conType === "a") {
    return (<div>
      <Tooltip
        options={{placement:'left'}}
        displayedElement={(Tprops) => {return(
        <StyledAvatarA onMouseEnter={Tprops.open} onMouseLeave={Tprops.close} onMouseDownCapture={Tprops.close} style={statusstyle()} to={props.to} size={props.size} ref={props.innerRef}>
          {imageQuery.data ? <img src={imageQuery.data} /> : props.children}
          <StatusIcon />
        </StyledAvatarA>)}}
        tooltipmessage={() => {return(
        <StatusTooltip/>
        )}}
      />
      </div>
    );
  }

  return (
    <div>
    <Tooltip
    options={{placement:'left'}} 
    displayedElement={(Tprops) => {return(
      <StyledAvatarButton onMouseEnter={Tprops.open} onMouseLeave={Tprops.close} onMouseDownCapture={Tprops.close} style={statusstyle()} {...props} ref={props.innerRef}>
        {imageQuery.data ? <img src={imageQuery.data} /> : props.children}
        <StatusIcon />
      </StyledAvatarButton>
    )}}
    tooltipmessage={()=>{return(
      <StatusTooltip/>
    )}}
    />
    </div>
  );
};
