import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  style?: {};
};
export default function Segment(props: Props) {
  const { header, children, footer, ...rest } = props;
  return (
    <SCon {...rest} className="border">
      {header}
      {children}
      {footer}
    </SCon>
  );
}

const SCon = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  background: white;
  /* box-shadow: 0 2px 12px rgb(0 40 117 / 6%); */
  border-radius: 4px;
  overflow: hidden;
  /* margin: 1rem; */
  @media only screen and (max-width: 600px) {
    /* margin: 1rem 0; */
  }
`;

export const SHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.border.light};
  padding: 1.5em;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 1em;
  }
`;

export const SContent = styled.div`
  padding: 1.5em;
  @media only screen and (max-width: 600px) {
    padding: 1em;
  }
`;

export const SFooter = styled(SHeader)`
  border-top: 1px solid ${theme.border.light};
  border-bottom: 0;
`;
