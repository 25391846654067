import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import axios from "axios";

import "./index.css";

const TransferHistory = () => {
  const [transfers, setTransfers] = useState([]);

  const getTransferHistory = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/transfer_history`
    );

    setTransfers(response.data);
  };

  useEffect(() => {
    getTransferHistory();
  }, []);

  return (
    <div className="transfer-history-container">
      <div className="hist-accordion-container">
        {transfers.map((transfer) => {
          const users = Array.from(
            new Set(
              transfer.orders?.map((order) => {
                return order.user;
              })
            )
          );

          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };

          return (
            <Accordion className="hist-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="hist-summary-main">
                  <div className="hist-transfer-left-group">
                    <div className="hist-transfer-title">
                      <div className="hist-transfer-wh">
                        {transfer.warehouse_name}
                      </div>
                    </div>

                    <div className="hist-names">
                      {users
                        ? users.map((user) => {
                            return <div className="hist-user">{user}</div>;
                          })
                        : null}
                    </div>

                    <div className="hist-transfer-date">
                      {new Date(transfer.timestamp).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </div>
                  </div>

                  <div className="hist-transfer-status">{transfer.status}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="hist-transfer-body">
                  {transfer.orders?.map((order) => {
                    return (
                      <div className="hist-order-card">
                        <div className="hist-order-row">
                          <div className="hist-order-row-left">
                            <div className="hist-order-job">{order.job}</div>
                            <div>{order.user}</div>
                          </div>
                          <div>
                            {order.items?.map((item) => {
                              return (
                                <div className="hist-item-row">
                                  <div>
                                    {item.parent_category_name}
                                    {" -> "}
                                    {item.category_name}
                                    {" -> "}
                                    <span className="hist-bold-name">
                                      {item.item_name}
                                    </span>
                                  </div>
                                  <div className="hist-order-row-right">
                                    {item.quantity}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default TransferHistory;
