import * as React from "react";
import { FormikProps } from "formik";
import { cloneDeep } from "lodash";
import { Input } from "components/input";
import RadioGroup from "components/input/radio";
import Select from "components/react-select";
import TextArea from "components/textarea";
import AssigneeSelect from "components/react-select/assignees";

type Props = {
  formik: FormikProps<any>;
  children: React.ReactNode;
} & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;
export default function Form({ formik, children, ...formProps }: Props) {
  const isRightChild = (child: any) =>
    child.type === RadioGroup ||
    child.type === Input ||
    child.type === Select ||
    child.type === AssigneeSelect ||
    child.type === TextArea;

  const hasChildren = (children: any) =>
    children && typeof children !== "string";

  const modifyChildProps = (child: any) => {
    const onChange = (e: any) => {
      if (child.props.formatOn === "change" && child.props.formatter) {
        let temp = cloneDeep(e);
        temp.target.value = child.props.formatter(temp.target.value);
        formik.handleChange(temp);
      } else if (child.props.onChange) {
        child.props.onChange(e);
      } else if (child.type === Select || child.type === AssigneeSelect) {
        formik.setFieldValue(child.props.name, e);
      } else {
        formik.handleChange(e);
      }
    };

    const onBlur = (e: any) => {
      formik.handleBlur(e);

      if (child.props.formatOn === "blur" && child.props.formatter) {
        formik.setFieldValue(
          e.target.name,
          child.props.formatter(e.target.value)
        );
      }
    };

    const value = child.props.value ?? formik.values[child.props.name] ?? "";

    const error =
      formik.touched[child.props.name] && formik.errors[child.props.name];

    // const checked =
    //   child.props.type === "checkbox" && formik.values[child.props.name];
    return {
      ...child.props,
      onChange: onChange,
      onBlur: onBlur,
      value: value,
      error: error,
      // checked: checked,
    };
  };

  const ChildrenRecursion = (parent: React.ReactNode): React.ReactNode => {
    return React.Children.map(parent, (child) => {
      if (React.isValidElement(child)) {
        let childProps: any;

        if (isRightChild(child) && child.props.name) {
          childProps = modifyChildProps(child);
          return React.cloneElement(child, childProps);
        } else if (hasChildren(child.props.children)) {
          childProps = {
            children: ChildrenRecursion(child.props.children),
          };
          return React.cloneElement(child, childProps);
        }

        return child;
      }

      return null;
    });
  };

  const populateChildren = ChildrenRecursion(children);

  return (
    <form {...formProps} onSubmit={formik.handleSubmit}>
      {populateChildren}
    </form>
  );
}
