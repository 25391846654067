import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";

const TaxManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<TaxGroup | null>(null);
  const [selectedTaxes, setSelectedTaxes] = useState<number[]>([]);
  const [groupName, setGroupName] = useState("");

  const queryClient = useQueryClient();

  const getTaxes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax/dashboard`
    );
    return response.data;
  };

  const { data, isLoading, isError, error } = useQuery("taxes", getTaxes);

  const mutation = useMutation(
    (newGroup: { label: string; taxIds: number[] }) => {
      if (isEditMode && selectedGroup) {
        return axios.put(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax_groups`,
          {
            id: selectedGroup.id,
            label: newGroup.label,
            taxIds: newGroup.taxIds,
          }
        );
      } else {
        return axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/tax_groups`,
          newGroup
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("taxes");
        setIsModalOpen(false);
        setSelectedTaxes([]);
        setGroupName("");
        setSelectedGroup(null);
        setIsEditMode(false);
      },
      onError: (err) => {
        console.error("Failed to save tax group:", err);
      },
    }
  );

  type Tax = {
    id: number;
    label: string;
    list_rate: number;
    list_type: string;
    sale_rate: number;
    sale_type: string;
    install_rate: number;
    install_type: string;
    tax_type: string;
  };

  type TaxGroup = {
    id: number;
    label: string;
    taxes: Tax[];
  };

  const taxes = data?.taxes as Tax[];
  const taxGroups = data?.taxGroups as TaxGroup[];

  const toggleModal = (group?: TaxGroup) => {
    if (group) {
      setIsEditMode(true);
      setSelectedGroup(group);
      setGroupName(group.label);
      setSelectedTaxes(group.taxes.map((tax) => Number(tax.id)));
    } else {
      setIsEditMode(false);
      setGroupName("");
      setSelectedTaxes([]);
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleTaxSelection = (taxId: number) => {
    setSelectedTaxes((prevSelected) =>
      prevSelected.includes(Number(taxId))
        ? prevSelected.filter((id) => Number(id) !== Number(taxId))
        : [...prevSelected, Number(taxId)]
    );
  };

  const handleSaveGroup = async () => {
    if (groupName && selectedTaxes.length > 0) {
      mutation.mutate({ label: groupName, taxIds: selectedTaxes });
    }
  };

  useEffect(() => {
    console.log("taxGroup changed", selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    console.log("selectedTaxes changed", selectedTaxes);
  }, [selectedTaxes]);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Tax Management</h1>
      <button
        onClick={() => toggleModal()}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        New Group
      </button>

      {isLoading ? (
        <div className="text-center text-gray-600">Loading...</div>
      ) : (
        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-semibold mb-2">Taxes</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {taxes.map((tax: Tax) => (
                <div
                  key={tax.id}
                  className="p-4 bg-white rounded-lg shadow-md border border-gray-200"
                >
                  <div className="font-medium text-gray-700">{tax.label}</div>
                  <div className="text-gray-500">Rate: {tax.list_rate}%</div>
                  <div className="text-gray-500">Type: {tax.list_type}</div>
                  <div className="text-gray-500">Category: {tax.tax_type}</div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">Tax Groups</h2>
            <div className="space-y-4">
              {taxGroups.map((taxGroup: TaxGroup) => (
                <div
                  key={taxGroup.id}
                  className="p-4 bg-white rounded-lg shadow-md border border-gray-200"
                >
                  <div className="flex justify-between items-center">
                    <div className="font-medium text-gray-700">
                      {taxGroup.label}
                    </div>
                    <button
                      onClick={() => toggleModal(taxGroup)}
                      className="text-blue-500 hover:underline"
                    >
                      Edit
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {taxGroup.taxes.map((tax: Tax) => (
                      <div
                        key={tax.id}
                        className="p-2 bg-gray-50 rounded-lg border border-gray-100"
                      >
                        <div className="font-medium text-gray-700">
                          {tax.label}
                        </div>
                        <div className="text-gray-500">
                          Rate: {tax.list_rate}%
                        </div>
                        <div className="text-gray-500">
                          Type: {tax.list_type}
                        </div>
                        <div className="text-gray-500">
                          Category: {tax.tax_type}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {isError && (
            <div className="text-red-500 text-center mt-4">{error}</div>
          )}
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {isEditMode ? "Edit Tax Group" : "Create New Tax Group"}
            </h3>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              placeholder="Group Name"
              className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <div className="mb-4">
              <h4 className="font-medium mb-2">Select Taxes</h4>
              <div className="space-y-2 max-h-48 overflow-y-auto">
                {taxes.map((tax) => (
                  <label key={tax.id} className="flex items-center space-x-3">
                    <input
                      type="checkbox"
                      checked={selectedTaxes.includes(Number(tax.id))}
                      onChange={() => handleTaxSelection(tax.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span>{tax.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveGroup}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {isEditMode ? "Update Group" : "Create Group"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaxManagement;
