import * as React from "react";
import Modal, { useModalContext } from "components/modal";
import { Button, SubtleButton } from "components/button";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string;
};

const useAreYouSure = () => {
  const { t } = useTranslation();
  const tBase = "components.areYouSure";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  const handleConfirm = (action: any) => {
    action();
    setModal();
  };
/**const that handles a cancel  */
  const handleCancel = () => {
    setModal();
  };

  const activate = (action: () => void, props?: Props) => {
    setModal({
      component: (
        <Con>
          <span>
            {props?.message || tr("Are you sure you want to perform this action?")}{" "}
          </span>
          <div>
            <SubtleButton onClick={handleCancel}>{tr("Cancel")}</SubtleButton>
            <Button primary onClick={() => handleConfirm(action)}>
              {tr("Confirm")}
            </Button>
          </div>
        </Con>
      ),
      label: tr("Confirmation"),
    });
  };

  return { activate };
};

const Con = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  > div:last-child {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
    > button {
      margin-left: 0.5rem;
    }
  }
`;
export { useAreYouSure };
