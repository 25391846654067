import React from "react";
import { Link } from "react-router-dom";

interface IButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  shape?: "slim";
  active?: boolean;
  type?: "primary" | "secondary";
  link?: boolean,
  to?: string
}

const Button = (props: IButtonProps) => {
  const { 
    children, 
    onClick, 
    className = "", 
    disabled = false, 
    shape = "default", 
    active = true, 
    type = "primary",
    link = false,
    to = "/"
  } = props;

  const buttonStylePrimary = ` 
    ${shape === "slim" ? "px-0 w-8" : "px-3"}
    ml-auto h-10 flex items-center justify-center rounded-[4px] font-bold text-sm no-underline 
    ${active ? 
      "bg-primary text-white hover:bg-primary-dark hover:text-[#ccc0ba]" 
      : "bg-white text-gray-400 hover:text-gray-500 hover:bg-gray-100"
    }
    transition-colors duration-300 ease-in-out
  `;

  const buttonStyleSecondary = ` 
    ${shape === "slim" ? "px-0 w-8" : "px-3"}
    ml-auto h-10 flex items-center justify-center rounded-[4px] font-bold text-sm no-underline 
    ${active ? 
      "bg-[#edf1f4] hover:bg-[#bec1c3]" 
      : "bg-white text-gray-400 hover:text-gray-500 hover:bg-gray-100"
    }
    transition-colors duration-300 ease-in-out
  `;

  const buttonStylePrimaryLoading = `
    ${shape === "slim" ? "px-0 w-8" : "px-3"}
    ml-auto h-10 flex items-center justify-center rounded-[4px] font-bold text-sm no-underline 
    bg-gray-300 text-black hover:bg-gray-400 cursor-not-allowed
    transition-colors duration-300 ease-in-out
  `;

  // const buttonStyle = (type === "secondary" ? buttonStyleSecondary : buttonStylePrimary);

  const buttonStyle = (
    type === "secondary" 
    ? buttonStyleSecondary 
    : type === "primary" && disabled && shape !== "slim" // let slim disabled still be primary (just disabled)
    ? buttonStylePrimaryLoading
    : buttonStylePrimary
  );

  if (link) {
    return (
      <div>
        <Link to={to}
          onClick={onClick} 
          className={className + buttonStyle}
        >
          {children}
        </Link>
      </div>
    )
  }

  return (
    <div>
      <button 
        disabled={disabled}
        onClick={onClick} 
        className={className + buttonStyle}
      >
        {children}
      </button>
    </div>
  )
};



export {
  Button
};