import * as React from "react";
import styled, { StyledComponentProps } from "styled-components";
import { theme } from "styles/theme";

export default function Divider(
  props: StyledComponentProps<"div", any, {}, never> & {
    spanAll?: boolean;
  }
) {
  if (props.spanAll) {
    return <SSpanAll {...props} />;
  }
  return <SCon {...props} />;
}

const SSpanAll = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  background: ${theme.border.light};
`;

const SCon = styled.div`
  width: 50px;
  height: 2px;
  margin: 0.5rem 0;
  border-radius: 5px;
  background: ${theme.color.main};
`;
