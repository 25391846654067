import axios from "api/axios";
import { IconButton, MenuButton, SubtleButton } from "components/button";
import Popper from "components/react-popperv2";
import Segment, { SContent, SHeader } from "components/segment";
import * as React from "react";
import { RiMore2Fill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import type { Status } from "types/status";
import { dollarFormatter } from "utils";
import { Status as StatusTag } from "views/request/component/status";
import EstimatesList from "containers/estimatesListV2";
import { TEstimate } from "types/estimate";
import { useTranslation } from 'react-i18next';

type Estimate = {
  id: number;
  name: string;
  total: number;
  status: Status;
};

type Quote = {
  fk_quote_id: string;
  estimates: Estimate[];
};
export default function Estimates() {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.components.estimates";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const params = useParams<{ id: string }>();

  const estimates = useQuery<TEstimate[]>(
    ["estimates", "project", params.id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${params.id}/estimates
  `
        )
        .then((res) => res.data)
  );

  if (!estimates.data) return null;

  return (
    <div className="divide-y">
      <div className="flex justify-between items-center py-4">
        <h2>{tr("Estimates")}</h2>
      </div>
      <div className="py-4">
        <EstimatesList data={estimates.data} type="projects" id={params.id} />
      </div>
    </div>
  );
}

const GroupedEstimates = ({
  updated,
  data,
}: {
  updated: () => void;
  data: Quote;
}) => {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.components.estimates";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const populateEstimates = data.estimates.map((estimate) => {
    return (
      <SGroupedContent key={estimate.id}>
        <div>
          <div>
            <span>{estimate.name}</span>
            <StatusTag color={estimate.status.color}>
              {estimate.status.label}
            </StatusTag>
          </div>
          <span style={{ color: theme.color.subtext3 }}>
            {dollarFormatter(estimate.total)}
          </span>
        </div>
        <div>
          <Popper
            reference={(props) => (
              <IconButton size="small" onClick={props.toggle}>
                <RiMore2Fill />
              </IconButton>
            )}
            container={(props) => (
              <EstimateDropdown
                updated={() => {
                  props.close();
                  updated();
                }}
                id={estimate.id}
              />
            )}
          />
        </div>
      </SGroupedContent>
    );
  });
  return (
    <SGroupedEstimates>
      <SGroupedHeader>
        <h4 style={{ color: theme.color.subtext2 }}>
          {tr("From Quote")} #{data.fk_quote_id}
        </h4>
      </SGroupedHeader>
      {populateEstimates}
    </SGroupedEstimates>
  );
};

const SGroupedEstimates = styled.div`
  border-bottom: 1px solid ${theme.border.dark};
  padding-top: 1em;
`;

const SGroupedHeader = styled.div`
  display: flex;
`;

const SGroupedContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  border-bottom: 1px solid ${theme.border.light};
  > div:first-child {
    display: flex;
    flex-direction: column;
  }
  &:hover {
    cursor: pointer;
    background: ${theme.bg.hoverblue};
  }
`;

const EstimateDropdown = ({
  updated,
  id,
}: {
  updated: () => void;
  id: number;
}) => {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.components.estimates";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const mutation = useMutation(
    async (e: { status: number }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${id}/status
  `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: updated,
    }
  );

  return (
    <div>
      <MenuButton onClick={() => mutation.mutate({ status: 5 })}>
        {tr("Mark as Won")}
      </MenuButton>
      <MenuButton onClick={() => mutation.mutate({ status: 4 })}>
        {tr("Mark as Lost")}
      </MenuButton>
    </div>
  );
};
