import React, { useEffect, useState } from "react";
import { ICustomer } from "../types";
import { Button } from "../../../../components/buttonV2";
import axios from "axios";
import { FiExternalLink } from "react-icons/fi";
import { IoIosArrowRoundForward } from "react-icons/io";
import TooltippedElement from "components/TooltippedElement";
import { MdVerifiedUser } from "react-icons/md";
import { useTranslation } from 'react-i18next';

interface MergeModalProps {
  original: ICustomer; // original
  duplicate: ICustomer; // duplicate
  mergeDuplicates: (originalId: string, duplicateId: string, originalCustomer: any, duplicateCustomer: any, flipped: boolean ) => Promise<void>;
  setModal: () => void;
  cannotFlip: boolean
};

interface MergeArea {
  table_name: string;
  original_count: number;
  duplicate_count: number;
}

function MergeModal({ original, duplicate, mergeDuplicates, setModal, cannotFlip }: MergeModalProps) {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.MergeModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [mergeAreas, setMergeAreas] = useState<MergeArea[]>([]);
  const [arrowRight, setArrowRight] = useState(true);

  useEffect(() => {
    if (original.fk_customer_id !== null) {
      setArrowRight(true);
    } else if (duplicate.fk_customer_id !== null) {
      setArrowRight(false);
    } else {
      setArrowRight(true);
    }
  }, [original, duplicate]);

  const onClickMerge = () => {
    const flipped = !arrowRight;
    let id1, id2, customer1, customer2;
    if (!arrowRight) {
      id1 = duplicate.id.toString();
      id2 = original.id.toString();
      customer1 = duplicate;
      customer2 = original;
    } else {
      id1 = original.id.toString();
      id2 = duplicate.id.toString();
      customer1 = original;
      customer2 = duplicate;
    }
    mergeDuplicates(
      id1,
      id2,
      customer1,
      customer2,
      flipped // boolean used for --> or <--
    );
    setModal();
  };

  const seeWhereMerge = async (ids: {id1: number, id2: number}) => {
    const {id1, id2} = ids;
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/duplicates/customers/seeWhereMerge/${id1}&${id2}`);
    if (!res.data) return;
    // remove logs from the response
    const withoutLogsRes = res.data.filter((area: any) => area.table_name !== "Logs");
    setMergeAreas(withoutLogsRes);
  };

  const viewCustomer = (id: number) => {
    window.open(`/customers/${id}`, "_blank");
  };

  useEffect(() => {
    seeWhereMerge({id1: original.id, id2: duplicate.id});
  }, []);

  return (
    <div className="w-[550px]">
      <div>
        <span>{tr("This action will merge the following data from the duplicate customer into the primary customer.")} </span>
        <span className="text-sm text-gray-400"> 
         ({tr("You can also reverse the order by clicking the")}: <span className="text-primary">{"->"}</span> {tr("icon")})
        </span>
      </div>

      <div className="grid grid-cols-7 w-full my-3">
        <div className="col-span-3">
          <div className="text-gray-600 flex justify-center border-b mb-1">
            {tr("Duplicate")}
            {duplicate.fk_customer_id && 
              <TooltippedElement 
                message="Verified Customer"
                placement="top"
                element={ <MdVerifiedUser className="text-secondary text-[0.9rem] mt-[3px] ml-0.5" /> }
              />
            }
          </div>
          <div>
            <span className="flex items-center flex-wrap">
              {duplicate.first_name} {duplicate.last_name}
              &nbsp;
              {duplicate.company_name ?
                <span className="text-gray-500"> ({duplicate.company_name})</span>
                : null
              }
              <button 
                onClick={() => viewCustomer(duplicate.id)} 
                className="text-secondary hover:text-secondary-light"
              >
                <FiExternalLink size={18} className="ml-1" />
              </button>
            </span>
            {mergeAreas.map((area, index) => (
              <div key={index}>
                {area.duplicate_count > 0 && (
                  `${area.table_name} (${area.duplicate_count})`
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1 mt-3.5 flex justify-center">
            <button 
              onClick={() => setArrowRight(!arrowRight)}
              disabled={cannotFlip}
              className={`
                flex items-center justify-center w-12 h-12 text-primary transform-gpu transition-transform duration-300 ease-in-out 
                hover:border hover:border-secondary rounded-full
                ${cannotFlip ? 'cursor-not-allowed' : 'cursor-pointer'}
                ${arrowRight ? 'rotate-0' : 'rotate-180'}
              `}
              >
              {cannotFlip ? (
                <TooltippedElement 
                  message={tr("Cannot flip verified customer with unverified")}
                  placement="top"
                  element={ <IoIosArrowRoundForward size={50} /> }
                />
              ) : (
                <IoIosArrowRoundForward size={50} />
              )}
            </button>
        </div>
        <div className="col-span-3">
          <div className="text-gray-600 flex justify-center border-b mb-1">
            {tr("Original")}
            {original.fk_customer_id &&
            <TooltippedElement 
                message={tr("Verified Customer")}
                placement="top"
                element={ <MdVerifiedUser className="text-secondary text-[0.9rem] mt-[3px] ml-0.5" /> }
              />
            }
          </div>
          <div>
            <span className="flex items-center flex-wrap">
              {original.first_name} {original.last_name}
                &nbsp;{original.company_name ?
                  <span className="text-gray-500"> ({original.company_name})</span>
                  : null
                }
              <button 
                onClick={() => viewCustomer(original.id)} 
                className="text-secondary hover:text-secondary-light"
              >
                <FiExternalLink size={18} className="ml-1" />
              </button>
            </span>
            {mergeAreas.map((area, index) => (
              <div key={index}>
                {area.original_count > 0 && (
                  `${area.table_name} (${area.original_count})`
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="border mb-1.5" />
      <div>
        {tr("Are you sure you want to proceed? This action cannot be undone.")}
      </div>
      <div className="flex flex-row justify-end space-x-4 mt-2">
        <Button onClick={onClickMerge}>
          {tr("Merge")}
        </Button>
        <Button 
          type="secondary"
          onClick={() => setModal()}
        >
          {tr("Cancel")}
        </Button>
      </div>
    </div>
  )
}

export default MergeModal;