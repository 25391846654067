import React from "react";
import { useTranslation } from 'react-i18next';

import Row from "../row/row.component";

import "./row-list.styles.css";

const RowList = (props) => {
  const { t } = useTranslation();
  const tBase = "components.row-list";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <div>
      <div className="headers">
        <div className="header-job">{tr("Job")}</div>
        <div className="header-date">{tr("Date")}</div>
        <div className="header-tech">{tr("Technician")}</div>
        <div className="header-customer">{tr("Customer")}</div>
        <div className="header-address">{tr("Address")}</div>
        <div className="header-status">{tr("Status")}</div>
      </div>
      {props.jobs.map((job, index) => (
        <Row
          changeModal={props.changeModal}
          setModalJob={props.setModalJob}
          job={job}
          index={index}
        />
      ))}
    </div>
  );
};

export default RowList;
