import { useState } from "react";
import axios from "axios";
import formatDollars from "utils/formatDollars";
import Invoice from "../types/Invoice";
import formatClientDate from "utils/formatClientDate";
import { OutlineButton } from "components/button";
import { MdOpenInNew } from "react-icons/md";
import { useTranslation } from "react-i18next";

type Props = {
  invoice: Invoice;
  invalidateQueries: () => void;
};

const InvoiceCard = ({ invoice, invalidateQueries }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.online.components.InvoiceCard";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const resyncInvoice = async (invoiceId: string) => {
    setIsLoading(true);
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/sync-invoice`,
      { invoiceId }
    );
    invalidateQueries();
    setIsLoading(false);
  };

  const resetInvoice = async (invoiceId: string) => {
    setIsLoading(true);
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/reset-invoice`,
      { invoiceId }
    );
    invalidateQueries();
    setIsLoading(false);
  };

  const handleOpenInvoice = () => {
    const invoiceId = invoice.id;
    window.open(`/invoices/${invoiceId}`);
  };

  const cardClass =
    invoice.qbol_id && invoice.qbol_synced_at
      ? "border-2 border-green-500 bg-green-200"
      : !invoice.qbol_id && invoice.qbol_message
      ? "border-2 border-red-500 bg-red-200"
      : invoice.qbol_message && (!invoice.qbol_synced_at || !invoice.qbol_id)
      ? "border-2 border-orange-500 bg-orange-50"
      : "border-2 border-gray-500 bg-gray-50";

  return (
    <div
      className={`bg-white p-4 rounded-lg shadow mb-1 mr-1 text-xs ${cardClass}`}
    >
      <div className="flex flex-row justify-between">
        <div className="w-[50%]">
          <p>
            <div className="flex flex-row items-center">
              <strong>ID:</strong> {invoice.id}
              <MdOpenInNew
                color="blue"
                className="cursor-pointer ml-2"
                onClick={handleOpenInvoice}
              />
            </div>
          </p>
          <p>
            <strong>{tr("Name")}:</strong> {invoice.first_name}{" "}
            {invoice.last_name}
          </p>
          <p>
            <strong>{tr("Company")}:</strong> {invoice.company_name}
          </p>
          <p>
            <strong>{tr("Customer ID")}:</strong> {invoice.fk_bcustomer_id}
          </p>
          <p>
            <strong>{"Products"}</strong>{" "}
            {invoice.subtotal
              ? formatDollars(invoice.subtotal)
              : "Invalid Amount"}{" "}
            {/* TODO: Add translation */}
          </p>
          <p>
            <strong>{"Fee"}:</strong> {/* TODO: Add translation */}
            {invoice.total_fee ? formatDollars(invoice.total_fee) : "$0.00"}
          </p>
          <p>
            <strong>{"Discount"}:</strong> {/* TODO: Add translation */}
            {invoice.total_discount
              ? formatDollars(invoice.total_discount)
              : "$0.00"}
          </p>
          <p>
            <strong>{"Subtotal"}:</strong>{" "}
            {invoice.subtotal
              ? formatDollars(
                  Number(invoice.subtotal) +
                    Number(invoice.total_fee ? invoice.total_fee : 0) -
                    Number(invoice.total_discount ? invoice.total_discount : 0)
                )
              : "Invalid Amount"}{" "}
            {/* TODO: Add translation */}
          </p>
          <p>
            <strong>{tr("Tax")}:</strong>{" "}
            {invoice.percent_tax_rate &&
            invoice.subtotal &&
            invoice.total_fee &&
            invoice.total_discount
              ? formatDollars(
                  Number(
                    invoice.subtotal -
                      Number(invoice.total_fee) -
                      Number(invoice.total_discount)
                  ) * Number(invoice.percent_tax_rate)
                )
              : "$0.00"}
          </p>
          <p>
            <strong>{"Total"}:</strong>{" "}
            {invoice.total_after_discounts_fees_and_taxes
              ? formatDollars(invoice.total_after_discounts_fees_and_taxes)
              : "Invalid Amount"}{" "}
            {/* TODO: Add translation */}
          </p>
        </div>
        <div className="flex flex-col items-end w-[50%] text-right">
          <p>
            <strong>QBO ID:</strong> {invoice.qbol_id}
          </p>
          <p>
            <strong>{tr("Sync Token")}:</strong> {invoice.qbol_sync_token}
          </p>
          <p>
            <strong>{tr("Doc Number")}:</strong> {invoice.qbol_doc_num}
          </p>
          <p>
            <strong>{tr("Message")}:</strong> {invoice.qbol_message}
          </p>
          <p>
            <strong>{tr("Last Synced")}:</strong>{" "}
            {invoice.qbol_synced_at
              ? formatClientDate(invoice.qbol_synced_at.toString())
              : tr("Never")}
          </p>

          <div className="flex flex-row">
            <OutlineButton
              onClick={() => resetInvoice(invoice.id.toString())}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {tr("Reset")}
            </OutlineButton>
            <OutlineButton
              className="ml-2"
              onClick={() => resyncInvoice(invoice.id.toString())}
              disabled={isLoading}
              isLoading={isLoading}
            >
              {"Re-sync"}
            </OutlineButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
