import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import Spinner from "components/spinner";
import IFormValues from "../types/IFormValues";
import useUsers from "../hooks/useUsers";
import IUser from "../types/IUser";
import { useTranslation } from 'react-i18next';

type IProps = {
  isFetching: boolean;
  validationErrors: string[];
  formData: IFormValues;
  setFormData: (formData: IFormValues) => void;
};

const Filters = ({ isFetching, formData, setFormData }: IProps) => {  
  const { t } = useTranslation();
  const tBase = "views.admin.production.components.Filters";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { data } = useUsers();
  const { register, handleSubmit } = useForm<IFormValues>();

  const sortableColumns = [
    {
      label: "ID",
      value: "id",
    },
    {
      label: "Appointment ID",
      value: "fk_appointment_id",
    },
    {
      label: "User ID",
      value: "fk_user_id",
    },
    {
      label: "Estimate ID",
      value: "fk_estimate_id",
    },
    {
      label: "Invoice ID",
      value: "fk_invoice_id",
    },
    {
      label: "Appointment Production",
      value: "appointment_production",
    },
    {
      label: "Estimate Production",
      value: "estimate_production",
    },
    {
      label: "Invoice Production",
      value: "invoice_production",
    },
    {
      label: "Updated At",
      value: "updated_at",
    },
    {
      label: "Created At",
      value: "created_at",
    },
  ];

  const [validationErrors] = useState<string[]>([]);

  const onSubmit: SubmitHandler<IFormValues> = ({
    sortColumn,
    sortOrder,
    startDate,
    endDate,
    limit,
    offset,
    divisionId,
    requestId,
    projectId,
    appointmentId,
    userId,
  }) => {
    // console.log("submitting, setting formData");
    setFormData({
      sortColumn,
      sortOrder,
      startDate,
      endDate,
      limit,
      offset,
      divisionId,
      requestId,
      projectId,
      appointmentId,
      userId,
    });
  };

  return (
    <div className="p-3 rounded-md bg-slate-300">
      <div className="flex flex-row items-center justify-between mb-2">
        <h2>{tr("Search Options")}</h2>
        <button
          type="submit"
          disabled={isFetching || validationErrors.length > 0}
          className="p-2 text-white transition rounded-md bg-primary hover:bg-primary-dark w-[100px] h-[40px]"
          onClick={handleSubmit(onSubmit)}
        >
          {isFetching ? <Spinner /> : tr("Search")}
        </button>
      </div>

      <hr className="mt-2 mb-2" />

      <form
        className="grid grid-cols-3 gap-3 mb-4 bg-transparent rounded-md bg-secondary-100"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Sort Column")}</label>
          <select
            {...register("sortColumn")}
            value={formData?.sortColumn}
            onChange={(e) => {
              const selected = sortableColumns.find(
                (column) => column.value === formData?.sortColumn
              );
              if (selected) {
                setFormData({
                  ...formData,
                  sortColumn: e.target.value,
                });
              }
            }}
            className="w-auto p-2 border rounded-md"
          >
            {sortableColumns.map((column) => (
              <option key={column.value} value={column.value}>
                {tr(column.label)}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Sort Order")}</label>
          <select
            {...register("sortOrder")}
            value={formData?.sortOrder}
            onChange={(e) => {
              setFormData({
                ...formData,
                sortOrder: e.target.value,
              });
            }}
            className="w-auto p-2 border rounded-md"
            disabled
          >
            <option value="asc">{tr("Ascending")}</option>
            <option value="desc">{tr("Descending")}</option>
          </select>
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Start Date")}</label>
          <input
            {...register("startDate")}
            name="startDate"
            className="flex-grow px-2 py-1 border rounded-md"
            type="date"
            defaultValue={""}
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("End Date")}</label>
          <input
            {...register("endDate")}
            name="endDate"
            className="flex-grow px-2 py-1 border rounded-md"
            type="date"
            defaultValue={""}
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Limit")}:</label>
          <input
            {...register("limit")}
            defaultValue={formData?.limit || 100}
            name="limit"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
            required
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Offset")}</label>
          <input
            {...register("offset")}
            defaultValue={formData?.offset || 0}
            name="offset"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
            required
            disabled
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Division ID")}</label>
          <input
            {...register("divisionId")}
            name="divisionId"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Request ID")}</label>
          <input
            {...register("requestId")}
            name="requestId"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Project ID")}</label>
          <input
            {...register("projectId")}
            name="projectId"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("Appointment ID")}</label>
          <input
            {...register("appointmentId")}
            name="appointmentId"
            className="flex-grow px-2 py-1 border rounded-md"
            type="number"
          />
        </div>

        <div className="flex items-center justify-between space-x-2">
          <label className="ml-2 text-left">{tr("User ID")}</label>
          <select
            {...register("userId")}
            name="userId"
            className="flex-grow px-2 py-1 border rounded-md"
            defaultValue={formData?.userId || ""}
          >
            <option value="">{tr("Select a user")}</option>
            {data
              ?.sort((a: IUser, b: IUser) =>
                a.first_name.localeCompare(b.first_name)
              )
              ?.map((user: IUser) => (
                <option key={user.user_id} value={user.user_id}>
                  {user.first_name + " " + user.last_name}
                </option>
              ))}
          </select>
        </div>
      </form>
    </div>
  );
};

export default Filters;
