import { Button } from "components/button";
import IProductionItem from "../types/IProductionItem";
import { useTranslation } from 'react-i18next';

type Props = {
  data: IProductionItem[] | undefined;
};

const ExportButton = ({ data }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.admin.production.components.ExportButton";
  const tr = (key: string) => t(`${tBase}.${key}`);
  return <Button>{tr("Export")}</Button>;
};

export default ExportButton;
