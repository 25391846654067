import { TTargetProduct } from "types/product";
import { TTargetTax } from "types/tax";

export const calculateTotal = (array?: TTargetProduct[]) => {
  return (
    (array &&
      array.reduce(
        (prev, product) => ({
          list: prev.list + product.quantity * +(product.list_price || 0),
          sale: prev.sale + product.quantity * +(product.unit_price || 0),
          install:
            prev.install + product.quantity * +(product.install_price || 0),
        }),
        {
          list: 0,
          sale: 0,
          install: 0,
        }
      )) || {
      list: 0,
      sale: 0,
      install: 0,
    }
  );
};

type Total = {
  list: number;
  sale: number;
  install: number;
};

export const calculateTaxTotal = (total: Total, taxes?: TTargetTax[]) =>
  taxes?.reduce(
    (prev, tax) => {
      const listTotal =
        tax.list_type === "fixed"
          ? tax.list_rate
          : (tax.list_rate / 100) * total.list;
      const saleTotal =
        tax.sale_type === "fixed"
          ? tax.sale_rate
          : (tax.sale_rate / 100) * total.sale;
      const installTotal =
        tax.install_type === "fixed"
          ? tax.install_rate
          : (tax.install_rate / 100) * total.install;
      return {
        list: prev.list + listTotal,
        sale: prev.sale + saleTotal,
        install: prev.install + installTotal,
      };
    },
    {
      list: 0,
      sale: 0,
      install: 0,
    }
  ) || {
    list: 0,
    sale: 0,
    install: 0,
  };

export const calculateGrandTotal = (total: Total, taxTotal: Total) => ({
  list: total.list + taxTotal.list,
  sale: total.sale + taxTotal.sale,
  install: total.install + taxTotal.install,
});
