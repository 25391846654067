import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

import "./index.scss";
import LevelItem from "./level-item";

const ManageWarehouses = (props) => {
	const { t } = useTranslation();
	const tBase = "views.warehouse.manage.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [warehouse, setWarehouse] = useState(null);
	const [warehouses] = useState(
		props.warehouses.map((w) => {
			return { value: w.warehouse_id, label: w.warehouse_name };
		})
	);
	const [levels, setLevels] = useState(null);
	const [filter, setFilter] = useState("");
	const [filteredLevels, setFilteredLevels] = useState(null);

	const getWarehouseItems = async () => {
		try {
			const response = await axios({
				method: "get",
				url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/inventory/inventoryLevels/${warehouse.value}`,
			});

			setLevels(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getWarehouseItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse]);

	useEffect(() => {
		if (levels && levels.length > 0) {
			let tempLevels = [];

			levels.forEach((level) => {
				let item = "";

				item += level.item_id.toString();

				if (level.item_name) {
					item += level.item_name.toString();
				}

				if (level.long_description) {
					item += level.long_description.toString();
				}

				if (level.model) {
					item += level.model.toString();
				}

				if (level.category_name) {
					item += level.category_name.toString();
				}

				item = item.trim().toLowerCase();

				if (item.includes(filter.trim().toString().toLowerCase())) {
					tempLevels.push(level);
				}
			});

			if (tempLevels.length > 0) {
				setFilteredLevels(tempLevels);
			}
		}
	}, [filter, levels]);

	if (!warehouses) {
		return null;
	}

	return (
		<div style={{ marginRight: "20%", marginLeft: "20%" }}>
			<div style={{ marginLeft: 20, marginTop: 20 }}>
				{tr("Choose Warehouse")}
			</div>
			<div className="react-select-container">
				<Select
					value={warehouse}
					onChange={(e) => {
						setWarehouse(e);
					}}
					options={warehouses}
				/>
				<div style={{ marginTop: 10 }}>{tr("Filter")}</div>
				<input
					style={{
						padding: 5,
						border: "1px solid gray",
						width: "100%",
						marginBottom: 10,
					}}
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>
			</div>
			<div className="warehouse-content-container">
				{filteredLevels &&
					filteredLevels.length > 0 &&
					filteredLevels?.map((item) => {
						return (
							<LevelItem
								warehouseId={warehouse.value}
								item={item}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default ManageWarehouses;
