/* File made by Hannah Green 
   for task ES-45 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsPlus } from "react-icons/bs";
import { ContainerWithSidebar } from "components/layout";
import Modal from "react-modal";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from 'react-i18next';

import "./index.css";

Modal.setAppElement("#root");

export default function CheckOut() {
  const { t } = useTranslation();
  const tBase = "views.vehicleCheckout.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [userVehicles, setUserVehicles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCard, setCurrentCard] = useState({});

  useEffect(() => {
    fetchUsers();
    fetchVehicles();
  }, []);

  //Fetch the users from the database
  const fetchUsers = async () => {
    try {
      const userResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/users`
      );

      // console.log(userResponse.data);
      setUsers(userResponse.data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchVehicles = async () => {
    try {
      const vehicleResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/vehicles`
      );

      setVehicles(vehicleResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserVehicles = async (userId) => {
    try {
      const userVehicleResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/userVehicles/${userId}`
      );

      setUserVehicles(userVehicleResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const checkOut = async (userId, vehicleId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/checkOut`,
        {
          userId,
          vehicleId,
        }
      );

      if (response.status === 200) {
        fetchUsers();
        fetchVehicles();
        getUserVehicles(currentUser.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIn = async (userId, vehicleId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/checkIn`,
        {
          userId,
          vehicleId,
        }
      );

      if (response.status === 200) {
        fetchUsers();
        fetchVehicles();
        getUserVehicles(currentUser.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDefault = async (userId, vehicleId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicleCheckout/updateDefault`,
        {
          userId,
          vehicleId,
        }
      );

      if (response.status === 200) {
        fetchUsers();
        fetchVehicles();
        getUserVehicles(currentUser.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterUsers = () => {
    const filteredUserList = users.map((user) => {
      let show = false;
      let itemList;

      if (
        user.hasOwnProperty("equipment") &&
        user.equipment &&
        user.equipment.length > 0
      ) {
        itemList = user.equipment.map((item) => {
          if (
            item.vehicle_label.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            show = true;
            return item;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }

      if (show) {
        return itemList;
      } else {
        return null;
      }
    });

    setFilteredUsers(filteredUserList);
  };

  useEffect(() => {
    filterUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (currentUser.hasOwnProperty("user_id") && currentUser.user_id) {
      getUserVehicles(currentUser.user_id);
    }
  }, [currentUser]);

  const handleClick = async (event, userId, vehicleId) => {
    await setAnchorEl(event.currentTarget);
    await setCurrentCard({ userId, vehicleId });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Modal
        className="modal"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableScrollLock={true}
        >
          <MenuItem
            onClick={() => {
              updateDefault(currentCard.userId, currentCard.vehicleId);
              handleClose();
            }}
          >
            {tr("Set as Default")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              checkIn(currentCard.userId, currentCard.vehicleId);
              handleClose();
            }}
          >
            {tr("Remove")}
          </MenuItem>
        </Menu>
        <div className="modal-internal-container">
          {currentUser.hasOwnProperty("first_name") ? (
            <div>
              <div className="modal-name">
                {currentUser.first_name} {currentUser.last_name}
              </div>
              <div className="modal-h2">{tr("Current Vehicles")}</div>
              <div className="modal-vehicle-list">
                {userVehicles &&
                  userVehicles.length > 0 &&
                  userVehicles?.map((vehicle) => {
                    return (
                      <div
                        onClick={(e) =>
                          handleClick(
                            e,
                            currentUser.user_id,
                            vehicle.vehicle_id
                          )
                        }
                        className="modal-vehicle-card"
                        style={
                          vehicle.is_default
                            ? {
                                backgroundColor: "#ff4f00",
                                color: "#fff",
                                borderColor: "#ffffff00",
                              }
                            : null
                        }
                      >
                        <div>{vehicle.vehicle_label}</div>
                      </div>
                    );
                  })}
              </div>

              <div className="modal-h2">{tr("Unassigned Vehicles")}</div>
              <div className="modal-vehicle-list">
                {vehicles &&
                  vehicles.length > 0 &&
                  vehicles?.map((vehicle) => {
                    if (vehicle.vehicle_count > 0) {
                      return null;
                    } else {
                      return (
                        <div
                          onClick={() =>
                            checkOut(
                              parseInt(currentUser.user_id),
                              parseInt(vehicle.vehicle_id)
                            )
                          }
                          className="modal-vehicle-card"
                          style={
                            (vehicle.is_default
                              ? {
                                  backgroundColor: "#ff4f00",
                                  color: "#fff",
                                }
                              : null,
                            {
                              justifyContent: "space-between",
                            })
                          }
                        >
                          <div>{vehicle.vehicle_label}</div>
                          <BsPlus
                            color="green"
                            size={18}
                            className="modal-vehicle-plus"
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
      <ContainerWithSidebar>
        <div className="unassigned-vehicle-container">
          <div className="container-label">{tr("Unassigned Vehicles")}</div>
          <div className="unassigned-vehicle-inner-container">
            <div className="unassigned-vehicle-list">
              {vehicles &&
                vehicles.length > 0 &&
                vehicles?.map((vehicle) => {
                  if (vehicle.vehicle_count > 0) {
                    return null;
                  } else {
                    return (
                      <div className="unassigned-vehicle-card">
                        {/* <FaTruckPickup /> */}
                        <div className="vehicle-label">
                          {vehicle.vehicle_label}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div className="user-container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 10,
              paddingRight: 100,
            }}
          >
            <div className="container-label-driver">{tr("Drivers")}</div>
            <input
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                filterUsers();
              }}
              placeholder={tr("Search...")}
              className="search"
              style={{ fontSize: 12 }}
            />
          </div>
          <div className="user-inner-container">
            <div className="user-grid">
              {searchTerm === "" &&
                users &&
                users.length > 0 &&
                users?.map((user) => {
                  if (!user) {
                    return null;
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setCurrentUser(user);
                          setModalIsOpen(true);
                        }}
                        className="user-card"
                      >
                        <div>
                          {user.first_name} {user.last_name}
                        </div>
                        <div className="vehicle-list">
                          {user.equipment &&
                            user.equipment.length > 0 &&
                            user.equipment?.map((item) => {
                              return (
                                <div
                                  className="vehicle-card"
                                  style={
                                    item.is_default
                                      ? {
                                          backgroundColor: "#ff4f00",
                                          color: "#fff",
                                        }
                                      : null
                                  }
                                >
                                  {/* <FaTruckMoving /> */}
                                  <div className="vehicle-label">
                                    {item.vehicle_label}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  }
                })}

              {searchTerm !== "" &&
                filteredUsers &&
                filteredUsers.length > 0 &&
                filteredUsers?.map((user) => {
                  if (!user) {
                    return null;
                  } else {
                    return (
                      <div
                        onClick={() => {
                          setCurrentUser(user);
                          setModalIsOpen(true);
                        }}
                        className="user-card"
                      >
                        <div>
                          {user.first_name} {user.last_name}
                        </div>
                        <div className="vehicle-list">
                          {user.equipment &&
                            user.equipment.length > 0 &&
                            user.equipment?.map((item) => {
                              if (!item) {
                                return null;
                              } else {
                                return (
                                  <div
                                    className="vehicle-card"
                                    style={
                                      item.is_default
                                        ? {
                                            backgroundColor: "#ff4f00",
                                            color: "#fff",
                                          }
                                        : null
                                    }
                                  >
                                    {/* <FaTruckMoving /> */}
                                    <div className="vehicle-label">
                                      {item.vehicle_label}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </ContainerWithSidebar>
    </>
  );
}
