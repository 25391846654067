import * as React from "react";

const EditIcon = ({ 
  outlineFill = "#000",
  pencilFill = "#000", 
  className = "",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} className={className}>
    <g>
      <path
        d="M20 11.5a1 1 0 0 0-1 1V21a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h10a1 1 0 0 0 0-2H3a3 3 0 0 0-3 3v17a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-8.5a1 1 0 0 0-1-1Z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: outlineFill,
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.83 0 0 .83 2.04 2.04)"
      />
      <path
        d="M23.41.58a2 2 0 0 0-2.82 0L10.3 10.87a.45.45 0 0 0-.11.17l-1.42 3.53a.51.51 0 0 0 .11.54.49.49 0 0 0 .54.11L13 13.81a.62.62 0 0 0 .17-.11L23.41 3.41a2 2 0 0 0 0-2.83Z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: pencilFill,
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.83 0 0 .83 2.04 2.04)"
      />
    </g>
  </svg>
);

const SaveIcon = ({
  color = "#2091ff",
  className = "",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} className={className}>
    <path
      d="M2.594 1A1.6 1.6 0 0 0 1 2.594v9.812A1.6 1.6 0 0 0 2.594 14h9.812A1.6 1.6 0 0 0 14 12.406V4.043L10.957 1Zm0 1H3v3c0 .547.453 1 1 1h6c.547 0 1-.453 1-1V2.457l2 2v7.95a.586.586 0 0 1-.594.593H11v-3c0-.547-.453-1-1-1H5c-.547 0-1 .453-1 1v3H2.594A.586.586 0 0 1 2 12.406V2.594C2 2.258 2.258 2 2.594 2ZM4 2h3v2h2V2h1v3H4Zm1 8h5v3H5Z"
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: color,
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(1.54 0 0 1.54 .45 .45)"
    />
  </svg>
);

const WizardIcon = ({
  className = "",
  // default gradient
  gradient = {
    top: "#1a6dff",
    bottom: "#c822ff",
    starTop: "#6dc7ff", 
    starBottom: "#e6abff",
  }
 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={24} height={24}>
    <g>
      {/* Left Star */}
      <g transform="matrix(.31 0 0 .31 5.18 6.42)">
        <linearGradient
          id="a"
          x1={10}
          x2={10}
          y1={10.191}
          y2={17.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            style={{
              stopColor: gradient.starTop,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: gradient.starBottom,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <path
          d="m10.612 10.461.267.926a2.535 2.535 0 0 0 1.735 1.735l.926.267c.614.177.614 1.047 0 1.224l-.926.267a2.535 2.535 0 0 0-1.735 1.735l-.267.926c-.177.614-1.047.614-1.224 0l-.267-.926a2.535 2.535 0 0 0-1.735-1.735l-.926-.267c-.614-.177-.614-1.047 0-1.224l.926-.267a2.535 2.535 0 0 0 1.735-1.735l.267-.926c.177-.615 1.047-.615 1.224 0z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "url(#a)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="translate(-10 -14)"
        />
      </g>
      {/* Right Star */}
      <g transform="matrix(.31 0 0 .31 18.2 8.9)">
        <linearGradient
          id="b"
          x1={52}
          x2={52}
          y1={17.808}
          y2={26.324}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            style={{
              stopColor: gradient.starTop,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: gradient.starBottom,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <path
          d="m52.612 18.461.267.926a2.535 2.535 0 0 0 1.735 1.735l.926.267c.614.177.614 1.047 0 1.224l-.926.267a2.535 2.535 0 0 0-1.735 1.735l-.267.926c-.177.614-1.047.614-1.224 0l-.267-.926a2.535 2.535 0 0 0-1.735-1.735l-.926-.267c-.614-.177-.614-1.047 0-1.224l.926-.267a2.535 2.535 0 0 0 1.735-1.735l.267-.926c.177-.615 1.047-.615 1.224 0z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "url(#b)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="translate(-52 -22)"
        />
      </g>
      {/* Main Icon */}
      <g transform="matrix(.31 0 0 .31 12 12)">
        <linearGradient
          id="c"
          x1={32}
          x2={32}
          y1={6.872}
          y2={60.234}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            style={{
              stopColor: gradient.top,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: gradient.bottom,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <path
          d="M57 49h-5.534L35.281 8.228A3.512 3.512 0 0 0 32 6a3.512 3.512 0 0 0-3.281 2.228L12.534 49H7a1 1 0 0 0-1 1c0 4.411 3.589 8 8 8h36c4.411 0 8-3.589 8-8a1 1 0 0 0-1-1zM30.578 8.966A1.522 1.522 0 0 1 32 8c.631 0 1.189.379 1.422.966L49.314 49H45v-2h-2v2h-2v-4h-2v4h-2v-6h-2v6h-2v-9h-2v9h-2v-6h-2v6h-2v-4h-2v4h-2v-2h-2v2h-4.314L30.578 8.966zM50 56H14a6.01 6.01 0 0 1-5.917-5h47.833A6.009 6.009 0 0 1 50 56z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "url(#c)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="translate(-32 -32)"
        />
      </g>
      {/* Star in icon */}
      <g transform="matrix(.31 0 0 .31 12 11.69)">
        <linearGradient
          id="d"
          x1={32}
          x2={32}
          y1={6.872}
          y2={60.234}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0%"
            style={{
              stopColor: gradient.top,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: gradient.bottom,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <path
          d="M29.998 31.666a.564.564 0 0 1 .152.487l-.198 1.202c-.074.45.379.793.766.58l1.036-.567a.512.512 0 0 1 .492 0l1.036.567c.388.212.841-.131.766-.58l-.198-1.202a.563.563 0 0 1 .152-.487l.838-.851c.314-.318.141-.873-.293-.939l-1.158-.175a.532.532 0 0 1-.398-.301l-.518-1.093a.519.519 0 0 0-.947 0l-.518 1.093a.532.532 0 0 1-.398.301l-1.158.175c-.433.066-.606.62-.293.939l.839.851z"
          style={{
            stroke: "none",
            strokeWidth: 1,
            strokeDasharray: "none",
            strokeLinecap: "butt",
            strokeDashoffset: 0,
            strokeLinejoin: "miter",
            strokeMiterlimit: 4,
            fill: "url(#d)",
            fillRule: "nonzero",
            opacity: 1,
          }}
          transform="translate(-32 -31)"
        />
      </g>
    </g>
  </svg>
);

const LockIcon = ({
  className = "",
  colorTop = "#000",
  colorBottom = "#000",
  classNameTop="",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={24} viewBox="0 0 10 24">
    {/* Lock top */}
    <g 
      className={classNameTop}
    >
    <path
      d="
      M16 0c-2.21 0-4.121.918-5.313 2.406C9.498 3.895 9 5.824 9 7.906V9h3V7.906c0-1.578.39-2.82 1.031-3.625C13.671 3.477 14.543 3 16 3c1.46 0 2.328.45 2.969 1.25.64.8 1.031 2.059 1.031 3.656V9h3V7.906c0-2.093-.527-4.043-1.719-5.531C20.09.887 18.207 0 16 0Z"
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: colorTop,
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.77 0 0 .77 -.32 1.99)"
    />
    </g>

    {/* Lock bottom */}
    <path
      d="
      M9 10a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V13a3 3 0 0 0-3-3Z
      m7 5c1.105 0 2 .895 2 2 0 .738-.402 1.371-1 1.719V21c0 .55-.45 1-1 1s-1-.45-1-1v-2.281c-.598-.348-1-.98-1-1.719 0-1.105.895-2 2-2Z"
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: colorBottom,
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.77 0 0 .77 -.32 1.99)"
    />
  </svg>
);

const HideIcon = ({
  className = "",
  strikeColor = "#000",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
    <path
      d="M15 5C6 5 .197 14.408.197 14.408v.028A.973.973 0 0 0 0 15a.97.97 0 0 0 .162.514v.03S5 25 15 25s14.838-9.455 14.838-9.455v-.031A.97.97 0 0 0 30 15a.973.973 0 0 0-.197-.564v-.028S24 5 15 5zm0 3a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
      style={{
        fill: "#000",
      }}
      transform="matrix(.67 0 0 .67 1.306 1.306)"
    />
    <path
      d="m3 3 18 18"
      style={{
        stroke: strikeColor,
        strokeWidth: 2,
      }}
    />
  </svg>
);

const MergeIcon = ({
  className = "",
  color = "#000",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} className={className}>
    <path
      d="M14.984 2.986a1 1 0 0 0-.78.397l-4.911 4.91a1 1 0 1 0 1.414 1.414L14 6.414V17c0 4.43-3.57 8-8 8a1 1 0 1 0 0 2c5.511 0 10-4.489 10-10V6.414l3.293 3.293a1 1 0 1 0 1.414-1.414l-4.916-4.916a1 1 0 0 0-.807-.39zm2.235 18.237c-.27.716-.606 1.398-1.002 2.04A9.978 9.978 0 0 0 24 27a1 1 0 0 0 0-2c-2.86 0-5.366-1.514-6.781-3.777z"
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: color,
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.83 0 0 .83 -.442 -.442)"
    />
  </svg>
);

// const AIStars = (props) => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -10 110 135" {...props}>
//     <path d="m47.629 57.789-3.34-1.238-1.238-3.34a1.503 1.503 0 0 0-2.82 0l-1.239 3.34-3.34 1.238a1.503 1.503 0 0 0 0 2.82l3.34 1.239 1.238 3.34a1.503 1.503 0 0 0 2.82 0l1.24-3.34 3.339-1.239a1.503 1.503 0 0 0 0-2.82zm-5.027 1.48c-.41.149-.73.481-.89.891l-.071.192-.07-.192a1.53 1.53 0 0 0-.891-.89l-.192-.07.192-.071c.41-.149.73-.48.89-.89l.07-.192.071.191c.149.41.469.73.89.89l.192.071zM66.699 46.109l-7.52-2.781-2.78-7.52a1.503 1.503 0 0 0-2.821 0l-2.781 7.52-7.52 2.781a1.503 1.503 0 0 0 0 2.82l7.52 2.782 2.78 7.52a1.503 1.503 0 0 0 2.821 0l2.781-7.52 7.52-2.782a1.503 1.503 0 0 0 0-2.82zm-9.21 3.02c-.411.148-.731.48-.892.89l-1.609 4.36-1.61-4.36a1.53 1.53 0 0 0-.89-.89l-4.36-1.61 4.36-1.61c.41-.148.73-.48.89-.89l1.61-4.36 1.61 4.36c.148.41.48.73.89.89l4.36 1.61zM41.398 41.801c.691 0 1.238-.559 1.238-1.238s-.558-1.239-1.238-1.239c-.68 0-1.238.559-1.238 1.239s.558 1.238 1.238 1.238z" />
//   </svg>
// );

const AIStars = ({
  className1 = "",
  className2 = "",
  color1 = "#a645b8",
  color2 = "#166BCC",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="28 28 40 40" >
    <path 
      d="m47.629 57.789-3.34-1.238-1.238-3.34a1.503 1.503 0 0 0-2.82 0l-1.239 3.34-3.34 1.238a1.503 1.503 0 0 0 0 2.82l3.34 1.239 1.238 3.34a1.503 1.503 0 0 0 2.82 0l1.24-3.34 3.339-1.239a1.503 1.503 0 0 0 0-2.82zm-5.027 1.48c-.41.149-.73.481-.89.891l-.071.192-.07-.192a1.53 1.53 0 0 0-.891-.89l-.192-.07.192-.071c.41-.149.73-.48.89-.89l.07-.192.071.191c.149.41.469.73.89.89l.192.071z" 
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: color1,
        fillRule: "nonzero",
        opacity: 1,
      }}
      className={className1}
    />
    <path 
      d="M66.699 46.109l-7.52-2.781-2.78-7.52a1.503 1.503 0 0 0-2.821 0l-2.781 7.52-7.52 2.781a1.503 1.503 0 0 0 0 2.82l7.52 2.782 2.78 7.52a1.503 1.503 0 0 0 2.821 0l2.781-7.52 7.52-2.782a1.503 1.503 0 0 0 0-2.82zm-9.21 3.02c-.411.148-.731.48-.892.89l-1.609 4.36-1.61-4.36a1.53 1.53 0 0 0-.89-.89l-4.36-1.61 4.36-1.61c.41-.148.73-.48.89-.89l1.61-4.36 1.61 4.36c.148.41.48.73.89.89l4.36 1.61zM41.398 41.801c.691 0 1.238-.559 1.238-1.238s-.558-1.239-1.238-1.239c-.68 0-1.238.559-1.238 1.239s.558 1.238 1.238 1.238z" 
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: color2,
        fillRule: "nonzero",
        opacity: 1,
      }}
      className={className2}
    />
  </svg>
);

export {
  EditIcon,
  SaveIcon,
  WizardIcon,
  LockIcon,
  HideIcon,
  MergeIcon,
  AIStars,
}
