import * as react from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  progress: number;
};

export default function CircularProgress(props: Props) {
  return <Con progress={props.progress} />;
}

const Con = styled.div<{ progress: number; size?: number }>`
  background: conic-gradient(
    #ff4f00 0,
    #ff4f00 ${(props) => props.progress}%,
    #f0f0f0 0,
    #f0f0f0 100%
  );
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &::before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: 50%;
    background: white;
    z-index: 1;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;
