import React, { useState } from "react";
import axios from "api/axios";
import { A, OutlineButton } from "components/button";
import FlatContainer from "components/container/flat";
import SafeArea from "components/safe-area";
import { RiAddFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { TInventory } from "types/inventory";
import queryString from "query-string";
import Pagination from "components/table/pagination";
import Field from "components/field";
import { useAreYouSure } from "components/areYouSure";
import { useSnackbarContext } from "components/snackbar";
import Tab from "components/tab";
import Product from "./product";
import { TProduct } from "types/product";
import { TItemGroup } from "types/item_group";
import { TCategory } from "./categories/TCategory";
import { useModalContext } from "components/modal";
import EditItemGroupModal from "views/item_group/edit";
import { Categories } from "./categories";
import { CategoryModal } from "./categories/CategoryModal";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';

type TCategoryPicker = {
  value: number;
  label: string;
  is_parent_category: boolean;
  parent_category_id: number | null;
};

export default function InventoriesView() {
  const { t } = useTranslation();
  const tBase = "views.inventories.index2";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const location = useLocation<{ page: string }>(),
    history = useHistory(),
    areYouSure = useAreYouSure(),
    snackBar = useSnackbarContext(),
    modal = useModalContext();
  const [showArchived, setShowArchived] = useState(false);

  const search = queryString.parse(location.search);
  const query = useQuery<{
    items: (TInventory | TProduct | TItemGroup)[];
    page_count: number;
    item_count: number;
    services_count: number;
    groups_count: number;
    categories: TCategory[] | null;
  }>(
    ["items", [search, showArchived]],
    async () => {
      // console.log("query", query.data);
      let result: {
        items: (TInventory | TProduct | TItemGroup)[];
        page_count: number;
        item_count: number;
        services_count: number;
        groups_count: number;
        categories: TCategory[] | null;
      } = {
        items: [],
        page_count: 0,
        item_count: 0,
        services_count: 0,
        groups_count: 0,
        categories: null,
      };

      if (showArchived === true) {
        result = await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ALLITEMS_INCLUDING_ARCHIVED`,
            {
              params: {
                page: search.page || 1,
                category: search.category,
                search: search.search,
                tab: search.tab,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
      } else if (showArchived === false) {
        result = await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ALLITEMS`,
            {
              params: {
                page: search.page || 1,
                category: search.category,
                search: search.search,
                tab: search.tab,
              },
            }
          )
          .then((res) => {
            return res.data;
          });
      }

      return result;
    },
    {
      keepPreviousData: true,
    }
  );

  const archiveItem = useMutation(
    async (e: { id: string; type: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/${e.id}/${e.type}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        snackBar.showSnackbar(tr("Item archived!"));
      },
    }
  );

  const unarchiveItem = useMutation(
    async (e: { id: string; type: string }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/${e.id}/${e.type}/undo
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        query.refetch();
        snackBar.showSnackbar(tr("Item restored!"));
      },
    }
  );

  const populateItems =
    query.data !== undefined
      ? query.data?.items?.map((item) => (
          <Product
            key={"item_id" in item ? item.item_id : item.id}
            data={item}
            type={"item_id" in item ? "part" : "product_label" in item ? "service" : "group"}
            archive={(id) =>
              areYouSure.activate(() =>
                archiveItem.mutate({
                  id: id,
                  type: "item_id" in item ? "part" : "product_label" in item ? "service" : "group",
                })
              )
            }
            unarchive={(id) =>
              areYouSure.activate(() =>
                unarchiveItem.mutate({
                  id: id,
                  type: "item_id" in item ? "part" : "product_label" in item ? "service" : "group",
                })
              )
            }
          />
        ))
      : new Array(3).fill(0).map((_, rowInd) => (
          <div className="flex p-4 space-x-4 animate-pulse" key={rowInd}>
            <div className="bg-slate-200 w-[100px] h-[100px] rounded" />
            <div className="flex flex-col justify-between flex-1 space-y-2">
              <div className="bg-slate-200 w-full max-w-[100px] h-2 rounded" />
              <div className="bg-slate-200 w-full max-w-[250px] h-4 rounded" />
              <div className="flex space-x-4">
                <div className="w-16 h-12 rounded bg-slate-200" />
                <div className="w-16 h-12 rounded bg-slate-200" />
                <div className="w-16 h-12 rounded bg-slate-200" />
              </div>
            </div>
          </div>
        ));

  const handleSearch = (e: any) => {
    history.push({
      search: queryString.stringify({
        ...search,
        search: e.target.value,
        page: 1,
      }),
    });
  };

  const handleCategorySelect = (e: { value: number; label: string } | null) => {
    history.push({
      search: queryString.stringify({
        ...search,
        page: 1,
        category: e?.value || null,
      }),
    });
  };

  let sortedCategories = query.data?.categories?.sort((a, b) =>
    (a.category_name || "").localeCompare(b.category_name || "")
  );

  // Add "--" to subcategories
  let modifiedCategories = sortedCategories?.map((category) => {
    // Step 4: If the category is a parent category, leave the label as it is.
    if (category.is_parent_category) {
      return category;
    }

    return {
      ...category,
      label: "-- " + category.category_name,
    };
  });

  // Organize categories into parent and subcategories both alphabetically
  const organizeCategories = (categories: TCategory[]) => {
    const parentCategories = categories.filter(cat => cat.is_parent_category);
    const subCategories = categories.filter(cat => !cat.is_parent_category);
  
    const organizedCategories: TCategory[] = [];
  
    parentCategories.forEach(parent => {
      organizedCategories.push(parent); // Add the parent category
      // Find and add subcategories of the current parent, sorted alphabetically
      const children = subCategories
        .filter(sub => sub.parent_category_id === parent.category_id)
        .sort((a, b) => a.category_name.localeCompare(b.category_name));
      organizedCategories.push(...children);
    });
  
    return organizedCategories;
  };

  modifiedCategories = organizeCategories(modifiedCategories || []);

  return (
    <SafeArea>
      <FlatContainer
        style={{ header: { borderBottom: 0, paddingBottom: 0 } }}
        footer={
          <Pagination
            pageIndex={
              search.page && !Array.isArray(search.page) ? +search.page : 1
            }
            pageCount={query.data?.page_count}
            handleButtonClick={(e) =>
              history.push({
                search: queryString.stringify({ ...search, page: e }),
              })
            }
          />
        }
        title={tr("Products")}
        buttons={[
          <A primary to="/inventory/new">
            <RiAddFill /> {tr("Add Part")}
          </A>,
          <OutlineButton
            onClick={() =>
              modal.setModal({
                component: <EditItemGroupModal id={"new"} />,
                label: tr("Item Group"),
              })
            }
            primary
          >
            <RiAddFill /> {tr("Add Group")}
          </OutlineButton>,
          <OutlineButton
            onClick={() =>
              modal.setModal({
                component: <CategoryModal id={"new"} />,
                label: tr("Category"),
              })
            }
            primary
          >
            <RiAddFill /> {tr("Add Category")}
          </OutlineButton>,
        ]}
      >
        {/* Switch button for showing archived */}
        <div className="[&>*]:inline-block -mt-3">
          <div>
            <Switch checked={showArchived} onChange={setShowArchived} />
          </div>
          <div className="ml-2">
            {tr("Show Archived")} 
            {
              search.tab === "parts" ? 
            tr(" Parts")
            : search.tab === "services" ?
            tr(" Services")
            : search.tab === "groups" ?
            tr(" Groups")
            : 
            tr(" Categories")
            }
          </div>
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Field
            type="input"
            isLoading={query.isLoading}
            value={
              search.search && !Array.isArray(search.search)
                ? search.search
                : ""
            }
            onChange={handleSearch}
            placeholder={tr("Search")}
            style={{ minWidth: "58%" }}
          />
          {search.tab !== "services" &&
            search.tab !== "groups" &&
            search.tab !== "categories" && (
              <Field
                type="select"
                isClearable
                value={modifiedCategories?.find(cat => cat.category_id === (search.category && +search.category))}
                onChange={handleCategorySelect}
                options={modifiedCategories?.map(category => ({
                  ...category,
                  label: `${category.is_parent_category ? '' : '-- '}${category.category_name}${!category.active ? ' (Archived)' : ''}`,
                  style: { color: !category.active ? 'red' : 'initial' }
                }))}
                style={{ minWidth: "32%" }}
                placeholder={tr("Category")}
              />
            )}
        </div>

        <Tab
          data={{
            parts: {
              count: query.data?.item_count,
              tab: tr("Parts"),
            },
            services: {
              count: query.data?.services_count,
              tab: tr("Services"),
            },
            groups: {
              count: query.data?.groups_count,
              tab: tr("Groups"),
            },
            categories: {
              count: query.data?.categories?.length,
              tab: tr("Categories"),
              content: 
                <Categories 
                  categories={query.data?.categories || []} 
                  isLoading={query.isLoading} 
                />
              ,
            },
          }}
          onClick={(e) =>
            history.push({
              search: queryString.stringify({ ...search, tab: e, page: 1 }),
            })
          }
          content={<>{populateItems}</>}
          defaultTab={
            search.tab && !Array.isArray(search.tab) ? search.tab : "parts"
          }
        />
      </FlatContainer>
    </SafeArea>
  );
}
