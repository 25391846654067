import React from "react";
import styled from "styled-components";
import axios from "axios";
import { Button } from "components/button";
import { Avatar } from "components/avatar";
import { useModalContext } from "components/modal";
import NewUserModal from "./container/newuser";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { useQuery } from "react-query";
import { RiAddLine } from "react-icons/ri";
import { OwnerTag } from "components/tag/index";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  padding: 1rem;
`;

const Title = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export default function Logslist() {
  const { t } = useTranslation();
  const tBase = "views.admin.activitylog";
  const tr = (key) => t(`${tBase}.${key}`);

  const fetchData = useQuery(
    "users",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=LOGS`
        )
        .then((res) => res.data)
  );

  const columns = {
    nameandpic: {
      label: tr("Name"),
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar showLabel user_id={row.user_id}>
            {row.full_name}
          </Avatar>
        </div>
      ),
    },
    action: {
      label: tr("Method"),
    },
    endpoint: {
      label: tr("Endpoint"),
    },
    timestamp:{
      label: tr("Log Time"),
        cell:(row)=>{
            const date = new Date(row.timestamp)
            return<p>{date.toLocaleString()}</p>}
    },
  };

  return (
    <Con>
        <Title>
         <h1>{tr("Activity Log")}</h1>
        </Title>
        
      <Table
        columns={columns}
        data={fetchData?.data}
      />
    </Con>
  );
}
