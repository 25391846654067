import axios from "axios";
import { useQuery } from "react-query";
import { IProductionApiResponse } from "types/production";

const useAppointmentProduction = (appointmentId: number) => {
	return useQuery<IProductionApiResponse | null>(
		["production", "appointment", appointmentId],
		async () =>
			await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/company/metrics/production/${appointmentId}?type=appointment`
				)
				.then((res) => res.data)
	);
};

export { useAppointmentProduction };
