import { Button } from "components/button";
import { format, subDays, addDays, isToday } from "date-fns";
import * as React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import styled from "styled-components";
import Appointments from "./container/appointments";
import Overview from "./container/overview";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {};

export default function Production(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.home.container.production.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const [date, setDate] = React.useState(new Date());

  const handleDate = (action: string) => {
    switch (action) {
      case "add":
        setDate((date) => addDays(date, 1));
        break;
      case "sub":
        setDate((date) => subDays(date, 1));
        // setDate((date) => format(subDays(new Date(date), 1), "yyyy-MM-dd"));
        break;
      default:
        throw Error("Undefined");
    }
  };
  return (
    <Con>
      <SHeader>
        <h2 style={{ minWidth: "150px" }}>
          {isToday(date) ? tr("Today") : format(date, "MMM, dd yyyy", {
            locale: locale,
          })}
        </h2>
        <Button onClick={() => handleDate("sub")}>
          <RiArrowLeftSLine />
        </Button>
        <Button onClick={() => handleDate("add")}>
          <RiArrowRightSLine />
        </Button>
      </SHeader>
      <SContent>
        <Overview date={date} />
        {/* <Appointments date={date} /> */}
      </SContent>
    </Con>
  );
}

const Con = styled.div``;

const SHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  > h2 {
    margin-right: 1rem;
  }
  > button {
    margin-right: 0.5rem;
  }
`;

const SContent = styled.div``;
