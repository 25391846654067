import { AxiosError, AxiosResponse } from "axios";
import { companyClient } from "./api";

const __customerTemplate__ = "downloadTemplate/customer";
const __productTemplate__ = "downloadTemplate/product";
const __servicesTemplate__ = "downloadTemplate/services";
const __customerUploadPath__ = "customer";
const __productUploadPath__ = "product";

type columnObj = { 
  pCol: string,
  idCol: string
  nCol: string
}

export const downloadTemplate = async (type: string) => {
  let path = null;

  switch (type) {
    case "customer":
      path = __customerTemplate__;
      break;
    case "product":
      path = __productTemplate__;
      break;
    case "services":
      path = __servicesTemplate__;
      break;
    default:
      break;
  }

  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  if (path) {
    return companyClient({
      url: `/import/${path}`,
      method: "GET",
    })
      .then(onSuccess)
      .catch(onError);
  }
};

export const uploadList = async (
  formData: FormData,
  insertDuplicates: boolean,
  type: string,
  vendor: string |null | undefined = null,
  columnObj: columnObj |null | undefined = null,
) => {
  let path = null;

  switch (type) {
    case "customer":
      path = __customerUploadPath__;
      break;
    case "product":
      path = __productUploadPath__;
      break;
    case "parts":
        path = 'parts'
        break;
    default:
      break;
  }

  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "POST",
    url: `/upload/uploadList/${path}/${insertDuplicates}?vendor=${vendor}&idColumn=${columnObj?.idCol}&nameColumn=${columnObj?.nCol}&priceColumn=${columnObj?.pCol}`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(onSuccess)
    .catch(onError);
};

export const uploadCustomers = async ( formData: FormData ) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "POST",
    url: `/import/uploadCustomers`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(onSuccess)
    .catch(onError);
};

export const uploadProducts = async ( formData: FormData ) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "POST",
    url: `/import/uploadProducts`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(onSuccess)
    .catch(onError);
};

export const uploadServices = async ( formData: FormData ) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "POST",
    url: `/import/uploadServices`,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(onSuccess)
    .catch(onError);
};
