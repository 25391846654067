import { Input, TransparentInput } from "components/input";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";

export const SCon = styled.div``;

export const SCategory = styled.div`
  > div:first-child {
    position: sticky;
    z-index: 1;
    top: ${theme.height.navigation};
    background: white;
    display: flex;
    align-items: center;

    padding: 0.5rem 0;
    > button:last-child {
      margin-left: auto;
      color: ${theme.color.main};
    }
    > h3 {
      margin: 0;
      padding: 0;
    }
  }
  > div:last-child {
    border-top: 1px solid ${theme.border.light};
    > div:nth-child(2n) {
      background: ${theme.bg.hoverblue};
    }
  }
`;

export const SItem = styled.div<{ hide?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding: 0.25rem 0;
  align-items: center;
  > div:nth-child(2n) {
    font-weight: 600;
  }
  ${(props) =>
    props.hide &&
    css`
      grid-template-columns: 1fr 5fr 1fr 1fr;
      > div:nth-child(3) {
        display: none;
      }
      > div:nth-child(5) {
        display: none;
      }
    `}
`;

export const SItemInput = styled(Input)`
  width: 100%;
`;

export const SButtonCon = styled.div<{ expandAll: number }>`
  margin: 0.5rem 0;
  > button {
    margin-right: 0.5rem;
    &:nth-child(${(props) => props.expandAll}) {
      border: 2px solid ${theme.color.main};
      color: ${theme.color.main};
    }
  }
`;

export const SButtonGroup = styled.div`
  margin-left: auto;
  > button:not(:last-child) {
    margin-right: 1rem;
  }
`;
