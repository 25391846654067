import React from "react";
import wizard from "images/onboarding/wizard.png";
import TooltippedElement from "components/TooltippedElement";
import { useOnboardingWizard } from "contexts/onboarding-wizard";
import { useTranslation } from 'react-i18next';

const OnboardingNavIcon = () => {
  const { t } = useTranslation();
  const tBase = "features.onboarding-wizard";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { iconHidden, setIconHidden } = useOnboardingWizard();

  return (
    <>
      {iconHidden && (
        <div className="
            h-10 w-10 rounded-full border-2 cursor-pointer border-secondary  
            shadow-[0_0_5px_#1e90ff] 
            hover:shadow-[0_0_10px_#1e90ff] 
            active:shadow-[0_0_15px_#1e90ff]
          "
          onClick={() => setIconHidden(false)}
        >
          <div>
            <TooltippedElement
              message={tr("Onboarding Wizard")}
              element={
                <div className="rounded-full ">
                  <img src={wizard} alt="" className="rounded-full" />
                </div>
                }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default OnboardingNavIcon