import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarContextProvider } from "components/snackbar";
import { ModalProvider } from "components/modal";
import { AuthContextProvider } from "./auth";
import { CartCountProvider } from "components/cart";
import { VehicleCheckoutProvider } from "components/vehicleCheckout";
import Routes from "../routes";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { TourProvider } from "./tour";
import { OnboardingWizardProvider } from "./onboarding-wizard";

const queryClient = new QueryClient({
  onError: (errors) => {
    console.error("WHAADDAAPp");
  },
});

export default function Context({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider options={HTML5toTouch}>
        <AuthContextProvider>
          <TourProvider>
            <OnboardingWizardProvider>
              <SnackbarContextProvider>
                <ModalProvider>
                  <CartCountProvider>
                    <VehicleCheckoutProvider>
                        <Routes />
                    </VehicleCheckoutProvider>
                  </CartCountProvider>
                </ModalProvider>
              </SnackbarContextProvider>
            </OnboardingWizardProvider>
          </TourProvider>
        </AuthContextProvider>
      </DndProvider>
    </QueryClientProvider>
  );
}
