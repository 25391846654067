import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  style?: {
    overlay?: {};
    content?: {};
  };
  classNames?: {
    overlay?: string;
    content?: string;
  };
  children: React.ReactNode;
  border?: boolean;
};

export default function SafeArea(props: Props) {
  const { style, children, classNames, border, ...rest } = props;
  return (
    <SOverlay
      style={props.style?.overlay}
      className={`${border && "border-t border-b"} ${classNames?.overlay}`}
    >
      <SCon
        style={props.style?.content}
        {...rest}
        className={`${classNames?.content}`}
      >
        {props.children}
      </SCon>
    </SOverlay>
  );
}

const SOverlay = styled.div`
  width: calc(100vw-1rem);
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  // background: ${theme.bg.lightblue};
`;

const SCon = styled.div`
  width: 100%;
  max-width: 1300px;
  // min-height: calc(100vh - ${theme.height.navigation});
`;
