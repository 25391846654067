import * as React from "react";
import styled from "styled-components";
import type { Details } from "./index";
import { format } from "date-fns";
import { TEstimate } from "types/estimate";
import DataLabel from "components/dataLabel";
import { NoStyleA } from "components/button";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";
import i18n from "i18n";

type Props = {
  details: TEstimate;
};

export const dateFormatter = (date: string | null) => {
  const locale = i18n.language === "es" ? es : enUS;

  if (!date) {
    return "-";
  }
  return format(new Date(date), "MMM dd, yyyy, hh:mm aa", {
    locale: locale,
  });
};

export const Info = (props: Props) => {
  const { t } = useTranslation();
  const tBase = "views.estimate.info";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  return (
    <div className="md:grid-cols-2 grid gap-2 space-y-2">
      <DataLabel title={tr("Description")}>{props.details.description}</DataLabel>
      <DataLabel title={tr("Request ID")}>
        {props.details.fk_request_parent_id ? (
          <NoStyleA
            className="!text-blue-700 !underline"
            to={`/requests/${props.details.fk_request_parent_id}`}
          >{`${tr("Request")} #${props.details.fk_request_parent_id}`}</NoStyleA>
        ) : (
          <span className="text-slate-400 font-medium">{tr("Unassigned")}</span>
        )}
      </DataLabel>
      <DataLabel title={tr("Project ID")}>
        {props.details.fk_project_id ? (
          <NoStyleA
            className="!text-blue-700 !underline"
            to={`/projects/${props.details.fk_project_id}`}
          >{`${tr("Project")} #${props.details.fk_project_id}`}</NoStyleA>
        ) : (
          <span className="text-slate-400 font-medium">{tr("Unassigned")}</span>
        )}
      </DataLabel>
      <DataLabel title={tr("Created")}>
        {dateFormatter(props.details.entry_date)}
      </DataLabel>
      <DataLabel title={tr("Checklists")}>
        {props.details.checklist_response ? (
          props.details.checklist_response.map((res) => (
            <div key={res.id}>
              <p className="text-slate-500 font-thin">
                {res.label}
                <span className="text-black font-bold">
                  {res.status ? " Yes" : " No"}
                </span>
              </p>
            </div>
          ))
        ) : (
          <span className="text-slate-500">{tr("No Checklist")}</span>
        )}
      </DataLabel>
      <DataLabel title={tr("Opened")}>
        {props.details.read_timestamp ? (
          dateFormatter(props.details.read_timestamp)
        ) : (
          <span className="text-red-600">{tr("Pending")}...</span>
        )}
      </DataLabel>
      {/* <div>
        <div>
          <span>Description</span>
        </div>
        <div></div>
      </div>
      <div>
        <div>
          <span>Created</span>
        </div>
        <div>{dateFormatter(props.details.entry_date)}</div>
      </div> */}
    </div>
  );
};

const Con = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;
  > div {
    display: flex;
    font-size: 0.9rem;
    > div:first-child {
      flex: 0 0 20%;
      color: grey;
    }
    > div:last-child {
      flex: 0 0 80%;
    }
  }
`;
