import axios from "api/axios";
import { Button, OutlineButton } from "components/button";
import { RiCloseCircleLine } from "react-icons/ri";
import FlatContainer from "components/container/flat";
import Field from "components/field";
import { useModalContext } from "components/modal";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import LineItemsContainer from "containers/lineItems";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { TItemGroup } from "types/item_group";
import { TTargetProduct } from "types/product";
import EditItemGroupModal from "./edit";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

type Props = {
  data?: TItemGroup;
};

const Typescript = (props: {costs: string[]}) => {
  return <p>Reply {props.costs.map((cost) => `${cost}`)}</p>
}

export default function ItemGroup(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.item_group.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { id, type } = useParams<{ id: string; type?: string }>(),
    modal = useModalContext();
  
  const [archiver, setArchiver] = React.useState< {archived_by:string, archived_at:Date} | null >(null)

  React.useEffect(() => {
    <Typescript costs={["everything"]} />
    getArchiver()
  }, [])

  let { name } = useAuthContext()

  const Archive = async(toggle: boolean) => {
    await 
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/archive/${id}?type=${type}&archive=${toggle}&name=${name}`)
        .then(() => query.refetch())
  }

  const getArchiver = async() => {
    await 
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ARCHIVER&item_id=${id}&item_type=${type}`)
        .then((res) => setArchiver(res.data[0]))
  }

  const query = useQuery<TItemGroup>(
    ["item_groups", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/item_groups/${id}
  `
        )
        .then((res) => res.data)
  );

  if (query.isLoading) return <Spinner />;

  return (
    <SafeArea>
      <FlatContainer
        title={
          <div className="flex items-center space-x-4">
            {query.data?.imageUrl && (
              <img
                className="object-contain w-[60px] h-[60px] border"
                src={query.data.imageUrl}
              />
            )}
            <h2>{query.data?.label}</h2>
            {query.data?.is_voided &&
              <div>
                <div className="bg-red-600 text-white p-1 mx-2 font-bold text-sm rounded uppercase" style={{display:"inline-block"}}>
                  {tr("Archived")}
                </div>
                <div style={{display:"inline-block", color:"red", fontStyle:"italic"}}>
                {/* Note: existing archived items will not have this shown */}
                {archiver?.archived_by && `${tr("Archived By")}: ` + archiver.archived_by + tr(" on ") + new Date(archiver.archived_at).toLocaleString('default', {weekday: 'long', month: 'short', day: 'numeric', year:'numeric'} )}
                </div>
              </div>
            }
          </div>
        }
        buttons={[
          <>
          {id !== "new" && !query.data?.is_voided &&
          <OutlineButton
            className="!border-red-600 !text-red-600"
            onClick={() => Archive(true)} 
          >
            <RiCloseCircleLine /> {tr("Archive")}
          </OutlineButton>
          }
          {id !== "new" && query.data?.is_voided && (
            <OutlineButton
              className="!border-green-600 !text-green-600"
              onClick={() => Archive(false)} 
            >
              <RiCloseCircleLine /> {tr("Unarchive")}
            </OutlineButton>
          )}
          <Button
            onClick={() =>
              modal.setModal({
                component: <EditItemGroupModal id={id} />,
                label: tr("Item Group"),
              })
            }
          >
            {tr("Edit")}
          </Button>
          </>
        ]}
      >
        <LineItemsContainer id={id} type="group" />
      </FlatContainer>
    </SafeArea>
  );
}
