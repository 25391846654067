import * as React from "react";
import { OutlineButton } from "components/button";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { dollarFormatter } from "utils";
import { format } from "date-fns";
import { Avatar } from "components/avatar";
import { Status } from "views/request/component/status";
import Segment, { SContent, SHeader } from "components/segment";
import { RiAddFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  id: string;
  editable: boolean;
};

type Status = {
  color: string;
  label: string;
  id: string;
};

type Quote = {
  id: string;
  label: string;
  total_amount: number;
  created_on: string;
  name: string;
  status: Status;
};
export default function Quote(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.projects.views.project.views.components.quote-component";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const history = useHistory();

  const quotes = useQuery<Quote[]>(
    ["quotes", props.id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/project
  `
        )
        .then((res) => res.data)
  );

  const addQuote = useMutation(
    async () =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}
    `
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        history.push(`/projects/${props.id}/quote/${id}`, {
          focusOnLabel: true,
        });
      },
    }
  );

  const columns = {
    id: {
      label: tr("Quote #"),
    },
    total_amount: {
      label: tr("Bid Price"),
      style: {
        fontWeight: "bold",
      },
      cell: (row: Quote) => dollarFormatter(row.total_amount),
    },
    status: {
      label: tr("Status"),
      cell: (row: Quote) => (
        <Status color={row.status.color}>{row.status.label}</Status>
      ),
    },
    label: {
      label: tr("Description"),
      width: "50%",
    },
    name: {
      label: tr("Created"),
      cell: (row: Quote) => (
        <Avatar size="xsmall" showLabel>
          {row.name}
        </Avatar>
      ),
      width: "50%",
    },
    created_on: {
      label: tr("Created On"),
      cell: (row: Quote) =>
        format(new Date(row.created_on), "MMM dd, yyyy hh:mm aa", {
          locale: locale
        }),
    },
  };

  const handleClick = (row: Quote) => {
    history.push(`/projects/${props.id}/quote/${row.id}`);
  };

  return (
    <div className="divide-y max-w-full">
      <div className="py-4 flex justify-between items-center">
        <h2>{tr("Quote Generator")}</h2>
        {props.editable && (
          <OutlineButton onClick={() => addQuote.mutate()}>
            <RiAddFill /> {tr("New Quote")}
          </OutlineButton>
        )}
      </div>
      <div className="py-4 max-w-full">
        {quotes.data && quotes.data.length > 0 && (
          <Table
            columns={columns}
            data={quotes.data}
            handleClick={handleClick}
          />
        )}
      </div>
    </div>
  );
}
