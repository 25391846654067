import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, OutlineButton, SubtleButton } from "components/button";
import Field from "components/field";
import MultiInput from "components/input/multi";
import { useModalContext } from "components/modal";
import Assignee from "components/react-select/assignees";
import { useSnackbarContext } from "components/snackbar";
import CustomDocModal from "containers/customDocModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TooltippedElement from "components/TooltippedElement";
import { FaInfoCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

type Props = {
  emails: string[] | null;
  estimateId: string;
};

export default function SendEstimateModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.estimate.sendEstimateModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const snackbar = useSnackbarContext(),
    queryClient = useQueryClient(),
    modal = useModalContext();

  const [templateId, setTemplateId] = useState<string>();
  const [emails, setEmails] = useState<(string | null)[] | null>();
  const [customText, setCustomText] = useState<string>("");
  const [manager, setManager] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [noColor, setNoColor] = useState<boolean>(false);

  const getManagerQuery = useQuery<{ value: string; label: string } | null>(
    ["manager", "estimate", props.estimateId],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${props.estimateId}/manager
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        setManager(data);
      },
    }
  );

  const mutation = useMutation(
    async () =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${props.estimateId}/send
  `,
        { 
          templateId, 
          emails, 
          customText, 
          fk_manager_id: manager?.value,
          noColor
        }
      ),
    {
      onSuccess: () => {
        snackbar.showSnackbar(tr("Estimate sent!"));
        queryClient.invalidateQueries();
        modal.setModal();
      },
      onError: () => {
        snackbar.showSnackbar(tr("Error sending estimate"));
      },
    }
  );

  const preview = useMutation(
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/estimates/${props.estimateId}/preview`,
          {
            params: {
              templateId,
              customText,
              fk_manager_id: manager?.value,
              noColor
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onError: () => {
        snackbar.showSnackbar(tr("Error previewing estimate"));
      },
    }
  );

  useEffect(() => {
    if (props.emails) {
      setEmails(props.emails);
    }
  }, [props.emails]);
  return (
    <div className="max-w-[500px] space-y-4">
      <MultiInput values={emails} onChange={(e) => setEmails(e)} />
      <CustomDocModal
        id={templateId}
        type="estimate"
        onClick={(id) => setTemplateId((tId) => (tId === id ? undefined : id))}
      />
      <Field
        label={tr("Custom Text")}
        type="textarea"
        minRows={4}
        value={customText}
        onChange={(e) => setCustomText(e.target.value)}
      />
      <Assignee
        label={tr("Manager")}
        value={manager}
        isMulti={false}
        menuPlacement="top"
        onChange={(e: any) => setManager(e)}
      />
      <div className="flex items-center [&>*]:-mt-1.5 [&>*]:-mb-2.5">
        <input 
          type="checkbox" 
          checked={noColor} 
          onChange={() => setNoColor(!noColor)} 
        />
        <label className="ml-2">{tr("Black & White Print")}</label>
        <TooltippedElement 
          message="Turn Off Color for Printing PDF"
          placement="top"
          element={
            <FaInfoCircle className="ml-1 text-secondary" />
          }
        />
      </div>
      <div className="flex justify-end space-x-2">
        <SubtleButton
          isLoading={preview.isLoading}
          disabled={!templateId || !manager || preview.isLoading}
          primary
          onClick={() => preview.mutate()}
        >
          {tr("Preview")} 
        </SubtleButton>
        <Button
          primary
          type="submit"
          isLoading={mutation.isLoading}
          disabled={emails?.length === 0 || !templateId || !manager}
          onClick={() => mutation.mutate()}
        >
          {tr("Send Estimate")}
        </Button>
        <OutlineButton onClick={() => modal.setModal()}>{tr("Cancel")}</OutlineButton>
      </div>
    </div>
  );
}
