import React, { useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import Axios from "axios";
import { format } from "date-fns";
import { Input } from "components/input";
import Placeholder from "../component/placeholder";
import {
  RiCloseLine,
  RiPencilLine,
  RiPushpinFill,
  RiPushpinLine,
} from "react-icons/ri";
import { SubtleIconButton } from "components/button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbarContext } from "components/snackbar";
import { useAuthContext } from "contexts/auth";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';

export default function RequestNotes({ id, type }) {
  const { t } = useTranslation();
  const tBase = "views.request.container.notes";
  const tr = (key) => t(`${tBase}.${key}`);

  const [value, setValue] = React.useState("");
  const { showSnackbar } = useSnackbarContext(),
    queryClient = useQueryClient();

  const notes = useQuery(
    [type, id, "notes"],
    async () =>
      await Axios.get(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/notes/${type}/${id}
  `
      ).then((res) => res.data)
  );

  const addNote = useMutation(
    async (body) =>
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/notes/${type}/${id}`,
        body
      ),
    {
      onSuccess: () => {
        setValue("");
        queryClient.invalidateQueries();
        showSnackbar(tr("Note added!"));
      },
    }
  );

  const deleteNote = useMutation(
    async (noteId) => {
      return await Axios.post(`
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/notes/${noteId}/delete
      `);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        showSnackbar(tr("Note deleted!"));
      },
    }
  );

  const updateNote = async (noteId, newValue) => {
    const response = await Axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/notes/${noteId}`,
      { value: newValue }
    );

    if (response.status === 200) {
      queryClient.invalidateQueries();
      showSnackbar(tr("Note updated!"));
    }
    return response;
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      addNote.mutate({ value: e.target.value });
    }
  };

  const populateNotes = notes.data?.map((note) => {
    return (
      <Note
        key={note.id}
        type={type}
        {...note}
        handleDelete={() => deleteNote.mutate(note.id)}
        updateNote={updateNote}
      />
    );
  });

  return (
    <div className="space-y-4">
      <div className="py-4 border-b">
        <h2>{tr("Notes")}</h2>
      </div>
      <div>
        <Input
          value={value}
          placeholder={tr("Insert new notes..")}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleSubmit}
        />
        {notes.data?.length > 0 ? (
          <NotesCon className="py-2 space-y-2">{populateNotes}</NotesCon>
        ) : (
          <Placeholder
            type="note"
            header={tr("No notes")}
            label={tr("Notes added will appear here.")}
          />
        )}
      </div>
    </div>
  );
}

const NotesCon = styled.div``;
const EmptyPlaceholder = styled.div`
  color: grey;
  margin-top: 1rem;
  font-weight: bold;
`;

const TextArea = styled(TextareaAutosize)`
  resize: none;
  padding: 1rem 0 0.5rem 0;
`;

const Note = ({
  type,
  id,
  details,
  entry_timestamp,
  name,
  handleDelete,
  fk_user_id,
  updateNote,
  pinned,
}) => {
  const { t } = useTranslation();
  const tBase = "views.request.container.notes";
  const tr = (key) => t(`${tBase}.${key}`);

  const { user_id: userId } = useAuthContext(),
    queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(details);
  // const [isPinned, setIsPinned] = useState(false);

  // React.useEffect(() => {
  //   setIsPinned(pinned);
  // }, [pinned]);

  const pinNote = useMutation(
    async (body) =>
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/notes/${type}/${id}/pin`,
        body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  const handleUpdateNote = async (newValue) => {
    const result = await updateNote(id, newValue);
    if (result.status === 200) {
      setEditing(false);
    }
  };

  return (
    <div className={`${pinned ? "bg-purple-100" : ""} p-2 rounded`}>
      {editing ? (
        <Input
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setEditValue(details);
              setEditing(false);
            } else if (e.key === "Enter") {
              handleUpdateNote(editValue);
            }
          }}
          onBlur={() => {
            // setEditValue(details);
            // setEditing(false);
          }}
        />
      ) : (
        <div className="flex items-center justify-between">
          <div className="flex flex-col flex-wrap flex-initial">
            <Label>{details}</Label>
            <EntryDate>
              {name}
              {" · "}
              {format(new Date(entry_timestamp), "MM/dd/yy · hh:mm aa")}
            </EntryDate>
          </div>
          <div className="flex flex-row items-center justify-end">
            <TooltippedElement
              message={pinned ? tr("Unpin") : tr("Pin")}
              element={
                <SubtleIconButton
                  size="small"
                  onClick={() => {
                    pinNote.mutate({ pinned: !pinned });
                  }}
                >
                  {pinned ? (
                    <RiPushpinFill className="text-purple-900" />
                  ) : (
                    <RiPushpinLine />
                  )}
                </SubtleIconButton>
              }
            />
            {userId === fk_user_id && (
              <>
                <TooltippedElement
                  message={tr("Edit")}
                  element={
                    <SubtleIconButton
                      size="small"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      <RiPencilLine />
                    </SubtleIconButton>
                  }
                />

                <SubtleIconButton
                  className="!text-red-600"
                  size="small"
                  onClick={handleDelete}
                >
                  <RiCloseLine />
                </SubtleIconButton>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const Label = styled.div`
  font-weight: 600;
`;

const EntryDate = styled.div`
  font-size: 0.8rem;
  color: #283747;
`;
