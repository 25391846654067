 import React, { useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { A, Button } from "components/button";
import { Input, TextArea } from "components/input";

import Table from "components/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useModalContext } from "components/modal";
import { RiAddLine } from "react-icons/ri";
import Switch from "rc-switch";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  padding: 1rem;
`;
const Rowcell = styled.div`
  margin-top: 5px;
  padding: 4px;
  display: block;
  text-align: center;
`;
const Title = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;

export default function ServicePlans() {
  const { t } = useTranslation();
  const tBase = "views.service_plans.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const plansData = useQuery(
    "plans",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=PLANS`
        )
        .then((res) => res.data)
  );
  const updatePlans = useMutation((planinfo) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/plans`,
      {
        type: "EDITPLANS",
        data: planinfo,
      }
    );
  });
  const DeletePlan = useMutation((planinfo) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/plans`,
      {
        type: "REMOVEPLAN",
        data: planinfo,
      }
    );
    queryClient.invalidateQueries();
  });
  const ArchivePlan = useMutation((planinfo) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/plans`,
      {
        type: "ARCHIVEPLAN",
        data: planinfo,
      }
    );
    queryClient.invalidateQueries();
  });
  const displaydata = plansData?.data;
  const applistinDB = useQuery(
    "applist",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=PLANSAPPLIST`
        )
        .then((res) => res.data)
  );
  const planswithcustomers = useQuery(
    "cusplans",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=CUSPLANSLIST`
        )
        .then((res) =>
          res.data.map((a) => {
            return +a.service_planid;
          })
        )
  );

  const appointmentSummary = (row) => {
    let plan;

    plansData?.data.forEach((x) => {
      if (x.id === row.id) {
        plan = x;
      }
    });
    if (plan.archived === false) {
      let appsum = plan.appointment_types.map((i) => {
        return " " + i.label + "(" + i.service_plans_toapptypes.quantity + ")";
      });
      return appsum.toString();
    } else {
      return "Archived";
    }
  };
  const nextid = () => {
    let highestID = 0;
    displaydata.forEach((a) => {
      if (+a.id >= highestID) {
        highestID = +a.id;
      }
    });
    const newid = highestID + 1;
    return newid;
  };

  const Addandeditplans = (props) => {
    const Plan_ID = props.Plan_ID;
    const [Plan_info, setplan_info] = React.useState(() => {
      if (Plan_ID !== "new") {
        let data;
        displaydata.forEach((x) => {
          if (x.id === Plan_ID) {
            data = x;
          }
        });

        return {
          id: +data.id,
          archived: data.archived,
          is_contract: data.is_contract,
          terms_and_conditions: data.terms_and_conditions,
          auto_renew: data.auto_renew,
          description: data.description,
          Pname: data.name,
          Duration: data.duration,
          ActiveApps: {
            labels: data.appointment_types.map((i) => {
              return i.label;
            }),
            quantities: applistinDB.data.map((i) => {
              let labels = data.appointment_types.map((i) => {
                return i.label;
              });
              if (labels.includes(i.label)) {
                return data.appointment_types[
                  labels.findIndex((element) => {
                    if (element === i.label) {
                      return true;
                    }
                    return false;
                  })
                ].service_plans_toapptypes.quantity;
              } else {
                return 0;
              }
            }),
            ids: data.appointment_types.map((i) => {
              return {
                id: +i.id,
                index: applistinDB.data
                  .map((x) => {
                    return x.id;
                  })
                  .findIndex((a) => {
                    if (a === i.id) {
                      return true;
                    }
                    return false;
                  }),
              };
            }),
          },
        };
      } else {
        return {
          id: nextid(),
          Pname: null,
          is_contract: false,
          terms_and_conditions: "",
          auto_renew: false,
          description: "",
          archived: false,
          Duration: null,
          new: true,
          ActiveApps: {
            labels: [],
            quantities: applistinDB.data.map(() => {
              return 0;
            }),
            ids: [],
          },
        };
      }
    });
    const hiddencheck = () => {
      if (
        Plan_info.archived !== true ||
        Plan_info.id >> Object.keys(displaydata).length ||
        planswithcustomers.data.includes(Plan_info.id) === true
      ) {
        return "none";
      } else {
        return "inline";
      }
    };
    const  inputChange = (event) => {
      // console.log("updated"+event.target.name+"to"+event.target.value)
      setplan_info({ ...Plan_info, [event.target.name]: event.target.value });
    };
    const switchChange = (label, index, id) => {
      if (Plan_info.ActiveApps.labels.includes(label)) {
        const Lindex = Plan_info.ActiveApps.labels.findIndex((element) => {
          if (element === label) {
            return true;
          }
          return false;
        });
        setplan_info((prev) => ({
          ...prev,
          ActiveApps: {
            ...prev.ActiveApps,
            labels: [
              ...prev.ActiveApps.labels.slice(0, Lindex),
              ...prev.ActiveApps.labels.slice(Lindex + 1),
            ],
            quantities: [
              ...prev.ActiveApps.quantities.slice(0, index),
              0,
              ...prev.ActiveApps.quantities.slice(index + 1),
            ],
            ids: [
              ...prev.ActiveApps.ids.slice(0, Lindex),
              ...prev.ActiveApps.ids.slice(Lindex + 1),
            ],
          },
        }));
      } else {
        setplan_info((prev) => ({
          ...prev,
          ActiveApps: {
            ...prev.ActiveApps,
            labels: [...prev.ActiveApps.labels, label],
            quantities: [
              ...prev.ActiveApps.quantities.slice(0, index),
              1,
              ...prev.ActiveApps.quantities.slice(index + 1),
            ],
            ids: [...prev.ActiveApps.ids, { id: id, index: index }],
          },
        }));
      }
    };
    const quantityChange = (event, index) => {
      if (event.target != null) {
        if (event.target.value >= 1 || event.target.value === "0") {
          setplan_info((prev) => ({
            ...prev,
            ActiveApps: {
              ...prev.ActiveApps,
              labels: [...prev.ActiveApps.labels],
              quantities: [
                ...prev.ActiveApps.quantities.slice(0, index),
                +event.target.value,
                ...prev.ActiveApps.quantities.slice(index + 1),
              ],
            },
          }));
        }
      }
      event.persist();
    };
    useEffect(() => {
      // console.log(Plan_info.ActiveApps)
    }, [Plan_info]);
    const AppointmentList = () => {
      return applistinDB.data.map((app, appindex) => {
        return (
          <Row
            key={app.id}
            style={{
              background: "#ebedef",
              padding: "3px",
              textAlign: "center",
              borderRadius: "60px",
            }}
          >
            <Rowcell
              style={{
                textAlign: "left",
              }}
            >
              {app.label}
            </Rowcell>
            <Input
              name="quantity"
              onChange={(e) => {
                quantityChange(e, appindex);
              }}
              defaultValue={Plan_info.ActiveApps.quantities[appindex]}
              maxLength={2}
              disabled={
                Plan_info.ActiveApps.quantities[appindex] >= 1 === false
              }
            />
            <Rowcell
              style={{
                textAlign: "right",
              }}
            >
              <Switch
                defaultChecked={Plan_info.ActiveApps.quantities[appindex] >= 1}
                onChange={() => switchChange(app.label, appindex, +app.id)}
              ></Switch>
            </Rowcell>
          </Row>
        );
      });
    };
    const archivedBGstyle = () => {
      if (Plan_info.archived) {
        return {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "800px",
          padding: "5px",
          borderTop: "solid",
          borderColor: "#999999",
          borderRadius: "10px",
        };
      } else {
        return {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "800px",
          background: "#ffffff",
        };
      }
    };
    // console.log(Plan_info.id, planswithcustomers.data, planswithcustomers.data.includes(Plan_info.id))
    const Archivelabel = () => {
      if (Plan_info.archived) {
        return <p>{tr("Unarchive Plan")}</p>;
      } else {
        return <p>{tr("Archive Plan")}</p>;
      }
    };

    return (
      <div style={archivedBGstyle()}>
        <div hidden={Plan_info.archived !== true}>
          {tr("This Plan Is Archived")}
          <div hidden={hiddencheck() === "inline"}>
            {tr("You must remove this plan from all customers before you may delete it.")}
          </div>
          <Button
            onClick={async () => {
              setModal({
                component: (
                  <>
                    <Button
                      onClick={async (event) => {
                        event.preventDefault();
                        DeletePlan.mutate(Plan_info);
                        setModal();
                      }}
                    >
                      {tr("Delete")}
                    </Button>
                    <Button
                      onClick={async (event) => {
                        setModal();
                      }}
                    >
                      {tr("Cancel")}
                    </Button>
                  </>
                ),
                label: tr("Are you sure you want to delete this plan?"),
                props: { shouldCloseOnOverlayClick: false },
              });
            }}
            style={{
              marginRight: "auto",
              color: "#ff0000",
              display: hiddencheck(),
            }}
          >
            {tr("Delete Plan")}
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{width:'48%',float:'left',margin:'1%'}}>
          <Input
            name="Pname"
            label={tr("Plan Name")}
            required
            placeholder={tr("Plan Name")}
            onChange={inputChange}
            defaultValue={Plan_info.Pname}
          />
          <Input
            name="Duration"
            label={tr("Duration of plan (months)")}
            placeholder={tr("Duration of plan (months)")}
            required
            onChange={inputChange}
            defaultValue={Plan_info.Duration}
          />
          <Row style={{marginTop:'5px'}}>
          <p style={{display:'inline'}}>{tr("Should this plan be a contract?")}</p>
          <Switch
          style={{display:'inline'}}
          name="is_contract"
          checked={Plan_info?.is_contract}
          onChange={(e) => {
            setplan_info((prev) => ({
              ...prev,
              is_contract: e,
            }));
          }}
          ></Switch>
          </Row>
          <Row style={{marginTop:'5px'}}>
          <p style={{display:'inline'}} >{tr("Should this plan auto-renew?")}</p>
          <Switch
          style={{display:'inline'}}
          name="auto_renew"
          checked={Plan_info?.auto_renew}
          onChange={(e) => {
            setplan_info((prev) => ({
              ...prev,
              auto_renew: e,
            }));
          }}
          ></Switch>
          </Row>
          </div>
          <div style={{width:'48%',float:'right',margin:'1%'}}>
          <TextArea
            
            name="description"
            label={tr("Description")}
            style={{width:'100%'}}
            placeholder={tr("Description")}
            onChange={inputChange}
            defaultValue={Plan_info.description}
          />
          <TextArea
            name="terms_and_conditions"
            label={tr("Terms and Conditions")}
            placeholder={tr("Terms and Conditions")}
            required={Plan_info.is_contract}
            style={{height:'8rem',width:'100%'}}
            onChange={inputChange}
            defaultValue={Plan_info.terms_and_conditions}
          />
          
        </div>
        </div>
        <Row
          style={{
            borderBottom: "solid",
          }}
        >
          <Rowcell
            style={{
              textAlign: "left",
            }}
          >
            {tr("Appointment Type")}
          </Rowcell>
          <Rowcell>{tr("Quantity")}</Rowcell>
          <Rowcell
            style={{
              textAlign: "right",
            }}
          >
            {tr("Active")}
          </Rowcell>
        </Row>
        <AppointmentList></AppointmentList>
        <Row>
          <Button
            onClick={async (event) => {
              event.preventDefault();
              let newval;
              if (Plan_info.archived === true) {
                newval = false;
              } else {
                newval = true;
              }
              setplan_info((prev) => ({ ...prev, archived: newval }));
              ArchivePlan.mutate({ id: Plan_info.id, archived: newval });
            }}
            disabled={Plan_info.id >> Object.keys(displaydata).length}
            style={{ marginRight: "auto", color: "#ff0000", display: "inline" }}
          >
            <Archivelabel></Archivelabel>
          </Button>
          <Button
            onClick={async (event) => {
              event.preventDefault();
              updatePlans.mutate(Plan_info, {
                onSettled: () => {
                  setModal();
                  queryClient.invalidateQueries();
                },
              });
            }}
            primary
            style={{ marginLeft: "auto", display: "inline" }}
            disabled={
              Plan_info.Duration === null ||
              Plan_info.Pname === null ||
              isNaN(+Plan_info.Duration)
            }
          >
            {tr("Save Changes")}
          </Button>
        </Row>
      </div>
    );
  };
  const columns = {
    id: {
      label: tr("Plan Number"),
      isClickable: false,
    },
    name: {
      label: tr("Name"),
      isClickable: false,
    },
    description: {
      label: tr("Description"),
      isClickable: false,
    },
    duration: {
      label: tr("Duration (months)"),
      isClickable: false,
    },
    appointments: {
      disabled: true,
      label: tr("active appointments"),
      isClickable: false,
      cell: (row) => (
        <div
          style={{ fontStyle: "italic", display: "flex", alignItems: "center" }}
        >
          {appointmentSummary(row)}
        </div>
      ),
    },
    edit: {
      label: "",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            primary
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setModal({
                component: <Addandeditplans Plan_ID={row.id}></Addandeditplans>,
                label: tr("Edit Plan"),
                props: { shouldCloseOnOverlayClick: true },
              });
            }}
          >
            {tr("Edit Plan")}
          </Button>
        </div>
      ),
    },
  };

  return (
    <Con
      onFocus={() => {
        queryClient.invalidateQueries();
      }}
      onBlur={() => {
        queryClient.invalidateQueries();
      }}
    >
      <Title>
        <h1>{tr("Service Plans")}</h1>
        <Button
          primary
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setModal({
              component: <Addandeditplans Plan_ID={"new"}></Addandeditplans>,
              label: tr("Add plan"),
              props: {
                shouldCloseOnOverlayClick: true,
                onAfterClose: () => {
                  queryClient.invalidateQueries();
                },
              },
            });
          }}
        >
          <RiAddLine /> {tr("Add New Plan")}
        </Button>
      </Title>

      <Table
        handleClick={(row) => {
          setModal({
            component: <Addandeditplans Plan_ID={row.id}></Addandeditplans>,
            label: tr("Edit plan ") + row.id,
            props: {
              shouldCloseOnOverlayClick: true,
              onAfterClose: () => {
                queryClient.invalidateQueries();
              },
            },
          });
        }}
        columns={columns}
        data={plansData?.data}
      />
    </Con>
  );
}
