import { Button } from "components/button";
import { EDIT } from "../../../../../../constants";
import { EQUIPMENT } from "features/equipment/constants";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {
  vehicle_id: number;
  vehicle_label: string;
  handleOpen: (itemType: string, modalType: string, id?: number) => void;
};

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 5px;
  &:hover {
    background-color: rgba(100, 188, 255, 0.4);
  }
`;

const LeftGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  margin-left: 10px;
`;

const RightGroup = styled.div``;

const EditButton = styled(Button)`
  margin-right: 10px;
  cursor: pointer;
`;

const EquipmentItem = ({ vehicle_id, vehicle_label, handleOpen }: Props) => {
  const { t } = useTranslation();
  const tBase = "features.equipment";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <Item>
      <LeftGroup>
        {/* <Checkbox /> */}
        <Label>{<Label>{vehicle_label}</Label>}</Label>
      </LeftGroup>
      <RightGroup>
        <EditButton
          size="small"
          onClick={() => handleOpen(EQUIPMENT, EDIT, vehicle_id)}
        >
          {tr("Edit")}
        </EditButton>
      </RightGroup>
    </Item>
  );
};

export default EquipmentItem;
