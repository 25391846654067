import styled from "styled-components";
import { theme } from "styles/theme";

export const MenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.border.light};
  }
`;
