import Cell from "./Cell";
import * as React from "react";
import { useDrop } from "react-dnd";
import { theme } from "styles/theme";

type Props = {
  newDate: string;
  children: React.ReactNode;
  appointmentMoved: any;
  className?: any;
};

export default function DroppableCell({
  newDate,
  children,
  appointmentMoved,
  className,
}: Props) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "BOX",
      drop: ({ id, date: prevDate }: any) => {
        appointmentMoved.mutate({
          prevDate,
          date: newDate,
          id,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [newDate]
  );

  return (
    <Cell
      ref={drop}
      style={{ background: isOver ? theme.bg.blue : "transparent" }}
      className={className}
    >
      {children}
    </Cell>
  );
}
