import { companyClient } from "api/api";
import { ITags } from "../components/interfaces/ITags";

export const updateTags = async (
  updatedTags: ITags
) => {
  return companyClient({
    method: "PUT",
    url: "/tags/updateTag",
    data: updatedTags,
  });
};
