import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "styles/theme";

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  font-weight: ${(props) => (props.selected ? "bold" : "500")};
  transition: all linear 0.1s;
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => theme.fontSize[props.size]};
  &:hover {
    background: #ebedef;
  }
  > svg {
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
  background: ${(props) => (props.selected ? "#EBEDEF" : "transparent")};
`;

export const StyledMenuLink = styled(StyledLink)``;

export const StyledUnderlinedLink = styled(StyledLink)`
  text-decoration: underline;
`;

export const StyledSubtleLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.main};
  &:hover {
    color: black;
  }
`;

export const SBreadCrumbs = styled(Link)`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: ${theme.color.subtext2};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
