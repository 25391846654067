import { SubtleIconButton } from "components/button";
import { useModalContext } from "components/modal";
import { differenceInDays, format } from "date-fns";
import * as React from "react";
import { RiPencilLine } from "react-icons/ri";
import { TWarrantyTargetItem } from "types/warranty";
import CountdownRing from "./countdownRing";
import EditWarrantyModal from "./editWarrantyLineModal";
import { useTranslation } from 'react-i18next';

type Props = {
  item: TWarrantyTargetItem;
};

export default function WarrantyLine({ item }: Props) {
  const { t } = useTranslation();
  const tBase = "containers.warrantyTab.warrantyLine";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext();

  const diffDay = item.start_date
    ? Math.max(
        0,
        differenceInDays(new Date(item.expiry_date!), new Date(item.start_date))
      )
    : null;

  const currDay =
    item.expiry_date &&
    differenceInDays(new Date(item.start_date!), new Date()) <= 0
      ? Math.max(0, differenceInDays(new Date(item.expiry_date), new Date()))
      : null;

  const color =
    currDay === 0
      ? "slate"
      : !currDay
      ? "blue"
      : currDay <= 30
      ? "red"
      : currDay <= 180
      ? "amber"
      : "green";

  return (
    <div className="flex items-center space-x-4">
      <CountdownRing {...{ color, item, diffDay, currDay }} />
      <div className="flex flex-col flex-1">
        <span className="flex items-center space-x-1 text-xs">
          <span className={`text-${color}-600 `}>
            {currDay === 0 ? (
              <span className="text-red-600">{tr("Expired")}</span>
            ) : !currDay ? (
              tr("Not yet started")
            ) : (
              `${currDay} ${tr("days left")}`
            )}
          </span>
          <span> | </span>
          {item.start_date && (
            <>
              <span className=" text-slate-500">
                {format(new Date(item.start_date), "MM/dd/yy")} -{" "}
                {format(new Date(item.expiry_date!), "MM/dd/yy")}
              </span>
              <span> | </span>
            </>
          )}

          <span className=" text-slate-500">
            {tr("Term")}:{" "}
            {`${item.duration} ${item.duration_type}${
              item.duration <= 1 ? "" : "s"
            }`}
          </span>
        </span>
        <p>{item.label}</p>
      </div>
      <div>
        <SubtleIconButton
          size="small"
          onClick={() =>
            modal.setModal({
              component: <EditWarrantyModal item={item} />,
              label: tr("Edit"),
            })
          }
        >
          <RiPencilLine />
        </SubtleIconButton>
      </div>
    </div>
  );
}
