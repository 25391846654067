/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { MdDelete } from "react-icons/md";
import Popup from "components/popup";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  border: 1px solid #f0f0f0;
  padding-bottom: 1rem;
  position: relative;
  border-radius: 5px;
  display: flex;

  flex-direction: column;
  text-align: left;
  &:hover {
    box-shadow: 0 0 10px -8px black;
    cursor: pointer;
  }
  ${(props) =>
    props.new &&
    css`
      background: #ffe9c3;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      padding: 0.5rem;
      border: 0;
      color: #ff8700;
      &:hover {
        color: red;
        cursor: pointer;
      }
    `}
`;

const Title = styled.div`
  // border-bottom: 1px solid #F0F0F0;
  padding: 0.5rem;
  font-weight: bold;
  color: grey;
  font-size: 1.1rem;
`;

const Date = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8rem;
  padding: 0.2rem;
  color: grey;
`;

const Detail = styled.div`
  padding: 0.5rem;
  color: #424242;
`;

const More = styled(MdDelete)`
  position: absolute;
  top: 0;
  right: 0;
  color: #d0d0d0;
  font-size: 1.5rem;
  padding: 0.5rem 0 0.5rem 0;
  &:hover {
    cursor: pointer;
  }
`;

const Confirmation = styled(Popup)`
  width: 100%;
  max-width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  > div {
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    align-items: center;
    &:first-child {
      justify-content: center;
    }
    &:nth-child(2) {
      justify-content: space-around;
      > button {
        font-size: 1.2rem;
        background: white;
        border-radius: 4px;
        border: 1px solid grey;
        padding: 1rem 2.5rem 1rem 2.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default function (props) {
  const { t } = useTranslation();
  const tBase = "views.team.viewUser.note";
  const tr = (key) => t(`${tBase}.${key}`);

  const [show, setShow] = useState(false);
  let { title, content, clicked, created, deleted } = props; // id,

  if (props.new) {
    return (
      <Con new={props.new} onClick={() => clicked()}>
        {/* <MdAddCircle/> */}+
      </Con>
    );
  } else {
    return (
      <Con
        onClick={(e) => {
          clicked();
        }}
      >
        <Title>{title}</Title>
        <Date>{created}</Date>
        <Detail>{content}</Detail>
        <More
          onClick={(e) => {
            setShow(true);
            e.stopPropagation();
          }}
        />
        {show && (
          <Confirmation cancelDisplay={() => setShow(false)}>
            <div>Are you sure you want to proceed?</div>
            <div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(false);
                  deleted();
                }}
              >
                {tr("Yes")}
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(false);
                }}
              >
                {tr("Cancel")}
              </button>
            </div>
          </Confirmation>
        )}
      </Con>
    );
  }
}
