import axios from "axios";
import Spinner from "components/spinner";
import InvoicesList from "containers/invoicesList";
import InvoiceTable from "containers/invoiceTable";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { TInvoice } from "types/invoice";
import { useHistory } from "react-router-dom";
import useQueryString from "hooks/useQueryString";
import { useTable } from "components/table";

type Props = {
  id: string;
};

export default function Estimates(props: Props) {
  const history = useHistory();
  const queryString = useQueryString();

  const table = useTable({
    url: true,
    onPaginate: (page) => queryString.update({ ...queryString.search, page }),
  });

  // ensures that page 1 is used, probably could use a better implementation
  React.useEffect(() => {
    queryString.update({ ...queryString.search, page: 1 });
  }, []);

  const invoices = useQuery<{
    invoices: TInvoice[];
    status_counts: { [key: string]: number };
    page_count: number;
    count: number;
  }>(
    ['invoices', queryString.search],
    async () => 
      await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.id}/invoices`,
        {
          params: queryString.search,
        }
      ).then((res) => res.data.rows),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data: any) => {
        table.setPageCount(data[0].page_count || 1);
      },
    }
  );

  if (invoices.isFetching) return <Spinner />;

  const handleClick = (row: TInvoice) => {
    history.push(`/invoices/${row.id}`);
  };

  return (
    <Con>
      <InvoiceTable
        pageIndex={
          queryString.search.page &&
          !Array.isArray(queryString.search.page)
            ? +queryString.search.page
            : 1
        }
        data={invoices.data}
        handleClick={handleClick}
        table={table}
      />
    </Con>
  );
}

const Con = styled.div``;