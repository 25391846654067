/* Created by Hannah Green for ES-169 on 6/11/2021 */
import React, { useState, useEffect } from "react";
import InspectionRow from "./inspection-row";
import Reports from "../reports";
import axios from "axios";
import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  @media only screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-bottom: 15px;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
`;

const Header = styled.h1`
  text-align: center;
`;

export default function InspectionDashboard() {
  const { t } = useTranslation();
  const tBase = "views.vehicle_inspection.dashboard.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [inspectionsToday, setInspectionsToday] = useState();
  const [allInspections, setAllInspections] = useState();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchData = async () => {
    try {
      const things = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/inspections_today`
      );
      setInspectionsToday(things.data);

      const all = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/all_inspections`
      );
      setAllInspections(all.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Paper style={{ backgroundColor: "#eee" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={tr("Not Reviewed")} />
          <Tab label={tr("Reviewed")} />
          <Tab label={tr("Reports")} />
        </Tabs>
      </Paper>
      {tabValue === 0 && inspectionsToday && inspectionsToday.length === 0 && (
        <Header>{tr("No Inspections to Show")}</Header>
      )}

      {tabValue === 1 && allInspections.length === 0 && (
        <Header>{tr("No Inspections to Show")}</Header>
      )}

      <Con>
        {tabValue === 0 &&
          inspectionsToday &&
          inspectionsToday.map((i) => {
            return <InspectionRow rows={i} key={i.inspection_id} />;
          })}

        {tabValue === 1 &&
          allInspections &&
          allInspections.map((i) => {
            return <InspectionRow rows={i} key={i.inspection_id} />;
          })}

        {tabValue === 2 && (
          <>
            <Reports />
          </>
        )}
      </Con>
    </>
  );
}
