  import { Button, SubtleButton } from "components/button";
import * as React from "react";
import * as S from "./style";
import { useTranslation } from 'react-i18next';

type Props = {
  value?: string;
  onClick: (row: any) => void;
  setValue?: (e: string) => void;
  data: any[];
  rowFormat: (row: any) => React.ReactNode;
  header?: () => React.ReactNode;
  close?: () => void;
  footer?: boolean;
};

export default function Select(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.selectv2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [index, setIndex] = React.useState(0);
  const [selected, setSelected] = React.useState<boolean>(false);

  const initialSearch = React.useRef<string | null>(null);
  const selectRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLButtonElement[] | null[]>([]);

  const totalLength = props.data ? props.data.length : 0;

  React.useEffect(() => {
    // props.data &&
    //   typeof selected === "number" &&
    //   props.onClick(props.data[selected]);

    return () => {
      !selected &&
        initialSearch.current &&
        props.setValue &&
        props.setValue(initialSearch.current);
    };
  }, []);

  React.useEffect(() => {
    initialSearch.current = props.value || null;
  }, []);

  const handleScroll = (index: number) => {
    const selectedRef = listRef.current[index];
    const containerRef = selectRef.current;
    const isFirst = index === 0;
    const isLast = index === totalLength - 1;
    if (containerRef && selectedRef) {
      if (isFirst) {
        containerRef.scrollTo(0, 0);
      } else if (isLast) {
        containerRef.scrollTo(0, containerRef.scrollHeight);
      } else if (
        selectedRef.offsetTop + selectedRef.clientHeight >
        containerRef.scrollTop + containerRef.clientHeight
      ) {
        containerRef.scrollTo(
          0,
          containerRef.scrollTop + selectedRef.clientHeight
        );
      } else if (selectedRef.offsetTop < containerRef.scrollTop) {
        containerRef.scrollTo(
          0,
          containerRef.scrollTop - selectedRef.clientHeight
        );
      }
    }
  };

  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowUp":
        setIndex((i) => {
          let updatedIndex = i - 1 < 0 ? totalLength - 1 : i - 1;
          handleScroll(updatedIndex);
          return updatedIndex;
        });
        e.preventDefault();
        break;
      case "ArrowDown":
        setIndex((i) => {
          let updatedIndex = i + 1 > totalLength - 1 ? 0 : i + 1;
          handleScroll(updatedIndex);
          return updatedIndex;
        });
        e.preventDefault();
        break;
      case "Enter":
        handleSelected(e);
        break;
      default:
        setIndex(0);
        return;
    }
  };

  const handleSelected = (e: any) => {
    setSelected(true);
    props.onClick(props.data[index]);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.data, index]);

  const populateprops =
    props.data?.length > 0
      ? props.data.map((row: any, i: any) => (
          <SubtleButton
            key={i}
            ref={(r) => (listRef.current[i] = r)}
            onMouseEnter={() => setIndex(i)}
            onClick={() => handleSelected(row)}
          >
            {props.rowFormat(row)}
          </SubtleButton>
        ))
      : tr("No result");

  return (
    <>
      {props.header && props.header()}
      <S.SListCon ref={selectRef} {...{ index }}>
        {populateprops}
      </S.SListCon>
      {props.footer && (
        <S.SFooter>
          <Button onClick={props.close} primary size="small">
            {tr("Done")}
          </Button>
        </S.SFooter>
      )}
    </>
  );
}
