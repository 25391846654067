import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "styles/theme";
import { A, SubtleA } from "components/button";

export const SidebarCon = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
  /* background: ${theme.bg.lightorange}; */
  flex: 0 0 250px;
  background: ${theme.bg.lightblue};
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const HomeCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;
  > h1 {
    margin: 0;
    padding: 0;
  }
`;

export const ChildGraphCon = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  border-top: 1px solid #f0f0f0;
  gap: 1rem;
  > div {
    height: 200px;
    padding: 1rem 0;
    overflow: hidden;
    border-top: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
`;

export const MenuLink = styled(SubtleA)`
  justify-content: flex-start;

  /* text-decoration: none;
  color: ${theme.color.subtext};
  transition: all 0.1s linear;
  font-size: 0.9rem;
  &:hover {
    color: black;
    font-size: 1rem;
    font-weight: bold;
  } */
`;

export const MenuHeader = styled.div`
  // margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  > svg {
    font-size: 1rem;
  }
`;

export const MenuContainer = styled.div`
  padding-left: 2.5rem;
  display: flex;
  flex-direction: column;
  // gap: 0.25em;
  padding: 1rem 0;
  border-bottom: 1px solid ${theme.border.light};
`;

export const SideBarSection = styled.div`
  padding: 0.5rem;
`;

export const ReminderCon = styled.div`
  border-radius: 20px;
  padding: 0.5rem;
  background: ${theme.bg.blue};
  margin-bottom: 0.5rem;
  transition: all 0.1s linear;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.border.dark};
  }
`;

export const ButtonCon = styled.div<{ selected: number }>`
  display: flex;
  gap: 0.5rem;
  > button {
    border-radius: 25px;
    &:nth-child(${(props) => props.selected + 1}) {
      background: black;
      color: white;
    }
  }
`;
