import React from "react";
import { useTranslation } from 'react-i18next';

import "./row.styles.css";

const Row = (props) => {
  const { t } = useTranslation();
  const tBase = "components.row";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <div>
      <div className={props.index % 2 === 0 ? "job-row" : "job-row-dark"}>
        <span className="label">
          <div className="job-job">{props.job.service_fusion_job_id}</div>
          <div className="job-date">
            {new Date(props.job.entry_date).toLocaleDateString()}
          </div>
          <div className="job-tech">{props.job.technician}</div>
          <div className="job-customer">{props.job.customer_name}</div>
          <div className="job-address">{props.job.address}</div>
          <div className="job-status">{props.job.status.toUpperCase()}</div>
        </span>

        <button
          className="open-button"
          onClick={() => {
            props.changeModal();
            props.setModalJob(props.job.id);
          }}
        >
          {tr("View Parts")}
        </button>
      </div>
    </div>
  );
};

export default Row;
