import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const SignupConfirmationSplash = () => {
  const { t } = useTranslation();
  const tBase = "views.signup.signupConfirmationSplash";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory() as any;
  // console.log(history.location.state)
  const email = history.location.state.email;
  // console.log("signup confirmation splash email: ", email);

  React.useEffect(() => {
    async function sendConfirmationEmail(email: string) {
      await axios.post(
        `
            ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/sendConfirmationEmail
          `,
        {
          email,
        }
      );
    }
    sendConfirmationEmail(email);
  }, []);

  return (
    <div
      style={{
        display: "grid",
        gap: "20px",
        width: "30em",
        marginTop: "10em",
        backgroundColor: "lightgrey",
        borderRadius: "10px",
        border: "5px #FF4F00 solid",
        textAlign: "center",
        alignSelf: "center",
        padding: "10px",
      }}
    >
      <h1>{tr("Welcome to Eserv!")}</h1>
      <h1>{tr("We have sent a confirmation email to your email account")}</h1>
    </div>
  );
};

export default SignupConfirmationSplash;
