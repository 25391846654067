import React from "react";

type Props = {
  progress: number;
};

const CircularProgressBar = ({ progress }: Props) => {
  const radius = 54; // Radius of the circle
  const stroke = 4; // Width of the progress bar stroke
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <circle
        className="transition-[stroke-dashoffset] duration-500 ease-in-out"
        stroke="#1e90ff"
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset, transform: 'rotate(-90deg)', transformOrigin: 'center' }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

export default CircularProgressBar;