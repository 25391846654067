/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import TextField from '@material-ui/core/TextField'
import Button from "@material-ui/core/Button";
// import { IoMdAddCircle } from 'react-icons/io'
import { useHistory } from "react-router-dom";
import "react-day-picker/lib/style.css";
import axios from "axios";
import Dropdown from "components/searchableDropdown";
import moment from "moment";
import socket from "../socket";
// import { useSelector } from 'react-redux'
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Input from "components/input3";
import Axios from "axios";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  width: calc(100% - 1rem);
  text-align: left;
  max-width: 1200px;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5rem;
  > * {
    margin: 0.5rem;
  }
`;
const Title = styled.div`
  font-size: 1.5rem;
  color: grey;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  > div {
    margin-bottom: 1rem;
    // width: 100%;
  }
`;

// const InputCon = styled.div`
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     > label {
//         position: absolute;
//         background: white;
//         left: 0.5rem;
//         top: -0.5rem;
//         font-size: 0.9rem;
//         padding: 0 0.5rem 0 0.5rem;
//     }
// `

// const Texts = styled(TextField)`
//     margin-bottom: 1rem;
//     margin: 1rem;
//     padding: 1rem;
// `

const Buttons = styled(Button)`
  width: 100%;
  max-width: 400px;
  background: black;
  color: green;
`;

const RawInput = styled(Input)`
  > input {
    background: transparent;
    border: 1px solid #c0c0c0;
  }
`;

// const Add = styled(IoMdAddCircle)`
//     font-size: 5rem;
//     color: grey;
// `

export default function () {
  const { t } = useTranslation();
  const tBase = "views.team.sick.leaveform";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const [employee, setEmployee] = useState([]);
  const [error, setError] = useState({});

  const history = useHistory();

  // const permission = useSelector( ({isAuth}) => isAuth.permission.absent.create)

  // useEffect(() => {
  //     if (!permission) {
  //         history.push('/')
  //     }
  // }, [])

  const submission = async (data) => {
    const dataSent = {
      type: "NEWABSENT",
      data: {
        ...data,
        id: data.id.value,
        start_date: new Date(data.start_date + " 0:00").toISOString(),
        end_date: new Date(data.end_date + " 0:00").toISOString(),
      },
    };
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent`,
        dataSent
      );
      if (
        moment().isBetween(data["start_date"], data["end_date"], "days", "[]")
      ) {
        socket.emit("applysick", {
          category: "Absent",
          employeeId: data["id"],
        });
      }
      history.goBack();
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent?type=EMPLOYEELIST`
        );

        const formatEmployee = res.data.map(({ full_name, employee_id }) => ({
          value: employee_id,
          label: full_name,
        }));
        setEmployee(formatEmployee);
      } catch (e) {
        if (e.status === 403) return history.push("/");
        alert(e);
        throw e;
      }
    };

    fetchData();
  }, [history]);

  return (
    <Con>
      <Body>
        <Formik
          initialValues={{
            id: "",
            start_date: "",
            end_date: "",
            reason: "",
          }}
          onSubmit={submission}
          validationSchema={Yup.object().shape({
            id: Yup.object().required(tr("Required")),
            start_date: Yup.date().required(tr("Required")),
            end_date: Yup.date()
              .min(Yup.ref("start_date"))
              .required(tr("Required")),
            reason: Yup.string().required(tr("Required")),
          })}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Title>{tr("Leave Request")}</Title>
                <Select
                  isSearchable={true}
                  name="id"
                  options={employee}
                  value={values.id}
                  onChange={(e) => setFieldValue("id", e)}
                />
                <RawInput
                  name="reason"
                  label={tr("Reason")}
                  value={values.reason}
                  onChange={handleChange}
                  error={errors.reason}
                />
                <RawInput
                  name="start_date"
                  label={tr("Start Date")}
                  type="date"
                  value={values.start_date}
                  error={errors.start_date}
                  onChange={handleChange}
                />
                <RawInput
                  name="end_date"
                  type="date"
                  label={tr("End Date")}
                  value={values.end_date}
                  error={errors.end_date}
                  onChange={handleChange}
                />
                <Buttons type="submit" variant="contained" color="primary">
                  {tr("Submit")}
                </Buttons>
              </Form>
            );
          }}
        </Formik>
      </Body>
    </Con>
  );
}
