import Tooltip from "components/tooltip";

type ElementProps = {
  message: string;
  element: React.ReactElement;
  placement?: "top" | "bottom" | "left" | "right";
  className?: string;
};

const TooltippedElement = (props: ElementProps) => {
	// Function to determine arrow position classes based on placement
	const arrowClass = (placement: string) => {
		switch (placement) {
			case "top":
				return "top-full left-1/2 transform -translate-y-1/2 -translate-x-1/2 rotate-45";
			case "bottom":
				return "bottom-full left-1/2 transform translate-y-1/2 -translate-x-1/2 rotate-45";
			case "left":
				return "top-1/2 left-full transform -translate-y-1/2 -translate-x-1/2 rotate-45";
			case "right":
				return "top-1/2 right-full transform -translate-y-1/2 translate-x-1/2 rotate-45";
			default:
				// Default to left
				return "top-1/2 left-full transform -translate-y-1/2 -translate-x-1/2  rotate-45";
		}
	};

	const placement = props.placement || "left";

  return (
    <div className={props.className}>
      <Tooltip
        options={{ placement: props.placement || "left" }}
        displayedElement={(TProps) => (
          <div
            onMouseEnter={TProps.open}
            onMouseLeave={TProps.close}
            onMouseDownCapture={TProps.close}
            className="relative overflow-hidden"
          >
            {props.element}
          </div>
        )}
        tooltipmessage={() => {
          return (
            <>
              <div className="relative visible z-[100] w-fit bg-gray-700 py-1 px-2 rounded-[5px] text-white text-xs">
                {props.message}
              </div>
              <span className={`absolute w-2 h-2 bg-gray-700 ${arrowClass(placement)}`} />
            </>
          );
        }}
      />
    </div>
  );
};
export default TooltippedElement;
