import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  style?: {};
};
export default function FlatSegment(props: Props) {
  const { header, children, footer, ...rest } = props;
  return (
    <SCon {...rest}>
      {header}
      {children}
      {footer}
    </SCon>
  );
}

const SCon = styled.div`
  display: flex;
  text-align: start;
  flex-direction: column;
  background: white;
  border-bottom: 1px solid ${theme.border.light};
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    margin: 1rem 0;
  }
`;

export const SFlatHeader = styled.div`
  display: flex;
  padding: 1em 1.5em;
  justify-content: space-between;
  /* border-bottom: 1px solid ${theme.border.light}; */
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 1em;
  }
`;

export const SFlatContent = styled.div`
  padding: 1em 1.5em;
  @media only screen and (max-width: 600px) {
    padding: 1em;
  }
`;

export const SFlatFooter = styled(SFlatHeader)``;
