import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const loadKeyFrame = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`;
const Con = styled.div`
  background: white;
  position: relative;
  box-shadow: 0 0 10px -3px black;
  z-index: 99;
  overflow: auto;
  border-radius: 10px;
  animation: ${loadKeyFrame} 0.3s ease-out;
`;

export default function (props) {
  const nodeSet = useRef(null);

  const { cancelDisplay } = props;

  useEffect(() => {
    const handleClick = (e) => {
      if (!nodeSet.current.contains(e.target)) {
        cancelDisplay();
      }
    };

    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [cancelDisplay]);

  return (
    <Overlay>
      <Con className={props.className} ref={nodeSet}>
        {props.children}
      </Con>
    </Overlay>
  );
}
