import axios from "axios";
import { __businessUrl__, __companyUrl__, __fieldUrl__ } from "../constants";

axios.defaults.withCredentials = true;

export const businessClient = (() => {
  return axios.create({ baseURL: `${__businessUrl__}` });
})();

export const companyClient = (() => {
  return axios.create({ baseURL: `${__companyUrl__}` });
})();

export const fieldClient = (() => {
  return axios.create({ baseURL: `${__fieldUrl__}` });
})();
