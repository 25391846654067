import axios from "axios";
import { Button } from "components/button";
import { useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};

const Sync = ({ id: customerId }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.customer.containers.sync";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const queryClient = new QueryClient();

  const { data, isLoading, isError, error } = useQuery(
    "customer-sync-details",
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/sync-details`,
        { params: { id: customerId, type: "customer" } }
      );
      return response.data;
    }
  );

  const [syncing, setSyncing] = useState(false);
  const sync = async () => {
    setSyncing(true);
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/sync-customer`,
      { customerId }
    );
    queryClient.invalidateQueries("customer-sync-details");
    setSyncing(false);
  };

  return (
    <div>
      <h1>{tr("Sync Details")}</h1>
      <h2>QuickBooks Online</h2>
      {isLoading && <p>{tr("Loading")}...</p>}
      {isError && <p>{error}</p>}
      {data && (
        <div>
          <p>{tr("Customer ID")}: {data.id}</p>
          <p>QBOL ID: {data.qbol_id}</p>
          <p>{tr("Sync Status")}: {data.qbol_sync}</p>
          <p>{tr("Message")}: {data.qbol_message}</p>
          <Button isLoading={syncing} onClick={sync}>
            {tr("Sync")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Sync;
