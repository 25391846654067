import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import timeserv from "images/TIMEServ.png";
import { Button } from "components/button";
import { Input } from "components/input";
import TimeConfirmModal from "./TimeConfirmModal";
import ConfirmstatusModal from "./ConfirmStatusModal";
import { useModalContext } from "components/modal";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  padding: 1rem;
`;
const Title = styled.div`
  text-align        : center;
  margin            : auto;
  margin-bottom     : 1rem;
  display           : flex;
  flex-direction    : row;
  flex-wrap         : wrap;
  align-items       : center;
  width             : 60%;
`;
const Row = styled.div`
  max-width         : 30rem;
  display           : flex;
  margin            : auto;
  align-items       : center;
  text-align        : center;
  margin-top        : '10px';
  padding           : '10px';
  justify-content   : space-between;
  > div {
    width: 45%;
  }
`;

export default function TimeClock() {
  const { t } = useTranslation();
  const tBase = "views.time-clock.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const [pin, setpin] = useState('')
  const auth = useAuthContext()


  let path = auth.isAuth ? `eserv-business` : `eserv-business-unprotected`;

  const userdata = async(x) => {
    return await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/${path}/time_clock/userinfo?pin=${x}`,
    ).then((res) => {
      return res.data
    })
  }


  return (
    <Con>
      <Title>
        <img
          alt="TIMEServ.logo"
          src={timeserv}
          style={{ width: "200px", margin: "auto" }}
        ></img>
      </Title>
      <Con>
          <Row>
            <Input value={pin} style={{margin:'auto'}} label={tr("Personal ID")} type='text' placeholder='FL123' onChange={(e)=>{setpin(e.target.value.toUpperCase())}}></Input>
          </Row> 
          <Row>
            <Button
            label={tr("Punch")}
            style = {{
              background    : '#ff4f00',
              height        : '50px',
              fontSize      : '18pt',
              color         : '#fff',
              margin        : 'auto',
              marginTop     : '10px'
            }}
            disabled={pin.length < 4}
            onClick={async() => {userdata(pin).then((a) => {
              if (a.length === 0) {
                setpin('')
                setModal({
                  label: tr("Invalid Pin"),
                  props: { shouldCloseOnOverlayClick: false },
                })
              } else {
                setModal({
                  component: <TimeConfirmModal data={{...a[0], pin:pin}} {...{setpin}} />,
                  // label: "Confirm",
                  props: { shouldCloseOnOverlayClick: false },
                })
              }
            })}}
            >
              {tr("Clock In or Out")} 
            </Button>
          </Row>
          <Row>
            <Button
              label={tr("Change Status")}
              style = {{
                background    : '#ff4f00',
                height        : '50px',
                fontSize      : '18pt',
                color         : '#fff',
                margin        : 'auto',
                marginTop     : '10px'
              }}
              disabled={pin.length < 4}
              onClick={async() => {
                  userdata(pin).then((a) => {
                    if (a.length > 0) {
                      setModal({
                        component: <ConfirmstatusModal data={{...a[0], pin:pin}} />,
                        label: tr("Change Status"),
                        props: { shouldCloseOnOverlayClick: false, isTimeClockStatusChangeModal: true },
                    })
                    } else {
                      setpin('')
                      setModal({
                        label: tr("Invalid Pin"),
                        props: { shouldCloseOnOverlayClick: true },
                      })
                    }
                  
                })}}
            >
          {tr("Change Job or Status")} </Button></Row>
      </Con>
    </Con>
  );
}
