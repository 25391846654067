import { FormEvent, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";

import Spinner from "components/spinner";
import { VendorContext } from "contexts/VendorContext";
import { VendorItemDetails } from "types/vendor";
import { getVendorItemDetails } from "api/vendorsApi";

import styles from "./VendorItems.module.scss";
import { SearchInput } from "components/input";
import { Button, IconButton } from "components/button";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';

const VendorRefreshModal = () => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorRefreshModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const { setModal } = useModalContext();
  const { currentVendor } = useContext(VendorContext);
  const [Modalpage, setModalpage] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [heritageUsername, setHeritageUsername] = useState<string>("");
  const [heritagePassword, setHeritagePassword] = useState<string>("");
  const [formcomplete, setFormcomplete] = useState<boolean>(false);
  const [heritageBranchCode, setHeritageBranchCode] = useState<string>("");

  const HeritageRefresh = useMutation(
    "HeritageRefresh",
    async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/heritage-refresh`,
        {
          username: heritageUsername,
          password: heritagePassword,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        // console.log("Heritage Refresh Success");
      },
    }
  );

  const handleHeritageRefresh = () => {
    HeritageRefresh.mutate();
    setModal();
  };

  const checkformcomplete = () => {
    if (
      heritageUsername !== "" &&
      heritagePassword !== "" &&
      heritageBranchCode !== ""
    ) {
      setFormcomplete(true);
    } else {
      setFormcomplete(false);
    }
  };

  if (Modalpage === "Heritage") {
    return (
      <div>
        <h1>{tr("Heritage Direct Refresh")}</h1>
        <p>{tr("Enter your Heritage username and password to refresh pricing.")}</p>
        <div>
          <label>{tr("Username")}:</label>
          <input
            type="text"
            key={"heritageUsernameInput"}
            onChange={(e) => {
              setHeritageUsername(e.target.value);
              checkformcomplete();
            }}
            value={heritageUsername}
          ></input>
        </div>
        <div>
          <label>{tr("Password")}:</label>
          <input
            type="text"
            key={"heritagePasswordInput"}
            onChange={(e) => {
              setHeritagePassword(e.target.value);
              checkformcomplete();
            }}
            value={heritagePassword}
          ></input>
        </div>
        <div>
          <label>{tr("Branch code")}:</label>
          <input
            type="text"
            key={"heritageBranchCodeInput"}
            onChange={(e) => {
              setHeritageBranchCode(e.target.value);
              checkformcomplete();
            }}
            value={heritageBranchCode}
          ></input>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              setModalpage("");
            }}
          >
            {tr("Back")}
          </Button>
          <Button
            disabled={formcomplete === false}
            style={{
              float: "right",
            }}
            onClick={() => {
              handleHeritageRefresh();
            }}
          >
            {tr("Refresh")}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div>
      <h1>{tr("Choose a method for refreshing")}:</h1>
      <div
        onClick={() => {
          setModalpage("Heritage");
        }}
        style={{
          height: "75px",
          width: "75px",
          border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
          margin: "10px",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <img src="" alt="Heritage logo"></img>
        <p>{tr("Direct Refresh")}</p>
      </div>
      <div>
        <p>{tr("Refresh via CSV")}</p>
      </div>
    </div>
  );
};

export default VendorRefreshModal;
