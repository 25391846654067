import React, { createContext, useContext, useState } from "react";

type SelectedAppointmentsContextType = {
  selectedAppointments: number[];
  setSelectedAppointments: React.Dispatch<React.SetStateAction<number[]>>;
};

const SelectedAppointmentsContext = createContext<
  SelectedAppointmentsContextType | undefined
>(undefined);

export const SelectedAppointmentsProvider: React.FC = ({ children }) => {
  const [selectedAppointments, setSelectedAppointments] = useState<number[]>(
    []
  );

  return (
    <SelectedAppointmentsContext.Provider
      value={{ selectedAppointments, setSelectedAppointments }}
    >
      {children}
    </SelectedAppointmentsContext.Provider>
  );
};

const useSelectedAppointments = (): SelectedAppointmentsContextType => {
  const context = useContext(SelectedAppointmentsContext);
  if (!context) {
    throw new Error(
      "useSelectedAppointments must be used within a SelectedAppointmentsProvider"
    );
  }
  return context;
};

export default useSelectedAppointments;
