import hosts from "constants/hosts";
import API from "constants/api";
import clients from "constants/clients";
import domains from "constants/domains";

interface UrlOptions {
  client?: (typeof clients)[keyof typeof clients];
  domain?: (typeof domains)[keyof typeof domains];
  endpoint?: string;
}

const constructUrl = ({ client, domain, endpoint }: UrlOptions) => {
  console.log(
    "constructing url... \n",
    "client",
    client,
    "domain",
    domain,
    "endpoint",
    endpoint
  );
  let url = `${hosts.SERVER}/${API}`;
  if (client) {
    url += `/${client}`;
  }
  if (domain) {
    url += `/${domain}`;
  }
  if (endpoint) {
    url += `/${endpoint}`;
  }
  return url;
};

export default constructUrl;
