import { Input } from "components/input";
import * as React from "react";
import { SItem, SItemInput } from "../style";
import type { QuoteItem } from "../index";
import { dollarFormatter } from "utils";
import { theme } from "styles/theme";
import DollarInput from "components/input/dollarInput";
import currency from "utils/currency";
import useWindowDimensions from "hooks/useWindowDimensions";
import { IconButton, SubtleIconButton } from "components/button";
import { RiEdit2Fill } from "react-icons/ri";
import { useModalContext } from "components/modal";
import AddItem from "./addItem";
import { useTranslation } from 'react-i18next';

type Props = {
  data: QuoteItem;
  onChange: (e: { value: string | number; type: string }) => void;
  editable: boolean;
  isHidden: boolean;
  type: "dropdown" | "list";
  categoryId: string;
};
export default function Item({
  isHidden,
  type,
  editable,
  data,
  onChange,
  categoryId,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.item";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isFocus, setIsFocus] = React.useState(false);
  const { setModal } = useModalContext();

  const dimensions = useWindowDimensions();

  if ((isHidden || type === "dropdown") && !data.quantity && !isFocus) {
    return null;
  }

  if (editable)
    return (
      <SItem
        style={{
          color: data.quantity ? "black" : theme.color.subtext3,
        }}
      >
        <SItemInput
          type="number"
          value={data.quantity}
          onBlur={() => setIsFocus(false)}
          onChange={(e: any) =>
            onChange({ value: e.target.value, type: "quantity" })
          }
          onFocus={(e) => {
            setIsFocus(true);
            e.target.select();
          }}
        />

        <div>
          {data.label}
          <SubtleIconButton
            size="xxsmall"
            style={{ margin: "0 0.5rem", color: theme.color.subtext3 }}
            onClick={() =>
              setModal({
                component: (
                  <AddItem categoryId={categoryId} id={data.id} data={data} />
                ),
                label: tr("Item"),
              })
            }
          >
            <RiEdit2Fill />
          </SubtleIconButton>
        </div>
        <DollarInput
          value={data.list_price}
          onChange={(e: string) =>
            onChange({ value: currency.parse(e), type: "list_price" })
          }
        />
        {/* <div>{dollarFormatter(data.list_price)}</div> */}
        <div>
          {data.quantity
            ? dollarFormatter(data.list_price * data.quantity)
            : "-"}
        </div>
        <DollarInput
          value={data.sale_price}
          onChange={(e: string) =>
            onChange({ value: currency.parse(e), type: "sale_price" })
          }
        />
        <div>
          {data.quantity
            ? dollarFormatter(data.sale_price * data.quantity)
            : "-"}
        </div>
      </SItem>
    );

  return (
    <SItem
      style={{
        color: data.quantity ? "black" : theme.color.subtext3,
      }}
      hide={dimensions.width < 800}
    >
      <div>{data.quantity}</div>

      <div>{data.label}</div>
      <div>{dollarFormatter(data.list_price)}</div>
      <div>
        {data.quantity ? dollarFormatter(data.list_price * data.quantity) : "-"}
      </div>
      <div>{dollarFormatter(data.sale_price)}</div>
      <div>
        {data.quantity ? dollarFormatter(data.sale_price * data.quantity) : "-"}
      </div>
    </SItem>
  );
}
