import { TItemsContainer } from "types/items-container";
import { TTargetType } from "types/target-type";
import { dollarFormatter } from "utils";
import EachProduct from "./eachProduct";
import Taxes from "./taxes";
import {
	calculateGrandTotal,
	calculateTaxTotal,
	calculateTotal,
} from "./utils";
import * as React from "react";
import { useTranslation } from 'react-i18next';

export default function ReadLayout(props: {
	data?: TItemsContainer;
	id: string;
	type: TTargetType;
}) {
	const { t } = useTranslation();
	const tBase = "containers.lineItems.readLayout";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const priceType = props.data?.price_type || "list";
	const rateLabel =
		priceType === "list"
			? "list_price"
			: priceType === "sale"
			? "unit_price"
			: "install_price";

	let products =
		props.data?.products &&
		props.data?.products.length > 0 &&
		props.data.products;

	if (products && props.data?.hide_empty) {
		products = products.filter((product) => product.quantity);
	}

	const populateProducts =
		products &&
		products.map((product, i, f) => {
			if (!product) return;

			const showHeader =
				i === 0 ||
				product.fk_ingroup_id !== f[i - 1].fk_ingroup_id ||
				(!product.fk_ingroup_id &&
					product.root_id !== f[i - 1].root_id);

			const group =
				showHeader && product.fk_ingroup_id && product.group_name;

			const cat = showHeader && (product.root_name || tr("Service"));

			return (
				<React.Fragment key={product.id}>
					{(i === 0 ||
						f[i - 1].fk_estimate_option_id !==
							product.fk_estimate_option_id) && (
						<span className="bg-[#ff4f00]/10 text-[#ff4f00] p-1 text-sm rounded">
							{product.estimate_option_label ||
								tr("Additional Charges")}
						</span>
					)}
					{(group || cat) && (
						<h3 className="mt-4 text-sm font-bold uppercase text-slate-500">
							{product.group_name || cat}
						</h3>
					)}
					<EachProduct
						priceType={priceType}
						rateLabel={rateLabel}
						hideEmpty={props.data?.hide_empty || false}
						{...product}
						view="read"
					/>
				</React.Fragment>
			);
		});

	const total = calculateTotal(props.data?.products);
	const afterTaxTotal = calculateTaxTotal(total, props.data?.taxes);
	const grandTotal = calculateGrandTotal(total, afterTaxTotal);

	const array: ("list" | "install" | "sale")[] = ["list", "sale", "install"];

	const restOfPriceType = array
		.filter((f) => f !== priceType)
		.reduce(
			(prev, f, i, all) =>
				prev +
				`${tr(f.toLocaleUpperCase())}: ${dollarFormatter(grandTotal[f])} ${
					i !== all.length - 1 ? " | " : ""
				}`,
			""
		);

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<h2
				style={{
					textTransform: "capitalize",
					alignSelf: "flex-end",
				}}
			>
				{/* {props.data?.price_type && tr(props.data?.price_type)} {tr("Price")} */}
				{props.data?.price_type && tr(props.data?.price_type + " Price")}
			</h2>
			{populateProducts}
			{props.type !== "appointment" && (
				<Taxes
					view="read"
					total={total}
					id={props.id}
					type={props.type}
					data={props.data?.taxes}
					priceType={priceType}
				/>
			)}
			<div style={{ alignSelf: "flex-end" }}>
				<div style={{ fontSize: "0.8rem" }}>{restOfPriceType}</div>
				<div
					style={{
						fontSize: "1.5rem",
						textAlign: "right",
						textTransform: "capitalize",
					}}
				>
					{" "}
					{props.data?.price_type} Total:{" "}
					{dollarFormatter(grandTotal[priceType])}
				</div>
			</div>
		</div>
	);
}
