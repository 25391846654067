import axios from "axios";
import TableComponent from "components/table";
import * as React from "react";
import { useQuery } from "react-query";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type TProps = {
  id: string;
};

type TItemBreakdown = {
  id: string;
  label: string;
  est_quantity: string;
  act_quantity: string;
  production_value: number;
  est_value: number;
};

export default function BreakdownModal(props: TProps) {
  const { t } = useTranslation();
  const tBase = "views.appointments.breakdownModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<{
    est_sum: number | null;
    estimated_total: number | null;
    items: TItemBreakdown[] | null;
  }>(
    ["appointment breakdown", props.id],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/production/appointment/${props.id}
    `
        )
        .then((res) => res.data)
  );

  const columns = {
    label: {
      label: tr("Label"),
    },
    est_quantity: {
      label: tr("Est. Qty"),
    },
    act_quantity: {
      label: tr("Act. Qty"),
    },
    est_value: {
      label: tr("Estimate $"),
      cell: (row: TItemBreakdown) => dollarFormatter(row.est_value),
    },
    production_value: {
      label: tr("Production $"),
      cell: (row: TItemBreakdown) => dollarFormatter(row.production_value),
    },
  };

  const apptTotal =
    query.data?.items
      ?.filter((item) => item.act_quantity)
      .reduce((acc, curr) => acc + curr.production_value, 0) || 0;

  return (
    <div>
      <TableComponent columns={columns} data={query.data?.items} />
      <div className="pt-2">
        <span>{(tr("Estimated Total"))}: </span>
        <span>{dollarFormatter(query.data?.estimated_total || 0)}</span>
      </div>
      <div className="">
        <span>{tr("Total")}: </span>
        <span>{dollarFormatter(apptTotal)}</span>
      </div>
      <div className="">
        <span>{tr("Bid Total")}: </span>
        <span>{dollarFormatter(query.data?.est_sum)}</span>
      </div>
      <div className="">
        <span>{tr("Progress")}: </span>
        <span>
          {((apptTotal / (query.data?.est_sum || 1)) * 100).toFixed(2)}%
        </span>
      </div>
    </div>
  );
}
