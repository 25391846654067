/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import moment from "moment";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import Year from "./year";
import PopUp from "components/popup";
import { useTranslation } from 'react-i18next';

// const fontAnimation = keyframes`
//     0% { font-size: 1rem }
//     100% { font-size: 1.5rem }
// `

const opacityAnimation = keyframes`
    0% { opacity: 0 } 
    100% { opacity: 1}
    // 0% { transform: translate(0, 100%)}
    // 100% { transform: translate(0,0) }
    
`;

const Con = styled.div`
  background: white;
  text-align: left;
  margin-top: 1rem;
  // height: 1000px;
  display: flex;
  flex-direction: column;

  > div:nth-child(9) {
    background: #f0f0f0;
    color: grey;
  }
`;

const MonthHeader = styled.div`
  // grid-column: span 7;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  background: #f77100;
  color: white;
  align-items: center;
  font-size: 2rem;
  padding: 0.5rem;
`;

const DayHeader = styled.div`
  border-bottom: 1px solid grey;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  > div {
    padding: 0.5rem 0 0.5rem 0.5rem;
    text-align: center;
  }
`;

const Up = styled(IoIosArrowUp)`
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`;

const Down = styled(IoIosArrowDown)`
  margin-left: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const backgroundColor = ["#FFE5E5", "#E5FFE7", "#E5F7FF"];
const color = ["red", "green", "blue"];

const User = styled.div`
  display: ${(props) => props.display};
  background: ${backgroundColor[1]};
  font-size: 0.8rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.1rem;
  overflow: hidden;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.1rem 0.1rem 0.1rem 0.4rem;
  color: ${color[1]};
  &:before {
    position: absolute;
    left: 0;
    width: 0.2rem;
    background: ${color[1]};
    content: "";
    top: 0;
    height: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Extra = styled.div`
  text-align: center;
  background: #4e4455;
  color: white;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

const CalendarBody = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  height: 100%;
  > div:nth-child(7n + 1) {
    color: red;
    background: #fff4f4;
  }
  > div {
    overflow: hidden;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    outline: 0.01px solid #fafafa;
    height: 150px;
  }
  ${(props) =>
    props.animation &&
    css`
      animation: ${opacityAnimation} 0.5s linear;
    `}
`;

const Modal = styled(PopUp)`
  padding: 2rem 1rem 2rem 1rem;
  font-size: 1.2rem;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  > div {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    &:first-child {
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }
  }
`;

export default function () {
  const { t, i18n } = useTranslation();
  const tBase = "views.team.sick.calendar";
  const tr = (key) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? "es" : "en";

  const [currentMonth, setMonth] = useState(+moment().format("MM"));
  const [currentYear, setYear] = useState(+moment().format("YYYY"));
  const [dateGrid, setDateGrid] = useState([]);
  const [extra, setExtra] = useState([]);
  const [animate, setAnimate] = useState(false);
  // const [ view, setView ] = useState('month');
  const view = "month";
  const [show, setShow] = useState(false);
  const history = useHistory();

  const openExtra = useCallback(
    (req) => {
      let { date, data } = req;
      data.pop();
      let formatter = data.map((e) => {
        return (
          <User
            onClick={() =>
              history.push({
                pathname: `/admin/employees/${e.props.id}/absence`,
                from: "sick",
              })
            }
            key={e.key}
          >
            {e.props.children}
          </User>
        );
      });
      formatter.unshift(<div key={date}>{date}</div>);
      setExtra(formatter);
      setShow(true);
    },
    [history]
  );

  const setGrid = useCallback(
    async (req = moment(), list) => {
      const requestedDate = moment(req);
      const firstDay = requestedDate.startOf("month").format("d");
      const lastDay = requestedDate.endOf("month").format("d");
      const numberOfDays = requestedDate.daysInMonth();
      const today = moment().startOf("day");
      const sameMonth =
        today.isSame(requestedDate, "year") &&
        today.isSame(requestedDate, "month");

      const checkMatch = (i, list) => {
        let tabulatedList = [];
        list.forEach((e) => {
          const [startM, startD, startY] = e["start"];
          const [endM, endD, endY] = e["end"];

          if (
            (i >= startD && i <= endD && startM === endM && startY === endY) || // SAME MONTH && YEAR // DIFFERENT MONTH
            (i <= endD && currentMonth === endM && currentMonth !== startM) || // FOR LATTER MONTH
            (i >= startD && currentMonth === startM && currentMonth !== endM) || // FOR STARTING MONTH
            (startM < currentMonth && currentMonth < endM) || // FOR MONTH IN BETWEEN SAME YEAR
            (startM > currentMonth && currentMonth < endM)
          ) {
            if (tabulatedList.length > 4) {
              tabulatedList.push(
                <User display="none" id={e["id"]} key={e["sickId"]}>
                  {e["name"]}
                </User>
              );
            } else {
              tabulatedList.push(
                <User
                  id={e["id"]}
                  display=""
                  key={e["sickId"]}
                  onClick={() =>
                    history.push({
                      pathname: `/admin/employees/${e["id"]}/absence`,
                      from: "sick",
                    })
                  }
                >
                  {e["name"]}
                </User>
              );
            }
          }
        });
        tabulatedList.length > 5 &&
          tabulatedList.push(
            <Extra
              onClick={() =>
                openExtra({
                  date: currentMonth + "/" + i + "/" + currentYear,
                  data: tabulatedList,
                })
              }
              key={i}
            >
              +{tabulatedList.length - 5}
            </Extra>
          );
        return tabulatedList;
      };

      let grid = [];
      for (let i = 0; i < firstDay; i++) {
        grid.push(<div key={"n" + i} style={{ background: "#F6F6F6" }}></div>);
      }

      for (let i = 0; i < numberOfDays; i++) {
        let isToday;
        if (sameMonth && +today.format("D") === i + 1) {
          isToday = true;
        }
        grid.push(
          <div style={{ background: isToday && "#D2ECFF" }} key={i + 1}>
            {i + 1}
            {checkMatch(i + 1, list, currentMonth)}
          </div>
        );
      }

      for (let i = 0; i < 6 - lastDay; i++) {
        grid.push(<div key={"e" + i} style={{ background: "#F6F6F6" }}></div>);
      }

      setDateGrid(grid);
      setAnimate(true);
    },
    [currentMonth, currentYear, history, openExtra]
  );

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent?type=CALENDAR&month=${currentMonth}&year=${currentYear}`
      );
      const tabulation = await res.data.map((e) => {
        let {
          employee_id: id,
          sick_id: sickId,
          full_name: name,
          start_date: start,
          end_date: end,
        } = e;
        start = moment(new Date(start));
        start = [+start.format("M"), +start.format("D"), +start.format("Y")];

        end = moment(new Date(end));
        end = [+end.format("M"), +end.format("D"), +end.format("Y")];

        return {
          name,
          start,
          end,
          id,
          sickId,
        };
      });

      setGrid(
        currentYear +
          "-" +
          (currentMonth < 10 ? "0" + currentMonth : currentMonth),
        tabulation
      );
    } catch (e) {
      console.error(e);
      // alert(e)
    }
  }, [currentMonth, currentYear, setGrid]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changeMonth = (req) => {
    let updatedMonth = currentMonth;
    req === "ADD" ? updatedMonth++ : updatedMonth--;

    if (updatedMonth > 12) {
      updatedMonth = 1;
      setYear(() => currentYear + 1);
    } else if (updatedMonth === 0) {
      updatedMonth = 12;
      setYear(() => currentYear - 1);
    }

    setMonth(() => updatedMonth);
  };

  return (
    <Con>
      <MonthHeader>
        <div>
          {moment(currentMonth, "MM").locale(locale).format("MMMM") + " " + currentYear}
        </div>
        <Up onClick={() => changeMonth("MINUS")}>{tr("Left")}</Up>
        <Down onClick={() => changeMonth("ADD")}>{tr("Right")}</Down>
      </MonthHeader>
      {view === "month" ? (
        <>
          <DayHeader>
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </DayHeader>
          <CalendarBody
            onAnimationEnd={() => setAnimate(false)}
            animation={animate}
          >
            {dateGrid}
          </CalendarBody>
        </>
      ) : (
        <Year />
      )}
      {show && <Modal cancelDisplay={() => setShow(false)}>{extra}</Modal>}
    </Con>
  );
}
