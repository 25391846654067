// import { useHistory } from 'react-router-dom'
// import React from 'react'
import axios from "axios";
import { history } from "../routes";

// axios.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   (err) => {
//     if (err.response) {
//       switch (err.response.status) {
//         case 403:
//           history.replace("/nopermission");
//           break;
//         case 401:
//           history.push("/login");
//           break;
//         default:
//           console.error(err.response);
//           break;
//       }
//       return Promise.reject(err.response);
//     }
//   }
// );

// axios.defaults.withCredentials = true;

export default axios;
