import Head from "./Head";
import Body from "./Body";
import IProductionItem from "../types/IProductionItem";
import ExportButton from "./ExportButton";
import { useTranslation } from 'react-i18next';

type IProps = {
  isLoading: boolean;
  isError: boolean;
  error: any;
  data: IProductionItem[] | undefined;
  sum: number;
};

const Table = ({ isLoading, isError, error, data, sum }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.admin.production.components.Table";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-row items-center justify-between p-3">
        <div className="flex flex-row items-center">
          <h2 className="mr-2">{tr("Sum")}:</h2>
          <div className="flex flex-row items-center">
            {sum ? (
              <span>${(sum / 100).toFixed(2).toString()}</span>
            ) : (
              <span>$0.00</span>
            )}
          </div>
        </div>
        <div className="flex flex-row">
          <ExportButton data={data} />
        </div>
      </div>
      <hr className="mb-2" />
      <table className="min-w-full bg-white border rounded-md">
        <Head />

        {isLoading && <div className="mb-2">{tr("Loading")}...</div>}

        {isError && error instanceof Error && (
          <div className="p-2 mb-2 text-red-600 bg-red-100 rounded-md">
            {tr("Error")}: {error.message}
          </div>
        )}

        {!isLoading && !isError && data ? (
          <Body productionItems={data} />
        ) : (
          <div>{tr("No Data Found.")}</div>
        )}
      </table>
    </div>
  );
};

export default Table;
