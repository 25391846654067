import { cloneDeep, get, set } from "lodash";
import * as React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Input, InputProps } from ".";

type Props = InputProps & NumberFormatProps;
export default function DurationInput(props: Props) {
  return (
    <NumberFormat
      // @ts-ignore
      customInput={Input}
      format={(e) => {
        let hr = e.substring(0, 2);
        let min = e.substring(2, 4);

        if (Number(min) > 60) {
          min = (60).toString();
        }
        return `${hr || "__"}  h  ${min || "__"}  m`;
      }} // "##  h  ##  m"
      mask="_"
      onValueChange={(e) => {
        props.name &&
          props.formik &&
          get(props.formik.values, props.name) !== (e.floatValue ?? "") &&
          props.formik.setFieldValue(props.name, e.floatValue ?? null);
      }}
      value={props.name && get(props.formik?.values, props.name)}
      {...props}
    />
  );
}
