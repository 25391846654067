/* Created by Hannah Green for ES-147 on 4/20/2021 */
import React, { useState, useEffect } from "react";
import Axios from "axios";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Requests from "./components/requests/requests";
import RequestCategory from "./components/requests/request-category";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import Reports from "./components/reports/reports";
import {
  useDisplayModeDispatch,
  useDisplayModeState,
} from "../../contexts/displayMode";
import { useTranslation } from 'react-i18next';

const DisplaySwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 15px;
`;

const NoRequests = styled.div`
  text-align: center;
  font-size: 20px;
  padding: 5px;
  margin-top: 20px;
`;

export default function RepairRequestDashboard() {
  const { t } = useTranslation();
  const tBase = "views.repair_request.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [requests, setRequests] = useState();
  const [inProgressRequests, setInProgressRequests] = useState();
  const [completedRequests, setCompletedRequests] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [timer, setTimer] = useState();
  const displayModeState = useDisplayModeState();
  const displayModeDispatch = useDisplayModeDispatch();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggle = (event) => {
    displayModeDispatch({
      type: "CHANGE_MODE",
      displayMode: event.target.checked,
    });
  };

  const fetchData = async () => {
    try {
      const res = await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/all_requests`
      );
      setRequests(res.data);

      const resu = await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/in_progress_requests`
      );
      setInProgressRequests(resu.data);

      const result = await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/completed_requests`
      );
      setCompletedRequests(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (displayModeState.displayMode) {
      setTimer(
        setTimeout(() => {
          window.location.reload();
        }, 300000)
      ); /* 300000 - 5 mins */
    } else {
      clearTimeout(timer);
    }
  }, [displayModeState]);

  return (
    <>
      {requests && requests.length > 0 && (
        <div>
          <Paper style={{ backgroundColor: "#eee" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={tr("All Requests")} />
              <Tab label={tr("In Progress Requests")} />
              <Tab label={tr("Completed Requests")} />
              <Tab label={tr("Request Reports")} />
            </Tabs>
          </Paper>

          {tabValue !== 3 && (
            <DisplaySwitch>
              <FormControlLabel
                value="Display Mode"
                control={
                  <Switch
                    color="primary"
                    checked={displayModeState.displayMode}
                    onChange={handleToggle}
                  />
                }
                label={tr("Display Mode")}
                labelPlacement="start"
              />
            </DisplaySwitch>
          )}

          {tabValue === 0 &&
            requests &&
            requests.map((request, index) => {
              return (
                <RequestCategory
                  key={index}
                  requestList={request.json_agg}
                  label={request.status}
                />
              );
            })}

          {tabValue === 1 &&
            inProgressRequests &&
            inProgressRequests.length > 0 && (
              <Requests requestList={inProgressRequests[0].json_agg} />
            )}

          {tabValue === 1 &&
            inProgressRequests &&
            inProgressRequests.length === 0 && (
              <NoRequests>
                <div>{tr("No Requests to Show")}</div>
              </NoRequests>
            )}

          {tabValue === 2 &&
            completedRequests &&
            completedRequests.length > 0 && (
              <Requests requestList={completedRequests[0].json_agg} />
            )}

          {tabValue === 2 &&
            completedRequests &&
            completedRequests.length === 0 && (
              <NoRequests>
                <div>{tr("No Requests to Show")}</div>
              </NoRequests>
            )}

          {tabValue === 3 && <Reports />}
        </div>
      )}

      {requests && requests.length === 0 && (
        <div>
          <Paper style={{ backgroundColor: "#eee" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={tr("All Requests")} />
              <Tab label={tr("In Progress Requests")} />
              <Tab label={tr("Completed Requests")} />
              <Tab label={tr("Request Reports")} />
            </Tabs>
          </Paper>

          <DisplaySwitch></DisplaySwitch>

          <NoRequests>
            <div>{tr("No Requests to Show")}</div>
          </NoRequests>
        </div>
      )}
    </>
  );
}
