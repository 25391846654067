import axios from "api/axios";
import { Button, OutlineButton, SubtleIconA } from "components/button";
import GroupedButton from "components/button/grouped";
import ImageUploadButton, {
  UploadImage,
} from "components/button/imageUploadButton";
import Field from "components/field";
import { Input } from "components/input";
import DollarInputV2 from "components/input/dollarInputV2";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import { useFormik } from "formik";
import { flatten } from "lodash";
import * as React from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { RiCloseCircleLine, RiCloseFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TInventory, TInventoryForm } from "types/inventory";
import { TProduct, TProductForm } from "types/product";
import { TItemGroup } from "types/item_group";
import ReadLayout from "./read";
import * as Yup from "yup";
import Switch from "rc-switch";
import { useAreYouSure } from "components/areYouSure";
import { useAuthContext } from "contexts/auth";
import { TCategory } from "../categories/TCategory";
import Select from "components/react-select";
import { useTranslation } from 'react-i18next';

type Props = {
  data?: TProduct | TInventory;
};

export default function Inventory(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.inventories.inventory.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [isPart, setIsPart] = React.useState(true);
  const [image, setImage] = React.useState<any>();
  const { id, type } = useParams<{ id: string; type?: string }>();
  const { showSnackbar } = useSnackbarContext(),
    history = useHistory(),
    triggerButton = React.useRef(() => null),
    saveButton = React.useRef<any>(),
    areYouSure = useAreYouSure();

  const [isEdit, setIsEdit] = React.useState(true);

  const handleUpload = (props: UploadImage) => {
    // console.log("image props: ", props);
    setImage(props);
  };

  const [archiver, setArchiver] = React.useState<{
    archived_by: string;
    archived_at: Date;
  } | null>(null);

  React.useEffect(() => {
    if (type === "part") setIsPart(true);
    if (type === "service") setIsPart(false);
    if (type === "group") setIsPart(false);
  }, [type]);

  React.useEffect(() => {
    getArchiver();
  }, []);

  const query = useQuery<
    ({ type: "part" } & TInventory) | ({ type: "service" } & TProduct)
  >(
    ["item", id, type],
    async () =>
      id !== "new" &&
      (await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/${type}/${id}
  `
        )
        .then((res) => ({ ...res.data, type })))
  );

  const mutation = useMutation(
    async (form: any) =>
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/update`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => res.data)
  );

  let { name } = useAuthContext();

  const Archive = async (toggle: boolean) => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item/archive/${id}?type=${type}&archive=${toggle}&name=${name}`
      )
      .then(() => query.refetch());

    getArchiver();
  };

  const getArchiver = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ARCHIVER&item_id=${id}&item_type=${type}`
      )
      .then((res) => setArchiver(res.data[0]));
  };

  let partInitialValues = {
    item_name: "",
    short_name: "",
    short_description: "",
    long_description: "",
    model: "",
    sku: "",
    list_price: null,
    fixed_unit_price: null,
    fixed_install_price: null,
    cat_id_v2: "",
    root_id: "",
    special_order_item: false,
    bulk_order_item: false,
    bulk_item_description: "",
    sale_is_fixed: true,
    sale_multiplier: null,
    install_is_fixed: true,
    install_multiplier: null,
    group_only: false,
  };
  let serviceInitialValues = {
    description: "",
    product_label: "",
    commission: true,
    list_price: null,
    fixed_unit_price: null,
    fixed_install_price: null,
    sale_is_fixed: true,
    sale_multiplier: null,
    install_is_fixed: true,
    install_multiplier: null,
    group_only: false,
  };

  const initialValues = query.data
    ? query.data.type === "part"
      ? {
          item_name: query.data?.item_name || "",
          short_name: query.data?.short_name || "",
          short_description: query.data?.short_description || "",
          long_description: query.data?.long_description || "",
          model: query.data?.model || "",
          sku: query.data?.sku || "",
          list_price:
            (typeof query.data?.list_price === "number" &&
              query.data.list_price / 100) ||
            null,
          fixed_unit_price:
            (typeof query.data?.fixed_unit_price === "number" &&
              query.data.fixed_unit_price / 100) ||
            null,
          sale_is_fixed:
            query.data?.sale_is_fixed !== undefined
              ? query.data?.sale_is_fixed
              : true,
          sale_multiplier: query.data?.sale_multiplier ?? null,
          fixed_install_price:
            (typeof query.data?.fixed_install_price === "number" &&
              query.data.fixed_install_price / 100) ||
            null,
          install_is_fixed:
            query.data?.install_is_fixed !== undefined
              ? query.data?.install_is_fixed
              : true,
          install_multiplier: query.data?.install_multiplier ?? null,
          cat_id_v2: query.data?.cat_id_v2 || "",
          root_id: query.data?.root_id || "",
          special_order_item: query.data?.special_order_item || false,
          bulk_order_item: query.data?.bulk_order_item || false,
          bulk_item_description: query.data?.bulk_item_description || "",
          group_only: query.data?.group_only || false,
        }
      : {
          description: query.data?.description || "",
          product_label: query.data?.product_label || "",
          commission: query.data?.commission ?? true,
          sale_is_fixed:
            query.data?.sale_is_fixed !== undefined
              ? query.data.sale_is_fixed
              : true,
          sale_multiplier: query.data?.sale_multiplier || null,
          list_price:
            (typeof query.data?.list_price === "number" &&
              query.data.list_price / 100) ||
            null,
          fixed_unit_price:
            (typeof query.data?.fixed_unit_price === "number" &&
              query.data.fixed_unit_price / 100) ||
            null,
          install_is_fixed:
            query.data?.install_is_fixed !== undefined
              ? query.data.install_is_fixed
              : true,
          install_multiplier: query.data?.install_multiplier || null,
          fixed_install_price:
            (typeof query.data?.fixed_install_price === "number" &&
              query.data.fixed_install_price / 100) ||
            null,
          group_only: query.data?.group_only || false,
        }
    : isPart
    ? partInitialValues
    : serviceInitialValues;

  const submit = async (anotherOne: boolean) => {
    try {
      const res = await formik.submitForm();
      showSnackbar(id !== "new" ? "Item updated!" : "Item added!");
      if (id === "new") {
        anotherOne
          ? formik.resetForm()
          : history.push(`/inventory/${res.id}/${res.type}`);
      } else {
        query.refetch();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const onSubmit = async (
    e: TProductForm | (TInventoryForm & { t?: boolean })
  ) => {
    try {
      const formData = new FormData();

      formData.append(
        "form",
        JSON.stringify({
          ...e,
          isPart,
          id,
          list_price:
            typeof e.list_price === "number"
              ? Math.round(e.list_price * 100)
              : null,
          fixed_unit_price:
            typeof e.fixed_unit_price === "number"
              ? Math.round(e.fixed_unit_price * 100)
              : null,
          fixed_install_price:
            typeof e.fixed_install_price === "number"
              ? Math.round(e.fixed_install_price * 100)
              : null,
        })
      );

      if (image) {
        // const imageBlob = await (await fetch(image)).blob();
        formData.append("image", image.blob);
      }

      const res = await mutation.mutateAsync(formData);

      return res;
    } catch (e) {
      console.error(e);
    }
  };

  const validationSchema = isPart
    ? Yup.object({
        item_name: Yup.string().required(),
        short_name: Yup.string(),
        short_description: Yup.string(),
        long_description: Yup.string(),
        model: Yup.string(),
        sku: Yup.string(),
        list_price: Yup.number().nullable(),
        fixed_unit_price: Yup.number().nullable(),
        fixed_install_price: Yup.number().nullable(),
        cat_id_v2: Yup.string(),
        root_id: Yup.string(),
        special_order_item: Yup.boolean(),
        bulk_order_item: Yup.boolean(),
        bulk_item_description: Yup.string(),
        sale_is_fixed: Yup.boolean(),
        sale_multiplier: Yup.number().nullable(),
        install_is_fixed: Yup.boolean(),
        install_multiplier: Yup.number().nullable(),
        group_only: Yup.boolean(),
      })
    : Yup.object({
        description: Yup.string(),
        product_label: Yup.string().required(),
        commission: Yup.boolean().required(),
        list_price: Yup.number().nullable(),
        fixed_unit_price: Yup.number().nullable(),
        fixed_install_price: Yup.number().nullable(),
        sale_is_fixed: Yup.boolean(),
        sale_multiplier: Yup.number().nullable(),
        install_is_fixed: Yup.boolean(),
        install_multiplier: Yup.number().nullable(),
        group_only: Yup.boolean(),
      });

  const formik = useFormik<TInventoryForm | TProductForm>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  if (id !== "new" && query.isLoading) return <Spinner />;
  return (
    <div style={{ position: "relative" }}>
      <div
        className={`px-2 py-4 sticky top-0 b-b flex !z-${
          theme.zIndex.navigation
        } border-b-2 bg-white items-center ${
          query.data?.is_voided && "border-red-600 bg-red-100"
        }`}
        style={{ zIndex: theme.zIndex.navigation }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <SubtleIconA to="/products" size="small">
            <RiCloseFill />
          </SubtleIconA>
          | {tr("Edit Product")}
        </div>
        {query.data?.is_voided && (
          <div>
            <div
              className="p-1 mx-2 text-sm font-bold text-white uppercase bg-red-600 rounded"
              style={{ display: "inline-block" }}
            >
              {tr("Archived")}
            </div>
            <div
              style={{
                display: "inline-block",
                color: "red",
                fontStyle: "italic",
              }}
            >
              {/* Note: existing archived items will not have this shown */}
              {archiver?.archived_by &&
                `${tr("Archived By")}: ` +
                  archiver.archived_by +
                  ` ${tr("on")} ` +
                  new Date(archiver.archived_at).toLocaleString("default", {
                    weekday: "long",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
            </div>
          </div>
        )}
        <div className="flex items-center ml-auto">
          {id !== "new" && !query.data?.is_voided && (
            <OutlineButton
              className="!border-red-600 !text-red-600"
              onClick={() => Archive(true)}
            >
              <RiCloseCircleLine /> {tr("Archive")}
            </OutlineButton>
          )}
          {id !== "new" && query.data?.is_voided && (
            <OutlineButton
              className="!border-green-600 !text-green-600"
              onClick={() => Archive(false)}
            >
              <RiCloseCircleLine /> {tr("Unarchive")}
            </OutlineButton>
          )}
          {id === "new" && (
            <OutlineButton
              primary
              // size="small"
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
              onClick={() => submit(true)}
              isLoading={mutation.isLoading}
            >
              {tr("Save and Add Another")}
            </OutlineButton>
          )}

          <Button
            primary
            // size="small"
            style={{ marginLeft: "1rem" }}
            type="submit"
            ref={saveButton}
            disabled={!formik.dirty || !formik.isValid}
            onClick={() => submit(false)}
            isLoading={mutation.isLoading}
          >
            {tr("Save Changes")}
          </Button>
        </div>
      </div>
      <SafeArea
        style={{
          content: {
            display: "flex",
            maxWidth: "700px",
            padding: "1rem 0",
            flexDirection: "column",
          },
        }}
      >
        {isEdit ? (
          <>
            <ImageUploadButton
              uploadImages={handleUpload}
              Component={
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    fontSize: "2rem",
                    border: `1px solid ${theme.border.light}`,
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    borderRadius: "10px",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={image?.dataUrl || query.data?.imageUrl}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                  <AiOutlineCamera style={{ position: "absolute" }} />
                </div>
              }
            />
            {type ? (
              <h1 style={{ textTransform: "capitalize" }}>{type}</h1>
            ) : (
              <GroupedButton style={{ margin: "1rem 0" }}>
                <Button $selected={isPart} onClick={() => setIsPart(true)}>
                  {tr("Part")}
                </Button>
                <Button $selected={!isPart} onClick={() => setIsPart(false)}>
                  {tr("Services")}
                </Button>
              </GroupedButton>
            )}

            {isPart ? (
              <PartForm {...{ formik }} />
            ) : (
              <ServiceForm {...{ formik }} />
            )}
          </>
        ) : (
          <ReadLayout data={query.data} isPart={isPart} />
        )}
      </SafeArea>
    </div>
  );
}

type PartFormProps = {
  formik: any;
};

// const fetchCategories = async (): Promise<TCategory[]> => {
//   const { data } = await axios.get<TCategory[]>(
//     `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/categories`
//   );
//   return data;
// };

const PartForm = ({ formik }: PartFormProps) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.inventory.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const {
    data: categories,
    isError,
    isLoading,
    error,
  } = useQuery<TCategory[], Error>(
    "categories",
    async () =>
      await axios
        .get<TCategory[]>(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/categories`
        )
        .then((res) => res.data)
  );

  const handleCategoryChange = ({ value, label }: any) => {
    formik.setValues((v: any) => ({
      ...v,
      root_id: value,
    }));
  };

  const handleSubCategoryChange = ({ value, label }: any) => {
    formik.setValues((v: any) => ({
      ...v,
      cat_id_v2: value,
    }));
  };

  const handleChangeSpecialOrder = (value: boolean) => {
    formik.setValues((v: any) => ({ ...v, special_order_item: value }));
  };

  const handleChangeBulk = (value: boolean) => {
    formik.setValues((v: any) => ({ ...v, bulk_order_item: value }));
  };

  const handleChangeGroupOnly = (value: boolean) => {
    formik.setValues((v: any) => ({ ...v, group_only: value }));
  };

  if (isLoading) return <Spinner />;
  if (isError) return <div>{tr("Error fetching categories")}</div>;

  return (
    <SForm onSubmit={formik.handleSubmit}>
      <Input label={tr("Item Name")} name="item_name" required formik={formik} />
      <Input label={tr("Short Name")} name="short_name" formik={formik} />
      <Input
        name="short_description"
        label={tr("Short Description")}
        formik={formik}
      />
      <Input label={tr("Long Description")} name="long_description" formik={formik} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          style={{ width: "49%" }}
          name="model"
          label={tr("Model")}
          formik={formik}
        />
        <Input
          label="SKU"
          style={{ width: "49%" }}
          name="sku"
          formik={formik}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Select
          style={{ width: "49%" }}
          name="root_id"
          label={tr("Category")}
          options={
            categories
              ? categories
                  .filter((cat) => cat.is_parent_category)
                  .map((cat) => ({
                    label: cat.category_name,
                    value: cat.category_id,
                  }))
              : []
          }
          onChange={handleCategoryChange}
          value={
            formik.values.root_id
              ? {
                  label: categories?.find(
                    (cat) => cat.category_id === formik.values.root_id
                  )?.category_name,
                  value: formik.values.root_id,
                }
              : undefined
          }
        />
        <Select
          name="cat_id_v2"
          label={tr("Sub Category")}
          options={
            formik.values.root_id && categories
              ? categories
                  .filter(
                    (cat) => cat.parent_category_id === formik.values.root_id
                  )
                  .map((cat) => ({
                    label: cat.category_name,
                    value: cat.category_id,
                  }))
              : []
          }
          onChange={handleSubCategoryChange}
          style={{
            width: "49%",
          }}
          value={
            formik.values.cat_id_v2
              ? {
                  label: categories?.find(
                    (cat) => cat.category_id === formik.values.cat_id_v2
                  )?.category_name,
                  value: formik.values.cat_id_v2,
                }
              : undefined
          }
        />
      </div>

      <div className="flex flex-row items-center">
        <Switch
          onChange={(e) => handleChangeBulk(e)}
          checked={formik.values.bulk_order_item}
        />
        <label className="ml-2">{tr("Bulk Order Item")}</label>
      </div>

      <div className="flex flex-row items-center">
        <Switch
          onChange={(e) => handleChangeSpecialOrder(e)}
          checked={formik.values.special_order_item}
        />
        <label className="ml-2">{tr("Special Order Item")}</label>
      </div>

      <div className="flex flex-row items-center">
        <Switch
          onChange={(e) => handleChangeGroupOnly(e)}
          checked={formik.values.group_only}
        />
        <label className="ml-2">{tr("Group Only")}</label>
      </div>
      {/* {!bulkOrderDescHidden && (
        <Input
          label="Bulk Order Item Description"
          name="bulk_item_description"
          type="text"
        />
      )} */}
      {/* <Select name="tags" creatable isMulti options={tagBox} label="Tags" /> */}

      <div className="">
        <h2>{tr("Price Type")}</h2>
      </div>
      <div className="border rounded-lg">
        <div className="divide-y">
          <div className="p-4">
            <h4>{tr("List Price")}</h4>
            <DollarInputV2
              name="list_price"
              style={{ width: "50%" }}
              formik={formik}
              // value={formik.values.list_price}
            />
          </div>

          <div className="p-4">
            <div className="flex justify-between">
              <h4>{tr("Sale Price")}</h4>
              <div>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    formik.values.sale_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      sale_is_fixed: true,
                    }))
                  }
                >
                  {tr("Fixed")}
                </OutlineButton>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    !formik.values.sale_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      sale_is_fixed: false,
                    }))
                  }
                >
                  {tr("Multiply")}
                </OutlineButton>
              </div>
            </div>
            <div className="flex items-end justify-between space-x-2">
              {!formik.values.sale_is_fixed ? (
                <>
                  <DollarInputV2
                    name="list_price"
                    style={{ width: "35%" }}
                    label={tr("List Price")}
                    disabled
                    formik={formik}
                  />
                  <span className="leading-10">x</span>
                  <Input
                    name="sale_multiplier"
                    label={tr("Multiplier")}
                    style={{ width: "20%" }}
                    formik={formik}
                    type="number"
                  />
                  <span className="leading-10">=</span>
                  <DollarInputV2
                    label={tr("Sale Price")}
                    style={{ width: "35%" }}
                    value={
                      (formik.values.sale_multiplier || 0) *
                      (formik.values.list_price || 0)
                    }
                    disabled
                  />
                </>
              ) : (
                <DollarInputV2
                  name="fixed_unit_price"
                  style={{ width: "50%" }}
                  formik={formik}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex justify-between">
              <h4>{tr("Installed Price")}</h4>
              <div>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    formik.values.install_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      install_is_fixed: true,
                    }))
                  }
                >
                  {tr("Fixed")}
                </OutlineButton>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    !formik.values.install_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      install_is_fixed: false,
                    }))
                  }
                >
                  {tr("Multiply")}
                </OutlineButton>
              </div>
            </div>

            <div className="flex items-end justify-between space-x-2">
              {!formik.values.install_is_fixed ? (
                <>
                  <DollarInputV2
                    name="list_price"
                    style={{ width: "35%" }}
                    label={tr("List Price")}
                    disabled
                    formik={formik}
                  />
                  <span className="leading-10">x</span>
                  <Input
                    name="install_multiplier"
                    label={tr("Multiplier")}
                    style={{ width: "20%" }}
                    formik={formik}
                    type="number"
                  />
                  <span className="leading-10">=</span>
                  <DollarInputV2
                    label={tr("Installed Price")}
                    style={{ width: "35%" }}
                    value={
                      (formik.values.install_multiplier || 0) *
                      (formik.values.list_price || 0)
                    }
                    disabled
                  />
                </>
              ) : (
                <DollarInputV2
                  name="fixed_install_price"
                  style={{ width: "50%" }}
                  formik={formik}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SForm>
  );
};

type ServiceFormProps = {
  formik: any;
};

const ServiceForm = ({ formik }: ServiceFormProps) => {
  const { t } = useTranslation();
  const tBase = "views.inventories.inventory.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const handleChangeGroupOnly = (value: boolean) => {
    formik.setValues((v: any) => ({ ...v, group_only: value }));
  };

  return (
    <SForm onSubmit={formik.handleSubmit}>
      <Input name="product_label" label={tr("Label")} formik={formik} required />
      <Input name="description" label={tr("Description")} formik={formik} />
      <div className="flex flex-row items-center">
        <Switch
          onChange={(e) => handleChangeGroupOnly(e)}
          checked={formik.values.group_only}
        />
        <label className="ml-2">{tr("Group Only")}</label>
      </div>
      <div className="">
        <h2>{tr("Price Type")}</h2>
      </div>
      <div className="border rounded-lg">
        <div className="divide-y">
          <div className="p-4">
            <h4>{tr("List Price")}</h4>
            <DollarInputV2
              name="list_price"
              style={{ width: "50%" }}
              formik={formik}
              // value={formik.values.list_price}
            />
          </div>

          <div className="p-4">
            <div className="flex justify-between">
              <h4>{tr("Sale Price")}</h4>
              <div>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    formik.values.sale_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      sale_is_fixed: true,
                    }))
                  }
                >
                  {tr("Fixed")}
                </OutlineButton>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    !formik.values.sale_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      sale_is_fixed: false,
                    }))
                  }
                >
                  {tr("Multiply")}
                </OutlineButton>
              </div>
            </div>
            <div className="flex items-end justify-between space-x-2">
              {!formik.values.sale_is_fixed ? (
                <>
                  <DollarInputV2
                    name="list_price"
                    style={{ width: "35%" }}
                    label={tr("List Price")}
                    disabled
                    formik={formik}
                  />
                  <span className="leading-10">x</span>
                  <Input
                    name="sale_multiplier"
                    label={tr("Multiplier")}
                    style={{ width: "20%" }}
                    formik={formik}
                    type="number"
                  />
                  <span className="leading-10">=</span>
                  <DollarInputV2
                    label={tr("Sale Price")}
                    style={{ width: "35%" }}
                    value={
                      (formik.values.sale_multiplier || 0) *
                      (formik.values.list_price || 0)
                    }
                    disabled
                  />
                </>
              ) : (
                <DollarInputV2
                  name="fixed_unit_price"
                  style={{ width: "50%" }}
                  formik={formik}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex justify-between">
              <h4>{tr("Installed Price")}</h4>
              <div>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    formik.values.install_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      install_is_fixed: true,
                    }))
                  }
                >
                  {tr("Fixed")}
                </OutlineButton>
                <OutlineButton
                  size="small"
                  className={`mr-1 ${
                    !formik.values.install_is_fixed && "!bg-primary !text-white"
                  }`}
                  onClick={() =>
                    formik.setValues((v: any) => ({
                      ...v,
                      install_is_fixed: false,
                    }))
                  }
                >
                  {tr("Multiply")}
                </OutlineButton>
              </div>
            </div>

            <div className="flex items-end justify-between space-x-2">
              {!formik.values.install_is_fixed ? (
                <>
                  <DollarInputV2
                    name="list_price"
                    style={{ width: "35%" }}
                    label={tr("List Price")}
                    disabled
                    formik={formik}
                  />
                  <span className="leading-10">x</span>
                  <Input
                    name="install_multiplier"
                    label={tr("Multiplier")}
                    style={{ width: "20%" }}
                    formik={formik}
                    type="number"
                  />
                  <span className="leading-10">=</span>
                  <DollarInputV2
                    label={tr("Installed Price")}
                    style={{ width: "35%" }}
                    value={
                      (formik.values.install_multiplier || 0) *
                      (formik.values.list_price || 0)
                    }
                    disabled
                  />
                </>
              ) : (
                <DollarInputV2
                  name="fixed_install_price"
                  style={{ width: "50%" }}
                  formik={formik}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-2">
        <span>{tr("Include in Commission")}</span>
        <Switch
          checked={formik.values.commission}
          onChange={(e) => formik.setFieldValue("commission", e)}
        />
      </div>
    </SForm>
  );
};

const SForm = styled.form`
  > * {
    margin: 1rem 0;
  }
`;
