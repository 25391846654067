/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Calendar from "./calendar";
import Card from "./card";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import moment from "moment";
import { differenceInDays } from "date-fns";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  // width: 100%;
  position: relative;
  text-align: left;
  > div:last-child {
    // border: 1px solid black;
    position: relative;
    background: #f9f9f9;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    overflow: auto;
    &::-webkit-scrollbar {
      background: #f9f9f9;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #f0f0f0;
    }
  }
  @media only screen and (min-width: 1500px) {
    &:before {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      content: "";
      height: 100%;
      width: 5rem;
      // background: linear-gradient(to right, rgb(255,255,255,0), white);
    }
  }
`;

const Sick = styled(Button)`
  align-self: flex-start;
  margin: 1rem;
`;

export default function () {
  const { t } = useTranslation();
  const tBase = "views.team.sick.index";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const history = useHistory();

  const [sick, setSick] = useState([
    { count: 0, data: [] },
    { count: 0, data: [] },
  ]);

  // const permission = useSelector( ({isAuth : {permission }}) => permission['/api/absent']['POST'])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/absent?type=SICKLIST`
        );
        let tabulation = [
          { count: 0, data: [] },
          { count: 0, data: [] },
        ];
        res.data[0].forEach((e) => {
          let {
            employee_id: id,
            full_name: name,
            sick_id: sickID,
            // sick_reason: reason,
            employee_job_title: title,
            start_date: start,
            end_date: end,
          } = e;
          let difference, type;
          start = moment(new Date(start));
          end = moment(new Date(end));

          if (moment().startOf("day").diff(start, "days") >= 0) {
            difference = `${
              moment().startOf("day").diff(start, "days") + 1
            } / ${end.diff(start, "days") + 1}`;
            type = 0;
          } else {
            difference = `0 / ${end.diff(start, "days") + 1}`;
            type = 1;
          }

          tabulation[type]["data"].push(
            <Card
              name={name}
              clicked={() =>
                history.push({
                  pathname: `/admin/employees/${id}/absence`,
                  from: "sick",
                })
              }
              image={res.data[1][id]}
              title={title}
              key={sickID}
              start={start.format("MMM-DD")}
              end={end.format("MMM-DD")}
              difference={difference}
            />
          );

          tabulation[type]["count"]++;
        });
        setSick(tabulation);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [history]);

  return (
    <Con>
      <Button
        style={{ flex: "0 0 auto", alignSelf: "flex-start" }}
        primary
        onClick={() => history.push("./absent/new")}
      >
        {tr("Add New Absence")}
      </Button>

      {/* { permission && <Sick onClick={() => history.push('./absent/new')}/>} */}
      <div>
        <Row>
          <h1 style={{ fontWeight: "normal", marginLeft: "1rem" }}>
            {tr("Ongoing")} |
            <span style={{ color: "grey", fontWeight: "lighter" }}>
              {" " + sick[0]["count"]}
              {sick[0]["count"] > 1 ? tr(" requests") : tr(" request")}
            </span>
          </h1>
          <div>{sick[0]["data"]}</div>
        </Row>
        <Row>
          <h1 style={{ fontWeight: "normal", marginLeft: "1rem" }}>
            {tr("Upcoming")} |
            <span style={{ color: "grey", fontWeight: "lighter" }}>
              {" " + sick[1]["count"]}{" "}
              {sick[1]["count"] > 1 ? tr(" requests") : tr(" request")}
            </span>
          </h1>
          <div>{sick[1]["data"]}</div>
        </Row>
      </div>
      <Calendar />
    </Con>
  );
}
