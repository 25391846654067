import { IChecklistWithResponses } from "features/requests/types/checklists";
import { AiFillCheckCircle, AiFillMinusCircle } from "react-icons/ai";

interface IProps {
  checklist: IChecklistWithResponses[];
}

export const ChecklistModalContent = ({ checklist }: IProps) => (
  <div className="flex flex-1 flex-col">
    {checklist?.map(({ is_done, label }) => {
      return (
        <div
          className={`shadow-lg border-slate-300 border flex flex-row flex-1 p-2 m-2 items-center rounded-lg  ${
            is_done ? `bg-green-500/20` : `bg-red-500/20`
          }`}
        >
          {is_done ? (
            <div className="mr-3">
              <AiFillCheckCircle color="green" size={20} />
            </div>
          ) : (
            <div className="mr-3">
              <AiFillMinusCircle color="gray" size={20} />
            </div>
          )}
          <div>{label}</div>
        </div>
      );
    })}
  </div>
);
