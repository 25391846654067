import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  background: black;
  color: white;
  height: calc(100vh - ${theme.height.navigation});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span {
    letter-spacing: 8px;
  }
  > p {
    font-size: 1.2rem;
  }
`;

export default function () {
  const { t } = useTranslation();
  const tBase = "views.nopermission.index";
  const tr = (key) => t(`${tBase}.${key}`);

  return (
    <Con>
      <h1>{tr("ERROR 403")}</h1>
      <span>{("ACCESS NOT GRANTED")}</span>
      <p>{tr("Please consult with the admin to gain access to the page.")}</p>
    </Con>
  );
}
