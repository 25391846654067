import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import { Button } from "components/button";
import Switch from "rc-switch";
import { GroupTemplateModal } from "./GroupTemplateModal";
import { useTranslation } from 'react-i18next';

type Props = {
  userId: number;
};

type mutateProps = {
  userId: number | string;
  routeId: string | number;
};

export default function PermittedFeatures(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.permission.components.PermittedFeatures";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const queryClient = useQueryClient();

  const routes = useQuery(
    ["routes", props.userId],
    async () =>
      await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutes/${props.userId}`
				)
        .then((res) => res.data)
  );

  const toggleRoute = useMutation(
    ["toggleroutes"],
    async (props: mutateProps) => {
      await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutes`,
				{
					userId: props.userId,
					routeId: props.routeId,
				}
			);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("routes");
      },
    }
  );

  const toggleRouteEdit = useMutation(
    ["toggleroutesEdit"],
    async (props: mutateProps) => {
      await axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/permissions/webroutesedit`,
        {
          userId: props.userId,
          routeId: props.routeId,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("routes");
      },
    }
  );

  const viewPermissions = (route: any) => {
    if (route.allowed) {
      return "none";
    } else {
      return "none";
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between items-center mb-4">
        <h2>{tr("Permitted Features")}:</h2>
        <Button
          primary
          onClick={() => {
            setModal({
              component: <GroupTemplateModal userId={props.userId} />,
              label: tr("Group Templates"),
            });
          }}
        >
          {tr("Apply Group Template")}
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
        {routes.data?.map((route: any) => (
          <div className="flex flex-row items-center border-b-2 border-gray-300 pb-2">
            <Switch
              className="min-w-[44px]" // 44px is the width of the switch
              checked={route.allowed}
              onChange={async () => {
                await toggleRoute
                  .mutateAsync({ userId: props.userId, routeId: route.id })
                  .then(() => {});
              }}
            ></Switch>
            <h4 className="ml-2">{route.name}</h4>

            {/* Start: Not being used currently */}
            <div style={{ display: viewPermissions(route), flex: "100%" }}>
              <h4 className="inline m-1"> Full Access</h4>
              <Switch
                checked={route.allowedEdit}
                onChange={async () => {
                  await toggleRouteEdit
                    .mutateAsync({ userId: props.userId, routeId: route.id })
                    .then(() => {});
                }}
              />
            </div>
            {/* End */}
          </div>
        ))}
      </div>
      
    </div>

  );
}
