import React from "react";
import styled from "styled-components";
import Info from "./userInfo";
import Demographic from "./userDetail";

const Con = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default function (props) {
  const id = props.match.params.id;
  const from = props.history.location.from || "";

  return (
    <Con>
      <Info id={id} />
      <Demographic id={id} from={from} />
    </Con>
  );
}
