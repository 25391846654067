/* eslint-disable import/no-anonymous-default-export */
/* Created By Hannah Green on 01/20/2021 for ES-58 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Popup from "../../../components/closablePopup";
import WarningForm from "./warningPopup";
import axios from 'axios';
import Select from 'react-select';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const Con = styled.div`
    width:100%
`
const Form = styled.form`
label {
    display: flex;
    flex-direction: column;
    padding: 15px;

    > input {
        float: right;
    }
}
`
const WarningPopup = styled(Popup)`
  padding: 1rem;
  width: 30%;
  height: 15%;
`;

export default function (props) {
    const { t } = useTranslation();
    const tBase = "views.category_management.components.form";
    const tr = (key) => t(`${tBase}.${key}`);

    //Props variables
    const { formType, categoryId, closePopup } = props;

    //State Variables
    const [categories, setCategories] = useState();
    const [categoryDropdown, setCategoryDropdown] = useState();
    const [form, setForm] = useState({
        'category_name': "",
        'root_category': false,
        'parent_category': "",
        'active': true,
        'is_parent_category': false,
        'is_service': false,
        'showWarning': false,
        'updateRootToSub': false
    });
    const [popupMessage, setPopupMessage] = useState();

    /* Database Functions */

    useEffect(() => {
        fetchData();
        if (formType === 'edit') {
            fetchCategory();
        }
    }, []);

    const fetchData = async () => {
        try {
            const cats = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category?type=ALLCATEGORIES`
            );
            setCategories(cats.data);
            let array = [];
            cats.data.forEach((category) => {
                array.push({ label: category.category_name, value: category.category_id });
            });
            setCategoryDropdown(array);
        } catch (e) {
            console.error(e);
        }
    }

    const fetchCategory = async () => {
        try {
            const category = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category?type=GETCATEGORY&category_id=${categoryId}`
            );
            setForm(category.data);
        } catch (e) {
            console.error(e);
        }
    }

    /* Handlers */
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setForm((state) => {
            const tempState = cloneDeep(state);
            tempState[name] = value;
            return tempState;
        });
    }

    const handleDropdown = (event) => {
        setForm((state) => {
            const tempForm = cloneDeep(state);
            const selected_parent_category = categories.find((cat) => {
                return cat.category_name === event.label;
            });

            if (selected_parent_category.parent_category !== null && selected_parent_category.is_parent_category === false) {
                tempForm.is_parent_category = true;
            } else {
                tempForm.is_parent_category = false;
            }

            if (formType === 'edit') {
                setPopupMessage(tr("If you move this root category to a sub-category all the current sub-categories will become root categories."));
                if (selected_parent_category.parent_category === null) {
                    tempForm.showWarning = true;
                    tempForm.updateRootToSub = true;
                }
            }

            tempForm.parent_category = event;
            tempForm.root_category = false;
            return tempForm;
        });
    }

    const handleCheckboxChange = (event) => {
        const target = event.target;
        const name = target.name;

        setForm((state) => {
            const tempForm = cloneDeep(state);
            tempForm[name] = target.checked;
            if (name === "root_category" && target.checked) {
                tempForm.parent_category = { label: null, value: null }
            }
            return tempForm;
        });
    }

    /* Submit Things */
    const submitCategory = async () => {
        try {

            if (form.root_category !== false || form.parent_category.label !== null) {
                if (formType === "edit") {
                    if (form.updateRootToSub) {
                        await axios.post(
                            `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category`,
                            {
                                type: "UPDATEROOTTOSUB",
                                form,
                                categoryId
                            }
                        )
                    } else {
                        await axios.post(
                            `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category`,
                            {
                                type: "UPDATECATEGORY",
                                form,
                                categoryId
                            }
                        )
                    }
                } else {
                    await axios.post(
                        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/category`,
                        {
                            type: "NEWCATEGORY",
                            form
                        }
                    )
                }
            } else {
                setPopupMessage(tr("A category must have a parent or be marked as a root category"));

                setForm((state) => {
                    const tempForm = cloneDeep(state);
                    tempForm.showWarning = true;
                    return tempForm;
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Con>
            <Form>
                <label>
                    {tr("Category Name")}
                    <input
                        type="text"
                        name="category_name"
                        value={form.category_name}
                        onChange={handleInputChange} />
                </label>

                <label>
                    {tr("Parent Category")}
                    <Select
                        options={categoryDropdown}
                        onChange={handleDropdown}
                        value={form.parent_category}
                    />
                </label>

                <label>
                    {tr("Root Category")}
                    <input
                        type="checkbox"
                        name="root_category"
                        checked={form.root_category}
                        onChange={handleCheckboxChange}
                    />
                </label>

                <label>
                    {tr("Is Service Category")}
                    <input
                        type="checkbox"
                        name="is_service"
                        checked={form.is_service}
                        onChange={handleCheckboxChange}
                    />
                </label>

                <label>
                    {tr("Active")}
                    <input
                        type="checkbox"
                        name="active"
                        checked={form.active}
                        onChange={handleCheckboxChange}
                    />
                </label>
                <div>
                    <input type="button" value="Save" onClick={() => {
                        try {
                            submitCategory();
                            if (!form.showWarning) {
                                closePopup();
                            }
                            window.location.reload();
                        } catch (e) {
                            console.error(e);
                        }
                    }} />
                </div>
            </Form>
            {form.showWarning && <WarningPopup cancelDisplay={() => { }}>
                <WarningForm message={popupMessage} close_popup={() => {
                    setForm((state) => {
                        const tempForm = cloneDeep(state);
                        tempForm.showWarning = false;
                        return tempForm;
                    });
                }} />
            </WarningPopup>}
        </Con>
    )

}
