import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import ToolTip from "./toolTip";
import { useTranslation } from 'react-i18next';

const ModalCon = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	overflow: auto;
`;

const Con = styled.div`
	text-align: left;
	flex: 1;
	max-width: 1700px;
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

const Title = styled.h2``;

const DetailCon = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1 1 auto;
	overflow: auto;
	margin-top: 1rem;
	// grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const FilterCon = styled.div`
	display: flex;
	flex-direction: row;
`;

const Filter = styled.div`
	margin: 0 1rem 0 0;
	padding: 0.5rem 1rem 0.5rem 1rem;
	background: #f0f0f0;
	border-radius: 10px;
	cursor: pointer;
`;

const AssignedContainer = styled.div`
	padding: 0.5rem;
	margin: 0 0 0.5rem 0;
	border-radius: 10px;
`;

const AssignedPerson = styled.div`
	font-weight: bold;
	font-size: 0.9rem;
`;

const AssignedDetail = styled.div`
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 0.2rem;
	overflow: auto;
`;

export default function UnscheduledModal ({ match }) {
	const { t } = useTranslation();
	const tBase = "views.calendar.unscheduledModal";
	const tr = (key) => t(`${tBase}.${key}`);

	const history = useHistory();

	const [filter, setFilter] = useState("");
	const [appointments, setAppointments] = useState([]);
	const [toolTipModal, setToolTipModal] = useState(false);

	const fetchData = async () => {
		try {
			const res = await Axios.get(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/all?grid=unscheduled`
			);
			setAppointments(res.data);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const refreshPage = () => fetchData();

	const formattedData = appointments.map(({ id, assignee, appointment }) => {
		return (
			<AssignedContainer key={id}>
				<AssignedPerson>
					<div>{assignee || tr("Unassigned")}</div>
				</AssignedPerson>
				<AssignedDetail>
					{appointment[0] &&
						appointment.map((info) => {
							return (
								<EachAppointment
									key={info.id}
									handleAppointmentClick={setToolTipModal}
									{...{ info }}
								/>
							);
						})}
				</AssignedDetail>
			</AssignedContainer>
		);
	});

	return (
		<ModalCon>
			<Con>
				<Title>{tr("Appointments")}</Title>
				<FilterCon>
					<Filter onClick={() => setFilter("Scheduled")}>
						{tr("Scheduled")}
					</Filter>
					<Filter onClick={() => setFilter("Unscheduled")}>
						{tr("Unscheduled")}
					</Filter>
					<Filter onClick={() => setFilter("Unassigned")}>
						{tr("Unassigned")}
					</Filter>
					<Filter onClick={() => setFilter("Completed")}>
						{tr("Completed")}
					</Filter>
					{filter !== "" && (
						<Filter onClick={() => setFilter("")}>{tr("Cancel")}</Filter>
					)}
				</FilterCon>
				<DetailCon>{formattedData}</DetailCon>
			</Con>
			<ToolTip
				{...{ toolTipModal, refreshPage }}
				cancelDisplay={() => setToolTipModal(false)}
			/>
		</ModalCon>
	);
}

const EachAppointmentCon = styled.div`
	padding: 0.5rem;
	position: relative;
	margin: 0.25rem 0.5rem 0.25rem 0;
	border-radius: 5px;
	display: flex;
	flex: 0 0 auto;
	flex-direction: column;
	cursor: pointer;
	overflow: hidden;
	width: 250px;
	background: #f0f0f0;
	&:hover {
		box-shadow: 0 0 10px -5px grey;
	}
	> div:first-child {
		font-size: 1.1rem;
	}
`;

const EachAppointment = ({ handleAppointmentClick, info }) => {
	const { status, id, note, description, customer } = info;
	const containerColor = `rgba(${status.color})`;

	return (
		<EachAppointmentCon onClick={() => handleAppointmentClick({ id: id })}>
			<div>{customer}</div>
			<div>
				{description} &#183;{" "}
				<span
					style={{
						background: containerColor,
						color: "white",
						fontSize: "0.7rem",
						padding: "0 0.5rem",
						fontWeight: "bold",
						lineHeight: 1,
					}}
				>
					{status.label}
				</span>
			</div>
			<div>{note}</div>
		</EachAppointmentCon>
	);
};
