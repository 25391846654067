import React, { useEffect } from "react";
import styled from "styled-components";
import QuestionTopBar from "./topbar";
import Sidebar from "./sidebar";
import { useParams } from "react-router-dom";

import EachQuestion from "./question/index";
import { QuestionBuilderProvider, useQuestionBuilderState } from "./context";
import { ContainerWithSidebar } from "components/layout";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  flex: 1;
  // align-items: flex-start;
`;

export default function () {
  const params = useParams<{ id: string }>();
  return (
    <ContainerWithSidebar>
      <QuestionBuilderProvider>
        <Sidebar />
        <QuestionContent>
          {params?.id && (
            <>
              <QuestionTopBar />
              <QuestionMainContent />
            </>
          )}
        </QuestionContent>
      </QuestionBuilderProvider>
    </ContainerWithSidebar>
  );
}

const QuestionContent = styled.div`
  flex: 1;
  background: white;
  overflow: auto;
`;
const QuestionMainContentCon = styled.div`
  padding: 1rem;
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  // overflow: auto;
`;

const QuestionMainContent = () => {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { questions, selectedQuestion } = useQuestionBuilderState();

  let isDatePicker:any
  if (questions) {
    isDatePicker = questions.find(question => question.type === "CALENDAR") ? true : false
  }

  const populateQuestions = questions?.map((question, i) => {
    const isSelected = i === selectedQuestion;

    return (
      <EachQuestion key={i} index={i} selected={isSelected} datePicker={isDatePicker} {...question} />
    );
  }) || <span className="py-20 text-slate-600">{tr("Empty")}</span>;

  return <QuestionMainContentCon>{populateQuestions}</QuestionMainContentCon>;
};
