import { useForm, SubmitHandler } from "react-hook-form";
import { Form } from "components/_ui/react-hook-form/Form";
import React, { useState, useEffect } from "react";
import { useSnackbarContext } from "components/snackbar";
import { ITags } from "./interfaces/ITags";
import { useTagsAddMutation } from "../hooks/useTagsAddMutation";
import { InputSubmit } from "components/_ui/react-hook-form/InputSubmit";
import { InputText } from "components/_ui/react-hook-form/InputText";
import { SubmitButtonRow } from "components/_ui/react-hook-form/SubmitButtonRow";
import { useModalContext } from "components/modal";
import Tab  from "components/tab";
import { CirclePicker } from "react-color";
import { getContrastTextColorHEX } from "utils/getContrastTextColor";
import { useTranslation } from 'react-i18next';
import { 
  blockColors,  
  circleColors,
  compactColors,
  ghColors,
  sketchColors,
  twitterColors,
} from "styles/theme/colors";

// Form for creating a new tag
const NewTag = () => {
  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();
  const { mutateAsync, isLoading, isError, isSuccess } = useTagsAddMutation();
  const [, setColor] = useState("gray");
  const [selectedColors, setSelectedColors] = useState(blockColors);
  const { t } = useTranslation();
  const tBase = "views.admin.tags.components.NewTag";
  const tr = (key: string) => t(`${tBase}.${key}`);

  // Bring in the hook for react-hook-form
  const newTagForm = useForm<ITags>({
    defaultValues: {
      id: 0,
      label: "",
      fk_business_id: 0,
      color: "",
      created_at: new Date().toLocaleDateString(),
      updated_at: new Date().toLocaleDateString(),
      active: true,
    },
  });

  const { setValue, watch } = newTagForm; // destructure from hook
  const colorValue = watch("color"); // watch color input
  const labelText = watch("label"); // watch label input

  // Function to handle color change
  const handleChangeColor = (newColor: any) => {
    setColor(newColor.hex);
    setValue("color", newColor.hex);
  };

  // Function to handle form submission
  const onSubmit: SubmitHandler<ITags> = async (
    newTag: ITags
  ) => {
    // Submit form changes to the API
    await mutateAsync(newTag);
  };

  // Show a snackbar when the tag is successfully created
  const onSuccessChange = () => {
    if (isSuccess) {
      showSnackbar(tr("Tag created successfully."));
      setModal();
    }
  };

  // Run the onSuccessChange function when the isSuccess value changes
  useEffect(() => {
    onSuccessChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // Handles tab click
  const handleTabClick = (key: string) => {
    switch (key) {
      case "block":
        setSelectedColors(blockColors);
        break;
      case "circle":
        setSelectedColors(circleColors);
        break;
      case "compact":
        setSelectedColors(compactColors);
        break;
      case "gh":
        setSelectedColors(ghColors);
        break;
      case "sketch":
        setSelectedColors(sketchColors);
        break;
      case "twitter":
        setSelectedColors(twitterColors);
        break;
      default:
        setSelectedColors(blockColors);
    }
  };

  const tabData = {
    block: { tab: tr("Block Colors") },
    circle: { tab: tr("Circle Colors") },
    compact: { tab: tr("Compact Colors") },
    gh: { tab: tr("GH Colors") },
    sketch: { tab: tr("Sketch Colors") },
    twitter: { tab: tr("Twitter Colors") },
  };

  return (
    <div className="w-[660px]">
      <Form form={newTagForm} onSubmit={onSubmit}>
        <div className="flex space-x-10">
          <div className="w-1/2">
            <InputText 
              name={tr("Label")} 
              form={newTagForm} 
              required
            />
          </div>

          <div>
            <div className="ml-[2px] text-slate-600 text-sm">
              {tr("Preview")}
            </div>
            <div  
              className="text-xs p-1 space-x-2 rounded "
              style={{
                background: colorValue,
                color: getContrastTextColorHEX(colorValue),
            }}>
              {colorValue ?
                (labelText || tr("Example Tag")) 
                : (
                <span className="italic text-slate-400">{tr("No color selected")}</span>
                )}
            </div>
          </div>
        </div>

        <Tab 
          data={tabData} 
          onClick={handleTabClick} 
          defaultTab="block" 
        />
        
        <CirclePicker
          color={colorValue}
          colors={selectedColors}
          onChangeComplete={handleChangeColor}
          className="mt-4 min-h-[130px]"
          width="100%"
        />

        <SubmitButtonRow>
          <InputSubmit isError={isError} isLoading={isLoading} />
        </SubmitButtonRow>
      </Form>
    </div>
  );
};

export { NewTag };
