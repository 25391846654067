import * as React from "react";
import styled from "styled-components";
import { Button, SubtleButton, SubtleIconButton } from "components/button";
import { FileUpload } from "components/file-upload";
import { useMutation, useQuery } from "react-query";
import Axios from "axios";
import { generateThumbnail } from "hooks/thumbnail";
import { MdImage, MdPictureAsPdf, MdMoreVert } from "react-icons/md";
import { useSnackbarContext } from "components/snackbar";
import Popper from "components/react-popper";
// consider making placeholder a base component that can be easily imported by all files
import Placeholder from "../request/component/placeholder";
import Spinner from "components/spinner";
import DropBox from "views/customer/component/dropBox";
import { useAuthContext } from "contexts/auth";
import PropertyRequestFiles from "./component/propertyRequestFiles";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';

type Props = {
	id: string;
	type: string;
};

const PropertyFiles = (props: Props) => {
	const { t } = useTranslation();
	const tBase = "views.property.property-files";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const { id, type } = props;
	const canvasRef = React.useRef<HTMLCanvasElement>(null);
	const { showSnackbar } = useSnackbarContext();
	
	const [maxHeight, setMaxHeight] = React.useState(350);
	const [maxWidth, setMaxWidth] = React.useState(300);
	const { name } = useAuthContext();

	const files = useQuery(
		["propertyFiles", type, id],
		async () =>
			await Axios.get(
				`
                    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}
                `
			).then((res) => res.data),
		{
			refetchOnWindowFocus: false,
		}
	);

	const sendFileMutation = async (files: any) => {
		for (let i = 0; i < files.length; i++) { 
			const formData = new FormData();
			formData.append("file", files[i]);
			let thumbnail;
			if (files[i].type.includes("image")) {
				thumbnail = await generateThumbnail(canvasRef, files[i], "image");
			} else if (files[i].type === "application/pdf") {
				thumbnail = await generateThumbnail(canvasRef, files[i], "pdf");
			} else {
				throw new Error("no");
			}
	
			formData.append("thumbnail", thumbnail);
			formData.append("type", "PropertyFile");
			formData.append("filetype", files[i].type);
			formData.append("filesize", files[i].size);
			formData.append("user", name);
	
			return await Axios.post(
				`
					${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}
				`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		}
	};

	const sendFile = useMutation(sendFileMutation, {
		onSuccess: () => {
			files.refetch();
			showSnackbar(tr("File Uploaded!"));
		},
	});

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;

		if (files) {
			sendFile.mutate(files);
		}
	};

	const handleDropChange = async (file: any) => {
		let fileArr: any = [];
		let push: boolean;
		if (file) {
		// checks new files against existing files, doesn't send file if duplicate, which means a new record is no longer created in the database
		for (let i = 0; i < file.length; i++) {
			// all files are pushed to upload unless a duplicate is found
			push = true;
			for (let o = 0; o < files.data.length; o++) {
			if (file[i].name === files.data[o].Key) {
				push = false;
			}
			}
			if (push) {
			fileArr.push(file[i]);
			}
		}

		if (fileArr[0]) {
			sendFile.mutate(fileArr);
		}
		}
	};

	React.useEffect(() => {
		// needs to be run once to set inital size
		imageSizeChanged(null);

		// these two event listeners prevent the default window behavior of opening a new tab whenever an image is dropped onto the window
		// which is useful for when the user mistakenly drops the image OUTSIDE of the DropBox zone
		document.getElementById("propertyFilesPage")?.addEventListener(
			"dragover",
			(event) => {
				event = event || event;
				event.preventDefault();
			},
			false
		);
		document.getElementById("propertyFilesPage")?.addEventListener(
			"drop",
			(event) => {
				event = event || event;
				event.preventDefault();
			},
			false
		);
	}, []);

	const imageSizeChanged = (e: React.ChangeEvent<HTMLSelectElement> | null) => {
		// 50% size is the default
		let divisor = 0.5;
		if (e) {
		divisor = parseFloat(e.target.value);
		}
		setMaxHeight(350 * divisor);
		setMaxWidth(300 * divisor);
	};

	// icons for excel and other file formats
	// const [icon, setIcon] = React.useState<any>();

	// const getIcon = async () => {
	// 	await Axios.get(
	// 	`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/icons/customerIcons`
	// 	).then((res) => setIcon(res.data));
	// };

	const populateFilesGrid =
		files.data &&
		files.data.map((file: any) => {
			return <FileGrid
				maxHeight={maxHeight + `px`}
				maxWidth={maxWidth + `px`}
				key={file.Key} 
				{...{ file, id, type }} 
				refetch={() => files.refetch()} 
			/>;
		});

	return (
		<div className="space-y-4" id="propertyFilesPage">
			<div
				className="py-4 flex justify-between items-center"
				style={{
				borderBottomWidth: "1px",
				borderBottomColor: "black",
				minWidth: "550px",
				}}
			>
				<h2 style={{ padding: "5px" }}>Property Files</h2>
				<div>
				<label style={{ marginRight: "5px", fontSize: "20px" }}>
					{tr("Change Image Size")}
				</label>
				<select
					style={{ borderWidth: "2px", height: "50px" }}
					onChange={(e) => imageSizeChanged(e)}
				>
					<option value=".25">25%</option>
					<option value=".5" selected>
					50%
					</option>
					<option value="1">100%</option>
				</select>
				</div>
				<FileUpload
					multiple
					accept="image/*, application/pdf"
					component={<Button isLoading={sendFile.isLoading}>{tr("Upload File")}</Button>}
					onChange={handleChange}
				/>
			</div>
			<div>
				{files.isFetching ? (
					<Spinner size={40} />
				) : files.data ? (
					<div style={{ display: "inline-flex", width: "100%", justifyContent:"space-between"}}>
						<Grid>{populateFilesGrid}</Grid>
						<DropBox onChange={handleDropChange} />
					</div>
				) : (
					<div
						style={{
							display: "inline-flex",
							width: "100%",
							justifyContent: "center",
							alignItems: "baseline",
						}}
					>
						<Placeholder
							type="file"
							header={tr("No files attached")}
							label={tr("Uploaded files will be shown here.")}
						/>
						<DropBox onChange={handleDropChange} />
					</div>
				)}

				<PropertyRequestFiles
					propertyId={id}
					styles={{
						Grid,
						FileGridCon,
						Con,
						maxHeight: maxHeight, 
						maxWidth: maxWidth,
					}}
				/>
				<canvas style={{ display: "none" }} ref={canvasRef} />
			</div>
		</div>
	);
};

const Con = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		margin-top: 1rem;
	}
`;

const Grid = styled.div`
	display: inline-flex;
	align-content: flex-start;
	flex-wrap: wrap;
	/* background-color: #fef6e0; */
	width: 75%;
`;

const FileGridCon = styled.a`
	border: 1px solid #c0c0c0;
	height: 350px;
	width: 300px;
	margin-right: 5px;
	margin-top: 5px;
	min-height: 25px;
	max-height: 350px;
	min-width: 125px;
	max-width: 300px;
	border-radius: 3px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	> div:first-child {
		flex: 1;
		width: 100%;
		overflow: hidden;
	}
	> div:last-child {
		padding: 0.25em 0.5em;
		flex: nowrap;
		flex: 0 0 auto;
		font-size: 0.8rem;
		display: flex;
		align-items: center;
		> svg {
			flex: 0 0 auto;
			font-size: 1.1rem;
			margin-right: 0.5em;
		}
		> span {
			font-weight: 600;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			flex: 1;
		}
	}
`;

// const Con = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	> div {
// 		margin-top: 1rem;
// 	}
// `;

// const Grid = styled.div`
// 	display: grid;
// 	gap: 0.5rem;
// 	grid-template-columns: repeat(2, 1fr);
// 	justify-content: center;
// `;

// const FileGridCon = styled.a`
// 	border: 1px solid #c0c0c0;
// 	height: 250px;
// 	min-height: 250px;
// 	max-height: 250px;
// 	border-radius: 3px;
// 	overflow: hidden;
// 	display: flex;
// 	flex-direction: column;
// 	> div:first-child {
// 		flex: 1;
// 		width: 100%;
// 		overflow: hidden;
// 	}
// 	> div:last-child {
// 		padding: 0.25em 0.5em;
// 		flex: nowrap;
// 		flex: 0 0 auto;
// 		font-size: 0.8rem;
// 		display: flex;
// 		align-items: center;
// 		> svg {
// 			flex: 0 0 auto;
// 			font-size: 1.1rem;
// 			margin-right: 0.5em;
// 		}
// 		> span {
// 			font-weight: 600;
// 			overflow: hidden;
// 			white-space: nowrap;
// 			text-overflow: ellipsis;
// 			flex: 1;
// 		}
// 	}
// `;

const FileGrid = ({
	maxHeight,
	maxWidth,
	file,
	id,
	type,
	refetch,
}: {
	maxHeight: string;
	maxWidth: string;
	file: any;
	id: string;
	type: string;
	refetch: () => void;
}) => {
	const { t } = useTranslation();
	const tBase = "views.property.property-files";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const { showSnackbar } = useSnackbarContext();
	const { setModal } = useModalContext()
	const fileType = file.Key.includes("pdf") ? "pdf" : "image";

	// const showFilenameInput = () => {
	//   setToggle(!toggle)
	//
	//   if (toggle) {
	//     setShowInput("")
	//   }
	//   else {
	//     setShowInput("none")
	//   }
	//   getFile.mutate()
	//
	// }

	const [currentInput, setCurrentInput] = React.useState("");
	const [showInput, setShowInput] = React.useState("none");
	const [toggle, setToggle] = React.useState(true);

	React.useEffect(() => {
		// console.log(currentInput);
	}, [currentInput]);

	const confirmChoice = (e: any) => {
		if (window.confirm(tr("Confirm Deletion"))) {
		deleteFile.mutate();
		}
		e.stopPropagation();
	};

	const handleInputChange = (e: any) => {
		setCurrentInput(e.target.value);
	};

	const handleFilenameChange = (e: any) => {
		// console.log(currentInput);
		e.preventDefault();
		// console.log(e.target.value)
		setShowInput("none");
		setToggle(true);
	};

	// axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customer-files/latent/des/alterFilename`,
	//     {
	//       newFilename: currentInput,
	//       oldFilename: modal?.label
	//     }
	//   )
	//   .then(()=>{console.log("success")})

	const getPNG = useMutation(
		async () =>
		await Axios.get(
			`${
			process.env.REACT_APP_SERVER_URL
			}/api/v1/company/files/${type}/${id}/${encodeURIComponent(
			file.Key
			)}`
		).then((res) => {
			return res.data;
		}),
		{
		onSuccess: (data) => {
			setModal({
			component: (
				<>
				<Con>
					<img
					src={data}
					style={{ maxWidth: "500px", maxHeight: "500px" }}
					alt="file"
					></img>

					{/* <label>
						Please change the name of file:
						</label>
						<div>
						<Input onChange={(e)=>handleInputChange(e)} placeholder="New File Name"/>
						</div>
						<div>
						<Button primary>
							Confirm
						</Button>
						<Button style={{float:"right"}}>
							Cancel
						</Button>
						</div> */}
				</Con>
				</>
			),
			label: file.Key,
			// headerButtons:
			// <IconButton>
			//   <button onClick={()=>showFilenameInput()} style={{maxWidth:"25px", maxHeight:"25px"}}>
			//     <img src={icon}/>
			//   </button>
			// </IconButton>,
			customerFile: true,
			// icon: icon,
			editName: false,
			// displayInput: showInput,
			// formats the modal header for proper spacing between elements
			displayStyle: "inline-grid",
			displayGridStyle:
				"[First] 400px [Line2] auto [Line3] 5px [Line4] 40px",
			handleFilenameChange: handleFilenameChange,
			handleInputChange: handleInputChange,
			// showFilenameInput: showFilenameInput,
			toggle: toggle,
			showInput: showInput,
			});
		},
		}
	);

	const getFile = (filename: any) => {
		if (filename.includes("pdf")) {
			Axios.get( `${ process.env.REACT_APP_SERVER_URL }/api/v1/company/files/${type}/${id}/${encodeURIComponent( file.Key )}`
			).then(
				(res) =>
				window.open(res.data, "_blank") || window.location.replace(res.data)
			);
		} else {
			getPNG.mutate();
		}
	};


	// const getFile = useMutation(
	// 	async () =>
	// 		await Axios.get(
	// 			`
    //                 ${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}/${encodeURIComponent(
	// 				file.Key
	// 			)}
    //             `
	// 		).then((res) => res.data),
	// 	{
	// 		onSuccess: (data) => {
	// 			window.open(data, "_blank") || window.location.replace(data);
	// 		},
	// 	}
	// );

	const deleteFile = useMutation(
		async () =>
			await Axios.delete(`
                ${process.env.REACT_APP_SERVER_URL}/api/v1/company/files/${type}/${id}/${encodeURIComponent(
				file.Key
			)}
            `),
		{
			onSuccess: () => {
				refetch();
				showSnackbar(tr("File deleted!"));
			},
		}
	);

	return (
		<FileGridCon
			target="_blank"
			onClick={() => getFile(file.Key)}
			style={{ width: "100%", maxHeight: maxHeight, maxWidth: maxWidth }}
		>
			<div>
				<img src={file.signedUrl} style={{ width: "100%", objectFit: "cover", height: "100%" }} />
			</div>
			<div>
				{fileType === "pdf" ? (
					<MdPictureAsPdf style={{ color: "red" }} />
				) : (
					<MdImage style={{ color: "green" }} />
				)}

				<span>{file.Key}</span>
				<Popper
					clickable
					placement="bottom-end"
					reference={
						<SubtleIconButton size="small">
							<MdMoreVert />
						</SubtleIconButton>
					}
				>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<SubtleButton
							isLoading={deleteFile.isLoading}
							onClick={(e) => {
								e.stopPropagation();
								deleteFile.mutate();
							}}
						>
							{tr("Delete")}
						</SubtleButton>
					</div>
				</Popper>
			</div>
		</FileGridCon>
	);
};

export default PropertyFiles;
