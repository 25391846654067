import * as React from "react";
import {
  RiArrowRightLine,
  RiArrowRightSLine,
  RiArrowRightUpLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import styled, { StyledComponent } from "styled-components";
import { theme } from "styles/theme";

type LinkProps = {
  label: string;
  to: string;
};
type Props = {
  link: LinkProps[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function BreadCrumbs(props: Props) {
  const { link, ...rest } = props;

  const populateLinks = props.link.map((link) => (
    <React.Fragment key={link.label}>
      <Link to={link.to}>{link.label}</Link>
      <RiArrowRightSLine />
    </React.Fragment>
  ));
  return <Con {...rest}>{populateLinks}</Con>;
}

const Con = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  > a {
    line-height: 1;
    text-decoration: none;
    color: ${theme.color.subtext3};
    &:hover {
      text-decoration: underline;
      color: ${theme.color.main};
    }
  }
  > svg {
    margin: 0 0.5rem;
  }
`;
