import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

import { Button } from "components/button";

import "./download-appointment.css";

function DownloadAppointments() {
  const [date, setDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const tBase = "views.appointments.download-apptointment";
  const tr = (key) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const fetchAppointments = async () => {
    try {
      const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const isoDate = date.toISOString();

      axios({
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/appointmentReportByDate`,
        method: "GET",
        responseType: "blob",
        params: {
          date: isoDate,
          timezone: clientTz,
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Appointments-${isoDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.error(error);
    }
  };

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: 300,
      height: 400,
    },
  };

  return (
    <>
      <Button
        size="small"
        style={{ marginLeft: 10 }}
        onClick={() => {
          openModal();
        }}
      >
        {tr("Download")}
      </Button>

      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel={tr("Choose Date")}
        style={customStyles}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <DatePicker
            type="date"
            name="date"
            placeholder={tr("Date")}
            value={date.toLocaleDateString()}
            onChange={(e) => {
              setDate(e);
            }}
            wrapperClassName="download-appointment-input"
            locale={locale}
          />
          <Button
            onClick={() => {
              fetchAppointments();
            }}
          >
            {tr("Download")}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default DownloadAppointments;
