import React, { useState } from "react";
import BreadCrumbs from "components/breadcrumbs";
import { history } from "routes";
import { useLocation, Redirect } from "react-router-dom";
import { OutlineButton } from "components/button";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import UpdateCardForm from "../components/updateCardForm";
import { Button } from "components/button";
import axios from "axios";
import Spinner from "components/spinner";
import { useTranslation } from 'react-i18next';

const UpdateSubscription = () => {
  const { t } = useTranslation();
  const tBase = "views.subscription.admin.updateSubscription";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const location = useLocation() as any;
  const subData = location.state.subscription;
  const [plan, setPlan] = React.useState<string>(subData.plan_name);
  // const [savePlan, setSavePlan] = React.useState<boolean>(false);
  const [isExclusive, setIsExclusive] = React.useState<boolean>(false);
  const [showCardInput, setShowCardInput] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (plan === "Exclusive") setIsExclusive(true);
    else setIsExclusive(false);
  }, [plan]);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

  const updateValue = (e: any) => {
    // console.log(e.target.value)
  };

  type PlanProps = {
    plan_name: string;
    total_price: number;
    date_end: Date;
    duration: string;
    stripe_customer_id: string;
  };

  type BasePlanProps = {
    [key: string]: {
      name: string;
      price: number;
      duration: string;
    };
  };

  type PlanHistoryProps = {
    plan_name: string;
    total_price: number;
    date_start: string;
    duration: string;
  };

  const [planInfo, setPlanInfo] = React.useState<PlanProps>();
  const [basePlanInfo, setBasePlanInfo] = React.useState<BasePlanProps[]>();
  const [planEndDate, setPlanEndDate] = React.useState<string>();
  const [planHistory, setPlanHistory] = React.useState<PlanHistoryProps[]>();
  const [cardInfo, setCardInfo] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription`, {
        params: {
          type: "subscription",
        },
      })
      .then((res) => {
        setPlanInfo(res.data.subscription);
        setBasePlanInfo(res.data.basePlan);
        if (res.data.subscription !== undefined) {
          let formattedDate = new Date(
            res.data.subscription.date_end
          ).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
          setPlanEndDate(formattedDate);
        }
        setPlanHistory(res.data.history);
        setCardInfo(res.data.card);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    <Spinner />;
  }

  if (location.state === undefined) {
    //! redirects to a page with sensitive information
    return <Redirect to="/admin/subscriptionAdmin" />;
  }

  // const result = basePlanInfo && basePlanInfo.filter((plan:any) => plan.name === plan)
  // const result = basePlanInfo && Object.keys(basePlanInfo).filter((key:any) => basePlanInfo[key].name === plan)
  // console.log(basePlanInfo && Object.keys(basePlanInfo))

  // console.log(basePlanInfo);

  return (
    <div>
      <BreadCrumbs
        link={[{ label: tr("Admin Panel"), to: "/admin/subscriptionAdmin" }]}
      />
      <div style={{ textAlign: "center", width: "40em", margin: "auto" }}>
        <h1 style={{ marginBottom: "20px" }}>
          {tr("Update Subscription for")} {subData.business_name}
        </h1>
        <form onClick={(e) => e.preventDefault()}>
          <div>
            <p>
              Cost:
              {/* <p style={planInfo && {textDecoration:"line-through"}}>${result}</p> + Addons */}
            </p>
            {/* <p style={{fontSize:"20px"}}>Total: ${total}.00</p> */}
            <div>
              <OutlineButton
                size="regular"
                $selected={"Essential" === plan}
                onClick={() => setPlan("Essential")}
              >
                {tr("Essential")}
              </OutlineButton>
              <OutlineButton
                size="regular"
                $selected={"Enhanced" === plan}
                onClick={() => setPlan("Enhanced")}
              >
                {tr("Enhanced")}
              </OutlineButton>
              <OutlineButton
                size="regular"
                $selected={"Exclusive" === plan}
                onClick={() => setPlan("Exclusive")}
              >
                {tr("Exclusive")}
              </OutlineButton>
            </div>
            {isExclusive && (
              <div
                style={{
                  display: "inline-grid",
                  gridTemplateColumns: "60% 20% 20%",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "inline-grid",
                    gridTemplateRows: "repeat(4, 50px)",
                    alignItems: "center",
                  }}
                >
                  <p id="appointmentRouting">{tr("Appointment Routing")}</p>
                  <p id="serviceLocation">{tr("Service Location Pin Drops")}</p>
                  <p id="inventoryCustom">{tr("Inventory Custom Add on")}</p>
                  <p id="incentiveTracking">
                    {tr("Production, Goals, and Incentives Tracking")}
                  </p>
                </div>
                <div
                  style={{
                    display: "inline-grid",
                    gridTemplateRows: "repeat(4, 50px)",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ width: "25px" }}
                    value="appointmentRouting"
                    onClick={(e) => updateValue(e)}
                  />
                  <input
                    type="checkbox"
                    value="serviceLocation"
                    onClick={(e) => updateValue(e)}
                  />
                  <input
                    type="checkbox"
                    value="inventoryCustom"
                    onClick={(e) => updateValue(e)}
                  />
                  <input
                    type="checkbox"
                    value="incentiveTracking"
                    onClick={(e) => updateValue(e)}
                  />
                </div>
                <div
                  style={{
                    display: "inline-grid",
                    gridTemplateRows: "repeat(4, 50px)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>$75</p>
                  <p>$50</p>
                  <p>$100</p>
                  <p>$200</p>
                </div>
              </div>
            )}
          </div>
          <Button
            primary
            style={{
              display: "block",
              marginTop: "20px",
              marginRight: "0",
              marginLeft: "auto",
            }}
            type="submit"
            onClick={handleFormSubmit}
            disabled={subData.plan_name === plan}
          >
            {tr("Save")}
          </Button>
        </form>
        <h2 style={{ marginBottom: "20px" }}>Customer Payment Details</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, auto)",
            justifyContent: "space-around",
            width: "40em",
            margin: "auto",
          }}
        >
          <div>
            <p>{tr("Customer Name")}: </p>
            <p>
              Payment Card:
              <Button onClick={() => setShowCardInput(!showCardInput)}>
                {showCardInput ? tr("Hide") : tr("Show")}
              </Button>
            </p>
          </div>
        </div>
        <div></div>
        {showCardInput && (
          <Elements stripe={stripePromise}>
            <UpdateCardForm customerId={subData.stripe_customer_id} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default UpdateSubscription;
