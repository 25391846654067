import React from "react";
import Form from "components/form";
import CustomerForm2 from "views/requests/newRequestModal/customerForm2";
import NewCustomerSimplified from "containers/newCustomerSimplified";
import Questions from "views/requests/newRequestModal/questions";
// import Questio

const UnassignedRequestForm = () => {
	const [page, setPage] = React.useState<0 | 1 | 2>(0),
		[customerId, setCustomerId] = React.useState<string | null>(),
		[address, setAddress] = React.useState<number>(),
		[managers, setManagers] = React.useState<
			{ value: string; label: string }[] | null
		>();

	const submitForm = () => {
		// console.log("form submitted")
	};

	const handleNext = () => {
		// needed because NewCustomerSimplified requires this function and I don't want to change the component to accomodate the UnassignedRequestForm
		return null;
	};

	const handleCustomerSelect = (e: any) => {
		setCustomerId(e);
		setPage(1);
	};

	return (
		<Form
			style={{ width: "30em" }}
			initialValues={{ qty: 1 }}
			onSubmit={submitForm}
		>
			{page === 0 ? (
				<CustomerForm2 onChange={handleCustomerSelect} />
			) : page === 1 ? (
				<NewCustomerSimplified
					// id={customerId}
					// requestId={props.requestId}
					// addressId={address}
					// the following functions are necessary for the component, but go unused here
					handleChangeAddress={(a) => setAddress(a)}
					handleBack={() => setPage(0)}
					handleNext={() => handleNext()}
				/>
			) : // <Questions
			null}
		</Form>
	);
};

export default UnassignedRequestForm;
