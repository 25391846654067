import _ from "lodash";

const addAddress = (state, action) => {
  return [
    ...state.filter((o) => o.address !== action.payload.address),
    {
      address: action.payload.address,
      center: action.payload.center,
      order: null,
      isStart: false,
      isEnd: false,
    },
  ];
};

export const optimizeReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return addAddress(state, action);
    case "ADD_START":
      if (!_.find(state, { address: action.payload.address })) {
        return addAddress(state, action).map((o) => {
          if (o.address === action.payload.address) {
            return { ...o, isStart: true };
          } else {
            return { ...o, isStart: false };
          }
        });
      } else {
        return state.map((o) => {
          if (o.address === action.payload.address) {
            return { ...o, isStart: true };
          } else {
            return { ...o, isStart: false };
          }
        });
      }
    case "ADD_END":
      if (!_.find(state, { address: action.payload.address })) {
        return addAddress(state, action).map((o) => {
          if (o.address === action.payload.address) {
            return { ...o, isEnd: true };
          } else {
            return { ...o, isEnd: false };
          }
        });
      } else {
        return state.map((o) => {
          if (o.address === action.payload.address) {
            return { ...o, isEnd: true };
          } else {
            return { ...o, isEnd: false };
          }
        });
      }
    case "REMOVE_START":
      return [...state.map((o) => ({ ...o, isStart: false }))];
    case "REMOVE_END":
      return [...state.map((o) => ({ ...o, isEnd: false }))];
    case "REMOVE":
      return [...state.filter((o) => o.address !== action.payload.address)];
    case "UPDATE_ORDER":
      return state.map((o) => {
        if (o.address === action.payload.address) {
          return { ...o, order: action.payload.order };
        } else {
          return o;
        }
      });
    case "CLEAR":
      return [];
    default:
      return state;
  }
};
