/* eslint-disable import/no-anonymous-default-export */
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import React, { useContext, createContext, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { IconButton, SubtleButton, SubtleIconButton } from "./button";
import { useTranslation } from 'react-i18next';

const SnackbarContext = createContext();

export const SnackbarContextProvider = ({ children }) => {
  const showSnackbar = (child) => {
    changeState((state) => ({
      ...state,
      open: child,
    }));
  };

  const [state, changeState] = useState({ open: false, showSnackbar });

  return (
    <SnackbarContext.Provider value={state}>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);

export default function () {
  const { t } = useTranslation();
  const tBase = "components.snackbar";
  const tr = (key) => t(`${tBase}.${key}`);

  const { open, showSnackbar } = useSnackbarContext();
  const contentRef = React.useRef();
  // return (
  //   <Snackbar
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={open ? true : false}
  //     autoHideDuration={6000}
  //     onClose={() => showSnackbar()}
  //     action={
  //       <>
  //         <div>hi</div>
  //       </>
  //     }
  //   >
  //     <SnackbarContent
  //       style={{
  //         background: "#1C2833",
  //       }}
  //       message={open}
  //     />
  //   </Snackbar>
  // );
  const debouncing = React.useRef(
    debounce(() => {
      showSnackbar();
    }, 6000)
  );

  const handleClick = (e) => {
    if (
      contentRef.current &&
      e.target !== contentRef.current &&
      !contentRef.current.contains(e.target)
    ) {
      showSnackbar();
    }
  };

  React.useEffect(() => {
    if (open) {
      debouncing.current();
      window.addEventListener("click", handleClick);

      return () => {
        window.removeEventListener("click", handleClick);
      };
    }
  });

  if (!open) {
    return null;
  }

  return (
    <SCon ref={contentRef}>
      {typeof open === "string" ? (
        <SDefault>
          <span>{open}</span>
          <SubtleButton primary onClick={() => showSnackbar()}>
            {tr("Close")}
          </SubtleButton>
        </SDefault>
      ) : (
        open
      )}
    </SCon>
  );
}

const SCon = styled.div`
  background: #1c2833;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  color: white;
  display: flex;
  padding: 0.5em;
`;

const SDefault = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;
