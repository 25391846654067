import React, { useEffect, useState } from 'react'
import axios from 'axios'
import format from 'date-fns/format'
import { Button } from 'components/button'
import { history } from 'routes'
import { BsJustifyRight } from 'react-icons/bs'
import UpdateCardForm from './components/updateCardForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Spinner from 'components/spinner'
import { useTranslation } from 'react-i18next';
// import It from 'views/signup/it'

const Subscription = () => {
    const { t } = useTranslation();
    const tBase = "views.subscription.index";
    const tr = (key: string) => t(`${tBase}.${key}`);

    type PlanProps = {
        plan_name: string
        total_price: number
        date_end: Date;
        duration: string
        stripe_customer_id: string
    }

    type BasePlanProps = {
        name: string,
        price: number,
        duration: string
    }

    type PlanHistoryProps = {
        plan_name: string
        total_price: number
        date_start: string
        duration: string
    }

    const [planInfo, setPlanInfo] = React.useState<PlanProps>()
    const [basePlanInfo, setBasePlanInfo] = React.useState<BasePlanProps>()
    const [planEndDate, setPlanEndDate] = React.useState<string>()
    const [planHistory, setPlanHistory] = React.useState<PlanHistoryProps[]>()
    const [cardInfo, setCardInfo] = React.useState<string>()
    const [showCardInput, setShowCardInput] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription`,
        {
            params: {
                type: "subscription"
            }
        })
        .then((res) => {
            setPlanInfo(res.data.subscription);
            setBasePlanInfo(res.data.basePlan);
            if (res.data.subscription !== undefined) {
                let formattedDate = new Date(res.data.subscription.date_end).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                });
                setPlanEndDate(formattedDate)
            }
            setPlanHistory(res.data.history);
            setCardInfo(res.data.card);
            setIsLoading(false)
        })
        .catch((err) => { console.log(err.message) })
    }, [])

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);
    // console.log("base plan info: ", basePlanInfo)

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div>
            {/* <It /> */}
            <Button
                primary
                style={{ float: "right" }}
                onClick={() => history.push("/admin/subscription/upgrade", { planInfo: planInfo, basePlanInfo: basePlanInfo })}
            >
                {tr("Upgrade Plan")}
            </Button>

            <h1 style={{ textDecoration: "underline", marginBottom: "5px" }}>
                {tr("Manage Eserv Subscription")}
            </h1>

            <div style={{display:"inline-block", width:"450px", verticalAlign:"top"}}>
                <h2 style={{ textDecoration: "underline", marginBottom: "5px", marginTop: "20px" }}>
                    {tr("Current Subscription")}
                </h2>

                <div style={{ backgroundColor: "lightgrey", borderRadius: "10px", padding: "40px", width: "440px" }}>
                    <div style={{ display: "inline-flex", flexDirection: "column", gap: "10px" }}>
                        <p>{tr("Current Plan")}:        </p>
                        <p>{tr("Renewal Date")}:        </p>
                        <p>{tr("Cost")}:                </p>
                        <p>{tr("Payment Method")}:      </p>
                    </div>
                    <div style={{ marginLeft: "7em", display: "inline-flex", flexDirection: "column", gap: "10px" }}>
                        { planInfo ? 
                        <>
                        <p>{planInfo?.plan_name}   </p>
                        <p>{planEndDate}    </p>
                        <p>${(planInfo?.total_price / 100).toFixed(2)}</p>
                        <p>{tr("Credit Card")}             </p>
                        </>
                        : 
                        <p>{tr("No Active Plans")}</p>
                        }
                    </div>
                </div>

                <h2 style={{ textDecoration: "underline", marginBottom: "5px", marginTop: "20px" }}>
                    {tr("Payment History")}
                </h2>

                <div style={{ display: "inline-flex", flexDirection: "row", gap: "75px", backgroundColor: "lightgrey", borderRadius: "10px", padding: "40px", width: "440px", textAlign: "center" }}>
                    <div style={{ display: "inline-flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                        <p style={{ marginBottom: "20px", width:"80px" }}>Plan Name</p>
                        {
                            planHistory && planHistory.map(record =>
                                <p>{record.plan_name}</p>
                            )
                        }
                    </div>
                    <div style={{ display: "inline-flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                        <p style={{ marginBottom: "20px" }}>{tr("Price")}</p>
                        {
                            planHistory && planHistory.map(record =>
                                <p>${(record.total_price / 100).toFixed(2)}</p>
                            )
                        }
                    </div>
                    <div style={{ display: "inline-flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                        <p style={{ marginBottom: "20px" }}>{tr("Date")}</p>
                        {
                            planHistory && planHistory.map(record =>
                                <p>  
                                {
                                    new Date(record.date_start).toLocaleDateString('en-US', {
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric'
                                    })
                                }
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
            
            {planInfo &&  
                <div style={{display:"inline-block", width:"450px", verticalAlign:"top"}}>
                    <h2 style={{ textDecoration: "underline", marginBottom: "5px", marginTop: "20px" }}>
                        {tr("Payment Card")}
                    </h2>

                    <div style={{ backgroundColor: "lightgrey", borderRadius: "10px", padding: "40px", width: "440px" }}>
                        <div style={{ display:"grid", gridTemplateColumns:"[first] 50% [last] 50%", alignItems:"center", marginBottom:"20px"}}>
                            <p style={{display:"inline-block"}}>XXXXXXXXXXXX{cardInfo}</p>
                            <Button primary style={{display:"inline-block", justifySelf:"right"}} onClick={()=>setShowCardInput(!showCardInput)}>{tr("Change Card")}</Button>
                        </div>
                        {
                            showCardInput &&
                                <Elements
                                    stripe={stripePromise}
                                >
                                    <UpdateCardForm customerId={planInfo?.stripe_customer_id} {...{setShowCardInput}} />
                                </Elements>
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default Subscription