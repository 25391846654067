import React, { useState } from "react";
import phase1video from "videos/onboarding-phase-1.mp4";
import poster from "../../../images/eserv.png";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Button } from "components/button";
import CheckboxInput  from "./CheckboxInput";
import { ISetChecked } from "../interface";
import { useTranslation } from 'react-i18next';

type Phase1Props = {
  className?: string;
  updateCheckedField: (
    phase: "phase1" | "phase2" | "phase3", 
    fieldName: string, 
    value: boolean
    ) => void;
  checked?: ISetChecked;
  handleClickPhase: (phase: "phase1" | "phase2" | "phase3") => void;
  isPhase1Complete?: boolean;
  companyId: string;
}

const Phase1: React.FC<Phase1Props> = ({ className, updateCheckedField, checked, handleClickPhase, isPhase1Complete, companyId }) => {
  const { t } = useTranslation();
  const tBase = "features.onboarding-wizard.phase1";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [copy, setCopy] = useState(false);

  const copied = () => {
    setCopy(true);
    setTimeout(() => setCopy(false), 100);
  }

  return (
    <div className={`${className} mt-10 mb-6`}>
      <video className="w-full h-[200px] mb-2 z-[2]" controls poster={poster}>
        <source src={phase1video} type="video/mp4"/>
      </video>
      <div className="z-1 text-gray-600 ">
        {/* Phase title */}
        <div className="text-lg font-semibold text-gray-700">
          <span className="text-secondary">{tr("Phase 1")} </span>- {tr("Creating Your Company Profile in EServ")}
        </div>

        {/* Description */}
        <div className="mt-2">
          {tr("Congratulations on joining EServ! We're thrilled to have you on board, and we want to ensure your onboarding experience is smooth and rewarding. If you need additional assistance or clarification, your onboarding specialists will be happy to assist.")}
        </div>
        <div className="mt-2">
          {tr("In Phase 1 of the onboarding process, we'll guide you through creating your company profile. This profile serves as the cornerstone for your company within EServ.")}
        </div>

        {/* Content */}
        <div className="text-gray-600 mt-2">

          {/* Divisions */}
          <div className="mt-2">
            <div className="flex items-center mb-2">
              <CheckboxInput 
                phase="phase1" 
                fieldName="divisions" 
                onChange={updateCheckedField} 
                checked={checked?.phase1?.divisions || false}
              />
              <Link to="/admin/divisions" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Divisions")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("First thing we want to do is to get your company divisions setup. This will help establish how  the work is being dispersed as well as which divisions an employee is apart for their contributions towards production as well as how they are displayed on the calendar.")}
              </div>
              <div>
                {tr("Click on the")}&nbsp;
                <Link to="/admin/divisions" className="text-secondary no-underline hover:underline">
                  {tr("Divisions")}
                </Link>
                &nbsp;{tr("link to go straight to the Divisions section of settings. Proceed by clicking the button labeled '+ Division' to add each of your divisions.")}
              </div>
              <div>
                {tr("You will be able to distinguish between default price types (Install or Sale) for each division and how you would like Production tracked for each division.")}
              </div>
            </div>
          </div>
          
          {/* Team Info */}
          <div className="mt-2">
            <div className="flex items-center mb-2">
              <CheckboxInput 
                phase="phase1" 
                fieldName="teamInfo" 
                onChange={updateCheckedField} 
                checked={checked?.phase1?.teamInfo || false}
              />
              <Link to="/admin/employees" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Team Info")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="mb-2">
              {tr("Now that you have added your divisions, let's get your team added! Start by clicking on the")}&nbsp;
              <Link to="/admin/employees" className="text-secondary no-underline hover:underline">
                {tr("Team info")} 
              </Link>
              &nbsp;{tr("link. This will take you to the Employees page. To begin, click on Add new user and provide first and last name, photo, phone number, email, division and title for all current employees. Indicate if employees will need access to EServ.")}
            </div>
          </div>

          {/* Question Builder */}
          <div className="mt-2">
            <div className="flex items-center mb-2">
              <CheckboxInput 
                phase="phase1" 
                fieldName="questionBuilder" 
                onChange={updateCheckedField} 
                checked={checked?.phase1?.questionBuilder || false}
              />
              <Link to="/settings/questions" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Question Builder")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("The question builder is the first interaction between customer and business. Questions are designed to elicit the most vital information, allowing for appropriate scheduling or bidding. Click on the")}&nbsp;
                <Link to="/settings/questions" className="text-secondary no-underline hover:underline">
                  {tr("Question Builder")}
                </Link> 
                &nbsp;{tr("link to review the pre-loaded example Questions. You can add, remove or modify these questions to better fit your business.")}
              </div>
              <div>
                {tr("The primary Category is the starting point of your questions and each additional category can be added to expand your questions into your preferred workflow. In addition, Categories will help define the type of requests you are receiving. We recommend using them to differentiate between work types such as Install or Service.")}
              </div>
              <div>
                {tr("Sub questions can be used to expand upon a question. For example, when someone chooses an option 'Other' you may wish to give them the opportunity to expand upon that with a text box.")}
              </div>
              <div className="mt-2 italic text-xs">
                {tr("If you need additional assistance or clarification, your onboarding specialists will be happy to assist.")}
              </div>
            </div>
          </div>

          {/* Website Access */}
          <div className="mt-2">
            <div className="flex items-center mb-2">
              <CheckboxInput 
                phase="phase1" 
                fieldName="websiteAccess" 
                onChange={updateCheckedField} 
                checked={checked?.phase1?.websiteAccess || false}
              />
              <div className="text-secondary font-bold">{tr("Website Access")}:</div>
            </div>

            <div className="mb-2">
              {tr("Connect the EServ questions you just made in the Question Builder to your company's website by sharing the link below with your web developers. This will send requests from your customers directly into your EServ account and you'll never have to worry about a missed email or lost piece of paper again.")} 
            </div> 
            <div>
              <label className={`
                inline-block bg-gray-200 rounded-md py-1 px-2 mr-3 mt-2
                ${copy ? "shadow-[inset_0_0_10px_gray]" : "shadow-none"}
              `}>
                https://eserv.com/questions/{companyId}
              </label>
              <Button
                primary 
                style={{height: "30.5px"}}
                onClick={() => { 
                  navigator.clipboard.writeText(`https://eserv.com/questions/${companyId}`)
                  copied()
                }}>
                {tr("Copy")}
              </Button>
            </div>

          </div>

          {/* Next Steps */}
          <div className="mt-6 border-t">
            <div className="my-4">
              {tr("Continue to Phase 2 once you have completed the tasks above.")}
            </div>
            <Button 
              primary
              disabled={!isPhase1Complete}
              onClick={() => handleClickPhase("phase2")}
            >
              {tr("Phase 2")}
            </Button>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Phase1;