import { createContext, ReactElement, useState } from "react";
import { Vendor } from "types/vendor";
import axios from "axios";

interface VendorContextProps {
  currentVendor: Vendor | null;
  handleUpdateVendor: (newVendor: Vendor) => void;
  updateMode?: boolean;
  toggleUpdateMode?: () => void;
  handleUpdateVendorData?: (vendorKey:string, value:string) => void;
  updater: (vendor:any) => void;
  toggleUpdateModeButton: (editMode:boolean, refetch:any) => void;
  deleteVendor: (refetch:any) => void;
}

export const VendorContext = createContext<VendorContextProps>(
  {} as VendorContextProps
);

interface IProps {
  children: ReactElement;
}

export const VendorProvider = ({ children }: IProps) => {
  const [currentVendor, setCurrentVendor] = useState<Vendor | null>(null);
  const [updateMode, setUpdateMode] = useState(false);

  const handleUpdateVendor = (newVendor: Vendor) => {
    setCurrentVendor(newVendor);
    setUpdateMode(false)

    return;
  };

  const toggleUpdateMode = () => {
    setUpdateMode(!updateMode);
  }

  const updater = (vendor:any) => {
    setCurrentVendor(vendor)
  }

  const toggleUpdateModeButton = (editMode:boolean, refetch:any) => {
    if (editMode) {
      axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/updateVendor`,
        {
          vendorId: currentVendor!.vendor_id,
          data: currentVendor
        }
      ).then(() => {
        if (refetch) {
          refetch()
          setCurrentVendor(currentVendor)
        }
      })
    }
    
    if (toggleUpdateMode)
      toggleUpdateMode()
  }

  const deleteVendor = (refetch:any) => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/deleteVendor`,
      {
        vendorId: currentVendor!.vendor_id,
      }
    ).then(() => {
      if (refetch) {
        refetch()
        setCurrentVendor(null)
      }
    })
  }

  return (
    <VendorContext.Provider value={{ currentVendor, handleUpdateVendor, updateMode, toggleUpdateMode, updater, toggleUpdateModeButton, deleteVendor }}>
      {children}
    </VendorContext.Provider>
  );
};
