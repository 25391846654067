import React, { useState, useEffect } from "react";

import "./index.css";

import ItemCard from "./components/item-card";
import { useModalContext } from "components/modal";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { BiSearchAlt } from "react-icons/bi";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { useCartCountState } from "components/cart";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import RawSelect from "components/react-select";
import { Button } from "components/button";
import SpecialOrderHistory from "./components/SpecialOrderHistory";
import { useTranslation } from 'react-i18next';

const SearchCon = styled.div`
  padding: 35px 5px 35px 5px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: 0px 0px 10px -3px grey;
  z-index: 1;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
`;
const SearchBar = styled.input`
  width: 50%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  background: #f3f3f3;
  border: 0;
  border-radius: 3px;
`;
const CartCount = styled.span`
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;

  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;

  background-color: #ff4f00;
`;
const Select = styled(RawSelect)`
  width: 180px;
  margin: 0 20px 0 20px;
`;
const Category = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  padding: 1%;
  margin: 0;
  font-size: 20px;
  align-items: flex-start;
`;
const CategoryName = styled.h1`
  padding: 10px;
`;
const ItemCon = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 75%;
`;
const Con = styled.div`
  width: 100%;
`;

export default function App() {
  const { t, i18n } = useTranslation();
  const tBase = "views.order.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const cartState = useCartCountState();
  const [search, setSearch] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [filter, setFilter] = useState({
    label: "Specialty Parts",
    value: true,
  });
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    setFilter({
      label: tr("Specialty Parts"),
      value: true,
    })
  }, [i18n.language]);

  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;

    setSearch((state) => {
      let tempState = { ...state };
      tempState = value;
      return tempState;
    });
  };

  const handleKeyPress = (event) => {
    if (event.which === 13) {
      submitSearch();
    }
  };

  const submitSearch = async () => {
    try {
      const searchResults = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/specialOrder?type=SEARCHITEMS&searchTerm=${search}`
      );

      if (searchResults.data.length > 0) {
        setParentCategories(searchResults.data);
        setSearch("");
      } else {
        getData();
      }

      return;
    } catch (e) {
      console.error("Function submitSearch", e);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event);
  };

  const history = useHistory();

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/specialOrder?type=GETITEMS`
      );
      const useableCategories = res.data.filter((category) => {
        return category.items !== null;
      });
      setParentCategories(useableCategories);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!search) {
      getData();
    }
  }, []);

  return (
    <Con>
      <SearchCon>
        <BiSearchAlt size={25} />
        <SearchBar
          className="search-item"
          placeholder={tr("Search")}
          onChange={handleSearch}
          onKeyPress={handleKeyPress}
          value={search}
        ></SearchBar>
        <Tooltip title={tr("Cart")}>
          <IconButton
            aria-label="cart"
            onClick={() => {
              history.push("/cart");
            }}
          >
            <ShoppingCartIcon fontSize="large" />
            <CartCount>{cartState.count}</CartCount>
          </IconButton>
        </Tooltip>
        <Select
          options={[
            { label: tr("Specialty Parts"), value: true },
            { label: tr("Bulk Order Parts"), value: false },
          ]}
          value={filter}
          onChange={handleFilterChange}
        />
        <Button
          primary={showHistory ? true : false}
          onClick={() => setShowHistory((prev) => !prev)}
          size="small"
        >
          {tr("History")}
        </Button>
      </SearchCon>

      <div className="dashboard-container">
        <ItemCon style={{ width: showHistory ? "75%" : "100%" }}>
          {parentCategories &&
            parentCategories.length > 0 &&
            parentCategories
              .filter((category) => {
                let items =
                  category.items.filter((item) => {
                    try {
                      if (filter.value) {
                        return item.special_order_item === true;
                      } else if (!filter.value) {
                        return item.bulk_order_item === true;
                      } else {
                        return item;
                      }
                    } catch (e) {
                      return item;
                    }
                  }).length > 0;

                return items;
              })
              .map((category) => {
                return (
                  <>
                    <CategoryName>{category.category_name}</CategoryName>
                    <Category>
                      {category.items
                        .filter((item) => {
                          try {
                            if (filter.value) {
                              return item.special_order_item === true;
                            } else if (!filter.value) {
                              return item.bulk_order_item === true;
                            } else {
                              return item;
                            }
                          } catch (e) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          return (
                            <ItemCard
                              key={item.item_id}
                              item={item}
                              {...{ setModal }}
                            />
                          );
                        })}
                    </Category>
                  </>
                );
              })}
        </ItemCon>
        {showHistory ? <SpecialOrderHistory /> : null}
      </div>
    </Con>
  );
}
