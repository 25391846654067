import FlatContainer from "components/container/flat";
import SafeArea from "components/safe-area";
import Spinner from "components/spinner";
import * as React from "react";
import { TInventory } from "types/inventory";
import { TProduct } from "types/product";

type Props = {
  data?: any; // TInventory | TProduct;
  isPart: boolean;
};

export default function ReadLayout(props: Props) {
  if (!props.data) return <Spinner />;

  if (!props.isPart) {
    return <ServiceLayout {...props.data} />;
  }
  return <PartLayout {...props.data} />;
  //   return <FlatContainer title={props.data.item_name}>hi</FlatContainer>;
}

const PartLayout = (props: TInventory) => {
  return <div>hi</div>;
};
const ServiceLayout = (props: TProduct) => {
  return <FlatContainer title={props.product_label}>hi</FlatContainer>;
};
