import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const DropBox = ({onChange}) => {
    const { t } = useTranslation();
    const tBase = "views.customer.component.dropBox";
    const tr = (key) => t(`${tBase}.${key}`);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (errorMessage) {
            alert(tr("Error") + ":" + errorMessage)
            setErrorMessage(null)
        }
        if (selectedFiles.length > 0) {
            onChange(selectedFiles)
            setSelectedFiles([])
        }

        
    }, [selectedFiles, errorMessage])

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                // this can be ignored unless we get into uploading multiple files simultaenously
                // setSelectedFiles(prevArray => [...prevArray, files[i]]);

                // hacky workaround...since input elements pass their files in an array,
                // and selectedFiles is passed into the same function (sendFileMutation) an input element uses, 
                // files is sent in as an element of an array so the function accepts it without issue
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setErrorMessage('File type not permitted. \n Permitted File Types - PNG, PDF, JPG, JPEG, GIF');
            }
        }
    }

    const dragOver = (e) => {
        e.target.style.backgroundColor = "#C1C1C1"
        e.target.style.transition = "all 0.5s ease"
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.target.style.backgroundColor = "#FFFFFF"
        e.target.style.transition = "all 0.5s ease"
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.target.style.backgroundColor = "#FFFFFF"
        e.target.style.transition = "all 0.5s ease"
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'application/pdf','text/csv'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }



    // TODO: add function to validate file size and return a fail condition if file exceeds file size        

    return (
        <>
            {/* <p>Drop Box</p> */}

            <div style={{display:"inline-grid", width:"25%", placeContent:"center", overflow:"hidden", minWidth:"100px", margin:"10px"}}>
                    <div className="drop-container" 
                        style=
                        {{
                        border:"orange", 
                        borderWidth:"3px", 
                        borderStyle:"dashed", 
                        // backgroundColor:"grey",
                        width:"100%", 
                        height:"300px", 
                        display:"inline-grid", 
                        placeContent:"center", 
                        textAlign:"center",
                        padding:"20px",
                        justifyContent:"space-between"
                        }}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                    >
                        {tr("Drop Files Here to Upload")}
                
                    </div>
                </div>
        </>
    )
}

export default DropBox