import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TInventory } from "types/inventory";

type Props = TInventory & {};

export default function Part(props: Props) {
  return (
    <Con>
      <img
        src={props.imageUrl || ""}
        style={{
          border: `1px solid ${theme.border.light}`,
          borderRadius: "5px",
          width: "100%",
          objectFit: "contain",
        }}
      />
      <div style={{ marginTop: "0.5rem" }}>
        <h5 style={{ color: theme.color.subtext2 }}>{props.category_name}</h5>
        <span>{props.item_name}</span>
      </div>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;
