import React from "react";
import styled from "styled-components";
import {
  IoMdRadioButtonOff,
  IoMdSquareOutline,
  IoIosArrowRoundForward,
} from "react-icons/io";
import { RiAddLine, RiArrowRightLine, RiCloseLine } from "react-icons/ri";
import {
  Choices,
  useQuestionBuilderDispatch,
  useQuestionBuilderState,
} from "../context";
// import Select from "react-select";
import Select from "components/react-select";
import { SubtleButton, SubtleIconButton } from "components/button";
import { useModalContext } from "components/modal";
import SubQuestionModal from "../sub-question-modal";
import { FiEdit2, FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type Props = {
  index: number;
  choices: Choices[];
  type: string;
  selected: boolean;
  isGroupLinkageQuestion: boolean;
  id: string;
  is_subquestion: boolean;
};
export default function MultiChoice({
  index: questionIndex,
  choices,
  type,
  selected,
  isGroupLinkageQuestion,
  ...props
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.question.multiChoice";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const dispatch = useQuestionBuilderDispatch(),
    modal = useModalContext();

  const {
    groupLinkage: { optionLinkage },
    category,
    subquestions,
    option,
  } = useQuestionBuilderState();

  const populateLinkage = ({ id }: { id: string }) => {
    const optionValue = optionLinkage?.find(
      ({ optionId }) => optionId === id
    )?.groupId;

    let index =
      category?.findIndex(({ value }) => value.toString() === optionValue) ??
      -1;

    if (selected)
      return (
        <Select
          name="placeholder"
          onChange={(value) => {
            dispatch &&
              dispatch({
                type: "handleLinkageSelect",
                optionId: id,
                value: value?.value,
              });
          }}
          style={{ width: "300px" }}
          value={index !== -1 ? category![index] : undefined}
          options={category || []}
          // styles={{ container: (base) => ({ ...base, flex: 1 }) }}
        />
      );

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {index !== -1 ? (
          <>
            <IoIosArrowRoundForward style={{ fontSize: "1.5rem" }} />
            <span>{category![index]["label"]}</span>
          </>
        ) : (
          tr("Not linked")
        )}
      </div>
    );
  };

  const populateChoices = choices?.map(({ id, label, subquestion }) => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.select();
    };

    const subquestionLabel = subquestions?.find(
      (subq) => subq.id.toString() === subquestion
    )?.label;

    return (
      <div className="flex flex-col" key={id}>
        <ChoiceCon>
          {type === "SINGLE" ? <IoMdRadioButtonOff /> : <IoMdSquareOutline />}
          <input
            value={label}
            onClick={() => dispatch({ type: "handleClick", option: id })}
            onFocus={handleFocus}
            autoFocus={option === id}
            onChange={(e) => {
              dispatch &&
                dispatch({
                  type: "handleOptionEdit",
                  payload: {
                    questionIndex,
                    value: e.target.value,
                    optionId: id,
                  },
                });
            }}
          />
          {selected && (
            <RiCloseLine
              onClick={() =>
                dispatch({ type: "deleteOption", optionIndex: id })
              }
            />
          )}

          {isGroupLinkageQuestion &&
            populateLinkage({
              id,
            })}
        </ChoiceCon>
        {selected && !props.is_subquestion ? (
          subquestion ? (
            <div className="flex space-x-2 items-center justify-between text-[1rem] text-green-600 py-1 px-2 bg-green-100/50 rounded">
              {/* <FiCornerDownRight /> */}
              <span className="text-sm">{subquestionLabel}</span>
              <div>
                <SubtleIconButton
                  size="xsmall"
                  className="!text-[0.7rem]"
                  onClick={() =>
                    modal.setModal({
                      component: (
                        <SubQuestionModal
                          optionId={id}
                          questionId={props.id}
                          dispatch={dispatch}
                        />
                      ),
                      label: tr("Add Sub Questions"),
                    })
                  }
                >
                  <FiEdit2 />
                </SubtleIconButton>
                <SubtleIconButton
                  size="xsmall"
                  className="!text-red-600 !text-[0.7rem]"
                  onClick={() =>
                    dispatch({
                      type: "handleOptionToSubQuestion",
                      payload: {
                        questionId: props.id,
                        optionId: id,
                        subquestion: null,
                      },
                    })
                  }
                >
                  <FiX />
                </SubtleIconButton>
              </div>
            </div>
          ) : (
            <div className="pl-2">
              <SubtleButton
                size="xsmall"
                className="!py-0"
                onClick={() =>
                  modal.setModal({
                    component: (
                      <SubQuestionModal
                        optionId={id}
                        questionId={props.id}
                        dispatch={dispatch}
                      />
                    ),
                    label: tr("Add Sub Questions"),
                  })
                }
              >
                <RiAddLine /> {tr("Sub Questions")}
              </SubtleButton>
            </div>
          )
        ) : (
          subquestion && (
            <div className="text-green-600 font-bold text-xs pl-4 flex items-center space-x-2">
              <RiArrowRightLine />
              <span>{subquestionLabel}</span>
            </div>
          )
        )}
      </div>
    );
  });

  return (
    <>
      {populateChoices}
      {selected && (
        <ChoiceCon>
          {type === "SINGLE" ? <IoMdRadioButtonOff /> : <IoMdSquareOutline />}
          <AddOption onClick={() => dispatch({ type: "addOption" })}>
            {tr("Add option")}
          </AddOption>
        </ChoiceCon>
      )}
    </>
  );
}

const AddOption = styled.div`
  margin-right: 0.5rem;
  color: grey;
  cursor: pointer;
`;

const ChoiceCon = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  align-items: center;
  > input {
    border: 0;
    border-radius: 0;
    outline: none;
    padding: 0.5rem 0;
    flex: 1;
    border-bottom: 1px solid white;
    &:hover {
      border-bottom: 1px solid black;
    }
    &:focus {
      border-bottom: 1px solid black;
    }
  }
  > svg {
    font-size: 1.5rem;
    color: grey;
    margin-right: 0.5rem;
  }
`;
