import { useContext } from "react";

import { Vendor } from "types/vendor";
import { VendorContext } from "contexts/VendorContext";

import styles from "./VendorButton.module.scss";

interface IProps {
  vendor: Vendor;
}

const VendorButton = ({ vendor }: IProps) => {
  const { currentVendor, handleUpdateVendor } = useContext(VendorContext);
  const { vendor_name: vendorName } = vendor;
  return (
    <div
      onClick={() => handleUpdateVendor(vendor)}
      className={
        currentVendor && vendor.vendor_id === currentVendor.vendor_id
          ? styles.selectedContainer
          : styles.container
      }
    >
      <div className={styles.text}>{vendorName}</div>
    </div>
  );
};

export default VendorButton;
