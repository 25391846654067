import { Box, Modal } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";

import { Button } from "components/button";
import { EQUIPMENT } from "features/equipment/constants";
import { CREATE } from "../../../../../constants";
import { IEquipment } from "features/equipment/types/equipment";
import { Input } from "components/input";
import { useEquipmentMutation } from "features/equipment/hooks/useEquipmentMutation";
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  handleSave: () => void;
  handleClose: () => void;
  modalType: string;
  itemType: string;
  selectedEquipment: IEquipment | null;
}

interface FormValues {
  vehicle_label: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 3,
};

const EquipmentModal = ({
  open,
  handleClose,
  modalType,
  itemType,
  selectedEquipment,
}: IProps) => {
  const { t } = useTranslation();
  const tBase = "features.equipment";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const title = modalType === CREATE ? "Create" : "Edit";
  const type = itemType === EQUIPMENT ? "Equipment" : "Maintenance Item";

  const { register, handleSubmit, setValue } = useForm<FormValues>();

  const { mutate, isError, isLoading, isSuccess } =
    useEquipmentMutation(modalType);

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    if (modalType === CREATE) {
      mutate({
        vehicle_id: 0,
        vehicle_label: formData.vehicle_label,
        company_id: 0,
        default_crew: null,
        vehicle_type: null,
        description: null,
        display_on_team: true,
      } as IEquipment);
      if (isSuccess) {
        setValue("vehicle_label", "");
        handleClose();
      }
    } else {
      if (selectedEquipment && formData.vehicle_label) {
        const updatedEquipment = {
          ...selectedEquipment,
          vehicle_label: formData.vehicle_label,
        };
        mutate(updatedEquipment);
        if (isSuccess) {
          setValue("vehicle_label", "");
          handleClose();
        }
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="">
          {modalType === CREATE ? (
            <h2>{`${title} ${type}`}</h2>
          ) : (
            <h2>{`${title} ${type}: ${selectedEquipment?.vehicle_label}`}</h2>
          )}
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <label className="mt-4">{tr("Name")}:</label>
          <div className="mt-2 mb-2">
            <Input {...register("vehicle_label")} />
          </div>
          <div className="flex flex-row justify-between mt-4">
            <Button onClick={handleClose}>{tr("Cancel")}</Button>
            <Button
              type="submit"
              primary
              onClick={(e: any) => {
                handleSubmit(e);
              }}
              isLoading={isLoading}
            >
              {tr("Save")}
            </Button>
          </div>
          <div className="items-end justify-end mt-2">
            {isError && (
              <div className="text-red-500 text-right">
                {tr("Something went wrong...")}
              </div>
            )}
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EquipmentModal;
