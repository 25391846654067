const teamReducer = (state = { employee: [], image: {} }, action) => {
  switch (action.type) {
    case "ADDTEAM":
      return {
        employee: action.employee,
        image: state.image,
      };
    case "INITIALTEAM":
      return {
        employee: action.employee,
        image: action.image,
      };
    default:
      return { employee: state.employee, image: state.image };
  }
};

export default teamReducer;
