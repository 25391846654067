import { combineReducers } from "redux";
/* import pageReducer from './pageContext' */
/* import {useCartStore} from './cartCount' */
import teamReducer from "./team";
//import dropdownReducer from './dropdownContext'
/* import itemModalReducer from './itemModalContext' */
import isAuthReducer from "./isAuth";

const allReducers = combineReducers({
  /* page: pageReducer, */
  /* cartCount: useCartStore, */
  /* itemModalStatus: itemModalReducer, */
  team: teamReducer,
  isAuth: isAuthReducer,
});

export default allReducers;
