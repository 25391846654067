import { companyClient } from "api/api";
import { IAppointmentType } from "../types/IAppointmentType";

export const updateAppointmentType = async (
  updatedAppointmentType: IAppointmentType
) => {
  return companyClient({
    method: "PUT",
    url: "/appointment-types",
    data: updatedAppointmentType,
  });
};
