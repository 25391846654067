import { Button, IconButton } from "components/button";
import { Input } from "components/input";
import { FormikProps } from "formik";
import * as React from "react";
import { RiAddLine, RiCloseFill } from "react-icons/ri";
import { TWarranty, TWarrantyItem, TWarrantyTargetItem } from "types/warranty";
import Field from "components/field";
import { cloneDeep } from "lodash";
import { useTranslation } from 'react-i18next';

type TProps = {
  formik: FormikProps<TWarranty>;
};

const options = {
  day: { value: "day", label: "Day" },
  week: { value: "week", label: "Week" },
  month: { value: "month", label: "Month" },
  year: { value: "year", label: "Year" },
};

export default function WarrantyItemsCon(props: TProps) {
  const { t } = useTranslation();
  const tBase = "containers.warrantyItems.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const butRef = React.useRef<HTMLButtonElement>(null);

  const [adding, setAdding] = React.useState(false);

  React.useEffect(() => {
    if (adding) {
      butRef.current?.scrollIntoView();
      setAdding(false);
    }
  }, [adding]);

  const populateLineItems = props.formik.values.items?.map((item, idx) => (
    <WarrantyItem formik={props.formik} item={item} idx={idx} key={idx} />
  )) || <span className="text-slate-500">{tr("No Items.")}</span>;

  return (
    <div className="divide-y">
      <div className="py-4">
        <h3>{tr("Warranty Items")}</h3>
      </div>

      <div className="space-y-4 py-4 divide-y">{populateLineItems}</div>

      <Button
        ref={butRef}
        primary
        onClick={() => {
          props.formik.setFieldValue(
            "items",
            props.formik.values.items
              ? [...props.formik.values.items, { label: "" }]
              : [{ label: "" }]
          );
          setAdding(true);
        }}
      >
        <RiAddLine />
        {tr("Add Item")}
      </Button>
    </div>
  );
}

export const WarrantyItem = (props: {
  formik: FormikProps<TWarranty>;
  item: TWarrantyTargetItem;
  idx: number;
}) => {
  const { t } = useTranslation();
  const tBase = "containers.warrantyItems.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className="py-2 space-y-2">
      <div className="flex justify-between items-end">
        <div className="flex-1 max-w-[300px] flex items-end space-x-4">
          <Input
            name={`items[${props.idx}].duration`}
            formik={props.formik}
            placeholder={tr("Date")}
            label={tr("Duration")}
          />
          <Field
            className={"w-[200px]"}
            label={tr("Duration Type")}
            type="select"
            options={[
              { value: "day", label: tr("Day") },
              { value: "week", label: tr("Week") },
              { value: "month", label: tr("Month") },
              { value: "year", label: tr("Year") },
            ]}
            name={`items[${props.idx}].duration_type`}
            value={options[props.formik.values.items![props.idx].duration_type]}
            onChange={(e: any) => {
              let items = cloneDeep(props.formik.values).items;
              items![props.idx].duration_type = e.value;
              props.formik.setFieldValue("items", items);
            }}
          />
        </div>
        <IconButton
          className="!bg-red-100 !text-red-600"
          onClick={() =>
            props.formik.setFieldValue(
              "items",
              props.formik.values.items!.length > 0
                ? [
                    ...props.formik.values.items!.slice(0, props.idx),
                    ...props.formik.values.items!.slice(props.idx + 1),
                  ]
                : null
            )
          }
        >
          <RiCloseFill />
        </IconButton>
      </div>

      <Field
        label={tr("Description")}
        type="textarea"
        name={`items[${props.idx}].label`}
        formik={props.formik}
        placeholder={tr("Label")}
        minRows={4}
      />
    </div>
  );
};
