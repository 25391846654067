import axios from "axios";
import { A, Button } from "components/button";
import SafeArea from "components/safe-area";
import TableComponent from "components/table";
import * as React from "react";
import { useQuery } from "react-query";
import { TCustomDoc } from "types/custom-doc";
import { useTranslation } from 'react-i18next';

export default function CustomDocuments() {
  const { t } = useTranslation();
  const tBase = "views.custom_documents.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<TCustomDoc[]>(
    ["custom documents"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/custom-doc
    `
        )
        .then((res) => res.data)
  );

  const columns = {
    id: {
      label: "ID",
    },
    label: {
      label: tr("Label"),
    },
    type: {
      label: tr("Type"),
    },
  };

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="py-4 justify-between flex items-center">
          <h2>{tr("Custom Documents")}</h2>
          <A primary to="/custom-docs/new">
            {tr("Create Custom Document")}
          </A>
        </div>
      </SafeArea>
      <SafeArea>
        <TableComponent
          data={query.data}
          columns={columns}
          to={(row) => `/custom-docs/${row.id}`}
        />
      </SafeArea>
    </div>
  );
}
