import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import {
  MdFolderSpecial,
  MdClose,
  MdArrowBack,
  MdArrowForward,
} from "react-icons/md";
import { FiCheckCircle, FiCircle } from "react-icons/fi";

import "./index.css";
import ReactDatePicker from "react-datepicker";

const SpecialDrawer = () => {
  const [date, setDate] = useState(new Date());
  const [spec, setSpec] = useState([]);
  const [specMenuOpen, setSpecMenuOpen] = useState(false);
  const [openSpec, setOpenSpec] = useState(0);
  const { t } = useTranslation();
  const tBase = "views.dashboard.spec-item.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const fetchSpecial = async () => {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/spec?date=${date}`,
    });

    if (response.data) {
      setSpec(response.data);
    }
  };

  useEffect(() => {
    fetchSpecial();
  }, [date]);

  useEffect(() => {
    let count = 0;
    spec.forEach((order) => {
      order.items.forEach((item) => {
        if (!item.is_done) {
          count++;
        }
      });
    });
    setOpenSpec(count);
  }, [spec]);

  const setDone = async (id, status, item_id, quantity) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/transfer/specUpdate`,
      data: { id, status, item_id, quantity },
    });

    fetchSpecial();
  };

  return (
    <>
      <div>
        <div
          onClick={() => setSpecMenuOpen(!specMenuOpen)}
          className={specMenuOpen ? "overlay-shown" : "overlay-hidden"}
        ></div>

        <div
          className="spec-btn"
          onClick={() => setSpecMenuOpen(!specMenuOpen)}
          style={openSpec === 0 ? { color: "gray" } : {}}
        >
          <MdFolderSpecial size={20} /> {openSpec}
        </div>

        <div
          className={
            specMenuOpen ? "spec-container-open" : "spec-container-closed"
          }
        >
          <div className="spec-head-row">
            <div className="spec-header">{tr("Special Order Items")}</div>
            <div
              className="spec-btn-close"
              onClick={() => setSpecMenuOpen(!specMenuOpen)}
            >
              <MdClose size={22} />
            </div>
          </div>
          <div className="spec-date-row">
            {/* <MdArrowBack
              onClick={() => {}}
              size={22}
              className="spec-date-arrow"
            /> */}
            <ReactDatePicker
              className="spec-date"
              selected={date}
              onChange={(date) => setDate(date)}
            />
            {/* <MdArrowForward
              onClick={() => {}}
              size={22}
              className="spec-date-arrow"
            /> */}
          </div>
          <div className="spec-list">
            {spec &&
              spec?.map((order) => {
                return (
                  <div className="spec-group">
                    <div className="spec-user">
                      <div className="spec-name">{order.tech_name}</div>
                      {order.items?.map((item) => {
                        return (
                          <div className="spec-part">
                            <div style={{ flexDirection: "column" }}>
                              <div>
                                #{item.internal_id.toString().padStart(3, "0")}
                              </div>
                              <div>
                                <div className="spec-pc">
                                  {item.parent_category}
                                  {" -> "}
                                  {item.category_name}
                                </div>
                                <div className="spec-item">
                                  <strong>{item.item_name}</strong>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ alignSelf: "center" }}
                              className="spec-qty"
                            >
                              {item.qty}
                            </div>
                            {item.is_done ? (
                              <FiCheckCircle
                                onClick={() => {
                                  setDone(
                                    item.special_order_item_id,
                                    !item.is_done,
                                    item.item_id,
                                    -item.qty
                                  );
                                }}
                                className="spec-check"
                                size={28}
                                color={"green"}
                              />
                            ) : (
                              <FiCircle
                                onClick={() => {
                                  setDone(
                                    item.special_order_item_id,
                                    !item.is_done,
                                    item.item_id,
                                    item.qty
                                  );
                                }}
                                className="spec-check"
                                size={28}
                                color={"black"}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialDrawer;
