import * as React from "react";
import {
  SidebarCon,
  HomeCon,
  ChildGraphCon,
  MenuLink,
  MenuHeader,
  MenuContainer,
  SideBarSection,
  ReminderCon,
  ButtonCon,
} from "./style";

import LineGraph from "components/lineGraph";
import { useQuery } from "react-query";
import {
  fetchRequestByMonth,
  fetchNumberOfCustomer,
  fetchRequestByCategory,
} from "./api";
import { Button } from "components/button";
import Onboarding from "./container/onboarding";
import { ContainerWithSidebar } from "components/layout";
import Production from "./container/production";
import Setup from "./components/setup";
import { RiAddFill } from "react-icons/ri";
import { useModalContext } from "components/modal";
import CreateModal from "./components/createModal";
import { useTranslation } from 'react-i18next';

const buttonArray = ["week", "month", "year"];
export default function Home2() {
  const { t } = useTranslation();
  const tBase = "views.home.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [view, setView] = React.useState("week");

  const requestByMonth = useQuery(
    ["requestByMonth", view],
    async () => await fetchRequestByMonth(view)
  );

  const numberOfCustomer = useQuery(
    ["numberOfCustomer", view],
    async () => await fetchNumberOfCustomer(view)
  );

  const requestByCategory = useQuery(
    ["requestByCategory", view],
    async () => await fetchRequestByCategory(view)
  );

  const populateRequestByCategory =
    requestByCategory.data &&
    requestByCategory.data.map((category: { link: any; counts: any }) => (
      <LineGraph
        key={category.link}
        data={category.counts}
        x={"day"}
        y="count"
        title={`${category.link} Requests`}
      />
    ));

  return (
    <div className="p-4 space-y-4">
      <Onboarding />
      <Production />
      <h1>{tr("Dashboard")}</h1>
      <ButtonCon selected={buttonArray.findIndex((button) => button === view)}>
        {/* <Button onClick={() => setView("day")}>1d</Button> */}
        <Button onClick={() => setView("week")}>{tr("1w")}</Button>
        <Button onClick={() => setView("month")}>{tr("1m")}</Button>
        <Button onClick={() => setView("year")}>{tr("1y")}</Button>
      </ButtonCon>
      <LineGraph
        data={requestByMonth.data}
        x={"day"}
        y={"count"}
        title={tr("Requests")}
      />
      <ChildGraphCon>
        <LineGraph
          data={numberOfCustomer.data}
          x={"day"}
          y={"count"}
          title={tr("Customers")}
        />
        {populateRequestByCategory}
      </ChildGraphCon>
    </div>
  );
}

export const Sidebar = () => {
  const { t } = useTranslation();
  const tBase = "views.home.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { setModal } = useModalContext();

  return (
    <SidebarCon>
      <SideBarSection>
        <Button
          onClick={() =>
            setModal({ component: <CreateModal />, label: tr("Create New") })
          }
          style={{ width: "100%" }}
          primary
        >
          <RiAddFill /> {tr("Create")}
        </Button>
        {/* <h1>Navigation</h1> */}
        {/* <MenuHeader>
          <RiUser6Fill />
          Customer Management
        </MenuHeader> */}
        <MenuContainer>
          <MenuLink to="/customers">{tr("Customers")}</MenuLink>
          <MenuLink to="/requests">{tr("Requests")}</MenuLink>
          <MenuLink to="/appointments">{tr("Appointments")}</MenuLink>
          <MenuLink to="/invoices">{tr("Invoices")}</MenuLink>
        </MenuContainer>
        {/* <MenuHeader>
          <RiUser6Fill />
          Projects
        </MenuHeader> */}
        <MenuContainer>
          <MenuLink to="/projects">{tr("Projects")}</MenuLink>
          <MenuLink to="/quotes">{tr("Quotes")}</MenuLink>
        </MenuContainer>
        {/* <MenuHeader>
          <RiBuildingFill />
          Company Management
        </MenuHeader> */}
        <MenuContainer>
          <MenuLink to="/">{tr("Analytics")}</MenuLink>
          <MenuLink to="/admin/requests">{tr("Settings")}</MenuLink>
          <MenuLink to="/admin/employees">{tr("Employees")}</MenuLink>
          <MenuLink to="/admin/notifications">{tr("Notification Settings")}</MenuLink>
          <MenuLink to="/admin/templates">{tr("Permission Templates")}</MenuLink>
          <MenuLink to="/settings/questions">{tr("Question Builder")}</MenuLink>
          <MenuLink to="/admin/quickbooks">Quickbooks</MenuLink>
        </MenuContainer>
      </SideBarSection>
      {/* <Setup /> */}
    </SidebarCon>
  );
};
