import React from "react";

const optionStyle: React.CSSProperties = {
    position: "relative",
    cursor: "pointer",
    backgroundColor: "white",
    padding: "0.5em",
    minHeight: 0,
    fontSize: "0.9rem",
    boxShadow: "none",
    borderRadius: "5px",
};

const optionBoxStyle: React.CSSProperties = {
    padding: "0.5em",
    boxShadow: "none",
    backgroundColor: "white",
    border: "3px solid #000000",
    borderRadius: "5px",
    zIndex: 99999,
    position: "absolute",
    width: "100%",
};

const styles = {
    option: optionStyle,
    optionBox: optionBoxStyle,
};

export default styles;
