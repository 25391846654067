// import { useModal } from "contexts/modalContext";
import ReactModal from "react-modal";
import styled from "styled-components";
import { IconButton } from "components/button";
import { GrClose } from "react-icons/gr";
import * as React from "react";
import ReactDOM from "react-dom";
import { theme } from "styles/theme";
import axios from "axios";

type ShowState = {
  component: React.ReactNode;
  label: string;
  footer?: React.ReactNode;
  props?: Omit<ReactModal.Props, "isOpen"> & { fullScreen?: boolean } & { isTimeClockStatusChangeModal?: boolean };
  headerButtons?: React.ReactNode;
  // displayInput?: string | "none";
  customerFile?: boolean | "false";
  icon?: any;
  editName?: boolean | false;
  displayStyle?: string | "flex";
  displayGridStyle?: string;
  handleFilenameChange?: any;
  handleInputChange?: any;
  showFilenameInput?: any;
  toggle?: any;
  showInput?: any;
};
type ModalState = {
  modal?: ShowState;
  setModal: (modal?: ShowState) => void;
};

const ModalContext = React.createContext<ModalState>({ setModal: () => {} });

function ModalProvider(props: { children: React.ReactNode }) {
  const [modal, setModal] = React.useState<ShowState>();

  return (
    <ModalContext.Provider
      value={{ modal, setModal }}
      children={props.children}
    />
  );
}

const useModalContext = () => React.useContext<ModalState>(ModalContext);

export { ModalProvider, useModalContext };

export default function ModalComponent() {
  const { modal, setModal } = useModalContext();
  const ref = React.useRef<any>();
  const [showInput, setShowInput] = React.useState("none");
  const [toggle, setToggle] = React.useState(true);
  // const filenameInputRef =

  const isOpen = modal ? true : false;

  const closeModal = () => {
    setModal();
  };

  // React.useEffect(() => {
  //   if (!modal) {
  //     setToggle(true)
  //     setShowInput("none")
  //     closeModal();
  //     // console.log("closed modal")
  //   }
  // }, [modal]);

  const showFilenameInput = () => {
    // console.log("show file name input");
    setToggle(!toggle);
    if (toggle) {
      setShowInput("");
      ref.current!.focus();
    }
  };

  const props = modal?.props && modal.props;

  return ReactDOM.createPortal(
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      onRequestClose={closeModal}
      {...props}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{
        content: {
          padding: "0 1rem 1rem 1rem",
          position: "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "5px",
          // border: "2px solid black",
          border: 0,
          transition: "all 0.1s linear",
          overflow: "hidden",
          maxHeight: props?.fullScreen ? "100vh" : "90vh",
          minHeight: props?.fullScreen ? "100vh" : "auto",
          minWidth: props?.fullScreen ? "100vw" : props?.isTimeClockStatusChangeModal ? "30%" : "400px",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
        },
        overlay: {
          background: "rgba(0, 14, 41,0.5)",
          backdropFilter: "blur(4px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 1s linear",
          zIndex: theme.zIndex.modal,
        },
      }}
    >
      <Header
        style={{
          display: `${modal?.displayStyle}`,
          // display: `flex`,
          gridTemplateColumns: `${modal?.displayGridStyle}`,
        }}
      >
        {toggle && (
          <span
            style={{
              maxWidth: "400px",
              padding: "10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {modal && modal.label}
          </span>
        )}
        {modal?.customerFile && modal?.editName && (
          <>
            <input
              ref={ref}
              style={{
                maxWidth: "300px",
                padding: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: `${showInput}`,
              }}
              autoFocus
              placeholder={modal?.label}
            ></input>
            <IconButton>
              <button
                onClick={() => showFilenameInput()}
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              >
                <img src={modal?.icon} />
              </button>
            </IconButton>
          </>
        )}
        <span>{modal && modal.headerButtons}</span>
        <IconButton onClick={closeModal}>
          <GrClose />
        </IconButton>
      </Header>
      <Content>{modal && modal.component}</Content>
      {modal?.footer && <Footer>{modal.footer}</Footer>}
    </ReactModal>,
    document.querySelector("#popper") as HTMLElement
  );
}

const Footer = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  padding: 0.5rem 0;
  background: white;
  top: 0;
  > span {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;
  }
`;

const Content = styled.div`
  overflow: auto;
`;
