import axios from "axios";
import Spinner from "components/spinner";
import EstimateSection from "containers/estimatesList";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

type Props = {
  id: string;
};

export default function Estimates(props: Props) {
  const estimates = useQuery(
    ["estimates", "customer", props.id],
    async () =>
      await axios(`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers/${props.id}/estimates
    `).then((res) => res.data)
  );

  if (estimates.isFetching) return <Spinner />;

  return (
    <Con>
      <EstimateSection data={estimates.data} />
    </Con>
  );
}

const Con = styled.div``;
