import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { A, Button } from "components/button";
import DollarInputV2 from "components/input/dollarInputV2";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import axios from "axios";
import StripeCheckout from "views/payment/components/StripeCheckout";
import { useTranslation } from 'react-i18next';

type SubStripeProps = {
	stripeAccountId: string;
	planId?: string | null;
	addonsArr?: object | null;
	addonsMixId?: string | null;
	ownerId?: string | null;
};

const SubscriptionStripeContainer = ({
	stripeAccountId,
	planId,
	addonsArr = null,
	addonsMixId,
	ownerId = null,
}: SubStripeProps) => {
	const { t } = useTranslation();
	const tBase = "views.subscription.subscriptionStripeContainer";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const [secret, setSecret] = React.useState();
	// const [billingDetails, setBillingDetails] = React.useState()
	// const [isDirect, setIsDirect] = React.useState(true)
	const [subId, setSubId] = React.useState("");
	const [subData, setSubData] = React.useState<object | null>(null);

	const date = new Date();
	const startDate = date.toDateString();
	date.setDate(date.getDate() + 30);
	const endDate = date.toDateString();

	const { mutate: checkSubscription } = useMutation(
		async () =>
			axios
				.post(
					`
						${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/subscription_checkout/
          `,
					{
						planId: planId,
						// addons will be null if plan is Essential or Enhanced
						addonsArr: addonsArr,
						addonsMixId: addonsMixId,
						startDate: startDate,
						endDate: endDate,
						ownerId: ownerId,
					}
				)
				.then((res) => res.data),
		{
			onSuccess: (data: any) => {
				setSecret(data.clientSecret);
				setSubId(data);
			},
			onError: (e) => console.error("error: ", e),
		}
	);

	const handleClickCheckout = () => {
		checkSubscription();
	};

	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE!);

	if (!secret) {
		return (
			<Button onClick={handleClickCheckout} primary>
				{tr("Checkout")}
			</Button>
		);
	}

	const options = {
		clientSecret: secret,
	};

	return (
		<Elements stripe={stripePromise} options={options}>
			<StripeCheckout isSubscription={true} subscriptionData={subData} />
		</Elements>
	);
};

export default SubscriptionStripeContainer;
