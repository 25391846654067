import axios from "axios";
import { Button, OutlineButton } from "components/button";
import Field from "components/field";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function CancelRequestModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.request.cancelRequestModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const queryClient = useQueryClient(),
    modal = useModalContext(),
    snackbar = useSnackbarContext();
  const [value, setValue] = React.useState("");

  const mutate = useMutation(
    async () =>
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/changeStatus`,
        {
          status: "canceled",
          id: props.id,
          reason: value,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        modal.setModal();
        snackbar.showSnackbar(tr("Request canceled!"));
      },
    }
  );

  return (
    <div className="space-y-4">
      <span>{tr("Please leave a reason for cancellation")}</span>
      <Field
        type="textarea"
        minRows={4}
        placeholder={tr("Reason...")}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="flex justify-end space-x-4">
        <Button
          primary
          type="submit"
          className="!bg-red-500 disabled:!bg-slate-100"
          disabled={!value.trim()}
          onClick={() => mutate.mutate()}
        >
          {tr("Submit")}
        </Button>
        <OutlineButton type="button" onClick={() => modal.setModal()}>
          {tr("Cancel")}
        </OutlineButton>
      </div>
    </div>
  );
}
