import axios from "axios";
import { useAreYouSure } from "components/areYouSure";
import BreadCrumbs from "components/breadcrumbs";
import {
  Button,
  IconButton,
  NoStyleA,
  OutlineButton,
  SubtleButton,
} from "components/button";
import DataLabel from "components/dataLabel";
import Dropdown from "components/dropdown";
import InlineEdit from "components/inline_edit";
import { useModalContext } from "components/modal";
import SafeArea from "components/safe-area";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import CustomerContainer from "containers/customer";
import LineItemsContainer from "containers/lineItems";
import { useAuthContext } from "contexts/auth";
import { format } from "date-fns";
import Switch from "rc-switch";
import * as React from "react";
import { RiMore2Line } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TInvoice } from "types/invoice";
import { dollarFormatter } from "utils";
import NewPaymentModal from "views/payment/newPaymentModal";
import { Status } from "views/request/component/status";
import TaxModal from "views/requests/components/taxModal";
import { MarkInvoiceAsSentModal } from "./markInvoiceAsSentModal";
import PayableOptions from "./payable-options/payable-options";
import PaymentContainer from "./payment";
import SendInvoiceModal from "./sendinvoiceModal";
import clients from "constants/clients";
import domains from "constants/domains";
import quickbooksDesktopEndpoints from "constants/routes/quickbooks/desktop";
import constructUrl from "utils/constructUrl";
import AppointmentTimes from "./appointment-times";
import AppointmentStatuses from "../../containers/appointmentCard/appointmentStatuses";
import AppointmentCardMini from "containers/appointmentCardMini";
import GenerateInvoicePDFModal from "./GenerateInvoicePDFModal";
import { useTranslation } from "react-i18next";
import { enUS, es } from "date-fns/locale";

export interface INewInvoicePayConfig {
  is_payable: boolean;
  allow_card: boolean;
  allow_ach: boolean;
}

export default function Invoice() {
  const { t, i18n } = useTranslation();
  const tBase = "views.invoice.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const { id } = useParams<{ id: string }>(),
    areYouSure = useAreYouSure(),
    history = useHistory(),
    snackBar = useSnackbarContext(),
    queryClient = useQueryClient(),
    auth = useAuthContext(),
    modal = useModalContext();

  const query = useQuery<TInvoice>(
    ["invoice", id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}
  `
        )
        .then((res) => res.data)
  );
  const sendInvoice = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/resend
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        snackBar.showSnackbar(tr("Invoice sent!"));
      },
    }
  );

  const updatePayOptions = useMutation(
    async (newConfig: INewInvoicePayConfig) =>
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/payable`,
          {
            ...newConfig,
          }
        )
        .then((res) => res.data),
    {
      onMutate: async (newConfig: INewInvoicePayConfig) => {
        await queryClient.cancelQueries(["invoice", id]);

        const prev = queryClient.getQueryData(["invoice", id]);

        queryClient.setQueryData(["invoice", id], (old: any) => ({
          ...old,
          ...newConfig,
        }));

        return { prev };
      },
      onError: (err, _, context: any) => {
        queryClient.setQueryData(["invoice", id], context.prev);
      },
    }
  );

  const handleUpdatePayOptions = async (newConfig: INewInvoicePayConfig) => {
    await updatePayOptions.mutateAsync(newConfig);
  };

  const updateQBSync = useMutation(
    async (e: boolean) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/qbsync?status=${e}
  `
        )
        .then((res) => res.data),
    {
      onMutate: async (e) => {
        await queryClient.cancelQueries(["invoice", id]);

        const prev = queryClient.getQueryData(["invoice", id]);

        queryClient.setQueryData(["invoice", id], (old: any) => ({
          ...old,
          qb_id: e ? null : "disabled",
        }));

        return { prev };
      },
      onError: (err, _, context: any) => {
        queryClient.setQueryData(["invoice", id], context.prev);
      },
    }
  );

  const deleteInvoice = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        history.goBack();
        snackBar.showSnackbar(tr("Invoice deleted!"));
      },
    }
  );
  const markInvoiceAsPaid = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/mark_as_paid
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        snackBar.showSnackbar(tr("Invoice marked as paid!"));
      },
    }
  );

  const handleClickAddToSyncLog = async () => {
    const client = clients.QUICKBOOKS;
    const domain = domains.DESKTOP;
    const endpoint = quickbooksDesktopEndpoints.SYNC_LOG;

    try {
      const response = await axios.post(
        constructUrl({ client, domain, endpoint }),
        {
          action: "add_or_modify",
          resource_type: "Invoice",
          resource_id: id,
        }
      );

      if (response.data) {
        snackBar.showSnackbar(tr("Added to sync log!"));
      }
    } catch (error) {
      snackBar.showSnackbar(tr("Error adding to sync log!"), error);
    }
  };

  const resyncInvoice = async () => {
    await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/quickbooks-online/sync-invoice`,
      { invoiceId: id }
    );
  };

  const markInvoiceAsPending = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${id}/mark_as_pending
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        snackBar.showSnackbar(tr("Invoice marked as pending!"));
      },
    }
  );

  if (query.isLoading) return <Spinner />;
  if (!query.data) return null;

  const status =
    query.data.status.id === 17
      ? "open"
      : query.data.status.id === 18
      ? "paid"
      : query.data.status.id === 37
      ? "pending"
      : "unpaid";

  const {
    is_payable: isPayable,
    allow_card: allowCard,
    allow_ach: allowAch,
  } = query.data;

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="flex justify-between py-4">
          <div>
            <BreadCrumbs link={[{ label: tr("Invoice"), to: "/invoices" }]} />
            <div className="text-baseline">
              <span className="text-3xl">
                {tr("Invoice")} #{query.data?.id}
              </span>
              <span className="mx-1 text-xl font-light text-slate-600">
                {tr("for")}
              </span>
              <NoStyleA to={`/customers/${query.data?.customer.id}`}>
                <span className="text-xl font-medium text-slate-800">
                  {query.data?.customer.name}
                </span>
              </NoStyleA>
              <Status color={query.data?.status.color || ""}>
                {tr(query.data?.status.label)}
              </Status>
            </div>
          </div>
          <div className="flex space-x-2">
            {status === "open" && (
              <Button
                primary
                isLoading={sendInvoice.isLoading}
                // disabled={!auth.charges_enabled}
                // onClick={() => sendInvoice.mutate()}
                onClick={() =>
                  modal.setModal({
                    component: (
                      <SendInvoiceModal
                        invoiceId={id}
                        emails={
                          query.data?.customer.email
                            ? [
                                query.data?.customer.email,
                                ...(query.data?.customer.additional_emails ||
                                  []),
                              ]
                            : null
                        }
                      />
                    ),
                    label: tr("Send Invoice"),
                  })
                }
              >
                {tr("Send Invoice")}
              </Button>
            )}
            {status === "unpaid" && (
              <OutlineButton
                primary
                // disabled={!auth.charges_enabled}
                onClick={() =>
                  modal.setModal({
                    component: (
                      <SendInvoiceModal
                        invoiceId={id}
                        emails={
                          query.data?.customer.email
                            ? [
                                query.data?.customer.email,
                                ...(query.data?.customer.additional_emails ||
                                  []),
                              ]
                            : null
                        }
                      />
                    ),
                    label: tr("Send Invoice"),
                  })
                }
                // onClick={() => sendInvoice.mutate()}
              >
                {tr("Resend Invoice")}
              </OutlineButton>
            )}

            <Dropdown
              reference={
                <IconButton square>
                  <RiMore2Line />
                </IconButton>
              }
              data={[
                {
                  button: [
                    status !== "open" && {
                      label: tr("Invoice PDF"),
                      onClick: () => {
                        modal.setModal({
                          component: (
                            <GenerateInvoicePDFModal
                              invoiceId={id}
                              emails={
                                query.data?.customer.email
                                  ? [query.data?.customer.email]
                                  : null
                              }
                            />
                          ),
                          label: tr("Invoice PDF"),
                        });
                      },
                    },
                    status !== "open" && {
                      label: tr("View as Customer"),
                      onClick: () =>
                        query.data?.hosted_invoice_url &&
                        window.open(query.data.hosted_invoice_url, "_blank"),
                    },
                    status !== "paid" &&
                      query.data?.amount_due === 0 && {
                        label: tr("Mark as Paid"),
                        onClick: () => markInvoiceAsPaid.mutate(),
                      },
                    // status !== "pending" && {
                    //   label: "Mark as Pending",
                    //   onClick: () => markInvoiceAsPending.mutate(),
                    // },
                    status !== "paid" && {
                      label: tr("Submit Payment"),
                      onClick: () =>
                        modal.setModal({
                          component: (
                            <NewPaymentModal
                              id={id}
                              type="invoice"
                              is_deposit={false}
                              amount_due={query.data.amount_due}
                            />
                          ),
                          label: tr("Submit Payment"),
                        }),
                    },
                    {
                      label: tr("Edit Tax/Discount/Fees"),
                      onClick: () =>
                        modal.setModal({
                          component: (
                            <TaxModal
                              id={query.data.id}
                              data={query.data.taxes}
                              type={"invoice"}
                            />
                          ),
                          label: tr("Tax"),
                        }),
                    },
                    status === "open" && {
                      label: tr("Mark Sent"),
                      onClick: () => {
                        modal.setModal({
                          component: <MarkInvoiceAsSentModal invoiceId={id} />,
                          label: tr("Mark Invoice as Sent"),
                        });
                      },
                    },
                    {
                      label: tr("Delete"),
                      onClick: () => areYouSure.activate(deleteInvoice.mutate),
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </SafeArea>
      <SafeArea>
        <div className={`py-4 space-y-8`}>
          <div
            className="flex items-center p-6 py-10 space-x-8 rounded-lg"
            style={{
              background: `rgba(${query.data.status.color}, 0.1)`,
              color: `rgb(${query.data.status.color})`,
            }}
          >
            <div className="flex flex-col">
              <span>{tr("Invoice Total")}</span>
              <span className="text-xl lg:text-5xl md:text-4xl">
                {dollarFormatter(query.data.actual_total)}
              </span>
            </div>
            <div className="text-xl lg:text-5xl md:text-4xl">-</div>
            <div className="flex flex-col">
              <span>{tr("Paid")}</span>
              <span className="text-xl lg:text-5xl md:text-4xl">
                {dollarFormatter(query.data.paid_total)}
              </span>
            </div>
            <div className="text-xl lg:text-5xl md:text-4xl">=</div>
            <div className="flex flex-col">
              <span>{tr("Due")}</span>
              <span className="text-xl lg:text-5xl md:text-4xl">
                {dollarFormatter(query.data.amount_due)}
              </span>
            </div>
          </div>
          <div className="divide-y">
            <div className="py-4">
              <h2>{tr("Summary")}</h2>
            </div>
            <div className="grid gap-4 py-4 md:grid-cols-2">
              {/* <DataLabel title="Invoice Total">
                <span className="text-3xl">
                  {dollarFormatter(query.data.actual_total)}
                </span>
              </DataLabel>
              <DataLabel title="Remaining Total">
                <span className="text-3xl">
                  {dollarFormatter(query.data.amount_due)}
                </span>
              </DataLabel> */}
              <DataLabel title={tr("Request ID")}>
                {query.data?.fk_request_id ? (
                  <NoStyleA
                    className="!text-blue-700 !underline"
                    to={`/requests/${query.data?.fk_request_id}`}
                  >{`${tr("Request")} #${query.data?.fk_request_id}`}</NoStyleA>
                ) : (
                  <span className="font-medium text-slate-400">
                    {tr("Unassigned")}
                  </span>
                )}
              </DataLabel>
              <DataLabel title={tr("Project ID")}>
                {query.data?.fk_project_id ? (
                  <NoStyleA
                    className="!text-blue-700 !underline"
                    to={`/projects/${query.data?.fk_project_id}`}
                  >{`${tr("Project")} #${query.data?.fk_project_id}`}</NoStyleA>
                ) : (
                  <span className="font-medium text-slate-400">
                    {tr("Unassigned")}
                  </span>
                )}
              </DataLabel>
              <DataLabel title={tr("PO Number")}>
                <InlineEdit
                  value={query.data?.po_number}
                  placeholder={tr("No PO Number")}
                  updateUrl={`company/invoice/${id}/po_number`}
                />
              </DataLabel>
              <DataLabel title={tr("Quickbooks Desktop")}>
                {query.data?.qb_id && query.data?.qb_id !== "disabled" ? (
                  <span className="">{query.data.qb_id}</span>
                ) : (
                  <div className="flex flex-row">
                    <Switch
                      checked={!query.data?.qb_id}
                      onChange={(e) => updateQBSync.mutate(e)}
                    />
                    <span className="mb-2 ml-2 font-medium text-slate-400">
                      {tr("Toggle Sync")}
                    </span>
                  </div>
                )}
                <OutlineButton onClick={handleClickAddToSyncLog}>
                  {tr("Add to Sync Log")}
                </OutlineButton>
              </DataLabel>
              <DataLabel title={tr("Payment Options")}>
                <PayableOptions
                  is_payable={isPayable}
                  allow_card={allowCard}
                  allow_ach={allowAch}
                  updatePayOptions={handleUpdatePayOptions}
                />
              </DataLabel>
              <DataLabel title={tr("Opened")}>
                {query.data.read_timestamp ? (
                  format(
                    new Date(query.data.read_timestamp),
                    "MMM dd, yyyy hh:mm aa",
                    {
                      locale: locale,
                    }
                  )
                ) : (
                  <span className="text-red-600">{tr("Pending")}...</span>
                )}
              </DataLabel>
              <DataLabel title={tr("Created On")}>
                <InlineEdit
                  type="date"
                  value={format(
                    new Date(query.data?.creation_date),
                    "yyyy-MM-dd"
                  )}
                  placeholder={tr("No PO Number")}
                  onSubmit={(value: any) =>
                    new Date(value + " 0:00").toISOString()
                  }
                  updateUrl={`company/invoice/${id}/creation-date`}
                />
              </DataLabel>

              <DataLabel title="Quickbooks Online">
                {/* Sample columns
                  t1.qbol_id, t1.qbol_message, t1.qbol_sync, t1.qbol_synced_at, t1.qbol_sync_token, t1.qbol_doc_num,
                */}
                <div>
                  <strong>QBO ID:</strong> {query.data.qbol_id}
                </div>
                <div>
                  <strong>{tr("Sync Token")}:</strong>
                  {query.data.qbol_sync_token}
                </div>
                <div>
                  <strong>{tr("Message")}:</strong> {query.data.qbol_message}
                </div>
                <div>
                  <strong>{tr("Synced At")}:</strong>{" "}
                  {query.data.qbol_synced_at
                    ? new Date(query.data.qbol_synced_at).toLocaleString()
                    : "Not Synced"}
                </div>
                <br className="mt-2" />
                <OutlineButton onClick={resyncInvoice}>QBO Sync</OutlineButton>
              </DataLabel>
            </div>
          </div>
          <CustomerContainer
            data={query.data.customer}
            selected_address={query.data.fk_address_id}
            size="lg"
          />
          <AppointmentCardMini
            id={
              query.data.fk_request_id
                ? query.data.fk_request_id
                : query.data.fk_project_id
            }
            type={query.data.fk_request_id ? "requests" : "projects"}
            // isBookit={props.bookit}
            // responses={responses}
          />
          <PaymentContainer data={query.data.payments} />
          <LineItemsContainer
            type="invoice"
            id={id}
            readOnly={query.data.status.id !== 17}
          />
          <div className="h-4" />
        </div>
      </SafeArea>
    </div>
  );
}
