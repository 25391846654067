/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Input from "components/searchableDropdown";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import ImageModal from "./imageModal";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  width: 100%;
  max-width: 600px;
  align-self: center;
  display: flex;
  padding: 1rem;
  // border: 1px solid #F0F0F0;
  margin-top: 1rem;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  margin-top: 1rem;
  > div {
    margin-bottom: 1rem;
  }
  > button {
    background: black;
    color: white;
    border-radius: 5px;
    border: 0;
    padding: 0.5rem 10rem 0.5rem 10rem;
    font-size: 1.3rem;
    &:disabled {
      background: grey;
      cursor: not-allowed;
    }
  }
`;

const Image = styled.div`
  position: relative;
`;

const ImageIcon = styled(AiOutlineCamera)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  background: black;
  color: white;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
`;

export default function (props) {
  const { t } = useTranslation();
  const tBase = "views.team.viewUser.edit";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const id = props.match.params.id;
  const history = useHistory();

  const [populateData, setData] = useState([]);
  const [image, setImage] = useState("");
  const [allowed, setAllowed] = useState(true);

  const [title, setTitle] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [birthday, setBirthday] = useState("");
  const [error, setError] = useState({});

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team/edit`,
          {
            params: {
              type: "ONE",
              id: id,
            },
          }
        );

        let {
          employee_job_title: title,
          first_name: first,
          last_name: last,
          employee_birth_date: birthday = "",
          // employee_status: status,
        } = res.data[0][0];

        birthday = birthday && moment(birthday).toISOString().substr(0, 10);

        setTitle(title);
        setFirst(first);
        setLast(last);
        setBirthday(birthday);
        setImage(res.data[1][id]);
        // setStatus(status === "Inactive" ? false : true);

        let populateInput = [];
        let datass = { title, first, last, birthday };
        for (var key in datass) {
          let title;
          let type;
          switch (key) {
            case "first":
              title = "First Name";
              break;
            case "last":
              title = "Last Name";
              break;
            case "birthday":
              title = "Birthday";
              type = "date";
              break;
            case "title":
              title = "Job Title";
              break;
            default:
              break;
          }
          populateInput.push(
            <Input
              key={key}
              label={key}
              title={tr(title)}
              type={type || ""}
              error={error[key]}
              inputVal={datass[key]}
              changed={(e) => {
                changed(e);
              }}
            />
          );
        }
        setData(populateInput);
      } catch (e) {
        throw e;
      }
    };

    fetchData();
  }, [error, id]);

  const submission = async (e) => {
    e.preventDefault();
    let errorCount = 0;
    console.error(error);
    let newObject = {};
    let data = {
      id,
      first,
      last,
      birthday,
      title,
    };
    for (var key in data) {
      if (!data[key]) {
        newObject[key] = "Required";
        errorCount++;
      }
    }

    if (
      !moment(data["birthday"], "YYYY-MM-DD", true).isValid() &&
      !newObject["birthday"]
    ) {
      newObject["birthday"] = "Invalid Date";
      errorCount++;
    }

    setError(() => newObject);

    if (errorCount) return;

    // const file = new File([blob], 'file.png')
    const formData = new FormData();

    // if (blob) { formData.append('image', file) }

    formData.append("type", "EDITUSER");
    formData.append("data", JSON.stringify(data));

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      history.goBack();
    } catch (e) {
      throw e;
    }
  };

  const changed = (e) => {
    setAllowed(false);
    switch (e.name) {
      case "first":
        setFirst(e.value);
        break;
      case "last":
        setLast(e.value);
        break;
      case "birthday":
        setBirthday(e.value);
        break;
      case "title":
        setTitle(e.value);
        break;
      default:
        break;
    }
  };

  return (
    <Con>
      <Image>
        <Avatar style={{ width: "100px", height: "100px" }} src={image} />
        <ImageIcon onClick={() => setModal(true)} />
      </Image>

      <Form onSubmit={(e) => submission(e)}>
        {populateData}
        <button disabled={allowed} type="submit">
          {tr("SUBMIT")}
        </button>
      </Form>
      {modal && (
        <ImageModal
          id={id}
          cancelDisplay={() => setModal(false)}
          editable={modal}
        />
      )}
    </Con>
  );
}
