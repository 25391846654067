import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  th,
  td {
    text-align: left;
    font-size: 14px;
    /* cursor: pointer; */
    white-space: nowrap;
  }

  th {
    // border-bottom: 1px solid ${theme.border.light};
    text-transform: capitalize;
    font-size: 0.85rem;
    font-weight: 900;
    color: ${theme.color.subtext2};
    /* background: ${theme.bg.blue}; */
    // border-top: 1px solid black;
    // border-bottom: 1px solid black;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      // border-left: 1px solid black;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      // border-right: 1px solid black;
    }
    > div {
      display: flex;
      align-items: center;
      > svg {
        color: ${theme.color.main};
        font-size: 1rem;
        margin-left: 0.5rem;
      }
    }
  }

  thead {
    > tr {
      // background: ${theme.bg.blue};
    }
  }

  td {
    text-transform: capitalize;
    /* border-bottom: 1px solid ${theme.border.light}; */
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TrBody = styled.tr<{ clickable?: boolean }>`
  ${(props) =>
    props.clickable &&
    `
    &:hover {
        cursor: pointer;
        background: ${theme.bg.hoverblue};
    }
    `}
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  // overflow: hidden;
`;
