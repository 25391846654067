import axios from "axios";
import React, { useEffect, useState } from "react";
import TagRow from "./components/TagRow";
import { Button } from "components/button";
import TagAddModal from "./components/TagAddModal";
import _ from "lodash";
import { useModalContext } from "components/modal";
import { NewTag } from "./components/NewTag";
import { useTags } from "./hooks/useTags";
import { TagList } from "./components/TagList";
import { useTranslation } from 'react-i18next';

function TagManager() {
  const { data, isError, isLoading } = useTags();
  const { setModal } = useModalContext();
  const [tags, setTags] = useState<any[]>([]);
  const [editing, setEditing] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
 
  const { t } = useTranslation();
  const tBase = "views.admin.tags.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleClickAddNewTag = () => {
    setModal({
      label: tr("New Tag"),
      component: <NewTag />,
    });
  };

  if (!tags) return null;

  return (
    <>
      <div className="flex items-center justify-between">
        <h1>{tr("Tags")}</h1>
        <Button onClick={handleClickAddNewTag} primary>
          {tr("New")}
        </Button>
      </div>
      {isLoading && <div>{tr("Loading")}...</div>}
      {isError && <div>{tr("Error")}...</div>}
      {data && <TagList tags={data} />}
    </>
  );
}

export default TagManager;
