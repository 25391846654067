import React from "react";
import styled from "styled-components";
import axios from "axios";
import { Button } from "components/button";
import { Avatar } from "components/avatar";
import { useModalContext } from "components/modal";
import NewUserModal from "./container/newuser";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { useQuery } from "react-query";
import { RiAddLine } from "react-icons/ri";
import { OwnerTag } from "components/tag/index";
import Switch from "rc-switch";
import {tohrs} from 'utils/tohrs'
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  padding: 1rem;
`;

const Title = styled.div`
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export default function Userlist() {
  const { t } = useTranslation();
  const tBase = "views.admin.userlist";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const history = useHistory();

  const [showInactive, setShowInactive] = React.useState(false);

  const fetchData = useQuery(
    "users",
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=ALLUSER`
        )
        .then((res) => res.data)
  );
  const StatusList = useQuery(
		"statlist",
		async () =>
			await axios
				.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/statuses`
				)
				.then((res) => {
					return res.data;
				})
	);

  const columns = {
    name: {
      label: "Name",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar showLabel user_id={row?.user_id}>
            {row?.full_name}
          </Avatar>
          {row.owner && <OwnerTag>{tr("Owner")}</OwnerTag>}
          {(row.status_inactive) && (
            <span className="text-red-600 bg-red-100/40 p-1 text-xs rounded">
              {tr("Inactive")}
            </span>
          )}
        </div>
      ),
    },
    division: {
      label: tr("Division"),
    },
    job_title_label: {
      label: tr("Job Title"),
    },
    username:{
      label: tr("Username"),
    },
    pin: {
      label: tr("TIMEServ Pin"),
    },
    status:{
      label:'Status',
      cell:(row)=>{
        if(StatusList.data?.find((a)=>a.user_id===row.user_id)===undefined){
          return 'No Status'
        }
        return (StatusList.data?.find((a)=>a.user_id===row.user_id)?.status+' ('+tohrs(StatusList.data?.find((a)=>a.user_id===row.user_id)?.timeinstatus)+')')
      }
    }

  };

  const handleClick = (row) => {
    if(row.user_id!==undefined){
    history.push(`/admin/employees/${row.user_id}/permission/team`);
    }
    else{
      setModal({
        component:<Button onClick={()=>{
          setModal()
        }} >{'Okay'}</Button>,
        label:'This Employee is not enabled as user on Eserv'
    })
    }
  
  };

  return (
    <Con>
      <Title>
        <h1>{tr("Employees")}</h1>
        <Button
          primary
          style={{ marginLeft: "auto" }}
          onClick={() =>
            setModal({
              component: <NewUserModal />,
              label: tr("Add New User"),
              props: { shouldCloseOnOverlayClick: false },
            })
          }
        >
          <RiAddLine /> {tr("Add New User")}
        </Button>
      </Title>
      <div>
        <p>{tr("Show Inactive Employees")}</p>
        <Switch checked={showInactive} onChange={setShowInactive} />
      </div>
      <Table
        handleClick={handleClick}
        columns={columns}
        data={fetchData?.data?.map((a)=>{
          if(a.status_inactive===true && showInactive===false){
            return null
          }
          return a
        }).filter((a)=>{
          if(a===null){
            return false
          }
          return true
        })}
      />
    </Con>
  );
}
