import React, { createContext, useContext, useReducer, useEffect } from "react";
import { getIntialState, persistState, removeState } from "./utils";

const DisplayModeStateContext = createContext();
const DisplayModeDispatchContext = createContext();

const defaultState = { displayMode: false };

const STORAGE_KEY = "displayMode";

const initialState = getIntialState(STORAGE_KEY) ?? defaultState;

const displayModeReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_MODE": {
      return {
        displayMode: action.displayMode,
      };
    }
    default: {
      return { ...state };
    }
  }
};

function DisplayModeProvider({ children }) {
  const [state, dispatch] = useReducer(displayModeReducer, initialState);

  useEffect(() => {
    persistState(STORAGE_KEY, state);
  }, [state]);

  return (
    <DisplayModeStateContext.Provider value={state}>
      <DisplayModeDispatchContext.Provider value={dispatch}>
        {children}
      </DisplayModeDispatchContext.Provider>
    </DisplayModeStateContext.Provider>
  );
}

function useDisplayModeState() {
  const context = useContext(DisplayModeStateContext);

  if (!context) {
    console.info(
      "useDisplayModeState must be used within a cart count context provider"
    );
  }

  return context;
}

function useDisplayModeDispatch() {
  const context = useContext(DisplayModeDispatchContext);

  if (!context) {
    console.info(
      "useDisplayModeDispatch must be used within a cart count context provider"
    );
  }

  return context;
}

export { DisplayModeProvider, useDisplayModeDispatch, useDisplayModeState };
