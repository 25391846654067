import React, { useEffect } from "react";
import { IUploadResult } from "../interface";

interface InitializeProgressListenerProps {
  setResults: (value: IUploadResult | null) => void;
  setUploadProgress: React.Dispatch<
    React.SetStateAction<{ processedRows: number; totalRows: number }>
  >;
  eventSourceRef: React.MutableRefObject<EventSource | null>;
  selected: string;
}

interface ProgressBarProps {
  uploadProgress: {
    processedRows: number;
    totalRows: number;
  };
}

// Function to initialize SSE for progress updates
const initializeProgressListener = ({
  setResults,
  setUploadProgress,
  eventSourceRef,
  selected,
}: InitializeProgressListenerProps) => {

  if (eventSourceRef.current) {
    eventSourceRef.current.close();
    console.log("Previous event source closed");
  };

  // Reset progress state before initializing a new EventSource.
  setUploadProgress({ processedRows: 0, totalRows: 1 });

  // Ensure that the EventSource is created only when this function is called
  const eventSource = new EventSource(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/progress/${selected}`,
    { withCredentials: true }
  );

  // Watch for progress updates
  eventSource.onmessage = (event) => {
    const progressData = JSON.parse(event.data);
    setUploadProgress(progressData);

    // Close the EventSource when upload is complete
    if (progressData.processedRows >= progressData.totalRows) {
      eventSource.close();
      console.log("event source closed");
    }
  };

  // Watch for results update
  eventSource.addEventListener("resultsUpdate", (event) => {
    if (event.data) {
      // Check if data is not undefined or empty
      try {
        const results = JSON.parse(event.data);
        // console.log("Results:", results);
        setResults(results); // Update your state with the results
      } catch (error) {
        console.error("Failed to parse results:", error);
      }
    }
  });

  eventSource.onerror = (error) => {
    console.error("EventSource failed:", error);
    eventSource.close();
  };

  eventSourceRef.current = eventSource;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ uploadProgress }) => {
  const percentage = (uploadProgress.processedRows / uploadProgress.totalRows) * 100;
  return (
    <div className="relative mb-5">
      <div className="text-center mb-2">
        <span className="text-lg text-secondary">{`${percentage.toFixed(0)}%`}</span>
      </div>
      <div className="rounded-full border border-secondary overflow-hidden">
        <div
          className="flex h-6 bg-gray-200 animate-pulse bg-gradient-to-r from-blue-200 to-blue-600"
          style={{ width: `${percentage}%` }}
        >
          {/* Empty div to allow for gradient fill */}
        </div>
      </div>
    </div>
  );
};

export { initializeProgressListener, ProgressBar };
