import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data2 = [
  {
    name: "Page A",
    uv: 3000,
    pv: 2000,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 2000,
    pv: 1200,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 1500,
    pv: 8000,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2000,
    pv: 3000,
    amt: 2000,
  },
];

const AnnualGraphs = () => {
  return (
    <div>
      <LineChart width={700} height={100} data={data}>
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#2233d3"
          strokeWidth={2}
          style={{ zIndex: 2 }}
        />
        <Line
          type="monotone"
          dataKey="uv"
          stroke="#dd1e1e"
          strokeWidth={2}
          style={{ zIndex: 1 }}
        />
      </LineChart>
    </div>
  );
};

export { AnnualGraphs };
