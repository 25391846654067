/* Created by Hannah Green on 02/09/2021 for ES-133 */

import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

/* Components */
import AddEditWhseGroupForm from "./addEditWhseGroup";
import AddEditWhseForm from "./addEditWhseForm";
import DeleteGroupForm from "./deleteGroup";
import DeleteWhseForm from "./deleteWarehouse";
import { useModalContext } from "components/modal";

/* Icons */
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

/* Table Imports */
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

/* Table Utils */
const { EnhancedTableHead, sort, getComparator } = require("./table_utils");

export default function WarehouseGroupTable(props) {
	const { t } = useTranslation();
	const tBase = "views.warehouse_management.components.warehouseGroupTable";
	const tr = (key) => t(`${tBase}.${key}`);
	
	const { classes, noResults, items } = props;

	const { setModal } = useModalContext();

	const useStickyState = (key = "sticky", initialState = null) => {
		const [state, setState] = useState(() => {
			let fixedState = 0;
			const storedState = localStorage.getItem(key);

			if (storedState !== null) {
				fixedState = JSON.parse(storedState);
			} else {
				fixedState = storedState;
			}

			return fixedState ?? initialState;
		});

		useEffect(() => {
			localStorage.setItem(key, state);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [state]);

		const clearState = () => localStorage.removeItem(key);

		return [state, setState, clearState];
	};

	//State Variables
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("warehouse_id");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const groupHeadCells = [
		{
			id: "placeholder_2",
			numeric: false,
			disablePadding: true,
			label: "",
		},
		{
			id: "group_name",
			numeric: false,
			disablePadding: true,
			label: "Group Name",
		},
		{
			id: "edit_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
		{
			id: "delete_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
	];

	const warehouseHeadCells = [
		{
			id: "warehouse_name",
			numeric: false,
			disablePadding: true,
			label: tr("Warehouse Name"),
		},
		{
			id: "vehicle_name",
			numeric: false,
			disablePadding: true,
			label: tr("Vehicle Name"),
		},
		{
			id: "edit_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
		{
			id: "delete_item",
			numeric: false,
			disablePadding: true,
			label: "",
		},
	];

	//sort request handler
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	//Change page handler
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	//change rows handler
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

	const GroupEditToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<AddEditWhseGroupForm
						formType="edit"
						groupId={row.group_id}
					/>
				),
				label: tr("Edit Group"),
			});
		};

		return (
			<Tooltip title={tr("Edit")}>
				<IconButton aria-label="edit" onClick={handleClick}>
					<EditIcon />
				</IconButton>
			</Tooltip>
		);
	};

	const WhseEditToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<AddEditWhseForm
						formType="edit"
						warehouseId={row.warehouse_id}
					/>
				),
				label: tr("Edit Group"),
			});
		};

		return (
			<Tooltip title={tr("Edit")}>
				<IconButton aria-label="edit" onClick={handleClick}>
					<EditIcon />
				</IconButton>
			</Tooltip>
		);
	};

	const GroupDeleteToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<DeleteGroupForm
						id={row.group_id}
						name={row.group_name}
						closePopup={() => {
							setModal();
						}}
					/>
				),
				label: tr("Delete Group"),
			});
		};

		return (
			<Tooltip title={tr("Delete")}>
				<IconButton aria-label="delete" onClick={handleClick}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		);
	};

	const WhseDeleteToolTip = ({ row, setModal }) => {
		const handleClick = () => {
			setModal({
				component: (
					<DeleteWhseForm
						id={row.warehouse_id}
						name={row.warehouse_name}
						closePopup={() => {
							setModal();
						}}
					/>
				),
				label: tr("Delete Group"),
			});
		};

		return (
			<Tooltip title={tr("Delete")}>
				<IconButton aria-label="delete" onClick={handleClick}>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		);
	};

	const CollapsableTableRow = (props) => {
		const { row, classes } = props;
		const [open, setOpen] = useStickyState(row.group_id.toString(), false);

		return (
			<>
				<TableRow hover tabIndex={-1} key={row.group_id}>
					<TableCell align="left">
						{row.warehouses !== null && (
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => setOpen(!open)}
							>
								{open ? (
									<KeyboardArrowUpIcon />
								) : (
									<KeyboardArrowDownIcon />
								)}
							</IconButton>
						)}
					</TableCell>
					<TableCell align="left">{row.group_name}</TableCell>
					<TableCell align="right">
						<GroupEditToolTip {...{ row, setModal }} />
					</TableCell>

					<TableCell align="left">
						<GroupDeleteToolTip {...{ row, setModal }} />
					</TableCell>
				</TableRow>

				{row.warehouses && (
					<TableRow>
						<TableCell
							style={{ paddingBottom: 0, paddingTop: 0 }}
							colSpan={6}
						>
							<Collapse in={open} timeout="auto" unmountOnExit>
								<Box margin={1}>
									<Table
										size="small"
										aria-label="collapsible table"
									>
										<EnhancedTableHead
											classes={classes}
											order={order}
											orderBy={orderBy}
											onRequestSort={handleRequestSort}
											rowCount={row.warehouses.length}
											headCells={warehouseHeadCells}
										/>
										<TableBody>
											{sort(
												row.warehouses,
												getComparator(order, orderBy)
											)
												.slice(
													page * rowsPerPage,
													page * rowsPerPage +
														rowsPerPage
												)
												.map((row, index) => {
													return (
														<TableRow
															hover
															tabIndex={-1}
															key={
																row.warehouse_id
															}
														>
															<TableCell align="left">
																{
																	row.warehouse_name
																}
															</TableCell>
															<TableCell align="left">
																{
																	row.vehicle_name
																}
															</TableCell>
															<TableCell align="right">
																<WhseEditToolTip
																	{...{
																		row,
																		setModal,
																	}}
																/>
															</TableCell>
															<TableCell align="left">
																<WhseDeleteToolTip
																	{...{
																		row,
																		setModal,
																	}}
																/>
															</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
								</Box>
							</Collapse>
						</TableCell>
					</TableRow>
				)}
			</>
		);
	};

	return (
		<>
			<TableContainer>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
				>
					<EnhancedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={items.length}
						headCells={groupHeadCells}
					/>
					{noResults && (
						<TableBody>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
							<TableCell>{tr("No Results")}</TableCell>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableBody>
					)}
					{!noResults && (
						<TableBody>
							{sort(items, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									return (
										<CollapsableTableRow
											row={row}
											classes={classes}
										/>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[25, 50, 100]}
				component="div"
				count={items.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</>
	);
}
