export const productionType = [
  {
    value: "estimate",
    label: "Estimate",
  },
  {
    value: "usage",
    label: "Current Price",
  },
  {
    value: "invoice",
    label: "Invoice",
  },
];
