/* Created by Hannah Green for ES-171 on 6/22/2021 */
import React, { useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { cloneDeep } from 'lodash';
import RateTable from "../rates/rate-table";
import { Con, Form, SubmitButton, RateCon } from "./styles";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
const { TextArea } = require('components/input');
const { Input } = require('components/input');

export default function AddEditService(props) {
    const { t } = useTranslation();
    const tBase = "views.services.components.table.addEditServiceModal";
    const tr = (key) => t(`${tBase}.${key}`);

    const [form, setForm] = useState(props.service);
    const [categories, setCategories] = useState();
    const [rates, setRates] = useState([{
        rate_name: "Default Rate",
        rate_desc: "",
        qty_hrs: 0,
        reg_rate: 0,
        internal_cost: 0,
        member_rate: 0,
        service_id: form.id,
        new_product: true,
        deleted: false,
        add: false
    }]);
    const formType = props.formType;

    const cats = useQuery(
        'categories',
        async () =>
            await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/categories`
            ).then((res) => res.data),
        {
            onSuccess: (res) => {
                setCategories(res);
            }
        }

    );

    const getRates = useQuery(
        'getRates',
        async () => await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/getRatesByServiceId?service_id=${form.id}`
        ).then((res) => res.data),
        {
            onSuccess: (res) => {
                if (res) {
                    setRates(res);
                }
            }
        }
    );

    const addMutation = useMutation((form) => {
        axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/addService`,
            form,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    });

    const updateMutation = useMutation((form) => {
        axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/updateService`,
            form,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
    });

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setForm((state) => {
            const tempState = cloneDeep(state);
            tempState[name] = value;
            return tempState;
        });
    };

    const handleDropdown = (event) => {
        setForm((state) => {
            const tempForm = cloneDeep(state);
            tempForm.service_category = event;
            return tempForm;
        });
    };

    const handleCheckboxChange = (event) => {
        const target = event.target;
        const name = target.name;

        setForm((state) => {
            const tempForm = cloneDeep(state);
            tempForm[name] = target.checked;
            return tempForm;
        });
    };

    const submitForm = async (values) => {
        const { ...form } = values;
        form.rates = rates;
        const formData = new FormData();
        formData.append("form", JSON.stringify(form));

        if (formType === "edit") {
            updateMutation.mutate(formData);
        } else {
            addMutation.mutate(formData);
        }
    };

    return (
        <Con>
            <Form>

                <Input
                    label={tr("Service Name")}
                    required={true}
                    name="product_label"
                    onChange={handleInputChange}
                    value={form.product_label}
                />

                <TextArea
                    label={tr("Description")}
                    required={true}
                    name="description"
                    onChange={handleInputChange}
                    value={form.description}
                />

                <label>
                    {tr("Service Category")}
                    <Select
                        name="service_category"
                        options={categories}
                        onChange={handleDropdown}
                        value={form.service_category}
                    />
                </label>

                <FormControlLabel
                    control={<Switch checked={form.flat_rate} onChange={handleCheckboxChange} name="flat_rate" />}
                    label={tr("Flat Rate")}
                    color="orange"
                />

                <FormControlLabel
                    control={<Switch checked={form.status} onChange={handleCheckboxChange} name="status" />}
                    label={tr("Status")}
                    color="orange"
                />

                <RateCon>
                    <RateTable id={form.id} rates={rates} setRates={setRates} formType={formType} />
                </RateCon>

                <SubmitButton primary type="submit" onClick={() => {
                    submitForm(form);
                }}>{tr("Submit")}</SubmitButton>

            </Form>
        </Con>
    );
}