import React, { useCallback } from 'react';
import styled from 'styled-components';
import Select, { StylesConfig } from 'react-select';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

const ContainingDiv = styled.div`
    padding: 20px;

    button {
        background-color: #ff844b;
        border: none;
        cursor: pointer;
        padding: 15px;
        border-radius: 5px;
    }

    button:hover {
        background-color: #ddd;
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .pagination button {
        margin: 0 5px;
    }
`;

const SimpleTableCon = styled.div`
    .maintable {
        font-family: Arial, sans-serif;
        display: block;
        min-height: 30em;
        max-height: 30em;
        overflow: auto;
    }

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        box-sizing: border-box;
    }

    th {
        background-color: #f2f2f2;
        color: black;
    }
`;

interface DataRecord {
    [key: string]: any;
}

interface SimpleTableProps {
    columns: string[];
    records: DataRecord[];
    totalPages: number;
    page: number;
    limit: number | string;
    setPage: (page: number) => void;
    setLimit: (limit: number) => void;
    pagination: (page: number) => void;
    columnDataMapping: { [key: string]: string };
}

const SimpleTable: React.FC<SimpleTableProps> = ({
    columns,
    records,
    totalPages,
    page,
    limit,
    setPage,
    setLimit,
    pagination,
    columnDataMapping,
}) => {
    // console.count("SimpleTable");

    const renderPagination = useCallback(() => {
        const pageNumbers: (number | string)[] = [];
        let maxPageNumbersToShow = 7;

        if (totalPages <= 9) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            let startPage: number;
            let endPage: number;

            if (page >= totalPages - 3) {
                startPage = totalPages - 6;
                endPage = totalPages;
            } else if (page <= 4) {
                startPage = 1;
                endPage = 7;
            } else {
                startPage = page - 2;
                endPage = page + 2;
            }

            startPage = Math.max(startPage, 1);
            endPage = Math.min(endPage, totalPages);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i);
            }

            if (startPage > 1) {
                pageNumbers.unshift('...');
                pageNumbers.unshift(1);
            }

            if (endPage < totalPages) {
                pageNumbers.push('...');
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers.map((pageNumber, index) => {
            if (typeof pageNumber === 'string') {
                return <span key={index} style={{ margin: '0 5px', width: "40px", fontSize: "30px", textAlign: "center" }}>{pageNumber}</span>;
            }
            return (
                <button
                    key={index}
                    onClick={() => pagination(pageNumber)}
                    style={{
                        backgroundColor: pageNumber === page ? '#ff844b' : '#ddd',
                        color: pageNumber === page ? '#fff' : '#000',
                        width: '40px',
                        padding: '10px 0',
                        textAlign: 'center',
                    }}
                >
                    {pageNumber}
                </button>
            );
        });
    }, [page, totalPages]);

    const customStyles: StylesConfig<{ value: string; label: string }, false> = {
        control: (provided: any) => ({
            ...provided,
            width: '200px',
        }),
    };

    return (
        <ContainingDiv>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                <label style={{ fontSize: "1.2em", margin: "auto 10px" }}>Show:</label>
                <Select
                    onChange={(e) => setLimit(Number(e!.value))}
                    styles={customStyles}
                    options={[
                        { value: '10', label: '10' },
                        { value: '20', label: '20' },
                        { value: '50', label: '50' },
                        { value: '100', label: '100' },
                    ]}
                />
            </div>

            <SimpleTableCon>
                <div className="maintable">
                    <table>
                        <thead style={{ position: 'sticky', top: 0 }}>
                            <tr>
                                {columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {records.map((row, index) => (
                                <tr key={index}>
                                    {columns.map((column, colIndex) => (
                                        <td key={colIndex}>
                                            {row[columnDataMapping[column]]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </SimpleTableCon>

            <div className="pagination">
                <button
                    onClick={() => pagination(Math.max(1, page - 1))}
                    disabled={page === 1}
                >
                    <RiArrowLeftSLine />
                </button>

                {renderPagination()}

                <button
                    onClick={() => pagination(Math.min(totalPages, page + 1))}
                    disabled={page === totalPages}
                >
                    <RiArrowRightSLine />
                </button>
            </div>
        </ContainingDiv>
    );
};

export default SimpleTable;
