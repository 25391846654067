import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";
import { IEquipment } from "../types/equipment";

export const updateEquipment = async (updatedEquipment: IEquipment) => {
  const onSuccess = (res: AxiosResponse) => {
    return res.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "PUT",
    url: "/equipment",
    data: updatedEquipment,
  })
    .then(onSuccess)
    .catch(onError);
};
