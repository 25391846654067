import React from "react"
import styled from "styled-components"
import { useQuestionBuilderDispatch, useQuestionBuilderState } from "../context"
import { useTranslation } from 'react-i18next';

type TProps = {
    leadDate: string,
    setLeadDate: React.Dispatch<React.SetStateAction<string>>
    leadNumber: number,
    setLeadNumber: React.Dispatch<React.SetStateAction<number>>
}

export default function BookitChoice( {leadDate, setLeadDate, leadNumber, setLeadNumber} : TProps ) {
    const { t } = useTranslation();
    const tBase = "views.settings.questionBuilder.question.bookitChoice";
    const tr = (key: string) => t(`${tBase}.${key}`);

    const dispatch = useQuestionBuilderDispatch()
    
    const updateLeadDate = (interval:any) => {
      let date = new Date()
      date.setDate(date.getDate() + Number(interval.target.value))
    
      setLeadDate(date.toLocaleDateString())
      setLeadNumber(interval.target.value)

      dispatch({ 
          type:"handleLeadChange", 
          payload: { 
              leadTime: interval.target.value,
          }
      })
    }

    return (
        <CalendarCon>Minimum lead time:
            <input style={{borderWidth:"2px", borderColor:"black", width:"3em", marginLeft:"1em"}} value={leadNumber} type="number" min={0} max={90} onChange={(e:any) => updateLeadDate(e)} />            
            <div style={{marginTop:"1em"}}>
            <p>{tr("Requests won't be schedulable prior to")} {leadDate}</p>
            {/* <Calendar minDate={calendar} onChange={updateCalendar} showFixedNumberOfWeeks={true} value={null} /> */}
            </div>
        </CalendarCon>
    )
}

const CalendarCon = styled.div`
  margin: 1rem 0 0.5rem 0;
`;