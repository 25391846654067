import axios from "api/axios";
import { Button, NoStyleButton, OutlineButton } from "components/button";
import { Input } from "components/input";
import MultiInput from "components/input/multi";
import { useModalContext } from "components/modal";
import ReactSelect from "components/react-select";
import { SContent } from "components/segment";
import { Overline } from "components/typography/overline";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TEstimateCD } from "types/custom-doc";
import { TQuoteSubmission } from "types/project";
import { dollarFormatter } from "utils";
import { EstimateCustomDocumentContent } from "views/custom_document";
import Select from "react-select";
import { TEstimateOptions } from "types/estimate";
import { useSnackbarContext } from "components/snackbar";
import CustomDocModal from "containers/customDocModal";
import Assignee from "components/react-select/assignees";
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: string;
};

type TOption = { id: number; label: string; total: number; selected?: boolean };
type SubmitQuoteData = Omit<TQuoteSubmission, "options"> & {
  options: TOption[];
};

export default function SubmitQuoteModal({ projectId }: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.submitQuoteModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient(),
    history = useHistory();

  const [step, setStep] = React.useState(0),
    [data, setData] = React.useState<SubmitQuoteData[] | null>(null),
    [templateId, setTemplateId] = React.useState<string>(),
    [manager, setManager] = React.useState<{
      value: string;
      label: string;
    } | null>(null);
  const query = useQuery<{
    info: SubmitQuoteData[];
    manager: { value: string; label: string } | null;
  }>(
    ["submit quote"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects/${projectId}/submit-quote
    `
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        setData(res.info);
        setManager(res.manager);
      },
      refetchOnWindowFocus: false,
    }
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${projectId}/submit
  `,
          {
            data,
            templateId,
            fk_manager_id: manager?.value,
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setModal();
        snackbar.showSnackbar(tr("Quote sent!"));
        history.push(`/projects/${projectId}`);
        queryClient.invalidateQueries();
      },
    }
  );

  if (!data) return null;

  const last = step === data.length - 1;

  const isFinalizing = step === data.length;

  const next = () => {
    setStep((s) => s + 1);
  };

  const back = () => setStep((s) => s - 1);

  const optionClick = (id: number) => {
    setData((data) => {
      let temp = cloneDeep(data)!;

      let optionIndex = temp[step].options.findIndex(
        (option) => option.id === id
      );
      let option = temp[step].options[optionIndex];

      if (option.selected) {
        option.selected = false;
      } else {
        option.selected = true;
      }

      return temp;
    });
  };

  const emailChange = (emails: (string | null)[]) => {
    setData((data) => {
      let temp = cloneDeep(data)!;

      temp[step].emails = emails;
      return temp;
    });
  };

  const handleSkip = () => {
    setStep((s) => s + 1);
  };

  return (
    <SCon>
      {isFinalizing ? (
        // <EstimateCustomDocumentContent formik={formik} />
        <>
          <CustomDocModal
            id={templateId}
            type="estimate"
            onClick={(e) => setTemplateId((id) => (id === e ? undefined : e))}
          />
          <Assignee
            label={tr("Manager")}
            value={manager}
            isMulti={false}
            onChange={(e: any) => setManager(e)}
          />
        </>
      ) : (
        <EachContractor
          {...{ step, optionClick, emailChange }}
          length={data.length}
          data={data[step]}
        />
      )}
      <div style={{ alignSelf: "flex-end" }} className="space-y-4">
        {step !== 0 && (
          <OutlineButton style={{ marginRight: "0.5rem" }} onClick={back}>
            {tr("Back")}
          </OutlineButton>
        )}
        {!isFinalizing && (
          <OutlineButton onClick={handleSkip} style={{ marginRight: "0.5rem" }}>
            {tr("Skip")}
          </OutlineButton>
        )}
        <Button
          onClick={() => (isFinalizing ? mutation.mutate() : next())}
          primary
          isLoading={mutation.isLoading}
          disabled={
            (!isFinalizing &&
              // data[step].emails.length === 0 ||
              data[step].options.filter((o) => o.selected).length === 0) ||
            (isFinalizing && (!templateId || !manager))
          }
        >
          {last ? tr("Finalize") : isFinalizing ? tr("Submit") : tr("Next")}
        </Button>
      </div>
    </SCon>
  );
}

const SCon = styled(SContent)`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 500px;
`;

type ECProps = {
  data: SubmitQuoteData;
  length: number;
  step: number;
  optionClick: (id: number) => void;
  emailChange: (email: (string | null)[]) => void;
};

export const EachContractor = (props: ECProps) => {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.submitQuoteModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const populateOptions = props.data.options.map((option) => {
    return (
      <EachOption
        id={option.id}
        selected={option.selected}
        total={option.total}
        label={option.label}
        optionClick={props.optionClick}
        key={option.id}
      />
    );
  });
  return (
    <SEachContractor>
      <Overline>
        {tr("Contractor")} {props.step + 1} {tr("of")} {props.length}
      </Overline>

      <h2 style={{ marginBottom: "0.5rem" }}>{props.data.name}</h2>
      {/* <Input label="Estimate Title" /> */}
      <Overline>{tr("Email")}</Overline>
      <MultiInput
        values={props.data.emails}
        onChange={props.emailChange}
        error={props.data.emails.length === 0}
      />
      {/* <Select
        isMulti
        creat
        defaultValue={props.data.emails.map((email) => ({
          value: email,
          label: email,
        }))}
      /> */}
      <Overline>{tr("Options")}</Overline>
      {populateOptions}
    </SEachContractor>
  );
};

const SEachContractor = styled.div`
  display: flex;
  flex-direction: column;
`;

const SOption = styled(NoStyleButton)<{ selected?: boolean }>`
  border: 1px solid
    ${(props) => (props.selected ? theme.color.main : theme.border.light)};
  background: ${(props) =>
    props.selected ? theme.bg.lightorange : "transparent"};
  padding: 0.5rem;
  margin: 0.5rem 0;
`;

export const EachOption = ({
  label,
  total,
  id,
  selected,
  optionClick,
}: {
  label: string;
  total: number;
  id: number;
  selected?: boolean;
  // option: TOption | TEstimateOptions;
  optionClick: (id: number) => void;
}) => {
  return (
    <SOption key={id} onClick={() => optionClick(id)} selected={selected}>
      <h3>{label}</h3>
      <h4 style={{ color: theme.color.subtext3 }}>{dollarFormatter(total)}</h4>
    </SOption>
  );
};
