import React, { useState, useEffect } from "react";
import { MenuButton } from "../../../components/button";
import { ContainerWithSidebar } from "../../../components/layout/index";
import { Button } from "../../../components/button";
import { FaTrashAlt } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import "./index.scss";

const Review = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.review.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [orders, setOrders] = useState([]);
  const [details, setDetails] = useState({});
  const [vendors, setVendors] = useState([]);

  const fetchReview = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReview`
    );

    setOrders(response.data);
  };

  const fetchReviewDetails = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReview/${id}`
    );

    setDetails(response.data.review);
    setVendors(response.data.vendors);
  };

  const removeCartItem = async (id) => {
    confirmAlert({
      title: tr("Delete Item"),
      message: tr("Are you sure you want to remove this item?"),
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: tr("Yes"),
          onClick: async () => {
            await axios.delete(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/removeCartItem`,
              {
                data: {
                  cartItemId: id,
                },
              }
            );

            fetchReviewDetails(details.id);
          },
        },
      ],
    });
  };

  const deleteOrder = async (orderId) => {
    confirmAlert({
      title: tr("Delete Order"),
      message: tr("Are you sure you want to delete this order?"),
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: tr("Yes"),
          onClick: async () => {
            await axios.delete(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/deleteOrder/${orderId}`
            );

            props.fetchCounts();
            fetchReview();
            setDetails({});
          },
        },
      ],
    });
  };

  const finalize = async (orderId) => {
    confirmAlert({
      title: tr("Finalize Order"),
      message: tr("Are you sure you want to finalize this order?"),
      buttons: [
        {
          label: "No",
          onClick: () => {},
        },
        {
          label: tr("Yes"),
          onClick: async () => {
            await axios.put(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/finalize`,
              {
                orderId,
              }
            );

            props.fetchCounts();
            fetchReview();
            setDetails({});
          },
        },
      ],
    });
  };

  useEffect(() => {
    fetchReview();
  }, []);

  return (
    <>
      <ContainerWithSidebar>
        <div style={{ backgroundColor: "#edf1f4" }}>
          <div className="review-menu-title">{tr("Pending Orders")}</div>
          <hr />
          {orders &&
            orders?.map((order) => {
              return (
                <MenuButton
                  style={{
                    textAlign: "left",
                    marginTop: 3,
                    marginBottom: 3,
                    fontSize: "90%",
                  }}
                  onClick={() => {
                    fetchReviewDetails(order.id);
                  }}
                >
                  <span>#{order.id}</span>
                  <span>{new Date(order.timestamp).toLocaleDateString()}</span>
                  <span>({order.name})</span>
                </MenuButton>
              );
            })}
        </div>
        <div>
          {details && details.hasOwnProperty("id") ? (
            <div>
              <div className="finalize-header">
                <div>Order: #{details.id}</div>

                <div>
                  {tr("Created")}: {new Date(details.timestamp).toLocaleDateString()}
                </div>

                <div>{tr("Entered By")}: {details.name}</div>

                <div>
                  <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                      deleteOrder(details.id);
                    }}
                  >
                    {tr("Delete")}
                  </Button>

                  <Button onClick={() => finalize(details.id)} primary>
                    {tr("Finalize")}
                  </Button>
                </div>
              </div>

              <div>
                <div className="items">
                  {details.items &&
                    details.items?.map((item, index) => {
                      return (
                        <div key={index} className="finalize-item">
                          <div className="part-details">
                            <div className="review-part-name">
                              <div>
                                {item.pc_name} - {item.category_name}
                              </div>
                              <div className="review-part">
                                {item.item_name}
                              </div>
                            </div>

                            <img
                              className="review-img"
                              src={item.imageUri}
                              alt={item.item_name}
                            />
                          </div>

                          <div className="detail-row">
                            <div className="review-detail-group">
                              <div className="review-detail-text">{tr("Minimum")}</div>
                              <di className="review-detail-text" v>
                                {item.minimum}
                              </di>
                            </div>

                            <div className="review-detail-group">
                              <div className="review-detail-text">{tr("Maximum")}</div>
                              <div className="review-detail-text">
                                {item.maximum}
                              </div>
                            </div>

                            <div className="review-detail-group">
                              <div className="review-detail-text">{tr("Stock")}</div>
                              <div className="review-detail-text">
                                {item.stock}
                              </div>
                            </div>
                          </div>

                          <div className="detail-row">
                            <div className="review-detail-group">
                              <div className="review-detail-text">
                                {tr("Outstanding")}
                              </div>
                              <div className="review-detail-text">
                                {item.outstanding || 0}
                              </div>
                            </div>

                            <div className="review-detail-group">
                              <div className="review-detail-text">
                                {tr("Recommended")}
                              </div>
                              <div className="review-detail-text">
                                {item.maximum - item.stock - item.outstanding >
                                0
                                  ? item.maximum - item.stock - item.outstanding
                                  : 0}
                              </div>
                            </div>

                            <div className="review-detail-group">
                              <div className="review-detail-text">{tr("Ordered")}</div>
                              <div className="review-detail-text">
                                {item.quantity}
                              </div>
                            </div>
                          </div>

                          <div className="detail-row">
                            <div className="review-detail-group-last">
                              <div className="review-detail-text">
                                {tr("Update Order")}
                              </div>
                              <QuantityInput
                                className="review-qty-input"
                                item={item}
                                fetchReviewDetails={fetchReviewDetails}
                                details={details}
                              />
                            </div>

                            <div className="review-detail-group-last">
                              <div className="review-detail-text">
                                {tr("Remove Item")}
                              </div>
                              <div className="review-trash-container">
                                <FaTrashAlt
                                  onClick={() => removeCartItem(item.id)}
                                  size={20}
                                  color="red"
                                  className="review-trash"
                                />
                              </div>
                            </div>
                          </div>

                          <VendorContainer
                            vendors={vendors}
                            details={details}
                            item={item}
                            fetchReviewDetails={fetchReviewDetails}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ContainerWithSidebar>
    </>
  );
};

const QuantityInput = (props) => {
  const [quantity, setQuantity] = useState(props.item.quantity);

  const updateQuantity = async (cartItemId, itemId, quantity) => {
    if (quantity && quantity >= 0) {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/updateQuantity`,
        {
          cartItemId,
          itemId,
          quantity,
        }
      );

      if (response.status === 200) {
        setQuantity(quantity);
        props.fetchReviewDetails(props.details.id);
      }
    }
  };

  return (
    <input
      className="review-input"
      value={quantity}
      onChange={(e) => setQuantity(e.target.value)}
      onBlur={(e) =>
        updateQuantity(props.item.id, props.item.item_id, e.target.value)
      }
    />
  );
};

const VendorContainer = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.review.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [value, setValue] = useState(props.item.vendor);
  const [other, setOther] = useState("");
  const [inFocus, setInFocus] = useState(false);

  const updateVendor = async (vendor) => {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/updateVendor`,
      { orderId: props.details.id, itemId: props.item.item_id, vendor }
    );

    if (response.status === 200) {
      setValue(vendor);
    }
  };

  return (
    <div className="vendor-list">
      {tr("Choose Vendor")}:
      {props.item.hasOwnProperty("vendors") &&
        props.item.vendors &&
        props.item.vendors?.map((vendor) => {
          return (
            <div
              onClick={() => updateVendor(vendor.vendor_id)}
              className={
                vendor.vendor_id == value
                  ? "vendor-detail-selected"
                  : "vendor-detail"
              }
            >
              <div>{vendor.vendor_name}</div>
              <div className="vendor-item-details">
                <div>
                  {tr("Price")}:{" "}
                  {(vendor.price / 100).toLocaleString("en-us", {
                    style: "currency",
                    currency: "USD",
                  })}
                </div>
                <div>Lead Time: {vendor.lead_time}</div>
              </div>
            </div>
          );
        })}
      {inFocus ? (
        <div onClick={() => setInFocus(false)} className="auto-complete-bg" />
      ) : null}
      <div className="vendor-other-container">
        <div
          onClick={() => updateVendor(other)}
          className={
            other == value ? "vendor-detail-selected" : "vendor-detail"
          }
          style={{ padding: 1 }}
        >
          <input
            placeholder={tr("Other Vendor")}
            className="review-vendor-other-input"
            value={other}
            onChange={(e) => setOther(e.target.value)}
            onBlur={() => {
              updateVendor(other);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateVendor(other);
                setInFocus(false);
              }
            }}
            onFocus={() => {
              setInFocus(true);
            }}
          />
          <MdClear
            style={{ cursor: "pointer", marginRight: 10 }}
            onClick={() => setOther("")}
            size={28}
            color="gray"
          />
        </div>
        {inFocus ? (
          <div className="auto-complete-container">
            {props.vendors &&
              props.vendors.length > 0 &&
              props.vendors.map((vendor) => {
                let show = true;

                if (
                  props.item.hasOwnProperty("vendors") &&
                  props.item.vendors &&
                  props.item.vendors.length > 0
                ) {
                  props.item.vendors.forEach((itemVendor) => {
                    if (
                      parseInt(vendor.vendor_id) ===
                      parseInt(itemVendor.vendor_id)
                    ) {
                      show = false;
                    }
                  });
                }

                if (!show) return null;

                return (
                  <>
                    {vendor.vendor_name
                      .toLowerCase()
                      .includes(other.toLowerCase()) ? (
                      <div
                        onClick={() => {
                          updateVendor(vendor.vendor_name);
                          setOther(vendor.vendor_name);
                          setInFocus(false);
                        }}
                        className="auto-complete-item"
                      >
                        <div>{vendor.vendor_name}</div>
                      </div>
                    ) : null}
                  </>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Review;
