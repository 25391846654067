import * as React from "react";
import styled from "styled-components";
import { SemiTransparentInput } from "components/input";
import { FaLayerGroup } from "react-icons/fa";
import { IoMdAdd, IoMdClose, IoMdMore } from "react-icons/io";
import { FiCheck, FiX } from "react-icons/fi";
import { BiLink, BiUnlink } from "react-icons/bi";
import { useParams, useHistory, Prompt } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Link } from "components/link";
import {
  Category,
  useQuestionBuilderState,
  useQuestionBuilderDispatch,
} from "../context";
import Popper from "components/react-popperv2";
import { SubtleIconButton, SubtleButton, MenuButton } from "components/button";
import { Menu } from "components/menu";
import { useSnackbarContext } from "components/snackbar";
import { useTour } from "contexts/tour";
import { useTranslation } from 'react-i18next';

type EditCategory = {
  label: string;
  id: number;
};

export default function Sidebar() {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.sidebar.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [showInput, setShowInput] = React.useState(false),
    [showInput2, setShowInput2] = React.useState(false),
    history = useHistory(),
    queryClient = useQueryClient(),
    { showSnackbar } = useSnackbarContext(),
    { incompleteForm } = useQuestionBuilderState(),
    dispatch = useQuestionBuilderDispatch();

  // React.useEffect(() => {
  //   tour?.start();
  // }, []);

  const newCategory = useMutation(
    (newCategory: { label: string; type: "category" | "subquestion" }) =>
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question`,
          newCategory
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("questionCategories");
        history.replace(`/settings/questions/${res}`);
        showSnackbar(tr("Category created!"));
      },
    }
  );

  const query = useQuery<{
    categories: Category[] | null;
    subquestions: Category[] | null;
  }>(
    "questionCategories",
    () =>
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/category`
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        dispatch({ type: "setCategory", data: res.categories });
        dispatch({ type: "setSubquestions", data: res.subquestions });
      },
      refetchOnWindowFocus: false,
    }
  );

  const isNotEmpty = (e: string, type: "category" | "subquestion"): void => {
    let value = e.trim();
    if (!value) {
      type === "category" ? setShowInput(false) : setShowInput2(false);
    } else {
      newCategory.mutate({ label: value, type });
      type === "category" ? setShowInput(false) : setShowInput2(false);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    type: "category" | "subquestion"
  ) => {
    isNotEmpty(e.target.value, type);
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    type: "category" | "subquestion"
  ) => {
    if (e.key === "Enter" || !e.key) {
      let value = (e.target as HTMLInputElement).value;
      isNotEmpty(value, type);
    }
  };

  const populateTab = query.data?.categories?.map(
    (category: Category, i: number) => {
      return <Tab key={category.id} {...category} i={i} />;
    }
  );

  const populateSubquestions = query.data?.subquestions?.map(
    (category: Category, i: number) => {
      return <Tab key={category.id} {...category} i={i} />;
    }
  );

  return (
    <Con className="space-y-4">
      <Prompt
        message={tr("You may have an unsaved changes. Are you sure you want to leave?")}
        when={incompleteForm}
      />
      <div>
        <h6 className="px-4 py-2 font-bold text-sm text-slate-600">{tr("CATEGORY")}</h6>
        {populateTab}
        {showInput && (
          <Input
            onBlur={(e) => handleBlur(e, "category")}
            onKeyPress={(e) => handleKeyPress(e, "category")}
            autoFocus={true}
          />
        )}
        <SubtleButton onClick={() => setShowInput(true)} primary>
          <IoMdAdd />
          <span>{tr("Add Category")}</span>
        </SubtleButton>
      </div>
      <div>
        <h6 className="px-4 py-2 font-bold text-sm text-slate-600">
          {tr("SUB QUESTIONS")}
        </h6>
        {populateSubquestions}
        {showInput2 && (
          <Input
            onBlur={(e) => handleBlur(e, "subquestion")}
            onKeyPress={(e) => handleKeyPress(e, "subquestion")}
            autoFocus={true}
          />
        )}
        <SubtleButton onClick={() => setShowInput2(true)} primary>
          <IoMdAdd />
          <span>{tr("Add Sub Questions")}</span>
        </SubtleButton>
      </div>
    </Con>
  );
}

const TabButton = styled(Link)`
  padding: 0.5rem 0 0.5rem 1rem;
  > svg:first-child {
    color: #9a9bff;
  }
  border-radius: 0 25px 25px 0;
  /* &:hover {
    background: ${(props) => !props.selected && "rgba(0, 0, 0, 0.3)"};
  } */
  background: ${(props) =>
    props.selected ? "rgba(255, 79, 0,.2) !important" : "transparent"};

  > svg:nth-child(3) {
    margin-left: auto;
  }
`;

const Input = styled(SemiTransparentInput)`
  // margin: 0 1rem;
`;

const Con = styled.div`
  /* background: #f7fafc; */
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 0 0 270px;
  > h3 {
    padding: 0 1rem;
  }
`;

type TabProps = { i: number } & Category;

const Tab = ({ ...category }: TabProps) => {
  const { t } = useTranslation();
  const tBase = "views.settings.questionBuilder.sidebar.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [rename, setRename] = React.useState<number | undefined>(),
    queryClient = useQueryClient(),
    { showSnackbar } = useSnackbarContext(),
    tour = useTour(),
    params = useParams<{ id: string }>();

  const editCategory = useMutation(
    (editCategory: EditCategory) =>
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/edit`,
        editCategory
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionCategories");
        showSnackbar(tr("Category renamed!"));
      },
    }
  );

  const deleteCategory = useMutation(
    (deleteCategory: number) =>
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/question/delete`,
        { id: deleteCategory }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("questionCategories");
        showSnackbar(tr("Category deleted!"));
      },
    }
  );

  const handleDelete = (id: number) => {
    deleteCategory.mutate(id);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleRename = (id: number, close: () => void) => {
    close();
    setRename(id);
  };

  const handleBlurEdit = (e: React.FocusEvent<HTMLInputElement>) => {
    rename && isEditNotEmpty({ label: e.target.value, id: rename });
  };

  const handleKeyPressEdit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || !e.key) {
      let value = (e.target as HTMLInputElement).value;
      rename && isEditNotEmpty({ label: value, id: rename });
    }
  };

  const isEditNotEmpty = ({ label, id }: EditCategory): void => {
    let value = label.trim();
    if (!value) {
      setRename(undefined);
    } else {
      editCategory.mutate({ label: value, id });
      setRename(undefined);
    }
  };

  const category_status =
    !category.is_primary &&
    (category.is_link ? (
      <FiCheck className="text-green-600" />
    ) : (
      <FiX className="text-red-600" />
    ));

  const isRename = rename === category.id;

  return (
    <TabButton
      key={category.id}
      // ref={(ref) => i === 0 && tour?.setStep(ref, 0)}
      selected={+params.id === category.id}
      to={`/settings/questions/${category.id}`}
      className="!flex"
      size="small"
    >
      <FaLayerGroup />
      {isRename ? (
        <input
          autoFocus
          onFocus={handleFocus}
          defaultValue={category.label}
          onBlur={handleBlurEdit}
          onKeyPress={handleKeyPressEdit}
        />
      ) : (
        <span className="flex-1">{category.label}</span>
      )}

      {category_status}
      {!category.is_primary && (
        <Popper
          // placement="bottom-end"
          reference={({ toggle }) => (
            <SubtleIconButton onClick={toggle} size="xsmall">
              <IoMdMore />
            </SubtleIconButton>
          )}
          container={({ close }) => (
            <Menu>
              <MenuButton
                onClick={() => handleRename(category.id, close)}
                style={{ width: "100%", textAlign: "right" }}
              >
                {tr("Rename")}
              </MenuButton>
              <MenuButton
                onClick={() => handleDelete(category.id)}
                style={{ width: "100%", textAlign: "right" }}
              >
                {tr("Delete")}
              </MenuButton>
            </Menu>
          )}
        />
      )}
    </TabButton>
  );
};
