import axios from "api/axios";
import Field from "components/field";
import * as React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Customer } from "views/requests/newRequestModal/customerForm";
import { components, OptionProps, Props } from "react-select";
import { MdVerifiedUser } from "react-icons/md";

type TProps = Omit<Props, "isMulti"> & {
  onChange: (e: any) => void;
  value: any;
  label?: string;
  isMulti?: boolean;
  name?: string;
  formik?: any;
  error?: string;
};

export default function ClientSelect(props: TProps) {
  const getCustomers = useMutation(
    async (e: string) =>
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers`, {
          params: {
            type: "autoComplete",
            search: e,
          },
        })
        .then((res) => res.data)
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: Customer[]) => void
  ) => {
    getCustomers
      .mutateAsync(inputValue)
      .then((res) =>
        callback(
          res.map((customer: Customer) => ({
            ...customer,
            label: customer.name,
            value: customer.id,
          }))
        )
      )
      .catch((e) => {
        throw e;
      });
  };

  return (
    <Field
      type="select"
      isAsync
      loadOptions={loadOptions}
      defaultOptions
      components={{ Option }}
      style={{ width: "100%" }}
      {...props}
    />
  );
}

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-col">
        <div>
          <span className="text-sm flex items-center space-x-1">
            <span>{props.data.name}</span>

            {props.data.fk_customer_id && (
              <MdVerifiedUser className="text-blue-600 text-xs" />
            )}
          </span>
        </div>

        <span className="text-xs text-slate-500">
          {props.data.email} | {props.data.phone}{" "}
        </span>
      </div>
    </components.Option>
  );
};
