import React, { useState } from "react";
import styled from "styled-components";
import Permission from "./employee-permissions/permission/index";
import { ContainerWithSidebar } from "components/layout";
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useHistory,
} from "react-router-dom";
import EmployeeInfo from "./employee-info";
import { useQuery } from "react-query";
import axios from "api/axios";
import Spinner from "components/spinner";
import OtherSettings from "./employee-permissions/permission/components/OtherSettings"
import Tab from "components/tab";
import CommissionSummary from "./employee-permissions/commission";
import Commission from "views/commission";
import UserAbsence from "./employee-permissions/absence";
import { useTranslation } from 'react-i18next';

const List = styled.div``;

const Content = styled.div``;

export type UserInfo = {
  template_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  job_title_label: string;
  username: string;
  company_id: string;
  division: {
    id: number;
    label: string;
  } | null;
  imageUrl: string | null;
};

export default function User (
  props: RouteComponentProps<{ id: string; page: string; pageId?: string }>
) {
  const { t } = useTranslation();
  const tBase = "views.admin.user.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { id, page, pageId } = props.match.params;

  const history = useHistory();

  const infoQuery = useQuery<UserInfo>(
    ["employeeInfo", id],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin?type=PERSONAL&id=${id}`
        )
        .then((res) => res.data)
  );

  const info = infoQuery.data;

  if (!info) return <Spinner />;

  const tabData = {
    permission: {
      tab: tr("Permission"),
      content: (
        <>
          <Permission templateId={info.template_id} />
        </>
      ),
    },
    commission: {
      tab: tr("Commission"),
      content: pageId ? (
        <Commission commissionId={pageId} />
      ) : (
        <CommissionSummary userId={id} />
      ),
    },
    absence: {
      tab: tr("Absence"),
      content: <UserAbsence id={id} />,
    },
  };

  return (
    <ContainerWithSidebar>
      <List>
        <EmployeeInfo id={id} info={info} />
      </List>
      <Content>
        <Tab
          data={tabData}
          defaultTab={page}
          onClick={(e) => history.push(`/admin/employees/${id}/${e}/`)}
        />
        {/* <Switch> */}
        {/* <Route
          render={(props) => (
            <Permission {...props} templateId={info.template_id} />
          )}
          path="/admin/employees/:id/permission/:permissionType?"
        /> */}
        {/* </Switch> */}
        {/* <OtherSettings userId={id} /> */}
      </Content>
    </ContainerWithSidebar>
  );
}
