import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, OutlineButton, SubtleButton } from "components/button";
import Field from "components/field";
import MultiInput from "components/input/multi";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import CustomDocModal from "containers/customDocModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TProperty } from "types/property";
import CustomerTag from "views/customer/customer-tag";
import { useAreYouSure } from "components/areYouSure";
import { FaInfoCircle } from "react-icons/fa";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';

type Props = {
  emails: string[] | null;
  invoiceId: string;
};

export default function SendInvoiceModal(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.invoice.sendinvoiceModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const snackbar = useSnackbarContext(),
    queryClient = useQueryClient(),
    modal = useModalContext(),
    snackBar = useSnackbarContext();

  const [templateId, setTemplateId] = useState<string>();
  const [emails, setEmails] = useState<(string | null)[] | null>();
  const [billing, setBilling] = useState<TProperty>();
  const [mailOnly, setMailOnly] = useState<boolean>(false);
  const [completion_notes, setCompletionNotes] = useState("");
  const [includeHeaders, setIncludeHeaders] = useState(true);
  const [noColor, setNoColor] = useState(false);
  const areYouSure = useAreYouSure();

  const getSendDetailsQuery = useQuery(
    ["invoice-send-details"],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/send-details`
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onSuccess: (details) => {
        if (details) {
          setBilling(details.sendDetails[0].billing_address);
          setTemplateId(details.sendDetails[0].fk_customdoc_id);
          setCompletionNotes(details.sendDetails[0].completion_notes);
          if (details.mailOnly) {
            setMailOnly(details.mailOnly);
          }
        }
      },
    }
  );

  const billingAddress = useQuery<TProperty[] | null>(
    ["billing address"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/billing_address
  `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/send
  `,
          { 
            templateId, 
            emails, 
            completion_notes, 
            billing,
            includeHeaders,
            noColor
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        snackbar.showSnackbar(tr("Invoice sent!"));
        queryClient.invalidateQueries();
        modal.setModal();
      },
    }
  );

  const preview = useMutation(
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoice/${props.invoiceId}/preview`,
          {
            params: {
              templateId,
              completion_notes,
              billing,
              includeHeaders,
              noColor,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
    }
  );

  const handlePreview = () => {
    preview.mutate();
  };
  useEffect(() => {
    if (props.emails) {
      setEmails(props.emails);
    }
  }, [props.emails]);

  const populateBillingAddresses = billingAddress.data?.map((address) => {
    return (
      <label key={address.id} className="flex items-center space-x-2">
        <input
          type="radio"
          value={address.id}
          name="billing_address"
          onChange={(e: any) => {
            setBilling(address);
          }}
          checked={billing?.id === address.id}
        />
        <span> {address.full_address}</span>
      </label>
    );
  });

  return (
    <div className="max-w-[500px] space-y-4">
      {mailOnly && <CustomerTag color="red" label={tr("Mail Only")} />}
      <MultiInput values={emails} onChange={(e) => setEmails(e)} />
      <CustomDocModal
        id={templateId}
        type="invoice"
        onClick={(id) => setTemplateId((tId) => (tId === id ? undefined : id))}
      />
      <div>
        <h3>{tr("Billing Address")}</h3>
        <div className="flex flex-col">{populateBillingAddresses}</div>
      </div>
      <div>
        <h3>{tr("Completion Notes")}</h3>
        <div className="flex flex-col">
          <Field
            type="textarea"
            value={completion_notes}
            onChange={(e) => setCompletionNotes(e.target.value)}
            minRows={5}
          />
        </div>
      </div>
      <div className="flex items-center [&>*]:-my-2.5">
        <input 
          type="checkbox" 
          checked={includeHeaders} 
          onChange={() => setIncludeHeaders(!includeHeaders)} 
        />
        <label className="ml-2">{tr("Include Headers")}</label>
        <TooltippedElement 
          message={tr("Turn On/Off Headers for Mail Only Customers")}
          placement="top"
          element={
            <FaInfoCircle className="ml-1 text-secondary" />
          }
        />
      </div>
      <div className="flex items-center [&>*]:-mt-1.5 [&>*]:-mb-2.5">
        <input 
          type="checkbox" 
          checked={noColor} 
          onChange={() => setNoColor(!noColor)} 
        />
        <label className="ml-2">{tr("Black & White Print")}</label>
        <TooltippedElement 
          message={tr("Turn Off Color for Printing PDF")}
          placement="top"
          element={
            <FaInfoCircle className="ml-1 text-secondary" />
          }
        />
      </div>
      <div className="flex justify-end space-x-2">
        <SubtleButton
          isLoading={preview.isLoading}
          disabled={!templateId || !billing || preview.isLoading}
          onClick={handlePreview}
        >
          {tr("Preview")}
        </SubtleButton>
        <Button
          primary
          type="submit"
          isLoading={mutation.isLoading}
          disabled={emails?.length === 0 || !templateId || !billing}
          onClick={() => {
            if (mailOnly) {
              // Prompt the user before sending the invoice
              // areYouSure.activate(() => mutation.mutate(), {
              //   message:
              //     "This customer has a 'Mail Only' tag. Are you sure you want to proceed with sending the invoice?",
              // });
              areYouSure.activate(() => mutation.mutate(), {
                message:
                  tr("This customer has a 'Mail Only' tag. Are you sure you want to proceed with sending the invoice?"),
              });
            } else {
              // Directly send the invoice if there's no 'Mail Only' condition
              mutation.mutate();
            }
          }}
        >
          {tr("Send Invoice")}
        </Button>
        <OutlineButton onClick={() => modal.setModal()}>{tr("Cancel")}</OutlineButton>
      </div>
    </div>
  );
}
