/* Created by Hannah Green for ES-169 on 6/14/2021 */
import React, { useState } from "react";
import styled from "styled-components";
import TextArea from "components/textarea";
import {DetailsTable} from "./inspection_details_table";
import { ReviewButton, SubmitButton } from "./styles";
import { useMutation } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
	width: 100%;
`;

const StickyWrapper = styled.div`
	position: relative;
	height: 3rem;
`;

const Sticky = styled.div`
	@media only screen and (max-width: 1200px) {
		width: 41%;
	}

	@media only screen and (max-width: 1200px) and (orientation: portrait) {
		width: 65.5%;
	}
	position: fixed;
	width: 24.7%;
`;

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;

	> label {
		font-weight: bold;
		padding-left: 5px;
	}

	> div {
		width: 100%;
		padding-bottom: 10px;

		> textarea {
			width: 100%;
		}
	}
`;

export default function InspectionDetails(props) {
	const { t } = useTranslation();
	const tBase = "views.vehicle_inspection.dashboard.inspection-details";
	const tr = (key) => t(`${tBase}.${key}`);

	const inspection = props.inspection;
	const { setModal } = useModalContext();
	const [form, setForm] = useState({
		comments: inspection.comments,
		notes: inspection.notes,
	});

	const mutation = useMutation(
		(inspection_id) => {
			axios.put(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/mark_reviewed?inspection_id=${inspection_id}`
			);
		},
		{
			onSuccess: () => {
				window.location.reload();
				setModal();
			},
		}
	);

	const saveComments = useMutation(
		(form) => {
			axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/add_description`,
				form,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		},
		{
			onSuccess: () => {
				window.location.reload();
				setModal();
			},
		}
	);

	const updateForm = (textarea) => {
		const target = textarea.target;
		const value = target.value;
		const name = target.name;

		setForm((state) => {
			const tempForm = { ...state };

			tempForm[name] = value;

			return tempForm;
		});
	};

	const submitForm = (values) => {
		const { ...form } = values;

		form.inspection_id = inspection.inspection_id;

		const formData = new FormData();

		formData.append("form", JSON.stringify(form));

		saveComments.mutate(formData);
	};

	return (
		<Con>
			{!inspection.is_reviewed && (
				<StickyWrapper>
					<Sticky>
						<ReviewButton
							type="submit"
							primary
							onClick={() => {
								mutation.mutate(inspection.inspection_id);
							}}
						>
							{tr("Mark as Reviewed")}
						</ReviewButton>
					</Sticky>
				</StickyWrapper>
			)}

			{!inspection.is_reviewed && (
				<Form>
					<label>{tr("Comments")}</label>
					<div>
						<TextArea
							name="comments"
							value={form.comments}
							onChange={updateForm}
						/>
					</div>

					<label>{tr("Maintenance Notes")}</label>
					<div>
						<TextArea
							name="notes"
							value={form.notes}
							onChange={updateForm}
						/>
					</div>

					<SubmitButton
						primary
						onClick={() => {
							submitForm(form);
						}}
					>
						{tr("Save")}
					</SubmitButton>
				</Form>
			)}

			<DetailsTable inspection={inspection} />
		</Con>
	);
}
