import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";
import { IAppointmentType } from "../types/IAppointmentType";

export const getAppointmentTypes = async () => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data.sort((a: IAppointmentType, b: IAppointmentType) =>
      a.label.localeCompare(b.label)
    );
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "GET",
    url: "/appointment-types",
  })
    .then(onSuccess)
    .catch(onError);
};
