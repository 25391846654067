const styles = {
  planBoxes: {
      display:"inline-grid",
      // gridTemplateRows: "repeat(100, 70px)",
      alignItems:"center",
      // rowGap:"15px",
      // marginLeft:"5px",
      backgroundColor: "lightgrey",
      borderWidth:"10px",
      borderRadius:"10px",
      width:"20em",
      height:"55em",
      cursor:"pointer",
      // padding:"10px",
      margin:"5px",
      justifyItems:"center",
      justifyContent:"space-around",
      overflow:"hidden",

      // "&:hover": {
      //     backgroundColor:"yellow",
      // }
  },

  planBoxBorder: {
      width:"200px", 
      alignSelf:"center", 
      borderBottomWidth:"2px", 
      borderBottomColor: "grey"
  },

  planBoxContents: {
      display:"inline-grid",
      gridTemplateRows: "repeat(15, 50px)",
      alignItems:"center",
  }
}

export const planStyle = {
    display:"grid",
    gridColumnGap:"32px",
    gridRowGap:"32px",
    gridTemplateRows:"auto",
    gridTemplateColumns:"1fr 1fr 1fr",
    maxWidth:"70em",
    margin:"auto",
}

export const planCardStyle: React.CSSProperties = {
    position:"relative",
    height:"100%",
    color:"#07090d",
    backgroundColor:"white",
    border:"1px solid #edeff2",
    borderRadius:"12px",
    padding:"40px",
    transition:"all .3s ease-in-out",
}

export const planTitleStyle: React.CSSProperties = {
    color:"#0095DA",
    letterSpacing:".1em",
    textTransform:"uppercase",
    marginBottom:"16px",
    fontSize:"20px",
    fontWeight:"700",
    lineHeight:"24px",
}

export const planPriceWrapperStyle = {
    display:"flex",
    alignItems:"flex-end",
    marginBottom:"24px",
    paddingTop:"4px",
    paddingBottom:"4px",
}

export const planPriceStyle = {
    letterSpacing:"-0.03em",
    fontSize:"44px",
    fontWeight:"700",
    lineHeight:"44px",
}

export const planPeriodStyle = {
    // color: "#767b85",
    fontSize:"16px",
    fontWeight:"500",
    lineHeight:"24px",
}

export const planFeatureListStyle:React.CSSProperties = {
    display:"grid",
    gridColumnGap:"16px",
    gridRowGap:"16px",
    textAlign:"left",
    gridTemplateRows:"auto",
    gridTemplateColumns:"1fr",
}

export const planFeatureTextWrapperStyle = {
    display:"flex",
    gridColumnGap:"12px",
    color:"#818a9c",
    alignItems:"center",
}

export const planFeatureTextStyle = {
    fontSize:"16px",
    lineHeight:"1.5",
}

export const planUnderscoreStyle = {
    height:"1px",
    backgroundColor:"#000",
    marginTop:"24px",
    marginBottom:"43px",
}

export const planProceedButtonStyle = {
    display:"flex",
    minHeight:"48px",
    gridColumnGap:"8px",
    color:"#07090d",
    border:"1px solid #edeff2",
    backgroundColor:"#ffffff",
    borderRadius:"6px",
    justifyContent:"center",
    alignItems:"center",
    padding:"12px 24px",
    fontSize:"14px",
    fontWeight:"500",
    lineHeight:"24px",
    textDecoration:"none",
    width:"100%",
}

export const ChosenStyle = {
    planBox: {
        ...styles.planBoxes,
        backgroundColor: "#7c7c7c",
        borderColor: "#ff8349",
        cursor:"default",
    },

    border: {
        ...styles.planBoxBorder,
        borderBottomColor: "#ff8349"
    },

    contents: {
        ...styles.planBoxContents,
        opacity: "0.3"
    }
}

export const blockedStyle = {
    planBox: {
        ...styles.planBoxes,
        backgroundColor: "#7c7c7c",
        borderColor: "#7c7c7c",
        cursor:"default",
    },

    border: {
        ...styles.planBoxBorder,
        borderBottomColor: "#ff8349"
    },

    contents: {
        ...styles.planBoxContents,
        opacity: "0.3"
    }
}