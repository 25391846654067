import { AxiosError, AxiosResponse } from "axios";
import { Button } from "components/button";
import { Input } from "components/input";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { downloadInventoryUsage } from "../api/downloadInventoryUsage";

interface IProps {
  type: string;
}

interface IReportTypes {
  [key: string]: (
    startDate: string,
    endDate: string
  ) => Promise<AxiosResponse<any>>;
}

const downloadReportTypes = {
  inventoryUsage: downloadInventoryUsage,
};

const ReportParametersModal = ({ type }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.reports.components.ReportParametersModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleClickDownload = () => {
    setError(null);

    if (startDate && endDate) {
      // call download function returned from report object based on type
      (downloadReportTypes as IReportTypes)
        [type](startDate, endDate)
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `InventoryUsage_${new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((e: AxiosError) => {
          setError(e.message);
        });
    }
  };

  return (
    <div>
      <Input
        className="mt-2"
        value={startDate}
        onChange={handleChangeStartDate}
        type="date"
        label={tr("Start Date")}
      />
      <Input
        className="mt-2"
        value={endDate}
        onChange={handleChangeEndDate}
        type="date"
        label={tr("End Date")}
      />
      <div className="flex flex-row items-center justify-between">
        {error ? (
          <p className="text-red-500 max-w-[200px] text-sm">{error}</p>
        ) : (
          <div></div>
        )}
        <Button
          primary
          className="mt-2"
          onClick={handleClickDownload}
          disabled={!startDate || !endDate}
        >
          {tr("Download Report")}
        </Button>
      </div>
    </div>
  );
};

export default ReportParametersModal;
