import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

export const ContainerWithSidebar = styled.div`
  display: flex;
  > div:first-child {
    flex: 0 0 250px;
    resize: horizontal;
    /* background: ${theme.bg.lightblue}; */
    position: sticky;
    top: ${theme.height.navigation};
    height: calc(100vh - ${theme.height.navigation});
    overflow: auto;
  }
  > div:last-child {
    flex: 1;
    overflow: auto;
  }
`;
