import axios from "axios";
import { A } from "components/button";
import TableComponent from "components/table";
import * as React from "react";
import { RiAddFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { TWarrantyList } from "types/warranty";
import { useTranslation } from 'react-i18next';

export default function Warranties() {
	const { t } = useTranslation();
	const tBase = "views.warranties.index";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const query = useQuery<TWarrantyList[] | null>(
		["warranties"],
		async () =>
			await axios
				.get(
					`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty
    `
				)
				.then((res) => res.data)
	);

	const columns = {
		id: {
			label: "ID",
		},
		label: {
			label: tr("Label"),
			width: "100%",
		},
		item_count: {
			label: tr("Warranty Count"),
		},
	};

	return (
		<div>
			<div className="flex justify-between">
				<h1>{tr("Warranties")}</h1>
				<A to="/admin/warranty/new" primary>
					<RiAddFill /> {tr("Create New Warranty")}
				</A>
			</div>
			<div>
				<TableComponent
					data={query.data}
					columns={columns}
					to={(row) => `/admin/warranty/${row.id}`}
				/>
			</div>
		</div>
	);
}
