import axios from "axios";
import { SubtleIconButton } from "components/button";
import LineItemsContainer from "containers/lineItems";
import ReadLayout from "containers/lineItems/readLayout";
import * as React from "react";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import { useQuery } from "react-query";
import { TItemsContainer } from "types/items-container";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function LineItemsTab(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.request.lineItemsTab";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [showAppt, setShowAppt] = React.useState(false);
  let total = 0;

  const query = useQuery<TItemsContainer[] | null>(
    ["products", props.id],
    async () =>
      props.id !== "new"
        ? await axios
            .get(
              `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/request/${props.id}?get_child=true
        `
            )
            .then((res) => res.data)
        : []
  );

  const populateApptsItems = query.data?.map((val) => {
    total += val.actual_total;

    return (
      <div key={val.fk_appointment_id} className="py-2">
        <h3>{tr("Appointment")} #{val.fk_appointment_id}</h3>
        <ReadLayout data={val} id={val.fk_appointment_id} type="appointment" />
      </div>
    );
  });
  return (
    <div className="py-4">
      {query.data && (
        <div className="divide-y bg-green-100/40 rounded-lg p-4 border-green-700 border">
          <div className="flex justify-between items-center">
            <h2>
              {tr("Appointments")}{" "}
              <span className="font-thin">
                {total ? " | " + dollarFormatter(total) : ""}
              </span>
            </h2>
            <SubtleIconButton
              className="!bg-green-300"
              onClick={() => setShowAppt((s) => !s)}
            >
              {showAppt ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </SubtleIconButton>
          </div>
          {showAppt && (
            <div className="py-4 divide-y">{populateApptsItems}</div>
          )}
        </div>
      )}

      <LineItemsContainer id={props.id} type="request" title="Add On" />
    </div>
  );
}
