import React from "react";

import { SpecialOrder } from "types/special-order";
import SpecialOrderItemRow from "./SpecialOrderItemRow";
import { useTranslation } from 'react-i18next';

import styles from "./SpecialOrderRow.module.scss";

interface IProps {
  order: SpecialOrder;
}

const SpecialOrderRow = ({ order }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.invoice";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className={styles.rowContainer}>
      <div className={styles.orderDetails}>
        {order.user_name ? (
          <div>
            {tr("For")}: <span className={styles.nameText}>{order.user_name}</span>
          </div>
        ) : null}
        {order.added_by_name ? (
          <div>
            {tr("Added By")}:{" "}
            <span className={styles.nameText}>{order.added_by_name}</span>
          </div>
        ) : null}
        {order.label ? (
          <div>
            {tr("Label")}: <span className={styles.nameText}>{order.label}</span>
          </div>
        ) : null}
        {order.job ? (
          <div>
            {tr("Job")}: <span className={styles.nameText}>{order.job}</span>
          </div>
        ) : null}
      </div>
      {order.items &&
        order.items?.map((item) => <SpecialOrderItemRow item={item} />)}
    </div>
  );
};

export default SpecialOrderRow;
