import * as React from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import axios from "axios";
import Table, { useTable } from "components/table";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { Status } from "views/request/component/status";
import { SearchInput } from "components/input";
import Tab from "components/tab";
import qs from "query-string";
import Spinner from "components/spinner";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Quote = {
  created_on: string;
  fk_business_id: string;
  fk_project_id: string;
  fk_status_id: string;
  fk_user_id: string;
  id: string;
  label: string;
  name: string;
  project_label: string;
  reviewed_by: string;
  submitted_by: string | null;
  total_amount: number;
  status: {
    id: number;
    label: string;
    color: string;
  };
};

type Query = {
  quotes: Quote[];
  counts: { [key: string]: number };
  total_page: number;
};

const statuses = {};

export default function Projects(props: any) {
  const { t, i18n } = useTranslation();
  const tBase = "views.quotes.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  // const filters = useFilter();
  const history = useHistory();
  const [search, setSearch] = React.useState("");

  const params: any = qs.parse(props.location.search);

  const quotes = useQuery<Query>(
    ["quotes", search, params],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes`,
          {
            params: {
              ...params,
              search,
            },
          }
        )
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  const table = useTable({
    total: quotes.data?.total_page,
    pageIndex: +params.page,
    url: true,
    onPaginate: (page) => {
      history.push({
        search: qs.stringify({ ...params, page: page }),
      });
    },
  });

  if (!quotes.data) return null;

  const columns = {
    id: {
      label: tr("Quote #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: Quote) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    total_amount: {
      label: tr("Total"),
      style: {
        fontWeight: "bold",
      },
      cell: (row: Quote) =>
        row.total_amount ? dollarFormatter(row.total_amount) : "",
    },
    project_label: {
      label: tr("Project Label"),
      width: "50%",
      style: {
        maxWidth: "500px",
      },
    },
    label: {
      label: tr("Label"),
      maxWidth: "50%",
      style: {
        maxWidth: "500px",
      },
    },
    created_on: {
      label: tr("Created On"),
      cell: (row: Quote) => format(new Date(row.created_on), "MMM dd, yyyy", { locale: locale }),
    },
    // start_date: {
    //   label: "Projected Start Date",
    //   cell: (row: Quote) =>
    //     row.start_date ? format(new Date(row.start_date), "MMM dd, yyyy") : "-",
    // },
    // entry_date: {
    //   label: "Created on",
    //   cell: (row: Quote) =>
    //     format(new Date(row.entry_date), "MMM dd, yyyy hh:mm aa"),
    // },
  };

  const handleClick = (row: Quote) => {
    history.push(`/projects/${row.fk_project_id}/quote/${row.id}`);
  };

  const data = {
    all: {
      tab: tr("All Quotes"),
      count:
        Object.entries(quotes.data?.counts).reduce(
          (prev, [key, current]) => prev + current,
          0
        ) || 0,
    },
    draft: {
      tab: tr("Draft"),
      count: quotes.data.counts["Draft"] || 0,
    },
    finalizing: {
      tab: tr("Finalizing"),
      count: quotes.data.counts["Finalizing"] || 0,
    },
    reviewed: {
      tab: tr("Reviewed"),
      count: quotes.data.counts["Reviewed"] || 0,
    },
    submitted: {
      tab: tr("Submitted"),
      count: quotes.data.counts["Submitted"] || 0,
    },
    approved: {
      tab: tr("Approved"),
      count: quotes.data.counts["Approved"] || 0,
    },
    denied: {
      tab: tr("Denied"),
      count: quotes.data.counts["Denied"] || 0,
    },
  };

  const handleTabChange = (key: string) => {
    history.push({ search: qs.stringify({ ...params, page: 1, status: key }) });
  };

  return (
    <Con>
      <SHeader>
        <h1>{tr("Quotes")}</h1>
        <SearchInput
          type="search"
          placeholder={tr("Search Quotes")}
          onKeyDown={(e: any) => e.key === "Enter" && setSearch(e.target.value)}
          search
          style={{ width: "300px" }}
        />
        {/* <Filter /> */}
      </SHeader>
      <Tab
        data={data}
        defaultTab={(!Array.isArray(params.status) && params.status) || "all"}
        onClick={handleTabChange}
        content={
          !quotes.isSuccess ? (
            <Spinner />
          ) : (
            <Table
              {...table}
              {...{ columns, handleClick }}
              data={quotes.data?.quotes}
            />
          )
        }
      />
    </Con>
  );
}

const Con = styled.div`
  padding: 2rem;
`;

const SHeader = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
  > button:nth-child(3) {
    margin-left: auto;
  }
`;
