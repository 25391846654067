import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useTranslation } from 'react-i18next';

Modal.setAppElement("#root");

function WinterizationTable(props) {
	const { t } = useTranslation();
	const tBase = "views.winterization.winterization-table";
	const tr = (key) => t(`${tBase}.${key}`);

	const winterizations = props.winterizations;
	const labels = props.labels;

	const [complete, setComplete] = useState([]);
	const [modalItems, setModalItems] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);

	const handleCompleteChange = () => {
		let newLabels = [];

		for (let i = 0; i < labels.length; i++) {
			newLabels.push(labels[i]);
			for (let j = 0; j < complete.length; j++) {
				if (
					parseInt(complete[j].fk_checklist_id) ===
					parseInt(labels[i].id)
				) {
					newLabels.pop();
				}
			}
		}

		setModalItems(newLabels);
	};

	const formatTime = (time) => {
		const hours = parseInt(time.slice(0, 2));
		const minutes = parseInt(time.slice(3, 5));

		const suffix = hours >= 12 ? "pm" : "am";

		let newHours = hours;

		if (hours > 12) {
			newHours = hours - 12;
		}

		if (hours === "00") {
			newHours = 12;
		}

		return `${newHours}:${minutes.toString().padStart(2, "0")} ${suffix}`;
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleSetComplete = (complete) => {
		if (complete && complete.length > 0) {
			setComplete(complete);
		} else {
			setComplete([]);
		}
	};

	useEffect(() => {
		handleCompleteChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complete]);

	return (
		<div className="winterization-table__container">
			<div
				style={{ cursor: "default" }}
				className="winterization-table__row"
			>
				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__sf-id__header"
				>
					{tr("Job ID")}
				</div>

				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__date__header"
				>
					{tr("Start Time")}
				</div>

				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__customer__header"
				>
					{tr("Customer")}
				</div>

				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__techs__header"
				>
					{tr("Technician")}
				</div>

				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__counts__header"
				>
					{tr("Completed")}
				</div>

				<div
					style={{ cursor: "text" }}
					className="winterization-table__row__counts__header"
				>
					{tr("Total")}
				</div>
			</div>

			<Modal
				className="winterization__modal"
				isOpen={modalOpen}
				onRequestClose={handleModalClose}
				contentLabel={tr("Incomplete Checklist Items")}
			>
				<h3 className="winterization__modal__row-header">
					{tr("Incomplete Checklist Items")}
				</h3>
				{modalItems && modalItems.length > 0 ? (
					modalItems?.map((label) => (
						<div className="winterization__modal__row">
							- {label.label}
						</div>
					))
				) : (
					<div>{tr("All Items Complete")}</div>
				)}
			</Modal>

			{winterizations &&
				winterizations.length > 0 &&
				winterizations?.map((winterization, index) => {
					return (
						<div
							onClick={() => {
								handleSetComplete(winterization.complete);
								handleModalOpen();
							}}
							key={index}
							className="winterization-table__row"
						>
							<div className="winterization-table__row__sf-id">
								{winterization.service_fusion_id}
							</div>

							<div className="winterization-table__row__date">
								{winterization.start_time
									? formatTime(winterization.start_time)
									: null}
							</div>

							<div className="winterization-table__row__customer">
								{winterization.customer_name}
							</div>

							<div className="winterization-table__row__techs">
								{winterization.hasOwnProperty("techs") &&
									winterization.techs &&
									winterization.techs.length > 0 &&
									winterization.techs?.map((tech) => {
										return (
											<div>
												{tech.first_name}{" "}
												{tech.last_name}
											</div>
										);
									})}
							</div>

							<div className="winterization-table__row__counts">
								{winterization.checks_done}
							</div>

							<div className="winterization-table__row__counts">
								{winterization.checks}
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default WinterizationTable;
