import { useState } from "react";

// Third-party libraries
import { ReactQueryDevtools } from "react-query/devtools";

// Components
import Filters from "./Filters";

// import Validation from "./Validation";
import Table from "./Table";
import IFormValues from "../types/IFormValues";
import useProductionItems from "../hooks/useProductionItems";

const ProductionCentralTable = () => {
  const [formData, setFormData] = useState<IFormValues>({
    sortColumn: "id",
    sortOrder: "desc",
    startDate: "",
    endDate: "",
    limit: 100,
    offset: 0,
    divisionId: "",
    requestId: "",
    projectId: "",
    appointmentId: "",
    userId: "",
  });

  const { isLoading, isError, error, data, sum } = useProductionItems(formData);

  return (
    <div className="p-4 bg-neutral-50">
      <Filters
        isFetching={false}
        validationErrors={[]}
        formData={formData}
        setFormData={setFormData}
      />
      {/* <Validation /> */}
      <Table
        isLoading={isLoading}
        error={error}
        isError={isError}
        data={data}
        sum={sum}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  );
};

export default ProductionCentralTable;
