import { useQuery } from "react-query";
import getUsers from "../api/getUsers";
import IUser from "../types/IUser";

type QueryError = {
  message: string;
};

const useUsers = () => {
  const { data, isLoading, isError, error, refetch, isFetching } = useQuery<
    IUser[],
    QueryError
  >(["users"], getUsers);

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useUsers;
