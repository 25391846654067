import { AxiosError, AxiosResponse } from "axios";

import { companyClient } from "api/api";
import { IEquipment } from "../types/equipment";

export const insertEquipment = async (newEquipment: IEquipment) => {
  const onSuccess = (res: AxiosResponse) => {
    return res.data;
  };

  const onError = (error: AxiosError) => {
    return console.error(error);
  };

  return companyClient({
    method: "POST",
    url: "/equipment",
    data: newEquipment,
  })
    .then(onSuccess)
    .catch(onError);
};
