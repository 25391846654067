import axios from "axios";

import constructUrl from "utils/constructUrl";
import clients from "constants/clients";
import domains from "constants/domains";
import mapEndpoints from "constants/routes/map";

const client = clients.COMPANY;
const domain = domains.PROPERTY_MAP;
const endpoint = mapEndpoints.MARKER;

export const updateMarker = async (markerId: number, formData: FormData) => {
  const url = constructUrl({
    client,
    domain,
    endpoint: `${endpoint}/${markerId}`,
  });
  const response = await axios.put(url, formData);
  console.log("updateMarker response:\n", response);
  return response;
};
