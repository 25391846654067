import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
// import { useDispatch } from 'react-redux'
// import { MdArrowDropDown } from 'react-icons/md'


const Con = styled.div`
    position: absolute;
    z-index: 999;
    background: rgb(0,0,0,0.9);
    // min-width: 300px;
    box-shadow: 0 0 10px -5px grey;
    border-radius: 5px;
    // top: 100%;
    top: 0;
    left: 0%;
    right: 0;
    > div {
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-size: 1rem;
        text-align: left;
        font-weight: 400;
        &:hover {
            background: rgb(255,255,255,0.5);
            cursor: pointer;
        }
    }
    overflow: hidden;
`

export default function ({setContainer, children}) {
    const nodeSet = useRef(null);

    

    useEffect( () => {

        const handleClick = e => {
            if (!nodeSet.current.contains(e.target)) {
                // dispatch({type: 'BUTTONCLICK'})
                setContainer()
                return;
            }
            else setContainer(e.target)
    
    
        }

        document.addEventListener('click', handleClick, false)
        // nodeSet.current.scrollIntoView();
        return () => {
            document.removeEventListener('click', handleClick, false)
        }
    }, [setContainer])
    return (
        <Con ref={nodeSet}> 
            {/* <h5 style={{textAlign: 'left', marginLeft: '1rem'}}>Category</h5> */}
            {children}
        </Con>
    )
}