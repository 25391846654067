/* Created by Hannah Green on 7/22/2021 for ES-174 */

/* This is a class because the react-to-print library does not work with 
functions very well and whatever is being printed HAS to be a class */
import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from "styled-components";
import ReactToPrint from "react-to-print";
import { PrintButton, PrintCon } from './styles';
import { useTranslation } from 'react-i18next';

const UserInfo = styled.div`
    width: 100%;
    background-color: #ebedef;
    border: 1px solid #ebedef;
    border-radius: 5px;
    padding: 10px;

    > label {
        font-weight: bold;
        padding-left: 5px;
    }

    > div {
        width: 450px;
        padding-left: 10px;
    }
`;

const formatDate = (date) => {
    const oldDate = new Date(date);
    return oldDate.toLocaleDateString();
};

const CheckRowDivider = (props) => {
    return (
        <>
            <TableRow>
                <TableCell variant="head">{props.label}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </>
    )
}

const CheckRow = (props) => {
    let check = false;

    if (props.value === "G") {
        check = true;
    }

    return (
        <>
            <TableRow>
                <TableCell>{props.label}</TableCell>
                {check &&
                    <>
                        <TableCell align="center">{<CheckIcon />}</TableCell>
                        <TableCell align="center"></TableCell>
                    </>
                }

                {!check &&
                    <>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">{<CloseIcon />}</TableCell>
                    </>
                }
            </TableRow>
        </>
    )


}

const CheckTableHeader = () => {
    const { t } = useTranslation();
    const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
    const tr = (key) => t(`${tBase}.${key}`);
    
    return (
        <TableHead>
            <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">{tr("Good")}</TableCell>
                <TableCell align="center">{tr("Needs Checked")}</TableCell>
            </TableRow>
        </TableHead>
    )
}

const PrintTable  = () => {
    const { t } = useTranslation();
    const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
    const tr = (key) => t(`${tBase}.${key}`);

        return (
            <div>
                <UserInfo>
                    <h3 align="center">{tr("Vehicle Information")}</h3>

                    <label >{tr("Vehicle Number")}</label>
                    <div>{this.props.inspection.vehicle_number}</div>

                    <label>{tr("Mileage")}</label>
                    <div>{this.props.inspection.mileage}</div>

                    <label>{tr("Tech Name")}</label>
                    <div>{this.props.inspection.tech_name}</div>

                    <label>{tr("Inspection Date")}</label>
                    <div>{formatDate(this.props.inspection.inspection_date)}</div>

                    {this.props.inspection.description &&
                        <>
                            <label>{tr("Description")}</label>
                            <div>{this.props.inspection.description}</div>
                        </>
                    }

                    {this.props.inspection.is_reviewed && this.props.inspection.comments &&
                        <>
                            <label>{tr("Comments")}</label>
                            <div>{this.props.inspection.comments}</div>
                        </>
                    }

                    {this.props.inspection.is_reviewed && this.props.inspection.notes &&
                        <>
                            <label>{tr("Maintenance Notes")}</label>
                            <div>{this.props.inspection.notes}</div>
                        </>
                    }

                </UserInfo>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <CheckTableHeader />
                        <TableBody>
                            <CheckRowDivider label={tr("Maintenance")} />
                            <CheckRow label={tr("Belts")} value={this.props.inspection.belts} />
                            <CheckRow label={tr("Lights")} value={this.props.inspection.lights} />
                            <CheckRow label={tr("Leaks")} value={this.props.inspection.leaks} />
                            <CheckRow label={tr("Wipers")} value={this.props.inspection.wipers} />
                            <CheckRow label={tr("Mirrors")} value={this.props.inspection.mirrors} />
                            <CheckRow label={tr("Tire Wear")} value={this.props.inspection.tire_wear} />
                            <CheckRowDivider label={tr("Fluid Levels")} />
                            <CheckRow label={tr("Power Steering")} value={this.props.inspection.power_steering} />
                            <CheckRow label={tr("Oil")} value={this.props.inspection.oil} />
                            <CheckRow label={tr("Washer Fluid")} value={this.props.inspection.washer_fluid} />
                            <CheckRow label={tr("Antifreeze")} value={this.props.inspection.antifreeze} />
                            <CheckRow label={tr("Transmission Fluid")} value={this.props.inspection.trans_fluid} />
                            <CheckRow label={tr("Brake Fluid")} value={this.props.inspection.brake_fluid} />
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    
}

export const DetailsTable = () => {
    const { t } = useTranslation();
    const tBase = "views.vehicle_inspection.dashboard.inspection_details_table";
    const tr = (key) => t(`${tBase}.${key}`);

        return (
            <>
                <PrintCon>
                    <ReactToPrint
                        trigger={() => <PrintButton primary>{tr("Print Details")}</PrintButton>}
                        content={() => this.componentRef}
                    />
                </PrintCon>

                <PrintTable ref={(el) => (this.componentRef = el)} inspection={this.props.inspection} />

            </>
        )
    
}