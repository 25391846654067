import * as React from "react";
import {
  StyledLink,
  StyledUnderlinedLink,
  StyledMenuLink,
  StyledSubtleLink,
} from "./style";
import { StyledComponent } from "styled-components";
import { LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  selected?: boolean;
  color?: string;
  size?: string;
}

const Wrapper = (
  Component: StyledComponent<any, any, object, string | number | symbol>
) => {
  return React.forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
      return <Component {...props} {...{ ref }} />;
    }
  );
};

export const Link = Wrapper(StyledLink);

export const UnderlineLink = Wrapper(StyledUnderlinedLink);

export const MenuLink = Wrapper(StyledMenuLink);

export const SubtleLink = Wrapper(StyledSubtleLink);
