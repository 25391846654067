import React, { Component } from "react";
import "./Progress Bar.css";
import styled, { keyframes } from "styled-components";
import CountUp from "react-countup";
import { CSSTransition } from "react-transition-group";
import { GiAchievement } from "react-icons/gi";
import { FaPizzaSlice } from "react-icons/fa";
import data from "./ytd.json";
import { RiAddLine, RiArrowUpLine, RiArrowDownLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';


const progress = keyframes`
0% { opacity: 100%}
100% { opacity: 50% }
`;
const progress2 = keyframes`
0% { width: 0% }
100% { width: ${(props) => props.statess} }
`;
const progress3 = keyframes`
0% { box-shadow: 0px 0px 1vmin 0.5vmin rgb(0, 255, 200); }
70% { box-shadow: 0px 0px 10vmin 2vmin rgb(0, 255, 200); }
100% { box-shadow: 0px 0px 7vmin 1vmin rgb(0, 255, 200); }
`;
const Bar = styled.span`
  height: 0;
  width: 0;
  font-size: 2vmin;
  position: relative;
  top: -20px;
  left: -2vmin;
  color: rgb(0, 255, 200);
  opacity: 1;
  &.bar2-enter-active,
  &.bar2-appear-active {
    animation: ${progress} 0.5s ease-in forwards;
  }
`;
const Bar2 = styled.span`
  width: ${(props) => props.statess}%;
  background-image: linear-gradient(
    to right,
    rgb(0, 119, 255, 0),
    rgba(0, 119, 255, 0.5),
    rgb(0, 255, 200)
  );
  height: 100%;
  border-radius: 50px;
  &.bar2-enter-active,
  &.bar2-appear-active {
    animation: ${progress2} 1s ease-in-out;
    &:after {
      animation: ${progress3} 1s linear forwards;
    }
  }

  &:after {
    content: "";
    height: 0;
    width: 10px;
    border-radius: 25%;
    box-shadow: 0px 0px 7vmin 1vmin rgb(0, 255, 200);
    left: 100%;
    position: relative;
  }
`;

export default function ProgressBar(props) {
  const { t } = useTranslation();
  const tBase = "views.production.Progress Bar";
  const tr = (key) => t(`${tBase}.${key}`);

  const [thisState, setthisState] = React.useState( {
      width: 0,
      animate: true,
      index: 0,
      start: 0,
      end: 0,
    }); 
    React.useEffect(() => {
      setthisState({
        width: 0,
        animate: true,
        index: 0,
        start: 0,
        end: 0,
      })

    }, [props.data.goal, props.data.ytdtotal, props.data.lastytd]);


    const Functionalarrow = () => {
      if((props.data.lastytd)-(props.data.ytdtotal)<0){
        return <RiArrowUpLine/>
      }else{
        return <RiArrowDownLine/>
      }
     }


    return (
      <div className="flex justify-between px-1 space-x-1" style ={{width:'99%'}}>
        <div className="basis-1/6 bg-[#1d2026] rounded-md flex items-center justify-center">
          <GiAchievement style={{color:'#009900'}} />
          <span style={{color:'#009900'}}>
            ${props.data.goal.toLocaleString()}
          </span>
        </div>
        <div className="basis-4/5 flex items-center p-2 bg-[#1d2026] rounded-md">
          <div className="progress-bar">
          
            <CSSTransition
              in={thisState.animate}
              appear={true}
              timeout={1000}
              classNames="bar2"
              onEntered={() => setthisState({...thisState, animate: false })}
            >
              <Bar2 statess={((props.data.ytdtotal)/(props.data.goal))*100}></Bar2>
            </CSSTransition>
            <CSSTransition
              in={thisState.animate}
              appear={true}
              timeout={1000}
              className="text-sm"
              // classNames="bar2"
              onEntered={() => setthisState({...thisState, animate: false })}
            >
              <Bar statess={0}>
                <CountUp end={parseFloat(((props.data.ytdtotal)/(props.data.goal))*100)} duration={1} decimals={1} />%
              </Bar>
            </CSSTransition>
          </div>
          <GiAchievement style={{ fontSize: "5vmin", color: "#25ffab", marginLeft:'25px' }} />
        </div>
        <div className="basis-1/5 bg-[#1d2026] rounded-md flex items-center justify-center">
          <span style={{color:'#009900'}}>
            $<CountUp end={(props.data.ytdtotal)} duration={1} separator="," />
            <sup style={{fontSize:'14px', color:'#009900' }}>({tr("This")} YTD)</sup>
          </span>
        </div>
        <div className="basis-1/5 bg-[#1d2026] rounded-md flex items-center justify-center">
          <span style={{color:'#009900'}}>
          $<CountUp end={(props.data.lastytd)} duration={1} separator="," />
            <sup style={{fontSize:'14px'}}>({tr("Last")} YTD)</sup>
          </span>
        </div>
        <div className="basis-1/5 bg-[#1d2026] rounded-md flex items-center justify-center" style={{color:'#009900'}}>
          <Functionalarrow/>
          <span><CountUp end={(Math.abs(Math.floor((props.data.lastytd)-(props.data.ytdtotal))))} duration={1} separator="," />
            <sup style={{fontSize:'14px', color:'#009900' }}>({tr("Difference")})</sup>
          </span>
          
        </div>
      </div>
    );
  }
