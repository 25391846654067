import { useRifm } from "rifm";
import currency from "utils/currency";
import { Input } from "./index";
import type { InputProps } from "./index";

// type Props = {
//     name?: string;
//     value: string;
//     label?:
//     onChange: (e: string) => void;
// }

export default function DollarInput(
  props: Omit<InputProps, "onChange"> & { onChange: (e: string) => void }
) {
  const { value, onChange, ...rest } = props;

  const rifm = useRifm({
    value: ((value || value === 0) && (+value / 100).toString()) || "",
    onChange: (e) => {
      onChange && onChange(e);
    },
    format: (e) => currency.format(e),
    accept: /[\d\.]/g,
  });

  return (
    <Input
      // onChange={rifm.onChange}
      value={rifm.value}
    />
  );
}
