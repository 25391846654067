import axios from "api/axios";
import { MenuButton } from "components/button";
import Spinner from "components/spinner";
import * as React from "react";
import { useQuery } from "react-query";
import { theme } from "styles/theme";
import { TInventoryCategory } from "types/inventory";

type Props = {
  handleClick: (id: string | number) => void;
  selectedCategory: string | number;
};

export default function PartCategories(props: Props) {
  const query = useQuery<TInventoryCategory[]>(
    "parts categories",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/inventory/category
    `
        )
        .then((res) => res.data)
  );

  if (query.isLoading) return <Spinner />;

  const populateCategories = query.data?.map((category) => {
    const selected = props.selectedCategory === category.category_id;
    return (
      <MenuButton
        key={category.category_id}
        onClick={() => props.handleClick(category.category_id)}
        style={{
          whiteSpace: "pre-wrap",
          borderRadius: "5px",
          color: selected ? theme.color.main : "black",
        }}
      >
        {category.category_name}
      </MenuButton>
    );
  });
  return <div>{populateCategories}</div>;
}
