import ProductionCentralTable from "./components";
import { useTranslation } from 'react-i18next';

const ProductionCentral = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.production.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  return (
    <div>
      <h1>{tr("Production Central")}</h1>
      <p>{tr("Production Central is a tool for managing production.")}</p>

      <ProductionCentralTable />
    </div>
  );
};

export default ProductionCentral;
