import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import Form from "components/form2";
import { Input } from "components/input";
import { Button, MenuButton, SubtleButton } from "components/button";
// import Select from "components/react-select";
import NameAutocomplete from "components/select";
import InputPopper, {
  useInputPopper,
} from "components/react-popper/inputPopper";
import Popper from "components/react-popperv2";
import { useRifm } from "rifm";
import currency from "utils/currency";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import Select from "components/selectv2";
import { RiCheckboxBlankFill, RiCheckboxBlankLine } from "react-icons/ri";
import { theme } from "styles/theme";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

type Props = {};
export default function AddMultiplier(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.addMultiplier";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const inputPopper = useInputPopper();

  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();

  const queryClient = useQueryClient();

  const categories = useQuery(
    ["quote item categories"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/categories
  `
        )
        .then((res) => res.data)
  );

  const addMultiplier = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/multiplier
    `,
        body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote"]);
        setModal();
        showSnackbar(tr("Multiplier created!"));
      },
    }
  );

  const initialValues = {
    label: "",
    rate: "0",
    is_list: true,
    is_sale: true,
    category: [],
  };

  const validationSchema = Yup.object({
    label: Yup.string().required(),
    is_list: Yup.boolean().required(),
    is_sale: Yup.boolean().required(),
    category: Yup.array().required(),
  });

  const rowFormat = (row: any) => <div>{row.label}</div>;

  const onSubmit = (e: any) => {
    const allSelected = e["category"].length === categories.data?.length;

    addMultiplier.mutate({
      ...e,
      rate: e.rate.replace(/[^\d.]/g, ""),
      all_category: allSelected,
    });
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  const rifm = useRifm({
    value: formik.values.rate,
    onChange: (e) => formik.setFieldValue("rate", e),
    format: (e) => parseFloat(e.replace(/[^\d.]/g, "")).toFixed(2) + "%",
    accept: /[\d\.]/g,
  });

  const handleCategorySelect = (e: any) => {
    formik.setFieldValue("category", e.id);
    inputPopper.setValue(e.label);
    inputPopper.disable();
  };

  const allSelected =
    formik.values["category"].length === categories.data?.length;

  return (
    <Con>
      <Form formik={formik}>
        <Input name="label" label={tr("Label")} />
        <Input
          onFocus={(e) => e.target.select()}
          value={rifm.value}
          name="rate"
          label={tr("Rate")}
          // onChange={rifm.onChange}
        />
        <label>{tr("Category")}</label>
        <Popper
          reference={({ toggle }) => (
            <Button style={{ width: "100%" }} onClick={toggle}>
              {allSelected
                ? tr("All Category Selected")
                : formik.values["category"].length + tr(" Category Selected")}
            </Button>
          )}
          container={(props: any) => {
            return (
              <Select
                close={props.close}
                footer={true}
                onClick={(e: any) => {
                  let prev: any = [...formik.values["category"]];
                  let index = prev.findIndex((row: any) => row.id === e.id);
                  if (index === -1) {
                    prev.push(e);
                  } else {
                    prev.splice(index, 1);
                  }
                  formik.setFieldValue("category", prev);
                }}
                data={categories.data}
                rowFormat={(row: any) => {
                  const selected =
                    formik.values["category"]?.findIndex(
                      (cat: any) => cat.id === row.id
                    ) !== -1;
                  return (
                    <MultiSelectRow>
                      {selected ? (
                        <RiCheckboxBlankFill />
                      ) : (
                        <RiCheckboxBlankLine />
                      )}
                      {row.label}
                    </MultiSelectRow>
                  );
                }}
                header={() => (
                  <MenuButton
                    style={{
                      padding: "0.5rem",
                      borderBottom: `1px solid ${theme.border.light}`,
                    }}
                    onClick={() =>
                      allSelected
                        ? formik.setFieldValue("category", [])
                        : formik.setFieldValue("category", categories.data)
                    }
                  >
                    <MultiSelectRow>
                      {allSelected ? (
                        <RiCheckboxBlankFill />
                      ) : (
                        <RiCheckboxBlankLine />
                      )}
                      {tr("Select All")}
                    </MultiSelectRow>
                  </MenuButton>
                )}
              />
            );
          }}
        />
        {/* <InputPopper
          data={categories.data}
          {...{ rowFormat, inputPopper }}
          reference={<Input name="category" label="Category" />}
          onClick={handleCategorySelect}
        /> */}
        {/* // <Select name="category" options={[{ value: 1, label: "1" }]} /> */}
        <Input type="checkbox" name="is_list" label={tr("Apply to List")} />
        <Input type="checkbox" name="is_sale" label={tr("Apply to Sale")} />
        <Button
          type="submit"
          primary
          isLoading={addMultiplier.isLoading}
          disabled={!formik.isValid || !formik.dirty}
        >
          {tr("Add Multiplier")}
        </Button>
      </Form>
    </Con>
  );
}

const Con = styled.div`
  width: 400px;
  > form {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const MultiSelectRow = styled.div`
  display: flex;
  align-items: center;
  > svg {
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
`;
