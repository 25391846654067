import {
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Modal } from "@material-ui/core";
import { Button } from "components/button";
import DatePicker from "react-datepicker";
import ReactDOMServer from "react-dom/server";
import { BiRectangle } from "react-icons/bi";
import Select from "react-select";
import Switch from "rc-switch";
import axios from "axios";
import _ from "lodash";
import Map, { Layer, Source } from "react-map-gl";
import polyline from "@mapbox/polyline";
import "react-confirm-alert/src/react-confirm-alert.css";
import AssigneeSelect from "components/react-select/assignees";
import * as turf from "@turf/turf";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';

import MapboxDraw from "@mapbox/mapbox-gl-draw";

import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  FaCheckCircle,
  FaHome,
  FaMapMarker,
  FaMapMarkerAlt,
} from "react-icons/fa";

import styles from "../MapModal.module.scss";
import AppointmentMarker from "./AppointmentMarker";
import HomeMarker from "./HomeMarker";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const basemaps = [
  {
    id: 1,
    uri: "mapbox://styles/mapbox/streets-v11",
    label: "Streets",
  },
  {
    id: 2,
    uri: "mapbox://styles/mapbox/satellite-streets-v11",
    label: "Satellite - Streets",
  },
  {
    id: 3,
    uri: "mapbox://styles/mapbox/outdoors-v11",
    label: "Outdoors",
  },
  { id: 4, uri: "mapbox://styles/mapbox/light-v10", label: "Light" },
  { id: 5, uri: "mapbox://styles/mapbox/dark-v10", label: "Dark" },
  {
    id: 6,
    uri: "mapbox://styles/mapbox/navigation-day-v1",
    label: "Navigation - Day",
  },
  {
    id: 7,
    uri: "mapbox://styles/mapbox/navigation-night-v1",
    label: "Navigation - Night",
  },
];
class HomeControl {
  _onClick;
  _map;
  _container;

  constructor(onClick) {
    this._onClick = onClick;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("button");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this._container.style.display = "block";
    this._container.style.border = "none";
    this._container.style.borderRadius = "2px";
    this._container.style.boxShadow = "0 0 0 2px rgba(0, 0, 0, 0.1)";
    this._container.style.cursor = "pointer";
    this._container.style.backgroundPosition = "center";
    this._container.style.backgroundRepeat = "no-repeat";
    this._container.style.margin = "8px";
    this._container.style.padding = "7px";
    this._container.innerHTML = ReactDOMServer.renderToString(<FaHome />);
    this._container.onclick = this._onClick;
    return this._container;
  }

  onRemove() {
    if (this._container && this._container.parentNode) {
      this._container.parentNode.removeChild(this._container);
      this._map.off("click", this._onClick);
      this._map = undefined;
    }
  }
}
class RectangleButtonControl {
  constructor(clickCallback) {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    this._button = document.createElement("button");
    this._button.className = "mapboxgl-ctrl-icon";
    const iconSvg = ReactDOMServer.renderToStaticMarkup(<BiRectangle />);
    const iconDataUrl = `data:image/svg+xml;base64,${btoa(iconSvg)}`;
    this._button.style.backgroundImage = `url(${iconDataUrl})`;
    this._button.style.backgroundRepeat = "no-repeat";
    this._button.style.backgroundPosition = "center";
    this._button.style.backgroundSize = "50%";
    this._button.addEventListener("click", clickCallback);
    this._container.appendChild(this._button);
  }

  onAdd(map) {
    this._map = map;
    return this._container;
  }

  onRemove() {
    if (this._map) {
      this._button.removeEventListener("click", this.clickCallback);
      this._container.parentNode?.removeChild(this._container);
      this._map = undefined;
    }
  }
}

const MapComponent = (props) => {
  const { t } = useTranslation();
  const tBase = "views.appointments.MapComponents.MapComponent";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const markers = props.markers;
  const viewState = props.viewState;
  const setViewState = props.setViewState;
  const mapRef = props.mapRef;
  const drawRef = props.drawRef;
  const trip = props.trip;
  const optimizeState = props.optimizeState;
  const optimizeDispatch = props.optimizeDispatch;
  const home = props.home;
  const showAddresses = props.showAddresses;
  const showTimes = props.showTimes;
  const showMarkerDisplayList = props.showMarkerDisplayList;
  const showUnassigned = props.showUnassigned;
  const users = props.users;
  const selectedBasemapId = props.selectedBasemapId;
  const setShowAddresses = props.setShowAddresses;
  const setShowTimes = props.setShowTimes;

  const [mapObject, setMapObject] = useState(null);
  const modes = MapboxDraw.modes;
  const [showpoly, setShowpoly] = useState(false);
  const [showrect, setShowrect] = useState(false);

  const onRectangleButtonClick = useCallback(() => {
    if (drawRef.current) {
      drawRef.current.changeMode("draw_rectangle");
      setShowpoly(false);
      setShowrect(true);
    }
  }, []);

  const rectangleButtonControl = useMemo(
    () => new RectangleButtonControl(onRectangleButtonClick),
    [onRectangleButtonClick]
  );
  useEffect(() => {
    if (mapObject) {
      let map = mapObject;
      map.on("draw.create", (event) => {
        // console.log(event);
        const rectangleCoordinates = event.features[0].geometry.coordinates[0];
        const rectangle = turf.polygon([rectangleCoordinates]);
        let markerList = markers;
        const markersWithinRectangle = markerList.filter((marker) => {
          if (marker.center && marker.center.length > 0) {
            const longitude = marker?.center[0];
            const latitude = marker?.center[1];
            // console.log(latitude, longitude);
            if (longitude && latitude) {
              const point = turf.point([longitude, latitude]);
              return turf.booleanPointInPolygon(point, rectangle);
            }
          }

          return false;
        });
        // console.log(markersWithinRectangle);
        optimizeDispatch({
          type: "CLEAR",
        });
        for (let i = 0; i < markersWithinRectangle.length; i++) {
          const marker = markersWithinRectangle[i];
          const getAddress = () => {
            let address = null;

            if (
              marker.request_details &&
              marker.request_details[0] &&
              marker.request_details[0].customer_address
            ) {
              address = marker.request_details[0].customer_address;
            } else if (
              marker.project_details &&
              marker.project_details[0] &&
              marker.project_details[0].customer_address
            ) {
              address = marker.project_details[0].customer_address;
            }

            return address;
          };
          let address = getAddress();
          optimizeDispatch({
            type: "ADD",
            payload: { address, center: marker.center },
          });
        }

        // Clear the drawn shapes
        if (drawRef.current) drawRef.current.deleteAll();
      });
    }
  }, [mapObject, markers]);
  const rectdis = () => {
    if (showrect) {
      return "block";
    } else {
      return "none";
    }
  };
  const polydis = () => {
    if (showpoly) {
      return "block";
    } else {
      return "none";
    }
  };

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      onLoad={(e) => {
        const map = e.target;
        setMapObject(map);

        drawRef.current = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
          },
          modes: {
            ...modes,
            draw_rectangle: DrawRectangle,
          },
        });
        map.addControl(drawRef.current, "top-right");

        map.on("draw.create", (event) => {
          // console.log(event);
          const rectangleCoordinates =
            event.features[0].geometry.coordinates[0];
          const rectangle = turf.polygon([rectangleCoordinates]);
          let markerList = markers;
          const markersWithinRectangle = markerList.filter((marker) => {
            if (marker.center && marker.center.length > 0) {
              const longitude = marker?.center[0];
              const latitude = marker?.center[1];
              // console.log(latitude, longitude);
              if (longitude && latitude) {
                const point = turf.point([longitude, latitude]);
                return turf.booleanPointInPolygon(point, rectangle);
              }
            }

            return false;
          });
          // console.log(markersWithinRectangle);
          optimizeDispatch({
            type: "CLEAR",
          });
          for (let i = 0; i < markersWithinRectangle.length; i++) {
            const marker = markersWithinRectangle[i];
            const getAddress = () => {
              let address = null;

              if (
                marker.request_details &&
                marker.request_details[0] &&
                marker.request_details[0].customer_address
              ) {
                address = marker.request_details[0].customer_address;
              } else if (
                marker.project_details &&
                marker.project_details[0] &&
                marker.project_details[0].customer_address
              ) {
                address = marker.project_details[0].customer_address;
              }

              return address;
            };
            let address = getAddress();
            optimizeDispatch({
              type: "ADD",
              payload: { address, center: marker.center },
            });
          }

          // Clear the drawn shapes
          if (drawRef.current) {
            setShowrect(false);
            setShowpoly(false);
            drawRef.current.deleteAll();
          }
        });
        map.on("draw.modechange", (event) => {
          // console.log(event);
          if (drawRef?.current.getMode() === "draw_polygon") {
            setShowpoly(true);
            setShowrect(false);
          }
          if (drawRef?.current.getMode() === "draw_rectangle") {
            setShowpoly(false);
            setShowrect(true);
          }
        });

        const homeControl = new HomeControl(() => {
          map.flyTo({
            center: [-96.1345, 41.2565],
            zoom: 9,
          });
        });
        map.addControl(homeControl, "bottom-left");

        map.addControl(rectangleButtonControl, "top-right");
      }}
      mapStyle={
        basemaps.filter(
          (bm) => parseInt(bm.id) === parseInt(selectedBasemapId)
        )[0].uri
      }
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      style={{
        display: "flex",
        flex: 5,
        borderTop: "1px solid rgb(180, 180, 180)",
        borderBottom: "1px solid rgb(180, 180, 180)",
      }}
    >
      {home ? (
        <HomeMarker
          home={home}
          showAddresses={showAddresses}
          optimizeState={optimizeState}
        />
      ) : null}
      {markers &&
        markers.length > 0 &&
        markers?.map((m) => {
          let display = false;
          if (m && m.hasOwnProperty("assignees") && m.assignees) {
            m.assignees.map((a) => {
              if (showMarkerDisplayList.includes(parseInt(a.user_id))) {
                display = true;
                return a;
              } else {
                return a;
              }
            });
          }

          if (m.hasOwnProperty("assignees") && !m.assignees && showUnassigned) {
            display = true;
          }

          if (m.hasOwnProperty("center") && m.center && display) {
            return (
              <AppointmentMarker
                key={m.id}
                marker={m}
                users={users}
                center={m.center}
                filteredMarkers={markers}
                showAddresses={showAddresses}
                showTimes={showTimes}
                optimizeState={optimizeState}
              />
            );
          } else {
            return null;
          }
        })}
      {trip && (
        <Source id="route-source" type="geojson" data={trip}>
          <Layer
            id="route-layer"
            type="line"
            source="route-source"
            paint={{
              "line-color": "#005485",
              "line-width": 7,
              "line-blur": 0.8,
              "line-opacity": 0.8,
            }}
            layout={{ "line-cap": "round", "line-join": "round" }}
          />
        </Source>
      )}
      <div className={styles.mapToolsContainer}>
        <div className={styles.mapToolToggleContainer}>
          <div className={styles.mapToolLabel}>{tr("Addresses")}</div>
          <Switch checked={showAddresses} onChange={setShowAddresses} />
        </div>
        <div className={styles.mapToolToggleContainer}>
          <div className={styles.mapToolLabel}>{tr("Times")}</div>
          <Switch checked={showTimes} onChange={setShowTimes} />
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "4%",
          right: "15%",
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "1px",
        }}
      >
        <p style={{ margin: "10px", display: polydis() }}>
          {tr("Polygon select")}: {tr("click once to place a node and click a node to finish drawing")}
        </p>
        <p style={{ margin: "10px", display: rectdis() }}>
          {tr("Rectangle select")}: {tr("click once to place one corner and again to finish box")}
        </p>
      </div>
    </Map>
  );
};
export default MapComponent;
