import { useQuery } from "react-query";
import { getMapDataByPropertyId } from "../api/propertyMapDataApi";

const usePropertyData = (propertyId: number) => {
  const propertyMapMarkersQuery = useQuery("property-map-data", () =>
    getMapDataByPropertyId(propertyId)
  );

  return propertyMapMarkersQuery;
};

export { usePropertyData };
