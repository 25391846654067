import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatTimeHAM } from "utils/formatTimeHAM";
import { useTranslation } from 'react-i18next';

const AppointmentTimeTotals = (props: {appointmentIds: number[]}) => { 
  const { t } = useTranslation();
  const tBase = "containers.appointmentCardMini.components.AppointmentTimeTotals";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { appointmentIds } = props;
  const [onTheWayTime, setOnTheWayTime] = useState("");
  const [inProgressTime, setInProgressTime] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const summarizeStatusById = (statusId: number, data: any) => {
    let arrTemp = [];

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (
        element.type === "appointment-status" &&
        (parseInt(element.previous_value) === statusId ||
          parseInt(element.new_value) === statusId)
      ) {
        arrTemp.push(element);
      }
    }

    arrTemp = arrTemp.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });

    let onTheWaySum = 0;

    if (arrTemp.length === 0) {
      onTheWaySum = 0;
    } else if (arrTemp.length % 2 !== 0) {
      onTheWaySum += new Date().getTime() - new Date(arrTemp[0].timestamp).getTime();
      if (arrTemp.length > 1) {
        for (let i = 1; i < arrTemp.length; i += 2) {
          onTheWaySum +=
            new Date(arrTemp[i].timestamp).getTime() - new Date(arrTemp[i + 1].timestamp).getTime();
        }
      }
    } else {
      if (arrTemp.length > 1) {
        for (let i = 0; i < arrTemp.length; i += 2) {
          onTheWaySum +=
            new Date(arrTemp[i].timestamp).getTime() - new Date(arrTemp[i + 1].timestamp).getTime();
        }
      }
    }

    return onTheWaySum / 60000;
  };

  const fetchAppointmentStatus = async (appointmentId: number) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/statuses/${appointmentId}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const aggregateStatuses = async () => {
    let totalOnTheWay = 0;
    let totalInProgress = 0;

    for (const appointmentId of appointmentIds) {
      const data = await fetchAppointmentStatus(appointmentId);
      if (data) {
        totalOnTheWay += Math.floor(summarizeStatusById(31, data));
        totalInProgress += Math.floor(summarizeStatusById(13, data));
      }
    }

  // Format the time values before setting them
    const formattedOnTheWayTime = formatTimeHAM(totalOnTheWay);
    const formattedInProgressTime = formatTimeHAM(totalInProgress);
    const formattedTotalTime = formatTimeHAM(totalOnTheWay + totalInProgress);

    setOnTheWayTime(formattedOnTheWayTime);
    setInProgressTime(formattedInProgressTime);
    setTotalTime(formattedTotalTime); 
  };

  useEffect(() => {
    aggregateStatuses();
  }, [appointmentIds]);

  const boxStyle = ` min-w-[93.18px] min-h-[47px] rounded flex text-white flex-col p-2`;

  return (
    <div>
      <div className="flex justify-between">
      <h2 className="py-2">{tr("Appointment Time Totals")}</h2>
      <div className="divide-y mr-[75px]">
        <div className="space-x-2 flex py-2">
        <div className={"bg-[#9c9900]" + boxStyle}>
            <span className="text-xs">{tr("ON THE WAY")}</span>
            <span className="leading-none">{onTheWayTime}</span>
          </div>
          <div className={"bg-[#2371a3]" + boxStyle}>
            <span className="text-xs">{tr("IN PROGRESS")}</span>
            <span className="leading-none">{inProgressTime}</span>
          </div>
          <div className={"bg-primary opacity-90" + boxStyle}>
            <span className="text-xs">{tr("TOTAL TIME")}</span>
            <span className="leading-none">{totalTime}</span>
          </div>
        </div>
      </div>
      </div>

    </div>
  )
}

export default AppointmentTimeTotals