import React from "react";

const CustomerTag = (props) => {
  return (
    <div
      style={{
        display: "block",
        marginRight: 5,
        marginTop: 5,
        fontSize: "80%",
        backgroundColor: props.color,
        color: "white",
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 4,
        paddingRight: 4,
        textAlign: "center",
        justifyContent: "center",
        alignSelf: "flex-start",
        borderRadius: 3,
      }}
    >
      {props.label}
    </div>
  );
};

export default CustomerTag;
