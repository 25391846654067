import { companyClient } from "api/api";
import { Button } from "components/button";
import { useModalContext } from "components/modal";
import Assignee from "components/react-select/assignees";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

interface IProps {
	targetId: number;
}

const ProductionAssigneeSelect = ({ targetId }: IProps) => {
	const { t } = useTranslation();
	const tBase = "containers.lineItems.components.ProductionAssigneeSelect";
	const tr = (key: string) => t(`${tBase}.${key}`);
	const queryClient = useQueryClient();
	const { setModal } = useModalContext();
	const [assignee, setAssignee] = useState<any>(null);

	const handleCancel = () => {
		setModal();
	};

	const handleSave = async () => {
		const response = await companyClient({
			method: "POST",
			url: "/products/updateAssignee",
			data: {
				assignee,
				targetId,
			},
		});

		if (response.status === 200) {
			queryClient.invalidateQueries();
			setModal();
		}
	};

	const handleSetAssignee = (assignee: any) => {
		setAssignee(assignee);
	};

	return (
		<div>
			<Assignee isMulti={false} onChange={handleSetAssignee} />
			<div className="flex justify-between mt-2">
				<Button onClick={handleCancel}>{tr("Cancel")}</Button>
				<Button primary onClick={handleSave}>
					{tr("Save")}
				</Button>
			</div>
		</div>
	);
};

export { ProductionAssigneeSelect };
