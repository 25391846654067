import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Button } from "components/buttonV2";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

interface IPaginationProps {
  itemsPerPage: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  useUrlParams?: boolean;
  className?: string;
};

const Pagination = ({ itemsPerPage, currentPage, totalPages, onPageChange, useUrlParams, className }: IPaginationProps) => {
  const { t } = useTranslation();
  const tBase = "components.paginationV2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const location = useLocation();

  const paginate = (pageNumber: number) => {
    onPageChange(pageNumber);
    if (useUrlParams) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('page', pageNumber.toString());
      searchParams.set('itemsPerPage', itemsPerPage.toString());  // Preserve the current itemsPerPage value
      history.push(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const getPageNumbers = () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always include the first page
      pages.push(1);
      // Handle ellipses for skipped sections of pages
      let lowerLimit = Math.max(2, currentPage - 2);
      let upperLimit = Math.min(totalPages - 1, currentPage + 2);

      if (lowerLimit > 2) {
        pages.push("...");
      }
      for (let i = lowerLimit; i <= upperLimit; i++) {
        pages.push(i);
      }
      if (upperLimit < totalPages - 1) {
        pages.push("...");
      }
      // Always include the last page
      pages.push(totalPages);
    }
    return pages;
  };

  const pageNumbers = getPageNumbers();
  const firstPage = pageNumbers[0];
  const lastPage = pageNumbers[pageNumbers.length - 1];

  return (
    <div className={className}>
      <div className="flex justify-center items-center gap-2 w-[500px]">
        <button 
          disabled={currentPage === 1} 
          onClick={() => paginate(currentPage - 1)}
          className={`
            px-3 ml-auto h-10 flex items-center justify-center rounded-[4px] font-bold text-sm no-underline hover:bg-gray-100
            ${currentPage === firstPage ? 
              "text-gray-400 hover:text-gray-500  cursor-not-allowed" 
              : "text-primary cursor-pointer"
            } 
            transition-colors duration-300 ease-in-out
          `}
        >
          <IoIosArrowBack />
          {tr("Previous")}
        </button>

        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={index}>...</span>
          ) : (
            <Button
              shape="slim"
              key={index}
              disabled={number === currentPage}
              active={number === currentPage}
              onClick={() => paginate(number as number)}
            >
              {number}
            </Button>
          )
        )}

        <button 
          disabled={currentPage === totalPages} 
          onClick={() => paginate(currentPage + 1)}
          className={`
          px-3 ml-auto h-10 flex items-center justify-center rounded-[4px] font-bold text-sm no-underline hover:bg-gray-100
          ${currentPage === lastPage ? 
            "text-gray-400 hover:text-gray-500  cursor-not-allowed" 
            : "text-primary cursor-pointer"
          } 
          transition-colors duration-300 ease-in-out
          `}
        >
          {("Next")}
          <IoIosArrowForward />
        </button>
      </div>
    </div>

  );
};

export default Pagination;
