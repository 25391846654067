import Controls from "./components/Controls";
import List from "./components/List/index";
import Selection from "./components/Selection";

import { AppointmentsProvider, useAppointments } from "./hooks/useAppointments";
import { SelectedAppointmentsProvider } from "./hooks/useSelectedAppointments";
import Map from "./components/Map";

const EservMap = () => {
  return (
    <AppointmentsProvider>
      <SelectedAppointmentsProvider>
        <div className="pt-4 pl-4 pr-4 hide-scrollbar">
          <Controls />
        </div>

        <div className="flex">
          <div className="w-2/5 ">
            <List />
          </div>

          <div className="w-[100%] pl-0 rounded-md border mt-4 mr-4">
            <Map />
          </div>
        <Selection />
        </div>
      </SelectedAppointmentsProvider>
    </AppointmentsProvider>
  );
};

export default EservMap;
