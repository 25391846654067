import * as React from "react";
import { theme } from "styles/theme";
import { useTranslation } from 'react-i18next';

type Props = {
  completion_note: string;
  style?: {};
};
export default function CompletionNote(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCard.completionNote";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  return (
    <div className="divide-y">
      <div className="py-2">
        <h2>{tr("Completion Note")}</h2>
      </div>
      <div className="py-2">
        {props.completion_note || (
          <span
            style={{
              fontSize: theme.fontSize.regular,
              color: theme.color.subtext3,
            }}
          >
            {tr("No Completion Note")}
          </span>
        )}
      </div>
    </div>
  );
}
