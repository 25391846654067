/* Created by Hannah Green for ES-171 on 6/24/2021 */
import Toolbar from "@material-ui/core/Toolbar";
import { lighten, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { GrAdd } from "react-icons/gr";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useModalContext } from "components/modal";
import AddEditService from "./addEditServiceModal";
import { CoolToolbar } from "./styles";
import { useTranslation } from "react-i18next";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    marginLeft: "8rem",
  },
}));

export const TableToolbar = (props) => {
  const { t } = useTranslation();
  const tBase = "views.services.components.table.table-utils";
  const tr = (key) => t(`${tBase}.${key}`);

  const classes = useToolbarStyles();
  const { setModal } = useModalContext();

  return (
    <CoolToolbar>
      <Toolbar className={clsx(classes.root)}>
        <Tooltip title={tr("Add Service")}>
          <IconButton
            size="small"
            onClick={() => {
              setModal({
                component: (
                  <AddEditService
                    formType="add"
                    service={{
                      id: undefined,
                      product_label: undefined,
                      description: undefined,
                      service_category: { label: "", value: "" },
                      flat_rate: false,
                      status: true,
                      rates: [],
                    }}
                  />
                ),
                label: tr("Add Service"),
              });
            }}
          >
            <GrAdd />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </CoolToolbar>
  );
};

//The table header
export const TableHeader = (props) => {
	const { t } = useTranslation();
	const tBase = "views.services.components.table.table-utils";
	const tr = (key) => t(`${tBase}.${key}`);

	const headCells = [
		{
			id: "product_label",
			label: tr("Name"),
		},
		{
			id: "category_name",
			label: tr("Category"),
		},
		{
			id: "status",
			label: tr("Status"),
		},
		{
			id: "edit",
			label: "",
		},
		{
			id: "delete",
			label: "",
		},
	];

  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? tr("sorted descending") : tr("sorted ascending")}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
