import React, { useState, useEffect } from "react";
import { Button, MenuButton } from "components/button";
import { ContainerWithSidebar } from "components/layout";
import { GoSignIn } from "react-icons/go";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import "./index.scss";

const Receive = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.receive.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [receiveList, setReceiveList] = useState([]);
  const [currentPo, setCurrentPo] = useState(null);

  const fetchReceive = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReceive`
      );

      setReceiveList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReceiveOne = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchReceive/${id}`
      );

      if (response.data[0]) {
        setCurrentPo(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeReceive = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/closeReceive`,
        { poId: currentPo.id }
      );

      setCurrentPo(null);
      fetchReceive();
      props.fetchCounts();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReceive();
  }, []);

  return (
    <ContainerWithSidebar>
      <div>
        <div className="title">{tr("Outstanding Orders")}</div>
        {receiveList?.map((order) => {
          return (
            <MenuButton
              onClick={() => {
                fetchReceiveOne(order.id);
              }}
              style={{ fontSize: "90%" }}
            >
              {order.po}
              {" ("}
              {order.hasOwnProperty("vendor_name")
                ? order.vendor_name
                : order.vendor}
              {")"}
            </MenuButton>
          );
        })}
      </div>
      <div className="receive-body">
        {currentPo ? (
          <div>
            <div className="receive-top-row">
              <div className="po-label-group">
                <div className="po-label">{currentPo.po}</div>
                {currentPo.timestamp && (
                  <div className="po-created">
                    Created:{" "}
                    {new Date(currentPo.timestamp).toLocaleDateString()}
                  </div>
                )}
              </div>
              <Button onClick={() => closeReceive()} primary>
                Close
              </Button>
            </div>
            {currentPo.hasOwnProperty("items") &&
              currentPo.items &&
              currentPo.items.length > 0 &&
              currentPo.items
                .sort((a, b) => {
                  return a.item_id > b.item_id;
                })
                ?.map((item, index) => {
                  return (
                    <div key={item.item_id} className="receive-row">
                      <div className="receive-item-group">
                        <div className="receive-item-label">
                          {item.pc_name && item.category_name && (
                            <div>{`${item.pc_name} -> ${item.category_name}`}</div>
                          )}
                          <div>{item.item_name}</div>
                        </div>
                      </div>

                      <div className="quantity-group">
                        <div className="receive-quantity-stack">
                          <div>{tr("Ordered")}</div>
                          <div>{item.quantity}</div>
                        </div>

                        <div className="receive-quantity-stack">
                          <div>{tr("Received")}</div>
                          <div>{item.received}</div>
                        </div>

                        <div className="receive-quantity-stack">
                          <div>{tr("Outstanding")}</div>
                          <div>{item.quantity - item.received}</div>
                        </div>
                      </div>

                      <div className="receive-input-group">
                        <ReceiveInput
                          quantity={item.quantity}
                          received={item.received}
                          poId={currentPo.id}
                          itemId={item.item_id}
                          fetchReceive={fetchReceive}
                          fetchReceiveOne={fetchReceiveOne}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
        ) : null}
      </div>
    </ContainerWithSidebar>
  );
};

const ReceiveInput = (props) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.receive.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [receiveQuantity, setReceiveQuantity] = useState(
    props.quantity - props.received
  );

  useEffect(() => {
    setReceiveQuantity(props.quantity - props.received);
  }, [props.fetchReceive]);

  const receive = async (poId, itemId, quantity) => {
    await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/receive`,
      {
        poId,
        itemId,
        quantity,
      }
    );

    props.fetchReceiveOne(poId);
  };

  return (
    <>
      <span>
        {tr("Receive")}:
        <input
          value={receiveQuantity}
          onChange={(e) => {
            setReceiveQuantity(e.target.value);
          }}
          className="receive-input"
        />
      </span>
      <GoSignIn
        className="receive-icon"
        color="green"
        size={22}
        onClick={() => {
          receive(props.poId, props.itemId, receiveQuantity);
        }}
      />
    </>
  );
};

export default Receive;
