import { FormEvent, useContext, useState } from "react";
import { useQuery } from "react-query";

import Spinner from "components/spinner";
import { VendorContext } from "contexts/VendorContext";
import { VendorItemDetails } from "types/vendor";
import { getVendorItemDetails } from "api/vendorsApi";
import VendorItemDetail from "./VendorItemDetail/VendorItemDetail";

import styles from "./VendorItems.module.scss";
import { SearchInput } from "components/input";
import { Button, IconButton } from "components/button";
import { useModalContext } from "components/modal";
import VendorItemLinkModalBody from "./VendorItemLinkModal/VendorItemLinkModalBody";
import { RiRefreshLine } from "react-icons/ri";
import VendorRefreshModal from "../VendorRefreshModal";
import { useTranslation } from 'react-i18next';

const VendorItems = () => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorItems.VendorItems";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const { setModal } = useModalContext();
  const { currentVendor } = useContext(VendorContext);
  const [search, setSearch] = useState<string>("");

  const vendorItemDetailsQuery = useQuery(
    ["vendors_items", currentVendor?.vendor_id],
    () => getVendorItemDetails(currentVendor?.vendor_id || null)
  );

  if (vendorItemDetailsQuery?.isLoading)
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );

  if (vendorItemDetailsQuery?.isError) return <div>{tr("Error")}</div>;
  const handleRefresh = () => {
    setModal({
      component: <VendorRefreshModal />,
      label: tr("Refresh Pricing"),
    })
  }
  const handleClickLinkItem = () => {
    setModal({
      component: <VendorItemLinkModalBody />,
      label: tr("Link Item"),
    });
  };

  const handleChangeSearch = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  return (
    <div className={styles.container} style={{borderLeft:"none"}}>
      <div className={styles.topRow}>
        <div className={styles.title}>
          <p style={{display:'inline'}}>{tr("Items")}</p>
          
            
          <RiRefreshLine
            style={{marginLeft:"10px",cursor:"pointer", fontSize:"20px", display:'inline'}}
            onClick={()=>{
              handleRefresh();
            }}
          />

        </div>
        <Button size="small" onClick={handleClickLinkItem}>
          {tr("Link")}
        </Button>
      </div>
      {/* <SearchInput
        search
        placeholder="Search..."
        style={{ width: "100%" }}
        value={search}
        onChange={handleChangeSearch}
      /> */}

      <div className={styles.listContainer}>
        {vendorItemDetailsQuery?.data?.map((i: VendorItemDetails) => {
          return <VendorItemDetail key={i.id} item={i} />;
        })}
      </div>
    </div>
  );
};

export default VendorItems;
