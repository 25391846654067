import React, { useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";

import { __businessUrl__ } from "../../../constants";
import { Button } from "components/button";
import { SpecialOrderItem } from "types/special-order";
import { useTranslation } from 'react-i18next';

import styles from "./SpecialOrderItemRow.module.scss";

interface IProps {
  item: SpecialOrderItem;
}

const SpecialOrderItemRow = ({ item }: IProps) => {
  const [quantity, setQuantity] = useState<number | null>(item.quantity);
  const { t } = useTranslation();
  const tBase = "views.order.components.SpecialOrderItemRow";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const {
    mutate: updateOrderItemQuantity,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(() => {
    return axios
      .put(`${__businessUrl__}/specialOrder/updateOrderItemQuantity`, {
        orderItemId: item.id,
        newQuantity: quantity,
      })
      .then((res) => {
        if (res.status === 200) {
          setQuantity(parseInt(res.data[0].quantity));
        }
      });
  });

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(e.target.value)) {
      setQuantity(parseInt(e.target.value));
    }
  };

  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemDetailsGroup}>
        {item.item_name ? (
          <div className={styles.mediumText}>{item.item_name}</div>
        ) : null}
        {item.long_description ? (
          <div className={styles.smallText}>{item.long_description}</div>
        ) : null}
      </div>
      <div className={styles.fulfillmentDetailsGroup}>
        <div className={styles.inputEditGroup}>
          <input
            className={styles.input}
            value={quantity?.toString()}
            onChange={handleChangeQuantity}
          />
          <div>
            <Button size="xsmall" className={styles.button}>
              {tr("Delete")}
            </Button>
            <Button
              isLoading={isLoading}
              size="xsmall"
              className={styles.button}
              onClick={() => updateOrderItemQuantity()}
            >
              {tr("Save")}
            </Button>
          </div>
        </div>
        <div>
          {item.quantity ? (
            <div className={styles.smallText}>{tr("Ordered")}: {item.quantity}</div>
          ) : null}
        </div>
        <div>
          {item.fulfilled ? (
            <div className={styles.smallText}>{item.fulfilled}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SpecialOrderItemRow;
