/* Created by Hannah Green on 5/5/2021 for ES-147 */
import React, { useState } from "react";
import styled from "styled-components";
import RawForm from "components/form";
import RawSelect from "components/react-select";
import { useMutation } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import ImageZoom from "./images";
import { useTranslation } from 'react-i18next';
const { TextArea } = require("components/input");
const { Button } = require("components/button");

const Con = styled.div`
  width: 100%;
`;

const UserInfo = styled.div`
  width: 100%;
  background-color: #ebedef;
  border: 1px solid #ebedef;
  border-radius: 5px;

  > label {
    font-weight: bold;
    padding-left: 5px;
  }

  > div {
    width: 450px;
    padding-left: 10px;
  }
`;

const Form = styled(RawForm)`
  width: 100%;

  > button {
    margin-top: 5px;
  }

  > div {
    margin-top: 5px;
  }
`;

const Select = styled(RawSelect)``;

const Image = styled.div`
  padding: 8px;
  width: 100%;
`;

export default function RequestDetails(props) {
  const { t } = useTranslation();
  const tBase = "views.repair_request.components.requests.request-details";
  const tr = (key) => t(`${tBase}.${key}`);

  const request = props.request;
  const lockTag = request.lockout_tagout ? "Yes" : "No";
  const name = request.first_name + " " + request.last_name;
  const equip = request.equip_type + " " + request.equip_num;
  const equipment = request.equip_num ? equip : request.equip_type;
  const { setModal } = useModalContext();

  let priority;

  if (request.request_priority === "1") {
    priority = "Low";
  } else if (request.request_priority === "2") {
    priority = "Medium";
  } else {
    priority = "High";
  }

  const [initialForm, setInitialForm] = useState({
    status: { label: request.status, value: request.status },
    comments: request.req_comments,
    maint_comments: request.maint_notes,
  });

  const mutation = useMutation(
    (form) => {
      axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/repair_request/update`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: () => {
        setModal();
        window.location.reload();
      },
    }
  );

  const updateForm = (input) => {
    if (input) {
      const target = input.target;
      const name = target.name;
      const value = target.value;

      setInitialForm((state) => {
        const tempForm = { ...state };
        tempForm[name] = value;
        return tempForm;
      });
    }
  };

  const updateRequest = async (values) => {
    const { ...form } = values;
    form.request_id = request.request_id;
    const formData = new FormData();

    formData.append("form", JSON.stringify(form));

    mutation.mutate(formData);
  };

  return (
    <Con>
      <UserInfo>
        <label>{tr("Name")}: </label>
        <div>{name}</div>
        <label>{tr("Email")}: </label>
        <div>{request.email}</div>
        <label>{tr("Equipment")}: </label>
        <div>{equipment}</div>
        <label>{tr("Lockout/Tagout Needed")}: </label>
        <div>{lockTag}</div>
        <label>{tr("Priority")}: </label>
        <div>{priority}</div>
        <label>{tr("Description")}: </label>
        <div>{request.description}</div>
      </UserInfo>
      {request.images &&
        request.images.map((img, index) => {
          return (
            <>
              {img && (
                <Image key={index}>
                  <ImageZoom image={img} />
                </Image>
              )}
            </>
          );
        })}
      <Form
        initialValues={initialForm}
        onSubmit={() => {
          updateRequest(initialForm);
        }}
      >
        <div>
          <Select
            label={tr("Status")}
            name="status"
            options={[
              { label: tr("New"), value: "New" },
              { label: tr("In Progress"), value: "In Progress" },
              { label: tr("Complete"), value: "Complete" },
            ]}
            value={initialForm.status}
            onChange={(value) => {
              setInitialForm((state) => {
                const tempForm = { ...state };
                tempForm.status = value;
                return tempForm;
              });
            }}
          />
        </div>

        <div>
          <TextArea
            name="comments"
            label={tr("Comments")}
            value={initialForm.comments}
            onChange={(value) => {
              updateForm(value);
            }}
          />
        </div>

        <div>
          <TextArea
            name="maint_comments"
            label={tr("Maintenance Notes")}
            value={initialForm.maint_comments}
            onChange={(value) => {
              updateForm(value);
            }}
          />
        </div>

        <Button type="submit" primary>
          {tr("Save")}
        </Button>
      </Form>
    </Con>
  );
}
