import { useState, useEffect } from "react";
import styled from "styled-components";

export const Apps = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
`;

export default function ({ children, theme }) {
  const [updateTheme, setTheme] = useState();
  useEffect(() => {
    setTheme(theme);
  }, [theme]);
  return <Apps themes={updateTheme}>{children}</Apps>;
}
