import * as React from "react";
import styled from "styled-components";
import { useAuthContext } from "contexts/auth";
import { Button, SubtleButton, SubtleIconButton } from "components/button";
import TextArea from "react-textarea-autosize";
import { MdContentCopy } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useSnackbarContext } from "components/snackbar";
import InlineEdit from "components/inline_edit";
import TooltippedElement from "components/TooltippedElement";
import { RiPencilLine } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

type Data = {
  request_email_template: string;
  redirect_url: string | null;
} | null;

export default function Requests() {
  const { company_id } = useAuthContext();

  const [data, setData] = React.useState<Data>();
  const { showSnackbar } = useSnackbarContext();

  const { t } = useTranslation();
  const tBase = "views.admin.requests.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const initialData = useQuery(
    "request-settings",
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request-settings
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (res) => setData(res),
    }
  );

  if (!data) return null;

  const link = `https://eserv.com/questions/${company_id}`;

  const handleCopy = () => {
    showSnackbar(tr("Link copied!"));
  };

  return (
    <Con>
      <h1>{tr("Request Settings")}</h1>
      <div>
        <Section>
          <div>{tr("Link")}</div>
          <div>
            <a target="_blank" href={link}>
              {link}
            </a>
            <div>
              <TooltippedElement
              message={tr("Copy Link")}
              element={
              <SubtleIconButton onClick={handleCopy}>
                <MdContentCopy />
              </SubtleIconButton>}/>
            </div>
          </div>
        </Section>
        <Section>
          <div>{tr("Redirect Url")}</div>
            <InlineEdit
              className="w-full"
              value={data?.redirect_url}
              updateUrl={"company/request-settings/redirect-url"}
            />
        </Section>
        <Section>
          <div>{tr("Customer Request Template")}</div>
          <EmailBodyLine text={data.request_email_template} />
        </Section>
      </div>
    </Con>
  );
}

const Con = styled.div`
  max-width: 1200px;
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;

  align-items: center;
  padding: 1rem 0;
  > div:first-child {
    font-weight: bold;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #c0c0c0;
  }
  > div:last-child {
    display: flex;
    align-items: center;
    > div:last-child {
      margin-left: auto;
    }
  }
`;

const EmailBodyLine = ({ text }: { text: string }) => {
  const { t } = useTranslation();
  const tBase = "views.admin.requests.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [edit, setEdit] = React.useState(false);

  const { showSnackbar } = useSnackbarContext();

  const queryClient = useQueryClient();

  const [value, setValue] = React.useState("");

  const updateEmailTemplate = useMutation(
    async (body: string) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/companySettings/emailTemplate
  `,
        { value: body }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("companySettings");
        showSnackbar(tr("Email Template Updated!"));
        setEdit(false);
      },
    }
  );

  React.useEffect(() => {
    setValue(text);
  }, [text]);

  if (edit) {
    return (
      <EditCon>
        <TextArea
          value={value}
          autoFocus
          minRows={2}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setValue(e.target.value)
          }
        />
        <div>
          <Button primary onClick={() => updateEmailTemplate.mutate(value)}>
            {tr("Save Changes")}
          </Button>
          <Button onClick={() => setEdit(false)}>{tr("Cancel")}</Button>
        </div>
      </EditCon>
    );
  }

  return (
    <div>
      <p style={{ whiteSpace: "pre-wrap" }}>{text}</p>
      <div>
        <TooltippedElement
          message={tr("Edit")}
          element={
            <SubtleIconButton onClick={() => setEdit(true)}>
              <RiPencilLine />
            </SubtleIconButton>
          }/>
      </div>
    </div>
  );
};

const EditCon = styled.div`
  display: flex;
  flex-direction: column;
  > textarea {
    align-self: stretch;
    padding: 0.5em;
    margin-bottom: 1rem;
    font-size: 1rem;
    border-radius: 5px;
  }
  > div {
    > button:last-child {
      margin-left: 0.5em;
    }
  }
`;
