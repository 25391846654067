import * as React from "react";
import { Button } from "components/button";

import { TInitialData, TQuestions, TLink } from "types/request";
import styled from "styled-components";
import { BsCircle, BsSquare, BsSquareFill, BsCircleFill } from "react-icons/bs";
import TextArea from "react-textarea-autosize";
import { cloneDeep } from "lodash";
import Assignee from "components/react-select/assignees";
import Calendar from "views/calendar";
import { useTranslation } from 'react-i18next';

type Props = {
  initialData: TInitialData;
  answers?: TQuestions[];
  setAnswers: React.Dispatch<React.SetStateAction<TQuestions[] | undefined>>;
  setPage: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  setLink: React.Dispatch<React.SetStateAction<string | undefined>>;
  submitForm: any;
  managers?: { value: string; label: string }[] | null;
  setManagers?: any;
  isRequestModal?: boolean | true;
};
export default function QuestionsComp({
  initialData,
  setAnswers,
  answers,
  setPage,
  setLink,
  submitForm,
  managers,
  setManagers,
  isRequestModal,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.requests.newRequestModal.questions";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modifyCategory = (
    categoryId: string,
    type: string,
    optionId: number
  ) => {
    setAnswers((temp) => {
      let ans = temp?.filter(({ is_primary }) => is_primary);

      if (type === "ADD" && ans) {
        let questions = initialData.questions;
        let category = questions?.find((q) => q.fk_category_id === categoryId);

        if (category?.questions) {
          let newQuestions = category.questions;
          ans.push(...newQuestions);
        }
      }

      return ans;
    });
  };


  return (
    <Con>
      {isRequestModal && 
        <Assignee
          value={managers}
          label={tr("Manager/Designer")}
          onChange={(e: any) => setManagers(e)}
        />
      }
      {answers &&
        answers.map((answer) => (
          <EachQuestion
            key={answer.id}
            {...{ setLink, setAnswers, answer, modifyCategory }}
            link={initialData?.link}
          />
        ))}
      <Row>
        <Button onClick={() => setPage(1)}>{tr("Back")}: {tr("Customer Info")}</Button>
        <Button
          primary
          onClick={submitForm.mutate}
          isLoading={submitForm.isLoading}
        >
          {tr("Submit Request")}
        </Button>
      </Row>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  gap: 0.5rem;
  align-self: flex-end;
`;

const EachQuestion = ({
  answer,
  setAnswers,
  link,
  setLink,
}: // modifyCategory,
{
  answer: TQuestions;
  setAnswers: React.Dispatch<React.SetStateAction<TQuestions[] | undefined>>;
  link: TLink[] | undefined;
  // ßmodifyCategory: (category_id: string, type: string) => void;
  setLink: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { id, question, type, required, options, response } = answer;

  if (type === "IMAGE") return null;

  /**
   *
   */
  const handleLink = (
    tempAnswers: TQuestions[],
    optionId: number,
    type: string
  ) => {
    if (link) {
      let selectedLink = link.find((link) => +link.fk_question_id === id);
      if (selectedLink) {
        const selectedCategory = link.find(
          (link) => +link.fk_option_id === optionId
        );

        setLink(selectedCategory?.fk_category_id || undefined);
      }
    }
  };

  const handleOptionClick = (type: string, optionId: number, label: string) => {
    setAnswers((answers) => {
      let tempAnswers = cloneDeep(answers);

      if (tempAnswers) {
        let index = tempAnswers.findIndex((ans: TQuestions) => ans.id === id);
        let answer = tempAnswers[index];
        if (type === "SINGLE") {
          if (answer.response && answer.response[0].id === optionId) {
            delete answer.response;
            handleLink(tempAnswers, optionId, "DELETE");
          } else {
            answer.response = [{ id: optionId, option: label }];
            handleLink(tempAnswers, optionId, "ADD");
          }
        } else {
          if (answer.response) {
            const findOptionIndex = answer.response.findIndex(
              (res) => res.id === optionId
            );
            if (findOptionIndex !== -1) {
              if (answer.response.length === 1) {
                delete answer.response;
              } else {
                answer.response.splice(findOptionIndex, 1);
              }
            } else {
              answer.response?.push({ id: optionId, option: label });
            }
          } else {
            answer.response = [{ id: optionId, option: label }];
          }
        }
      }

      return tempAnswers;
    });
  };

  const populateOptions =
    options &&
    options.map((option) => {
      const selected =
        response && response.findIndex((res) => res.id === option.id) !== -1;
      if (type === "SINGLE") {
        return (
          <SingleOption
            selected={selected}
            key={option.id}
            onClick={() => handleOptionClick(type, option.id, option.option)}
          >
            {selected ? <BsCircleFill /> : <BsCircle />}
            {option.option}
          </SingleOption>
        );
      } else if (type === "MULTIPLE") {
        return (
          <MultipleOption
            selected={selected}
            key={option.id}
            onClick={() => handleOptionClick(type, option.id, option.option)}
          >
            {selected ? <BsSquareFill /> : <BsSquare />}
            {option.option}
          </MultipleOption>
        );
      }
      return;
    });

  const populateInputField = () => {
    let value = response && response[0].option;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      let { value } = e.target;
      setAnswers((ans) => {
        let tempAns = cloneDeep(ans);
        if (tempAns) {
          let index = tempAns.findIndex((ans: TQuestions) => ans.id === id);
          if (value === "") {
            delete tempAns[index].response;
          } else {
            tempAns[index].response = [{ id: 1, option: value }];
          }
        }

        return tempAns;
      });
    };

    return (
      <InputField
        defaultValue={value || ""}
        onChange={handleChange}
        minRows={4}
      />
    );
  };

  return (
    <EachQuestionCon>
      <div>
        {question}
        {required && "*"}
      </div>
      <div>{type === "TEXT" ? populateInputField() : populateOptions}</div>
    </EachQuestionCon>
  );
};

const EachQuestionCon = styled.div`
  > div:first-child {
    font-weight: bold;
  }
  > div:last-child {
    // gap: 0.25rem;
    display: flex;
    flex-direction: column;
  }
`;

const Option = styled.div<{ selected?: boolean }>`
  border: 1px solid ${(props) => (props.selected ? "#ff4f00" : "transparent")};
  padding: 0.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  background: ${(props) => (props.selected ? "transparent" : "transparent")};
  > svg {
    color: ${(props) => (props.selected ? "#ff4f00" : "grey")};
    font-size: 1rem;
  }
  &:hover {
    background: #f0f0f0;
  }
`;

const SingleOption = styled(Option)``;

const MultipleOption = styled(Option)`
  border-radius: 5px;
`;

const InputField = styled(TextArea)`
  border-radius: 5px;
`;
