import React, { useState, useEffect } from "react";
import { ProgressBar } from "./ProgressBar";
import { useUpload } from "../context/UploadContext";
import { Link } from "react-router-dom";
import { FaClipboardCheck, FaTools, FaUsers } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const ProgressBarContainer = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.ProgressBarContainer";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { 
    uploadProgress, 
    results, 
    backButton,
    selected,
    setSelected,
    handleSelect,
  } = useUpload();
  
  const data = [
    { inventory: [
      { label: "Rows Handled", value: results ? results?.processedRows : 0},
      { label: "Inventory Items Added", value: results ? results?.insertedItems : 0},
      { label: "Categories Added", value: results ? results?.insertedCategories : 0},
      { label: "Subcategories Added", value: results ? results?.insertedSubCategories : 0},
    ]},

    { customers: [ 
      { label: "Rows Handled", value: (results ? (results?.processedRows) : 0)  }, 
      { label: "Existing Customers Found", value: (results ? results?.existingCustomers : 0)},
      { label: "Existing Addresses Found", value: (results ? results?.existingAddresses : 0)},
      { label: "New Customers Added", value: (results ? results?.insertedCustomers : 0)},
      { label: "New Addresses Added", value: (results ? results?.insertedAddresses : 0)},
    ]},

    { services: [
      { label: "Rows Handled", value: results ? results?.processedRows : 0},
      { label: "Existing Services Found", value: results ? results?.existingServices : 0},
      { label: "New Services Added", value: results ? results?.insertedServices : 0 },
    ]},
  ];  

  const summaryData = selected === "inventory" ? data[0].inventory : selected === "customers" ? data[1].customers : data[2].services;
  const summaryIcon = selected === "inventory" ? 
      <FaClipboardCheck className="text-2xl text-primary ml-2" />
    : selected === "customers" 
    ? <FaUsers className="text-2xl text-primary ml-2" /> 
    : <FaTools className="text-2xl text-primary ml-2" /> ;
  const summaryLink = selected === "inventory" ? "/products" : selected === "customers" ? "/customers" : "/services";
  const summaryTitle = selected === "inventory" ? "Inventory" : selected === "customers" ? "Customers" : "Services";  

  const uploadComplete = uploadProgress?.processedRows >= uploadProgress?.totalRows;

  const backButtonPlus = async () => {
    setSelected("");
    await handleSelect("");
    backButton();
  };

  return (
    <div className="bg-white w-[500px] h-[350px] rounded-lg">
      {uploadProgress && !uploadComplete ? 
        <div className="flex flex-col justify-between rounded-md p-5 bg-white w-full h-full">
          <div className="text-center text-2xl text-secondary">{tr("Importing")}...</div>
          <div className="mx-auto text-center">
            {tr("Upload Progress")} <br/>
            <span className="font-bold text-gray-400 text-lg">
              {uploadProgress.processedRows} / {uploadProgress.totalRows}
            </span>
          </div>
          <div className="-mb-10 -mt-8">
            <ProgressBar uploadProgress={uploadProgress} />
          </div>
          <div className="text-center text-[13px] text-red-500 mb-10">
            {tr("Please do not close or leave this window until the import is complete.")}
          </div>
        </div>
      : (
        <div className="flex flex-col justify-between rounded-md p-5 bg-white w-full h-full">
          <div className="text-center text-2xl text-secondary">{tr("Import complete")}</div>
          <div>
            <div className="underline underline-offset-2 text-lg text-gray-500">{tr("Summary")}:</div>
            {summaryData?.map((item, i) => (
              <div key={i} className="flex justify-between mt-3">
                <div className="pr-1 text-gray-400">{tr(item.label)}</div>
                <div className="flex-1 border-b-2 border-dotted mb-1.5"></div>
                <div className="pl-1 text-primary">{item.value}</div>
              </div>
            ))}
          </div>
          <div className="mx-auto text-center">
            <div className="flex my-2">
              <div className="">{tr("You can now view your")} {summaryTitle.toLocaleLowerCase()}</div>
              {summaryIcon}
            </div>
            <div className="space-x-5">
              <Link
                to={summaryLink}
                className="no-underline border text-primary px-5 py-2 rounded-md hover:bg-gray-100 active:bg-gray-200"
              >
                {tr("View")} {summaryTitle}
              </Link>
              <button onClick={backButtonPlus} className="border px-5 py-2 rounded-md hover:bg-gray-100 active:bg-gray-200">
                {tr("Close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProgressBarContainer