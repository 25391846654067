import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function useQueryString<T>() {
  const location = useLocation(),
    history = useHistory();

  const search = queryString.parse(location.search);
  // console.log(location)
  
  
  const update = (e: T) => {
    // @ts-ignore
    history.push({ search: queryString.stringify(e) });
  };

  return { search, update };
}
