import { useCallback } from "react";
import axios from "api/axios";
import Field from "components/field";
import Image from "components/image";
import * as React from "react";
import { RiSettings2Line, RiToolsLine } from "react-icons/ri";
import { useMutation } from "react-query";
import { components } from "react-select";
import { OptionProps } from "react-select/src/types";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TItemDropdown } from "types/product";
import { dollarFormatter } from "utils";
import { debounce } from "lodash";
import { useTranslation } from 'react-i18next';

type Props = {
  onChange: (e: any) => void;
  //   remove: string[];
};

export default function ProductsSelect(props: Props) {
  const getProducts = useMutation(
    async (e: { search: string }) =>
      await axios
        .get(
          `
${process.env.REACT_APP_SERVER_URL}/api/v1/company/items`,
          {
            params: e,
          }
        )
        .then((res) => {console.log(res.data); return res.data} )
  );

  // Debounced function to handle search input changes
  const debouncedGetProducts = useCallback(
    debounce((inputValue: string, callback: (options: TItemDropdown[]) => void) => {
      getProducts.mutateAsync({ search: inputValue })
        .then((res) => callback(res?.map((product: any) => ({ ...product }))))
        .catch((e) => {
          throw e;
        });
    }, 500),
    []
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: TItemDropdown[]) => void
  ) => {
    debouncedGetProducts(inputValue, callback);
  };

  const handleChange = (e: any) => {
    props.onChange(e);
  };


  return (
    <Field
      type="select"
      isAsync
      loadOptions={loadOptions}
      defaultOptions
      onChange={handleChange}
      components={{ Option }}
      style={{ width: "100%" }}
      styles={{
        option: (base: any) => ({
          ...base,
          display: "flex",
        }),
      }}
      value={""}
    />
  );
}

const Option = (props: OptionProps) => {
  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key: string) => t(`${tBase}.${key}`);

  if (props.data.group_only) return null;
  return (
    // @ts-ignore
    <components.Option {...props}>
      <Image
        src={props.data.imageUrl}
        placeholder={
          props.data.type === "service" ? <RiSettings2Line /> : <RiToolsLine />
        }
        style={{ width: "40px", height: "40px", marginRight: "0.5rem" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            color: theme.color.subtext3,
            fontSize: theme.fontSize.xsmall,
          }}
        >
          {/* @ts-ignore */}
          {props.data.type === "service"
            ? tr("Service")
            : props.data.type === "group"
            ? tr("Group")
            : props.data.root_name}
        </span>

        <span>
          {props.label}{" "}
          <span
            style={{
              fontSize: theme.fontSize.xsmall,
              color: theme.color.subtext3,
            }}
          >
            {`${tr("List")}: ${dollarFormatter(
              props.data.list_price
            )} | ${tr("Sale")}: ${dollarFormatter(
              props.data.unit_price
            )} | ${tr("Install")}: ${dollarFormatter(props.data.install_price)}`}
          </span>
        </span>
      </div>
    </components.Option>
  );
};

const Con = styled.div``;
