import React from "react";

type Props = {
  children: React.ReactNode;
  form: any;
  onSubmit: (formData: any) => void;
};

const Form = ({ children, form, onSubmit }: Props) => {
  const { handleSubmit } = form;
  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      {children}
    </form>
  );
};

export { Form };
