/* Created by Hannah Green for ES-171 on 7/1/2021 */
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { Input } from "components/input";
import { SubtleIconButton } from "components/button";
import { dollarInputField } from "utils";

export default function EditMode({ rate, i, setRates }) {
    const { rate_id, rate_name, rate_desc, qty_hrs, internal_cost, member_rate, reg_rate } = rate;

    const regRate = dollarInputField.get(reg_rate);
    const intCost = dollarInputField.get(internal_cost);
    const memRate = dollarInputField.get(member_rate);

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        setRates((rate) => {
            let tempRates = cloneDeep(rate);

            if (name === "internal_cost" || name === "member_rate" || name === "reg_rate") {
                let newValue = dollarInputField.format(value);
                tempRates[i][name] = newValue;
                return tempRates;
            }

            tempRates[i][name] = value;
            return tempRates;
        });
    };

    const handleDelete = () => {
        setRates((r) => {
            let tempRate = cloneDeep(r);
            if (tempRate[i]["new_product"]) {
                tempRate[i]["new_product"] = false;
            }
            tempRate[i]["deleted"] = true;
            return tempRate;
        });
    };

    return (
        <div>
            <Column>
                <SubtleIconButton
                    size="small"
                    square
                    onClick={handleDelete}
                    style={{ color: "red" }}
                >
                    <RiCloseLine />
                </SubtleIconButton>
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={rate_name}
                    name={"rate_name"}
                    onChange={handleInputChange}
                />
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100%" }}
                    value={rate_desc}
                    name={"rate_desc"}
                    onChange={handleInputChange}
                />
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100px", textAlign: "center" }}
                    value={qty_hrs}
                    name={"qty_hrs"}
                    type="number"
                    onChange={handleInputChange}
                />
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100px", textAlign: "center" }}
                    value={regRate}
                    name={"reg_rate"}
                    type="text"
                    onChange={handleInputChange}
                />
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100px", textAlign: "center" }}
                    value={intCost}
                    name={"internal_cost"}
                    type="text"
                    onChange={handleInputChange}
                />
            </Column>
            <Column>
                <Input
                    size="small"
                    style={{ width: "100px", textAlign: "center" }}
                    value={memRate}
                    name={"member_rate"}
                    type="text"
                    onChange={handleInputChange}
                />
            </Column>
        </div>
    );
}

const Column = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
`;
