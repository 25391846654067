import React, { createContext, useContext, useState, useEffect } from 'react';

import { IUploadResult, IUploadContext, IChoices } from "../interface";
import axios from "axios";

export const UploadContext = createContext<IUploadContext>({} as IUploadContext);

export const UploadProvider = ({ children }: { children: React.ReactNode }) => {
  const [selected, setSelected] = useState("");
  const [buttonHover, setButtonHover] = useState("");
  const [custHover, setCustHover] = useState(false); //TODO
  const [invHover, setInvHover] = useState(false);//TODO
  const [servicesHover, setServicesHover] = useState(false);//TODO 
  const [animEnd, setAnimEnd] = useState(false);
  const [order, setOrder] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState("other");
  const [triggerCurtains, setTriggerCurtains] = useState(false); 
  const [triggerCurtainsOpen, setTriggerCurtainsOpen] = useState(false);
  const [triggerCurtainsClose, setTriggerCurtainsClose] = useState(false);
  const [animationStep, setAnimationStep] = useState(0);
  const [importComplete, setImportComplete] = useState(false); 
  const [importCompleteContentShow, setImportCompleteContentShow] = useState(false);
  const [inventoryHasBeenImported, setInventoryHasBeenImported] = useState(false); //TODO
  const [customersHaveBeenImported, setCustomersHaveBeenImported] = useState(false); //TODO
  const [servicesHaveBeenImported, setServicesHaveBeenImported] = useState(false); //TODO
  const [results, setResults] = useState<IUploadResult | null>(null); // results from the API call
  const [hasDuplicates, setHasDuplicates] = useState(false); // results from the API call
  const [duplicatesComplete, setDuplicatesComplete] = useState(false); 
  const [choices, setChoices] = useState<IChoices>({});
  const [progress, setProgress] = useState(0);
  const [totalSelections, setTotalSelections] = useState({ import: 0, original: 0, both: 0 });
  const [uploadProgress, setUploadProgress] = useState({ processedRows: 0, totalRows: 1 }); // Initialize with totalRows as 1 to avoid division by zero
  const [hoverBackButton, setHoverBackButton] = useState(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [pauseCurtains, setPauseCurtains] = useState(false);
  const [viewImports, setViewImports] = useState(false);
  const [origFileName, setOrigFileName] = useState("");
  const [selectedImportType, setSelectedImportType] = useState<string>("customers" || "inventory" || "services");
  const [pages, setPages] = useState<{ [key: string]: number }>({ customers: 1, inventory: 1, services: 1 });
  const [hasBeenImported, setHasBeenImported] = useState<{[key: string]: boolean }>({ inventory: false, services: false, customers: false });

  const fetchImportStatus = async() => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/import/hasBeenImported`);
      setHasBeenImported(res.data);
    } catch (error) {
      console.error("Error fetching import status", error);
    }
  };

  // sets choice between "customers" or "inventory" or "services"
  const handleSelect = async (option: string) => {
    setSelected(option);
  };

  // set base style for Inventory or Customers
  const baseStyle = `
    w-[300px] h-[200px] border border-gray-400 rounded-xl
    flex justify-center items-center mx-10
    ${selected ? "shadow-xl cursor-default" : "hover:shadow-xl cursor-pointer"}
    will-change-auto will-change-transform overflow-hidden 
  `;

  // Enhances customer or inventory style with animation if selected, sets uploadB animation for unselected
  const getStyle = (option: any) => {
    let style = baseStyle;

    if (selected === option) {
      style += " animate-uploadA";
    } else if (selected && selected !== option) {
      style += " animate-uploadB"
    }
    return style;
  };

  // sets hover state for customer or inventory
  const onMouseEnter = (option: any) => {
    if (selected) return; // Do nothing if something is already selected

    if (option === "customers") {
      setCustHover(true);
    } else if (option === "inventory") {
      setInvHover(true);
    } else if (option === "services") {
      setServicesHover(true);
    }
  };

  // sets hover state for customer or inventory
  const onMouseExit = (option: any) => {
    if (selected) return; // Do nothing if something is already selected

    if (option === "customers") {
      setCustHover(false);
    } else if (option === "inventory") {
      setInvHover(false);
    } else if (option === "services") {
      setServicesHover(false);
    }
  };

  useEffect (() => {
    handleOrder();
  }, [selected, animEnd])

  // Sets initial order after first animation
  const handleOrder = () => {
    if (selected && animEnd) {
      setTriggerCurtainsOpen(true);
      setOrder(1);
    } 
  };

  // handles back button and close button when import is complete
  const backButton = () => {
    setSelected(""); // doesn't work on complete import so it's handled in useEffect
    handleSelect("");
    setButtonHover("");
    setCustHover(false);
    setInvHover(false);
    setServicesHover(false);
    setAnimEnd(false);
    setTriggerCurtains(false);
    setOrder(0); // resets the order
    setAnimationStep(0); // resets the curtains
    setImportComplete(false);
    setImportCompleteContentShow(false);
    setDuplicatesComplete(false);
    setResults(null);
    setUploadProgress({ processedRows: 0, totalRows: 1 }); // Initialize with totalRows as 1 to avoid division by zero
    setHoverBackButton(false);
    setTriggerCurtainsClose(false);
    setTriggerCurtainsOpen(false);
    setPauseCurtains(false);
    setViewImports(false);
    setOrigFileName("");
    setPages({ customers: 1, inventory: 1, services: 1 });
    fetchImportStatus();
  };

  // Fires on load to ensure all is reset
  useEffect(() => {
    backButton();
  }, []);

  // handles setting setSelected="" when import is complete
  useEffect(() => {
    if (!importCompleteContentShow) {
      handleSelect("");
    }
  }, [importCompleteContentShow]);

  const inventory = [
    "Item Name",
    "Short Name",
    "Short Description",
    "Long Description",
    "Model",
    "SKU",
    "Category",
    "Subcategory",
    "List Price",
    "Sale Price",
    "Install Price",
    "Sale Is Fixed",
    "Sale Multiplier",
    "Install Is Fixed",
    "Install Multiplier",
    "Special Order Item",
  ]

  const services = [
    "Service Name",
    "Description",
    "List Price",
    "Sale Price",
    "Install Price",
    "Sale Is Fixed",
    "Sale Multiplier",
    "Install Is Fixed",
    "Install Multiplier",
  ];

  const customers = [
    "First Name",
    "Last Name",
    "Company",
    "Title",
    "Email",
    "Phone",
    "Extension",
    "Address (Owner)",
    "City (Owner)",
    "State (Owner)",
    "Zip (Owner)",
    "City, State, Zip (Owner)",
    "Bill To Name (Billing)",
    "Address Nickname (Billing)",
    "Address (Billing)",
    "City (Billing)",
    "State (Billing)",
    "Zip (Billing)",
    "City, State, Zip (Billing)",
  ];

  const value = {

    // arrays
    inventory,
    services,
    customers,

    // states
    selected, 
    setSelected, 
    buttonHover,
    setButtonHover,
    custHover, 
    setCustHover, 
    invHover, 
    setInvHover,
    servicesHover,
    setServicesHover,
    animEnd, 
    setAnimEnd, 
    order, 
    setOrder,
    selectedCompany,
    setSelectedCompany,
    triggerCurtains,
    setTriggerCurtains,
    triggerCurtainsOpen,
    setTriggerCurtainsOpen,
    triggerCurtainsClose,
    setTriggerCurtainsClose,
    animationStep,
    setAnimationStep, 
    importComplete,
    setImportComplete,
    importCompleteContentShow,
    setImportCompleteContentShow,
    inventoryHasBeenImported,
    setInventoryHasBeenImported,
    customersHaveBeenImported,
    setCustomersHaveBeenImported,
    servicesHaveBeenImported,
    setServicesHaveBeenImported,
    results,
    setResults,
    hasDuplicates,
    setHasDuplicates,
    duplicatesComplete,
    setDuplicatesComplete,
    choices,
    setChoices,
    progress,
    setProgress,
    totalSelections,
    setTotalSelections,
    uploadProgress,
    setUploadProgress,
    hoverBackButton,
    setHoverBackButton,
    headers,
    setHeaders,
    selectedFileType,
    setSelectedFileType,
    pauseCurtains,
    setPauseCurtains,
    viewImports,
    setViewImports,
    origFileName,
    setOrigFileName,
    selectedImportType,
    setSelectedImportType,
    pages,
    setPages,
    hasBeenImported,
    setHasBeenImported,

    // functions
    handleSelect,
    onMouseEnter,
    onMouseExit,
    handleOrder,
    backButton,

    // API
    fetchImportStatus,

    // styling
    baseStyle,
    getStyle,
  };

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUpload = () => useContext(UploadContext);
