import { AxiosError, AxiosResponse } from "axios";
import { CREATE } from "../../../constants";
import { useMutation, useQueryClient } from "react-query";
import { insertEquipment } from "../api/insertEquipment";
import { updateEquipment } from "../api/updateEquipment";
import { IEquipment } from "../types/equipment";

export const useEquipmentMutation = (type: string) => {
  const queryClient = useQueryClient();
  const updateEquipmentMutation = useMutation<
    AxiosResponse,
    AxiosError,
    IEquipment
  >(type === CREATE ? insertEquipment : updateEquipment, {
    onSuccess: () => {
      queryClient.invalidateQueries("equipment");
    },
  });

  return updateEquipmentMutation;
};
