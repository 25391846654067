import { downloadTemplate } from "api/uploadApi";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  type: string;
};

const DownloadTemplate = ({ label, type }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.admin.uploadV2.components.DownloadTemplate";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const handleDownloadCustomerTemplate = async () => {
    const response = await downloadTemplate(type);

    const url = response.url;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${label}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <div className="text-lg text-secondary">{label}</div>
      <div className="flex justify-end items-center mt-2">
        <Button
          className="mx-auto "
          onClick={handleDownloadCustomerTemplate}
        >
          {tr("Download")}
        </Button>
      </div>
    </>
  );
};

export default DownloadTemplate;
