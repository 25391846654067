import { businessClient } from "api/api";

const getDivisions = async () => {
	return businessClient({
		method: "GET",
		url: `/divisions`,
	});
};

export { getDivisions };
