import axios from "api/axios";
import Field from "components/field";
import { TSelectProps } from "components/react-selectv2";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { TDivision } from "types/division";
import ReactSelect from ".";

type Props = {
  value?: string | null; //{ value: string; label: string }
  // onChange: (e: { value: string; label: string }) => void;
} & Omit<TSelectProps, "value">;

export default function DivisionDropdown(props: Props) {
  let { value, ...rest } = props;
  const divisions = useQuery<TDivision[]>(
    "divisions",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/divisions
    `
        )
        .then((res) => res.data)
  );

  let formatVal = props.value
    ? divisions.data?.find((division) => division.id === props.value)
    : null;

  return (
    <Field
      type="select"
      options={
        divisions.data?.map((division) => ({
          ...division,
          value: division.id,
          label: division.label,
        })) || []
      }
      value={formatVal ? { value: formatVal.id, label: formatVal.label } : null}
      {...rest}
    />
  );
}
