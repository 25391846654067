import Spinner from "components/spinner";
import { IProduction } from "types/production";
import { dollarFormatter } from "utils";
import { useAppointmentProduction } from "./hooks/useAppointmentProduction";
import { useTranslation } from 'react-i18next';

type Props = {
  appointmentId: string;
};

export default function AppointmentProduction(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCard.appointmentProduction";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const appointmentId = parseInt(props.appointmentId);

  const { data, error, isError, isLoading } =
    useAppointmentProduction(appointmentId);

  if (isLoading) return <Spinner />;

  if (isError && error) return <div>{error}</div>;

  if (!data || !data?.data?.length) return <div>{tr("No production data")}</div>;

  const populateProd = data?.data?.map(
    ({ appointment_production, id, user }: IProduction) => {
      const userName = user
        ? `${user.first_name} ${user.last_name}`
        : tr("Unassigned Production");

      const productionValue = `${dollarFormatter(appointment_production)}`;

      return (
        <div key={id}>
          <div className="flex mt-2 mb-2">
            <span className="text-xs text-slate-600">{userName}</span>
            <span className="ml-2 text-xs text-secondary">
              {productionValue}
            </span>
          </div>
        </div>
      );
    }
  );

  return (
    <div className="divide-y">
      <div className="py-2">
        <h2>{tr("Production")}</h2>
      </div>
      <div className="grid grid-cols-2 py-2">{populateProd}</div>
    </div>
  );
}
