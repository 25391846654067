/* Created by Hannah Green on 5/24/2021 for ES-147 */

/* This is a class because the react-to-print library does not work with 
functions very well and whatever is being printed HAS to be a class */

import React from "react";
import ReportTable from "./report-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// export default class BothTables extends React.Component {

//     render() {
//         return (
//             <div>
//                 <div>
//                     <ReportTable headers={openRequest} data={this.props.data.openRequests} title={"Open Requests"} />
//                 </div>
//                 <div>
//                     <ReportTable headers={lastFive} data={this.props.data.lastFive} title={"Completed in the Past 5 Days"} />
//                 </div>
//             </div>
//         )
//     }
// }

const BothTables = ({ data }) => {
  const { t } = useTranslation();
  const tBase = "views.repair_request.components.reports.both-tables";
  const tr = (key) => t(`${tBase}.${key}`);

  const lastFive = [
    tr("Start Date"),
    tr("Closing Date"),
    tr("Duration"),
    tr("Request"),
    tr("Equipment"),
    tr("Priority"),
    tr("Comments"),
    tr("Maintenance Notes"),
  ];

  const openRequest = [
    tr("Start Date"),
    tr("Last Updated"),
    tr("Request"),
    tr("Equipment"),
    tr("Priority"),
    tr("Comments"),
  ];

  return (
    <div>
      <div>
        <ReportTable
          headers={openRequest}
          data={data.openRequests}
          title={tr("Open Requests")}
        />
      </div>
      <div>
        <ReportTable
          headers={lastFive}
          data={data.lastFive}
          title={tr("Completed in the Past 5 Days")}
        />
      </div>
    </div>
  );
};

export default BothTables;
