import * as React from "react";
// @ts-ignore: Unreachable code error
import * as PdfJs from "pdfjs-dist";

// import Worker from 'pdfjs-dist/build/pdf.worker'

PdfJs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/build/pdf.worker.js`;

export const generateThumbnail = async (
  canvasRef: any,
  rawFile: any,
  type: "pdf" | "image" | "csv",
  url?: any,
): Promise<Blob> => {
  if (type === "pdf") {
    const file = await rawFile.arrayBuffer();
    const doc = await PdfJs.getDocument(file).promise;
    const page = await doc.getPage(1);
    const rotated = page.rotate === 90 || page.rotate === 270;
    const pageWidth = !rotated ? page.view[2] : page.view[3];
    const pageHeight = !rotated ? page.view[3] : page.view[2];
    const aspectRatio = pageWidth >= pageHeight ? "landscape" : "portrait";
    const scale =
      aspectRatio === "landscape" ? 200 / pageHeight : 200 / pageWidth;
    const viewPort = page.getViewport({ scale: scale });
    const canvas = canvasRef.current!;
    const context = canvas.getContext("2d");
    canvas.height = viewPort.height;
    canvas.width = viewPort.width;
    const renderContext = {
      canvasContext: context!,
      viewport: viewPort,
    };
    await page.render(renderContext).promise;
    return await new Promise((resolve) => canvas.toBlob(resolve));
  } else {
    const reader = new FileReader();

    const blob = (callback: (key: Blob) => void) => {
      reader.onload = (readerEvent) => {
        const image = new Image();
        // console.log("reader loaded");
        image.onload = async (imageEvent) => {
          // console.log("image loaded");
          const aspectRatio =
            image.width >= image.height ? "landscape" : "portrait";
          const scale =
            aspectRatio === "landscape"
              ? 200 / image.height
              : 200 / image.width;
          const canvas = canvasRef.current!;

          const scaledWidth = image.width * scale;
          const scaledHeight = image.height * scale;

          canvas.width = scaledWidth;
          canvas.height = scaledHeight;

          canvas
            .getContext("2d")
            .drawImage(image, 0, 0, scaledWidth, scaledHeight);

          canvas.toBlob((blob: Blob) => callback(blob));
          //   return await new Promise((resolve) => canvas.toBlob(resolve));
        };

        // if (type === "csv") {
        //   image.src = url as string;
        // }
        if (readerEvent.target) {
          image.src = readerEvent.target.result as string;
        }
        // console.log("imarge src: ", image.src)
      };

      if (type === "csv") {
        reader.readAsDataURL(rawFile);
      } else {
        reader.readAsDataURL(rawFile);
      }
      // console.log("reader: ", reader)
    };

    return await new Promise((resolve) => blob(resolve));
  }
};
