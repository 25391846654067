import React, { useState, useEffect, FC } from "react";
import Switch from "rc-switch";
import axios from "axios";
import { useTranslation } from 'react-i18next';

interface OtherSettingsProps {
  userId: string;
}

const OtherSettings: FC<OtherSettingsProps> = ({ userId }) => {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.permission.components.OtherSettings";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isAssignable, setIsAssignable] = useState<boolean>(false);
  const [isFieldManager, setIsFieldManager] = useState<boolean>(false);
  const [isInactive, setIsInactive] = useState<boolean>(false);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/employee/userSettings/${userId}`
      );

      setIsAssignable(response.data.is_assignable);
      setIsFieldManager(response.data.is_field_manager);
      setIsInactive(response.data.status_inactive);
    } catch (error) {
      console.error(error);
    }
  };

  const updateSetting = async (setting: string, value: boolean) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/employee/userSettings/${setting}`,
        {
          userId,
          value,
        }
      );

      if (response.status === 200) {
        fetchUserSettings();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserSettings();
  }, [userId]);

  return (
    <div className="mt-4  space-y-4">
      <h2>{tr("Other Settings")}:</h2>

      <div className="flex flex-row align-middle">
        <Switch
          className="mx-3"
          checked={isAssignable}
          onChange={(prev) => updateSetting("assignable", prev)}
        />
        <p>{tr("Can be assigned appointments")}</p>
      </div>

      <div className="flex flex-row align-middle">
        <Switch
          className="mx-3"
          checked={isFieldManager}
          onChange={(prev) => updateSetting("fieldManager", prev)}
        />
        <p>{tr("Is field app manager")}</p>
      </div>

      <div className="flex flex-row align-middle">
        <Switch
          className="mx-3"
          checked={!isInactive}
          onChange={(prev) => updateSetting("status", !prev)}
        />
        <p>{isInactive ? tr("Inactive") : tr("Active")}</p>
      </div>
      
    </div>
  );
};

export default OtherSettings;
