import * as React from "react";
import { useTranslation } from 'react-i18next';

export default function Failed() {
  const { t } = useTranslation();
  const tBase = "components.failed";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return <div>{tr("Failed to load")}</div>;
}
