import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "components/buttonV2";
import ViewBySelect from "./ViewBySelect";
import { Link } from "react-router-dom";
import { IAccordionStates, IHeaderProps } from "../types";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from 'react-i18next';

const Header = (
  {
    duplicateCount,
    accordionStates,
    setAccordionStates,
    customerData,
    findDuplicates,
    loading2,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    updateItemsPerPage,
    scanComplete,
    yesScanned,
    lastScanned
  }: IHeaderProps
) => {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.Header";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0;

  const formatDate = (date: Date) => {
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };
  
  useEffect(() => {
    setAccordionStates({});
  }, [currentPage]); 

  return (
    <>
      <div className="flex justify-between items-center mt-[23px]">
        <div>
          <span className="text-2xl font-bold">{tr("Customer Duplicates")}: </span>
          <span className="text-2xl text-secondary">{duplicateCount}</span>
          <span className="text-gray-400 ml-0.5">(Total)</span>
        </div>
        <div className="flex items-center space-x-2">

          {/* Close all accordion */}
          {!isObjectEmpty(accordionStates) ? (
            <Button onClick={() => setAccordionStates({})}>
              <FaAngleDoubleUp />
            </Button>
          ) : (
            <Button 
              onClick={() => {
                let newAccordionStates: IAccordionStates = {};
                customerData.forEach(customer => {
                  if (customer.original && customer.original.id) {
                    newAccordionStates[customer.original.id] = true; // Ensure we're using the correct ID
                  }
                });
                setAccordionStates(newAccordionStates);
              }}
            >
              <FaAngleDoubleDown />
            </Button>
          )}

          <ViewBySelect {...{itemsPerPage, setItemsPerPage, updateItemsPerPage}} />

          <Button 
            onClick={() => findDuplicates("rescan")}
            disabled={loading2}
          >
            {yesScanned ? tr("Rescan") : tr("Scan")} 
            <span className="scale-y-[-1]">
              <HiOutlineRefresh size={20} className={`ml-1 ${loading2 ? "animate-spinReverse" : "animate-none"}`} />
            </span>
          </Button>

          <Link 
            to="/customers"
            className="no-underline"
          >
            <Button>
              {tr("Customers")}
            </Button>
          </Link>
          
        </div>
      </div>
      <div className="flex justify-end italic text-gray-400 mt-3">
        {tr("Last scanned")}: {lastScanned ? formatDate(lastScanned ?? new Date()) : "Never"}
      </div>
      <div className="border-b border-gray-300 mt-3" />
    </>
  )
}

export default Header