import axios from "axios";
import constructUrl from "utils/constructUrl";
import clients from "constants/clients";
import domains from "constants/domains";
import productionCentralEndpoints from "constants/routes/admin/production-central";
import IFormValues from "../types/IFormValues";

const getProductionItems = async (formData: IFormValues) => {
  const client = clients.COMPANY;
  const domain = domains.METRICS;
  const endpoint = productionCentralEndpoints.ITEMS;

  try {
    const response = await axios.get(
      constructUrl({ client, domain, endpoint }),
      {
        params: formData,
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Error fetching production items");
  }
};

export default getProductionItems;
