const CompanyGoalCompletion = ({
  ytd,
  goal,
}: {
  ytd: number;
  goal: number;
}) => {
  const completionPercentage = Math.min((ytd / goal) * 100, 100);
  return (
    <div className="w-full h-2 bg-gray-600 rounded-full">
      <div
        className="h-2 rounded-full"
        style={{
          width: `${completionPercentage}%`,
          background: "linear-gradient(to right, #0088FE, #6ee794)",
        }}
      ></div>
    </div>
  );
};

export { CompanyGoalCompletion };
