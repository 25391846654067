import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const color = ["#004D9B", "#004D9B", "#004D9B"];

const Con = styled.div`
  width: 100%;
  display: ${(props) => props.display};
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 2fr 1fr;
  height: 150px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px grey;
  overflow: hidden;
  position: relative;
  &:hover {
    box-shadow: 3px 3px 6px -3px rgb(78, 78, 78);
    cursor: pointer;
  }

  &::before {
    content: "";
    position: absolute;
    height: 150px;
    width: 1.5%;
    background-color: ${(props) => props.pending};
  }
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
`;

const Name = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  text-align: left;
  font-size: 18px;
  margin-left: 2px;
`;

const TruckCon = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  font-size: 15px;

  > div:first-child {
    font-size: 20px;
    letter-spacing: 2px;
  }
  > div:last-child {
  }
`;

const Breakdown = styled.div`
  border-top: 1px solid rgba(128, 128, 128, 0.445);
  grid-column: 1 / span 3;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-size: 15px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33.33%;
    > div:first-child {
      color: grey;
    }
  }
  > div:not(:last-child) {
    border-right: 1px solid rgba(128, 128, 128, 0.445);
  }
`;

export default function UserCard(props) {
  const { t } = useTranslation();
  const tBase = "views.dashboard.user-card";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  let userName;

  if (!props.transfer.user) {
    userName = tr("Unassigned");
  } else {
    userName = props.transfer.user;
  }

  const openDashboard = () => {
    history.push({
      pathname: `/dashboard/${props.transfer.id}/${props.transfer.employee_id}/${userName}/${props.transfer.transfer_date}`,
    });
  };

  const formatDate = (date) => {
    const oldDate = new Date(date);
    return oldDate.toLocaleDateString();
  };

  const pending = props.transfer.total - props.transfer.done;
  const status = props.transfer.status;
  const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1);

  return (
    <Con
      onClick={() => openDashboard()}
      display={
        userName.toLowerCase().indexOf(props.display.toLowerCase()) === -1
          ? "none"
          : "grid"
      }
      pending={pending > 0 ? "orange" : "green"}
    >
      <Image>
        <Avatar
          alt={userName}
          src="/broken-image.jpg"
          style={{
            width: "60px",
            height: "60px",
            backgroundColor: color[Math.floor(Math.random() * 3)],
          }}
        >
          {userName.match(/\b(\w)/g).join("")}
        </Avatar>
      </Image>
      <Name>
        <p>{userName}</p>
      </Name>
      <TruckCon>
        <div>{props.transfer.vehicle}</div>
        <div>{props.jobTitle}</div>
        <div>{formatDate(props.transfer.transfer_date)}</div>
      </TruckCon>
      <Breakdown>
        <div>
          <div>{props.transfer.total}</div>
          <div>{tr("Total")}</div>
        </div>
        <div>
          <div>
            {parseInt(
              ((props.transfer.total - pending) / props.transfer.total) * 100
            )}
            %
          </div>
          <div>{tr("Progress")}</div>
        </div>
        <div>
          <div>{statusCapitalized}</div>
          <div>{tr("Status")}</div>
        </div>
      </Breakdown>
    </Con>
  );
}
