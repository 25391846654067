import React, { useState } from "react";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const GroupPunchExportOptions = (props) => {
    const { t } = useTranslation();
    const tBase = "views.time_dashboard.GroupPunchExportOptions";
    const tr = (key) => t(`${tBase}.${key}`);

    const { downloadexport, filterstring, exportType } = props;
    const [selectedInterval, setSelectedInterval] = useState("Weekly")
    const [startDay, setStartDay] = useState("Sunday")

    const handleClick = (e) => {
        setSelectedInterval(e.target.value)
    }

    const handleSubmit = () => {
        downloadexport({ filterstring: filterstring, exportType: exportType }, { interval: selectedInterval, startDay: startDay })
        // console.log(filterstring)
        console.log("Submit")
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection:"row", justifyContent: "space-around", width: "100%", borderBottom: "1px solid lightgrey" }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                    <label style={{ fontSize: "20px" }}>{tr("Weekly")}</label>
                    <label style={{ fontSize: "20px" }}>{tr("Bi-Weekly")}</label>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
                    <input
                        type="checkbox"
                        style={{ width: "20px", height: "20px" }}
                        value="Weekly"
                        onClick={(e) => handleClick(e)}
                        checked={selectedInterval === "Weekly"}
                    />
                    <input 
                        type="checkbox" 
                        style={{ width: "20px", height: "20px" }} 
                        value="Biweekly" 
                        onClick={(e) => handleClick(e)}
                        checked={selectedInterval === "Biweekly"}
                    />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", marginTop:"20px" }}>
                <label style={{ fontSize: "20px" }}>{tr("Start Date")}</label>
                <select onChange={(e) => setStartDay(e.target.value)} style={{ width: "50%", height: "30px", fontSize: "20px" }}> 
                    <option value="Sunday">{tr("Sunday")}</option>
                    <option value="Monday">{tr("Monday")}</option>
                    <option value="Tuesday">{tr("Tuesday")}</option>
                    <option value="Wednesday">{tr("Wednesday")}</option>
                    <option value="Thursday">{tr("Thursday")}</option>
                    <option value="Friday">{tr("Friday")}</option>
                    <option value="Saturday">{tr("Saturday")}</option>
                </select>
            </div>
            <Button onClick={handleSubmit} style={{ width: "100%", marginTop: "20px", background: "#ff4f00", color: "white", fontSize: "20px" }}>{tr("Submit")}</Button>
        </>
    )
}

export default GroupPunchExportOptions;
