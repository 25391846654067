import { useFormik } from "formik";
import * as React from "react";
import styled from "styled-components";
import { Input } from "components/input";
import { Button, SubtleButton } from "components/button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Select from "components/selectv2";
import Popper from "components/react-popperv2";
import { Customer } from "views/requests/newRequestModal/customerForm";
import { Project } from "../views/project";
import Assignee from "components/react-select/assignees";
import { StyledLabel } from "components/input/style";
import DivisionDropdown from "components/react-select/division";
import Field from "components/field";
import ClientSelect from "components/react-select/clients";
import Form from "components/form2";
import NewPropertyModal from "views/properties/newPropertyModal";
import PropertySelect from "components/react-select/property";
import { TProperty } from "types/property";
import { useTranslation } from 'react-i18next';

type FormProps = {
	label: string;
	due_date: string;
	start_date: string;
	division: string | null;
	assignees: { value: string; label: string }[];
	clients: (Customer & { id: number })[];
	property: TProperty | null;
};

type Props = {
	data?: Project;
};

export default function NewProject(props: Props) {
	const { t } = useTranslation();
	const tBase = "views.projects.components.newProject";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const { setModal } = useModalContext(),
		{ showSnackbar } = useSnackbarContext(),
		history = useHistory(),
		queryClient = useQueryClient();

	const [newProperty, setNewProperty] = React.useState(false);

	const addProject = useMutation(
		async (body: FormProps) =>
			await axios
				.post(
					`
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/projects
    `,
					{ ...body, id: props.data?.id }
				)
				.then((res) => res.data),
		{
			onSuccess: (id) => {
				setModal();
				queryClient.invalidateQueries();
				history.push(`/projects/${id}`);
				showSnackbar(tr("Project created!"));
			},
		}
	);

	const initialValues = {
		label: props.data?.label || "",
		// address: props.data?.address || "",
		due_date: props.data?.due_date || "",
		start_date: props.data?.start_date || "",
		division: props.data?.division?.id || "",
		assignees:
			props.data?.assignees?.map((assignee) => ({
				label: assignee.first_name + " " + assignee.last_name,
				value: assignee.id.toString(),
			})) || [],
		clients: props.data?.clients || [],
		billed: props.data?.billed || "",
		trip_count: props.data?.trip_count || "",
		manager: props.data?.manager ? [props.data?.manager] : [],
		property: props.data?.property || null,
	};

	const validationSchema = Yup.object({
		label: Yup.string().required(),
		// address: Yup.string(),
		due_date: Yup.mixed().nullable(),
		start_date: Yup.mixed().nullable(),
		clients: Yup.array().nullable(),
		assignees: Yup.array().nullable(),
		billed: Yup.mixed().nullable(),
		manager: Yup.array().nullable(),
	});

	const onSubmit = (e: FormProps) => {
		addProject.mutate(e);
	};

	const formik = useFormik<FormProps>({
		initialValues,
		onSubmit,
		enableReinitialize: true,
		validationSchema,
	});

	return (
		<Con>
			<Form formik={formik}>
				<Input required name="label" label={tr("Project Label")} autoFocus />
				{/* <Input name="address" label="Address" /> */}
				<Input name="due_date" type="date" label={tr("Due Date")} />
				<Input
					name="start_date"
					type="date"
					label={tr("Projected Start Date")}
				/>
				<div>
					<StyledLabel>Division</StyledLabel>
					<DivisionDropdown
						value={formik.values.division || null}
						onChange={(e: any) =>
							formik.setValues((v) => ({
								...v,
								division: e?.value || "",
							}))
						}
					/>
				</div>
				<div>
					<StyledLabel>{tr("Assignees")}</StyledLabel>
					<Assignee assigneeType="all" name="assignees" />
				</div>

				<div>
					<StyledLabel>{tr("Managers")}/{tr("Designers")}</StyledLabel>
					<Assignee assigneeType="all" name="manager" />
				</div>

				<div>
					<ClientSelect
						label={tr("Clients/Contractors")}
						isMulti
						value={formik.values.clients?.map((c) => ({
							...c,
							value: c.id,
							label: c.name,
						}))}
						onChange={(e) => formik.setFieldValue("clients", e)}
					/>
				</div>
				{props.data && (
					<Input
						name="billed"
						type="number"
						label={tr("Percentage Billed (%)")}
					/>
				)}
				<Input
					name="trip_count"
					type="number"
					label={tr("Total # of Trip")}
				/>
				<div className="divide-y">
					<h3 className="py-2">{tr("Property")}</h3>
					<div className="flex flex-col py-2 space-y-2">
						{newProperty ? (
							<NewPropertyModal
								notModal
								onCancel={() => setNewProperty(false)}
								onSubmit={() => setNewProperty(false)}
							/>
						) : (
							<>
								<PropertySelect
									menuPlacement="top"
									onChange={(e) =>
										formik.setFieldValue("property", e)
									}
									label={tr("Select Property")}
									value={
										formik.values.property
											? {
													...formik.values.property,
													value: formik.values
														.property.id,
													label: formik.values
														.property.full_address,
											  }
											: null
									}
								/>
								<span className="self-center">{tr("or")}</span>

								<Button
									onClick={() => {
										setNewProperty(true);
									}}
								>
									{tr("Create New Property")}
								</Button>
							</>
						)}
					</div>
				</div>

				<Button type="submit" primary>
					{tr("Submit")}
				</Button>
			</Form>
		</Con>
	);
}

const Con = styled.div`
	width: 100vw;
	max-width: 600px;
	> form {
		display: flex;
		flex-direction: column;
		> *:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
`;
