import React from "react";
import CustomerTag from "./customer-tag";

function CustomerTagList(props) {
  if (!props.tags.customer_tags) return null;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {props.tags.customer_tags?.map((tag) => {
        return (
          <CustomerTag key={tag.label} color={tag.color} label={tag.label} />
        );
      })}
    </div>
  );
}

export default CustomerTagList;
