import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RawForm from "components/form";
import DatePicker from "react-datepicker";
import { useModalContext } from "components/modal";
import "react-datepicker/dist/react-datepicker.css";
import Select from "components/react-select";
import ConfirmationPopup from "./components/confirmationPopup";
import { useCartCountState } from "../../components/cart";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18n from "i18n";
const { Button } = require("components/button");
const { Input } = require("components/input");

const SummaryCon = styled.div`
  font-size: 1rem;
  width: 15%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  background-color: LightGray;
  padding: 1rem;
  border-radius: 5px;
  > h3 {
    font-size: 30px;
    padding-bottom: 0.5rem;
  }
`;

const Form = styled(RawForm)`
  > button {
    margin-top: 1rem;
  }

  > p {
    font-weight: bold;
    font-size: 1.2em;
  }
`;

const Note = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  color: red;
`;

const StyledDatePicker = styled(DatePicker)`
  outline: none;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 5px;
  background: #f0f0f0;
  margin: 0;
  padding: 0.5em;
  padding-left: 2em;
  width: calc(100% - 1em - 2px - 5em);
  transition: all 0.09s linear;

  &:hover {
    border: 1px solid #5d6d7e;
  }
  &:focus {
    background: transparent;
    border: 1px solid #3498db;
  }
`;

const StyledLabel = styled.label`
  font-size: 0.8rem;
  font-weight: bold;
`;

const Asterick = styled.span`
  color: red;
`;

export default function Summary(props) {
  const { t } = useTranslation();
  const tBase = "views.cart.summary";
  const tr = (key) => t(`${tBase}.${key}`);

  const { items } = props;
  const cartState = useCartCountState();
  const [date, setDate] = useState(new Date());
  const [warehouses, setWarehouses] = useState();
  const [techs, setTechs] = useState();
  const [whse, setWhse] = useState();

  const labels = [
    { value: "Plan A", label: tr("Plan A") },
    { value: "Plan R", label: tr("Plan R")},
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const whses = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/specialOrder?type=WAREHOUSES`
      );
      setWarehouses(whses.data);

      const techs = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/specialOrder?type=GETTECHS`
      );

      setTechs(techs.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const { setModal } = useModalContext();

  return (
    <SummaryCon>
      <Note className={`${i18n.language === "es" ? "-mb-6" : "-mb-0"}`}>{tr("Note")}: {tr("Please split the orders for different Techs.")}</Note>
      <Form
        initialValues={{
          assigned_tech: "",
          job_num: "",
          job_address: "",
          warehouse: { label: "", value: "" },
        }}
        onSubmit={(values) => {
          values.projected_job_date = date;
          setModal({
            component: <ConfirmationPopup items={items} values={values} />,
            label: tr("Confirmation"),
          });
        }}
      >
        {cartState.specialForm && (
          <>
            <p>{tr("Special Order")}</p>
            <Select
              label={tr("Assigned Tech")}
              required={true}
              name="assigned_tech"
              options={techs}
            />
            <Input label={tr("Job #")} required={true} name="job_num" />
            <Input label={tr("Job Address")} required={true} name="job_address" />
            <Select
              label={tr("Label")}
              required={false}
              name="label"
              options={labels}
            />
            <StyledLabel>{tr("Projected Job Date")}</StyledLabel>
            <Asterick>({tr("Required")})</Asterick>
            <StyledDatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              name="projected_job_date"
              value={date}
            />
          </>
        )}

        {cartState.bulkForm && (
          <>
            <p>{tr("Bulk Order")}</p>
            <Select
              label={tr("Warehouse")}
              required={true}
              name="warehouse"
              options={warehouses}
              value={whse}
            />
          </>
        )}

        <Button type="submit" primary>
          {tr("Checkout")}
        </Button>
      </Form>
    </SummaryCon>
  );
}
