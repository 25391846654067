import React, { useState, useEffect } from "react";
import { Props } from "react-select";
import Field from "components/field";
import { useTranslation } from 'react-i18next';

type TProps = Omit<Props, "value"> & {
  value?: string | null;
};

export default function TimezoneSelect(props: TProps) {
  const { t } = useTranslation();
  const tBase = "components.react-select";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const timezoneOptions = [
    { label: "Washington, DC (GMT-5)", value: "America/New_York" },
    { label: "Chicago (GMT-6)", value: "America/Chicago" },
    { label: "Denver (GMT-7)", value: "America/Denver" },
    { label: "Phoenix (GMT-7)", value: "America/Phoenix" },
    { label: "Los Angeles (GMT-8)", value: "America/Los_Angeles" },
    { label: "Anchorage (GMT-9)", value: "America/Anchorage" },
    { label: "Honolulu (GMT-10)", value: "Pacific/Honolulu" },
  ];

  return (
    <Field
      type="select"
      style={{ width: "calc(100% + 100px)" }}
      options={timezoneOptions}
      name="select"
      placeholder={tr("Select a timezone")}
      {...props}
      value={props.value ? timezoneOptions.find(option => option.value === props.value) : ""}
    />
  );
}
