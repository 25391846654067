import { FC, PropsWithChildren, createContext, useContext } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import clients from "constants/clients";
import domains from "constants/domains";
import endpoints from "constants/routes/map/index";
import constructUrl from "utils/constructUrl";
import TMapAppointment from "../types/TMapAppointment";

type AppointmentsContextType = {
  data: { appointments: TMapAppointment[] } | undefined;
  isLoading: boolean;
  isError: boolean;
};

const AppointmentsContext = createContext<AppointmentsContextType | undefined>(
  undefined
);

const client = clients.COMPANY;
const domain = domains.MAP;
const endpoint = endpoints.APPOINTMENTS;

const fetchAppointments = async () => {
  const url = constructUrl({ client, domain, endpoint });
  const response = await axios.get(url);
  return response.data;
};

export const AppointmentsProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { data, isLoading, isError } = useQuery(
    "map-appointments",
    fetchAppointments
  );

  return (
    <AppointmentsContext.Provider value={{ data, isLoading, isError }}>
      {children}
    </AppointmentsContext.Provider>
  );
};

export const useAppointments = (): AppointmentsContextType => {
  const context = useContext(AppointmentsContext);
  if (context === undefined) {
    throw new Error(
      "useAppointments must be used within a AppointmentsProvider"
    );
  }
  return context;
};
