import React from "react";
import Item from "./item";
import Summary from "./summary";
import styled from "styled-components";
import { useCartCountState } from "../../components/cart";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  width: 100%;
  padding: 1rem;
`;
const CartCon = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: right;
  text-align: center;
  float: right;
  > div:first-child {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    top: 0;
    font-size: 25px;
    background-color: white;
    > * {
      padding: 0;
      margin: 20px 10px 20px 0;
    }
    > div:last-child {
      color: grey;
    }
  }
`;
const Loader = styled.div`
  color: grey;
  margin-top: 20px;
  text-align: center;
`;

export default function Cart(props) {
  const { t } = useTranslation();
  const tBase = "views.cart.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const cartCountState = useCartCountState();
  const itemCount = cartCountState.count;

  const itemOrdered =
    cartCountState.items.length !== 0 ? (
      cartCountState.items.map((e, index) => {
        return <Item key={e.item_id} item={e} isFavourite={true} />;
      })
    ) : (
        <Loader>{tr("Nothing to show here.")}</Loader>
      );

  return (
    <Con>
      <CartCon>
        <div>
          <h3>{tr("Cart")}</h3>
          <div>({itemCount} {tr("items")})</div>
        </div>
        {itemOrdered}
      </CartCon>
      {itemCount > 0 && <Summary items={cartCountState.items} />}
    </Con>
  );
}
