import * as React from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import styled, { keyframes } from "styled-components";
import { TWarrantyTargetItem } from "types/warranty";

type Props = {
  color: string;
  item: TWarrantyTargetItem;
  diffDay: number | null;
  currDay: number | null;
};
export default function CountdownRing(props: Props) {
  const bg =
    props.color === "red"
      ? "bg-red-100"
      : props.color === "amber"
      ? "bg-amber-100"
      : props.color === "slate"
      ? "bg-slate-100"
      : "bg-green-100";

  const txt =
    props.color === "red"
      ? "text-red-600"
      : props.color === "amber"
      ? "text-amber-600"
      : props.color === "slate"
      ? "text-slate-600"
      : props.color === "blue"
      ? "text-blue-600"
      : "text-green-600";

  const color =
    props.color === "red"
      ? "#dc2626"
      : props.color === "amber"
      ? "#d97706"
      : props.color === "slate"
      ? "#f0f0f0"
      : props.color === "blue"
      ? "#3563eb"
      : "#16a34a";

  const icon = !props.item.start_date ? (
    <div className={`text-4xl ${bg} rounded-full p-2`}>
      <HiOutlineDotsHorizontal />
    </div>
  ) : (
    <div
      className={`${txt} ${bg} rounded-full w-[50px] h-[50px] flex flex-col justify-center items-center`}
    >
      <span className="text-xl">{props.diffDay}</span>
    </div>
  );

  //   return <div>{icon}</div>;

  const circum = 20 * 2 * Math.PI,
    offset =
      props.diffDay && props.currDay
        ? circum - (props.currDay / props.diffDay) * circum
        : 0;

  return (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <circle
        stroke={"#f0f0f0"}
        strokeDasharray={`${circum} ${circum}`}
        stroke-strokeDashoffset={circum}
        stroke-width={2.5}
        fill="transparent"
        r="20"
        cx="25"
        cy="25"
      />

      <Circle
        stroke={color}
        strokeDasharray={`${circum} ${circum}`}
        fill="transparent"
        r="20"
        cx="25"
        cy="25"
        offset={offset}
        circum={circum}
      />
      <text
        stroke={color}
        x="50%"
        y="50%"
        stroke-width="1"
        dominantBaseline={"middle"}
        textAnchor={"middle"}
      >
        {props.currDay || "TBD"}
      </text>
    </svg>
  );
}

const load = (offset: number) => keyframes`
    to {
        stroke-dashoffset: ${offset};
    }
`;
const Circle = styled.circle<{ offset: number; circum: number }>`
  stroke-dashoffset: ${(props) => props.circum};
  stroke-width: 2.5;
  stroke-linecap: round;
  animation: ${(props) => load(props.offset)} 0.8s ease-out forwards;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
