import { useMutation, useQueryClient } from "react-query";
import { updateAppointmentType } from "../api/updateAppointmentType";
import { IAppointmentType } from "../types/IAppointmentType";

export const useAppointmentUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (updatedAppointmentType: IAppointmentType) =>
      updateAppointmentType(updatedAppointmentType),
    {
      onError: (error) => {
        console.error(error);
      },
      onSettled: () => {
        queryClient.invalidateQueries("appointment-types");
      },
    }
  );
};
