import { ButtonProps, MenuButton } from "components/button";
import Popper from "components/react-popperv2";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Data = {
  label?: string;
  button: (
    | (Omit<ButtonProps, "children"> & {
        label: React.ReactNode;
        hide?: boolean;
      })
    | null
    | undefined
    | false
  )[];
};
type Props = {
  reference: any;
  data: Data[];
  employeeList?: boolean;
  handleInputChange?: (value: string) => void;
  inputBlurred?: () => void;
};
export default function Dropdown(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.dropdown";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const container = (cprops: any) => {
    const populateGroup = props.data?.map((data, i) => {
      const populateButtons = data.button?.map((button, i) => {
        if (!button) return;
        let { hide, label, onClick, ...rest } = button;

        if (hide) return null;

        const handleClick = () => {
          // @ts-ignore
          onClick();
          cprops.close();
        };

        return (
          <MenuButton key={i} onClick={handleClick} {...rest}>
            {button.label}
          </MenuButton>
        );
      });
      return (
        <SGroup key={i}>
          {data.label && <span>{data.label}</span>}
          {populateButtons}
        </SGroup>
      );
    });
    return <>
    {props.employeeList && props.handleInputChange &&
      <div>
        <input type="text" style={{width: '75%', margin:"10px", padding:"5px"}} placeholder={tr("Search")} onChange={(e) => props.handleInputChange!(e.target.value)} autoFocus onBlur={() => props.inputBlurred!()}/>
      </div>
    }
    {populateGroup}
    
    </>;
  };

  return (
    <Popper
      reference={(rprops) =>
        React.cloneElement(props.reference, { onClick: () => rprops.toggle() })
      }
      container={container}
    />
  );
}

const SGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
