import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'components/button';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function UpdateCardForm({ customerId, setShowCardInput } : any) {
  const { t } = useTranslation();
  const tBase = "views.subscription.components.updateCardForm";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [countDownValue, setCountDownValue] = useState(5);

  const handleNameChange = (e:any) => {
    setCardholderName(e.target.value);
  };

  const countDown = () => {
    const interval = setInterval(() => {
      setCountDownValue((prevState) => prevState - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setShowCardInput(false);
    }, 5000);

  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    if (!stripe)
      return;

    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements!.getElement(CardElement)!,
      billing_details: {
        name: "johnny test",
      }
    });

    if (!paymentMethod)
      return;
    
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/update_card_information`,
        {
          customerId: customerId,
          paymentMethod: paymentMethod,
        }
      )

      if (response.data.success) {
        setSuccessMessage("Card updated successfully!");
        setErrorMessage('');

        countDown()
      }
      else {
        setErrorMessage("Error updating card.");
        setSuccessMessage('');
      }
  };

  return (
    <form onSubmit={handleSubmit} style={{display:"grid", gridTemplateRows:"1fr 2fr 1fr", backgroundColor:"white", padding:"10px", borderRadius:"10px"}}>
      <label>
        {tr("Cardholder Name")}:
        <input style={{borderRadius:"10px", border:"black 2px dotted", float:"right"}} type="text" value={cardholderName} onChange={handleNameChange} />
      </label>
      <CardElement />
      <Button primary style={{float:"right"}} type="submit">{tr("Update Card")}</Button>
      {errorMessage && <p>{tr(errorMessage)}</p>}
      {successMessage && <p>{tr(successMessage)}. {tr("Closing in")}...{countDownValue}</p>}

    </form>
  );
}

export default UpdateCardForm;