import axios from "axios";
import TooltippedElement from "components/TooltippedElement";
import { Button, OutlineButton } from "components/button";
import Field from "components/field";
import { useSnackbarContext } from "components/snackbar";
import { Formik, useFormik } from "formik";
import * as React from "react";
import { RiPencilLine, RiPencilRuler2Fill } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { theme } from "styles/theme";
import { TCustomField } from "types/custom-field";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function CustomFields(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.property.customFields";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [isEdit, setIsEdit] = React.useState(false);
  const snackbar = useSnackbarContext(),
    queryClient = useQueryClient();
  const query = useQuery<TCustomField[] | null[]>(
    ["property custom field", props.id],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${props.id}/custom_fields
    `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async (e: { cf: TCustomField[] | null[] }) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${props.id}/custom_fields
  `,
        e
      ),
    {
      onSuccess: () => {
        setIsEdit(false);
        queryClient.invalidateQueries();
        snackbar.showSnackbar(tr("Custom fields updated!"));
      },
    }
  );

  const initialValues = {
    cf: query.data ? query.data : [null],
  };

  const onSubmit = (e: { cf: TCustomField[] | null[] }) => {
    mutation.mutate(e);
  };

  const form = useFormik<{ cf: TCustomField[] | null[] }>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const populateCustomFields = query.data?.map((cf, i) => {
    if (!cf) return null;

    return (
      <div key={cf.id} className="h-14 grid grid-cols-4 items-center gap-5">
        <span className="text-right">{cf.custom_key}</span>
        {isEdit ? (
          <Field size="small" name={`cf.${i}.custom_value`} formik={form} />
        ) : (
          <span className="col-span-3">
            {cf.custom_value || (
              <span className="text-slate-400 font-thin text-sm">{tr("Empty")}</span>
            )}
          </span>
        )}
      </div>
    );
  });

  return (
    <div className="divide-y">
      <div className="py-4 flex justify-between items-center">
        <h3>{tr("Property Custom Fields")}</h3>
        <div className="space-x-4">
          {isEdit ? (
            <>
              <OutlineButton onClick={() => setIsEdit(false)}>
                {tr("Cancel")}
              </OutlineButton>
              <Button primary type="submit" onClick={() => form.handleSubmit()}>
                {tr("Save Changes")}
              </Button>
            </>
          ) : (
            <TooltippedElement
            message={tr("Edit custom fields")}
            element={
            <OutlineButton primary onClick={() => setIsEdit(true)}>
              <RiPencilLine/>
            </OutlineButton>}/>
          )}
        </div>
      </div>
      <form onSubmit={form.handleSubmit}>{populateCustomFields}</form>
    </div>
  );
}
