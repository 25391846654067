import axios from "axios";
import constructUrl from "utils/constructUrl";
import clients from "constants/clients";
import domains from "constants/domains";

const getProductionItems = async () => {
  const client = clients.COMPANY;
  const domain = domains.USERS;

  try {
    const response = await axios.get(constructUrl({ client, domain }));
    return response.data.data;
  } catch (error) {
    throw new Error("Error fetching users");
  }
};

export default getProductionItems;
