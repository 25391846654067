import React, { createContext, useContext, useState } from 'react';
import OnboardingWizard from 'features/onboarding-wizard';

interface OnboardingWizardContextType {
  iconHidden: boolean;
  setIconHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const OnboardingWizardContext = createContext<OnboardingWizardContextType>({} as OnboardingWizardContextType);

export const OnboardingWizardProvider = ({ children }: { children: React.ReactNode }) => {
  const [iconHidden, setIconHidden] = useState(false);

  const value = {
    iconHidden,
    setIconHidden,
  };

  return (
    <OnboardingWizardContext.Provider value={value}>
      <OnboardingWizard />
      {children}
    </OnboardingWizardContext.Provider>
  );
};

export const useOnboardingWizard = () => useContext(OnboardingWizardContext);
