import React from "react";
import ReactDOM from "react-dom";
import "styles/index.css";
import App from "./contexts";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { PageContextProvider } from "./redux/reducers/pageContext";
import { CartCountProvider } from "./components/cart";
import { DisplayModeProvider } from "./contexts/displayMode";
import { DropdownContextProvider } from "./redux/reducers/dropdownContext";
import allReducer from "./redux/reducers";
import { CookiesProvider } from "react-cookie";
import "./i18n";

const store = createStore(
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PageContextProvider>
        <DisplayModeProvider>
          <CartCountProvider>
            <DropdownContextProvider>
              <CookiesProvider>
                <App />
              </CookiesProvider>
            </DropdownContextProvider>
          </CartCountProvider>
        </DisplayModeProvider>
      </PageContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
