import { useQuery } from "react-query";
import axios from "axios";
import { useTranslation } from 'react-i18next';

interface IProperty {
  id: number;
  address: string;
}

const fetchProperties = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/missing-coordinates`
  );
  // console.log(data);
  return data;
};

const Geocoding = () => {
  const {
    data: properties,
    isLoading,
    error,
  } = useQuery("properties", fetchProperties);
  const { t } = useTranslation();
  const tBase = "views.admin.geocoding.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  if (isLoading) return tr("Loading...");
  if (error) return tr("An error has occurred: ") + (error as Error).message;

  return (
    <div>
      <h1>{tr("Geocoding")}</h1>
      {properties &&
        properties.map((property: IProperty) => (
          <div key={property.id}>
            <p>{property.address}</p>
          </div>
        ))}
    </div>
  );
};

export default Geocoding;
