import { OutlineButton } from "components/button";
import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useTranslation } from 'react-i18next';
import TMode from "../types/TMode";
import useSelectedAppointments from "../hooks/useSelectedAppointments";
import { FaMapMarkerAlt } from "react-icons/fa";

const Controls = () => {
  const { t } = useTranslation();
  const tBase = "features.map";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { selectedAppointments } = useSelectedAppointments();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState<TMode>("appointments");
  const buttonRef = useRef(null);
  const popperRef = useRef(null);
  const { styles, attributes, update } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement: "bottom-start",
    }
  );

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (update) {
      update();
    }
  }, [menuOpen, update]);

  const selectMode = (mode: TMode) => {
    setSelectedMode(mode);
    setMenuOpen(false);
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="">
        <OutlineButton
          size="small"
          className="flex flex-row items-center mr-2"
          ref={buttonRef}
          onClick={toggleMenu}
        >
          <FaMapMarkerAlt size={10} />{" "}
          <span style={{ textTransform: "capitalize" }}>{selectedMode}</span>
        </OutlineButton>
        {menuOpen && (
          <div
            ref={popperRef}
            style={styles.popper}
            {...attributes.popper}
            className="z-50 bg-white border border-gray-200 rounded shadow-lg"
          >
            <div
              className={`px-4 py-2 border-b rounded cursor-pointer text-xs border-gray-200 ${
                selectedMode === "appointments" ? "bg-blue-500 text-white" : ""
              }`}
              onClick={() => selectMode("appointments")}
            >
              {tr("Appointments")}
            </div>
            <div
              className={`px-4 py-2 rounded cursor-pointer text-xs ${
                selectedMode === "properties" ? "bg-blue-500 text-white" : ""
              }`}
              onClick={() => selectMode("properties")}
            >
              {tr("Properties")}
            </div>
          </div>
        )}
        {selectedMode === "appointments" && (
          <>
            <OutlineButton size="small" className="mr-2">
              {tr("Date")}
            </OutlineButton>
            <OutlineButton size="small" className="mr-2">
              {tr("Division")}
            </OutlineButton>
            <OutlineButton size="small" className="mr-2">
              {tr("Technician")}
            </OutlineButton>
          </>
        )}
      </div>

      <div className="flex flex-row items-center">
        <div className="p-2 text-xs border rounded-md">
          {tr("Selected")}:{" "}
          {selectedAppointments && selectedAppointments.length > 0
            ? selectedAppointments.length
            : 0}
        </div>
        <OutlineButton primary size="small" className="ml-2">
          {tr("Modify")}
        </OutlineButton>
      </div>
    </div>
  );
};

export default Controls;
