import { Avatar } from "components/avatar";
import { A, SubtleButton } from "components/button";
import DataLabel from "components/dataLabel";
import DueIn from "components/due_date";
import { useModalContext } from "components/modal";
import Segment from "components/segment";
import { format } from "date-fns";
import styled from "styled-components";
import { theme } from "styles/theme";
import NewProject from "views/projects/components/newProject";
import type { Project } from "../index";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  data?: Project;
};
export default function ProjectInfo({ data }: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.projects.views.project.components.projectInfo";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const { setModal } = useModalContext();

  if (!data) return null;

  const {
    clients,
    entry_date,
    start_date,
    due_date,
    property,
    billed,
    division,
  } = data;

  const populateClients = clients?.map((client) => {
    const { id, first_name, last_name } = client;
    return (
      <A key={id} size="xsmall" $rounded={true} to={`/customers/${id}`}>
        {`${first_name} ${last_name}`}
      </A>
    );
  }) || <span>{tr("No clients assigned.")}</span>;

  const populateAssignees = data.assignees?.map((assignee) => {
    const { id, first_name, last_name } = assignee;
    return (
      <Avatar
        key={id}
        size="xsmall"
        showLabel
      >{`${first_name} ${last_name}`}</Avatar>
    );
  }) || <span>{tr("No assignee.")}</span>;

  return (
    <div className="divide-y">
      <div className="py-4 flex justify-between items-center">
        <div>
          <h2>{tr("Project Info")}</h2>
        </div>

        <SubtleButton
          primary
          onClick={() =>
            setModal({
              component: <NewProject data={data} />,
              label: tr("Edit Project"),
            })
          }
        >
          {tr("Edit")}
        </SubtleButton>
      </div>
      <div className="py-4 space-y-4">
        <DataLabel title={tr("Created On")}>
          {format(new Date(entry_date), "MMM dd, yyyy hh:mm aa", {
            locale: locale,
          })}
        </DataLabel>
        <DataLabel title={tr("Due Date")}>
          {(due_date && (
            <>
              <DueIn date={due_date} />
              {format(
                new Date(due_date + "T00:00"),
                " • MMM dd, yyyy hh:mm aa", 
                { locale: locale }
              )}
            </>
          )) ||
            "-"}
        </DataLabel>
        <DataLabel title={tr("Address")}>{property?.full_address || "-"}</DataLabel>
        <DataLabel title="Projected Start Date">
          {(start_date && format(new Date(start_date), "MMM dd, yyyy", { locale: locale })) || "-"}
        </DataLabel>
        <DataLabel title={tr("Billed Percentage (%)")}>
          {billed ? billed + "%" : "-"}
        </DataLabel>
        <DataLabel title={tr("Division")}>{division?.label || "-"}</DataLabel>
        <DataLabel title={tr("Assignees")}>
          <SClients>{populateAssignees}</SClients>
        </DataLabel>
        <DataLabel title={tr("Clients")}>
          <SClients>{populateClients}</SClients>
        </DataLabel>
      </div>
    </div>
  );
}

const SClients = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0.25em 0.25em 0.25em 0;
  }
`;
