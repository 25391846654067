import axios from "axios";
import { Avatar } from "components/avatar";
import { OutlineButton, SubtleButton } from "components/button";
import GroupedButton from "components/button/grouped";
import Field from "components/field";
import { useModalContext } from "components/modal";
import ProgressBar from "components/progressBar";
import DivisionDropdown from "components/react-select/division";
import SafeArea from "components/safe-area";
import Tab from "components/tab";
import TableComponent, { useTable } from "components/table";
import { format, sub, isValid, parseISO } from "date-fns";
import useQueryString from "hooks/useQueryString";
import * as React from "react";
import ReactDatePicker from "react-datepicker";
import { useQuery } from "react-query";
import { TAppointment } from "types/appointment";
import { dollarFormatter } from "utils";
import { Status } from "views/request/component/status";
import DownloadAppointments from "./download-apptointment";
import MapModal from "./MapModal";
import BreakdownModal from "./breakdownModal";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

export default function Appointments() {
  const { t, i18n } = useTranslation();
  const tBase = "views.appointments.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const queryString = useQueryString(),
    modal = useModalContext();

  const { column, order } = queryString.search;

  let date =
    queryString.search.date && !Array.isArray(queryString.search.date)
      ? queryString.search.date
      : "all";

  const [dateRange, setDateRange] = React.useState<
    [Date | null, Date | null] | null | Date
  >([null, null]);

  const query = useQuery<{
    appointments: TAppointment[] | null;
    tab_counts: { [key: string]: { count: number } } | null;
    page_count: number;
    total_actual: number;
    total_estimate: number;
  }>(
    ["appointments", queryString.search],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment`,
          {
            params: {
              ...queryString.search,
              date:
                queryString.search.date === "today"
                  ? format(new Date(), "yyyy-MM-dd")
                  : queryString.search.date === "ytd"
                  ? format(sub(new Date(), { days: 1 }), "yyyy-MM-dd")
                  : null,
            },
          }
        )
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  const table = useTable({
    pageIndex:
      (queryString.search.page &&
        !Array.isArray(queryString.search.page) &&
        +queryString.search.page) ||
      1,
    total: query.data?.page_count,
    onPaginate: (page) => {
      queryString.update({ ...queryString.search, page: page });
    },
    onSort: (sort) => {
      queryString.update({ ...queryString.search, page: 1, ...sort });
    },
    sort: {
      column: column && !Array.isArray(column) ? column : null,
      order: order && !Array.isArray(order) ? order : null,
    },
    url: true,
  });

  const columns = {
    id: {
      label: "APPT #",
    },
    fk_status_id: {
      label: tr("Status"),
      cell: (row: any) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    name: {
      label: tr("Customer"),
      maxWidth: "250px",
    },
    assignee: {
      disabled: true,
      label: tr("Assignee"),
      maxWidth: "250px",
      style: {
        display: "flex",
      },
      cell: (row: any) => (
        <div className="space-y-2">
          {row.assignee?.map((assignee: any) => (
            <Avatar
              key={assignee.value}
              size="xsmall"
              showLabel
              user_id={assignee.value}
              style={{ marginRight: "0.5rem" }}
            >
              {assignee.label}
            </Avatar>
          ))}
        </div>
      ),
    },
    estimate_amount: {
      label: tr("Estimated"),
      cell: (row: any) => dollarFormatter(row.estimate_amount),
    },
    actual_amount: {
      label: tr("Actual"),
      cell: (row: any) => dollarFormatter(row.actual_amount),
    },
    breakdown: {
      label: tr("Details"),
      cell: (row: any) => (
        <SubtleButton
          className="!text-blue-700 !underline !px-0"
          size="xsmall"
          onClick={() =>
            modal.setModal({
              component: <BreakdownModal id={row.id} />,
              label: tr("Breakdown"),
            })
          }
        >
          {tr("Breakdown")}
        </SubtleButton>
      ),
    },
    date: {
      label: tr("Date"),
      cell: (row: any) => {
        try {
          // Ensure the date is parsed correctly
          const parsedDate = parseISO(row.date);
          return row.date && isValid(parsedDate)
            ? format(parsedDate, "MMM dd, yyyy", {
              locale: locale,
            })
            : tr("Invalid Date");
        } catch (error) {
          console.error(`Error formatting date: ${row.date}`, error);
          return tr("Invalid Date");
        }
      },
    },
    trips: {
      label: tr("Trips"),
      style: {
        minWidth: "100px",
      },
      cell: (row: any) => (
        <ProgressBar
          progress={row.trips}
          type="division"
          total={row.trip_count}
        />
      ),
    },
  };

  const tabData = {
    all: {
      tab: tr("All"),
      count:
        query.data?.tab_counts &&
        Object.entries(query.data?.tab_counts).reduce(
          (prev: number, [key, curr]) => prev + curr.count,
          0
        ),
    },
    unscheduled: {
      tab: tr("Unscheduled"),
      count: query.data?.tab_counts && query.data.tab_counts["6"]?.count,
    },
    unassigned: {
      tab: tr("Unassigned"),
      count: query.data?.tab_counts && query.data.tab_counts["9"]?.count,
    },
    scheduled: {
      tab: tr("Scheduled"),
      count: query.data?.tab_counts && query.data.tab_counts["10"]?.count,
    },
    in_progress: {
      tab: tr("In Progress"),
      count: query.data?.tab_counts && query.data.tab_counts["13"]?.count,
    },
    completed: {
      tab: tr("Completed"),
      count: query.data?.tab_counts && query.data.tab_counts["14"]?.count,
    },
  };

  const handleDateChange = (type: string) => {
    queryString.update({ division: queryString.search.division, date: type });
  };
  return (
    <div className="divide-y">
      <SafeArea>
        <div className="py-4 flex justify-between">
          <div className="flex">
            <h1>{tr("Appointments")}</h1>
            <GroupedButton className="ml-2">
              <OutlineButton
                size="small"
                $selected={date === "all"}
                onClick={() => handleDateChange("all")}
              >
                {tr("All Time")}
              </OutlineButton>
              <OutlineButton
                size="small"
                $selected={date === "today"}
                onClick={() => handleDateChange("today")}
              >
                {tr("Today")}
              </OutlineButton>
              <OutlineButton
                size="small"
                $selected={date === "ytd"}
                onClick={() => handleDateChange("ytd")}
              >
                {tr("Yesterday")}
              </OutlineButton>
            </GroupedButton>
            <DownloadAppointments />
            <MapModal />
          </div>

          <div>
            <DivisionDropdown
              value={
                (queryString.search.division &&
                  !Array.isArray(queryString.search.division) &&
                  queryString.search.division) ||
                ""
              }
              isClearable
              onChange={(e: any) => {
                e
                  ? queryString.update({
                      division: e.value,
                      date: queryString.search.date,
                    })
                  : queryString.update({});
              }}
              className="!w-[300px]"
              placeholder={tr("Division")}
            />
          </div>
        </div>
        <Tab
          headerOnly
          data={tabData}
          defaultTab={
            (queryString.search.tab &&
              !Array.isArray(queryString.search.tab) &&
              queryString.search.tab) ||
            "all"
          }
          onClick={(tab) =>
            queryString.update({
              division: queryString.search.division,
              date: queryString.search.date,
              tab: tab,
            })
          }
        />
      </SafeArea>
      <SafeArea>
        <div className="py-4">
          <div className="flex bg-slate-100 rounded-lg p-4 space-x-8">
            <div className="flex flex-col">
              <span className="text-sm text-slate-800">Estimate</span>
              <span className="text-xl">
                {dollarFormatter(query.data?.total_estimate)}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-slate-800">Total</span>
              <span className="text-xl">
                {dollarFormatter(query.data?.total_actual)}
              </span>
            </div>
          </div>
          <TableComponent
            {...table}
            columns={columns}
            data={query.data?.appointments}
            to={(row) =>
              `/${row.fk_request_id ? "requests" : "projects"}/${
                row.fk_request_id || row.fk_project_id
              }`
            }
          />
        </div>
      </SafeArea>
    </div>
  );
}
