import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  navigation: ReactNode;
  body: ReactNode;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TopNavigation = styled.div`
  flex: 1;
  height: 40px;
`;

const BodyContainer = styled.div`
  flex: 1;
  height: calc(100vh - 90px);
`;

const TopNavAndBody = ({ navigation, body }: Props) => {
  return (
    <Container>
      <TopNavigation>{navigation}</TopNavigation>
      <BodyContainer>{body}</BodyContainer>
    </Container>
  );
};

export default TopNavAndBody;
