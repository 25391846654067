import { formatCents } from "features/production/utils/formatCents";
import { IoMdRibbon } from "react-icons/io";

const CompanyGoal = ({ companyGoal }: { companyGoal: string | number }) => {
  const formattedGoal = formatCents(companyGoal);

  return (
    <div className="flex flex-row items-center ml-6">
      <IoMdRibbon size={48} color={"rgb(0,250,200)"} />
      <div className="mr-6 ml-2 text-[32px] text-greenFont">
        {formattedGoal}
      </div>
    </div>
  );
};

export { CompanyGoal };
