/* Created by Hannah Green for ES-174 on 7/16/2021 */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from "react-datepicker";
import ReportRow from "../../repair_request/components/reports/report-row";
import { useTranslation } from 'react-i18next';
const { Button } = require('components/button');

const Con = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 3%;
`;

const StyledDatePicker = styled(DatePicker)`
  outline: none;
  font-weight: 500;
  border: 1px solid transparent;
  border-radius: 5px;
  background: #f0f0f0;
  margin: 0;
  padding: 0.5em;
  padding-left: 2em;
  transition: all 0.09s linear;

  &:hover {
    border: 1px solid #5d6d7e;
  }
  &:focus {
    background: transparent;
    border: 1px solid #3498db;
  }
`;

const DateSelection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  > div {
    padding: 5px;

      > label {
          font-weight: bold;
          padding 5px;
      }
  }
`;

const check = (item) => {
    if (item === "G" || item) {
        return <CheckIcon />
    } else {
        return <CloseIcon />
    }
}

const formatReports = (reports) => {
    let formattedReport = [];

    if (reports && reports.length > 0) {
        reports.forEach((r) => {

            /*  r.belts = check(r.belts);
             r.lights = check(r.lights);
             r.leaks = check(r.leaks);
             r.wipers = check(r.wipers);
             r.mirrors = check(r.mirrors);
             r.tire_wear = check(r.tire_wear);
             r.power_steering = check(r.power_steering);
             r.oil = check(r.oil);
             r.washer_fluid = check(r.washer_fluid);
             r.antifreeze = check(r.antifreeze);
             r.trans_fluid = check(r.trans_fluid);
             r.brake_fluid = check(r.brake_fluid); */
            r.is_reviewed = check(r.is_reviewed);

            formattedReport.push(r);
        });
    } else {
        formattedReport = reports;
    }

    return formattedReport;
}

const previousDate = () => {
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 5);
    return daysAgo;
};

export default function Reports() {
    const { t } = useTranslation();
    const tBase = "views.vehicle_inspection.reports.index";
    const tr = (key) => t(`${tBase}.${key}`);

    const dailyDateHeaders = [
        tr("Tech Name"),
        tr("Vehicle Number"),
        tr("Mileage"),
        tr("Description"),
        tr("Comments"),
        tr("Notes"),
        /* 'Belts',
        'Lights',
        'Leaks',
        'Wipers',
        'Mirrors',
        'Power Steering',
        'Oil',
        'Washer Fluid',
        'Antifreeze',
        'Transmission Fluid',
        'Brake Fluid',
        'Tire Wear', */
        tr("Reviewed")
    ];
    
    const betweenDateHeaders = [
        tr("Inspection Date"),
        tr("Tech Name"),
        tr("Vehicle Number"),
        tr("Mileage"),
        tr("Description"),
        tr("Comments"),
        tr("Notes"),
        /* 'Belts',
        'Lights',
        'Leaks',
        'Wipers',
        'Mirrors',
        'Power Steering',
        'Oil',
        'Washer Fluid',
        'Antifreeze',
        'Transmission Fluid',
        'Blake Fluid',
        'Tire Wear', */
        tr("Reviewed")
    ];

    const [dailyDates, setDailyDates] = useState();
    const [betweenDates, setBetweenDates] = useState([]);
    const [startDate, setStartDate] = useState(previousDate());
    const [endDate, setEndDate] = useState(new Date());

    const getBetweenDates = async () => {
        const stringStartDate = startDate.toLocaleDateString();
        const stringEndDate = endDate.toLocaleDateString();
        const betweenDateIns = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/betweenDateReport?startDate=${stringStartDate}&endDate=${stringEndDate}`);
        setBetweenDates(formatReports(betweenDateIns.data));
    }

    const fetchData = async () => {
        const dailyIns = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/dailyReport`);
        setDailyDates(formatReports(dailyIns.data));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const label = tr("Inspection Report Between ") + startDate.toLocaleDateString() + tr(" And ") + endDate.toLocaleDateString();

    return (
        <Con>
            <div>
                {dailyDates &&
                    <>
                        <ReportRow label={tr("Daily Inspection Report")} data={dailyDates} tableHeaders={dailyDateHeaders} />
                    </>
                }
            </div>
            <div>
                <h2>{tr("Select Dates For The Report")}</h2>
                <DateSelection>
                    <div>
                        <label>
                            {tr("Start Date")}
                        </label>
                        <div>
                            <StyledDatePicker selected={startDate} onChange={date => setStartDate(date)} name="end_date" value={startDate} />
                        </div>
                    </div>

                    <div>
                        <label>
                            {tr("End Date")}
                        </label>
                        <div>
                            <StyledDatePicker selected={endDate} onChange={date => setEndDate(date)} name="start_date" value={endDate} />
                        </div>
                    </div>

                    <div>
                        <Button type="submit" primary onClick={() => {
                            getBetweenDates();
                        }}>{tr("Submit")}</Button>
                    </div>
                </DateSelection>
            </div>
            <div>
                {betweenDates && betweenDates.length > 0 &&
                    <>
                        <ReportRow label={label} data={betweenDates} tableHeaders={betweenDateHeaders} />
                    </>
                }
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </Con>
    );
}


