/* eslint-disable import/no-anonymous-default-export */
/* Created by Hannah Green on 01/22/2021 for ES-58 */

import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

//Components
import Popup from "components/closablePopup";
import Form from "./form";
import DeleteForm from "./deletePopup";

//Icons
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

//Table imports
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import { useTranslation } from 'react-i18next';

const AddEditPopup = styled(Popup)`
  padding: 1rem;
  width: 35%;
  height: 45%;
`;
const DeletePopup = styled(Popup)`
  padding: 1rem;
  width: 35%;
  height: 17%;
`;

export default function (props) {
    const { t } = useTranslation();
    const tBase = "views.category_management.components.allTable";
    const tr = (key) => t(`${tBase}.${key}`);

    const { classes, items, noResults } = props

    //State
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("category_id");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [isSomethingClicked, setIsSomethingClicked] = useState({
        type: null,
        rowCategoryId: null,
        rowName: null,
    });

    //Table Header
    const headCells = [
        { id: "category_name", numeric: false, disablePadding: true, label: tr("Name") },
        { id: "parent_category", numeric: false, disablePadding: true, label: tr("Parent Category" )},
        { id: "active", numeric: false, disablePadding: true, label: tr("Active"), },
        { id: "edit_item", numeric: false, disablePadding: true, label: "" },
        { id: "delete_item", numeric: false, disablePadding: true, label: "" },
    ];

    //Compare items in the cell to order by desc or asc
    const orderDescComparanator = (itm1, itm2, orderBy) => {
        if (itm1[orderBy] < itm2[orderBy]) {
            return -1;
        }
        if (itm1[orderBy] > itm2[orderBy]) {
            return 1;
        }
        return 0;
    };

    //Compare items in the cell and order them by by boolean
    const boolComparanator = (itm1, itm2, orderBy) => {
        if (itm1[orderBy] - itm2[orderBy]) {
            return 1;
        }
        if (!itm1[orderBy] - itm2[orderBy]) {
            return -1;
        }
        return 0;
    };

    //get the comparanator
    const getComparator = (order, orderBy) => {
        if (orderBy === "special_order" || orderBy === "bulk_order") {
            return (itm1, itm2) => boolComparanator(itm1, itm2, orderBy);
        }

        return order === "desc"
            ? (itm1, itm2) => -orderDescComparanator(itm1, itm2, orderBy)
            : (itm1, itm2) => orderDescComparanator(itm1, itm2, orderBy);
    };

    //sort the cells
    const sort = (array, comparanator) => {
        const stabilizeThis = array.map((el, index) => [el, index]);

        stabilizeThis.sort((itm1, itm2) => {
            const order = comparanator(itm1[0], itm2[0]);
            if (order !== 0) {
                return order;
            }
            return itm1[1] - itm2[1];
        });

        return stabilizeThis.map((el) => el[0]);
    };

    //sort request handler
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    //Change page handler
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //change rows handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //The table header
    const EnhancedTableHead = (props) => {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCells.disablePadding ? "none" : "default"}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "desc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

    return (
        <>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={items.length}
                    />
                    {noResults && (
                        <TableBody>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">{tr("No Results")}</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableBody>
                    )}
                    {!noResults && (
                        <TableBody>
                            {sort(items, getComparator(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.category_id}>
                                            <TableCell align="left">{row.category_name}</TableCell>
                                            <TableCell align="left">{row.parent_category}</TableCell>
                                            <TableCell align="left">
                                                {row.active ? <CheckIcon /> : <CloseIcon />}
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    <Tooltip title={tr("Edit")}>
                                                        <IconButton
                                                            aria-label="edit"
                                                            onClick={() =>
                                                                setIsSomethingClicked((state) => {
                                                                    const tempIsSomethingClicked = {
                                                                        ...state,
                                                                    };
                                                                    tempIsSomethingClicked.type =
                                                                        "edit";
                                                                    tempIsSomethingClicked.rowCategoryId =
                                                                        row.category_id;
                                                                    return tempIsSomethingClicked;
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </TableCell>

                                            <TableCell align="left">
                                                <Tooltip title={tr("Delete")}>
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            setIsSomethingClicked((state) => {
                                                                const tempIsSomethingClicked = {
                                                                    ...state,
                                                                };
                                                                tempIsSomethingClicked.type =
                                                                    "delete";
                                                                tempIsSomethingClicked.rowCategoryId =
                                                                    row.category_id;
                                                                tempIsSomethingClicked.rowName =
                                                                    row.category_name;
                                                                return tempIsSomethingClicked;
                                                            });
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={5} />
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {isSomethingClicked.type === "edit" && (
                <AddEditPopup
                    cancelDisplay={() => {
                        setIsSomethingClicked((state) => {
                            const tempIsSomethingClicked = { ...state };
                            tempIsSomethingClicked.type = null;
                            tempIsSomethingClicked.rowCategoryId = null;
                            return tempIsSomethingClicked;
                        });
                    }}
                >
                    <Form formType="edit" categoryId={isSomethingClicked.rowCategoryId} closePopup={() => {
                        setIsSomethingClicked((state) => {
                            const tempIsSomethingClicked = { ...state };
                            tempIsSomethingClicked.type = null;
                            tempIsSomethingClicked.rowCategoryId = null;
                            return tempIsSomethingClicked;
                        });
                    }} />
                </AddEditPopup>
            )}
            {isSomethingClicked.type === "delete" && (
                <DeletePopup
                    cancelDisplay={() => {
                        setIsSomethingClicked((state) => {
                            const tempIsSomethingClicked = { ...state };
                            tempIsSomethingClicked.type = null;
                            tempIsSomethingClicked.rowCategoryId = null;
                            tempIsSomethingClicked.rowName = null;
                            return tempIsSomethingClicked;
                        });
                    }}
                >
                    <DeleteForm category_id={isSomethingClicked.rowCategoryId} category_name={isSomethingClicked.rowName} close_popup={() => {
                        setIsSomethingClicked((state) => {
                            const tempIsSomethingClicked = { ...state };
                            tempIsSomethingClicked.type = null;
                            tempIsSomethingClicked.rowCategoryId = null;
                            tempIsSomethingClicked.rowName = null;
                            return tempIsSomethingClicked;
                        });
                    }} />
                </DeletePopup>
            )}
        </>
    )
}
