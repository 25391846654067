import React from "react";
import { forEach } from "lodash";

import Tag from "./Tag";
import { TTag } from "types/tag";
import { useTranslation } from 'react-i18next';

import styles from "./TagManager.module.scss";

type Props = {
  customerId: number | string;
  addedTags: any;
  availableTags: any;
  handleUpdateTag: (
    customerId: number | string,
    tagId: number | string,
    type: string
  ) => void;
};

const TagManager: React.FC<Props> = ({
  customerId,
  addedTags,
  availableTags,
  handleUpdateTag,
}) => {
  const { t } = useTranslation();
  const tBase = "views.customers.new-customer.TagManager";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <h4>{tr("Added")}</h4>
        <div className={styles.box}>
          {addedTags &&
            addedTags?.map((tag: any) => {
              return (
                <Tag
                  tag={tag}
                  customerId={customerId}
                  type="remove"
                  key={tag.id}
                  handleUpdateTag={handleUpdateTag}
                />
              );
            })}
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.column}>
        <h4>{tr("Available")}</h4>
        <div className={styles.box}>
          {availableTags &&
            availableTags
              .filter((t: any) => {
                let value = true;
                addedTags.forEach((tag: any) => {
                  if (tag.id == t.id) {
                    value = false;
                  }
                });
                return value;
              })
              ?.map((tag: any) => {
                return (
                  <Tag
                    tag={tag}
                    customerId={customerId}
                    type="add"
                    key={tag.id}
                    handleUpdateTag={handleUpdateTag}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default TagManager;
