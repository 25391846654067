import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import { Asterick, Error, StyledLabel } from "components/input/style";
import { Input } from "components/input";
import styles from "./styles";
import { VendorContext } from "contexts/VendorContext";
import { useAuthContext } from "contexts/auth";
import { useTranslation } from 'react-i18next';

const Option = ({ option, clickedAddress, setIsStandardizedAddress, setSelectedOption }: any) => {
	const [hovered, setHovered] = React.useState(false);

	return (
		<div
			style={{ ...styles.option, backgroundColor: hovered ? "#a6a6a6" : "" }}
			onClick={() => { setSelectedOption(option); setIsStandardizedAddress(true); clickedAddress(option, true) }}
			onMouseEnter={(e) => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{option.description}
		</div>
	);
};


const AddressInput = ({address, setAddress, isStandardizedAddress, setIsStandardizedAddress, selectedOption, setSelectedOption, clickedAddress, vendorUpdater, vendor, className, addressObj, setAddressObj, options}: any) => {
	const { t } = useTranslation();
	const tBase = "components.standardizedAddressInput";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const [showOptions, setShowOptions] = useState(false)

	const handleInputChange = (e: any) => {
		setAddress(e.target.value)
		setAddressObj({...addressObj, address: e.target.value, isStandardizedAddress: false})

		setSelectedOption({
			...selectedOption,
			description: "",
		})
	};

	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			setAddress(e.target.value)
			if (vendor)
				vendorUpdater(e)
			setIsStandardizedAddress(false);
			setSelectedOption({
				...selectedOption,
				description: e.target.value,
			})

			return;
		}
	}

	const handleInputFocus = () => {
		setShowOptions(true)
	}

	const handleInputBlur = () => {
		setTimeout(() => {
			setShowOptions(false)
		}, 200)
	}

	return (
		<div style={{width:"100%"}}>
			{vendor ? 
				<input
					type="text"
					onChange={(e)=>handleInputChange(e)}
					onKeyDown={(e)=>handleKeyPress(e)}
					value={address}
					className={className}
					style={{width:"450px"}}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
				/>
			:
				<Input
					type="text"
					onChange={(e)=>handleInputChange(e)}
					onKeyDown={(e)=>handleKeyPress(e)}
					value={address}
					className={className}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
				/>
			}
			{showOptions && address.length > 0 && selectedOption?.description === "" && (
				<div style={styles.optionBox}>
					{
						options.map ?
							options.map((option: any, index: number) => 
								<Option key={index} {...{option, clickedAddress, setIsStandardizedAddress, setSelectedOption}} />
							)
						: <div style={{textAlign:"center"}}>{tr("No Suggestions Found")}</div>
					}
				</div>
			)}
		</div>
	);
};


const StandardizedAddressInput = (props:any) => {
	const { t } = useTranslation();
	const tBase = "components.standardizedAddressInput";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const { updateMode, currentVendor } = useContext(VendorContext)
	const [address, setAddress] = useState(props.value || props.formik?.values.address || "");
	const [suggestions, setSuggestions] = useState<any[]>([]);
	const [isStandardizedAddress, setIsStandardizedAddress] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] = React.useState(
		{
			description: props.formik?.values.address || "",
			city: props.formik?.values.city || "",
			state: props.formik?.values.state || "",
			zipcode: props.formik?.values.zipcode || "",
		}
	)
	const { vendorUpdater, vendor, className, addressObj, setAddressObj, options } = props

    useEffect(() => {
        setAddress(props.value || props.formik?.values.address || "")
    }, [props.value]);

	const auth = useAuthContext()
	const longitude = auth.userLongitude
	const latitude = auth.userLatitude

    useEffect(() => {
		getAddressSuggestions()
    }, [address, isStandardizedAddress]);
	
	const clickedAddress = (option:any, isStandardizedAddress:any) => {
		let index = option.description.indexOf(',')
		setAddress(option.description.substring(0, index))

		option = {...option, description: option.description.substring(0, index)}

		let selectedOption = {description: '', city: '', state: '', zipcode: ''}
		selectedOption = {...selectedOption, ...option}

		props.setAddressObj({address: option.description, isStandardizedAddress: isStandardizedAddress, selectedOption: selectedOption})
	}

	const cachedSuggestionsRef = useRef<any[]>([])
	const cancelTokenSource = useRef<any>(axios.CancelToken.source())

	const getAddressSuggestions = async () => {

		// cancels an existing request if the user types in a new input before previous request being resolved
		if (cancelTokenSource.current) {
			cancelTokenSource.current.cancel(`Request cancelled for ${address}`)
		}

		cancelTokenSource.current = axios.CancelToken.source()

		// basic caching of address suggestions based on input string
		if (cachedSuggestionsRef.current.some(suggestion => suggestion[address])) {
			const cachedSuggestion = cachedSuggestionsRef.current.find(suggestion => suggestion[address])
			setSuggestions(cachedSuggestion[address])
			return
		}
		
		try {
			await axios.get(
				`
					${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers
				`,
				{
					params: {
						type: "autoCompleteAddress",
						input: address,
						longitude: longitude,
						latitude: latitude
					},
					cancelToken: cancelTokenSource.current.token
				}
			).then(res => {
				cachedSuggestionsRef.current.push({[address]: res.data})
				setSuggestions(res.data)
			})
		}
		catch (error) {
			if (axios.isCancel(error)) {
				// console.log(`Request cancelled for ${address}`)
			}
			else {
				// console.log(error)
			}
		}
	}

    return (
		<Container style={{ ...props.style, position: "relative" }}>
			{props.label && (
				<StyledLabel>
					{props.label}
					{props.required && <Asterick className="text-[0.6rem] ml-1">{tr("Required")}</Asterick>}
				</StyledLabel>
			)}
			<AddressInput options={suggestions} {...{address, setAddress, isStandardizedAddress, setIsStandardizedAddress, selectedOption, setSelectedOption, clickedAddress, vendor, vendorUpdater, className, addressObj, setAddressObj}} />
		</Container>
    );
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export default StandardizedAddressInput;