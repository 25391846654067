import Checkbox from "@mui/material/Checkbox";
import Switch from "rc-switch";
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import styles from "./HomeBlock.module.scss";

function HomeBlock({ home, optimizeState, optimizeDispatch }) {
  const { t } = useTranslation();
  const tBase = "views.appointments.MapComponents.HomeBlock";
  const tr = (key) => t(`${tBase}.${key}`);

  const { address } = home;

  return (
    <div
      className={styles.appointmentRow}
      style={{
        borderLeftColor: "lightgray",
        borderLeftWidth: 15,
      }}
    >
      <div className={styles.countColumn}>
        <div className={styles.orderContainer}>
          {_.find(optimizeState, { address: address }) &&
          _.find(optimizeState, { address: address }).order ? (
            <div className={styles.order}>
              {_.find(optimizeState, { address: address })?.isStart &&
              _.find(optimizeState, { address: address })?.isEnd
                ? `1,${_.find(optimizeState, { address: address })?.order}`
                : _.find(optimizeState, { address: address })?.order}
            </div>
          ) : null}
        </div>
        <div
          onClick={() => {
            if (_.findIndex(optimizeState, { address: address }) === -1) {
              optimizeDispatch({
                type: "ADD",
                payload: {
                  address,
                  center: [home.location.longitude, home.location.latitude],
                },
              });
            } else {
              optimizeDispatch({ type: "REMOVE", payload: { address } });
            }
          }}
          className={styles.countRow}
        >
          <Checkbox
            checked={_.findIndex(optimizeState, { address: address }) > -1}
          />
        </div>
        <div className={styles.timeSwitchGroup}>
          <div className={styles.timeSwitchRow}>
            <Switch
              checked={
                _.find(optimizeState, { address: address }) &&
                _.find(optimizeState, { address: address }).isStart
              }
              onChange={() => {
                if (
                  _.find(optimizeState, { address: address }) &&
                  _.find(optimizeState, { address: address }).isStart
                ) {
                  optimizeDispatch({
                    type: "REMOVE_START",
                    payload: { address },
                  });
                } else {
                  optimizeDispatch({
                    type: "ADD_START",
                    payload: {
                      address,
                      center: [home.location.longitude, home.location.latitude],
                    },
                  });
                }
              }}
            />
            <div className={styles.timeSwitchText}>{tr("Start")}</div>
          </div>
          <div className={styles.timeSwitchRow}>
            <Switch
              checked={
                _.find(optimizeState, { address: address }) &&
                _.find(optimizeState, { address: address }).isEnd
              }
              onChange={() => {
                if (
                  _.find(optimizeState, { address: address }) &&
                  _.find(optimizeState, { address: address }).isEnd
                ) {
                  optimizeDispatch({
                    type: "REMOVE_END",
                  });
                } else {
                  optimizeDispatch({
                    type: "ADD_END",
                    payload: {
                      address,
                      center: [home.location.longitude, home.location.latitude],
                    },
                  });
                }
              }}
            />
            <div className={styles.timeSwitchText}>{tr("End")}</div>
          </div>
        </div>
      </div>

      <div className={styles.detailColumn}>
        <div className={styles.detailRow}>
          <div className={styles.openButtonContainer}></div>
          <div className={styles.column}>
            {address ? (
              <div className={styles.address}>{address}</div>
            ) : (
              <div className={styles.address}>{tr("No Address")}</div>
            )}
          </div>
        </div>
        <div className={styles.newStartRow}>
          <div className={styles.buttonGroupRow}></div>
        </div>
      </div>
    </div>
  );
}

export default HomeBlock;
