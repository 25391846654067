import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import "./index.css";
import axios from "axios";
import { useTranslation } from 'react-i18next';

function InventoryReports() {
  const { t } = useTranslation();
  const tBase = "views.warehouse.reports.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [selected, setSelected] = useState({});
  const [options, setOptions] = useState([]);
  const [items, setItems] = useState([]);

  const fetchVehicles = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/warehouses_vehicles`
    );

    setOptions(response.data);
  };

  const fetchStockLevel = async (warehouseId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/warehouse_level/${warehouseId}`
    );

    setItems(response.data);
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  useEffect(() => {
    if (selected && selected.hasOwnProperty("warehouse_id")) {
      fetchStockLevel(selected.warehouse_id);
    }
  }, [selected]);

  return (
    <div style={{ padding: 5 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "20%" }}>
          <div style={{ marginRight: 10 }}>
            <Select
              placeholder={tr("Choose warehouse")}
              onChange={(e) => {
                setSelected(e);
              }}
              value={selected}
              isClearable={true}
              options={options}
              getOptionLabel={(option) => option.warehouse_name}
              getOptionValue={(option) => option.warehouse_id}
            />
          </div>

          <div style={{ marginTop: 10 }}>
            {selected && selected.hasOwnProperty("warehouse_name") ? (
              <ReactHTMLTableToExcel
                id="table-xls-button"
                className=""
                table="table-to-xls"
                filename={selected.warehouse_name}
                sheet={selected.warehouse_name + "Levels"}
                buttonText="Download as XLS"
              />
            ) : null}
          </div>
        </div>
        <table id="table-to-xls" style={{ width: "80%" }}>
          <tr
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 14,
              minHeight: 35,
              textAlign: "center",
            }}
            className="row"
          >
            <td style={{ width: "10%" }}>{tr("Part")}</td>
            <td style={{ width: "25%" }}>{tr("Category")}</td>
            <td style={{ width: "25%" }}>{tr("Group")}</td>
            <td style={{ width: "25%" }}>{tr("Name")}</td>
            <td style={{ width: "10%" }}>{tr("Quantity")}</td>
          </tr>
          {items.map((item, index) => {
            return (
              <tr
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 14,
                  minHeight: 35,
                }}
                className="row"
              >
                <td style={{ width: "10%", textAlign: "center" }}>
                  {item.internal_id}
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  {item.parent_category}
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  {item.category_name}
                </td>
                <td style={{ width: "25%", textAlign: "center" }}>
                  {item.item_name}
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>
                  {item.quantity}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
}

export default InventoryReports;
