/* Created by Hannah Green for ES-169 on 6/11/2021 */
import React from "react";
import { GoAlert } from "react-icons/go";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { useModalContext } from "components/modal";
import InspectionDetails from "./inspection-details";
import {
	RepairInfo,
	UserIcon,
	UserInfo,
	Middle,
} from "../../repair_request/components/requests/card-style";
import { Con, Description, Icons } from "./styles";
import { useTranslation } from 'react-i18next';

const formatDate = (date) => {
	const oldDate = new Date(date);
	return oldDate.toLocaleDateString();
};

export default function InspectionRow(props) {
	const { t } = useTranslation();
	const tBase = "views.vehicle_inspection.dashboard.inspection-row";
	const tr = (key) => t(`${tBase}.${key}`);

	const row = props.rows;
	const name = row.tech_name;
	const { setModal } = useModalContext();

	return (
		<Con>
			<div
				onClick={() => {
					setModal({
						component: <InspectionDetails inspection={row} />,
						label: tr("Details"),
					});
				}}
			>
				<RepairInfo>
					<UserIcon>
						<Avatar
							alt={name}
							src="/broken-image.jpg"
							style={{
								width: "40px",
								height: "40px",
								backgroundColor: "#1f618d",
							}}
						>
							{name.match(/\b(\w)/g).join("")}
						</Avatar>
					</UserIcon>
					<UserInfo>
						<div>{name}</div>
						<div>
							<div>{formatDate(row.inspection_date)}</div>
							<div>{row.vehicle_number}</div>
						</div>
					</UserInfo>

					<Middle>
						{row.description && (
							<Description>
								<label>{tr("Description")}</label>
								<div>{row.description}</div>
							</Description>
						)}

						{row.comments && (
							<Description>
								<label>{tr("Comments")}</label>
								<div>{row.comments}</div>
							</Description>
						)}

						{row.notes && (
							<Description>
								<label>{tr("Maintenance Notes")}</label>
								<div>{row.notes}</div>
							</Description>
						)}
					</Middle>
					{row.needs_checked && (
						<Icons>
							<div>
								<Tooltip
									title={tr("Vehicle Needs Checked")}
									placement="top"
								>
									<GoAlert color="Red" size={25} />
								</Tooltip>
							</div>
						</Icons>
					)}
				</RepairInfo>
			</div>
		</Con>
	);
}
