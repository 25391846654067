/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from 'react-i18next';

import { BiAddToQueue, BiSend, BiSave } from "react-icons/bi";
import {
	BsInfoCircle,
	BsTrash,
	BsPlusSquare,
	BsDashSquare,
	BsSearch,
} from "react-icons/bs";
import { FiTruck } from "react-icons/fi";

import axios from "axios";

import "./index.css";
import Reports from "./reports/index";
import ManageWarehouses from "./manage/index.js";

const WhRow = (props) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(false);
	}, [props.order]);

	const handleChange = () => {
		if (checked) {
			setChecked(false);
			props.removeCheck(props.item_id);
		} else {
			setChecked(true);
			props.addCheck(props.item_id);
		}
	};

	return (
		<TableRow className="table-row" key={props.item_id}>
			<TableCell className="cell" align="center">
				<span className="checkbox">
					<Checkbox
						checked={checked}
						color="primary"
						inputProps={{ "aria-label": "secondary checkbox" }}
						onChange={handleChange}
					/>
				</span>
			</TableCell>
			<TableCell className="cell" align="center">
				<span className="checkbox">
					{props.outstanding && props.outstanding > 0 ? (
						<div>
							<div>
								<FiTruck size={20} color={"gray"} />
							</div>
							<div style={{ fontSize: 10 }}>
								{props.outstanding}
							</div>
						</div>
					) : null}
				</span>
			</TableCell>

			<TableCell className="cell" align="center">
				<div className="cell-text">{props.model}</div>
			</TableCell>
			<TableCell className="cell" align="center">
				<div className="cell-text">{props.parent_category_name}</div>
			</TableCell>
			<TableCell className="cell" align="center">
				<div className="cell-text">{props.category_name}</div>
			</TableCell>
			<TableCell className="cell" align="center">
				<div className="cell-text">{props.item_name}</div>
			</TableCell>
			<TableCell
				className="cell"
				style={
					props.quantity < props.min
						? { color: "red" }
						: props.quantity > props.max
						? { color: "blue" }
						: { color: "black" }
				}
				align="center"
			>
				<div className="cell-text">{props.quantity}</div>
			</TableCell>
			<TableCell className="cell" align="center">
				<div className="cell-text">{props.min}</div>
			</TableCell>
			<TableCell className="cell" align="center">
				<div className="cell-text">{props.max}</div>
			</TableCell>
		</TableRow>
	);
};

const WarehouseInfo = (props) => {
	const { t } = useTranslation();
	const tBase = "views.warehouse.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [warehouse, setWarehouse] = useState({});
	const [params, setParams] = useState("");
	const [paramsList, setParamsList] = useState(["All", "Below Minimum"]);
	const [whInfo, setWhInfo] = useState([]);
	const [filteredWhInfo, setFilteredWhInfo] = useState(whInfo);
	const [filter, setFilter] = useState("");

	const [checkList, setCheckList] = useState([]);

	const [dialogItem, setDialogItem] = useState("");
	const [outstandingCount, setOutstandingCount] = useState("");
	const [orders, setOrders] = useState([]);
	const [open, setOpen] = React.useState(false);

	const addCheck = async (item) => {
		setDialogItem(item);
		setCheckList((prev) => [...prev, parseInt(item)]);

		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/check_ordered_item`,
			data: {
				item,
			},
		});

		setOutstandingCount(response.data.sum);
		setOrders(response.data.orders);

		if (response.data.sum > 0) {
			handleOpen();
		}
	};

	const removeCheck = (item) => {
		setCheckList(checkList.filter((prev) => prev !== parseInt(item)));
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const getWarehouseInfo = async (warehouse_id) => {
		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/warehouse_info`,
			data: {
				warehouse_id,
				params: params,
			},
		});

		setWhInfo(response.data);
	};

	const filterInfo = () => {
		const filtered = whInfo.filter((row) => {
			return (
				(row.item_name &&
					row.item_name
						.toString()
						.toLowerCase()
						.includes(filter.toLowerCase())) ||
				(row.parent_category_name &&
					row.parent_category_name
						.toString()
						.toLowerCase()
						.includes(filter.toLowerCase())) ||
				(row.category_name &&
					row.category_name
						.toString()
						.toLowerCase()
						.includes(filter.toLowerCase())) ||
				(row.model &&
					row.model
						.toString()
						.toLowerCase()
						.includes(filter.toLowerCase()))
			);
		});

		setFilteredWhInfo(filtered);
	};

	useEffect(() => {
		filterInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, whInfo]);

	useEffect(() => {
		if (warehouse) {
			getWarehouseInfo(warehouse.warehouse_id);
		} else {
			setWarehouse(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse, params]);

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="simple-dialog-title"
				open={open}
			>
				<DialogTitle id="simple-dialog-title">
					{tr("Item Present on Order")}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{outstandingCount ? (
							<>
								<div>{tr("Outstanding quantity")}:</div>
								<div>
									<strong>{outstandingCount}</strong>
								</div>
							</>
						) : null}
						<div style={{ marginTop: 10 }}>tr("From orders"):</div>
						<div>
							{orders &&
								orders[0]?.map((order) => {
									return (
										<div>
											<strong>#{order.id}</strong>
										</div>
									);
								})}
						</div>
						<div className="btn-row">
							<div className="btn">
								<Button
									onClick={() => {
										handleClose();
									}}
									variant="contained"
								>
									OK
								</Button>
							</div>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<div className="wh-top-row">
				<h3 className="detail-header">{tr("Warehouse")}</h3>
			</div>
			<div className="wh-selectors">
				<Autocomplete
					id="detail-wh"
					className="wh-select"
					options={props.warehouses}
					getOptionLabel={(option) => option.warehouse_name || ""}
					renderInput={(params) => (
						<TextField
							{...params}
							label={tr("Warehouse")}
							variant="outlined"
						/>
					)}
					value={warehouse}
					onChange={(event, newValue) => {
						setWarehouse(newValue);
					}}
				/>
				<Autocomplete
					id="wh-params"
					className="wh-select"
					options={paramsList}
					renderInput={(params) => (
						<TextField
							{...params}
							label={tr("Parameters")}
							variant="outlined"
						/>
					)}
					value={params}
					onChange={(event, newValue) => {
						setParams(newValue);
					}}
				/>
			</div>
			<div className="wh-filter-row">
				<TextField
					id="outlined-basic"
					className="wh-filter"
					label={tr("Filter")}
					variant="outlined"
					InputProps={{
						startAdornment: (
							<BsSearch
								style={{ marginRight: 10 }}
								position="start"
							/>
						),
					}}
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
				/>
			</div>

			<TableContainer className="wh-table" component={Paper}>
				<Table stickyHeader size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Part")}</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Model")}</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">
									{tr("Category")}
								</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Group")}</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Item")}</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">
									{tr("Quantity")}
								</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Minimum")}</div>
							</TableCell>
							<TableCell align="center">
								<div className="table-header-text">{tr("Maximum")}</div>
							</TableCell>
						</TableRow>
					</TableHead>

					{filteredWhInfo?.map((row) => {
						return (
							<>
								{warehouse ? (
									<TableBody>
										<WhRow
											addToOrder={props.addToOrder}
											order={props.order}
											getOrder={props.getOrder}
											key={row.item_id}
											checkList={checkList}
											addCheck={addCheck}
											removeCheck={removeCheck}
											getWarehouseInfo={getWarehouseInfo}
											warehouse_id={
												warehouse.warehouse_id
											}
											item_id={row.item_id}
											model={row.model}
											parent_category_name={
												row.parent_category_name
											}
											category_name={row.category_name}
											item_name={row.item_name}
											quantity={row.quantity}
											min={row.min}
											max={row.max}
											outstanding={row.outstanding}
											updateDetails={props.updateDetails}
										/>
									</TableBody>
								) : null}
							</>
						);
					})}
				</Table>
			</TableContainer>

			<div className="wh-bottom-row">
				<Button
					onClick={() => {
						props.addToOrder(checkList);
						setCheckList([]);
					}}
					color="primary"
					variant="contained"
				>
					{tr("Add to Order")}
				</Button>
			</div>
		</div>
	);
};

const DetailBox = (props) => {
	const { t } = useTranslation();
	const tBase = "views.warehouse.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [detailWarehouse, setDetailWarehouse] = useState(null);
	const [warehouse, setWarehouse] = useState(null);

	const [newQty, setNewQty] = useState("");
	const [newMin, setNewMin] = useState("");
	const [newMax, setNewMax] = useState("");

	const updateWh = async (item_id, warehouse_id) => {
		setWarehouse(await props.getDetails(item_id, warehouse_id));
		// setNewQty("");
		// setNewMin("");
		// setNewMax("");
	};

	useEffect(() => {
		if (detailWarehouse) {
			updateWh(props.item_id, detailWarehouse.warehouse_id);
		} else {
			setWarehouse(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailWarehouse, props.completeTransfer]);

	const updateStock = async () => {
		if (newQty.length > 0) {
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/update_stock`,
				data: {
					item_id: props.item_id,
					warehouse_id: detailWarehouse.warehouse_id,
					stock_level: newQty,
				},
			});

			updateWh(props.item_id, detailWarehouse.warehouse_id);
			setNewQty("");
		}
	};

	const updateMin = async () => {
		if (newMin.length > 0) {
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/update_min`,
				data: {
					item_id: props.item_id,
					warehouse_id: detailWarehouse.warehouse_id,
					min: newMin,
				},
			});

			updateWh(props.item_id, detailWarehouse.warehouse_id);
			setNewMin("");
		}
	};

	const updateMax = async () => {
		if (newMax.length > 0) {
			await axios({
				method: "post",
				url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/update_max`,
				data: {
					item_id: props.item_id,
					warehouse_id: detailWarehouse.warehouse_id,
					max: newMax,
				},
			});

			updateWh(props.item_id, detailWarehouse.warehouse_id);
			setNewMax("");
		}
	};

	return (
		<div className="detail-box">
			{props.parent_category_name ? (
				<>
					<div className="detail-top-row">
						<div className="detail-stack">
							<h3 className="detail-header">{tr("Item Details")}</h3>
							<h4 className="detail-category">
								{props.parent_category_name}
								{" -> "}
								{props.category_name}
							</h4>
							<h3 className="detail-header">{props.item_name}</h3>
						</div>
					</div>
					<div>
						<Autocomplete
							id="detail-wh"
							className="detail-select"
							options={props.warehouses}
							getOptionLabel={(option) =>
								option.warehouse_name || ""
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label={tr("Warehouse")}
									variant="outlined"
								/>
							)}
							value={detailWarehouse}
							onChange={(event, newValue) => {
								setDetailWarehouse(newValue);
							}}
						/>
					</div>

					{warehouse ? (
						<div>
							<div className="detail-group">
								<div className="detail-label">
									Current Stock:{" "}
								</div>
								<div className="detail-quantity">
									{warehouse.quantity}
								</div>
								<TextField
									id="outlined-basic"
									className="new-value"
									label={tr("Quantity")}
									variant="outlined"
									value={newQty}
									onChange={(e) => setNewQty(e.target.value)}
								/>
								<BiSave
									onClick={() => {
										updateStock();
									}}
									className="save-icon"
								/>
							</div>

							<div className="detail-group">
								<div className="detail-label">
									{tr("Minimum Level")}:{" "}
								</div>
								<div className="detail-quantity">
									{warehouse.min}
								</div>
								<TextField
									id="outlined-basic"
									className="new-value"
									label={tr("Minimum")}
									variant="outlined"
									value={newMin}
									onChange={(e) => setNewMin(e.target.value)}
								/>
								<BiSave
									onClick={() => updateMin()}
									className="save-icon"
								/>
							</div>

							<div className="detail-group">
								<div className="detail-label">
									{tr("Maximum Level")}:{" "}
								</div>
								<div className="detail-quantity">
									{warehouse.max}
								</div>
								<TextField
									id="outlined-basic"
									className="new-value"
									label={tr("Maximum")}
									variant="outlined"
									value={newMax}
									onChange={(e) => setNewMax(e.target.value)}
								/>
								<BiSave
									onClick={() => updateMax()}
									className="save-icon"
								/>
							</div>
						</div>
					) : null}
				</>
			) : (
				<div className="detail-top-row">
					<div className="detail-stack">
						<h3 className="detail-header">{tr("Item Details")}</h3>
					</div>
				</div>
			)}
		</div>
	);
};

const Index = () => {
	const { t } = useTranslation();
	const tBase = "views.warehouse.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);

	const [xferItems, setXferItems] = useState([]);
	const [detailItem, setDetailItem] = useState([]);

	const [modelInput, setModelInput] = useState("");
	const [pcInput, setPcInput] = useState("");
	const [cInput, setCInput] = useState("");
	const [partNameInput, setPartNameInput] = useState("");

	const [warehouses, setWarehouses] = useState([]);
	const [fromWh, setFromWh] = useState("");
	const [toWh, setToWh] = useState("");

	const [order, setOrder] = useState([]);

	const [tabValue, setTabValue] = React.useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const getOrder = async () => {
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/order`,
		}).then((res) => {
			setOrder(res.data[0]);
		});
	};

	const addToOrder = async (checkList) => {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/add_to_order`,
			data: checkList,
		});

		getOrder();
	};

	const getItems = async () => {
		const itemsResult = await axios.get(
			`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level`
		);

		setItems(itemsResult.data);
	};

	const getTransfers = async () => {
		const transferResponse = await axios({
			method: "get",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/transfer`,
		});

		setXferItems(transferResponse.data);
	};

	const getWarehouses = async () => {
		const warehouseResponse = await axios({
			method: "get",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/warehouses`,
		});

		setWarehouses(warehouseResponse.data);
	};

	const updatePart = async (id, quantity) => {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/updatePart`,
			data: {
				id,
				quantity,
			},
		});
	};

	const removePart = async (id) => {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/removePart`,
			data: {
				id,
			},
		});

		getTransfers();
	};

	const filterItems = () => {
		const filtered = items.filter((item) => {
			return (
				item.model &&
				item.model.toLowerCase().includes(modelInput.toLowerCase()) &&
				item.parent_category_name &&
				item.parent_category_name
					.toLowerCase()
					.includes(pcInput.toLowerCase()) &&
				item.category_name &&
				item.category_name
					.toString()
					.toLowerCase()
					.includes(cInput.toLowerCase()) &&
				item.item_name &&
				item.item_name
					.toString()
					.toLowerCase()
					.includes(partNameInput.toLowerCase())
			);
		});

		setFilteredItems(filtered);
	};

	const completeTransfer = () => {
		setXferItems([]);

		axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/complete`,
			data: { fromWh, toWh },
		});
	};

	const getDetails = async (item_id, warehouse_id) => {
		const response = await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/detail`,
			data: {
				item_id,
				warehouse_id,
			},
		});

		return response.data[0];
	};

	const updateDetails = async (
		item_id,
		warehouse_id,
		newQty,
		newMin,
		newMax
	) => {
		await axios({
			method: "post",
			url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/set_detail`,
			data: {
				item_id,
				warehouse_id,
				newQty,
				newMin,
				newMax,
			},
		});
	};

	useEffect(() => {
		getItems();
		getTransfers();
		getWarehouses();
		getOrder();
	}, []);

	useEffect(() => {
		filterItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		filterItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partNameInput, modelInput, pcInput, cInput]);

	const ItemTile = (props) => {
		const [quantity, setQuantity] = useState(props.quantity);

		const increment = () => {
			updateQuantity(quantity + 1);
		};

		const decrement = () => {
			updateQuantity(quantity - 1);
		};

		const updateQuantity = (value) => {
			if (value && Number.isInteger(parseInt(value)) && value >= 0) {
				setQuantity(parseInt(value));
				updatePart(props.id, parseInt(value));
			} else {
				setQuantity(0);
				updatePart(props.id, 0);
			}
		};

		return (
			<Paper>
				<div className="item-row">
					<BsTrash
						className="trash-icon"
						onClick={() => removePart(props.id)}
					/>

					<div className="item-stack">
						<div className="categories">
							{props.parent_category_name}
							{" -> "}
							{props.category_name}
						</div>
						<div className="item-name">{props.item_name}</div>
						<div className="part-model">{props.model}</div>
					</div>
					<div className="qty-row">
						<BsPlusSquare
							className="plus-icon"
							onClick={() => increment()}
						/>
						<input
							className="qty-input"
							value={quantity}
							onChange={(e) => {
								updateQuantity(e.target.value);
							}}
							onBlur={getTransfers}
						/>
						<BsDashSquare
							className="minus-icon"
							onClick={() => decrement()}
						/>
					</div>
				</div>
			</Paper>
		);
	};

	return (
		<div className="wh-page">
			<Paper style={{ backgroundColor: "#eee" }}>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					centered
				>
					<Tab label={tr("Transfer")} />
					<Tab label={tr("Manage")} />
					<Tab label={tr("Reports")} />
				</Tabs>
			</Paper>
			{tabValue === 0 ? (
				<div className="wh-container">
					<div className="left-column">
						<h3 className="box-header">{tr("Filters")}</h3>
						<Autocomplete
							id="pcName"
							style={{ marginTop: 10 }}
							className="picker"
							value={pcInput}
							onChange={(event, newInputValue) => {
								setPcInput(newInputValue || "");
							}}
							inputValue={pcInput}
							onInputChange={(event, newInputValue) => {
								setPcInput(newInputValue || "");
							}}
							options={[
								...new Set(
									filteredItems.map((item) => {
										return item.parent_category_name;
									})
								),
							]}
							renderInput={(params) => (
								<TextField
									{...params}
									label={tr("Category")}
									variant="outlined"
								/>
							)}
						/>

						<Autocomplete
							id="group"
							className="picker"
							value={cInput}
							onChange={(event, newInputValue) => {
								setCInput(newInputValue || "");
							}}
							inputValue={cInput}
							onInputChange={(event, newInputValue) => {
								setCInput(newInputValue || "");
							}}
							options={[
								...new Set(
									filteredItems.map((item) => {
										return item.category_name;
									})
								),
							]}
							renderInput={(params) => (
								<TextField
									{...params}
									label={tr("Group")}
									variant="outlined"
								/>
							)}
						/>

						<Autocomplete
							id="item"
							className="picker"
							value={partNameInput}
							onChange={(event, newInputValue) => {
								setPartNameInput(newInputValue || "");
							}}
							inputValue={partNameInput}
							onInputChange={(event, newInputValue) => {
								setPartNameInput(newInputValue || "");
							}}
							options={[
								...new Set(
									filteredItems.map((item) => {
										return item.item_name;
									})
								),
							]}
							renderInput={(params) => (
								<TextField
									{...params}
									label={tr("Part Name")}
									variant="outlined"
								/>
							)}
						/>

						<Autocomplete
							className="picker"
							value={modelInput}
							onChange={(event, newInputValue) => {
								setModelInput(newInputValue || "");
							}}
							inputValue={modelInput}
							onInputChange={(event, newInputValue) => {
								setModelInput(newInputValue || "");
							}}
							id="model"
							options={[
								...new Set(
									filteredItems.map((item) => {
										return item.model;
									})
								),
							]}
							renderInput={(params) => (
								<TextField
									{...params}
									label={tr("Model")}
									variant="outlined"
								/>
							)}
						/>
					</div>
					<div className="list-container">
						<h3 className="box-header">{tr("Inventory")}</h3>
						<div className="item-list">
							<div className="">
								{filteredItems.map((item, index) => {
									return (
										<Paper key={index}>
											<div className="item-row">
												<div className="part-details">
													<div className="item-stack">
														<div className="categories">
															{
																item.parent_category_name
															}
															{" -> "}
															{item.category_name}
														</div>
														<div className="item-name">
															{item.item_name}
														</div>
														<div className="part-model">
															{item.model}
														</div>
													</div>
												</div>
												<div className="list-buttons">
													<BsInfoCircle
														onClick={() => {
															setDetailItem({
																item_name:
																	item.item_name,
																parent_category_name:
																	item.parent_category_name,
																category_name:
																	item.category_name,
																model: item.model,
																item_id:
																	item.item_id,
															});
														}}
														size={34}
														className="info"
													/>
													<BiAddToQueue
														onClick={() => {
															axios
																.post(
																	`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/stock_level/setPart`,
																	{
																		item_id:
																			item.item_id,
																		parent_category_name:
																			item.parent_category_name,
																		category_name:
																			item.category_name,
																		item_name:
																			item.item_name,
																		model: item.model,
																	}
																)
																.then(
																	(
																		response
																	) => {
																		getTransfers();
																	}
																);
														}}
														size={38}
														className="arrow"
													/>
												</div>
											</div>
										</Paper>
									);
								})}
							</div>
						</div>
					</div>

					<div className="added-box">
						<h3 className="box-header">{tr("Transfer")}</h3>
						<div className="transfer-list">
							{xferItems.map((item, index) => {
								return (
									<ItemTile
										key={index}
										id={item.id}
										item_id={item.item_id}
										item_name={item.item_name}
										model={item.model}
										category_name={item.category_name}
										parent_category_name={
											item.parent_category_name
										}
										quantity={item.quantity}
									/>
								);
							})}
						</div>
						<div className="xfer-btn-row">
							<div>
								<Autocomplete
									id="from-wh"
									className="from-wh"
									options={warehouses}
									getOptionLabel={(option) =>
										option.warehouse_name || ""
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label={tr("From...")}
											variant="outlined"
										/>
									)}
									value={fromWh}
									onChange={(event, newValue) => {
										setFromWh(newValue);
									}}
								/>
							</div>
							<div>
								<Autocomplete
									id="to-wh"
									className="to-wh"
									options={warehouses}
									getOptionLabel={(option) =>
										option.warehouse_name || ""
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label={tr("To...")}
											variant="outlined"
										/>
									)}
									value={toWh}
									onChange={(event, newValue) => {
										setToWh(newValue);
									}}
								/>
							</div>
							<div>
								<BiSend
									onClick={() => completeTransfer()}
									className="submit-btn"
								/>
							</div>
						</div>
					</div>

					<DetailBox
						warehouses={warehouses}
						getDetails={getDetails}
						item_name={detailItem.item_name}
						item_id={detailItem.item_id}
						model={detailItem.model}
						category_name={detailItem.category_name}
						parent_category_name={detailItem.parent_category_name}
						completeTransfer={completeTransfer}
					/>
				</div>
			) : tabValue === 1 ? (
				<div style={{ width: "100%" }}>
					<ManageWarehouses warehouses={warehouses} />
				</div>
			) : (
				<Reports />
			)}
		</div>
	);
};

export default Index;
