import React from 'react';

interface CellProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Cell = React.forwardRef<HTMLDivElement, CellProps>(({ children, className, style }, ref) => {
  return (
    <div 
      ref={ref}
      className={`
        flex 
        flex-col 
        overflow-hidden 
        p-1 min-h-[50px] 
        border-b 
        border-r 
        ${className}
      `}
      style={style}
    >
      {children}
    </div>
  );
});

export default Cell;
