import React from "react";

import styles from "./Category.module.scss";

function Category({ children, categoryName }) {
  return (
    <div>
      <h4 className={styles.categoryName}>{categoryName}</h4>
      <div className={styles.childrenRow}>{children}</div>
    </div>
  );
}

export default Category;
