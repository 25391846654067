import { Button } from "components/button";
import { useModalContext } from "components/modal";
import { AppointmentTypeList } from "./components/AppointmentTypeList";
import { NewAppointmentType } from "./components/NewAppointmentType";
import { useAppointmentTypes } from "./hooks/useAppointmentTypes";
import { useTranslation } from 'react-i18next';

export const AppointmentTypes = () => {
  const { data, isError, isLoading } = useAppointmentTypes();
  const { setModal } = useModalContext();
  const { t } = useTranslation();

  const handleClickNewAppointmentType = () => {
    setModal({
      label: "New Appointment Type",
      component: <NewAppointmentType />,
    });
  };

  const tBase = "views.admin.appointment-types.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <>
      <div className="flex items-center justify-between">
        <h1>{tr("Appointment Types")}</h1>
        <Button onClick={handleClickNewAppointmentType} primary>
          {tr("New")}
        </Button>
      </div>
      {isLoading && <div>{tr("Loading...")}</div>}
      {isError && <div>{tr("Error...")}</div>}
      {data && <AppointmentTypeList appointmentTypes={data} />}
    </>
  );
};
