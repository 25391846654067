import axios from "api/axios";
import Spinner from "components/spinner";
import format from "date-fns/format";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

type Props = {
  date: Date;
};

export default function Overview(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.home.container.production.container.overview";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const production = useQuery(
    ["production", props.date],
    async () =>
      await axios
        .get(
          `
    ${
      process.env.REACT_APP_SERVER_URL
    }/api/v1/company/dashboard/production/${format(props.date, "yyyy-MM-dd")}
    `
        )
        .then((res) => res.data)
  );
  if (!production.data) return <Spinner />;

  return (
    <Con>
      <SCon>
        <div>{tr("Total Production")} ($)</div>
        <div>{dollarFormatter(production.data.actual_total)}</div>
      </SCon>
      <SCon>
        <div>{tr("Estimated Production")} ($)</div>
        <div>{dollarFormatter(production.data.estimate_total)}</div>
      </SCon>
    </Con>
  );
}

const Con = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  border-top: 1px solid ${theme.border.dark};
  border-bottom: 1px solid ${theme.border.dark};
`;

const SCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-right: 1px solid ${theme.border.light};
  > div:first-child {
    font-size: 0.8rem;
    font-weight: bold;
    color: ${theme.color.subtext2};
  }
  > div:last-child {
    font-size: 1.2rem;
  }
`;
