import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

export const orderDescComparanator = (itm1, itm2, orderBy) => {
    if (itm1[orderBy] < itm2[orderBy]) {
        return -1;
    }
    if (itm1[orderBy] > itm2[orderBy]) {
        return 1;
    }
    return 0;
};

//Compare items in the cell and order them by by boolean
export const boolComparanator = (itm1, itm2, orderBy) => {
    if (itm1[orderBy] - itm2[orderBy]) {
        return 1;
    }
    if (!itm1[orderBy] - itm2[orderBy]) {
        return -1;
    }
    return 0;
};

//get the comparanator
export const getComparator = (order, orderBy) => {
    if (orderBy === "special_order" || orderBy === "bulk_order") {
        return (itm1, itm2) => boolComparanator(itm1, itm2, orderBy);
    }

    return order === "desc"
        ? (itm1, itm2) => -orderDescComparanator(itm1, itm2, orderBy)
        : (itm1, itm2) => orderDescComparanator(itm1, itm2, orderBy);
};

//sort the cells
export const sort = (array, comparanator) => {
    const stabilizeThis = array.map((el, index) => [el, index]);

    stabilizeThis.sort((itm1, itm2) => {
        const order = comparanator(itm1[0], itm2[0]);
        if (order !== 0) {
            return order;
        }
        return itm1[1] - itm2[1];
    });

    return stabilizeThis.map((el) => el[0]);
};

//The table header
export const EnhancedTableHead = (props) => {
    const { t } = useTranslation();
    const tBase = "views.warehouse_management.components.table_utils";
    const tr = (key) => t(`${tBase}.${key}`);
    
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "desc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? tr("sorted descending")
                                        : tr("sorted ascending")}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

