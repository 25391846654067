import * as React from "react";
import styled from "styled-components";
import Logo from "images/eserv.png";
import { Input } from "components/input";
import { Button } from "components/button";
import { useMutation, useQuery } from "react-query";
import axios from "api/axios";
import { useParams } from "react-router-dom";
import Spinner from "components/spinner";
import { Link } from "components/link";
import Form from "components/form2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

export default function ValidateAccount() {
	const { t } = useTranslation();
	const tBase = "views.validate_account.index";
	const tr = (key: string) => t(`${tBase}.${key}`);

	const { id } = useParams<{ id: string }>();

	const changePassword = useMutation(
		async (body: { password: string }) =>
			await axios.post(
				`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/validate-account/${id}
  `,
				body
			)
	);

	const formik = useFormik({
		initialValues: { password: "" },
		onSubmit: (e) => changePassword.mutate(e),
		validationSchema: Yup.object({
			password: Yup.string().required().min(8),
		}),
	});

	const initialQuery = useQuery(
		"validate-account",
		async () =>
			await axios
				.get(
					`
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/validate-account/${id}
  `
				)
				.then((res) => res.data)
	);

	if (initialQuery.isLoading) {
		return <Spinner />;
	}

	return (
		<SCon formik={formik}>
			<img src={Logo} width="100px" alt="logo" />
			{changePassword.isSuccess ? (
				<>
					<p>
						{tr("Your account has been validated! To get started, navigate to home page by clicking on the button below.")}
					</p>
					<Button onClick={() => window.location.replace("/")}>
						{tr("Home Page")}
					</Button>
				</>
			) : !initialQuery.data ? (
				<>
					<p>
						{tr("Account has been validated. Please click on the button below to log in to your account.")}
					</p>
					<Link to="/login">{tr("Log In")}</Link>
				</>
			) : (
				<>
					<h1>{tr("Welcome to EServ!")}</h1>
					<p>{tr("Please set up your password to continue")}:</p>
					<Input type="password" name="password" />
					<Button
						disabled={!formik.isValid || !formik.dirty}
						style={{ marginTop: "1rem" }}
						primary
						type="submit"
					>
						{tr("Save Changes")}
					</Button>
				</>
			)}
		</SCon>
	);
}

const SCon = styled(Form)`
	width: 100%;
	height: 100vh;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	/* justify-items: center; */
	align-self: center;
	justify-self: center;
	padding: 0.5em;
`;
