/* eslint-disable react/jsx-no-target-blank */
import axios from "axios";
import TooltippedElement from "components/TooltippedElement";
import { Button, NoStyleA, SubtleIconA } from "components/button";
import DataLabel from "components/dataLabel";
import SafeArea from "components/safe-area";
import useWindowDimensions from "hooks/useWindowDimensions";
import * as React from "react";
import { RiNavigationLine, RiRoadMapFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { TCustomer } from "types/customer";
import { TOwner, TProperty } from "types/property";
import PropertyTagList from "views/property/property-tag-list";
import { dollarFormatter } from "utils";
import { useTranslation } from 'react-i18next';

export default function CustomerContainer(props: {
  data: TCustomer;
  selected_address: string;
  size?: "sm" | "lg";
  // id: string;
}) {
  const data = props.data;
  const { t } = useTranslation();
  const tBase = "containers.customer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const query = useQuery<TProperty & { total_amount_due?: number }, any>(
    ["property", props.selected_address],
    async () =>
      props.selected_address &&
      (await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${props.selected_address}
  `
        )
        .then((res) => {
          return res.data;
        }))
  );
  // console.log("query", query.data);

  // "lg" and "sm" are used to determine the size of the container
  // "lg" is used in the Invoice page as a full width container
  if (props.size === "lg") { 
    return (
      <div className="divide-y">
        <div className="py-4">
          <h2>{tr("Property Info")}</h2>
        </div>
        <div className="grid grid-cols-2 gap-4 py-4">
          <div>
            <div>
              <div className="text-base pb-2">{tr("Property")}</div>
                <DataLabel title={tr("Address")}>
                {query.data?.name && (
                  <div className="italic text-gray-700">
                    {query.data?.name}
                  </div>
                )}
                <div className="flex flex-row items-center pb-1">
                  <TooltippedElement
                    message={tr("Property Info")}
                    placement="top"
                    element={
                      <NoStyleA
                        to={`/property/${query.data?.id}`}
                        className="!text-blue-800 hover:!underline"
                      >
                        {query.data?.full_address}
                      </NoStyleA>
                    }
                  />
                  <TooltippedElement
                    message={tr("Open in Google Maps")}
                    placement="top"
                    element={
                      <a
                        href={`https://maps.google.com/?q=${query.data?.full_address}`}
                        target="_blank"
                        className="flex items-center ml-2 text-xl text-slate-300 hover:text-blue-500"
                      >
                        <RiRoadMapFill />
                      </a>
                    }
                  />
                </div>
                </DataLabel>
            </div>
            <DataLabel title={tr("Tags")} className="pb-1">
              {query.data?.tags.length !== 0 ? 
                <PropertyTagList tags={query.data?.tags} />
                : <span className="text-slate-500">{tr("None")}</span>
              }
            </DataLabel>
            <DataLabel
              title={tr("Most Recent Appointment")}
              classNames={{ title: "uppercase" }}
            >
              {query.data?.date || <span className="text-slate-500">{tr("None")}</span>}
            </DataLabel>
          </div>

          <div>
            {query.data?.owner && query.data?.billed_to ? (
              query.data.billed_to.id === query.data.owner.id ? (
                <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="both" size="lg" />
              ) : (
                <>
                  <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="owner" />
                  <ContactCon data={{ ...query.data.billed_to, total_amount_due: query.data.total_amount_due }} type="billed" size="lg" />
                </>
              )
            ) : query.data?.owner ? (
              <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="owner" size="lg" />
            ) : query.data?.billed_to ? (
              <ContactCon data={{ ...query.data.billed_to, total_amount_due: query.data.total_amount_due }} type="billed" size="lg" />
            ) : (
              <span>{tr("No customer assigned")}</span>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="divide-y">
        <div className="py-4">
          <h2>{tr("Property Info")}</h2>
        </div>
        <div className={`grid gap-4 py-4`}>
          <DataLabel title={tr("Address")} classNames={{ title: "uppercase" }}>
            {query.data?.name && (
              <div className="italic text-gray-700">
                {query.data?.name}
              </div>
            )}
            <div className="flex flex-row items-center ">
              <TooltippedElement
                message={tr("Property Info")}
                placement="top"
                element={
                  <NoStyleA
                    to={`/property/${query.data?.id}`}
                    className="!text-blue-800 hover:!underline"
                  >
                    {query.data?.full_address}
                  </NoStyleA>
                }
              />
              <TooltippedElement
                message={tr("Open in Google Maps")}
                placement="top"
                element={
                  <a
                    href={`https://maps.google.com/?q=${query.data?.full_address}`}
                    target="_blank"
                    className="flex items-center ml-2 text-xl text-slate-300 hover:text-blue-500"
                  >
                    <RiRoadMapFill />
                  </a>
                }
              />
            </div>
          </DataLabel>
          <DataLabel title={tr("Tags")}>
            <PropertyTagList tags={query.data?.tags} />
          </DataLabel>
          <DataLabel
            title={tr("Most Recent Appointment")}
            classNames={{ title: "uppercase" }}
          >
            {query.data?.date || <span className="text-slate-500">{tr("None")}</span>}
          </DataLabel>
          {query.data?.owner && query.data?.billed_to ? (
            query.data.billed_to.id === query.data.owner.id ? (
              <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="both" />
            ) : (
              <>
                <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="owner" />
                <ContactCon data={{ ...query.data.billed_to, total_amount_due: query.data.total_amount_due }} type="billed" />
              </>
            )
          ) : query.data?.owner ? (
            <ContactCon data={{ ...query.data.owner, total_amount_due: query.data.total_amount_due }} type="owner" />
          ) : query.data?.billed_to ? (
            <ContactCon data={{ ...query.data.billed_to, total_amount_due: query.data.total_amount_due }} type="billed" />
          ) : (
            <span>{tr("No customer assigned")}</span>
          )}
        </div>
      </div>
    );
  }
}

type ContactProps = {
  data: TOwner & { total_amount_due? : number };
  type: "both" | "owner" | "billed";
  size?: "sm" | "lg";
};

const ContactCon = (props: ContactProps) => {
  const { t } = useTranslation();
  const tBase = "containers.customer.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  if (props.size === "lg") {
    return (
      <div className="">
        <div className="text-base">
          {props.type === "both"
            ? tr("Owner and Billing")
            : props.type === "owner"
            ? tr("Owner")
            : tr("Billing")}
        </div>
        
        <div className="py-2 space-y-1">
          <DataLabel title={tr("Name")}>
            <NoStyleA
              to={`/customers/${props.data?.id}`}
              className="!text-blue-700 hover:!underline"
            >
              {props.data?.name}
            </NoStyleA>
          </DataLabel>
          {props.data?.customer_tags && (
            <DataLabel title={tr("Tags")}>
              <div className="flex space-x-1">
                {props.data.customer_tags.map((tag) => (
                  <span
                    style={{ background: tag.color }}
                    className="p-1 text-xs text-white rounded"
                    key={tag.id}
                  >
                    {tag.label}
                  </span>
                ))}
              </div>
            </DataLabel>
          )}
          <DataLabel title={tr("Contact Number")}>
            {props.data?.phone || (
              <span className="font-medium text-slate-400">{tr("No Number")}</span>
            )}
          </DataLabel>
          <DataLabel title={tr("Email")} classNames={{ title: "uppercase" }}>
            {props.data?.email}
          </DataLabel>
        </div>
      </div>
    );
  } else {
    return (
      <div className="divide-y">
        <div className="py-2">
          <h4>
            {props.type === "both"
              ? tr("Owner and Billing")
              : props.type === "owner"
              ? tr("Owner")
              : tr("Billing")}
          </h4>
        </div>
        <div className="py-2 space-y-1">
          <DataLabel title={tr("Name")}>
            <NoStyleA
              to={`/customers/${props.data?.id}`}
              className="!text-blue-700 hover:!underline"
            >
              {props.data?.name}
            </NoStyleA>
          </DataLabel>
          {props.data?.customer_tags && (
            <DataLabel title={tr("Tags")}>
              <div className="flex space-x-1">
                {props.data.customer_tags.map((tag) => (
                  <span
                    style={{ background: tag.color }}
                    className="p-1 text-xs text-white rounded"
                    key={tag.id}
                  >
                    {tag.label}
                  </span>
                ))}
              </div>
            </DataLabel>
          )}
          <DataLabel title={tr("Contact Number")}>
            {props.data?.phone || (
              <span className="font-medium text-slate-400">{tr("No Number")}</span>
            )}
          </DataLabel>
          <DataLabel title={tr("Email")} classNames={{ title: "uppercase" }}>
            {props.data?.email}
          </DataLabel>
          <DataLabel title={tr("Total Amount Owed By Customer")}>
              <span className={props.data.total_amount_due === 0 ? "text-green-500" : "text-red-500"}>
                {dollarFormatter(props.data.total_amount_due)}
              </span>
          </DataLabel>
        </div>
      </div>
    );
  }
};
