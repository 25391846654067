import { QuoteCategory, Multiplier } from "views/projects/views/project/views/quote";

type Total = {
    list_price: number;
    sale_price: number;
}
export const getProductTotal = (categories?: {[catId: string]: QuoteCategory }): Total => {

    const initial = {
        list_price: 0,
        sale_price: 0
    }
    if (!categories) return initial

    return Object.entries(categories).reduce(
        (prev, [catId, category]) => {
          let temp = { ...prev };
          Object.entries(category.items).forEach(([itemId, item]) => {
            if (!item.quantity) return;
            temp = {
              list_price: temp.list_price + item.quantity * item.list_price,
              sale_price: temp.sale_price + item.quantity * item.sale_price,
            };
          });
          return temp;
        },
        initial
      )
}

export const getCategoryTotal = (categories?: {[catId: string]: QuoteCategory }): {[catId: string]: Total} | null =>  {
    if (!categories) return null

    return Object.entries(categories).reduce(
        (prev, [catId, category]) => {
          let list_price = 0;
          let sale_price = 0;
          Object.entries(category.items).forEach(([itemId, item]) => {
            if (!item.quantity) return;
            list_price = list_price + item.quantity * item.list_price;
            sale_price = sale_price + item.quantity * item.sale_price;
          });
          return {
            ...prev,
            [catId]: {
              list_price,
              sale_price,
            },
          };
        },
        {}
      )
}

export type MultiplierReturnValues = Multiplier & Total

export const getMultiplersValues = (categoryTotal: {[catId: string]: Total} | null, total: Total, multipliers?: Multiplier[] | null): MultiplierReturnValues[] | null => {
    

    if (!multipliers || !categoryTotal) return null

    return multipliers.map((multiplier) => {

        let list_price = 0;
        let sale_price = 0;

        if (multiplier.all_category) {
            list_price = total.list_price
            sale_price = total.sale_price
        } else {
            if (multiplier.fk_category_id) {
                multiplier.fk_category_id.forEach((categoryId) => {
                    if (categoryTotal[categoryId]) {
                        list_price = list_price + categoryTotal[categoryId].list_price
                        sale_price = sale_price + categoryTotal[categoryId].sale_price
                    }
                })
            }
        }

        list_price = list_price * (multiplier.is_list ? multiplier.rate / 100 : 0)
        sale_price = sale_price * (multiplier.is_sale ? multiplier.rate / 100 : 0)

        return {
          ...multiplier,
          list_price,
          sale_price
        }
    })
}

export const getMultipliersTotal = (multipliers: MultiplierReturnValues[] | null): Total => {

    const initial = {list_price: 0, sale_price: 0}

    if (!multipliers) return initial


    return multipliers.reduce(
        (prev: any, multiplier: any) => {
          return {
            list_price: prev.list_price + multiplier.list_price,
            sale_price: prev.sale_price + multiplier.sale_price,
          };
        },
        initial
      )
}