import axios from "axios";
import RequestsList from "containers/requestsList";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import AppointmentsList from "./appointment-list";

export default function Appointments(props) {
  const [appointments, setAppointments] = useState(null);

  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/propertyAppointments/${props.propertyId}`
      );

      setAppointments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  if (!appointments) return null;

  return (
    <Con>
      <AppointmentsList data={appointments} />
    </Con>
  );
}

const Con = styled.div``;
