import React, { useState, useEffect } from 'react'
import BreadCrumbs from 'components/breadcrumbs'
import "./styles.css"
import { history } from 'routes'
import { useLocation, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import currentPlanTextImg from "images/current-plan-text.png"
import {
    planStyle,
    planCardStyle,
    planTitleStyle,
    planPriceWrapperStyle,
    planPriceStyle,
    planPeriodStyle,
    planFeatureListStyle,
    planFeatureTextWrapperStyle,
    planFeatureTextStyle,
    planUnderscoreStyle,
    planProceedButtonStyle
} from './styles'

const UpgradeSub = () => {
    const { t } = useTranslation();
    const tBase = "views.subscription.upgrade";
    const tr = (key: string) => t(`${tBase}.${key}`);

    // var state = useLocation().state as any
    // console.log(state)
    // var planName = state?.plan_name

    // find a way to go get plan information without creating multiple api calls, useLocation is an option,
    // but it doesn't work well with breadcrumbs
    
    const location = useLocation() as any

    if (location.state === undefined) {
        return <Redirect to="/admin/subscription" />
    }

    const planInfo = location.state.planInfo
    const basePlanInfo = location.state.basePlanInfo

    var planName = planInfo?.plan_name

    var EssentialChosen, EnhancedChosen, ExclusiveChosen = false
    var chosenPlanWeight = 0
    var ChosenPlan: any

    switch (planName) {
        case "Essential":
            EssentialChosen = true
            ChosenPlan = "Essential"
            break;
        case "Enhanced":
            EnhancedChosen = true
            ChosenPlan = "Enhanced"
            chosenPlanWeight = 1
            break;
        case "Exclusive":
            ExclusiveChosen = true
            ChosenPlan = "Exclusive"
            chosenPlanWeight = 2
            break;
    }

    const gotoPlan = (planName:string, weight:number) => {
        if (planName === ChosenPlan as any || weight < chosenPlanWeight) return
        const info = {
            planInfo: planInfo,
            basePlanInfo: basePlanInfo,
            initialSignup: false
        }
        switch (planName) {
            case "Essential":
                history.push("./upgrade/essential", info);
                break;
            case "Enhanced":
                history.push("./upgrade/enhanced", info);
                break;
            case "Exclusive":
                history.push("./upgrade/exclusive", info);
                break;
        }
    }

    const essentialFeatureArr = [
        "1 - 10 Users",
        "100GB Image Storage",
        "Question Builder",
        "Contact Management",
        "Estimating with Customer Interaction",
        "Field App",
        "Invoice/Payments",
        "Quickbook Sync",
        "Historical Data Reporting",
        "Inventory Management",
    ]

    const enhancedFeatureArr = [
        "EServ Essential Plus",
        "1 - 20 Users",
        "300GB Image Storage",
        "Auto Text / Email",
        "Project Management",
        "Equipment Management",
    ]

    const proFeatureArr = [
        "EServ Enhanced Plus",
        "1 - 20 Users",
        "300GB Image Storage",
        "Team Management (Dream Team)",
        "Access to Additional Features"
    ]

    return (
        <div style={{height:"75em"}}>
        
        <BreadCrumbs link={[{label: "Subscription", to:"/admin/subscription"}]} />

        <div style={planStyle}>
            <div>
                <div style={{height:"45px", padding:"10px"}}>
                { 
                    EssentialChosen &&
                    <img src={currentPlanTextImg} style={{margin:"auto", height:"30px"}}></img>
                }
                </div>
                <div style={{...planCardStyle, border: EssentialChosen ? "5px solid #ff4f00" : "1px solid #edeff2"}}>
                    <div style={planTitleStyle}>Essential</div>
                    <div style={planPriceWrapperStyle}>
                        <div style={planPriceStyle}>$399</div>
                        <div style={planPeriodStyle}>/{tr("Month")}</div>
                    </div>
                    <div style={planFeatureListStyle}>
                    {
                        essentialFeatureArr.map((feature, index) => {
                            return (
                                <div style={planFeatureTextWrapperStyle} key={index}>
                                    {/* syntax for the stars icon beside each feature, doesn't quite work so leaving it out */}
                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 15.8196L7.5098 18.562L8.73049 13.4439L4.73535 10.0217L9.97945 9.60156L11.9999 4.74323L14.0204 9.60156L19.2653 10.0217L15.2694 13.4439L16.4901 18.562L11.9999 15.8196Z" fill="blue"/>
                                    </svg> */}
                                    <li style={planFeatureTextStyle}>{tr(feature)}</li>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div style={planUnderscoreStyle}></div>
                    <div style={{position:"absolute", bottom:"0", left:"0", padding:"inherit", width:"100%"}}>
                        <button className={EssentialChosen ? `none`: `planBoxProceedButton`} style={{...planProceedButtonStyle, backgroundColor:"grey"}} onClick={() => gotoPlan("Essential", 0)} disabled={EssentialChosen}>{tr("Proceed")}</button>
                    </div>
                </div>
            </div>
            <div>
                <div style={{height:"45px", padding:"10px"}}>
                {
                    EnhancedChosen &&
                    <img src={currentPlanTextImg} style={{margin:"auto", height:"30px"}}></img>
                }
                </div>
                <div style={{...planCardStyle, border: EnhancedChosen ? "5px solid #ff4f00" : "1px solid #edeff2"}}>
                    <div style={{...planTitleStyle}}>Enhanced</div>
                    <div style={planPriceWrapperStyle}>
                        <div style={{...planPriceStyle}}>$699</div>
                        <div style={{...planPeriodStyle}}>/{tr("Month")}</div>
                    </div>
                    <div style={{...planFeatureListStyle}}>
                    {
                        enhancedFeatureArr.map((feature, index) => {
                            return (
                                <div style={planFeatureTextWrapperStyle} key={index}>
                                    <li style={{...planFeatureTextStyle}}>{tr(feature)}</li>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div style={{...planUnderscoreStyle}}></div>
                    <div style={{position:"absolute", bottom:"0", left:"0", padding:"inherit", width:"100%"}}>
                        <button className={EnhancedChosen ? `none` : `planBoxProceedButton`} style={{...planProceedButtonStyle, backgroundColor:"grey"}} onClick={() => gotoPlan("Enhanced", 1)}>{tr("Proceed")}</button>
                    </div>
                </div>
            </div>
            <div>
                <div style={{height:"45px", padding:"10px"}}>
                {
                    ExclusiveChosen &&
                    <img src={currentPlanTextImg} style={{margin:"auto", height:"30px"}}></img>
                }
                </div>
                <div style={{...planCardStyle, border: ExclusiveChosen ? "5px solid #ff4f00" : "1px solid #edeff2"}}>
                    <div style={planTitleStyle}>PRO</div>
                    <div style={planPriceWrapperStyle}>
                        <div style={planPriceStyle}>$699+</div>
                        <div style={planPeriodStyle}>/{tr("Month")}</div>
                    </div>
                    <div style={planFeatureListStyle}>
                    {
                        proFeatureArr.map((feature, index) => {
                            return (
                                <div style={planFeatureTextWrapperStyle} key={index}>
                                    <li style={planFeatureTextStyle}>{tr(feature)}</li>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div style={planUnderscoreStyle}></div>
                    <div style={{position:"absolute", bottom:"0", left:"0", padding:"inherit", width:"100%"}}>
                        <button className="planBoxProceedButton" style={planProceedButtonStyle} onClick={() => gotoPlan("Exclusive", 2)}>{tr("Proceed")}</button>
                    </div>
                </div>
            </div>
        </div>

        </div>
    )
}

export default UpgradeSub