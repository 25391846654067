import axios from "api/axios";
import { Button, SubtleButton } from "components/button";
import { StyledLabel } from "components/input/style";
import { useModalContext } from "components/modal";
import Assignee from "components/react-select/assignees";
import * as React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function SendQuoteForReview(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.sendQuoteForReview";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [assignees, setAssignees] = React.useState(null);
  const [delivery, setDelivery] = React.useState("text");
  //   const { setModal } = useModalContext()
  const sendQuote = useMutation(
    async (body: any) =>
      await axios.post(
        `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/send
      `,
        body
      ),
    {
      onSuccess: () => {
        window.location.reload();
        // setEditable(false);
        // quotes.refetch();
        // showSnackbar("Quote updated!");
        // window.scrollTo(0, document.body.scrollHeight);
      },
    }
  );

  return (
    <SCon>
      <StyledLabel>{tr("Assign Reviewers")}</StyledLabel>
      <Assignee assigneeType="all" onChange={(e: any) => setAssignees(e)} />
      <div style={{ marginTop: "1rem" }}>
        <StyledLabel>{tr("Delivery Method")}</StyledLabel>
        <SRadios>
          <SRadio>
            <input
              type="radio"
              name="delivery"
              value="text"
              checked={delivery === "text"}
              onChange={(e) => setDelivery(e.target.value)}
            />
            <StyledLabel>{tr("Text Message")}</StyledLabel>
          </SRadio>
          <SRadio>
            <input
              type="radio"
              checked={delivery === "email"}
              name="delivery"
              onChange={(e) => setDelivery(e.target.value)}
              value="email"
            />
            <StyledLabel>{tr("Email")}</StyledLabel>
          </SRadio>
        </SRadios>
      </div>
      <SButtons>
        <Button
          isLoading={sendQuote.isLoading}
          onClick={() => sendQuote.mutate({ assignees, delivery })}
          primary
        >
          {tr("Continue")}
        </Button>
      </SButtons>
    </SCon>
  );
}

const SCon = styled.div`
  width: 400px;
`;

const SButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  > button {
    margin-left: 1rem;
  }
`;

const SRadios = styled.div`
  display: flex;
`;

const SRadio = styled.div`
  display: flex;
  margin-right: 0.5em;
`;
