const formatDateForDisplay = (inputDate: Date | string): string => {
  try {
    const date = new Date(inputDate);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } catch (error) {
    return String(inputDate); // Fallback to the original input
  }
};

export default formatDateForDisplay;
