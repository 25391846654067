import React, { useState, useEffect } from "react";
import BreadCrumbs from "components/breadcrumbs";
// import { SubscriptionStripeContainerOld } from './subscriptionStripeContainerOld'
import { getStripeAccountId } from "views/payment/api/getStripeAccountId";
import SubscriptionStripeContainer from "./subscriptionStripeContainer";
import "./styles.css";
import axios from "axios";
import { useLocation, Redirect } from "react-router-dom";
import { Button } from "components/button";
import { useMutation } from "react-query";
import Spinner from "components/spinner";
import { useTranslation } from 'react-i18next';

const EssentialPlan = () => {
  const { t } = useTranslation();
  const tBase = "views.subscription.plans";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [stripeAccountId, setStripeAccountId] = React.useState<string>("");
  const [stripeProductId, setStripeProductId] = React.useState<string>();
  const location = useLocation() as any;

  let initialSignup = location.state.initialSignup;
  let planInfo: any;
  let basePlanInfo = location.state.basePlanInfo;

  if (!initialSignup) {
    planInfo = location.state.planInfo;
  }

  const [proration, setProration] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    // this is needed because useEffect will attempt to update the component state AFTER <Redirect /> unmounts the components, which results in a memory leak
    if (location.state === undefined) {
      return;
    }
    getStripeAccountId().then((data: string) => {
      setStripeAccountId(data);
    });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/check_product`,
        {
          planId: 2,
        }
      )
      .then((res) => {
        setStripeProductId(res.data["stripe_id"]);
      });
  }, []);

  React.useEffect(() => {
    if (planInfo && stripeProductId) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/`,
          {
            params: {
              type: "proration",
              subscriptionId: planInfo.stripe_subscription_id,
              customerId: planInfo.stripe_customer_id,
              priceId: stripeProductId,
            },
          }
        )
        .then((res) => setProration(res.data));
    }
    setIsLoading(false);
  }, [stripeProductId]);

  if (location.state === undefined) {
    return <Redirect to="/admin/subscription" />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!initialSignup && (
        <BreadCrumbs
          link={[
            { label: tr("Subscription"), to: "/admin/subscription/" },
            { label: tr("Plans"), to: "/admin/subscription/upgrade" },
          ]}
        />
      )}
      <div
        style={{
          display: "grid",
          gap: "20px",
          width: "30em",
          backgroundColor: "lightgrey",
          borderRadius: "10px",
          textAlign: "center",
          alignSelf: "center",
          padding: "10px",
        }}
      >
        <h1>{tr("Essential Plan")}</h1>
        <p>{tr("Renews Monthly")}</p>
        <p>
          {tr("Cost")}:
          <p>${(basePlanInfo.Essential.price / 100).toFixed(2)}</p>
          {
            // planInfo &&
            // <p style={{ fontWeight:"bold" }}>Prorated Price: {proration}</p>
          }
        </p>
        {planInfo && planInfo.plan_name !== basePlanInfo.name ? (
          <SubscriptionUpgradeButton planId="2" />
        ) : (
          <SubscriptionStripeContainer
            stripeAccountId={stripeAccountId}
            planId="2"
          />
        )}
      </div>
    </>
  );
};

const EnhancedPlan = () => {
  const { t } = useTranslation();
  const tBase = "views.subscription.plans";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [stripeAccountId, setStripeAccountId] = React.useState<string>("");
  const [stripeProductId, setStripeProductId] = React.useState<string>();
  const location = useLocation() as any;

  let initialSignup = location.state.initialSignup;
  let planInfo: any;
  let basePlanInfo = location.state.basePlanInfo;

  if (!initialSignup) {
    planInfo = location.state.planInfo;
  }

  const [proration, setProration] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (location.state === undefined) {
      return;
    }
    getStripeAccountId().then((data: string) => {
      setStripeAccountId(data);
    });
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/check_product`,
        {
          planId: 3,
        }
      )
      .then((res) => {
        setStripeProductId(res.data["stripe_id"]);
      });
  }, []);

  React.useEffect(() => {
    if (planInfo && stripeProductId) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/`,
          {
            params: {
              type: "proration",
              subscriptionId: planInfo.stripe_subscription_id,
              customerId: planInfo.stripe_customer_id,
              priceId: stripeProductId,
            },
          }
        )
        .then((res) => setProration(res.data));
    }

    setIsLoading(false);
  }, [stripeProductId]);

  if (location.state === undefined) {
    // console.log("undefined")
    return <Redirect to="/admin/subscription" />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!initialSignup && (
        <BreadCrumbs
          link={[
            { label: tr("Subscription"), to: "/admin/subscription/" },
            { label: tr("Plans"), to: "/admin/subscription/upgrade" },
          ]}
        />
      )}
      <div
        style={{
          display: "grid",
          gap: "20px",
          width: "30em",
          backgroundColor: "lightgrey",
          borderRadius: "10px",
          textAlign: "center",
          alignSelf: "center",
          padding: "10px",
        }}
      >
        <h1>{tr("Enhanced Plan")}</h1>
        <p>{tr("Renews Monthly")}</p>
        <p>
          Cost:
          <p>${(basePlanInfo.Enhanced.price / 100).toFixed(2)}</p>
          {
            // planInfo &&
            // <p style={{ fontWeight:"bold" }}>Prorated Price: {proration}</p>
          }
        </p>
        {/* determines whether current plan name is equal to base plan name, if not eqaul, then offer upgrade */}
        {planInfo && planInfo.plan_name !== basePlanInfo.name ? (
          <SubscriptionUpgradeButton planId="3" />
        ) : (
          <SubscriptionStripeContainer
            stripeAccountId={stripeAccountId}
            planId="3"
          />
        )}
      </div>
    </>
  );
};

const ExclusivePlan = () => {
  const { t } = useTranslation();
  const tBase = "views.subscription.plans";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [stripeAccountId, setStripeAccountId] = React.useState<string>("");
  const [total, setTotal] = React.useState<number>(699);
  const [planId, setPlanId] = React.useState<string>("4");
  const [stripeProductId, setStripeProductId] = React.useState<string>();

  const [addonsArr, setAddonsArr] = React.useState<string[]>([]);
  const [sentAddonsArr, setSentAddonsArr] = React.useState<object>([]);
  const [addonsMixId, setAddonsMixId] = React.useState<string>("");

  const [proration, setProration] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (location.state === undefined) {
      return;
    }
    getStripeAccountId().then((data: string) => {
      setStripeAccountId(data);
    });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/check_product`,
        {
          planId: 4,
        }
      )
      .then((res) => {
        setStripeProductId(res.data["stripe_id"]);
        setSentAddonsArr(res.data["addons"]);
        setAddonsMixId(res.data["addonsMixId"]);
      });

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (location.state === undefined) {
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/check_product`,
        {
          planId: 4,
          addonsArr: addonsArr,
        }
      )
      .then((res) => {
        setStripeProductId(res.data["stripe_id"]);
        setSentAddonsArr(res.data["addons"]);
        setAddonsMixId(res.data["addonsMixId"]);
      });
  }, [addonsArr]);

  React.useEffect(() => {
    // console.log(addonsMixId)
  }, [addonsMixId]);

  // React.useEffect(() => {
  //     if (planInfo && stripeProductId) {
  //         axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/`,
  //             {
  //                 params: {
  //                     type: "proration",
  //                     subscriptionId: planInfo.stripe_subscription_id,
  //                     customerId: planInfo.stripe_customer_id,
  //                     priceId: stripeProductId
  //                 }
  //             }
  //         ).then(res => setProration(res.data))
  //     }

  //     setIsLoading(false)
  // }, [stripeProductId])

  const location = useLocation() as any;

  let initialSignup = location.state.initialSignup;
  let planInfo: any;
  let basePlanInfo = location.state.basePlanInfo;

  if (!initialSignup) {
    planInfo = location.state.planInfo;
  }

  if (location.state === undefined) {
    return <Redirect to="/admin/subscription" />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  const addonObj: { [key: string]: number } = {
    appointmentRouting: 75,
    serviceLocation: 50,
    inventoryCustom: 100,
    incentiveTracking: 200,
  };

  const updateValue = (e: any) => {
    const { checked, value } = e.target;
    const amount = addonObj[value] || 0;
    setTotal((prevState) =>
      checked ? prevState + amount : prevState - amount
    );
    if (checked) {
      setAddonsArr([...addonsArr, value]);
    } else {
      setAddonsArr(addonsArr.filter((addon) => addon !== value));
    }

    // You can add additional code here if needed.
  };

  return (
    <>
      {!initialSignup && (
        <BreadCrumbs
          link={[
            { label: tr("Subscription"), to: "/admin/subscription/" },
            { label: tr("Plans"), to: "/admin/subscription/upgrade" },
          ]}
        />
      )}
      <div
        style={{
          display: "grid",
          gap: "20px",
          width: "30em",
          backgroundColor: "lightgrey",
          borderRadius: "10px",
          textAlign: "center",
          alignSelf: "center",
          padding: "10px",
        }}
      >
        <h1>{tr("Pro Plan")}</h1>
        <p>{tr("Renews Monthly")}</p>
        <p>
          Cost:
          <p>${(basePlanInfo.Exclusive.price / 100).toFixed(2)}</p> + Addons
          {
            // planInfo &&
            // <p style={{ fontWeight:"bold" }}>Prorated Price: {proration}</p>
          }
        </p>
        <p style={{ fontSize: "20px" }}>{tr("Total")}: ${total}.00</p>
        <div
          style={{
            display: "inline-grid",
            gridTemplateColumns: "60% 20% 20%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "inline-grid",
              gridTemplateRows: "repeat(4, 50px)",
              alignItems: "center",
            }}
          >
            <p id="appointmentRouting">{tr("Appointment Routing")}</p>
            <p id="serviceLocation">{tr("Service Location Pin Drops")}</p>
            <p id="inventoryCustom">{tr("Inventory Custom Add on")}</p>
            <p id="incentiveTracking">
              {tr("Production, Goals, and Incentives Tracking")}
            </p>
          </div>
          <div
            style={{
              display: "inline-grid",
              gridTemplateRows: "repeat(4, 50px)",
              justifyContent: "center",
            }}
          >
            <input
              type="checkbox"
              style={{ width: "25px" }}
              value="appointmentRouting"
              onClick={(e) => updateValue(e)}
            />
            <input
              type="checkbox"
              value="serviceLocation"
              onClick={(e) => updateValue(e)}
            />
            <input
              type="checkbox"
              value="inventoryCustom"
              onClick={(e) => updateValue(e)}
            />
            <input
              type="checkbox"
              value="incentiveTracking"
              onClick={(e) => updateValue(e)}
            />
          </div>
          <div
            style={{
              display: "inline-grid",
              gridTemplateRows: "repeat(4, 50px)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>$75</p>
            <p>$50</p>
            <p>$100</p>
            <p>$200</p>
          </div>
        </div>
        {planInfo && planInfo.plan_name !== basePlanInfo.name ? (
          <SubscriptionUpgradeButton planId={planId} />
        ) : (
          <SubscriptionStripeContainer
            stripeAccountId={stripeAccountId}
            planId={planId}
            addonsArr={sentAddonsArr}
            addonsMixId={addonsMixId}
          />
        )}
      </div>
    </>
  );
};

// Subscription update button might be combinable with the logic in SubscriptionStripeContainer
type SubUpdateProps = {
  planId: string;
  addonsArr?: object | null;
  addonsMixId?: string | null;
};

const SubscriptionUpgradeButton = ({
  planId,
  addonsArr,
  addonsMixId,
}: SubUpdateProps) => {
  const { t } = useTranslation();
  const tBase = "views.subscription.plans";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const [confirm, setConfirm] = React.useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [countDownValue, setCountDownValue] = useState<number>(5);

  const countDown = () => {
    const interval = setInterval(() => {
      setCountDownValue((prevState) => prevState - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  };

  if (countDownValue === 0) {
    return <Redirect to="/admin/subscription" />;
  }

  const handleClick = () => {
    setConfirm(!confirm);
  };

  const date = new Date();
  const startDate = date.toDateString();
  date.setDate(date.getDate() + 30);
  const endDate = date.toDateString();

  const upgradePlan = async () => {
    await axios
      .post(
        `
                    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/subscription/subscription_checkout/
                `,
        {
          planId: planId,
          // addons will be null if plan is Essential or Enhanced
          addonsArr: addonsArr,
          addonsMixId: addonsMixId,
          startDate: startDate,
          endDate: endDate,
        }
      )
      .then((res) => {
        return res.data;
      });
    setSuccessMessage(tr("Plan upgraded successfully!"));
    countDown();
  };

  return (
    <>
      <Button primary onClick={handleClick}>
        {tr("Upgrade")}
      </Button>
      {confirm && (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "10px",
            }}
          >
            <Button primary onClick={upgradePlan}>
              {tr("Confirm")}
            </Button>
            <Button onClick={() => setConfirm(false)}>{tr("Cancel")}</Button>
          </div>
          {successMessage && (
            <p>
              {successMessage} {tr("Redirect in")}...{countDownValue}
            </p>
          )}
        </>
      )}
    </>
  );
};

export { EssentialPlan, EnhancedPlan, ExclusivePlan };
