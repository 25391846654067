import { useMutation, useQueryClient } from "react-query";
import { addTags } from "../api/addTags";
import { ITags } from "../components/interfaces/ITags";

export const useTagsAddMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (newTag: ITags) =>
      addTags(newTag),
    {
      onError: (error) => {
        console.error(error);
        return;
      },
      onSettled: () => {
        queryClient.invalidateQueries("tags");
        return;
      },
    }
  );
};
