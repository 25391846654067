import { Avatar } from "components/avatar";
import styled from "styled-components";
import Spinner from "components/spinner";
import { theme } from "styles/theme";
import { SBreadCrumbs } from "components/link/style";
import { SubtleButton } from "components/button";
import { useModalContext } from "components/modal";
import NewUserModal from "../../container/newuser";
import { UserInfo } from "../index";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  info?: UserInfo;
};
export default function EmployeeInfo(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-info.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  if (!props.info) return <Spinner />;

  return (
    <Con>
      <SBreadCrumbs to="/admin/employees">Employees/</SBreadCrumbs>
      <div style={{ display: "flex" }}>
        <Avatar showLabel user_id={props.info.user_id}>
          {props.info.first_name + " " + props.info.last_name}
        </Avatar>
        <SubtleButton
          onClick={() =>
            setModal({
              component: <NewUserModal info={props.info} updateUser/>,
              label: tr("Employee"),
            })
          }
          primary
          style={{ marginLeft: "auto" }}
        >
          {tr("Edit")}
        </SubtleButton>
      </div>
      <LineItem>
        <span>{tr("Username")}</span>
        <span>{props.info.username}</span>
      </LineItem>
      <LineItem>
        <span>{tr("Email")}</span>
        <span>{props.info.email}</span>
      </LineItem>
      <LineItem>
        <span>{tr("Phone Number")}</span>
        <span>{props.info.phone}</span>
      </LineItem>
      <LineItem>
        <span>{tr("Title")}</span>
        <span>{props.info.job_title_label}</span>
      </LineItem>
      <LineItem>
        <span>{tr("Division")}</span>
        <span>{props.info.division?.label || "-"}</span>
      </LineItem>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const LineItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  > span:first-child {
    font-weight: bold;
    font-size: 0.8rem;
    color: ${theme.color.subtext};
  }
  > span:last-child {
    font-size: 1.1rem;
  }
`;
