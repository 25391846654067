import { Button, OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import * as React from "react";
import { FaClipboardList, FaMale } from "react-icons/fa";
import { RiClipboardLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import NewProject from "views/projects/components/newProject";
import RequestModal from "views/requests/newRequestModal";
import { useTranslation } from 'react-i18next';

export default function CreateModal() {
  const { t } = useTranslation();
  const tBase = "views.home.components.createModal";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { setModal } = useModalContext();
  const history = useHistory();

  return (
    <SCon>
      <SButton
        onClick={() =>
          setModal({ component: <NewProject />, label: tr("New Project") })
        }
      >
        <FaClipboardList />
        {tr("Project")}
      </SButton>
      <SButton
        onClick={() => {
          history.push("/customers/edit/new");
          setModal();
        }}
      >
        <FaMale />
        {tr("Customer")}
      </SButton>
      <SButton
        onClick={() => {
          // history.push("/customers/edit/new");
          setModal({ component: <RequestModal />, label: tr("New Request") });
        }}
      >
        <RiClipboardLine />
        {tr("Request")}
      </SButton>
    </SCon>
  );
}

const SButton = styled(OutlineButton)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1rem;
  > svg {
    font-size: 45px;
    margin-bottom: 0.5rem;
  }
`;

const SCon = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
