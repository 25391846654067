import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button } from "components/button";
import { useMutation, useQueryClient } from "react-query";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';


const ConfirmstatusModal = (a)=>{
  const { t } = useTranslation();
  const tBase = "views.time-clock.ConfirmStatusModal";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const queryClient = useQueryClient();
  const [pin, setpin] = useState(a.data.pin);

  const Sendstatus = useMutation((info) => {
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/statuschange`,
      {
        status: info.status,
        user_id: info.pin.slice(2)
      }
    ).then((res)=>{
      queryClient.invalidateQueries()
      setpin('');
  });
  });

  console.log(a)
  const makestasusval = (status, assigned)=>{
    if(assigned === a.data.parentstatus){
      return assigned}
    if (assigned === "None") {
      switch (status) {
        case "On-The-Way":
          return "On-The-Way-Unassigned";
        case "In-Progress":
          return "Clocked-In-Idle";
        case "Break":
          return "Break-Unassigned";
        default:
          return status;
      }
      // if (status === "On-The-Way") {
      // 	return "On-The-Way-Unassigned";
      // } else if (status === "In-Progress") {
      // 	return "Clocked-In-Idle";
      // } else if (status === "Break") {
      // 	return "Break-Unassigned";
      // }
    } else {
      return status + "-" + assigned;
    }
  }
  
  const [status, setstatus] = useState('On-The-Way')
  const [assigned, setassigned] = useState('None')

  const Parentstatusoption = ()=>{
    if(a.data.parentstatus&&a.data.parentstatus!=='Unknown Status'){
      return <option key={a.data.parentstatus} value={a.data.parentstatus}>{a.data.parentstatus}</option>
    } else {
      return <></>
    }
  }

  return (
    <div>
        <div style={{margin:'1rem', height:'2rem',display:'block'}}>
          <h3>{tr("Current Status")}:  
            <p style={{display:"inline", color:"#FF4F00", float:"right"}}> {a.data.currentstatus}</p>
          </h3>
        </div>
        <div style={{margin:'1rem', height:'2rem',display:'block'}}>
          <h3 style={{display:'inline'}}>{tr("New Status")}:</h3>
          <select style={{display:'inline',marginLeft:'1rem',border:'solid',borderRadius:'5px',borderColor:'#ccc', float:"right"}} onChange={(e)=>{
            e.persist();
            setstatus(e.target.value)}} value={status}>
            <option key="On-The-Way" value="On-The-Way">{tr("On-The-Way")}</option>
            <option key="In-Progress" value="In-Progress">{tr("In-Progress")}</option>
            <option key="Break" value="Break">{tr("Break")}</option>
          </select>
        </div>
        <div style={{margin:'1rem', height:'2rem',display:'block'}}>
          <h3 style={{display:'inline'}}>{tr("Assigned Appointment")}:</h3>
          <select style={{display:'inline',marginLeft:'1rem',border:'solid',borderRadius:'5px',borderColor:'#ccc', float:"right"}} onChange={(e)=>{
              e.persist();
              setassigned(e.target.value)}} value={assigned}>
            <option key='None' value="None">{tr("None")}</option>
            <Parentstatusoption/>
            {
              Array.isArray(a.data.appointments) && a.data.appointments.map((a) => {
                return <option key={a} value={a}>{a}</option>
              })
            }
          </select>
        </div>
        
        <Button
          primary
          style={{marginTop:'15px',float:'right'}}
          children=<p>{tr("Confirm")}</p>
            onClick={()=>{if (status !== "") {
              Sendstatus.mutate({ status: makestasusval(status, assigned), pin: pin });
              setModal();
            }}}
        />
    </div>
  );
}


export default ConfirmstatusModal;
