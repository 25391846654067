import * as React from "react";
import styled from "styled-components";

type Props = {
  count?: String;
  size?: String;
  children: React.ReactNode;
};

const Notification = React.forwardRef<any, any>(
  ({ count, children, ...props }: Props, ref) => (
    <>
      {count ? <Count>{count}</Count> : null}
      {React.Children.map(children, (c) => {
        if (React.isValidElement(c)) {
          return React.cloneElement(c, { ...props });
        }
      })}
    </>
  )
);

export default Notification;

const Con = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Count = styled.span`
  position: absolute;
  color: white;
  top: 0;
  transform: translate(25%, -25%);
  right: 0;
  background: red;
  font-size: 0.7rem;
  border-radius: 25px;
  line-height: 1;
  padding: 0.2em 0.5em;
`;
