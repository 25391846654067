import styled from "styled-components";
import DataLabel from "components/dataLabel";
import { useModalContext } from "components/modal";
import { useQuery } from "react-query";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const Answer = styled.div`
  margin-top: 0.25rem;
`;

const Responses = ({ questions, id }) => {
  const { t } = useTranslation();
  const tBase = "views.request.container.responses";
  const tr = (key) => t(`${tBase}.${key}`);

  const requestId = id;

  const getResponseImages = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/response-images/${requestId}`
    );

    return response.data.data;
  };

  const { data, isLoading, isError } = useQuery(
    ["response-images", requestId],
    getResponseImages
  );

  const populateOptions = (options) =>
    options?.map(({ response, subquestions }, optIdx) => {
      const populateSubquestions = subquestions?.map((subq, subqIdx) => {
        const populateSubqOptions = subq.options?.map((opt) => {
          return <span key={opt.id}>{opt.response}</span>;
        });

        return (
          <DataLabel
            key={subq.id}
            title={`${subqIdx + 1}. ${subq.question_label}`}
          >
            {isLoading && <span>{tr("Loading...")}</span>}
            {isError && <span>{tr("Error")}</span>}
            {subq.type === "IMAGE" && data ? (
              <>
                <ImageQuestion images={data} />
              </>
            ) : (
              <div className="flex flex-col">{populateSubqOptions}</div>
            )}
          </DataLabel>
        );
      });

      return (
        <div key={optIdx}>
          <span>{response}</span>

          {subquestions && (
            <div className="py-2 space-y-2 border-b">
              <div className="font-bold text-[#ff4f00] text-xs">
                Subquestions
              </div>
              {populateSubquestions}
            </div>
          )}
        </div>
      );
    });

  const populateResponses =
    questions &&
    questions.map(({ options, question_label, id, type }, i) => (
      <QuestionCon key={id}>
        <div className="flex-none mr-2 text-xl font-thin text-slate-600">
          Q{i + 1}
        </div>
        <div className="flex-1">
          <DataLabel title={question_label}>
            {type === "IMAGE" ? (
              <ImageQuestion images={data} />
            ) : (
              <Answer>{populateOptions(options)}</Answer>
            )}
          </DataLabel>
        </div>
      </QuestionCon>
    ));
  return (
    <div className="space-y-4">
      <h2 className="py-4 border-b">{tr("Responses")}</h2>
      <div className="space-y-6">{populateResponses}</div>
    </div>
  );
};

const ImageQuestion = ({ images }) => {
  const { t } = useTranslation();
  const tBase = "views.request.container.responses";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  // console.log(images);
  // quick fix to omit images with thumbnail in the name
  images = images?.filter((image) => !image.includes("thumbnail"));

  const populateImages = images?.map((image, i) => (
    <Image
      key={i}
      onClick={() =>
        setModal({
          component: <img alt="" src={`${image}`} style={{ height: "60vh" }} />,
          label: tr("Images"),
        })
      }
    >
      <img alt={""} src={`${image}`} />
    </Image>
  ));
  return <ImageCon>{populateImages}</ImageCon>;
};

export default Responses;

const ImageCon = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
`;

const Image = styled.div`
  overflow: hidden;
  height: 125px;
  > img {
    width: 100%;
    object-fit: cover;
  }
`;

const QuestionCon = styled.div`
  display: flex;
`;
