/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import Axios from "axios";
import { useModalContext } from "../../../components/modal";
import { useSnackbarContext } from "../../../components/snackbar";
import { mapValues } from "lodash";
import { format } from "date-fns";
import { Input } from "components/input";
import { Button, NoStyleButton, OutlineButton } from "components/button";
import AssigneeSelect from "components/react-select/assignees";
import { theme } from "styles/theme";
import Switch from "rc-switch";
import DurationInput from "components/input/duration";
import { parseDuration } from "utils/duration";
import { useFormik } from "formik";
import Field from "components/field";
import GroupedButton from "components/button/grouped";
import AppointmentType from "components/react-select/appointment-type";
import LineItemsContainer from "containers/lineItems";
import { useMutation } from "react-query";
import { VscWarning } from "react-icons/vsc";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

const AppointmentModal = styled.form`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1000px;
  overflow-x: none;
`;

const Row = styled.div`
  margin: 0.5rem 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  > label {
    text-align: right;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const TimeCon = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  > div {
    flex: 1;
  }
`;

const getAppointmentEmailConfig = async () => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/emailConfirmation`);
  return res.data;
};

export default function ({
  parentId = null,
  parentType = null,
  id,
  type,
  closed,
  appointmentId,
  appointment,
  acceptedBid = null,
  emailDefault,
  has_est = false,
  defaultdate,
  isBookit = false,
  responses = [],
}) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.appointmentCard.appointment-folder.appointmentModal";
  const tr = (key) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();
  const [assigneeOption, setAssigneeOption] = useState([]);
  const [assigned, setAssigned] = useState(
    appointment?.assignee !== null ? true : false
  );
  // const [updatedQuote, setUpdatedQuote] = React.useState();
  const [isEstimating, setIsEstimating] = React.useState(false);
  const [sendEmail, setSendEmail] = useState(emailDefault);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [appointmentEmailConfig, setAppointmentEmailConfig] = useState();

  // get the appointment email config and set the state
  const getAppointmentEmailConfigMutation = useMutation(
      getAppointmentEmailConfig,
      {
          onSuccess: (data) => {
              setAppointmentEmailConfig(data)
          },
      }
  );



  const fetchAssignee = async () => {
    try {
      const employee = await Axios.get(`
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/employee/all
      `);
      setAssigneeOption(
        employee.data.map(({ id, first_name, last_name }) => ({
          value: id,
          label: first_name + " " + last_name,
        }))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const createNewAppt = useMutation(
    async (e) =>
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${type}/${id}`,
        e
      ).then((res) => res.data),
    {
      onSuccess: (res) => {
        showSnackbar(tr("Appointment Added!"));
        closed();
        setModal();
        return res;
      },
    }
  );

  const updateAppt = useMutation(
    async (e) =>
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${appointmentId}/update`,
        e
      ).then((res) => res.data),
    {
      onSuccess: (res) => {
        showSnackbar(tr("Appointment Updated!"));
        closed();
        setModal();
        return res;
      },
    }
  );

  const handleShowRequestData = () => {
    setIsFlyoutVisible(!isFlyoutVisible);
  };

  const handleAddToDescription = () => {
    const responsesString = responses
      .map((response, index) => {
        return `${index + 1}: ${response.question_label}\n${response.options
          .map((option) => option.response)

          .join("\n")}`;
      })

      .join("\n");
    formik.setFieldValue("note", formik.values.note + "\n\n" + responsesString);
  };

  useEffect(() => {
    fetchAssignee();
    setSendEmail(appointment?.is_booked ? false : true);
    getAppointmentEmailConfigMutation.mutate();
  }, []);

  const initialValues = {
    date: appointment?.date
      ? format(new Date(appointment.date + "T00:00"), "yyyy-MM-dd")
      : "",
    start_time: appointment?.start_time
      ? format(new Date(appointment.start_time), "HH:mm")
      : "",
    end_time: appointment?.end_time
      ? format(new Date(appointment.end_time), "HH:mm")
      : "",
    duration: parseDuration(appointment?.duration).fullStr || "",
    assignee: appointment?.assignee || [],
    note: appointment?.note || "",
    estimate_amount: appointment?.estimate_amount / 100 || "",
    completion_note: appointment?.completion_note || "",
    type: appointment?.type || "job",
    fk_appointment_type_id:
      appointment?.fk_appointment_type_id?.toString() || "",
    estimate_products: null,
    estimate_pricetype: "sale",
    is_reminding: isBookit
      ? appointment?.is_booked
        ? true
        : false
      : appointment?.is_reminding || false,
  };

  const onSubmit = (e) => {
    // Get the client's timezone offset
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    // if (e.date) console.log("e.date found: ", e.date);

    // Format the date using the client's timezone offset
    const tempDate = e.date
      ? format(new Date(e.date), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd");

    const formatData = mapValues(e, (value, key) => {
      if (!value) return null;
      if (key === "start_time" || key === "end_time") {
        // console.log("tempDate: ", tempDate);
        // console.log("value: ", value);
        try {
          const dateTime = new Date(`${tempDate}T${value}`);
          // console.log("DateTime needs to be valid:", dateTime); // This should log a valid Date object

          const hours = (dateTime.getHours() + timezoneOffset) % 24;
          // console.log("Hours:", hours); // This should log a number between 0 and 23

          const formattedHours = hours < 10 ? "0" + hours : hours;
          const minutes = dateTime.getMinutes();
          // console.log("Minutes:", minutes); // This should log a number between 0 and 59

          const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
          return formattedHours + ":" + formattedMinutes;
        } catch (error) {
          console.error("Date formatting error: ", error);
        }
      }
      return value;
    });

    // console.log(formatData);

    if (appointment) {
      // e.assignee may be redundant as appointment data is being passed in anyway
      updateAppt.mutate({
        ...formatData,
        notify: sendEmail && appointmentEmailConfig,
        isBookit,
        assignee: e.assignee,
      });
    } else {
      if (mdatelist.length >= 1) {
        createNewAppt
          .mutateAsync({
            ...formatData,
            parentId,
            parentType,
            parts: [],
            notify: sendEmail && appointmentEmailConfig,
            parent_app: null,
          })
          .then((res) => {
            mdatelist.forEach((a) => {
              // console.log(a.toDate());
              delete formatData.date;
              createNewAppt.mutate({
                ...formatData,
                parentId,
                parentType,
                parts: [],
                notify: false,
                parent_app: res.id,
                date: a.toDate().toDateString(),
              });
            });
          })
          .then(() => {
            setModal();
          });
      } else {
        createNewAppt
          .mutateAsync({
            ...formatData,
            parentId,
            parentType,
            parts: [],
            notify: sendEmail && appointmentEmailConfig,
            parent_app: null,
          })
          .then(() => {
            setModal();
          });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date().nullable(),
    start_time: Yup.mixed().nullable(),
    end_time: Yup.mixed().nullable(),
    duration: Yup.string().nullable(),
    tech_assigned: Yup.array().of(Yup.number()),
    note: Yup.string(),
    // estimate_amount: Yup.number(),
    completion_note: Yup.string(),
    type: Yup.string(),
    fk_appointment_type_id: Yup.string().nullable(),
    is_reminding: Yup.boolean(),
  });
  const [Enddate, setEnddate] = React.useState(() => {
    return "none";
  });
  const [mdatelist, setmdatelist] = React.useState(() => {
    return [];
  });
  const switchMulti = () => {
    if (Enddate === "none") {
      setEnddate("inline-block");
    } else {
      setEnddate("none");
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <AppointmentModal onSubmit={formik.handleSubmit}>
      <SHeader>
        <div className="w-[100%] flex flex-row items-center justify-between">
          <h2>{tr("Info")}</h2>
          <div
            size="small"
            className="text-blue-500 underline hover:text-blue-700"
            // onMouseEnter={() => setIsFlyoutVisible(true)}
            // onMouseLeave={() => setIsFlyoutVisible(false)}
            // onClick={handleShowRequestData}
          >
            <NoStyleButton
              size="small"
              className=""
              onClick={handleShowRequestData}
            >
              <span className="text-blue-500 underline hover:text-blue-700">
                {isFlyoutVisible ? "Hide" : "Show"} {tr("Responses")}
              </span>
            </NoStyleButton>
            {isFlyoutVisible && (
              <div className="absolute right-0 z-10 p-2 mt-2 bg-white border rounded shadow-lg min-w-[400px] text-black">
                <div className="p-4 text-lg font-extrabold uppercase">
                  {tr("Responses")}
                </div>
                <hr />
                <div className="overflow-y-scroll min-h-[200px] cursor-text">
                  {responses ? (
                    responses?.map((response, index) => {
                      return (
                        <div key={response.id} className="mb-4">
                          <div className="font-bold">
                            {index + 1}: {response.question_label}
                          </div>
                          {response &&
                          response?.options &&
                          response.options.length > 0 ? (
                            response.options.map((option, index) => (
                              <div key={index} className="ml-2">
                                {option.response}
                              </div>
                            ))
                          ) : (
                            <div>{tr("No response")}</div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div>{tr("No responses")}</div>
                  )}
                </div>
                <Button onClick={handleAddToDescription} primary>
                  {tr("Add to Description")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </SHeader>
      <SContent>
        {!has_est && !appointment && formik.values.type === "job" && (
          <div className="flex p-2 my-2 text-yellow-600 border border-yellow-600 rounded bg-yellow-100/50">
            <VscWarning style={{ fontSize: "1.5rem", marginRight: "1rem" }} />
            <p>
              {tr("No estimate has marked as won yet. To get an accurate production value, please mark an estimate as won before proceeding to create appointments.")}
            </p>
          </div>
        )}

        <Row>
          <label>{tr("Category")}</label>
          <GroupedButton>
            <OutlineButton
              onClick={() => formik.setFieldValue("type", "estimate")}
              $selected={formik.values.type === "estimate"}
            >
              {tr("Estimate")}
            </OutlineButton>
            <OutlineButton
              onClick={() => formik.setFieldValue("type", "job")}
              $selected={formik.values.type === "job"}
            >
              {tr("Job")}
            </OutlineButton>
          </GroupedButton>
        </Row>
        <Row>
          <label>{tr("Type")}</label>
          <AppointmentType
            className="!max-w-[350px]"
            value={formik.values.fk_appointment_type_id}
            onChange={(e) =>
              formik.setFieldValue("fk_appointment_type_id", e.value)
            }
          />
        </Row>
        <Row>
          <label>{tr("Date")}</label>
          <Input type="date" name="date" placeholder={"Date"} formik={formik} />
        </Row>
        <Row>
          <label>{tr("Multi day appointment")}</label>
          <Switch
            onChange={() => {
              switchMulti();
            }}
          ></Switch>
          <label style={{ display: Enddate }}>{tr("Enter Other Dates")}</label>
          <DatePicker
            multiple
            style={{
              display: Enddate,
              width: "100%",
              height: "40px",
              background: "rgba(0,0,0,0)",
              border: "1px solid #d9d9d9",
              padding: "0 1em",
            }}
            value={mdatelist}
            onChange={setmdatelist}
          />
        </Row>
        <Row>
          <label>{tr("Arrival Time Window")}</label>
          <TimeCon>
            <Input
              type="time"
              name="start_time"
              placeholder={tr("Start Time")}
              formik={formik}
            />
            <span style={{ margin: "0 0.5rem 0 0.5rem" }}>{"-"}</span>
            <Input
              type="time"
              name="end_time"
              placeholder={tr("End Time")}
              formik={formik}
            />
          </TimeCon>
        </Row>

        <Row>
          <label>{tr("Duration")}</label>
          <DurationInput
            name="duration"
            value={formik.values.duration}
            onValueChange={(e) => {
              formik.setValues((v) => ({
                ...v,
                duration: e.value,
              }));
            }}
          />
          {/* <Input name="duration" type="number" placeholder={"Duration"} /> */}
        </Row>
        <Row>
          <label>{tr("Assign to")}</label>
          <AssigneeSelect
            assigneeType="appointment"
            name="assignee"
            value={formik.values.assignee}
            onChange={(e) => {
              formik.setValues((v) => ({ ...v, assignee: e }));
              setAssigned(true);
            }}
          />
        </Row>
        <Row>
          <label>{tr("Description")}</label>
          <Field name="note" type="textarea" minRows={4} formik={formik} />
        </Row>
        {/* <Row>
          <label>Estimate Production ($)</label>
          <DollarInputV2 name="estimate_amount" formik={formik} />
        </Row> */}
        <Row>
          <label>{tr("Completion Note")}</label>
          <Field
            name="completion_note"
            type="textarea"
            minRows={4}
            formik={formik}
          />
        </Row>
      </SContent>
      {!appointment && (
        <>
          <SHeader>
            <h2>{tr("Estimate")}</h2>
            <Switch
              style={{ marginLeft: "auto" }}
              checked={isEstimating}
              onChange={setIsEstimating}
            />
          </SHeader>
          <SContent>
            {isEstimating && (
              <LineItemsContainer
                view="edit"
                type="estimate_appt"
                parentType={type}
                id={id}
                onClick={(p) => {
                  formik.setFieldValue("estimate_products", p.products);
                  formik.setFieldValue("estimate_pricetype", p.price_type);
                }}
              />
            )}
          </SContent>
        </>
      )}
      <div
        className="space-x-4"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {type !== "projects" && (
          <>
            <div className="space-x-2">
              <span>{tr("Send Reminder")}</span>
              <Switch
                checked={formik.values.is_reminding}
                onChange={(e) => formik.setFieldValue("is_reminding", e)}
              />
            </div>
            <div className="space-x-2">
              <span style={{ color: "grey", fontSize: "85%" }}>
                {tr("Send E-mail Confirmation to Customer")}
              </span>
              <Switch
                checked={sendEmail && appointmentEmailConfig}
                onChange={(e) => {
                  setSendEmail(e);
                  setAppointmentEmailConfig(e);
                }}
              />
            </div>
          </>
        )}


        <Button
          type="submit"
          primary
          isLoading={createNewAppt.isLoading || updateAppt.isLoading}
          disabled={!assigned}
        >
          {
            // checks if request has bookit (preferred date) question
            isBookit
              ? appointment?.is_booked
                ? tr("Submit")
                : "Book It"
              : // for requests without a bookit (preferred date) question
                tr("Submit")
          }
        </Button>
      </div>
    </AppointmentModal>
  );
}

const SHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.border.dark};
  padding: 1rem 0;
`;

const SContent = styled.div`
  display: flex;
  flex-direction: column;
`;
