import axios from "axios";
import { Button } from "components/button";
import * as React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { theme } from "styles/theme";
import { useTranslation } from 'react-i18next';

export default function AlertModal() {
  const { t } = useTranslation();
  const tBase = "views.navigation.container.alertModal";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const getLink = useMutation(
    async () =>
      await axios
        .get(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/stripe/link
      `
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        window.open(data, "_blank");
      },
    }
  );

  return (
    <Con>
      <AlertCon>
        <span>
          {tr("Please complete the onboarding process to start receiving payments.")}
        </span>
        <Button isLoading={getLink.isLoading} onClick={() => getLink.mutate()}>
          {tr("Complete Process")}
        </Button>
      </AlertCon>
    </Con>
  );
}

const Con = styled.div`
  width: 500px;
  height: 600px;
`;

const AlertCon = styled.div`
  border: 1px solid ${theme.border.dark};
  border-radius: 10px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  > button {
    margin-top: 1rem;
    align-self: flex-start;
  }
`;
