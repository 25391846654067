import axios from "axios";
import {
  Button,
  IconButton,
  OutlineButton,
  SubtleButton,
} from "components/button";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import WarrantyItemsCon from "containers/warrantyItems";
import { useFormik } from "formik";
import * as React from "react";
import { RiArrowLeftSLine, RiPencilLine } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TWarranty, TWarrantyList, TWarrantyTargetItem } from "types/warranty";
import WarrantyLine from "./warrantyLine";
import { useTranslation } from 'react-i18next';

type TProps = {
  id: string;
  type: "project" | "request" | "property";
};
export default function WarrantyTab(props: TProps) {
  const { t } = useTranslation();
  const tBase = "containers.warrantyTab.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext();

  const query = useQuery<{
    items: TWarrantyTargetItem[] | null;
    prev_items: TWarrantyTargetItem[] | null;
  }>(
    ["warranty", props.type, props.id],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${props.id}?type=${props.type}
    `
        )
        .then((res) => res.data)
  );

  const populateWarranties = query.data?.items?.map((item) => (
    <WarrantyLine item={item} key={item.id} />
  ));

  const populatePrevWarranties = query.data?.prev_items?.map((item) => (
    <WarrantyLine item={item} key={item.id} />
  ));

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-center justify-between border-b py-4">
          <h2>{tr("Warranties")}</h2>
          {props.type !== "property" && (
            <div className="space-x-2">
              <IconButton
                primary
                onClick={() =>
                  modal.setModal({
                    component: (
                      <WarrantyModal id={props.id} type={props.type} />
                    ),
                    label: tr("Warranty"),
                  })
                }
              >
                <RiPencilLine />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className="space-y-4">{populateWarranties}</div>
      {query.data?.prev_items && (
        <div className="mt-2">
          <div className="flex items-center space-x-2 py-2">
            <span className="text-[#ff4f00] text-xs font-bold">
              {tr("Property Warranty")}{" "}
            </span>
            <div className="flex-1 h-[1px] bg-[#ff4f00]" />
          </div>

          <div className="space-y-4">{populatePrevWarranties}</div>
        </div>
      )}
    </div>
  );
}

const WarrantyModal = (props: TProps) => {
  const { t } = useTranslation();
  const tBase = "containers.warrantyTab.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    queryClient = useQueryClient();

  const [page, setPage] = React.useState("items");
  const query = useQuery<TWarranty | null>(
    ["warranty", props.id],
    async () =>
      props.id === "new"
        ? null
        : axios
            .get(
              `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${props.id}?type=${props.type}
    `
            )
            .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const mutation = useMutation(
    async (e: TWarranty | null) =>
      await axios
        .post(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${props.id}
    `,
          { ...e, type: props.type }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        modal.setModal();
        snackbar.showSnackbar(tr("Warranty updated!"));
      },
    }
  );

  const templates = useQuery<TWarrantyList[] | null>(
    ["warranties"],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty
    `
        )
        .then((res) => res.data)
  );

  const onSubmit = (e: TWarranty) => {
    mutation.mutate(e);
  };

  const initialValues = {
    items: query.data?.items || null,
  };

  const formik = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
  });

  const getItems = useMutation(
    async (e: { id: string }) =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/warranty/${e.id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (data: TWarranty) => {
        formik.setFieldValue("items", data.items);
        setPage("items");
      },
    }
  );

  const populateTemplates = templates.data?.map((template) => {
    const handleClick = () => {
      getItems.mutate({ id: template.id! });
    };

    return (
      <OutlineButton
        key={template.id}
        onClick={handleClick}
        isLoading={getItems.isLoading}
      >
        {template.label}
      </OutlineButton>
    );
  }) || <span>{tr("No template available.")}</span>;

  return (
    <div className="w-[500px]">
      {page === "items" ? (
        <div>
          {" "}
          <div>
            <OutlineButton primary onClick={() => setPage("templates")}>
              {tr("Select Template")}
            </OutlineButton>
          </div>
          <WarrantyItemsCon formik={formik} />
          <div className="flex justify-end">
            <Button
              primary
              type="submit"
              onClick={() => formik.handleSubmit()}
              isLoading={mutation.isLoading}
            >
              {tr("Save Changes")}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <SubtleButton
              size="small"
              className="!p-0"
              onClick={() => setPage("items")}
            >
              <RiArrowLeftSLine />
              {tr("Back")}
            </SubtleButton>
            <h2 className="py-2">{tr("Templates")}</h2>
          </div>
          <div className="flex flex-col space-y-2">{populateTemplates}</div>
        </div>
      )}
    </div>
  );
};
