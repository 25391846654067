import { dollarFormatter } from "../utils";
import { format } from "date-fns";
import { Status } from "../views/request/component/status";
import { SubtleIconButton } from "../components/button";
import { RiMore2Line } from "react-icons/ri";
import Placeholder from "../views/request/component/placeholder";
import Table, { useTable } from "components/table";
import { useHistory } from "react-router";
import { TEstimate } from "types/estimate";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Status = {
  color: string;
  label: string;
};

export default function EstimateSection<T>(props: {
  data: TEstimate[] | null;
  table?: any;
  pageCount?: number;
}) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.estimatesList";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory();

  const locale = i18n.language === "es" ? es : enUS;

  const columns = {
    id: {
      label: tr("Estimate #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: any) => (
        <Status color={row.status.color}>{tr(row.status.label)}</Status>
      ),
    },
    description: {
      label: tr("Description"),
      headerStyle : {
        maxWidth: "500px",
      },
    },
    customer: {
      label: tr("Customer"),
    },
    manager: {
      label: tr("Manager"),
    },
    entry_date: {
      label: tr("Entry Date"),
      cell: (row: any) =>
        format(new Date(row.entry_date), "MMM dd, yyyy hh:mm aa", {
          locale: locale,
        }),
    },
    date_won: {
      label: tr("Date Won"),
      cell: (row: any) =>
        row.won_date
          ? format(new Date(row.won_date), "MMM dd, yyyy hh:mm aa", {
            locale: locale,
          })
          : "",
    },
  };

  return (
    <div>
      {props.data && props.data.length > 0 ? (
        <Table
          data={props.data}
          columns={columns}
          handleClick={(row: any) => history.push(`/estimates/${row.id}`)}
          {...props.table}
          pageCount={props.pageCount}
        />
      ) : (
        <Placeholder
          type="estimate"
          header={tr("No estimate yet")}
          label={tr("When you add estimates to the request, they will appear here.")}
        />
      )}
    </div>
  );
}
