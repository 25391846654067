import { useTranslation } from 'react-i18next';

const useFormUtils = () => {
    const { t } = useTranslation();
    const tBase = "views.repair_request.components.form.form-utils";
    const tr = (key) => t(`${tBase}.${key}`);

    const lockoutTagout = [
        { label: tr("Yes"), value: true },
        { label: "No", value: false }
    ];

    const requestPriority = [
        { label: tr("Low - When you get a chance"), value: 1 },
        { label: tr("Medium - Within the week"), value: 2 },
        { label: tr("High - Must be done in 24hrs"), value: 3 }
    ];

    return { lockoutTagout, requestPriority };
};

export default useFormUtils;
