import { SubtleButton } from "components/button";
import styled from "styled-components";
import { theme } from "styles/theme";

export const Con = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TabCon = styled.div`
  display: flex;
  flex: 0 0 auto;
  // border-bottom: 2px solid black;
  border-bottom: 1px solid ${theme.border.light};
  overflow-x: auto;
  overflow-y: hidden;
`;

export const TabButton = styled.div<{ isSelected?: boolean }>`
  border-radius: 5;
  position: relative;
  outline: none;
  color: ${(props) =>
    props.isSelected ? theme.color.main : theme.color.subtext2};
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 0.86rem;
  &:hover {
    color: ${(props) =>
      props.isSelected ? theme.color.main : theme.color.subtext2};
    background: ${(props) =>
      props.isSelected ? "transparent" : theme.bg.blue};
  }

  &::before {
    content: "";
    width: 100%;
    height: 4px;
    background: ${(props) =>
      props.isSelected ? theme.color.main : "transparent"};
    position: absolute;
    bottom: -1px;
    transition: all 0.1s linear;
    /* border-radius: 25px 25px 0 0; */
  }
`;

export const ContentCon = styled.div`
  padding: 0.5rem 0;
  flex: 1;
`;
