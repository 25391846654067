import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";

type Props = {
  title?: React.ReactNode;
  buttons?: React.ReactNode[];
  style?: {
    header?: {};
    content?: {};
    footer?: {};
  };
  children?: React.ReactNode;
  footer?: React.ReactNode;
};
export default function FlatContainer(props: Props) {
  return (
    <Con>
      {(props.title || props.buttons) && (
        <SHeader style={props.style?.header}>
          {typeof props.title === "string" ? (
            <h2>{props.title}</h2>
          ) : (
            props.title
          )}
          <div>
            {props.buttons &&
              props.buttons.map((b, i) => (
                <React.Fragment key={i}>{b}</React.Fragment>
              ))}
          </div>
        </SHeader>
      )}
      {props.children && (
        <SContent style={props.style?.content}>{props.children}</SContent>
      )}
      {props.footer && (
        <SFooter style={props.style?.footer}>{props.footer}</SFooter>
      )}
    </Con>
  );
}

const Con = styled.div``;

const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.border.light};
  padding: 1rem 0;
  align-items: center;
  > div:last-child {
    display: flex;
    flex: 0 0 auto;
    margin-left: 1rem;
    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  > *:first-child {
    flex: 1;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    > div:last-child {
      margin-top: 1rem;
      justify-content: fill;
      flex: 1;
      margin-left: 0;
      > * {
        flex: 1;
      }
    }
  }
`;

const SFooter = styled(SHeader)``;

const SContent = styled.div`
  padding: 1rem 0;
`;
