import React, { useState, useEffect } from "react";
import axios from "axios";
import WinterizationTable from "./winterization-table";
import "./index.scss";
import { useTranslation } from 'react-i18next';

const Winterization = () => {
	const { t } = useTranslation();
	const tBase = "views.winterization.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [searchTerm, setSearchTerm] = useState("");

	const getDate = () => {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();

		return `${yyyy}-${mm}-${dd}`;
	};

	const [date, setDate] = useState(getDate());

	const handleDateChange = (e) => {
		setDate(e.target.value);
	};

	const handleSearchTermChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const [winterizations, setWinterizations] = useState([]);
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [labels, setLabels] = useState([]);

	const fetchWinterizationJobs = async () => {
		try {
			if (date) {
				const response = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/winterization/winterizationJobList/${date}`
				);

				if (
					response.status === 200 ||
					(response.status === 304 && response.data.length > 0)
				) {
					setWinterizations(response.data.rows);
					setLabels(response.data.labels);
				} else {
					setWinterizations([]);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const checkTechs = (techs) => {
		if (!techs) {
			return false;
		}

		const result = techs.filter((tech) =>
			(tech.first_name + " " + tech.last_name)
				.toString()
				.toLowerCase()
				.includes(searchTerm.toString().toLowerCase())
		);

		if (result.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const filterJobs = () => {
		let newJobs = [];

		winterizations.map((winterization) => {
			if (
				searchTerm === "" ||
				winterization.service_fusion_id
					.toString()
					.toLowerCase()
					.includes(searchTerm.toString().toLowerCase()) ||
				winterization.customer_name
					.toString()
					.toLowerCase()
					.includes(searchTerm.toString().toLowerCase()) ||
				checkTechs(winterization.techs)
			) {
				newJobs.push(winterization);
			}

			return winterization;
		});

		if (newJobs.length > 0) {
			setFilteredJobs(newJobs);
		} else {
			setFilteredJobs([]);
		}
	};

	useEffect(() => {
		fetchWinterizationJobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	useEffect(() => {
		filterJobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [winterizations, searchTerm]);

	return (
		<div className="winterization__container">
			<div className="winterization__search">
				<input
					className="winterization__search-date"
					type="date"
					value={date}
					onChange={handleDateChange}
				/>
				<input
					className="winterization__search-input"
					value={searchTerm}
					onChange={handleSearchTermChange}
					placeholder={tr("Filter results...")}
				/>
			</div>
			{filteredJobs.length > 0 ? (
				<WinterizationTable
					labels={labels}
					winterizations={filteredJobs}
				/>
			) : (
				<div className="winterization__no-results">{tr("No Results")}</div>
			)}
		</div>
	);
};

export default Winterization;
