import React, { useState } from "react";
import styled, { css } from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import { MdMoreVert, MdClose, MdCake } from "react-icons/md";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { applySick, remove } from "./action";
// import { team } from '../actions'
import { useDispatch } from "react-redux";
import { fetching } from "./action";
import Dropdown from "components/dropdown2";
import socket from "./socket";
import { format } from "date-fns";
import birthday from "./birthday.png";
import { SubtleIconButton } from "components/button";
import { BsFillPersonDashFill } from "react-icons/bs";
import { useAreYouSure } from "components/areYouSure";

const MainCon = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #002c37;
  margin: 0.2rem 0 0.2rem 0;
  border-radius: 3px;
  padding: 0 0.1rem 0 1rem;
  justify-content: center;
  ${(props) =>
    !props.editable &&
    css`
      // background-color: #265527;
      border: 1px solid #a3ffab;
    `}
  ${(props) =>
    props.disabled &&
    css`
      // background-color: #5F3333;
      border: 1px solid red;
      background: linear-gradient(
        to bottom right,
        rgb(0, 44, 55, 1),
        rgb(206, 0, 0, 0.3)
      );
    `}
`;

const UserCon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 0.3rem 0 0.3rem 0;
  border-radius: 5px;
  color: white;
  position: relative;
  overflow: hidden;
  width: 100%;
  // z-index: 10;
  ${(props) =>
    props.birthday &&
    `
    // background: linear-gradient(to right, red, transparent);
    background-image: url(${birthday});
    background-size: cover;
    `}
`;

const More = styled(MdMoreVert)`
  position: absolute;
  left: -1rem;
  &:hover {
    cursor: pointer;
  }
`;

const Birthday = styled(MdCake)`
  position: absolute;
  display: ${(props) => props.birthday};
  bottom: 0.1rem;
  left: 1.5rem;
  color: #ff5f5f;
`;

const UserDisc = styled.div`
  font-size: 0.8rem;
  margin-left: 0.5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  > div:first-child {
    font-size: 1rem;
  }
  > div:last-child {
    color: #dfdfdf;
  }
`;

const Child = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  margin-left: 0.1rem;
  flex-direction: column;
  margin-top: 1px;
`;

const ChildCon = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  // border-top: 1px solid grey;
  // background: white;
  max-width: 100%;
`;
// const Arrow = styled(MdKeyboardArrowRight)`
//     position: absolute;
//     font-size: 1.5rem;
//     left: -1rem;
//     top: calc( 50% - 0.75rem );

// `

const Close = styled(MdClose)`
  /* position: absolute; */
  /* right: 0; */
  /* &:hover {
    cursor: pointer;
  } */
`;

// const color = ['#FF885D','#5D9AFF','#00994A']
// const pictures = ['./Test2.png','./Test.png','./Tim Welch.jpg','./Josh Roberts.jpg','./Matt Oliver.jpg']

export default function (props) {
  let {
    employeeData,
    isNotChild,
    children,
    images,
    editable,
    data,

    parentId,
  } = props;

  let [
    {
      employee_id: employeeId = "",
      employee_job_title: jobTitle = "",
      employee_name: name = "",
      employee_birth_date: birthDate = "",
      is_sick,
      vehicles: vehicles = [],
    },
    categoryId,
  ] = data;

  employeeId = employeeId.toString();
  // jobTitle = jobTitle.toString();
  name = name.toString();

  const [dropdown, showDropdown] = useState(false);
  const isToday =
    birthDate &&
    format(new Date(birthDate + "T00:00"), "MM-dd") ===
      format(new Date(), "MM-dd");

  const dispatch = useDispatch(),
    areYouSure = useAreYouSure();

  const removeChild = () => {
    dispatch(remove(categoryId, +parentId, +employeeId, employeeData));
    fetching(
      "REMOVECHILD",
      employeeId,
      null,
      employeeData[0]["category_id"],
      props.parentId,
      null,
      props.index,
      null
    );
    socket.emit("removechild", [categoryId, +parentId, +employeeId]);
  };

  const childList = children ? (
    children.isArray ? (
      children.map((e) => {
        return <ChildCon>{e}</ChildCon>;
      })
    ) : (
      <ChildCon>{children}</ChildCon>
    )
  ) : null;

  const isChild = isNotChild ? (
    <Droppable type="droppable" droppableId={employeeId + "-" + categoryId}>
      {(provided) => (
        <Child ref={provided.innerRef} {...provided.droppableProps}>
          {childList ? childList : <div></div>}
          {provided.placeholder}
        </Child>
      )}
    </Droppable>
  ) : null;

  const dropdownClicked = (e) => {
    showDropdown(!dropdown);
  };

  const markSick = () => {
    dispatch(applySick("Absent", +employeeId, employeeData));
    socket.emit("applysick", {
      category: "Absent",
      employeeId: employeeId,
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString(),
    });
  };

  return (
    <Draggable
      isDragDisabled={editable || is_sick}
      draggableId={employeeId}
      index={props.index}
    >
      {(provided) => (
        <MainCon
          editable={editable}
          disabled={is_sick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <UserCon birthday={isToday}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <More onClick={() => showDropdown(!dropdown)} />
                <Avatar
                  src={images}
                  style={{ color: "white", background: "grey" }}
                >
                  {name.match(/\b(\w)/g).join("")}
                </Avatar>
                <Birthday birthday={isToday ? "block" : "none"} />
                <UserDisc>
                  <div>{name}</div>
                  <div>{jobTitle}</div>
                </UserDisc>
                <div className="flex flex-col justify-self-end">
                  {!editable && !is_sick && (
                    <SubtleIconButton
                      size="xxsmall"
                      className="!justify-end"
                      onClick={() => areYouSure.activate(markSick)}
                    >
                      <BsFillPersonDashFill className="text-white" />
                    </SubtleIconButton>
                  )}
                  {isNotChild || editable ? null : (
                    <SubtleIconButton
                      size="xxsmall"
                      onClick={() => removeChild()}
                    >
                      <Close className="text-white" />
                    </SubtleIconButton>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 2,
                }}
              >
                {vehicles &&
                  vehicles.length > 0 &&
                  vehicles?.map((vehicle) => {
                    return (
                      <div
                        style={{
                          border: "0.5px solid lightgray",
                          padding: 1,
                          paddingLeft: 4,
                          paddingRight: 4,
                          marginTop: 2,
                          marginRight: 2,
                          borderRadius: 10,
                          fontSize: 11,
                          color: "lightgray",
                        }}
                      >
                        {vehicle.vehicle_label}
                      </div>
                    );
                  })}
              </div>
            </div>

            {dropdown ? (
              <Dropdown setContainer={(e) => dropdownClicked(e)}>
                <div>Edit</div>
                <div>Edit</div>
                <div>Edit</div>
              </Dropdown>
            ) : null}
          </UserCon>
          {isChild}
        </MainCon>
      )}
    </Draggable>
  );
}
