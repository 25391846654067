import { ILocation } from "../types/ILocation";

const extractLocations = (responseData: any): ILocation[] => {
	const locations: ILocation[] = [];

	// console.log("extractLocations", responseData);

	for (const assigneeId in responseData) {
		const assigneeData = responseData[assigneeId];
		const appointments = assigneeData.appointments;

		for (const appointmentId in appointments) {
			const appointment = appointments[appointmentId];
			// console.log("appointment", appointment);
			const { latitude, longitude } = appointment;

			if (latitude && longitude) {
				locations.push({
					latitude,
					longitude,
				});
			}
		}
	}

	// console.log("locations extracted", locations);

	return locations;
};

export { extractLocations };
