/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import { Button } from "components/button";
import { RiCheckLine, RiCloseLine, RiUserAddLine } from "react-icons/ri";
import Table, { useTable } from "components/table";
import { Avatar } from "components/avatar";
import { cloneDeep } from "lodash";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;
const Title = styled.div`
  display: flex;
  padding-left: 0.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export default function () {
  const { t } = useTranslation();
  const tBase = "views.team.edit.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [inactive, setInactive] = useState(false);
  const table = useTable();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team/edit`,
          {
            params: {
              type: "ALL",
            },
          }
        );
        setUsers(res.data);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let { column, order } = table.sort;

    if (column === "name") {
      column = "first_name";
    }

    setUsers((users) => {
      let tempUsers = cloneDeep(users);
      tempUsers.sort((a, b) => {
        if (a[column] === null) return 1;
        if (b[column] === null) return -1;
        if (a[column] > b[column]) return order === "DESC" ? -1 : 1;
        if (a[column] < b[column]) return order === "DESC" ? 1 : -1;
        return 0;
      });
      return tempUsers;
    });
  }, [table.sort]);

  const showInactive = () => {
    setInactive(!inactive);
  };

  const columns = {
    name: {
      label: tr("Name"),
      cell: (row) => (
        <Avatar showLabel>{row.first_name + " " + row.last_name}</Avatar>
      ),
    },
    employee_job_title: {
      label: tr("Job Title"),
    },
    employee_status: {
      label: tr("Status"),
      style: {
        fontSize: "1.5rem",
      },
      cell: (row) =>
        row.employee_status === "Active" ? (
          <RiCheckLine style={{ color: "green" }} />
        ) : (
          <RiCloseLine style={{ color: "red" }} />
        ),
    },
  };

  const handleClick = (row) => {
    history.push(`/team/viewuser/${row["employee_id"]}`);
  };

  return (
    <Con>
      <Title>
        <h1>{tr("Employee")}</h1>
        <Button
          style={{ marginLeft: "auto" }}
          primary
          onClick={() => history.push("./newuser")}
        >
          <RiUserAddLine /> {tr("Add employee")}
        </Button>
      </Title>
      <div>
        {tr("Show Inactive")}
        <Switch checked={inactive} onChange={() => showInactive()} />
      </div>
      <Table
        {...{ ...table }}
        columns={columns}
        handleClick={handleClick}
        data={users?.filter((e) => {
          return inactive ? true : e["employee_status"] !== "Inactive";
        })}
      />
    </Con>
  );
}
