import React, { useContext, useEffect, useRef, useState } from "react";
import Map, { MapRef, Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useTranslation } from 'react-i18next';

import {
  PropertyMapContext,
  PropertyMapProvider,
} from "./context/property-map-context";
import { FaCheck, FaHome, FaImage, FaMapMarker, FaTimes } from "react-icons/fa";
import { Button } from "components/button";

import { __mapBoxToken__ } from "../../../constants";
import { basemaps } from "./constants/property-map-constants";
import { theme } from "styles/theme";
import Spinner from "components/spinner";
import { updateMarker } from "./api/updateMarker";
import { usePropertyData } from "./hooks/usePropertyData";
import { TMapMarker, TPropertyMapResponse } from "./types/TPropertyMapData";
import { PropertyMapToolBar } from "./components/toolbar/property-map-toolbar.component";
import { useQueryClient } from "react-query";
import { useSnackbarContext } from "components/snackbar";
import { map } from "lodash";

type Props = {
  propertyId: number;
};

type TPropertyMapContainerProps = {
  data: TPropertyMapResponse | null;
};

const PropertyMap = ({ propertyId }: Props) => {
  const { t } = useTranslation();
  const tBase = "views.properties";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { isLoading, data, isError } = usePropertyData(propertyId);

  if (isLoading) return <Spinner />;

  if (isError) return <div>{tr("Something went wrong.")}</div>;

  return (
    <>
      <PropertyMapProvider>
        <PropertyMapContainer data={data} />
      </PropertyMapProvider>
    </>
  );
};

const PropertyMapContainer = ({ data }: TPropertyMapContainerProps) => {
  const { t } = useTranslation();
  const tBase = "features.production";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const initialZoom = 18;
  const mapRef = useRef<MapRef | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { selectedBasemapId } = useContext(PropertyMapContext);
  const [selectedMarkerId, setSelectedMarkerId] = useState<number | null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [label, setLabel] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbarContext();
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);
  const [polygonVertices, setPolygonVertices] = useState<number[][]>([]);
  const [polygonArea, setPolygonArea] = useState<number | null>(null);

  const handleClickMarker = (markerId: number) => {
    if (markerId) {
      setShowPopup(false);
      setSelectedMarkerId(markerId);
      setShowPopup(true);
    }
    setShowPopup(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClickSave = async () => {
    if (selectedMarkerId) {
      setSaveStatus("loading");
      const formData = new FormData();
      if (selectedFile) formData.append("image", selectedFile);
      formData.append("label", label);

      console.log("saving selectedFile & label...", selectedFile, "\n", label);

      try {
        const response = await updateMarker(selectedMarkerId, formData);

        if (response.status === 200) {
          await queryClient.invalidateQueries("property-map-data");
          showSnackbar(tr("Marker updated successfully."));
          setSaveStatus("success");
        } else {
          showSnackbar(tr("Something went wrong."));
          setSaveStatus("error");
        }
      } catch (error) {
        console.error(error);
        setSaveStatus("error");
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedMarkerId(null);
    setSelectedFile(null);
    setLabel("");
    setSaveStatus("idle");
  };

  useEffect(() => {
    setSaveStatus("idle");
  }, [selectedMarkerId, selectedFile, label]);

  useEffect(() => {
    if (selectedMarkerId) {
      const marker = data?.data?.MapMarkers.filter(
        (marker: TMapMarker) => marker.id === selectedMarkerId
      )[0];
      if (marker) {
        setLabel(marker.label ? marker.label : "");
      }
    }
  }, [selectedMarkerId]);

  useEffect(() => {
    const map = mapRef.current?.getMap();

    const draw = new MapboxDraw({
      displayControlsDefault: true,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    if (map) {
      map.addControl(draw);
    }

    // Define event listeners for draw.create and draw.update events
    const calculateArea = (e: any) => {
      const feature = e.features[0];
      if (feature.geometry.type === "Polygon") {
        // Use Turf to calculate the area in square meters
        const area = turf.area(feature);
        // Convert area to a more readable format (e.g., square kilometers)
        const roundedArea = Math.round(area * 100) / 100; // Adjust rounding as necessary
        setPolygonArea(roundedArea);
        console.log(`Polygon area: ${roundedArea} square meters`);
      }
    };

    map?.on("draw.create", calculateArea);
    map?.on("draw.update", calculateArea);

    // Cleanup function to remove the draw control and event listeners
    return () => {
      if (map) {
        map.removeControl(draw);
        map.off("draw.create", calculateArea);
        map.off("draw.update", calculateArea);
      }
    };
  }, [mapRef]);

  if (!data) {
    return (
      <>
        <div>
          {tr("No property map data found. Please ensure the address has a valid latitude and longitude.")}
        </div>
      </>
    );
  }

  const { data: mapData } = data;
  const selectedMarker = mapData.MapMarkers.filter(
    (marker: TMapMarker) => marker.id === selectedMarkerId
  )[0];

  return (
    <>
      <>
        {/* Display polygon area if available */}
        {polygonArea && (
          <div>
            <p>{tr("Polygon Area")}: {polygonArea} {tr("square meters")}</p>
          </div>
        )}
      </>
      <Map
        ref={mapRef}
        mapboxAccessToken={__mapBoxToken__}
        initialViewState={{
          longitude: mapData && mapData.longitude ? mapData.longitude : 0,
          latitude: mapData && mapData.latitude ? mapData.latitude : 0,
          zoom: initialZoom,
        }}
        mapStyle={
          basemaps.filter((b) => b.id === selectedBasemapId)[0].uri ||
          basemaps[0].uri
        }
        style={{ width: "100%", height: "72vh" }}
      >
        {mapData && mapData.latitude && mapData.longitude && (
          <Marker
            longitude={mapData.longitude}
            latitude={mapData.latitude}
            anchor="center"
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: "none",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow: "0 0 0 2px #fff, 0 0 0 4px #00000066",
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
              }}
            >
              <FaHome color={theme.color.main} size={25} />
            </div>
          </Marker>
        )}

        {mapData &&
          mapData.MapMarkers &&
          mapData.MapMarkers.map(
            (marker: TMapMarker) =>
              marker.latitude &&
              marker.longitude && (
                <Marker
                  key={marker.id}
                  longitude={marker.longitude}
                  latitude={marker.latitude}
                  anchor="center"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundImage: "none",
                    backgroundColor: marker.color || "white",
                    borderRadius: "50%",
                    boxShadow: "0 0 0 2px #fff, 0 0 0 4px #00000066",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClickMarker(marker.id)}
                >
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <FaMapMarker color={theme.color.main} size={25} />
                  </div>
                </Marker>
              )
          )}

        {showPopup &&
          selectedMarkerId &&
          selectedMarker &&
          selectedMarker.latitude &&
          selectedMarker.longitude && (
            <Popup
              latitude={selectedMarker.latitude}
              longitude={selectedMarker.longitude}
              onClose={handleClosePopup}
              closeOnClick={false}
              closeButton={false}
              style={{
                minWidth: "300px",
                paddingLeft: "2px",
                paddingRight: "2px",
              }}
            >
              <div className="relative">
                <button
                  onClick={handleClosePopup}
                  className="absolute text-gray-500 top-2 right-2 hover:text-gray-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {/* {console.log("selectedMarker", selectedMarker)} */}

                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt={label}
                    className="object-cover w-full h-64 rounded-md cursor-pointer hover-effect"
                    onClick={() => fileInputRef.current?.click()}
                  />
                ) : selectedMarker.thumbnail_url ? (
                  <img
                    src={selectedMarker.thumbnail_url}
                    alt={selectedMarker.label ? selectedMarker.label : "Image"}
                    onError={(e) => {}}
                    className="object-cover w-full h-64 rounded-md cursor-pointer hover-effect"
                    onClick={() => fileInputRef.current?.click()}
                  />
                ) : (
                  <div
                    className="flex items-center justify-center w-full h-64 bg-gray-100 rounded-md cursor-pointer hover-effect"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <FaImage size={48} />
                  </div>
                )}
                <input
                  type="text"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  className="w-full p-1 mt-4 text-lg font-semibold border rounded-md"
                  placeholder={tr("Label")}
                />
                {/* <p className="mt-2 text-sm text-gray-500">
                  {selectedMarker.description}
                </p> */}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div className="flex flex-row items-center justify-end">
                  <Button
                    primary={saveStatus === "success" ? false : true}
                    className="mt-4"
                    onClick={handleClickSave}
                  >
                    {tr("Save")}
                    {saveStatus === "success" && <FaCheck color="green" />}
                    {saveStatus === "error" && <FaTimes color="red" />}
                  </Button>
                </div>
              </div>
            </Popup>
          )}

        <PropertyMapToolBar
          mapRef={mapRef}
          mapData={mapData}
          isDrawingPolygon={isDrawingPolygon}
          setIsDrawingPolygon={setIsDrawingPolygon}
          polygonVertices={polygonVertices}
          setPolygonVertices={setPolygonVertices}
        />
      </Map>
    </>
  );
};

export default PropertyMap;
