import styled, { keyframes } from "styled-components";

export const Svg = styled.svg``;

export const Line = styled.line``;

export const Animation = keyframes`
  to {
    stroke-dasharray: 2000 0;
    stroke-dashoffset: 0;
  }
`;

export const Path = styled.path`
  stroke-dasharray: 0 2000;
  stroke-dashoffset: 0;
  animation: ${Animation} 1s ease-in-out forwards;
`;

export const Con = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 300px;
  > div {
    &:first-child {
      flex: 0 0 2rem;
    }
    &:nth-child(2) {
      flex: 0 0 1.2rem;
    }
    &:nth-child(3) {
      flex: 1;
    }
    &:last-child {
      flex: 0 0 2rem;
    }
  }
`;

export const TitleCon = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  > div:last-child {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 0.25em;
  }
`;

export const SvgCon = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const AxisCon = styled.div`
  display: flex;
  justify-content: space-between;
  color: grey;
  font-size: 0.8rem;
  margin: 0.5em 0;
`;

export const HoverCon = styled.div<{ left: string }>`
  width: 100%;
  flex: 0 0 1.2rem;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  > div {
    right: 0;
    font-size: 0.8rem;
    top: 0;
    color: grey;
    position: sticky;
    left: ${(props) => props.left};
  }
`;

export const Percent = styled.span<{
  status: "negative" | "positive" | "zero";
}>`
  background: ${(props) =>
    props.status ? "rgb(148, 49, 38,0.1)" : "rgba(20, 90, 50, 0.1)"};
  color: ${(props) => (props.status ? "rgb(148, 49, 38)" : "rgb(20, 90, 50)")};
  font-size: 0.8rem;
  border-radius: 3px;
  padding: 0.25em;
  ${(props) =>
    props.status === "negative"
      ? `
        background: rgba(148,49,38,0.1);
        color: rgb(148,49,38);
        `
      : props.status === "positive"
      ? `
        background: rgba(20, 90, 50, 0.1);
        color:rgb(20, 90, 50);
        `
      : `
        background: rgba(0,0,0,0.1);
        color: rgb(0,0,0);
        `}
`;
