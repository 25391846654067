import axios from "axios";
import Spinner from "components/spinner";
import PaymentsList from "containers/payments";
import * as React from "react";
import { useQuery } from "react-query";
import { TPayment } from "types/payment";
import Placeholder from "views/request/component/placeholder";
import { useHistory } from "react-router-dom";
import useQueryString from "hooks/useQueryString";
import { useTable } from "components/table";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function CustomerPaymentsTab(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.customer.containers.payments";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const queryString = useQueryString();
  const search = useQueryString();

  const table = useTable({
    url: true,
    onPaginate: (page) => search.update({ ...search.search, page }),
    pageIndex:
      search.search.page && !Array.isArray(search.search.page)
        ? +search.search.page
        : 1,  
  });

  // ensures that page 1 is used, probably could use a better implementation
  React.useEffect(() => {
    search.update({ ...search.search, page: 1 });
  }, []);
  
  const query = useQuery<{ payments: TPayment[] | null; count: number }>(
    ["payments", "customer", props.id, search.search],
    async () =>
      await axios
        .get(
        `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/payments?type=customer&id=${props.id}&page=${search.search.page}
        `
        )
        .then((res) => res.data),
        {
          onSuccess: (data:any) => {
            table.setPageCount(Math.floor(data.count / 50));
          }
        }
  );

  if (query.isLoading) return <Spinner />;

  return (
		<div className="divide-y">
			<div className="py-4">
				<h2>
					{tr("Payments")}
				</h2>
			</div>
			<div className="py-4">
				{query.data ? (
					<PaymentsList data={query.data.payments} type="customer" table={table} />
				) : (
					<Placeholder
						header={tr("No Payments")}
						label={tr("Payments will appear here when added.")}
						type="payment"
					/>
				)}
			</div>
		</div>
  );
}
