import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";

const Con = styled.div`
  max-width: 500px;
  border-radius: 5px;
  height: 100%;
  max-height: 500px;
  width: 100%;
  background: #f3f3f3;
  align-items: center;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: white;
  // padding: 1rem;
  // margin: 0;
  > div:nth-child(2) {
    border: 1px solid black;
    border-radius: 5px;
    padding: 1rem;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
    max-height: 500px;
  }
`;

// const Buttons = styled.button`
//     padding: 0.5rem 10rem 0.5rem 10rem;
//     align-self: center;
//     font-size: 1.5rem;
//     background: white;
//     border: 1px solid grey;
//     border-radius: 25px;
//     margin-top: auto;
//     margin-bottom: 2rem;
// `

export default function (props) {
  const [crop, setCrop] = useState({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
    x: 25,
    y: 25,
  });
  const [upImg, setUpImg] = useState();
  // const [previewUrl, setPreviewUrl ] = useState();
  // const [imageBlob, setBlob ] = useState();

  const imgRef = useRef(null);

  const onLoad = (img) => {
    imgRef.current = img;
  };

  const cropCompletion = async () => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, "newFile.jpeg");
    }
  };

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = fileName;
        props.updateBlob(blob);
      });
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const renderImage = async () => {
  //     var file = new File([imageBlob], 'abc.png')
  //     const formData = new FormData();
  //     formData.append('myImage', file);
  //     formData.append('userid', 'Hello')
  //     try {
  //         const res = await axios.post('/api/team/pic', formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data'
  //             }
  //         });
  //         props.closeContainer();
  //     } catch (e) {
  //         console.error(e)
  //     }
  // }

  return (
    <Con>
      {/* <h3>Upload an image</h3> */}
      <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <div>
        {upImg ? (
          <ReactCrop
            ruleOfThirds
            circularCrop
            onChange={(newCrop, percentCrop) => setCrop(newCrop)}
            onImageLoaded={(img) => onLoad(img)}
            crop={crop}
            onComplete={() => cropCompletion()}
            imageStyle={{ maxHeight: "400px", maxWidth: "400px" }}
            src={upImg}
          />
        ) : (
          <span style={{ color: "grey" }}>Upload an image</span>
        )}
      </div>
      {/* <Buttons onClick={() => renderImage()}>Submit</Buttons> */}
    </Con>
  );
}
