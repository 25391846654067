import React, { useEffect, useState } from "react";
import { Avatar } from "components/avatar";
import { RiPencilLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { theme } from "styles/theme";
import axios from "axios";
import OverviewCustomField from "./overview-custom-field";
import { useQuery } from "react-query";
import CustomFields from "./customFields";
import DataLabel from "components/dataLabel";
import { NoStyleA } from "components/button";
import { useTranslation } from 'react-i18next';

const Overview = (props) => {
  const { t } = useTranslation();
  const tBase = "views.property.overview";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const propertyId = props.propertyId;
  const [propertyDetails, setPropertyDetails] = useState({});

  const query = useQuery(
    ["property", propertyId],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/${propertyId}
  `
        )
        .then((res) => res.data)
  );

  return (
    <div>
      <div
        style={{
          marginTop: 10,
          paddingBottom: 20,
          borderBottom: `1px solid ${theme.border.light}`,
        }}
      >
        <h3>{tr("Associated Accounts")}</h3>
      </div>
      <div className="flex space-x-8 py-4">
        <DataLabel title={tr("Owner")}>
          {query.data?.owner ? (
            <NoStyleA to={`/customers/${query.data.owner.id}`}>
              <Avatar showLabel={true}>{query.data?.owner?.name}</Avatar>
            </NoStyleA>
          ) : (
            <span>{tr("No owner assigned")}</span>
          )}
        </DataLabel>
        <DataLabel title="Billed To">
          {query.data?.billed_to ? (
            <NoStyleA to={`/customers/${query.data.billed_to.id}`}>
              <Avatar showLabel={true}>{query.data?.billed_to?.name}</Avatar>
            </NoStyleA>
          ) : (
            <span>{tr("No billing account assigned")}</span>
          )}
        </DataLabel>
      </div>

      {propertyDetails.customers &&
        propertyDetails.customers?.map((customer) => {
          return (
            <Link
              key={customer.id}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                marginTop: 20,
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: `1px solid ${theme.border.light}`,
                paddingBottom: 20,
              }}
              to={`/customers/${customer.id}`}
            >
              <Avatar
                showLabel={true}
              >{`${customer.first_name} ${customer.last_name}`}</Avatar>
            </Link>
          );
        })}

      <CustomFields id={propertyId} />
    </div>
  );
};

export default Overview;
