import React from "react";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import "./search-box.styles.css";

export const SearchBox = ({ placeholder, handleChange }) => {
  return (
    <div className="search-row">
      <SearchOutlinedIcon />
      <input
        className="search"
        type="search"
        placeholder={placeholder}
        onChange={handleChange}
      />
    </div>
  );
};
