import * as React from "react";
import styled from "styled-components";
import Form from "components/form2";
import { Input } from "components/input";
import { Button, OutlineButton } from "components/button";
import TextArea from "components/textarea";
import AssigneeSelect from "components/react-select/assignees";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "contexts/auth";
import { RiCheckLine } from "react-icons/ri";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';

type TUser = {
  emai: string;
  id: number;
  name: string;
};
type ReminderEdit = {
  date: string;
  time: string;
  label: string;
  users: null | TUser[];
};

type Reminder = {
  id: string;
  label: string;
  name: string;
  reminder_at: string;
  users: null | TUser[];
};

type Props = {
  id: number;
  type: string;
  reminderId?: string;
};

export default function NewReminder({ id, type, reminderId }: Props) {
  const { t } = useTranslation();
  const tBase = "views.request.container.reminders.newReminder";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { user_id, name } = useAuthContext();
  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();

  const query = useQuery<Reminder>(["reminder", id, reminderId], async () =>
    reminderId
      ? await axios
          .get(
            `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/${reminderId}
  `
          )
          .then((res) => res.data)
      : null
  );

  const [exDate, exTime] = query.data?.reminder_at
    ? format(new Date(query.data?.reminder_at || ""), "yyyy-MM-dd kk:mm").split(
        " "
      )
    : ["", ""];

  const addReminder = useMutation(
    async (body: any) =>
      await axios.post(
        `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/${type}/${id}
        `,
        body
      ),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Reminder added!"));
        queryClient.invalidateQueries("reminders");
      },
    }
  );

  const deleteReminder = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/reminders/${reminderId}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        setModal();
        showSnackbar(tr("Reminder deleted!"));
        queryClient.invalidateQueries("reminders");
      },
    }
  );

  const initialValues: ReminderEdit = {
    date: exDate || "",
    time: exTime || "",
    label: query.data?.label || "",
    users:
      query.data?.users?.map((user) => ({
        ...user,
        value: user.id.toString(),
        label: user.name,
      })) || null,
  };

  const onSubmit = (e: any) => {
    const timestamp = new Date(e.date + " " + e.time);
    addReminder.mutate({ ...e, timestamp });
  };

  const validationSchema = Yup.object({
    date: Yup.string().required(),
    time: Yup.string().required(),
    label: Yup.string().required(),
    users: Yup.array(Yup.object()),
  });

  const formik = useFormik<ReminderEdit>({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleAddMyself = () => {
    if (Array.isArray(formik.values.users)) {
      const users: any = [...formik.values.users];

      const findMyself = users.findIndex((user: any) => user.value === user_id);

      if (findMyself === -1) {
        users.push({ value: user_id, label: name });
      } else {
        users.splice(findMyself, 1);
      }
      formik.setFieldValue("users", users);
    } else {
      formik.setFieldValue("users", [{ value: user_id, label: name }]);
    }
  };

  const isMyselfSelected =
    formik.values.users &&
    formik.values.users.findIndex((user: any) => user.value === user_id) !== -1;

  return (
    <Con>
      <SForm formik={formik}>
        <div>
          <Input
            style={{ marginRight: "1rem" }}
            autoFocus
            name="date"
            type="date"
            label={tr("Date")}
          />
          <Input
            name="time"
            type="time"
            label={tr("Time")}
            // formatOn={"blur"}
            // formatter={formatTime}
          />
        </div>

        <div>
          <AssigneeSelect assigneeType="all" label={tr("Assign to")} name="users" />
          <MyselfButton
            size="small"
            selected={isMyselfSelected}
            onClick={handleAddMyself}
          >
            {isMyselfSelected && <RiCheckLine />}
            <span>{tr("Myself")}</span>
          </MyselfButton>
        </div>

        <TextArea name="label" minRows={3} placeholder={tr("Description")} />
        <div className="flex justify-end space-x-2">
          {reminderId && (
            <OutlineButton
              onClick={() => deleteReminder.mutate()}
              type="button"
            >
              {tr("Delete")}
            </OutlineButton>
          )}

          <Button
            disabled={!formik.isValid || !formik.dirty}
            type="submit"
            primary
            style={{ alignSelf: "flex-end" }}
          >
            {tr("Save Changes")}
          </Button>
        </div>
      </SForm>
    </Con>
  );
}

const SForm = styled(Form)`
  display: flex;
  flex-direction: column;
  > div:first-child {
    display: flex;
    > div {
      width: 100%;
    }
  }
  > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const MyselfButton = styled(OutlineButton)<{ selected: boolean | null }>`
  background: ${(props) => (props.selected ? "#D4EFDF" : "transparent")};
  color: ${(props) => (props.selected ? "green" : "black")};
  border-radius: 25px;
  line-height: 1;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
  outline: none;
`;

const Con = styled.div`
  min-width: 100%;
  width: 500px;
`;
