import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { SItem } from "../style";
import type { Multiplier, QuoteCategory } from "../index";
import { dollarFormatter } from "utils";
import { MultiplierReturnValues } from "utils/quote";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';

type Props = {
  data: Multiplier[] | null;
  total: {
    list_price: number;
    sale_price: number;
  };
  category?: { [catId: string]: QuoteCategory };
  multipliers: MultiplierReturnValues[] | null;
  multiplierTotal: { list_price: number; sale_price: number };
};

export default function Total({
  data,
  total,
  multipliers,
  multiplierTotal,
  category,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.total";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const dimensions = useWindowDimensions();

  const populateMultiplier =
    multipliers &&
    multipliers.map((multiplier) => {
      const populateCategories =
        category &&
        multiplier.fk_category_id?.map((catId, i) => (
          <span key={catId}>
            {i !== 0 && " | "} {category[catId].label}
          </span>
        ));

      return (
        <SMultiplerCon key={multiplier.id} hide={dimensions.width < 800}>
          <div />
          <div>
            <div>{multiplier.label}</div>
            <div>
              {populateCategories ||
                (multiplier.all_category && <span>All Category</span>)}
            </div>
          </div>
          <div>{multiplier.rate}%</div>
          <div>
            {multiplier.is_list && dollarFormatter(multiplier.list_price)}
          </div>
          <div />
          <div>
            {multiplier.is_sale && dollarFormatter(multiplier.sale_price)}
          </div>
        </SMultiplerCon>
      );
    });

  return (
    <Con>
      <SMultiplerCon hide={dimensions.width < 800}>
        <div />
        <div>{tr("Sub Total")}</div>
        <div />
        <div>{dollarFormatter(total.list_price)}</div>
        <div />
        <div>{dollarFormatter(total.sale_price)}</div>
      </SMultiplerCon>
      {populateMultiplier}
      <STotal>
        <div />
        <div>Total</div>
        <div />
        <div>
          {dollarFormatter(total.list_price + multiplierTotal.list_price)}
        </div>
        <div />
        <div>
          {dollarFormatter(total.sale_price + multiplierTotal.sale_price)}
        </div>
      </STotal>
    </Con>
  );
}

const Con = styled.div`
  border-top: 1px solid ${theme.border.dark};
  > div {
    padding: 0.5rem 0;
  }
`;

const SMultiplerCon = styled(SItem)`
  > div:nth-child(2) {
    text-align: right;
    display: flex;
    flex-direction: column;
    line-height: 1;
    > div:nth-child(2) {
      span {
        font-size: 0.8rem;
        color: ${theme.color.subtext2};
      }
    }
  }
`;

const STotal = styled(SItem)`
  border-top: 1px solid ${theme.border.light};
  font-size: 1.2rem;
  > div:nth-child(2) {
    text-align: right;
  }
`;
