import styles from "./VendorDetailItem.module.scss";
import { VendorContext } from "contexts/VendorContext";
import { useState, useEffect, useRef, useContext } from "react";
import globalVendorsStyles from "../../../Vendors.module.css"
import VendorStandardizedAddressInput from "components/standardizedAddressInput/vendor";
import StandardizedAddressInput from "components/standardizedAddressInput";
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
  vendorKey: string;
  refetch?: () => void;
  editable?: boolean;
  isNotes?: boolean;
  adjustheight?: () => void;
  limit? : number;
}

const VendorDetailItem = ({ label, vendorKey, refetch, editable, isNotes, adjustheight, limit = 35 }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorBody.VendorDetails.VendorDetailItem.VendorDetailItem";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { currentVendor, updateMode, updater, toggleUpdateModeButton } = useContext(VendorContext)
  const [text, setText] = useState<string>("")
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const [addressObj, setAddressObj] = useState({
    address: "",
    isStandardizedAddress: false,
    selectedOption: {
      description: "",
      city: "",
      state: "",
      zipcode: "",
    },
  });

  useEffect(() => {
    updater({
      ...currentVendor, 
      street_address: addressObj.address, 
      city: addressObj.selectedOption.city, 
      vendor_state: addressObj.selectedOption.state, 
      zip_code: addressObj.selectedOption.zipcode,
      is_standardized_address: addressObj.isStandardizedAddress
    })
  }, [addressObj])

  useEffect(() => {
    editable && setIsEditable(updateMode!)
  }, [updateMode])

  // if (!value) return null;

  const updateVendorData = (e:any) => {
    updater({...currentVendor, [vendorKey]: e.target.value})
    if (e.key === "Enter" && !textareaRef.current) {
      toggleUpdateModeButton(true, refetch)
      setIsEditable(false)
      e.target.blur()
    }
  }

  // TODO - clean this up and make it a custom component for textarea
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Temporarily shrink to auto so scrollHeight reflects content
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [text]); // This effect runs every time the text changes.


  return (
    currentVendor
      ?
        <div>
          <div style={{fontWeight:"bold"}}>{label}</div>
          {
            vendorKey === "street_address" ? 
              <StandardizedAddressInput
                {...{setAddressObj, addressObj}}
                vendorUpdater={updateVendorData}
                noLabel 
                vendor
                maxLength={35}
                value={currentVendor[vendorKey] ?? ""}
                className={isEditable ? globalVendorsStyles.editMode : globalVendorsStyles.readonlyMode}
              />
              : isNotes ?
                <textarea
                  id="vendorTextArea"
                  onChange={(e) => {updateVendorData(e); setText(e.target.value)} }
                  onKeyUp={(e) => updateVendorData(e) }
                  onInput={adjustheight}
                  ref={textareaRef}
                  readOnly={!isEditable}
                  value={currentVendor[vendorKey] ?? ""}
                  className={isEditable ? globalVendorsStyles.editMode : globalVendorsStyles.readonlyMode}
                  maxLength={500}
                  style={{maxHeight:"305px"}}
                />
                :
                <input
                  onChange={(e) => updateVendorData(e) }
                  onKeyUp={(e) => updateVendorData(e) }
                  maxLength={limit}
                  readOnly={!isEditable}
                  value={currentVendor[vendorKey] ?? ""}
                  className={isEditable ? globalVendorsStyles.editMode : globalVendorsStyles.readonlyMode}
                />
            }
        </div>
      : 
        <div>{tr("No Vendor Found")}</div>
  );
};

export default VendorDetailItem;
