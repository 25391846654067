import * as React from "react";
import styled from "styled-components";
import { Status } from "../views/request/component/status";
import Placeholder from "../views/request/component/placeholder";
import { useHistory } from "react-router-dom";
import Table from "components/table";
import { RequestStatusTag, CategoryTag } from "components/tag/index";
import { format } from "date-fns";
import { TRequest } from "types/request";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  data?: TRequest[] | null;
};
export default function RequestsList({ data }: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "containers.requestsList";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory();

  const handleClick = (row: any) => {
    history.push(`/requests/${row.id}`);
  };

  const locale = i18n.language === "es" ? es : enUS;

  const columns = {
    id: {
      label: tr("Request #"),
    },
    status: {
      label: tr("Status"),
      cell: (row: any) => <RequestStatusTag>{tr(row.status)}</RequestStatusTag>,
    },
    link: {
      label: tr("Category"),
      cell: (row: any) => <CategoryTag>{row.link}</CategoryTag>,
    },
    name:{
      label: tr("Name"),
    },
    address: {
      label: tr("Address"),
      width: "100%",
    },
    entry_date: {
      label: tr("Date"),
      cell: (row: any) =>
        format(new Date(row.entry_date), "MMM dd, yyyy hh:mm aa", {
          locale: locale,
        }),
      minWidth: "200px",
    },
  };

  return (
    <div>
      {data && data.length > 0 ? (
        <Table columns={columns} data={data} handleClick={handleClick} />
      ) : (
        <Placeholder
          type="job"
          header={tr("No requests yet.")}
          label={tr("Requests will appear here when added.")}
        />
      )}
    </div>
  );
}

const Con = styled.div``;
