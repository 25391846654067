import { IconButton } from "components/button";
import { Input } from "components/input";
import DollarInputV2 from "components/input/dollarInputV2";
import { useModalContext } from "components/modal";
import { RiCloseFill } from "react-icons/ri";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TTargetProduct } from "types/product";
import { dollarFormatter } from "utils";
import { ProductionAssigneeSelect } from "./components/ProductionAssigneeSelect";
import { useTranslation } from 'react-i18next';

export default function EachProduct(
  props: TTargetProduct & {
    deleteProduct?: () => void;
    view: string;
    formik?: any;
    index?: number;
    isChild?: boolean;
    priceType?: "list" | "sale" | "install";
    rateLabel: "list_price" | "unit_price" | "install_price";
    hideEmpty: boolean;
  }
) {
  const { t } = useTranslation();
  const tBase = "containers.lineItems.eachProduct";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  if (props.view === "edit" && props.formik.values) {
    let value = props.formik.values;

    let product = value.products[props.index!];

    if (!product.quantity && props.hideEmpty) return null;

    let rate =
      props.priceType === "list"
        ? product.list_price
        : props.priceType === "sale"
        ? product.unit_price
        : product.install_price;

    const children = (
      <>
        {props.isChild ? (
          <>
            <span />
            <IconButton size="small" onClick={props.deleteProduct}>
              <RiCloseFill />
            </IconButton>
            {/* <SubtleIconButton size="small" onClick={props.deleteProduct}>
              <GrDrag />
            </SubtleIconButton> */}
          </>
        ) : (
          <>
            {" "}
            <IconButton size="small" onClick={props.deleteProduct}>
              <RiCloseFill />
            </IconButton>
            {/* <SubtleIconButton size="small" onClick={props.deleteProduct}>
              <GrDrag />
            </SubtleIconButton> */}
            <span />
          </>
        )}
        <Input
          style={{ width: "100px" }}
          size="small"
          name={`products[${props.index}].quantity`}
          onFocus={(e) => e.target.select()}
          formik={props.formik}
        />
        <Input
          size="small"
          name={`products[${props.index}].label`}
          formik={props.formik}
        />
        <Input
          size="small"
          name={`products[${props.index}].long_description`}
          formik={props.formik}
        />
        <DollarInputV2
          $size="small"
          onFocus={(e) => e.target.select()}
          name={`products[${props.index}].${props.rateLabel}`}
          formik={props.formik}
        />
        <DollarInputV2 disabled $size="small" value={product.quantity * rate} />
      </>
    );

    if (props.isChild) {
      return <SChildRow>{children}</SChildRow>;
    }
    return children;
  }

  if (!props.quantity && props.hideEmpty) return null;

  const handleClickProductionAssignee = () => {
    const targetId = parseInt(props.id);
    setModal({
      label: tr("Assign Production"),
      component: <ProductionAssigneeSelect targetId={targetId} />,
    });
  };

  return (
    <EachProductCon>
      <div style={{ flex: "1" }}>
        <div>
          <span>
            {props.quantity}&nbsp; &#10761; &nbsp;
            {props.label}
            <span> • </span>
            <span className="text-sm font-bold text-slate-500">
              {props.long_description}
            </span>
          </span>
          &nbsp; &nbsp;
          <span
            style={{
              flex: "1",
              color: theme.color.subtext3,
              fontSize: theme.fontSize.xsmall,
            }}
          >
            {`${tr("List")}: ${dollarFormatter(
              props.list_price
            )} | ${tr("Sale")}: ${dollarFormatter(
              props.unit_price
            )} | ${tr("Install")}: ${dollarFormatter(props.install_price)}`}
          </span>
        </div>
        {/* {console.log(props.production_value)} */}
        {/* {props.production_value ? ( */}
        <div className="flex items-center">
          <div className="text-xs text-secondary">
            {/* {dollarFormatter(props.production_value)} */}
          </div>
          <div className="flex ml-0 text-[12px] font-bold text-slate-500">
            {`${tr("Added by")} ${props.tech}`}
            <button
              className="ml-2 text-[10px] text-primary hover:text-slate-400 focus:outline-none"
              onClick={handleClickProductionAssignee}
            >
              {tr("Edit")}
            </button>
          </div>
        </div>
        {/* ) : null} */}
      </div>

      <span style={{ float: "right" }}>
        {dollarFormatter(props.quantity * +(props[props.rateLabel] || 0))}
      </span>
    </EachProductCon>
  );
}

export const EachProductCon = styled.div`
  /* display: flex;
  flex-direction: column;
  padding: 0.25rem 0; */
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
`;

export const SChildRow = styled.div`
  display: contents;
  background: ${theme.bg.lightblue};
  border: 1px solid green;
  > * {
  }
`;
