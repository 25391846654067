import { cloneDeep, get, set } from "lodash";
import * as React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Input, InputProps } from ".";

type Props = InputProps & NumberFormatProps;
export default function PhoneNumberInput(props: Props) {
  return (
    <NumberFormat
      // @ts-ignore
      customInput={Input}
      decimalScale={2}
      type="tel"
      format="+1 (###) ###-####"
      mask="_"
      onValueChange={(e) => {
        props.name &&
          props.formik &&
          get(props.formik.values, props.name) !== (e.floatValue ?? "") &&
          props.formik.setFieldValue(props.name, e.floatValue ?? null);
      }}
      value={props.name && get(props.formik?.values, props.name)}
      {...props}
    />
  );
}
