// @ts-nocheck
import React from "react";
import { Marker } from "react-map-gl";
import { FcHome } from "react-icons/fc";
import _ from "lodash";

import { THomeMarker, TToOptimizeObject } from "types/appointment-marker";

import styles from "./HomeMarker.module.scss";

interface IProps {
  home: THomeMarker;
  showAddresses: Boolean;
  optimizeState: Array<TToOptimizeObject | null> | null;
}

const HomeMarker = ({ home, showAddresses, optimizeState }: IProps) => {
  if (!home) return null;

  const { address, location } = home;

  if (!address || !location || !location.latitude || !location.longitude) return null;

  return (
    <Marker
      longitude={location?.longitude}
      latitude={location?.latitude}
      anchor="bottom"
    >
      <div onClick={() => {}} className={styles.markerContainer}>
        {showAddresses && address && (
          <div className={styles.pinLabel}>{address}</div>
        )}
        <div className={styles.appointmentPinContainer}>
          <FcHome size={38} color={"green"} className={styles.appointmentPin} />
        </div>
        <div className={styles.orderContainer}>
          {optimizeState &&
          _.find(optimizeState, { address: address }) &&
          _.find(optimizeState, { address: address })?.order ? (
            <div className={styles.order}>
              {_.find(optimizeState, { address: address })?.isStart &&
              _.find(optimizeState, { address: address })?.isEnd
                ? `1,${_.find(optimizeState, { address: address })?.order}`
                : _.find(optimizeState, { address: address })?.order}
            </div>
          ) : null}
        </div>
      </div>
    </Marker>
  );
};

export default HomeMarker;
