import React from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {Button} from "components/button"
import axios from "axios";
import { useModalContext} from "components/modal";
import AssigneeSelect from "components/react-select/assignees";
import TAssignee from "../types/TAssignee";
import TMapAppointment from "../types/TMapAppointment";
import { useAppointments } from "../hooks/useAppointments";
import { useTranslation } from 'react-i18next';

const Selection = () => {
  const { t } = useTranslation();
  const tBase = "features.map";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const { modal, setModal } = useModalContext(); 
  const propapps= useAppointments().data?.appointments;
  const selectedAppointments = propapps?.filter((appointment) => appointment.selected).map((appointment) => {
    return appointment.appointment_id;
  });
  const queryClient = useQueryClient();

  const customerList = useQuery(["customers", selectedAppointments], async() => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/company/customers?type=LIST&ids=${propapps?.map((appointment) => appointment.business_customer_id).join(",")}}`)
      .then((res) => res.data);
  }
  );
const reassignAppointments = useMutation((data: any) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/company/appointment/reassign`, data);
}
);
const rescheduleAppointments = useMutation((data: any) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/v1/company/appointment/reschedule`, data);
}
);
const findRoute =()=>{
  const cords = propapps?.map((appointment)=>{return {id:appointment.appointment_id,lat:appointment.latitude,lng:appointment.longitude}})
  
}
const ReassignModal=()=>{
  const [assignedTo,setAssignedTo]=React.useState<string>("")
  return(
    <div>
      <p>{tr("Please choose the new assignee(s)")}:</p>
      <AssigneeSelect
        value={assignedTo}
        onChange={(e:any)=>{setAssignedTo(e.target.value)}}
      ></AssigneeSelect>
      <Button onClick={()=>{reassignAppointments.mutate({appointmentIds:selectedAppointments, assignees:assignedTo})}}>{tr("Save")}</Button>
      <Button onClick={()=>{setModal()}}>{tr("Cancel")}</Button>
    </div>
  )}
const RescheduleModal=()=>{
  const [date,setDate]=React.useState<string>("")
  const [Starttime,setStartTime]=React.useState<string>("")
  const [EndTime,setEndTime]=React.useState<string>("")
  return(
    <div>
      <p>{tr("Select Date")}:</p>
      <input type="date" value={date} onChange={(e)=>{setDate(e.target.value)}}/>
      <p>{tr("Select Start Time")}:</p>
      <input type="time" value={Starttime} onChange={(e)=>{setStartTime(e.target.value)}}/>
      <p>{tr("Select End Time")}:</p>  <input type="time" value={EndTime} onChange={(e)=>{setEndTime(e.target.value)}}/>
      <Button onClick={()=>{
        rescheduleAppointments.mutate({appointmentIds:selectedAppointments, date:date, start_time:Starttime, end_time:EndTime})
      }}>{tr("Save")}</Button>
      <Button onClick={()=>{setModal()}}>{tr("Cancel")}</Button>
    </div>
  )}

  const OperationsSection=()=>{
    return(
      <div>
        <Button onClick={()=>{
          setModal({
            label: tr("Reassign"),
            component:<ReassignModal/>
          });
        }}>{tr("Reassign")}</Button>
        <Button
          onClick={()=>{
            setModal({
              label: tr("Reschedule"),
              component:<RescheduleModal/>
            });
          }}
        >{tr("Reschedule")}</Button>        
      </div>
    )}
  const AppointmentCard = (props:TMapAppointment)=>{
    const customer = customerList.data?.find((customer:any)=>customer.id===props.business_customer_id)
    return(
      <div>
        <input type="checkbox" checked={props.selected} onChange={()=>{}}/>
        <p>{props.type}</p>
        <h3>{props.address}</h3>
        <h3>{customer.data?.first_name+' '+customer.data?.last_name}</h3>
      </div>
    )

  }
  return(
    <div>
      <div>
      {tr("Appointment section")}
      {propapps?.map((appointment)=>(
        <AppointmentCard {...appointment}/>
      ))}
      </div>
      <div>
        <OperationsSection/>
      </div>
    </div>
  )

};

export default Selection;
