import axios from "axios";
import RequestsList from "containers/requestsList";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

export default function Requests(props) {
  const [requests, setRequests] = React.useState(null);

  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/property/propertyRequests/${props.propertyId}`
      );

      setRequests(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  if (!requests) return null;

  return (
    <Con>
      <RequestsList data={requests} />
    </Con>
  );
}

const Con = styled.div``;
