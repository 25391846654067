import * as React from "react";
import styled from "styled-components";
import {
  RiClipboardLine,
  RiCalendarLine,
  RiToolsLine,
  RiFileEditLine,
  RiFileAddLine,
  RiStickyNoteLine,
  RiBankCard2Line,
  RiTimerFlashLine,
  RiListSettingsLine,
} from "react-icons/ri";

type Props = {
  type:
    | "appointment"
    | "job"
    | "estimate"
    | "invoice"
    | "file"
    | "note"
    | "payment"
    | "reminder"
    | "custom_fields";
  header: string;
  label: string;
};
export default function Placeholder({ type, header, label }: Props) {
  const image = (
    type:
      | "appointment"
      | "job"
      | "estimate"
      | "invoice"
      | "file"
      | "note"
      | "payment"
      | "reminder"
      | "custom_fields"
  ) => {
    switch (type) {
      case "appointment":
        return <RiCalendarLine />;
      case "job":
        return <RiToolsLine />;
      case "estimate":
        return <RiClipboardLine />;
      case "invoice":
        return <RiFileEditLine />;
      case "file":
        return <RiFileAddLine />;
      case "note":
        return <RiStickyNoteLine />;
      case "payment":
        return <RiBankCard2Line />;
      case "reminder":
        return <RiTimerFlashLine />;
      case "custom_fields":
        return <RiListSettingsLine />;
      default:
        console.error("Unhandled option: " + type);
    }
  };

  return (
    <Con>
      {image(type)}
      <h4>{header}</h4>
      <span>{label}</span>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  color: grey;
  padding: 1rem 0;
  > h4 {
    margin: 0;
    font-weight: 700;
    padding: 0;
  }
  > span {
    font-weight: 400;
    max-width: 500px;
  }
  > svg {
    font-size: 3rem;
  }
`;
