import * as React from "react";

import { components, OptionProps, GroupTypeBase } from "react-select";
import Axios from "axios";
import { Avatar } from "components/avatar";
import Select from "components/react-select";

type Options = {
  value: number;
  label: string;
};

export type Assignees = {
  id: number;
  first_name: string;
  last_name: string;
};

type Props = {
  value: any;
};

export default function Assignee(props: any) {
  const [assigneeOption, setAssigneeOption] = React.useState<Options[] | []>(
    []
  );

  React.useEffect(() => {
    const fetchAssignee = async () => {
      try {
        let target = "all";

        if (props.assigneeType === "appointment") {
          target = "assignableToAppointments";
        }

        const employee = await Axios.get(`
              ${process.env.REACT_APP_SERVER_URL}/api/v1/company/employee/${target}
              `);
        setAssigneeOption(
          employee.data.map(({ id, first_name, last_name }: Assignees) => ({
            value: id,
            label: first_name + " " + last_name,
          }))
        );
      } catch (e) {
        console.error(e);
      }
    };

    fetchAssignee();
  }, []);

  return (
    <Select
      components={{Option}}  options={assigneeOption}
      isMulti
      {...props}
    />
  );
}

const Option = ({
  children,
  ...props
}: OptionProps<Options, true, GroupTypeBase<Options>>) =>(
  <components.Option {...props}>
    <div>
      <Avatar showLabel size="xsmall" user_id={props.data.value}>
        {children}
      </Avatar>
    </div>
  </components.Option>
);
