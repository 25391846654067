// import { useProduction } from "features/production/hooks/useProduction";
import { ITeamData } from "features/production/types/ITeamData";
import { formatCents } from "features/production/utils/formatCents";
import { useTranslation } from 'react-i18next';

interface IProductionTableProps {
  teamData: ITeamData[];
}

const ProductionTable = ({ teamData }: IProductionTableProps) => {
  const { t } = useTranslation();
  const tBase = "features.production";
  const tr = (key: string) => t(`${tBase}.${key}`);

  // const {
  //   data: productionData,
  //   error,
  //   isError,
  //   isLoading,
  // } = useProduction(4, new Date().toLocaleDateString());

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (isError) {
  //   return <div>{(error as { message: string })?.message}</div>;
  // }

  return (
    <div className="w-full h-full">
      <div className="overflow-x-auto">
        {teamData ? (
          <table className="w-full border-collapse table-auto">
            <thead>
              <tr>
                <th className="p-2">{tr("Team")}</th>
                <th className="p-2">{tr("Yesterday")}</th>
                <th className="p-2">{tr("Today")}</th>
                <th className="p-2">{tr("Year to Date")}</th>
                <th className="p-2">{tr("Average")}</th>
                <th className="p-2">{tr("Last Year")} (YTD)</th>
                <th className="p-2">{tr("Goal")}</th>
              </tr>
            </thead>
            <tbody>
              {teamData.map((production: ITeamData, key: number) => (
                <tr
                  className={
                    key % 2 === 0 ? "text-highlight" : "text-greenFontAccent"
                  }
                  key={production.team}
                >
                  <td className="p-2 text-center">{production.team}</td>
                  <td className="p-2 text-center">
                    {formatCents(production.yesterday)}
                  </td>
                  <td className="p-2 text-center">
                    {formatCents(production.today)}
                  </td>
                  <td className="p-2 text-center">
                    <span>{formatCents(production.yearToDate)}</span>
                    <span
                      className={
                        (production.yearToDate - production.lastYear) /
                          production.lastYear >
                        0
                          ? "text-green-600 ml-2"
                          : "text-red-600 ml-2"
                      }
                    >
                      (
                      {`${
                        ((production.yearToDate - production.lastYear) /
                          production.lastYear) *
                        100
                      }%`}
                      )
                    </span>
                  </td>
                  <td className="p-2 text-center">
                    {formatCents(production.average)}
                  </td>
                  <td className="p-2 text-center">
                    {formatCents(production.lastYear)}
                  </td>
                  <td className="p-2 text-center">
                    {formatCents(production.goal)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>{tr("No Data Found")}</div>
        )}
      </div>
    </div>
  );
};

export { ProductionTable };
