import { companyClient } from "api/api";
import { IAppointmentType } from "../types/IAppointmentType";

export const addAppointmentType = async (
  newAppointmentType: IAppointmentType
) => {
  return companyClient({
    method: "POST",
    url: "/appointment-types",
    data: newAppointmentType,
  });
};
