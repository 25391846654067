import { Button, SubtleButton } from "components/button";
import * as React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

type Props = {
  handleButtonClick: (index: number) => void;
  pageIndex?: number;
  pageCount?: number;
  style?: {};
};

export default function Pagination(props: Props) {
  const { t } = useTranslation();
  const tBase = "components.table";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const populateButtons = (a: number) => {
    if (a + 1 === props.pageIndex) {
      return (
        <Button key={a} primary>
          {a + 1}
        </Button>
      );
    }
    return (
      <SubtleButton
        key={a}
        onClick={() => {
          window.scrollTo({ top: 0 });
          props.handleButtonClick(a + 1);
        }}
      >
        {a + 1}
      </SubtleButton>
    );
  };

  if (!props.pageCount || !props.pageIndex) return null;

  let pageCount = props.pageCount || 1;
  let pageIndex = props.pageIndex || 1;

  const initialCount = Math.min(Math.max(pageIndex - 3, 0), 2);
  const populateIntial = (
    <>{Array.from(Array(initialCount).keys()).map((a) => populateButtons(a))}</>
  );

  const last = Math.min(pageIndex + 2, pageCount);
  const first = Math.max(pageIndex - 2, 1);

  const finalCount = Math.min(pageCount - last, 2);

  const populateFinal = Array.from(Array(finalCount).keys()).map((a) =>
    populateButtons(a - finalCount + pageCount)
  );

  const populateMiddle = Array.from(Array(last - first + 1).keys()).map((a) =>
    populateButtons(first + a - 1)
  );

  return (
    <Con style={props.style}>
      <SubtleButton
        primary
        disabled={pageIndex === 1}
        onClick={() => props.handleButtonClick(pageIndex - 1)}
      >
        <RiArrowLeftSLine />
        {tr("Previous")}
      </SubtleButton>
      {populateIntial}
      {initialCount === 2 && first !== 3 && <span>...</span>}
      {populateMiddle}
      {finalCount === 2 && last !== pageCount - 2 && <span>...</span>}
      {populateFinal}
      <SubtleButton
        primary
        onClick={() => props.handleButtonClick(pageIndex + 1)}
        disabled={pageCount === pageIndex!}
      >
        {tr("Next")}
        <RiArrowRightSLine />
      </SubtleButton>
    </Con>
  );
}

const Con = styled.div`
  align-self: center;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  > button {
    margin-left: 0.5rem;
  }
  span {
    margin-left: 0.5rem;
  }
`;
