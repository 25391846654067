import axios from "axios";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  getContrastTextColorHEX,
  getContrastTextColorRGB,
} from "utils/getContrastTextColor";
import ViewByTypeContext from "../context/ViewByTypeContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

interface LegendProps {
  grid: string;
  date: string;
}

type Status = {
  id: string;
  label: string;
  color: string;
  counts: number | null;
};

type Type = {
  id: string;
  company_id: string;
  label: string;
  color: string;
  counts: number | null;
  active: boolean;
};

export default function Legend(props: LegendProps) {
  const { t } = useTranslation();
  const tBase = "views.calendar.legend.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  
  const { viewByType } = useContext(ViewByTypeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const { grid, date } = props;

  const { data: status } = useQuery<Status[]>(
    ["appointment status", grid, date],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/status/appointment/`,
          {
            params: {
              grid: grid,
              date: date,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!grid && !!date,
      keepPreviousData: true,
      refetchInterval: 60000,
    }
  );

  const { data: type } = useQuery<Type[]>(
    ["appointment type", grid, date],
    async () =>
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment-types/counts`,
          {
            params: {
              grid: grid,
              date: date,
            },
          }
        )
        .then((res) => {
          return res.data;
        }),
    {
      enabled: !!grid && !!date,
      keepPreviousData: true,
      refetchInterval: 60000,
    }
  );

  const appointmentStatus = status?.map((status) => {
    return (
      <div
        key={status.id}
        className={`text-xs p-1 space-x-2 rounded h-[25px]`}
        style={{
          background: `rgba(${status.color}, 1)`,
          color: getContrastTextColorRGB(status.color),
          border: status.color === null ? "1px solid #ccc" : "none",
        }}
      >
        <span>{tr(status.label)}</span>
        <span
          className="text-xs px-1 border-l-solid border-l"
          // style={{
          //   background: `rgba(${status.color}, 0.2)`,
          // }}
          style={{
            borderLeft: "1px solid " + getContrastTextColorRGB(status.color),
          }}
        >
          {status.counts || 0}
        </span>
      </div>
    );
  });

  const appointmentType = type
    ?.filter((type) => type.active)
    .map((type) => {
      return (
        <div
          key={type.id}
          className={`text-xs p-1 space-x-2 rounded h-[25px]`}
          style={{
            background: type.color,
            color: getContrastTextColorHEX(type.color),
            border: type.color === null ? "1px solid #ccc" : "none",
          }}
        >
          <span>{type.label}</span>
          <span
            className="text-xs px-1 border-l-solid border-l"
            style={{
              borderLeft: "1px solid " + getContrastTextColorHEX(type.color),
            }}
          >
            {type.counts || 0}
          </span>
        </div>
      );
    });

  return (
    <div
      className={`
      flex flex-[0_0_auto] relative border-t
      ${isExpanded ? "h-20" : "max-h-[40px] overflow-hidden"}
    `}
    >
      <div className="flex  flex-wrap p-1 gap-x-2 gap-y-1">
        {date && grid && (viewByType ? appointmentType : appointmentStatus)}
      </div>
      <div className="absolute top-[3px] right-[10px]">
        <button
          className="flex items-center justify-center text-lg text-white font-bold rounded-full bg-primary hover:bg-gray-300 w-[26px] h-[26px]"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <FaChevronDown /> : <FaChevronUp />}
        </button>
      </div>
      <div></div>
    </div>
  );
}
