import React, { useEffect, useState } from "react";
import Assignee from "components/react-select/assignees";
import { Button } from "components/button";
import { Input } from "components/input";
import axios from "axios";
import { format } from "date-fns";
import { useModalContext } from "components/modal";
import { useTranslation } from 'react-i18next';

const EditBlock = (props) => {
  const { setModal } = useModalContext();
  const { t } = useTranslation();
  const tBase = "views.calendar.editBlock";
  const tr = (key) => t(`${tBase}.${key}`);  

  const formatDateYyyyMmDd = (date) => {
    const newDate = date.toString().substring(0, 10);
    return newDate;
  };

  const [loaded, setLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [description, setDescription] = useState("");

  const fetchBlock = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/time_block/getTimeBlockById`,
      {
        params: {
          blockId: props.block.id,
        },
      }
    );

    if (response.status === 200) {
      setAssignees(response.data.assignees);
      setStartDate(formatDateYyyyMmDd(response.data.start_date));
      setEndDate(formatDateYyyyMmDd(response.data.end_date));
      setStartTime(response.data.start_time.substring(0, 5));
      setEndTime(response.data.end_time.substring(0, 5));
      setDescription(response.data.description);
      setLoaded(true);
    }
  };

  useEffect(() => {
    fetchBlock();
  }, []);

  if (!loaded) return null;

  const updateTimeBlock = async (values) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/time_block/updateTimeBlock`,
        { values }
      );

      if (response.status === 200) {
        setIsSubmitting(false);
        setModal(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = () => {
    if (
      props.block.id &&
      assignees &&
      assignees.length > 0 &&
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      description
    ) {
      setIsSubmitting(true);

      const values = {
        blockId: props.block.id,
        assignees,
        startDate,
        endDate,
        startTime: format(new Date(startDate + " " + startTime), "HH:mmXXX"),
        endTime: format(new Date(endDate + " " + endTime), "HH:mmXXX"),
        description,
      };

      updateTimeBlock(values);
    } else {
      alert(tr("All fields are required."));
    }
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/time_block/deleteTimeBlock`,
        {
          data: {
            blockId: props.block.id,
          },
        }
      );

      if (response.status === 200) {
        setModal(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Assignee
        value={assignees}
        onChange={setAssignees}
        assigneeType="appointment"
        label={tr("Assignee")}
      />

      <Input
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
        label={tr("Start Date")}
        type="date"
      />

      <Input
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
        label={tr("End Date")}
        type="date"
      />

      <Input
        value={startTime}
        onChange={(e) => {
          setStartTime(e.target.value);
        }}
        label={tr("Start Time")}
        type="time"
      />

      <Input
        value={endTime}
        onChange={(e) => {
          setEndTime(e.target.value);
        }}
        label={tr("End Time")}
        type="time"
      />

      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        label={tr("Description")}
        type="text"
      />

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{ marginTop: 10, marginRight: 10 }}
          disabled={isSubmitting}
          onClick={handleDelete}
        >
          {tr("Delete")}
        </Button>

        <Button
          style={{ marginTop: 10 }}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {tr("Save")}
        </Button>
      </div>
    </div>
  );
};

export default EditBlock;
