import React, { useState } from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import Axios from "axios";
import AssigneeSelect from "../../components/react-select/assignees";
import StatusSelect from "components/react-select/status";
import { Input } from "components/input";
import { IconButton, NoStyleA } from "components/button";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import DurationInput from "components/input/duration";
import { parseDuration } from "utils/duration";
import Field from "components/field";
import DataLabel from "components/dataLabel";
import { useLocationContext } from "./hooks/useLocationContext";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  max-width: 80%;
  overflow: auto;
  width: ${(props) => props.width}px;
  display: flex;
  flex-direction: column;
  // box-shadow: 0 0 10px -5px black;
  border-left: 1px solid black;
  background: white;
  padding: ${(props) => (props.width === 0 ? 0 : "1rem")};
  transition: all 0.1s ease-out;
  text-align: left;
  > div:not(:first-child) {
    margin: 1.2rem 0 0 0;
  }
`;

const Name = styled.div`
  margin-right: 1rem;
  font-size: 1.7rem;
  font-weight: 500;
`;

const PerLineItem = styled.div`
  font-size: 1rem;
  > div:first-child {
    font-weight: 700;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
  }
`;

const Address = styled(PerLineItem)``;

const StartDate = styled(PerLineItem)``;

const ArrivalWindow = styled(PerLineItem)`
  > div:nth-child(2) {
    display: flex;
    // justify-content: flex-start;
    justify-content: stretch;
    align-items: center;
    > div {
      width: 100%;
    }
  }
`;

const Duration = styled(PerLineItem)`
  > div:last-child {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
`;

const TechAssigned = styled(PerLineItem)``;

const Note = styled(PerLineItem)``;

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.5rem;
`;

export default function ToolTip({ cancelDisplay, toolTipModal }) {
  const { t } = useTranslation();
  const tBase = "views.calendar.toolTip";
  const tr = (key) => t(`${tBase}.${key}`);  
  const { setLocation } = useLocationContext();

  const history = useHistory(),
    queryClient = useQueryClient();

  const [appointment, setAppointment] = useState();

  const [duration, setDuration] = useState("");

  const query = useQuery(
    ["appointment", toolTipModal.id],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${toolTipModal.id}
  `
        )
        .then((res) => {
          if (res.data) {
            setDuration(parseDuration(res.data.duration).fullStr);
            return {
              ...res.data,
              start_time: res.data.start_time
                ? format(new Date(res.data.start_time), "HH:mm")
                : "",
              end_time: res.data.end_time
                ? format(new Date(res.data.end_time), "HH:mm")
                : "",
              latitude: res.data.latitude || "",
              longitude: res.data.longitude || "",
            };
          }
        })
  );

  if (!toolTipModal) return <Con width={0}></Con>;

  if (!query.data) return null;

  let data = query.data;

  const {
    id,
    date,
    start_time,
    end_time,
    note,
    description,
    status,
    estimate_sum,
    production_amount,
    invoice_sum,
    latitude,
    longitude,
  } = query.data;

  const handleAssigneeChange = async (e) => {
    try {
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/updateAssignee`,
        {
          id,
          type: "appointment",
          data: e,
        }
      );

      setAppointment((detail) => ({
        ...detail,
        assignee: e,
      }));
      queryClient.invalidateQueries();
    } catch (e) {
      console.error(e);
    }
  };

  const updateAppointment = async (column, value) => {
    let formatValue = value;

    // console.log("value", value);

    const tempDate = date ? date : format(new Date(), "yyyy-MM-dd");
    if ((column === "start_time" || column === "end_time") && value) {
      const localDate = new Date(`${tempDate}T${value}`);
      const serverDate = new Date(
        localDate.toLocaleString("en-US", { timeZone: "UTC" })
      );
      formatValue = serverDate.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    }
    try {
      await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/updateAppointment`,
        {
          id,
          column,
          value: formatValue,
        }
      );
      queryClient.invalidateQueries();
    } catch (e) {
      console.error(e);
    }
  };

  const handleStatusChange = async (e) => {
    try {
      await Axios.post(
        `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/updateAppointment
      `,
        {
          id,
          column: "status",
          value: e.value,
        }
      );
      setAppointment((detail) => ({
        ...detail,
        status: e,
      }));
      queryClient.invalidateQueries();
    } catch (e) {
      console.error(e);
    }
  };

  const deduplicateAssignees = (assignees) => {
    if (!assignees || !Array.isArray(assignees)) return [];

    const uniqueAssignees = new Map();
    assignees.forEach((assignee) => {
      // Use `assignee.value` as the key for deduplication
      if (assignee && assignee.value && !uniqueAssignees.has(assignee.value)) {
        uniqueAssignees.set(assignee.value, assignee);
      }
    });

    return Array.from(uniqueAssignees.values());
  };

  const handleInputChange = (e) => {
    let { value, name } = e.target;
    queryClient.setQueryData(["appointment", toolTipModal.id], (old) => ({
      ...old,
      [name]: value,
    }));
  };

  return (
    <div className="flex-[0_0_350px] p-2 space-y-4 overflow-auto border-l border-slate-100 py-4 pb-8">
      <ActionBar>
        <NoStyleA
          to={`/${data.fk_request_id ? "requests" : "projects"}/${
            data.fk_request_id ? data.fk_request_id : data.fk_project_id
          }`}
          className="!text-sm !text-blue-600 hover:!underline"
        >
          {data.fk_request_id
            ? `${tr("Request")} #${data.fk_request_id}`
            : `${tr("Project")} #${data.fk_project_id}`}
          /{tr("Appt")} #{data.id}
        </NoStyleA>

        <IconButton
          onClick={() => {
            setLocation(null);
            cancelDisplay();
          }}
        >
          <IoMdClose />
        </IconButton>
      </ActionBar>

      <DataLabel
        style={{
          fontSize: "80%",
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
        }}
      >
        {tr("Estimate")}:
        {estimate_sum ? ` $${(estimate_sum / 100).toFixed(2)}` : null}
      </DataLabel>

      <DataLabel
        style={{
          fontSize: "80%",
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
        }}
      >
        {tr("Production")}:
        {production_amount ? ` $${(production_amount / 100).toFixed(2)}` : null}
      </DataLabel>

      <DataLabel
        style={{
          fontSize: "80%",
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
        }}
      >
        {tr("Invoice")}:
        {invoice_sum ? ` $${(invoice_sum / 100).toFixed(2)}` : null}
      </DataLabel>

      {data.completion_note && (
        <div className="p-2 bg-yellow-100 rounded">
          <DataLabel title={tr("Completion Note")}>{data.completion_note}</DataLabel>
        </div>
      )}

      <div>
        <Name>{data.name}</Name>
        <div className="flex space-x-1">
          {data?.request_customer?.customer_tags?.map((tag) => {
            return (
              <span
                key={tag.id}
                className={`rounded p-1 text-white text-xs`}
                style={{ background: tag.color }}
              >
                {tag.label}
              </span>
            );
          })}
        </div>
      </div>
      <StatusSelect
        value={{
          value: status.id,
          label: tr(status.label),
          color: status.color,
        }}
        handleStatusChange={handleStatusChange}
        type="appointment"
      />
      <div className="mt-3"></div>

      <DataLabel title={tr("Address")}>
        <NoStyleA
          to={`/property/${data.address_obj?.id}`}
          className="!text-sm !text-blue-600 hover:!underline"
        >
          {data.address}
        </NoStyleA>
      </DataLabel>

      <DataLabel title={tr("Type")}>
        <span className="capitalize">{data.type}</span>
      </DataLabel>
      {data.appointment_category && (
        <DataLabel title={tr("Category")}>
          <span>{data.appointment_category}</span>
        </DataLabel>
      )}

      <StartDate>
        <div>{tr("Date")}</div>
        <Input
          // onChange={handleInputChange}
          defaultValue={date}
          type="date"
          key={"date-" + date}
          name="date"
          onBlur={(e) => updateAppointment("date", e.target.value)}
          // value={format(new Date(date), "MM/dd/yyyy")}
          lang="fr"
        />
      </StartDate>
      <ArrivalWindow>
        <div>{tr("Arrival Time Window")}</div>
        <div>
          <Input
            key={"start-" + start_time}
            defaultValue={start_time}
            name="start_time"
            type="time"
            // onChange={handleInputChange}
            onBlur={(e) => updateAppointment("start_time", e.target.value)}
          />
          <span style={{ margin: "0 0.5rem 0 0.5rem" }}>-</span>
          <Input
            key={"end-" + end_time}
            defaultValue={end_time}
            name="end_time"
            type="time"
            // onChange={handleInputChange}
            onBlur={(e) => updateAppointment("end_time", e.target.value)}
          />
        </div>
      </ArrivalWindow>
      <Duration>
        <div>{tr("Duration")}</div>
        <div>
          <DurationInput
            name="duration"
            value={duration}
            // defaultValue={data.duration}
            // key={"duration-" + data.duration}
            onValueChange={(e) => {
              Number(data.duration) !== Number(e.value) && setDuration(e.value);
            }}
            onBlur={() => {
              updateAppointment("duration", duration);
            }}
            onKeyDown={(e) =>
              e.key === "Enter" && updateAppointment("duration", duration)
            }
          />
        </div>
      </Duration>
      <TechAssigned>
        <div>{tr("Tech Assigned")}</div>
        {/* {console.log("query.data.assignee", query.data.assignee)} */}
        <AssigneeSelect
          assigneeType="appointment"
          value={deduplicateAssignees(query.data.assignee)}
          onChange={handleAssigneeChange}
        />
      </TechAssigned>
      <Note>
        <div>{tr("Description")}</div>
        <Field
          key={"note-" + note}
          type="textarea"
          name="note"
          minRows={4}
          // onChange={handleInputChange}
          className="text-sm"
          defaultValue={note || ""}
          onBlur={(e) => updateAppointment("note", e.target.value)}
        />
      </Note>

      {/* <RequestNote>
        <div>Request Description</div>
        <div>{description}</div>
      </RequestNote> */}

      <DataLabel
        style={{
          fontSize: "80%",
          marginTop: 30,
          marginBottom: 4,
          marginLeft: 2,
          color:
            !data.latitude || isNaN(Number(data.latitude)) ? "red" : "inherit",
        }}
      >
        {tr("Latitude")}:
        <input
          type="text"
          value={
            !isNaN(Number(data.latitude))
              ? data.latitude
              : "Coordinate Error: Invalid Latitude"
          }
          disabled
          style={{
            marginLeft: 10,
            width: 200,
          }}
        />
      </DataLabel>

      <DataLabel
        style={{
          fontSize: "80%",
          marginTop: 4,
          marginBottom: 4,
          marginLeft: 2,
          color:
            !data.longitude || isNaN(Number(data.longitude))
              ? "red"
              : "inherit",
        }}
      >
        {tr("Longitude")}:
        <input
          type="text"
          value={
            !isNaN(Number(data.longitude))
              ? data.longitude
              : "Coordinate Error: Invalid Longitude"
          }
          disabled
          style={{
            marginLeft: 10,
            width: 200,
          }}
        />
      </DataLabel>
    </div>
  );
}
