import axios from "axios";
import { OutlineButton } from "components/button";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};
export default function DuplicateQuote(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.duplicateQuote";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory(),
    snackBar = useSnackbarContext(),
    modal = useModalContext();

  const duplicateQuote = useMutation(
    async (e: { type: string }) =>
      await axios
        .post(
          `
      ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/${props.id}/duplicate
      `,
          e
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        history.replace(`./${id}`, {
          focusOnLabel: true,
        });

        modal.setModal();
        snackBar.showSnackbar(tr("Quote duplicated!"));
      },
    }
  );

  const handleClick = (e: string) => duplicateQuote.mutate({ type: e });

  return (
    <div className="space-y-8 mt-4 max-w-[400px]">
      <span className="text-xl">
        {tr("Would you like to keep the previous price or use the latest price?")}
      </span>
      <div className="flex justify-between space-x-4">
        <OutlineButton
          className="flex-1 !h-12"
          onClick={() => handleClick("old")}
        >
          {tr("Keep previous price")}
        </OutlineButton>
        <OutlineButton
          className="flex-1 !h-12"
          onClick={() => handleClick("new")}
        >
          {tr("Latest price")}
        </OutlineButton>
      </div>
    </div>
  );
}
