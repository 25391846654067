import React from "react";

interface CheckboxInputProps {
  phase: "phase1" | "phase2" | "phase3";
  fieldName: string;
  onChange: (phase: "phase1" | "phase2" | "phase3", fieldName: string, checked: boolean) => void;
  checked: boolean;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ phase, fieldName, onChange, checked }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(phase, fieldName, e.target.checked);
  };

  return (
    <input
      type="checkbox"
      onChange={handleChange}
      checked={checked}
      className="
        w-4 h-4 mr-1.5 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer
      focus:ring-blue-500 dark:focus:ring-blue-600 
      dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
    />
  );
};

export default CheckboxInput;
