/* Created by Hannah Green on 5/3/2021 for ES-147 */
import React from "react";
import Requests from "./requests";
import styled from "styled-components";

const Con = styled.div`
    width: 100%;
`;

const CategoryLabel = styled.h2`
    font-weight: bold;
    border-bottom: 1px solid rgb(187, 187, 187);
    padding: 10px; 
`;

export default function RequestCategory(props) {
    const requests = props.requestList;
    const label = props.label;

    return (
        <Con>
            <CategoryLabel>{label}</CategoryLabel>
            <Requests requestList={requests} />
        </Con>
    );

}