import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SubtleButton } from "components/button";
import Axios from "axios";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { useQuery, useQueryClient } from "react-query";
import Placeholder from "../../views/request/component/placeholder";
import { theme } from "styles/theme";
import { SContent } from "components/segment";
import Appointment from "./components/Appointment";
import Spinner from "components/spinner";
import AppointmentTimeTotals from "./components/AppointmentTimeTotals";
import { useTranslation } from 'react-i18next';

interface IAppointmentCardMini {
  editable?: boolean;
  id: any;
  type: any;
  parentId?: any;
  isBookit?: boolean;
  responses?: any[];
}

const AppointmentCardMini = ({
  editable = true,
  id,
  type,
  parentId = null,
  isBookit = false,
  responses = [],
}: IAppointmentCardMini) => {
  const { t } = useTranslation();
  const tBase = "containers.appointmentCardMini.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const { setModal } = useModalContext();
  const [expand, setExpand] = React.useState(false);
  const [selectedAppts, setSelectedAppts] = useState<any[]>([]);

  const { data, isLoading, isError, error} = useQuery(
    ["appointments", type, id],
    async () =>
      await Axios.get(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/appointment/${type}/${id}
  `
      ).then((res) => res.data)
  );

  // Set selectedAppts to all appointment IDs once data is loaded
  useEffect(() => {
    if (data?.appointments) {
      const allAppointmentIds = data.appointments.map((appointment: any) => appointment.id);
      setSelectedAppts(allAppointmentIds);
    }
  }, [data?.appointments]);

  const toggleAllAppointments = () => {
    // Check if all appointments are currently selected
    if (selectedAppts.length === data?.appointments.length) {
      setSelectedAppts([]);
    } else {
      const allAppointmentIds = data.appointments.map((appointment: any) => appointment.id);
      setSelectedAppts(allAppointmentIds);
    }
  };

  if (isLoading) return <Spinner />;

  if (!data) return null;
  
  const populateAppointments =
    data &&
    data.appointments?.map((appointment: any, i: any) => {
      return (
        <Appointment
          parentId={id}
          {...{
            appointment,
            setModal,
            i,
            acceptedBid: data.acceptedBid,
            type,
            expand,
            selectedAppts,
            setSelectedAppts, // used for the toggle for time totals in th AppointmentTimeTotals component
          }}
          length={data.appointments.length}
          key={appointment.id}
          isBookit={isBookit}
          responses={responses}
        />
      );
    });

  return (
    <div className="divide-y">
      <div className="flex items-center justify-between py-4">
        <h2>
          {tr("Appointments")}
          <SubtleButton primary onClick={() => setExpand((s) => !s)}>
            {expand ? "Collapse" : "Expand"}
          </SubtleButton>
        </h2>
        <button 
          onClick={toggleAllAppointments}
          className="text-primary hover:text-black text-sm transition-colors duration-200 ease-in-out focus:outline-none"
        >
          {selectedAppts.length === (data?.appointments || []).length ? tr("Deselect All") : tr("Select All")}
        </button>
      </div>
      <div className="py-4">
        {data && data.appointments && data?.appointments?.length > 0 ? (
          <SContent className="divide-y !p-0">
            {populateAppointments}
            <div className="mt-2 pt-2">
              <AppointmentTimeTotals appointmentIds={selectedAppts} />
            </div>
          </SContent>
        ) : (
          <Placeholder
            type="appointment"
            header={tr("No appointments yet")}
            label={tr("Preassessment appointments and job appointments will appear here when added.")}
          />
        )}
      </div>
    </div>
  );
}

export const JobNumber = styled.span`
  background: ${theme.bg.blue};
  color: blue;
  font-size: 0.8rem;
  font-weight: bold;
  width: auto;
  border-radius: 25px;
  padding: 0 0.5em;
  margin-left: 0.5rem;
`;

export default AppointmentCardMini;