import { useContext, useState } from "react";
import { useModalContext } from "components/modal";

import { Button } from "components/button";
import { VendorContext } from "contexts/VendorContext";
import axios from "axios";
import styles from "./VendorsToolBar.module.scss";
import globalVendorsStyles from "../Vendors.module.css";
import { useEffect } from "react";
import StandardizedAddressInput from "components/standardizedAddressInput";
import { useTranslation } from 'react-i18next';

interface VendorData {
  vendor_name: string;
  prefix: string;
  first_name: string;
  last_name: string;
  suffix: string;
  phone_type: string;
  phone_number: string;
  ext: string;
  email_type: string;
  email_address: string;
  job_title: string;
  department: string;
  street_address: string;
  unit: string;
  address_line_2: string;
  city: string;
  zip_code: string;
  vendor_state: string;
  notes: string;
  payment_term: string;
}


const VendorsToolBar = ({refetch}:any) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorsToolBar.VendorsToolBar";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const { currentVendor, updater, updateMode, toggleUpdateModeButton } = useContext(VendorContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const vendorDataInitial = {
    vendor_name: '',
    prefix: '',
    first_name: '',
    last_name: '',
    suffix: '',
    phone_type: '',
    phone_number: '',
    ext: '',
    email_type: '',
    email_address: '',
    job_title: '',
    department: '',
    street_address: '',
    unit: '',
    address_line_2: '',
    city: '',
    zip_code: '',
    vendor_state: '',
    notes: '',
    payment_term: '',
  };
  const [vendorData, setVendorData] = useState<VendorData>(vendorDataInitial);
	const [addressObj, setAddressObj] = useState<any>({
		address: "",
		isStandardizedAddress: false,
		selectedOption: {
			description: "",
			city: "",
			state: "",
			zipcode: "",
		}
	})

  useEffect(() => {
    setVendorData({
      ...vendorData,
       city: addressObj.selectedOption.city, 
       state: addressObj.selectedOption.state, 
       zip_code: addressObj.selectedOption.zipcode, 
       is_standardized_address: addressObj.isStandardizedAddress
    })
  }, [addressObj])

  const states = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
    "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
    "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
    "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
    "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY",
  ];

  const [isEditable, setIsEditable] = useState<boolean>(false)

  useEffect(() => {
    setIsEditable(updateMode!)
  }, [updateMode])

  const updateVendorData = (e:any) => {
    updater({...currentVendor, vendor_name: e.target.value})
    if (e.key === "Enter") {
      toggleUpdateModeButton(true, refetch)
      setIsEditable(false)
      e.target.blur()
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAddressObj({address: "", isStandardizedAddress: false, selectedOption: {description: "", city: "", state: "", zipcode: ""}});
    setVendorData(vendorDataInitial);
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setVendorData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
  
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/vendors/addVendor`, 
        {
          ...vendorData, 
          street_address: addressObj.address, 
          vendor_state: addressObj.selectedOption.state, 
          city: addressObj.selectedOption.city, 
          zip_code: addressObj.selectedOption.zipcode, 
          isStandardizedAddress: addressObj.isStandardizedAddress
        }
      );

      toggleModal();
    } catch (error) {
      console.error('Error adding vendor:', error);
      // Optionally show an error message
    }
  };

  interface VendorData {
    [key: string]: string; // Define the shape of your vendor data here
  }

  const createInputField = (key: keyof VendorData, placeholder: string, span?: string) => {
    const keyString = key as string;

    return (
      <div className={`${span ? span : "col-span-1"}`}>
        <label htmlFor={keyString} className="block text-sm font-medium text-gray-700">
          {placeholder === "Street Address" ? tr("Street Address") : placeholder}
          </label>
        {
          placeholder === "Street Address" ? 
            <StandardizedAddressInput {...{setAddressObj, addressObj}} noLabel />
          :
            <input 
              type="text"
              name={keyString}
              id={keyString}
              value={vendorData[key]}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            />
        }
      </div>
    );
  };
  

  return (
    <div className={styles.toolBar}>
    {
      currentVendor
        ?
          <div style={{marginLeft:"0px", height:"35px"}}>
            <input className={isEditable ? globalVendorsStyles.editMode : globalVendorsStyles.readonlyMode} style={{marginLeft:"15px", fontSize:"20px", fontWeight:"bold", width:"20em"}}
              onChange={(e) => {
                updateVendorData(e);
              }}
              onKeyUp={(e) => {
                updateVendorData(e);
              }}
              maxLength={50}
              readOnly={!isEditable}
              value={currentVendor?.vendor_name}
            />
          </div>
        :
        <div>
          <div className={styles.title}></div>
        </div>
    }
      <div className={styles.toolBarButtons}>
        <Button primary onClick={toggleModal} >
          {tr("New Vendor")}
        </Button>

        {/* Modal */}
        <div
          className={`
            relative 
            ${isModalOpen ? "ease-out duration-300 opacity-100 z-10" : "ease-in duration-200 opacity-0 -z-[1]"}
          `}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >

          {/* Background */}
          <div className="fixed inset-[50px_0_0_0] bg-gray-500 bg-opacity-75 transition-opacity " />

          <div className="fixed inset-[50px_0_0_0] z-10 lg:w-[calc(100vw-200px)] md:w-full mx-auto overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
                <div className="bg-white pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">

                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div
                        className="text-lg font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        {tr("Add Vendor")}
                      </div>

                      <div className="bg-white pt-5">
                        <div className="grid lg:grid-cols-2 lg:gap-10 md:gap-6 md:grid-cols-1 max-[640px]:px-3">

                          <div className="grid grid-col-2 gap-6">
                            {/* Personal Information Section */}
                            <div className="col-span-3">
                              <div className="text-base font-medium leading-6 text-gray-900">{tr("Personal Information")}</div>
                              <hr />
                              <div className="mt-4">
                              {createInputField("vendor_name", "Vendor Name")}

                              <div className="grid grid-cols-3 gap-6 mt-4">
                                {/* Prefix */}
                                <div className="col-span-1">
                                  <label htmlFor="prefix" className="block text-sm font-medium text-gray-700">{tr("Prefix")}</label>
                                  <select 
                                    id="prefix"
                                    name="prefix"
                                    value={vendorData.prefix}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                  >
                                    <option value="">--</option>
                                    <option value="Mr">Mr.</option>
                                    <option value="Mrs">Mrs.</option>
                                    <option value="Ms">Ms.</option>
                                  </select>
                                </div>
                                  
                                {/* First Name, Last Name, Suffix, Vendor Name */}  
                                {createInputField("first_name", "First Name")}
                                {createInputField("last_name", "Last Name")}

                              </div>

                              </div>
                            </div>

                            {/* Contact Information Section */}
                            <div className="col-span-3 lg:mt-[-80px] md:mt-0">
                              <h3 className="text-base font-medium leading-6 text-gray-900">{tr("Contact Information")}</h3>
                              <hr />
                              <div className="grid grid-cols-3 gap-6 mt-4">
                                {/* Phone Type, Phone Number, Extension, Email Type, Email Address */}
                                <div className="col-span-1">
                                  <label htmlFor="phone_type" className="block text-sm font-medium text-gray-700">{tr("Phone Type")}</label>
                                  <select 
                                    id="phone_type"
                                    name="phone_type"
                                    value={vendorData.phone_type}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                  >
                                    <option value="">--</option>
                                    <option value="work">{tr("Work")}</option>
                                    <option value="personal">{tr("Personal")}</option>
                                    <option value="office">{tr("Office")}</option>
                                  </select>
                                </div>
                                {createInputField("phone_number", "Phone Number")}
                                {createInputField("ext", "Extension")}
                                <div className="col-span-1">
                                  <label htmlFor="email_type" className="block text-sm font-medium text-gray-700">{tr("Email Type")}</label>
                                  <select 
                                    id="email_type"
                                    name="email_type"
                                    value={vendorData.email_type}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                  >
                                    <option value="">--</option>
                                    <option value="work">{tr("Work")}</option>
                                    <option value="personal">{tr("Personal")}</option>
                                  </select>
                                </div>
                                {createInputField("email_address", "Email Address", "col-span-2")}
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-col-2 gap-6">
                            {/* Address Information Section */}
                            <div className="col-span-3">
                              <div className="text-base font-medium leading-6 text-gray-900">{tr("Address Information")}</div>
                              <hr />
                              <div className="grid grid-cols-3 gap-6 mt-4">
                                {/* Street Address, Unit, Address Line 2, City, Zip Code, State */}
                                {createInputField("street_address", "Street Address", "col-span-2")}
                                {createInputField("address_line_2", "Address Line 2")}
                                {createInputField("city", "City")}
                                <div className="col-span-1">
                                  <label htmlFor="vendor_state" className="block text-sm font-medium text-gray-700">{tr("State")}</label>
                                  <select 
                                    id="vendor_state"
                                    name="vendor_state"
                                    value={vendorData.vendor_state}
                                    onChange={handleInputChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                                  >
                                    <option value={addressObj.selectedOption.state ? addressObj.selectedOption.state : ""}>{addressObj.selectedOption.state ? addressObj.selectedOption.state : "--"}</option>
                                    {states.map((state, index) => (
                                      <option key={index} value={state}>{state}</option>
                                    ))}
                                  </select>
                                </div>
                                {createInputField("zip_code", "Zip Code")}
                              </div>
                            </div>

                            {/* Additional Information Section */}
                            <div className="col-span-3">
                              <div className="text-base font-medium leading-6 text-gray-900">{tr("Additional Information")}</div>
                              <hr />
                              <div className="grid grid-cols-2 gap-6 mt-4">
                                {/* Job Title, Department, Notes, Payment Term */}
                                {createInputField("job_title", "Job Title")}
                                {createInputField("department", "Department")}
                                <div className="col-span-2">
                                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700">{tr("Notes")}</label>
                                  <textarea 
                                    id="notes"
                                    name="notes"
                                    value={vendorData.notes}
                                    onChange={handleInputChange}
                                    className="h-[80px] mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 resize-none"
                                    placeholder={tr("Notes")}
                                  />
                                </div>
                                <div className="col-span-2 py-3"></div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    
                  </div>
                </div>

                {/* Buttons */}
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:brightness-[0.8] sm:ml-3 sm:w-auto"
                  >
                    {tr("Add")}
                  </button>
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto"
                  >
                    {tr("Cancel")}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
                      
      </div>
    </div>
  );
};

export default VendorsToolBar;
