import { RiArrowDownSFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import Dropdown from "components/dropdown";
import { Button } from "components/button";
import { IBasemap } from "../../types/property-map";
import { basemaps } from "../../constants/property-map-constants";
import { useContext, RefObject, useState, useEffect, useCallback } from "react";
import { PropertyMapContext } from "../../context/property-map-context";
import { FaHome, FaMap } from "react-icons/fa";
import { theme } from "styles/theme";
import { MdPlace } from "react-icons/md";
import { MapRef } from "react-map-gl";
import { TMapMarker, TPropertyMapData } from "../../types/TPropertyMapData";
import { useMutation, useQueryClient } from "react-query";
import { postMarker } from "../../api/postMarker";
import { FiTriangle } from "react-icons/fi";

type TPropertyMapToolBarProps = {
  mapRef: RefObject<MapRef>;
  mapData: TPropertyMapData;
  isDrawingPolygon: boolean;
  setIsDrawingPolygon: (isDrawing: boolean) => void;
  polygonVertices: any;
  setPolygonVertices: (vertices: any) => void;
};

const PropertyMapToolBar = ({
  mapRef,
  mapData,
  isDrawingPolygon,
  setIsDrawingPolygon,
  polygonVertices,
  setPolygonVertices,
}: TPropertyMapToolBarProps) => {
  const { t } = useTranslation();
  const tBase = "features.properties";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setSelectedBasemapId } = useContext(PropertyMapContext);
  const [isAddingMarker, setIsAddingMarker] = useState(false);

  const queryClient = useQueryClient();
  const mutation = useMutation(postMarker, {
    onSuccess: () => {
      queryClient.invalidateQueries("markers");
      queryClient.invalidateQueries("property-map-data");
    },
  });

  const handleClickAddMarker = () => {
    setIsAddingMarker(true);

    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = "crosshair";
    }
  };

  const handleClickAddPolygon = () => {
    console.log("add polygon");

    setIsDrawingPolygon(true);

    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = "crosshair";
    }
  };

  const handleMapClick = useCallback(
    (event: any) => {
      const coordinates = event.lngLat;

      if (mapRef.current) {
        mapRef.current.getCanvas().style.cursor = "";
      }
      setIsAddingMarker(false);

      const newMarker: TMapMarker = {
        id: 0,
        label: null,
        description: null,
        fk_custom_field_id: null,
        fk_company_id: null,
        fk_property_id: parseInt(mapData.id) ? parseInt(mapData.id) : null,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        color: "orange",
        fk_user_id: null,
        updated_at: null,
        created_at: new Date().toISOString(),
        image_url: null,
        thumbnail_url: null,
      };

      mutation.mutate(newMarker);

      // Handling polygon drawing
      if (isDrawingPolygon) {
        setPolygonVertices((prevVertices: any) => [
          ...prevVertices,
          [coordinates.lng, coordinates.lat],
        ]);
      }
    },
    [mapRef, isDrawingPolygon, mutation, mapData.id]
  );

  useEffect(() => {
    const map = mapRef.current;

    if (isAddingMarker && map) {
      map.on("click", handleMapClick);
    }

    return () => {
      if (map) {
        map.off("click", handleMapClick);
      }
    };
  }, [isAddingMarker, mapRef, handleMapClick]);

  const handleClickHome = () => {
    if (!mapData) return;

    if (mapData.longitude && mapData.latitude)
      mapRef.current?.flyTo({
        center: [mapData.longitude, mapData.latitude],
        zoom: 18,
      });
  };

  return (
    <div className="absolute top-0 left-0 flex items-center w-full h-12 pl-1 border-b shadow bg-slate-100/90 border-slate-200/85">
      <Button className="mr-1" onClick={handleClickHome}>
        <FaHome color={`${theme.color.main}88`} /> {tr("Home")}
      </Button>
      <Button className="mr-1" onClick={handleClickAddMarker}>
        <MdPlace color={`${theme.color.main}88`} /> {tr("Add Marker")}
      </Button>
      <Button className="mr-1" onClick={handleClickAddPolygon}>
        <FiTriangle color={`${theme.color.main}88`} /> {tr("Add Polygon")}
      </Button>
      <Dropdown
        data={basemaps.map((basemap: IBasemap) => ({
          button: [
            {
              label: tr(basemap.label),
              onClick: () => {
                setSelectedBasemapId(basemap.id);
              },
            },
          ],
        }))}
        reference={
          <Button>
            <FaMap color={`${theme.color.main}88`} /> {tr("Basemap")}{" "}
            <RiArrowDownSFill />
          </Button>
        }
      />
    </div>
  );
};

export { PropertyMapToolBar };
