import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { subMinutes } from "date-fns";
import { useQuery } from "react-query";

import { __businessUrl__ } from "../../../constants";
import { SpecialOrder } from "types/special-order";
import Spinner from "components/spinner";
import SpecialOrderRow from "./SpecialOrderRow";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

import styles from "./SpecialOrderHistory.module.scss";

const SpecialOrderHistory = () => {
  const { t, i18n } = useTranslation();
  const tBase = "views.order.components.SpecialOrderHistory";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const [date, setDate] = useState(new Date());

  const { isLoading, error, data } = useQuery(["getSpecialOrders", date], () =>
    axios
      .get(`${__businessUrl__}/specialOrder/orders`, {
        params: { date: subMinutes(date, new Date().getTimezoneOffset()) },
      })
      .then((res) => res.data)
  );

  const handleChangeDate = (newDate: Date) => {
    setDate(newDate);
  };

  return (
    <div className={styles.container}>
      <div className={styles.dateGroup}>
        <h3>{tr("Fulfillment Date")}: </h3>
        <DatePicker
          className={styles.datePicker}
          selected={date}
          onChange={handleChangeDate}
          locale={locale}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <div>{tr("Something went wrong.")}</div>
      ) : (
        <>
          {data &&
            data?.map((order: SpecialOrder) => (
              <SpecialOrderRow order={order} />
            ))}
          <div style={{ height: 200 }} />
        </>
      )}
    </div>
  );
};

export default SpecialOrderHistory;
