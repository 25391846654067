export const theme = {
  color: {
    main: "#ff4f00",
    // subtext: "#3C4257",
    // subtext2: "#596281",
    // subtext3: "#8E96A5",
    text: "rgba(0,0,0)",
    subtext: "rgba(0,0,0,0.8)",
    subtext2: "rgba(0,0,0,0.7)",
    subtext3: "rgba(0,0,0,0.6)",
  },
  bg: {
    lightorange: "#FFF6F3",
    lightblue: "#FAFAFC",
    blue: "#EDF1F4",
    hoverblue: "#F6FAFE",
    lightgreen: "#E9F7EF",
    lightred: "#FDEDEC",
  },
  border: {
    light: "#d9d9d9",
    // light: "black",
    dark: "black", // "#A1C3D3",
    black: "black",
    // dark: "black",
  },
  height: {
    navigation: "50px",
    xxsmall: "20px",
    xsmall: "25px",
    small: "30px",
    regular: "40px",
    large: "45px",
    xlarge: "50px",
  },
  fontSize: {
    xxsmall: "0.7rem",
    xsmall: "0.75rem",
    small: "0.8rem",
    regular: "0.85rem",
    large: "1rem",
    xlarge: "1.1rem",
  },
  zIndex: {
    navigation: 10,
    modal: 11,
    tour: 12,
  },
};
