import React, {useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button} from "components/button";
import { useModalContext } from "components/modal";
import { useQuery, useMutation } from "react-query";
import  twodigitmonth  from "utils/twodigitmonth";
import { Input } from "components/input";
import {
	RiDeleteBinLine,
} from "react-icons/ri";
import Switch from "rc-switch";
import DatePicker from "react-multi-date-picker";
import TooltippedElement from "components/TooltippedElement";
import { useTranslation } from 'react-i18next';


const Con = styled.div`
	margin: 10px;
	display: inline;
`;
const Row = styled.div`
	margin-top: 10px;
	border: solid;
	border-width: 1.5px;
	border-radius: 5px;
	border-color: #ff4f00;
	display: block;
	min-height: 10px;
`;
const Title = styled.div`
	text-align: left;
	margin-bottom: 1rem;
`;

const EditPunchModal = (props) => {
    const { t } = useTranslation();
    const tBase = "views.time_dashboard.EditPunchModal";
    const tr = (key) => t(`${tBase}.${key}`);

    const { setModal } = useModalContext();    
    const row=props.row
    const refreshinfo = props.refreshinfo;
    const [note,setNote] = React.useState(row.note);
    const [showMainContent, setShowMainContent] = React.useState(false);

    const [time, setTime] = React.useState(() => {
        //2024-01-02T12:19
        let punchtime = new Date(row.time);
        let year = punchtime.getFullYear();
        let month = twodigitmonth(punchtime.getMonth() + 1);
        let day = twodigitmonth(punchtime.getDate());
        let hour = twodigitmonth(punchtime.getHours());
        let minute = twodigitmonth(punchtime.getMinutes());
        let second = twodigitmonth(punchtime.getSeconds());
        return (year + "-" + month + "-" + day + "T" + hour + ":" + minute+":"+second)
    });

    const [completed, setCompleted] = React.useState(row?.completed==="true");

    const deletepunch = useMutation((info) => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/deletepunch`,
                {
                    time: info.time,
                    pin: info.pin,
                }
            ).then((res)=>{
                refreshinfo();
                setModal();
            })
    });

    const editpunch = useMutation((info) => {
        axios
            .post(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/editpunch`,
                {
                    time: info.time,
                    pin: info.pin,
                    type: info.type,
                    completed: info.completed,
                    prevTime: info.prevTime,
                    note: info.note
                }
            ).then((res)=>{
                refreshinfo();
                setModal();
            })
    });

    const DeletePunchModal = (info) => {
        const rowdata = info.rowdata;
        return (
            <Con>
                <p>{tr("Are you sure you want to remove this punch?")}</p>
                <Row>
                    {rowdata.name +
                        "- " +
                        rowdata.time +
                        "(" +
                        rowdata.event_type +
                        ")"}
                </Row>
                <Button
                    style={{ margin: "5px" }}
                    onClick={async () => {
                        await deletepunch.mutateAsync({
                            time: new Date(rowdata.time),
                            pin: rowdata.pin,
                        })
                    }}
                >
                    {tr("Confirm")}
                </Button>
                <Button
                    style={{ margin: "5px" }}
                    onClick={() => {
                        setModal();
                    }}
                >
                    {tr("Cancel")}
                </Button>
            </Con>
        );
    };

    // add break modal
    const AddBreakModal = (info) => {
        const [breakTime, setBreakTime] = React.useState(15);
        const [errorMessage, setErrorMessage] = React.useState("");
        const breakTimeArr = [15, 30, 45, 60];
        const [allowedBreakTimes, setAllowedBreakTimes] = React.useState([]);

        const AddBreak = useMutation((info) => {
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/addBreak`,
                    {
                        time: info.clocktime,
                        duration: info.duration,
                        pin: info.pin,
                        type: info.type,
                        note: info.note,
                        isfromadmin: true,
                        punchType: info.type,
                        timezone: info.timezone,
                    }
                )
                .then((res) => {
                    let adjustedTimes = res.data.timesJustAdded.map((time) => {
                        return new Date(time).toLocaleString();
                    });
  
                    info.setTimesToColor(adjustedTimes);
                    refreshinfo();
                    setModal();
                }).catch((err) => {
                    const message = err.response?.data || err.message;
                    // setErrorMessage(`Error: ${message}`);
                    console.log("Error: ", err);
                    console.log("Error: ", message);
                });
        });

        const BreakCheck = (info) => {
            console.log("check")
            axios
                .post(
                    `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/time_clock/breakCheck`,
                    {
                        time: info.clocktime,
                        pin: info.pin,
                        breakTimeArr: breakTimeArr,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }
                )
                .then((res) => {
                    // refreshinfo();
                    setAllowedBreakTimes(res.data.allowedBreakTimesArr);
                    // console.log(res.data)
                    // console.log("Allowed Break Times: ", res.data);
                }).catch((err) => {
                    const message = err.response?.data || err.message;
                    setErrorMessage(`Error: ${message}`);
                    // console.log("Error: ", message);
                });
        }

        React.useEffect(() => {
            BreakCheck({ clocktime: time, pin: row.pin });
        }, []);

        return (
            <div style={{ margin: "5px", display: "flex", flexDirection: "row", flexWrap: "wrap", width: '500px', padding: '5px' }} >
                <p style={{ display: "flex", width: '46%', flex: '46%', margin: '2%', textAlign: 'right', alignItems:'center' }}>{tr("Break Time")}:</p>
                <div style={{ display: "flex", width: '46%', flex: '46%', margin: '2%', justifyContent: 'space-between' }} >
                    <Button 
                        onClick={() => { setBreakTime(15) }}
                        primary={breakTime === 15}
                        disabled={!allowedBreakTimes.includes(15)}
                    >
                        15
                    </Button>
                    <Button 
                        onClick={() => { setBreakTime(30) }}
                        primary={breakTime === 30}
                        disabled={!allowedBreakTimes.includes(30)}
                    >
                        30
                    </Button>
                    <Button 
                        onClick={() => { setBreakTime(45) }}
                        primary={breakTime === 45}
                        disabled={!allowedBreakTimes.includes(45)}
                    >
                        45
                    </Button>
                    <Button 
                        onClick={() => { setBreakTime(60) }}
                        primary={breakTime === 60}
                        disabled={!allowedBreakTimes.includes(60)}
                    >
                        60
                    </Button>
                </div>

                <p style={{ display: "flex", width: '46%', flex: '46%', margin: '2%', textAlign: 'right', alignItems:'center' }}>{tr("Break Notes")}:</p>
                <Input
                    style={{ display: "flex", width: '46%', flex: '46%', margin: '2%' }}
                    type="text"
                    value={note}
                    onChange={(a) => {
                        setNote(a.target.value);
                    }}
                />

                <div style={{ display: "flex", width: '46%', flex: '46%', margin: '2%' }} >
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <Button
                    style={{ marginLeft: 'auto' }}
                    primary
                    onClick={() => {
                        console.log("time: ", time);
                        AddBreak.mutate({
                            clocktime: time,
                            duration: breakTime,
                            pin: row.pin,
                            type: "CLOCK OUT - BREAK",
                            note: note,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            setTimesToColor: info.setTimesToColor,
                        });
                    }}
                    disabled={!allowedBreakTimes.includes(breakTime)}
                >
                    {tr("Submit")}
                </Button>
                </div>
            
            </div>
        );
    }

    if (props.isGroupPunch && !showMainContent) {
        return (
            <div style={{ margin: "5px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around", }} >
                <Button
                    primary
                    onClick={() => {
                        setShowMainContent(true);
                    }}
                >
                    {tr("Edit Punch")}
                </Button>
                <Button
                    primary
                    onClick={() => {
                        setShowMainContent(false);
                        setModal({
                            component: <AddBreakModal setTimesToColor={props.setTimesToColor} />,
                            label: <p>{tr("Add Break")}</p>,
                            props: { shouldCloseOnOverlayClick: true },
                        });
                    }}
                >
                    {tr("Add Break")}
                </Button>
            </div>
        );
    }





    // edit punch modal
    return (
        <div style={{ margin: "5px", display: "flex", flexDirection: "row", flexWrap: "wrap", width:'500px', padding:'5px' }} >
            <p style={{display:"flex", width:'46%',flex:'46%',margin:'2%',textAlign:'right'}}>{tr("Punch Time")}:</p>
            <Input
                style={{display:"flex", width:'46%',flex:'46%',margin:'2%'}}
                type="datetime-local"
                value={time}
                onChange={(a) => {
                    setTime(a.target.value);
                }}
            />
            <p style={{display:"flex", width:'46%',flex:'46%',margin:'2%',textAlign:'right'}}>{tr("Punch Notes")}:</p>
            <Input
                style={{display:"flex", width:'46%',flex:'46%',margin:'2%'}}
                type="text"
                value={note}
                onChange={(a) => {
                    setNote(a.target.value);
                }}
            />
            <p style={{display:"flex", width:'46%',flex:'46%',margin:'2%',textAlign:'right'}}>{tr("Completed")}:</p>
            <div style={{display:"flex", width:'46%',flex:'46%',margin:'2%'}} >
                <Switch
                    checked={completed}
                    onChange={(a) => {
                        setCompleted(a);
                    }}
                />
            </div>
            
            <Button 
                style={{width:'30%',flex:"100%",margin:'auto'}}
                onClick={()=>{
                    setModal({
                        component: <DeletePunchModal rowdata={row} />,
                        label: <p color="Red">{tr("Delete Punch?")}</p>,
                        props: {
                            shouldCloseOnOverlayClick: true,
                        },
                    });
                }}>
                <RiDeleteBinLine
                style={{ display: "inline", color: "red" }}

            />
                {tr("Delete punch")}
            </Button>

            <div style={{ flex:'100%' }} >
                <Button
                    primary
                    style={{float:'right',margin:'5px'}}
                    onClick={async()=>{
                        await editpunch.mutateAsync({
                            time: new Date(time).getTime(),
                            pin: row.pin,
                            completed:completed,
                            prevTime: new Date(row.time).getTime(),
                            note:note
                        })
                    }}
                >
                    {tr("Save Changes")}
                </Button>
                <Button
                    primary
                    style={{margin:'5px'}}
                    onClick={()=>{
                        setModal();
                    }}
                >
                    {tr("Cancel")}
                </Button>
            </div>
        </div>
    );
};

export default EditPunchModal;