import convertColumnHeader from "views/admin/production/utils/convertColumnHeader";
import { useTranslation } from 'react-i18next';

const columns = [
  "id",
  "fk_appointment_id",
  "fk_user_id",
  "fk_estimate_id",
  "fk_invoice_id",
  "appointment_production",
  "estimate_production",
  "invoice_production",
  "updated_at",
  "created_at",
];

const Head = () => {
  const { t } = useTranslation();
  const tBase = "views.admin.production.components.Head";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <thead className="bg-slate-600">
      {columns.map((col) => (
        <th
          key={col}
          onClick={() => {}}
          className="px-4 py-2 font-semibold text-left text-white cursor-pointer"
        >
          {tr(convertColumnHeader(col))}
        </th>
      ))}
    </thead>
  );
};

export default Head;
