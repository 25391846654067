import _ from "lodash";
import { Vendor } from "types/vendor";
import VendorButton from "./VendorButton/VendorButton";
import { useTranslation } from 'react-i18next';
import styles from "./VendorsList.module.scss";

interface IProps {
  vendors: Array<Vendor> | null;
}

const VendorsList = ({ vendors }: IProps) => {
  const { t } = useTranslation();
  const tBase = "views.vendors.VendorsList.VendorsList";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <>
      <h4 className={styles.listTitle}>{tr("Vendors")}</h4>
      {_.orderBy(vendors, "vendor_name")?.map((v: Vendor) => {
        return <VendorButton key={v.vendor_id} vendor={v} />;
      })}
    </>
  );
};

export default VendorsList;
