import React, { useEffect } from "react";
import TooltippedElement from "components/TooltippedElement";
import { IconButton, NoStyleA } from "components/button";
import Image from "components/image";
import {
  RiAddBoxFill,
  RiDeleteBinFill,
  RiHammerLine,
  RiSettings2Fill,
} from "react-icons/ri";
import {
  AiFillDelete,
} from "react-icons/ai";
import { FaUndo } from "react-icons/fa";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TInventory } from "types/inventory";
import { TItemGroup } from "types/item_group";
import { TProduct } from "types/product";
import { dollarFormatter } from "utils";
import axios from "axios";
import { useTranslation } from 'react-i18next';

export default function Product(props: {
  data: TInventory | TProduct | TItemGroup;
  type: string;
  archive: (id: any) => void;
  unarchive: (id: any) => void;
}) {
  const { t } = useTranslation();
  const tBase = "views.inventories.product";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const data = props.data;
  const type = props.type;
  const [archiver, setArchiver] = React.useState< {archived_by:string, archived_at:Date} | null >(null);

  const archivedMsg = `${tr("Archived By")}: ` + archiver?.archived_by + tr(" on ") + (archiver && new Date(archiver.archived_at).toLocaleString("default", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  }));

  const getArchiver = async(data: any, type: string) => {
    let id;
    if (type === "part") {
      id = data.item_id;
    } else if (type === "service") {
      id = data.id;
    } else {
      id = data.id;
    }

    await 
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/item?type=ARCHIVER&item_id=${id}&item_type=${type}`)
        .then((res) => setArchiver(res.data[0]))
  }

  useEffect(() => {
    getArchiver(data, type)
  }, [])

  return (
    <SItem
      to={
        "item_id" in data
          ? `/inventory/${data.item_id}/part`
          : "product_label" in data
          ? `/inventory/${data.id}/service`
          : `/item_groups/${data.id}/group`
      }
    >
      <Image
        src={("imageUrl" in data && data.imageUrl) || ""}
        placeholder={
          "item_id" in data ? (
            <RiHammerLine />
          ) : "product_label" in data ? (
            <RiSettings2Fill />
          ) : (
            <RiAddBoxFill />
          )
        }
      />
      <div>
        <span
          style={{
            color: theme.color.subtext2,
            fontSize: theme.fontSize.xsmall,
          }}
        >
          {"root_name" in data
            ? `${data.root_name}${data.cat_name ? ` > ${data.cat_name}` : ""}`
            : "product_label" in data
            ? "Product"
            : "Group"}
        </span>
        <span className="space-x-2">
          <span>
            {"item_name" in data
              ? data.item_name
              : "product_label" in data
              ? data.product_label
              : data.label}
          </span>
          {"special_order_item" in data && data.special_order_item && (
            <span className="font-bold text-xs bg-blue-600 rounded text-white px-1 uppercase">
              {tr("Special")}
            </span>
          )}
          {"bulk_order_item" in data && data.bulk_order_item && (
            <span
              className="font-bold text-xs bg-red-600 rounded text-white px-1 uppercase"
              style={{
                background: "red",
                fontSize: theme.fontSize.xxsmall,
                color: "white",
                padding: "0.05em 0.25em",
                margin: "0 0.5em",
              }}
            >
              {tr("Bulk")}
            </span>
          )}
          {/* {data.is_voided && (
            <span className="font-bold text-xs bg-red-600 rounded text-white px-1 uppercase">
              Archived
            </span>
          )} */}
          {data.is_voided && (
            <TooltippedElement 
              placement="right"
              message={archivedMsg}
              className="inline-flex"
              element={
                <span className="font-bold text-xs bg-red-600 rounded text-white px-1 uppercase">
                  {tr("Archived")}
                </span>
              }
            />
          )}
        </span>
        <SItemPrices>
          <div>
            <span>{tr("List Price")}</span>
            <span>
              {dollarFormatter(
                "list_price" in data ? data.list_price : data.list_net_total
              )}
            </span>
          </div>
          <div>
            <span>{tr("Sale Price")}</span>
            <span>
              {dollarFormatter(
                "unit_price" in data ? data.unit_price : data.sale_net_total
              )}
            </span>
          </div>
          <div>
            <span>{tr("Install Price")}</span>
            <span>
              {dollarFormatter(
                "install_price" in data
                  ? data.install_price
                  : data.install_net_total
              )}
            </span>
          </div>
        </SItemPrices>
      </div>
      <div style={{ marginLeft: "auto" }} className="flex self-center">
        {data && !data.is_voided ? (
          <TooltippedElement 
            placement="top"
            message={tr("Archive")}
            className="inline-flex"
            element={
              <IconButton
                onClick={() =>
                  props.archive("item_id" in data ? data.item_id : data.id)
                }
              >
                <AiFillDelete size={24} className="text-red-500 cursor-pointer" />
              </IconButton>
            }
          />
        ) : (
          <TooltippedElement 
            placement="top"
            message={tr("Restore")}
            className="inline-flex"
            element={
              <IconButton
                onClick={() =>
                  props.unarchive("item_id" in data ? data.item_id : data.id)
                }
              >
                <FaUndo size={24} className="text-green-500 p-0.5 cursor-pointer" />
              </IconButton>
            }
          />
        )}
      </div>
    </SItem>
  );
}

const SItem = styled(NoStyleA)`
  display: flex;
  > div {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
  }
  padding: 1rem 0;
  border-bottom: 1px solid ${theme.border.light};
  &:hover {
    background: ${theme.bg.hoverblue};
  }
`;

const SItemPrices = styled.div`
  display: flex;
  justify-self: flex-end;
  margin-top: auto;
  > div {
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${theme.border.light};
    padding-right: 1rem;
    margin-right: 1rem;
    > span:first-child {
      color: ${theme.color.subtext2};
      font-size: ${theme.fontSize.xsmall};
    }
    > span:nth-child(2) {
      /* font-size: ${theme.fontSize.xlarge}; */
    }
  }
`;
