import axios from "api/axios";
import { A, Button, NoStyleA } from "components/button";
import Spinner from "components/spinner";
import * as React from "react";
import { RiAddFill } from "react-icons/ri";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import styled from "styled-components";
import { theme } from "styles/theme";
import { TQuoteTemplate } from "types/quote-template";
import { useTranslation } from 'react-i18next';

type Props = {};

export default function QuoteTemplates(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.admin.quote-templates.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const query = useQuery<TQuoteTemplate[]>(
    "quote templates",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template
    `
        )
        .then((res) => res.data)
  );

  const mutation = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quote-template
  `
        )
        .then((res) => res.data),
    {
      onSuccess: (id) => {
        history.push(`/admin/quote-template/${id}`);
      },
    }
  );

  if (query.isLoading) return <Spinner />;

  const handleAddTemplate = () => mutation.mutate();

  const populateTemplates = query.data?.map((template) => {
    return (
      <NoStyleA
        to={`/admin/quote-template/${template.id}`}
        style={{
          padding: "1rem 0",
          borderBottom: `1px solid ${theme.border.light}`,
        }}
        key={template.id}
      >
        <span>{template.label}</span>
      </NoStyleA>
    );
  });

  return (
    <Con>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Quote Templates</h2>
        <Button
          primary
          onClick={handleAddTemplate}
          isLoading={mutation.isLoading}
        >
          <RiAddFill />
          {tr("New Template")}
        </Button>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {populateTemplates}
      </div>
    </Con>
  );
}

const Con = styled.div``;
