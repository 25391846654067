import axios from "api/axios";
import * as React from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import styled from "styled-components";
import { TInventory } from "types/inventory";
import PartCategories from "./categories";
import Part from "./part";

export default function Parts() {
  const [selectedCategory, setSelectedCategory] = React.useState<
    string | number
  >("");

  const query = useInfiniteQuery<TInventory[]>(
    ["parts", selectedCategory],
    async ({ pageParam = 0 }) =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/items`
          // {
          //   params: {
          //     category: selectedCategory,
          //     pageParam,
          //   },
          // }
        )
        .then((res) => res.data)
  );

  //   const query = useQuery<TInventory[]>(
  //     ["parts", selectedCategory],
  //     async () =>
  //       await axios
  //         .get(
  //           `
  //     ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/inventory`,
  //           {
  //             params: {
  //               category: selectedCategory,
  //             },
  //           }
  //         )
  //         .then((res) => res.data)
  //   );

  const handleClick = (id: string | number) => {
    setSelectedCategory(id);
  };

  const populateParts = query.data?.pages?.map((page) => (
    <React.Fragment>
      {page.map((inventory) => (
        <Part {...inventory} />
      ))}
    </React.Fragment>
  ));

  return (
    <Con>
      <PartCategories {...{ selectedCategory, handleClick }} />
      <SPartsCon>{populateParts}</SPartsCon>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  align-items: flex-start;
  > div:first-child {
    height: 90vh;
    overflow-y: auto;
    flex: 0 0 300px;
    position: sticky;
    top: 0;
    margin-right: 1rem;
  }
  > div:last-child {
    flex: 1;
  }
`;

const SPartsCon = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;
