import styled from "styled-components";
import RawAddEditService from "./addEditServiceModal";
import { lighten, makeStyles } from "@material-ui/core/styles";
const { Button } = require('components/button');

export const Con = styled.div`
    display: flex;
    flex-direction: column;
    width: 1050px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    
    > label {
      font-weight: bold;
      font-size: 0.8em;
    }

    > div {
      padding: 5px;
    }
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0 0.8rem 0;
  font-weight: 600;
  > div {
    /* text-align: left; */
    display: grid;
    border-bottom: 1px solid #f0f0f0;
    padding: 0.5em 0 0 2em;
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;

    &:first-child {
      font-weight: 800;
      font-size: 0.9rem;
      color: grey;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }

    > div {
      padding: 0 0 0.5em;
    }
    /* > div:last-child {
      text-align: right;
    } */
  }
`;

export const TableCon = styled.div`
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;
`;

export const SearchCon = styled.div`
  padding: 0.5% 1% 0.5% 1%;
  position: sticky;
  top: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px -3px grey;
  z-index: 2;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SearchBar = styled.input`
  width: 50%;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  background: #f3f3f3;
  border: 0;
  border-radius: 3px;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    marginLeft: "8rem",
  },
}));

export const CoolToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 25px;
`;

export const AddEditService = styled(RawAddEditService)`
    width: 500px;
`;

export const SubmitButton = styled(Button)`
`;

export const DeleteCon = styled.div`
  padding: 5px;
  width: 100%;
  text-align: center;

  > div {
    padding: 5px;
  }
`;

export const ButtonCon = styled.div`
  text-align: center;
`;

export const DeleteButton = styled(Button)`
  width: 25%;
  margin: 5px;
`;

export const AddRateButton = styled(Button)`
      width: 100%;
`;

export const RateCon = styled.div`
   margin: 5px;
`;
