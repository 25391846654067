import { CREATE } from "../../../../constants";
import { useState } from "react";
import styled from "styled-components";

import { EquipmentContainer } from "./equipment-container/equipment-container.component";
import EquipmentModal from "./equipment-modal/equipment-creation-modal.component";
import { MaintenanceContainer } from "./maintenance-container/maintenance-container.component";
import { EQUIPMENT } from "features/equipment/constants";
import { useQuery } from "react-query";
import { getEquipment } from "features/equipment/api/getEquipmentList";

export const EquipmentBodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  padding: 1rem;
`;

export const EquipmentBody = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>(CREATE);
  const [itemType, setItemType] = useState<string>(EQUIPMENT);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const handleOpen = (itemType: string, modalType: string, id?: number) => {
    setItemType(itemType);
    if (id) setSelectedId(id);
    setModalType(modalType);
    setOpen(true);
  };

  const handleSave = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const {
    isLoading,
    isError,
    data: equipment,
  } = useQuery("equipment", getEquipment);

  if (isLoading) return <div className="text-center">Loading...</div>;

  if (isError)
    return (
      <div className="text-center">Something went wrong. Please try again.</div>
    );

  return (
    <>
      <EquipmentBodyContainer>
        <EquipmentContainer handleOpen={handleOpen} equipment={equipment} />
        {/* <MaintenanceContainer handleOpen={handleOpen} /> */}
      </EquipmentBodyContainer>
      <EquipmentModal
        open={open}
        handleSave={handleSave}
        handleClose={handleClose}
        modalType={modalType}
        itemType={itemType}
        selectedEquipment={
          equipment && equipment.length > 0
            ? equipment.filter(
                (equipment: any) => equipment.vehicle_id === selectedId
              )[0]
            : []
        }
      />
    </>
  );
};
