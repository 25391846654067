import React, { useEffect, useState } from "react";
import { Button } from "components/buttonV2";
import { IProgressBarProps } from "../types";
import { FiUsers } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from 'react-i18next';

const ProgressBar = ({
  showSpinnerMessage,
  loading2,
  findDuplicates,
  progress,
  progressMessage,
  clickedArea,
  yesScanned
}: IProgressBarProps) => {
  const { t } = useTranslation();
  const tBase = "views.duplicates.customers.components.ProgressBar";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className="mt-2">
      {!yesScanned ? (
        <div className="flex flex-col items-center justify-center mt-8">
          <FiUsers className="text-5xl text-gray-500" />
          <div className="text-gray-500 text-center my-3">
            {loading2 ? (
              tr("Scanning for duplicates ... Please allow a few minutes to complete.")
            ) : (
              tr("Please click the button below to scan for duplicates.")
            )}
          </div>
          <div>
            <Button 
              onClick={() => findDuplicates("scan")}
              disabled={loading2}
              className="mx-auto mb-10"
            >
              {tr("Scan")} 
              <span className="scale-y-[-1]">
                <HiOutlineRefresh size={20} className={`ml-1 ${loading2 ? "animate-spinReverse" : "animate-none"}`} />
              </span>
            </Button>

            {/* Loading Bar */}
            {(clickedArea === "scan" || clickedArea === "rescan") && (
              <div className="relative mb-5">
                <div className="text-center mb-2">
                  <span className="text-lg text-secondary">
                    {progressMessage || tr("Scanning ...")}
                    <br/>
                    {`${progress.toFixed(0)}%`}
                  </span>
                </div>
                <div className="rounded-full border border-secondary overflow-hidden w-[500px]">
                  <div
                    className="flex h-6 bg-gray-200 animate-pulse bg-gradient-to-r from-blue-200 to-blue-600"
                    style={{ width: `${progress}%` }}
                  >
                    {/* Empty div to allow for gradient fill */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center mt-8">
          <div>
            <FiUsers className="text-5xl text-gray-500" />
          </div>
          <div className="text-gray-500 text-center mb-3 mt-4">
            {tr("Rescanning for duplicates")} ...
          </div>
          <div className="relative mb-5">
            <div className="text-center mb-2">
              <span className="text-lg text-secondary">
                {progressMessage || tr("Scanning ...")}
                <br/>
                {`${progress.toFixed(0)}%`}
              </span>
            </div>
            <div className="rounded-full border border-secondary overflow-hidden w-[500px]">
              <div
                className="flex h-6 bg-gray-200 animate-pulse bg-gradient-to-r from-blue-200 to-blue-600"
                style={{ width: `${progress}%` }}
              >
                {/* Empty div to allow for gradient fill */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProgressBar