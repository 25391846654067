export const persistState = (storageKey, state) => {
    window.localStorage.setItem(storageKey, JSON.stringify(state));
}

export const getIntialState = (storageKey) => {
    const savedState = window.localStorage.getItem(storageKey);
    try {
        if (!savedState) {
            return undefined;
        }
        return JSON.parse(savedState ?? `{}`);
    } catch (e) {
        console.error(`Error loading state : ` + storageKey);
        return undefined;
    }
}

export const removeState = (storageKey) => {
    localStorage.removeItem(storageKey);
}