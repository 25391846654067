import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

const LevelItem = ({ item, warehouseId }) => {
  const { t } = useTranslation();
  const tBase = "views.warehouse.manage.level-item.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [minimum, setMinimum] = useState(item.minimum);
  const [maximum, setMaximum] = useState(item.minimum);
  const [quantity, setQuantity] = useState(item.minimum);

  const [newMin, setNewMin] = useState("");
  const [newMax, setNewMax] = useState("");
  const [newQty, setNewQty] = useState("");

  const updateItem = async () => {
    try {
      const response = await axios({
        method: "put",
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/inventory/setInventoryLevels/${warehouseId}`,
        data: {
          itemId: item.item_id,
          min: newMin,
          max: newMax,
          qty: newQty,
        },
      });

      if (response.status === 200) {
        setMinimum(response.data[0].minimum);
        setMaximum(response.data[0].maximum);
        setQuantity(response.data[0].quantity);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setMinimum(item.minimum);
    setMaximum(item.maximum);
    setQuantity(item.quantity);
  }, [item]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 10,
        border: "1px solid gray",
        padding: 20,
        backgroundColor: "lightgrey",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 20,
          }}
        >
          <div style={{ fontSize: 11 }}>{item.category_name}</div>
          <div style={{ fontSize: 15, fontWeight: "bold" }}>
            {item.item_name}
          </div>
          <div style={{ fontSize: 14 }}>{item.long_description}</div>
          <div style={{ fontSize: 11, fontStyle: "italic" }}>{item.model}</div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="warehouse-value-group">
          <div style={{ fontSize: 14, textDecoration: "underline" }}>
            {tr("Minimum")}:
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", marginBottom: 4 }}>
            {minimum}
          </div>
          <input
            className="warehouse-value-input"
            value={newMin}
            onChange={(e) => setNewMin(e.target.value)}
          />
        </div>

        <div className="warehouse-value-group">
          <div style={{ fontSize: 14, textDecoration: "underline" }}>
            {tr("Maximum")}:
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", marginBottom: 4 }}>
            {maximum}
          </div>
          <input
            className="warehouse-value-input"
            value={newMax}
            onChange={(e) => setNewMax(e.target.value)}
          />
        </div>

        <div className="warehouse-value-group">
          <div style={{ fontSize: 14, textDecoration: "underline" }}>
            {tr("Level")}:
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", marginBottom: 4 }}>
            {quantity}
          </div>
          <input
            className="warehouse-value-input"
            value={newQty}
            onChange={(e) => setNewQty(e.target.value)}
          />
        </div>

        <div className="warehouse-value-button-container">
          <Button
            size="small"
            className="warehouse-value-button"
            primary={true}
            onClick={() => {
              updateItem();
            }}
          >
            {tr("Update")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LevelItem;
