import axios from "axios";
import react, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import SafeArea from "components/safe-area";
import { TInvoice } from "types/invoice";
import Tab from "components/tab";
import useQueryString from "hooks/useQueryString";
import InvoiceTable from "containers/invoiceTable";
import { useTable } from "components/table";
import Field from "components/field";
import { useTranslation } from 'react-i18next';

type Status = {
  id: number;
  label: string;
  color: string;
};
export default function Invoices() {
  const { t } = useTranslation();
  const tBase = "views.invoices.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const history = useHistory(),
    queryString = useQueryString();

  const [search, setSearch] = useState("");

  const table = useTable({
    url: true,
    onPaginate: (page) => queryString.update({ ...queryString.search, page }),
  });

  const invoices = useQuery<{
    invoices: TInvoice[];
    all_invoices_count: number;
    status_counts: { [key: string]: number };
    page_count: number;
    count: number;
  }>(
    ["invoices", queryString.search],
    async () =>
      await axios
        .get(
          `
          ${process.env.REACT_APP_SERVER_URL}/api/v1/company/invoices`,
          {
            params: queryString.search,
          }
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        table.setPageCount(data.page_count || 1);
      },
    }
  );

  function removeParentheses(str: any) {
    return parseInt(str.replace(/[^\d]/g, ""), 10);
  }

  const handleClick = (row: TInvoice) => {
    history.push(`/invoices/${row.id}`);
  };

  const tabData = {
    all: {
      tab: tr("All Invoices"),
      count: invoices.data?.all_invoices_count
        ? removeParentheses(invoices.data.all_invoices_count)
        : 0,
    },
    open: {
      tab: tr("Open"),
      count: invoices.data?.status_counts
        ? invoices.data?.status_counts["17"]
        : 0,
    },
    unpaid: {
      tab: tr("Unpaid"),
      count: invoices.data?.status_counts
        ? invoices.data?.status_counts["33"]
        : 0,
    },
    partially_paid: {
      tab: tr("Partially Paid"),
      count: invoices.data?.status_counts
        ? invoices.data?.status_counts["38"]
        : 0,
    },
    paid: {
      tab: tr("Paid"),
      count: invoices.data?.status_counts
        ? invoices.data?.status_counts["18"]
        : 0,
    },
    // pending: {
    //   tab: "Pending",
    //   count: invoices.data?.status_counts
    //     ? invoices.data?.status_counts["37"]
    //     : 0,
    // },
  };

  return (
    <div className="divide-y">
      <SafeArea>
        <div className="py-4">
          <h1>{tr("Invoices")}</h1>
          <Field
            // onChange={(e) => setSearch(e.target.value)}
            placeholder={tr("Search by Customer")}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.target.blur();
              }
            }}
            onBlur={(e) =>
              queryString.update({
                ...queryString.search,
                search: e.target.value,
                page: 1,
              })
            }
          />
        </div>
        <Tab
          defaultTab={
            queryString.search.tab && !Array.isArray(queryString.search.tab)
              ? queryString.search.tab
              : "all"
          }
          headerOnly
          data={tabData}
          onClick={(e) => queryString.update({ ...queryString.search, tab: e })}
        />
      </SafeArea>
      <SafeArea>
        <div className="py-4">
          <InvoiceTable
            pageIndex={
              queryString.search.page &&
              !Array.isArray(!queryString.search.page)
                ? +queryString.search.page
                : 1
            }
            data={invoices.data?.invoices}
            handleClick={handleClick}
            table={table}
          />
        </div>
      </SafeArea>
    </div>
  );
}
