import React, { useState, useEffect } from "react";
import { Button, MenuButton } from "components/button";
import { ContainerWithSidebar } from "components/layout";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import "./index.scss";
import { reOpenPurchaseOrder } from "api/purchasingApi";

const Complete = ({ fetchCounts }) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.complete.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [poList, setPoList] = useState([]);
  const [currentPo, setCurrentPo] = useState({});

  const fetchComplete = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/purchasing/fetchComplete`
    );

    setPoList(result.data);
  };

  const updatePo = (poId) => {
    poList.map((po) => {
      if (po.id === poId) {
        setCurrentPo(po);
      }
      return po;
    });
  };

  const handleReOpenPurchaseOrder = async (poId) => {
    try {
      let id = null;
      if (parseInt(poId)) {
        id = parseInt(poId);
        setCurrentPo({});
        await reOpenPurchaseOrder(id);
      }
      fetchComplete();
      fetchCounts();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchComplete();
  }, []);

  return (
    <ContainerWithSidebar>
      <div>
        <div className="title">{tr("Complete")} POs</div>
        <div className="menu-buttons">
          {poList &&
            poList.length > 0 &&
            poList.map((po) => {
              return (
                <MenuButton
                  onClick={() => {
                    updatePo(po.id);
                  }}
                  style={{ fontSize: "90%" }}
                >
                  {po.po} ({po.vendor})
                </MenuButton>
              );
            })}
        </div>
      </div>
      <div>
        {currentPo && currentPo.hasOwnProperty("id") ? (
          <div className="complete-po-container">
            <div className="complete-po-header">
              <div className="complete-po-heading">
                <div>
                  {currentPo.po} ({currentPo.vendor})
                </div>
                <Button
                  primary
                  onClick={() => handleReOpenPurchaseOrder(currentPo.id)}
                >
                  {tr("Re-Open")}
                </Button>
              </div>
              <h4 className="complete-po-heading">
                {new Date(currentPo.timestamp).toLocaleDateString()}
              </h4>
              <h4 className="complete-po-heading">{tr("Items")}</h4>
            </div>
            <div className="complete-po-item-row">
              <div className="cpo-th-c-name">{tr("Category")}</div>
              <div className="cpo-th-item-name">{tr("Item")}</div>
              <div className="cpo-th-item-name">{tr("Ordered")}</div>
              <div className="cpo-th-item-name">{tr("Received")}</div>
              <div className="cpo-th-item-name">{tr("Outstanding")}</div>
            </div>
            {currentPo.hasOwnProperty("items") &&
              currentPo.items &&
              currentPo.items.length > 0 &&
              currentPo.items?.map((item) => {
                return (
                  <div className="complete-po-item-row">
                    <div>
                      <div className="complete-po-item-c-name">
                        {item.pc_name}
                      </div>
                      <div className="complete-po-item-c-name">
                        {item.category_name}
                      </div>
                    </div>
                    <div className="complete-po-item-name">
                      {item.item_name}
                    </div>
                    <div className="complete-po-col">{item.quantity}</div>
                    <div className="complete-po-col">{item.received}</div>
                    <div className="complete-po-col">
                      {item.quantity - item.received}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </div>
    </ContainerWithSidebar>
  );
};

export default Complete;
