import React, { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const conAnimate = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

const unmountAnimate = keyframes`
    0% { opacity: 1 }
    100% { opacity: 0 }
`;

const Con = styled.div`
  padding: 1rem;
  background: green;
  color: white;
  border-radius: 5px;
  margin: 1rem;
  animation: ${conAnimate} 0.4s ease-out;
  ${(props) =>
    props.unmounting
      ? css`
          animation: ${unmountAnimate} 0.4s linear forwards;
        `
      : null}
`;

export default function (props) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(() => !animate);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [animate]);

  const transitionEnded = () => {
    if (animate) {
      props.sendBack();
    }
  };

  return (
    <Con
      onAnimationEnd={() => transitionEnded()}
      unmounting={animate ? true : false}
    >
      {props.label}
    </Con>
  );
}
