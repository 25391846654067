/* Created by Hannah Green for ES-171 on 6/22/2021 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Services from "./components/table/services-table";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { SearchCon, SearchBar } from './components/table/styles';
import { BiSearchAlt } from "react-icons/bi";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
    width: 100%;
`;

export default function ServiceList() {
    const { t } = useTranslation();
    const tBase = "views.services.index";
    const tr = (key) => t(`${tBase}.${key}`);

    const [services, setServices] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [categories, setCategories] = useState([]);

    const allServices = useQuery(
        'services',
        async () =>
            await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/allServices`
            ).then((res) => res.data),
        {
            onSuccess: (res) => {
                setServices(res);
            }
        }
    );

    const submitSearch = async () => {
        axios.get(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/searchServices?search_term=${searchTerm}`
        ).then((res) => setServices(res.data));
    };

    const handleSearch = (event) => {
        const target = event.target;
        const value = target.value;

        setSearchTerm(value);
    };

    const handleKeyPress = (event) => {
        if (event.which === 13) {
            submitSearch();
        }
    };

    return (
        <Con>
            <SearchCon>
                <BiSearchAlt size={25} />
                <SearchBar
                    placeholder={tr("Search")}
                    name="search"
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyPress={handleKeyPress}
                ></SearchBar>
            </SearchCon>
            <Services services={services} />
        </Con>
    );
}