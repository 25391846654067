/* Created by Hannah Green for ES-169 on 6/8/2021 */
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import RadioGroup from "components/input/radio";
import { useTranslation } from 'react-i18next';
const { TextArea } = require("components/input");
const { Input } = require("components/input");
const {
	Con,
	Form,
	SubmitButton,
	Header,
	Title,
	Group,
	Info,
	Tire,
	Area,
} = require("./styles");

export default function VehicleInspection() {
	const { t } = useTranslation();
	const tBase = "views.vehicle_inspection.index";
	const tr = (key) => t(`${tBase}.${key}`);

	const [success, setSuccess] = useState(false);
	const [description, setDescription] = useState();

	const initialValues = {
		vehicle_number: "",
		mileage: "",
		tech_name: "",
		belts: "",
		lights: "",
		leaks: "",
		wipers: "",
		mirrors: "",
		power_steering: "",
		oil: "",
		washer_fluid: "",
		antifreeze: "",
		trans_fluid: "",
		brake_fluid: "",
		tires: "",
		tr_prssr_lf: "",
		tr_prssr_rf: "",
		tr_prssr_lro: "",
		tr_prssr_lri: "",
		tr_prssr_rro: "",
		tr_prssr_rri: "",
		description: "",
	};

	const validationSchema = Yup.object({
		vehicle_number: Yup.string().required(),
		mileage: Yup.string().required(),
		tech_name: Yup.string().required(),
		belts: Yup.string().required(),
		lights: Yup.string().required(),
		leaks: Yup.string().required(),
		wipers: Yup.string().required(),
		mirrors: Yup.string().required(),
		power_steering: Yup.string().required(),
		oil: Yup.string().required(),
		washer_fluid: Yup.string().required(),
		antifreeze: Yup.string().required(),
		trans_fluid: Yup.string().required(),
		brake_fluid: Yup.string().required(),
		tires: Yup.string().required(),
		tr_prssr_lf: Yup.string().required(),
		tr_prssr_rf: Yup.string().required(),
		tr_prssr_lro: Yup.string().required(),
		tr_prssr_lri: Yup.string().required(),
		tr_prssr_rro: Yup.string().required(),
		tr_prssr_rri: Yup.string().required(),
	});

	const mutation = useMutation(
		(form) => {
			axios.post(
				`${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/vehicle_inspection/submit`,
				form,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		},
		{
			onSuccess: () => {
				setSuccess(true);
			},
		}
	);

	const onSubmit = async (values) => {
		const { ...form } = values;

		form.description = description;

		const formData = new FormData();

		formData.append("form", JSON.stringify(form));

		mutation.mutate(formData);
	};

	const formik = useFormik({ validationSchema, initialValues, onSubmit });

	const options = [
		{ value: "Good", label: tr("Good") },
		{ value: "Needs Attention", label: tr("Needs Checked") },
	];

	const updateDescription = (input) => {
		setDescription(input.target.value);
	};

	return (
		<Con>
			{!success && (
				<>
					<Title>Vehicle Inspection</Title>

					<Form formik={formik}>
						<Info>
							<Input
								required
								label={tr("Vehicle Number")}
								name="vehicle_number"
							/>

							<Input
								label={tr("Mileage")}
								required={true}
								name="mileage"
							/>

							<Input
								label={tr("Tech Name")}
								required={true}
								name="tech_name"
							/>
						</Info>

						<Header>{tr("Maintenance")}</Header>

						<Group>
							<RadioGroup
								name="belts"
								required
								label={tr("Belts")}
								options={options}
							/>
							<RadioGroup
								name="lights"
								required
								label={tr("Lights")}
								options={options}
							/>
							<RadioGroup
								name="leaks"
								required
								label={tr("Leaks")}
								options={options}
							/>
							<RadioGroup
								name="wipers"
								required
								label={tr("Wipers")}
								options={options}
							/>
							<RadioGroup
								name="mirrors"
								required
								label={tr("Mirrors")}
								options={options}
							/>
							<RadioGroup
								name="tires"
								required
								label={tr("Tire Wear")}
								options={options}
							/>
						</Group>

						<Header>Fluid Levels</Header>
						<Group>
							<RadioGroup
								name="power_steering"
								label={tr("Power Steering")}
								required
								options={options}
							/>
							<RadioGroup
								name="oil"
								required
								label={tr("Oil")}
								options={options}
							/>
							<RadioGroup
								name="washer_fluid"
								required
								label={tr("Washer Fluid")}
								options={options}
							/>
							<RadioGroup
								name="antifreeze"
								required
								label={tr("Antifreeze")}
								options={options}
							/>
							<RadioGroup
								name="trans_fluid"
								required
								label={tr("Transmission Fluid")}
								options={options}
							/>
							<RadioGroup
								name="brake_fluid"
								required
								label={tr("Brake Fluid")}
								options={options}
							/>
						</Group>

						<Header>{tr("Tire Pressure")}</Header>

						<Tire>
							<Input
								label={tr("LF - Left Front")}
								required={true}
								name="tr_prssr_lf"
							/>

							<Input
								label={tr("RF - Right Front")}
								required={true}
								name="tr_prssr_rf"
							/>

							<Input
								label={tr("LRO - Left Rear Outside")}
								required={true}
								name="tr_prssr_lro"
							/>

							<Input
								label={tr("LRI - Left Rear Inside")}
								required={true}
								name="tr_prssr_lri"
							/>

							<Input
								label={tr("RRO - Right Rear Outside")}
								required={true}
								name="tr_prssr_rro"
							/>

							<Input
								label={tr("RRI - Right Rear Inside")}
								required={true}
								name="tr_prssr_rri"
							/>
						</Tire>

						<Area>
							<TextArea
								label={tr("Description of things that need attention")}
								name="description"
								onChange={(value) => updateDescription(value)}
							/>
						</Area>

						<SubmitButton type="submit" primary>
							{tr("Submit")}
						</SubmitButton>
					</Form>
				</>
			)}

			{success && (
				<>
					<Title>
						{tr("Thank you for submitting you vehicle inspection")}
					</Title>
				</>
			)}
		</Con>
	);
}
