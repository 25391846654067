import React, { useCallback, useEffect, useState } from "react";
import { Marker } from "react-map-gl";
import { FaMapMarker, FaMapMarkerAlt } from "react-icons/fa";

import styles from "./AppointmentMarker.module.scss";
import { formatTime } from "utils/formatTime";
import _ from "lodash";

const AppointmentMarker = ({
  marker,
  users,
  center,
  filteredMarkers,
  showAddresses,
  showTimes,
  optimizeState,
}) => {
  const [showIds, setShowIds] = useState([]);

  useEffect(() => {
    if (filteredMarkers && filteredMarkers.length > 0) {
      setShowIds(filteredMarkers.map((m) => parseInt(m.id)));
    }
  }, [filteredMarkers]);

  if (!center) return null;

  const {
    project_details: projectDetails,
    request_details: requestDetails,
    assignees,
  } = marker;

  const backgroundColor =
    assignees &&
    assignees.length === 1 &&
    users &&
    users.length > 0 &&
    users.filter((u) => {
      return parseInt(u.value) === parseInt(assignees[0].user_id);
    }).length > 0 &&
    users.filter((u) => {
      return parseInt(u.value) === parseInt(assignees[0].user_id);
    })[0] &&
    users
      .filter((u) => {
        return parseInt(u.value) === parseInt(assignees[0].user_id);
      })[0]
      .hasOwnProperty("color") &&
    users.filter((u) => {
      return parseInt(u.value) === parseInt(assignees[0].user_id);
    })[0].color &&
    parseInt(assignees[0].user_id)
      ? `${
          users.filter((u) => {
            return parseInt(u.value) === parseInt(assignees[0].user_id);
          })[0].color
        }`
      : `rgba(50, 50, 50, 1)`;

  const getAddress = () => {
    let address = null;

    if (
      requestDetails &&
      requestDetails[0] &&
      requestDetails[0].customer_address
    ) {
      address = requestDetails[0].customer_address;
    } else if (
      projectDetails &&
      projectDetails[0] &&
      projectDetails[0].customer_address
    ) {
      address = projectDetails[0].customer_address;
    }

    return address;
  };

  const address = getAddress();

  return (
    <Marker longitude={center[0]} latitude={center[1]} anchor="bottom">
      <div onClick={() => {}} className={styles.markerContainer}>
        {(showTimes || showAddresses) &&
          showIds.indexOf(parseInt(marker.id)) >= 0 && (
            <div className={styles.pinLabel}>
              {showTimes && marker.start_time && (
                <div className={styles.time}>
                  {formatTime(marker.start_time)}
                </div>
              )}
              {showTimes && showAddresses && (
                <div className={styles.verticalBreak} />
              )}
              {showAddresses && address && (
                <div className={styles.address}>{address}</div>
              )}
            </div>
          )}
        <div className={styles.appointmentPinContainer}>
          <FaMapMarkerAlt
            size={38}
            color={backgroundColor}
            className={styles.appointmentPin}
          />
        </div>
        <div className={styles.orderContainer}>
          {optimizeState &&
          _.find(optimizeState, { address: address }) &&
          _.find(optimizeState, { address: address })?.order ? (
            <div className={styles.order}>
              {_.find(optimizeState, { address: address })?.isStart &&
              _.find(optimizeState, { address: address })?.isEnd
                ? `1,${_.find(optimizeState, { address: address })?.order}`
                : _.find(optimizeState, { address: address })?.order}
            </div>
          ) : null}
        </div>
      </div>
    </Marker>
  );
};

export default AppointmentMarker;
