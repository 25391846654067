import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled, { css } from "styled-components";
import NewField from "./newcategory";
import {useMutation, useQuery} from 'react-query'
import { RiDeleteBin2Line } from "react-icons/ri";
import {
  applySick,
  listFunction,
  reorderChild,
  reorder,
  fetching,
  fetchingCat,
  merge,
  remove,
  reorderCategory,
} from "./action";
import { IoIosAddCircle, IoIosArrowDown } from "react-icons/io";
// import { GrInspect } from 'react-icons/gr'
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { team } from "redux/actions";
import socket from "./socket";
// import { useHistory } from 'react-router-dom'
import Alert from "components/alert";
import Message from "./message";
import Axios from "axios";
import { theme } from "styles/theme";
import { format } from "date-fns";
import User from "./user";
import { Avatar } from "components/avatar";
import { useTranslation } from 'react-i18next';

const MainCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: linear-gradient(to bottom right, #014156, #370037);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: calc(100vh - ${theme.height.navigation});
  flex: 1;
  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

const Con = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  overflow: auto;
  &::-webkit-scrollbar {
    background: black;
    border-radius: 10px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2c2c2c;
  }
  &::-webkit-scrollbar-track {
    border-radius: 25px;
    background: #cacaca;
  }
  > div {
    margin: 0.5rem;
    border-radius: 5px;
    padding: 0.5rem;
    width: 5;
  }
  > div:last-child {
    background: rgb(54, 73, 91, 0.5);
  }
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  background: #36495b;
  color: white;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 220px;
  > div:last-child {
    min-height: 100px;
    min-width: 100%;
  }
`;

const Unassigned = styled.div`
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background: #36495b;
  color: white;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > div:last-child {
    // display: flex;
    ${(props) =>
      !props.view
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
    width: 100%;
    // flex: 1;
    overflow-x: auto;
    flex-direction: row;
    min-height: 10px;
    > div {
      margin-right: 0.5rem;
      flex: 0 0 200px;
    }
    &::-webkit-scrollbar {
      background: black;
      border-radius: 10px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #2c2c2c;
    }
    &::-webkit-scrollbar-track {
      border-radius: 25px;
      background: #cacaca;
    }
  }
`;

const HideUnHide = styled(IoIosArrowDown)`
  position: absolute;
  right: 1rem;
  color: white;
  top: 0.5rem;
  font-size: 1.7rem;
  ${(props) =>
    props.view === "true" &&
    css`
      transform: rotate(-90deg);
    `}
`;

const Add = styled(IoIosAddCircle)`
  margin: 50px;
  width: 100px;
  height: 100px;
  color: grey;
  border-radius: 50%;
  :hover {
    cursor: pointer;
    color: #a6ff9c;
  }
`;

const Update = styled.button`
  background: rgb(0, 0, 0, 0.5);
  font-size: 1rem;
  color: white;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  padding: 0.5rem;
  outline: none;
  &:hover {
    // background: black;
  }
  &:first-child {
    margin: 0.5rem 0 0.5rem 0.5rem;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid grey;
  }
  &:nth-child(2) {
    margin: 0.5rem 0.5rem 0.5rem 0;
    border-radius: 0 5px 5px 0;
  }
  ${(props) =>
    props.editable &&
    css`
      background: #f77100;
    `}
`;

const AlertCon = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
`;

const TeamDashboard = (props) => {
  const { t } = useTranslation();
  const tBase = "views.team.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [watcher, setWatcher] = useState();
  const [emptyCon, setCon] = useState(false);
  const [arrayofAlert, setArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [edit, setEditable] = useState(true);
  const [messageCon, setMessageCon] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [arrayOfUpdates, setUpdates] = useState([]);

  const [unassignView, setUnassignView] = useState(false);

  const employee = useSelector((state) => state.team.employee);
  const images = useSelector((state) => state.team.image);
  const dispatch = useDispatch();

  const onDragEnd = async (result) => {
    let {
      combine,
      source: { index: sourceIndex, droppableId: sourceDrop },
      destination,
      draggableId: employeeId,
      type,
    } = result;
    if (combine) {
      merge(result, employee, async (res) => {
        if (res) {
          dispatch(res);
          socket.emit("merge", result);
          let fetchType = "ADDCHILD";
          await fetching(
            fetchType,
            employeeId,
            sourceDrop,
            result.combine.droppableId,
            null,
            result.combine.draggableId,
            result.source.index,
            null
          );
        }
      });
    } else if (destination) {
      let { droppableId: destDrop, index: destIndex } = destination;
      if (type === "category" && sourceIndex !== destIndex) {
        // console.log("reorder", sourceIndex, destIndex, employee);
        await dispatch(reorderCategory(sourceIndex, destIndex, employee));
        const newemployee = (reorderCategory(sourceIndex, destIndex, employee)).employee
        let fetchType = "REORDERCATEGORY";
        await fetchingCat(
          fetchType,
          newemployee.map((a)=>{return a.category_id})
        );
        socket.emit("categorymove", [sourceIndex, destIndex]);
      }
      if (destDrop !== sourceDrop) {
        let sourceCat, destCat, sourceParent, destParent, fetchType;
        switch (type) {
          case "droppable":
            dispatch(reorderChild(result, employee));
            let a = destDrop.split("-");
            let b = sourceDrop.split("-");
            [destParent, destCat] = a;
            [sourceParent, sourceCat] = b;
            fetchType = "MOVECHILD";
            socket.emit("childmove", result);
            break;

          case "droppableSubItem":
            dispatch(reorder(result, employee));
            destCat = destDrop;
            sourceCat = sourceDrop;
            fetchType = "REORIENT";
            socket.emit("parentmove", result);
            break;
          default:
            break;
        }
        await fetching(
          fetchType,
          employeeId,
          sourceCat,
          destCat,
          sourceParent,
          destParent
        );
      }
    }
  };


  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      let res = await Axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/v1/eserv-business/team?date=${format(new Date(), "yyyy-MM-dd")}`
      );
      res = res.data;
      dispatch(team(res[0], res[1]));
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const openDialog = useCallback(
    (req) => {
      setArray((array) => {
        let old = [...array];
        old.unshift(
          <Alert key={Math.random()} label={req} sendBack={() => splicing()} />
        );
        if (old.length > 5) {
          old.pop();
        }
        return old;
      });
      setIndex(index + 1);
    },
    [index]
  );

  const splicing = () => {
    setArray((array) => {
      var old = [...array];
      old.pop();
      return old;
    });
  };

  const editable = () => {
    setEditable(() => !edit);
  };

  const deletecategory = useMutation((category_id) => {
    Axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/team`,
      {
        type: "DELETE",
        id: category_id,
      }
    ).then(()=>{
      fetchData();
    });
  });


  useEffect(() => {
    if (!isLoading && arrayOfUpdates.length > 0) {
      arrayOfUpdates.forEach(({ type, data }, i) => {
        switch (type) {
          case "merge":
            merge(data, employee, (res) => {
              dispatch(res);
              openDialog("Crew merged.");
            });
            break;
          case "removechild":
            dispatch(remove(data[0], data[1], data[2], employee));
            break;
          case "parentmove":
            dispatch(reorder(data, employee));
            break;
          case "childmove":
            dispatch(reorderChild(data, employee));
            break;
          case "categorymove":
            dispatch(reorderCategory(data[0], data[1], employee));
            fetchData()
            break;
          case "applysick":
            dispatch(applySick(data.category, +data.employeeId, employee));
            break;
          default:
            break;
        }
      });
      setUpdates([]);
    }
  }, [dispatch, isLoading, arrayOfUpdates, employee, openDialog,fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    socket.on("loggedIn", (data) => {
      setWatcher(data);
    });
    return () => {
      socket.off("loggedIn");
    };
  }, [watcher]);

  useEffect(() => {
    socket.on("initial", () => {
      fetchData();
    });
    socket.on("merge", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({ type: "merge", data });
        return newUpdates;
      });
    });
    socket.on("removechild", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({ type: "removechild", data });
        return newUpdates;
      });
      openDialog("Child removed.");
    });
    socket.on("parentmove", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({ type: "parentmove", data });
        return newUpdates;
      });
      openDialog("Parent moved.");
    });
    socket.on("childmove", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({ type: "childmove", data });
        return newUpdates;
      });
      openDialog("Child moved.");
    });
    socket.on("categorymove", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({ type: "categorymove", data });
        return newUpdates;
      });
      openDialog("Category moved.");
    });
    socket.on("applysick", (data) => {
      setUpdates((updates) => {
        let newUpdates = [...updates];
        newUpdates.push({
          type: "applysick",
          ...data,
        });
        return newUpdates;
      });
      openDialog("New Absence.");
    });
    return () => {
      socket.off("merge");
      socket.off("removechild");
      socket.off("parentmove");
      socket.off("childmove");
      socket.off("categorymove");
      socket.off("initial");
      socket.off("applysick");
    };
  }, [fetchData, openDialog, employee]);

  const firstList = useMemo(
    () =>
      employee[0] ? (
        <Unassigned key={employee[0]["category_id"]} view={unassignView}>
          <HideUnHide
            view={unassignView.toString()}
            onClick={() => {
              setUnassignView((view) => !view);
            }}
          />
          <div>{employee[0]["category_label"]}</div>
          {listFunction(
            employee[0]["category_id"],
            employee[0]["child"],
            "horizontal",
            employee[0]["category_index"],
            employee,
            images,
            edit
          )}
        </Unassigned>
      ) : null,
    [images, employee, edit, unassignView]
  );

  const employeeList = useMemo(() => {
    return employee
      .filter((cat) => cat.category_id !== "-2")
      .map((e, i) => {
        if (i !== 0) {
          return (
            <Draggable
              isDragDisabled={edit}
              draggableId={"c-" + e["category_id"].toString()}
              index={i}
              key={e["category_id"]}
            >
              {(provided) => (
                <Category
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  key={e["category_id"]}
                >
                  <div style={{width:'100%'}}><p style={{display:'inline'}}>{e["category_label"]}</p><button onClick={()=>{deletecategory.mutate(e.category_id)}} style={{verticalAlign:'bottom',float:"right"}}> <RiDeleteBin2Line style={{display:'inline',float:'right',color:'red',verticalAlign:'bottom'}}/> </button></div>
                  {listFunction(
                    e["category_id"],
                    e["child"],
                    "vertical",
                    e["category_index"],
                    employee,
                    images,
                    edit
                  )}
                </Category>
              )}
            </Draggable>
          );
        } else {
          return null;
        }
      });
  }, [images, employee, edit, deletecategory]);

  const absentee = employee.find((cat) => cat.category_id === "-2");

  return (
    <MainCon>
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <Update editable={!edit && "green"} onClick={() => editable()}>
            {edit ? tr("EDIT") : tr("SAVE")}
          </Update>
          <Update
            editable={messageCon && "green"}
            onClick={() => setMessageCon(!messageCon)}
          >
            {tr("MESSAGE")}
          </Update>
          {firstList}
        </div>
        <Droppable
          type="category"
          droppableId={"category"}
          direction="horizontal"
        >
          {(provided) => (
            <Con ref={provided.innerRef} {...provided.droppableProps}>
              {employeeList}
              {provided.placeholder}
              {emptyCon ? (
                <NewField
                  display={emptyCon}
                  added={() => {
                    fetchData();
                    setCon(false);
                  }}
                />
              ) : null}
              <Category>
                <Add onClick={() => setCon(true)} />
              </Category>
            </Con>
          )}
        </Droppable>
      </DragDropContext>
      {absentee && (
        <div className="absolute bottom-1 text-red-200 m-2 p-1 bg-red-800/50 !w-auto rounded !flex !flex-col ">
          <div>{tr("Absence")}</div>
          <div className="flex">
            {absentee.child?.map((f, i) => {
              return f ? (
                <Avatar
                  size="xsmall"
                  showLabel
                  key={f.employee_id}
                  user_id={f.employee_id}
                >
                  {f.employee_name}
                </Avatar>
              ) : null;
            })}
          </div>
        </div>
      )}

      <AlertCon>{arrayofAlert}</AlertCon>
      {messageCon && <Message close={() => setMessageCon(false)} />}
    </MainCon>
  );
};

export default TeamDashboard;
