import * as React from "react";
import styled, { css } from "styled-components";
import { OutlineButton, SubtleButton } from "components/button";
import { Count, Category, Query } from "./index";
import { theme } from "styles/theme";
import Switch from "rc-switch";
import useQueryString from "hooks/useQueryString";
import { useQuery } from "react-query";
import axios from "axios";
import { useTranslation } from 'react-i18next';

interface Props {
  count?: Count[];
  query?: Query;
  categories?: Category[];
  selected?: string;
  handleStatusClick: (status: string) => void;
  setAttention: (a: any) => any;
  attention: boolean;
  beginDate?: string;
  endDate?: string;
  setBeginDate: (date: string) => void;
  setEndDate: (date: string) => void;
}
export default function EstimateFilter({
  count,
  handleStatusClick,
  setAttention,
  attention,
  query,
  selected,
  categories,
  beginDate,
  endDate,
  setBeginDate,
  setEndDate,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.estimates.estimateFilter";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const statuses = ["Won", "Lost", "Converted to Job", "Draft", "Waiting For Response"]
  const queryString = useQueryString();

  const { invoice } = queryString.search;

  const attentionCounts = useQuery<number>(
    ["attention counts"],
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/request/attention-counts
  `
        )
        .then((res) => res.data)
  );

  const populateStatus = statuses.map((status) => {
    const findCount = count?.find((count) => status === count.status);

    return (
      <List
        key={status}
        clicked={status === selected}
        onClick={() => {
          handleStatusClick(status);
        }}
      >
        <div>{tr(status)}</div>
        <div>{findCount?.count || 0}</div>
      </List>
    );
  });

  React.useEffect(() => {
    if (beginDate || endDate) {
      queryString.update({
        ...queryString.search,
        date_won_begin: beginDate,
        date_won_end: endDate,
      });
    } else {
      queryString.update({
        ...queryString.search,
        date_won_begin: undefined,
        date_won_end: undefined,
      });
    }
  }, [beginDate, endDate]);

  return (
    <Con className="border-r">
      <ListCategory>
        <p>
          {tr("Status")}
        </p>
        <p onClick={() => handleStatusClick("showAll")} style={{ color: "#FF4F00", cursor: "pointer" }}>
          {tr("Show All")}
        </p>     
      </ListCategory>
      {populateStatus}
      <span
        style={{ textAlign: "center" }}
      >
        {tr("Date Won Range")}
      </span>
      <CheckboxRow>
      {/* checkbox for date range for won date */}

        {/* begin date */}
        <input
          type="date"
          onChange={(e) => {
            setBeginDate(e.target.value);
          }}
          value={beginDate || ""}
          style={{ fontSize: "0.8rem" }}
        />
        {/* end date */}
        <input
          type="date"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
          value={endDate || ""}
          style={{ fontSize: "0.8rem" }}
        />
      </CheckboxRow>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListCategory = styled.h4`
  padding: 0;
  margin: 1rem 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const List = styled.div<{ clicked?: boolean }>`
  font-size: 0.9rem;
  display: flex;
  color: #2e2e2e;
  text-transform: capitalize;
  // border-bottom: 1px solid #d6dbdf;
  padding: 0.7rem 1rem;
  cursor: pointer;
  &:hover {
    background: #f1f4f9;
  }
  > div:last-child {
    padding: 0 0.5rem;
    border-radius: 10px;
    margin-left: auto;
  }
  ${(props) =>
    props.clicked &&
    css`
      font-weight: bold;
      background: ${theme.bg.blue};
      color: ${theme.color.main};
    `}
`;

const CheckboxRow = styled.li`
  padding: 0 1rem;
  display: flex;
  align-items: center;
  > input {
    margin-right: 0.5rem;
  }
`;
