import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';

import styles from "./Item.module.scss";
import { Button } from "components/button";

const Item = ({
  itemId,
  itemName,
  imageUri,
  description,
  model,
  inStock,
  maximum,
  minimum,
  outstanding,
  addToCart,
}) => {
  const { t } = useTranslation();
  const tBase = "views.purchasing.order.Item";
  const tr = (key) => t(`${tBase}.${key}`);

  const [added, setAdded] = useState(false);
  const [value, setValue] = useState(
    maximum - (inStock + outstanding) < 0
      ? 0
      : maximum - (inStock + outstanding)
  );
  const [failedToAdd, setFailedToAdd] = useState(false);

  const handleAdd = () => {
    setFailedToAdd(false);
    if (parseFloat(value) && parseFloat(value) > 0) {
      addToCart(itemId, value);
      setAdded(true);
    } else {
      setFailedToAdd(true);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {description && (
          <Tooltip title={description} placement="bottom-end">
            {itemName && <h5 className={styles.heading}>{itemName}</h5>}
          </Tooltip>
        )}
        {model && <div className={styles.model}>{model}</div>}
        <img
          loading="lazy"
          className={styles.image}
          src={imageUri || null}
          alt={`item-${itemId}`}
        />
      </div>
      <div>
        <div className={styles.bodyText}>
          {tr("In Stock")}:{" "}
          <span className={inStock < minimum ? styles.redText : null}>
            {inStock}
          </span>
        </div>
        <div className={styles.bodyText}>{tr("Minimum")}: {minimum}</div>
        <div className={styles.bodyText}>{tr("Maximum")}: {maximum}</div>
        <div className={styles.bodyText}>{tr("Outstanding")}: {outstanding}</div>
        <div>
          {tr("Recommended")}:{" "}
          {maximum - (inStock + outstanding) < 0
            ? 0
            : maximum - (inStock + outstanding)}
        </div>
        <div>
          <div className={styles.row}>
            <input
              className={failedToAdd ? styles.inputFailed : styles.inputNormal}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Button
              disabled={added}
              primary={!added}
              onClick={handleAdd}
              className={styles.addButton}
              style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10 }}
            >
              +
            </Button>
          </div>
          {failedToAdd && (
            <div className={styles.error}>
              {tr("Must Be Integer Greater Than Zero")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
