import React from "react";
import phase2video from "videos/onboarding-phase-2.mp4";
import poster from "../../../images/eserv.png";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import CheckboxInput  from "./CheckboxInput";
import { ISetChecked } from "../interface";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';

type Phase2Props = {
  className?: string;
  updateCheckedField: (
    phase: "phase1" | "phase2" | "phase3", 
    fieldName: string, 
    value: boolean
    ) => void;
  checked?: ISetChecked;
  handleClickPhase: (phase: "phase1" | "phase2" | "phase3") => void;
  isPhase2Complete?: boolean;
}

const Phase1: React.FC<Phase2Props> = ({ className, updateCheckedField, checked, handleClickPhase, isPhase2Complete }) => {
  const { t } = useTranslation();
  const tBase = "features.onboarding-wizard.phase2";
  const tr = (key: string) => t(`${tBase}.${key}`);

  return (
    <div className={`${className} mt-10 mb-6`}>
      <video className="w-full h-[200px] mb-2 z-[2]" controls poster={poster}>
        <source src={phase2video} type="video/mp4"/>
      </video>
      <div className="z-1 text-gray-600 ">

        {/* Phase title */}
        <div className="text-lg font-semibold text-gray-700">
          <span className="text-secondary">{tr("Phase 2")} </span>- {tr("Importing Your Customers, Inventory, and Services")}
        </div>

        {/* Description */}
        <div className="mt-2">
         {tr("Congratulations on completing Phase 1! In this phase, we'll walk you through the process of importing essential data that drives your business operations. We'll help you add your customers, inventory, and services into EServ. Additionally, we'll finalize this phase by adding your vendors and your company's terms and conditions.")}     
        </div>

        {/* Content */}
        <div className="text-gray-600 mt-2">

          {/* Customer List */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase2" 
                fieldName="customerList" 
                onChange={updateCheckedField} 
                checked={checked?.phase2?.customerList || false}
              />
              <Link to="/admin/import" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Customer List")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("Let's get your customers added to EServ. Customer lists can be imported in either an excel or CSV file. It is highly recommended that you review this information before importing to EServ, as only you know what information is valid for your business.")}
              </div>
              <div>
                {tr("You can visit the import page by selecting Settings from the navigation menu or by clicking on the")}&nbsp; 
                <Link to="/admin/import" className="text-secondary no-underline hover:underline">
                {tr("Customer List")}
                </Link>
                &nbsp;{tr("link. To get started, select the Customers tile. You can now either upload a file by drag-and-drop or Browse. You may also want to download the provided template to ensure accuracy when transferring your customer data.")}
              </div>
              <div>
                {tr("After successfully uploading a file you will be directed to match the excel headers from your file to the matching EServ Values. This helps bring your data into Eserv so accuracy is important. Please review your selection with the Preview button before continuing. Once verified click Continue to fully transfer your customers into Eserv. And remember, your onboarding specialist is on standby for any assistance you may need as well.")}
              </div>
              <div> 
                {tr("Once the import is completed you can view your customers on the")}&nbsp;
                <Link to="/customers" className="text-secondary no-underline hover:underline">
                  {tr("Customers")}
                </Link> 
                &nbsp;{tr("page")}.
              </div>
              <div>
                {tr("You may want to check for any potential duplicates found during the import process or if you already have customer data present in EServ. This can be done by visiting the")}&nbsp;
                <Link to="/customers" className="text-secondary no-underline hover:underline">
                  {tr("Customers")}
                </Link> 
                &nbsp;{tr("page and clicking 'View Duplicates' button.")} 
              </div>
            </div>
          </div>

          {/* Inventory List */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase2" 
                fieldName="inventoryList" 
                onChange={updateCheckedField} 
                checked={checked?.phase2?.inventoryList || false}
              />
              <Link to="/admin/import" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Inventory List")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("Inventory can be provided one of two ways. By using the import tool for Inventory that can be used with either excel or CSV. Or you can provide your onboarding specialist with your vendor contact information, and we will attempt to acquire the information directly.")}
              </div>
              <div>
                {tr("To import by using the import tool, visit the import page by selecting Settings from the navigation menu or by clicking on the")}&nbsp;
                <Link to="/admin/import" className="text-secondary no-underline hover:underline">
                  {tr("Inventory List")}
                </Link>  
                &nbsp;{tr("link. To get started, select the Inventory tile. Similar to the instructions for the customers import, you can download and use a template or just upload a file directly via drag-and-drop or Browse.")}
              </div>
              <div>
                {tr("After successfully uploading a file you will be directed to match the excel headers from your file to the matching EServ Values. This helps bring your data into Eserv so accuracy is important. Please review your selection with the Preview button before continuing. Once verified click Continue to fully transfer your inventory into EServ. And remember, your onboarding specialist is on standby for any assistance you may need as well.")}
              </div>
              <div> 
                {tr("Once the import is completed you can view your inventory on the")}&nbsp;
                <Link to="/products" className="text-secondary no-underline hover:underline">
                  {tr("Inventory")}
                </Link> 
                &nbsp;{tr("page")}.
              </div>
            </div>
          </div>

          {/* Services */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase2" 
                fieldName="servicesList" 
                onChange={updateCheckedField} 
                checked={checked?.phase2?.servicesList || false}
              />
              <Link to="/admin/import" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Services")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("Services can be imported in either an excel or CSV file. To import by using the import tool, visit the import page by selecting Settings from the navigation menu or by clicking on the")}&nbsp;
                <Link to="/products" className="text-secondary no-underline hover:underline">
                {tr("Products")}
                </Link>  
                &nbsp;. {("To get started, select the Services tile. Similar to the instructions for the customers and inventory import, you can download and use a template or just upload a file directly via drag-and-drop or Browse.")}
              </div>
              <div>
                {tr("After successfully uploading a file you will be directed to match the excel headers from your file to the matching EServ Values. This helps bring your data into Eserv so accuracy is important. Please review your selection with the Preview button before continuing. Once verified click Continue to fully transfer your inventory into EServ. And remember, your onboarding specialist is on standby for any assistance you may need as well.")}
              </div>
              <div> 
                {tr("Once the import is completed you can view your services on the")}&nbsp;
                <Link to="/products" className="text-secondary no-underline hover:underline">
                  {tr("Services")}
                </Link> 
                &nbsp;{tr("tab on the Inventory page.")}
              </div>
            </div>
          </div>

          {/* Vendor List */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase2" 
                fieldName="vendorList" 
                onChange={updateCheckedField} 
                checked={checked?.phase2?.vendorList || false}
              />
              <Link to="/vendors" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Vendor List")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="[&>*]:mb-2">
              <div>
                {tr("Now that you have your parts added we'll want to add your vendors so that you can easily create purchase orders and keep your stock levels exactly where you need them. Start by visiting the Vendor page by, or by clicking on the")}&nbsp;
                <Link to="/vendors" className="text-secondary no-underline hover:underline">
                  {tr("Vendor List")}
                </Link>   
                &nbsp;{tr("link. Proceed by clicking the button labeled 'New Vendor' to add each of your Vendors.")}
              </div>
            </div>
          </div>

          {/* Terms and Conditions */}
          <div className="mt-2">
            <div className="flex items-center mb-1">
              <CheckboxInput 
                phase="phase2" 
                fieldName="termsAndConditions" 
                onChange={updateCheckedField} 
                checked={checked?.phase2?.termsAndConditions || false}
              />
              <Link to="/admin/custom-docs" className="[&>*]:inline-block">
                <div className="text-secondary font-bold hover:underline">{tr("Terms and Conditions")}:</div>
                <FiExternalLink className="ml-1 mb-1 text-gray-500 cursor-pointer hover:text-primary" />
              </Link>
            </div>
            <div className="mb-2">
              {tr("It's time to add your terms and conditions for invoices and Estimate. Multiple options for each type can be created and used as required. To get started click on the")}&nbsp;
              <Link to="/admin/custom-docs" className="text-secondary no-underline hover:underline">
                {tr("Terms and Conditions")}
              </Link> 
              &nbsp;{tr("link. Examples of an estimate and an invoice will be here for you to review. To create custom versions for your company, click on the button labeled 'Create Custom Document'.")}
            </div>
          </div>

          {/* Next Steps */}
          <div className="mt-6 border-t">
            <div className="my-4">
              {tr("Continue to Phase 3 once you have completed the tasks above.")}
            </div>
            <Button 
              primary
              disabled={!isPhase2Complete}
              onClick={() => handleClickPhase("phase3")}
            >
              {tr("Phase 3")}
            </Button>
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default Phase1;