import React from "react";
import styled from "styled-components";
import { Input } from "components/input";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button, IconButton, MenuButton } from "components/button";
import Form from "components/form2";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import { useFormik } from "formik";
import Popper from "components/react-popperv2";
import { UserInfo } from "../user";
import { theme } from "styles/theme";
import { diff } from "deep-object-diff";
import { TDivision } from "types/division";
import DivisionDropdown from "components/react-select/division";
import Switch from "rc-switch";
import Image from "components/image";
import { RiCamera2Fill } from "react-icons/ri";
import Imageuploader from "views/team/edit/imageuploader";
import ImageUploadButton from "components/button/imageUploadButton";
import ReactCrop from "react-image-crop";
import { useTranslation } from 'react-i18next';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 45%;
  }
`;

type Props = {
  info?: UserInfo;
  updateUser?: boolean | false;
};

export default function NewUserModal({ info, updateUser }: Props) {
  const { t } = useTranslation();
  const tBase = "views.admin.container.newuser";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const history = useHistory();
  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [blob, setBlob] = React.useState<any>();
  const [cropped, setCropped] = React.useState<any>();
  const [crop, setCrop] = React.useState<any>({
    unit: "%",
    width: 50,
    aspect: 1 / 1,
    x: 25,
    y: 25,
  });
  const imgRef = React.useRef(null);

  const onLoad = (img: any) => {
    imgRef.current = img;
  };

  const initialValues = info
    ? {
        ...info,
        division: info.division?.id,
      }
    : {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        phone: "",
        test: "",
        division: null,
        no_account: false,
        job_title_label: "",
      };

  const mutation = useMutation(
    async (e: any) => {
      return await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/newUser`,
          e
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        history.push(`/admin/employees/${res}/permission/team`);
        setModal();
        showSnackbar(tr("New User created!"));
      },
    }
  );

  const saveChangesMutation = useMutation(
    async (e: any) =>
      await axios.post(
        `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/editUser/${info?.user_id}
  `,
        e
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setModal();
        showSnackbar(tr("Employee info updated!"));
      },
    }
  );

  const emailValidator = 
    updateUser ? yup.string().required("Required") :
    yup.string().test({
      name: 'email',
      message: 'Please enter a valid email address.',
      test: async function (value) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        // if no val or no regex match, return error
        if (!value || !emailRegex.test(value)) {
          return this.createError({
            path: 'email',
            message: tr("Please enter a valid email address.")
          })
        }

        const emailExists = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/company/customers`, 
        {
          params: {
            type: "CHECK_FOR_EXISTING_EMAIL",
            email: value
          }
        })

        if (emailExists.data) {
          return this.createError({
            path: 'email',
            message: tr("Email already exists.")
          })
        }

        // if email exists, forbid submission, else allow submission
        return emailExists.data ? false : true; 
      }
    })
  

  const validationSchema = yup.object({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    username: yup.string().required("Required"),
    email: emailValidator,
    no_account: yup.boolean().required("Required"),
    job_title_label: yup.string().notRequired(),
  });

  const onSubmit = async (e: any, { setErrors }: any) => {
    const formData = new FormData();

    if (cropped) {
      const file = new File([cropped], "profile.png");
      formData.append("image", file);
    }

    if (info) {
      let differences = JSON.stringify(diff(info, e));
      formData.append("data", differences);

      saveChangesMutation.mutate(formData);
    } else {
      formData.append("data", JSON.stringify(e));
      try {
        await mutation.mutateAsync(formData);
      } catch (e: any) {
        if (e.data === "email") {
          setErrors({ email: tr("Email has been taken") });
        } else if (e.data === "username") {
          setErrors({ username: tr("Username has been taken") });
        }
      }
    }
  };

  const cropCompletion = async () => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, "newFile.jpeg");
    }
  };

  const formik = useFormik({ onSubmit, initialValues, validationSchema });

  const createCropPreview = async (image: any, crop: any, fileName: any) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx &&
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        // blob.name = fileName;
        setCropped(blob);

        // props.updateBlob(blob);
      });
    });
  };

  if (blob) {
    return (
      <div className="flex flex-col">
        <ReactCrop
          ruleOfThirds
          circularCrop
          onChange={(newCrop: any, percentCrop: any) => setCrop(newCrop)}
          onImageLoaded={(img: any) => onLoad(img)}
          crop={crop}
          onComplete={() => cropCompletion()}
          imageStyle={{ maxHeight: "400px", maxWidth: "400px" }}
          style={{ width: "400px", height: "400px" }}
          src={blob}
        />
        <div className="flex justify-end space-x-4 py-4">
          <Button
            onClick={() => {
              setBlob(null);
              setCropped(null);
            }}
          >
            {tr("Back")}
          </Button>
          <Button disabled={!cropped} primary onClick={() => setBlob(null)}>
            {tr("Save")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        width: "500px",
      }}
      formik={formik}
    >
      <div className="relative self-center">
        <Image
          src={
            (cropped && window.URL.createObjectURL(cropped)) ||
            info?.imageUrl ||
            ""
          }
        />
        {/* <Imageuploader updateBlob={(b: any) => setBlob(b)} /> */}
        <ImageUploadButton
          uploadImages={(e) => {
            setBlob(e.dataUrl);
          }}
          Component={
            <IconButton className="absolute bottom-0 right-0" size="small">
              <RiCamera2Fill />
            </IconButton>
          }
        />
      </div>

      <Row>
        <Input label={tr("First")} required name="first_name" placeholder="John" />
        <Input name="last_name" label={tr("Last")} required placeholder="Doe" />
      </Row>

      <Input
        disabled={info ? true : false}
        name="username"
        label={tr("Username")}
        required
        placeholder={tr("Username")}
      />
      <Input
        name="email"
        label={tr("Email Address")}
        type="email"
        required
        placeholder="abcd@domain.com"
      />
      <Input name="phone" label={tr("Phone Number")} type="phone" placeholder="" />
      <label>{tr("Division")}</label>
      <DivisionDropdown
        formik={formik}
        name="division"
        value={formik.values.division?.toString()}
        onChange={(e: any) =>
          formik.setValues((v) => ({ ...v, division: e.value }))
        }
      />
      {/* <Popper
        reference={({ toggle }) => (
          <Button onClick={toggle}>
            {formik.values.division?.label || "Select a division"}
          </Button>
        )}
        container={(props) => <>{populateDivisions(props)}</>}
      /> */}
      <Input name="job_title_label" label={tr("Job Title")} />
      <div>
        <span>{tr("EServ Account Access")}</span>
        <Switch
          checked={!formik.values.no_account}
          onChange={(e) => {
            formik.setFieldValue("no_account", !e);
          }}
        />
      </div>
      <Button
        isLoading={mutation.isLoading}
        // disabled={!formik.dirty || !formik.isValid}
        primary
        type="submit"
        style={{ alignSelf: "flex-end" }}
      >
        {info ? tr("Save Changes") : tr("Add new User")}
      </Button>
    </Form>
  );
}
