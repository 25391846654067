import * as React from "react";
import ReactDOM from "react-dom";
import { usePopper as useReactPopper } from "react-popper";

import { CSSTransition } from "react-transition-group";
import * as S from "./style";

type ElementProps = {
  container: (popper: any) => React.ReactNode;
  reference: (props: ReferenceProps) => React.ReactElement;
  options?: any;
};

const modifiers = [
  {
    name: "computeStyles",
    options: {
      adaptive: false,
    },
  },
  {
    name: "offset",
    options: {
      offset: [10, 5],
    },
  },
  // {
  //   name: "preventOverflow",
  //   options: {
  //     rootBoundary: 'document'
  //   }
  // }
];

export default function Popper({
  container,
  reference,
  options,
}: ElementProps) {
  const popper = usePopper({
    reference,
    ...options,
  });

  const {
    referenceElement,
    popperElement,
    setPopperElement,
    close,
    popperProps,
    trigger,
    status,
    disable,
  } = popper;

  const { styles, attributes, update } = popperProps;

  React.useEffect(() => {
    update && update();
    const handleClick = (e: any) => {
      if (
        !referenceElement?.contains(e.target) &&
        !popperElement?.contains(e.target)
      ) {
        close();
      }
    };

    if (status && !disable) {
      window.addEventListener("mousedown", handleClick);
      return () => {
        window.removeEventListener("mousedown", handleClick);
      };
    }
  }, [status, update]);

  return (
    <>
      {trigger}
      {ReactDOM.createPortal(
        <S.Con
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <CSSTransition
            in={status}
            timeout={100}
            classNames="alert"
            unmountOnExit
          >
            {options?.noWidth ? (
              <S.PopperNoWidth>{container(popper)}</S.PopperNoWidth>
            ) : (
              <S.Popper>{container(popper)}</S.Popper>
            )}
            {/* <S.Popper>{container(popper)}</S.Popper> */}
          </CSSTransition>
        </S.Con>,
        document.querySelector("#popper")!
      )}
    </>
  );
}

type ReferenceProps = {
  toggle: () => void;
  close: () => void;
  open: () => void;
  popperProps: any;
};
type Props = {
  reference: (props: ReferenceProps) => React.ReactElement;
  disable?: boolean;
  placement?:
    | "bottom-start"
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
};

export const usePopper = ({ placement, reference, disable }: Props) => {
  const [status, setStatus] = React.useState(false);

  const [referenceElement, setReferenceElement] =
    React.useState<Element | null>(null);

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const popperProps = useReactPopper(referenceElement, popperElement, {
    modifiers,
    placement: placement || "bottom-start",
  });

  const close = () => {
    setStatus(false);
  };

  const open = () => {
    setStatus(true);
  };

  const toggle = () => {
    setStatus((s) => !s);
  };

  const trigger = React.cloneElement(
    reference({ toggle, open, close, popperProps }),
    {
      ref: setReferenceElement,
    }
  );

  return {
    status,
    popperProps,
    close,
    open,
    trigger,
    setPopperElement,
    referenceElement,
    popperElement,
    disable,
  };
};
