const formatClientDate = (dateString: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  } catch (error) {
    console.error(error);
    return "Invalid Date";
  }
};

export default formatClientDate;
