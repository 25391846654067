/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { GrFormClose } from 'react-icons/gr' 
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useTranslation } from 'react-i18next';

const focused = keyframes`
    0% { border: 1px solid grey}
    100% { border: 1px solid blue}
`

const opacityAnimate = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`

const unmountAnimate = keyframes`
    0% { opacity: 1 }
    100% { opacity: 0} 
`

const Con = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    text-align: left;
`

const Input = styled.input`
    padding: 0.5rem;
    // margin-top: 0.1rem;
    border: 1px solid grey;
    border-radius: 2px;
    font-size: 1rem;
    -webkit-appearance: none;
    &:focus {
        outline: none;
        animation: ${focused} 0.5s linear forwards;
    }
    ${props => props.error && css`
        border: 1px solid red;
    `}
`

const Select = styled.div`
    padding: 0.5rem;
    border: 1px solid grey;
    position: relative;
    border-radius: 2px;
    &:hover {
        cursor: pointer;
    }
    ${props => props.error && css`
        border: 1px solid red;
    `}
`

const Dropdown = styled(MdKeyboardArrowDown)`
    position: absolute;
    right: 0.1rem;
    font-size: 1.5rem;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
`


const Option = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    box-shadow: 0 0 10px -5px grey;
    min-height: 100%;
    top: 100%;
    width: 100%;
    max-height: 500px;
    background: white;
    z-index: 10;
    overflow: auto;
    transform-origin: top left;
    animation: ${opacityAnimate} 0.1s linear;
    > div {
        padding: 0.5rem;
        // border-bottom: 1px solid #F0F0F0;

        &:hover {
            cursor: pointer;
            background: #F0F0F0;
        }
    }
    ${props => props.unmounted && css`
        animation: ${unmountAnimate} 0.1s linear;
    `}
`

const Close = styled(GrFormClose)`
    position: absolute;
    right: 0;
    top: 83%;
    transform: translateY(-83%);
    // line-height: 200px;
    font-size: 1.5rem;
    &:hover {
        cursor: pointer;
    }
`

// const DateInput = styled.input`
//     padding: 0.5rem;
//     margin-top: 0.1rem;
//     border: 1px solid grey;
//     border-radius: 2px;
//     &:focus {
//         outline: none;
//         animation: ${focused} 0.5s linear forwards;
//     }
// `

const Error = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    font-size: 0.7rem;
`


export const validation = () => {
    
}

export default function (props) {
    const { t } = useTranslation();
    const tBase = "components.searchableDropdown";
    const tr = (key) => t(`${tBase}.${key}`);
    
    const { inputVal, error = false, option, title = '', value, label, type, placeholder } = props
    const nodeSet = useRef(null);
    const [ filter, setFilter ] = useState(inputVal || '')
    const [ show, setShow ] = useState(false)
    const [ selection, setSelection ] = useState('')

    const clicked = (e) => {
        setShow(() => !show)
        setFilter('')
        setSelection(e[label])
        props.changed({value: e[value]})
    }

    const list = option && option
    .filter(e => e[label].toLowerCase().indexOf(filter.toLowerCase()) !== -1 )
    .map(e => <div onClick={() => clicked(e)} key={e[value]}>{e[label]}</div>)

    

    const inputChanged = (e) => {
        !option && props.changed(e)
        setFilter(e.value);
    }

    const inputType = option ?
        <Select error={error} selected={selection} onClick={() => setShow(() => !show)}>{selection ? selection : 'Pick an employee'}<Dropdown/></Select>
    : 
        <Input
        value={filter} 
        type={type}
        name={label}
        error={error}
        placeholder={placeholder}
        onChange={(e) => {inputChanged(e.target)}}>
        </Input>
    ;

    useEffect(() => {
        const handleClick = e => {
            if (!nodeSet.current.contains(e.target)) {
                setShow(() => !show)
                setFilter('')
            }
        }
        
        if (show) {
            document.addEventListener('click', handleClick, false)
            return () => {
                document.removeEventListener('click', handleClick, false)
            }
        }
    }, [show])

    return (
        <Con ref={nodeSet}>
            {title}
            {inputType}
            { filter && !option && <Close onClick={() => { props.changed({name: label, value: ''}); setFilter('')} }/> }
            { error && <Error>*{error}</Error>}
            { show &&
                <Option unmounted={!show}>
                    <Input
                    style={{position: "sticky", top: 0}}
                    value={filter} 
                    autoFocus={true}
                    type={type}
                    placeholder={tr("Search")}
                    onChange={(e) => inputChanged(e.target)}>
                    </Input>
                    {list}
                </Option>
            }
        </Con>
    )
};