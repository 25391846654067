import { useAppointments } from "../../hooks/useAppointments";
import TMapAppointment from "../../types/TMapAppointment";
import Card from "./Card";
import Controls from "./Controls";
import { useTranslation } from 'react-i18next';

const List = () => {
  const { t } = useTranslation();
  const tBase = "features.map";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { data, isLoading, isError } = useAppointments();
  return (
    <>
      <Controls />
      <div className="w-[100%] h-full overflow-y-scroll hide-scrollbar p-4">
        {isLoading && <p>{tr("Loading")}...</p>}
        {isError && <p>{tr("Error")}</p>}
        {data && (
          <>
            {data?.appointments?.map((appointment: TMapAppointment) => (
              <Card appointment={appointment} />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default List;
