export const priceType = [
  {
    value: "install",
    label: "Install",
    value22: "hi",
  },
  {
    value: "sale",
    label: "Sale",
    value22: "bye",
  },
  {
    value: "list",
    label: "List",
    value22: "hihihi",
  },
];
