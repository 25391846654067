import { useContext } from "react";
import { SelectedDivisionContext } from "../context/DivisionContext";

const useSelectedDivision = () => {
	const { selectedDivisionId, setSelectedDivisionId } = useContext(
		SelectedDivisionContext
	);
	return { selectedDivisionId, setSelectedDivisionId };
};

export { useSelectedDivision };
