import axios from "axios";
import { useAreYouSure } from "components/areYouSure";
import {
  Button,
  IconButton,
  SubtleButton,
  SubtleIconButton,
} from "components/button";
import Field from "components/field";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import TableComponent from "components/table";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as React from "react";
import { RiAddLine, RiDeleteBin2Line, RiEdit2Line } from "react-icons/ri";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { enUS, es } from "date-fns/locale";

type Props = {
  id: string;
};

export default function UserAbsence(props: Props) {
  const { t, i18n } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.absence";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const locale = i18n.language === "es" ? es : enUS;

  const modal = useModalContext(),
    queryClient = useQueryClient(),
    snackbar = useSnackbarContext(),
    areYouSure = useAreYouSure();

  const query = useQuery(
    ["absence", props.id],
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/absence/${props.id}
    `
        )
        .then((res) => res.data)
  );

  const deleteM = useMutation(
    async ({ absentId }: { absentId: string }) =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/absence/${absentId}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        modal.setModal();
        queryClient.refetchQueries();
        snackbar.showSnackbar(tr("Absence Deleted"));
      },
    }
  );

  const columns = {
    id: {
      label: "ID",
    },
    start_date: {
      label: tr("Start"),
      width: "25%",
      cell: (row: any) => format(new Date(row.start_date), "MMM dd, yyyy", {
        locale: locale,
      }),
    },
    end_date: {
      label: tr("End"),
      width: "25%",
      cell: (row: any) => format(new Date(row.end_date), "MMM dd, yyyy", {
        locale: locale,
      }),
    },
    days: {
      label: tr("Days"),
    },
    reason: {
      label: tr("Reason"),
      width: "50%",
    },
    edit: {
      label: "",
      cell: (row: any) => (
        <div className="space-x-2">
          <IconButton
            size="small"
            onClick={() =>
              modal.setModal({
                component: <AbsentModal {...row} />,
                label: tr("Absent"),
              })
            }
          >
            <RiEdit2Line />
          </IconButton>
          <SubtleIconButton
            size="small"
            className="!text-red-600"
            onClick={() =>
              areYouSure.activate(() => deleteM.mutate({ absentId: row.id }))
            }
          >
            <RiDeleteBin2Line />
          </SubtleIconButton>
        </div>
      ),
    },
  };

  return (
    <div className="p-2">
      <div className="flex justify-between">
        <h2>{tr("Absences")}</h2>
        <div>
          <Button
            primary
            onClick={() =>
              modal.setModal({
                component: <AbsentModal id={"new"} fk_employee_id={props.id} />,
                label: tr("Absent"),
              })
            }
          >
            <RiAddLine /> {tr("Absence")}
          </Button>
        </div>
      </div>
      <TableComponent columns={columns} data={query.data} />
    </div>
  );
}

type TAbsentModalProps = {
  id?: string;
  start_date?: string;
  end_date?: string;
  reason?: string;
  fk_employee_id?: string;
};

const AbsentModal = (props: TAbsentModalProps) => {
  const { t } = useTranslation();
  const tBase = "views.admin.user.employee-permissions.absence";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const modal = useModalContext(),
    snackbar = useSnackbarContext(),
    areYouSure = useAreYouSure(),
    queryClient = useQueryClient();

  const mutation = useMutation(
    async (e: Omit<TAbsentModalProps, "id" | "fk_employee_id">) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/absence/${props.id}
  `,
          {
            ...e,
            fk_employee_id: props.fk_employee_id,
            start_date: new Date(e.start_date + " 0:00").toISOString(),
            end_date: new Date(e.end_date + " 0:00").toISOString(),
          }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.removeQueries();
        modal.setModal();
        snackbar.showSnackbar(
          `${tr("Absence")} ${props.id === "new" ? tr("Created!") : tr("Edited!")}`
        );
      },
    }
  );

  const deleteM = useMutation(
    async () =>
      await axios
        .delete(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/absence/${props.id}
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        modal.setModal();
        queryClient.refetchQueries();
        snackbar.showSnackbar(tr("Absence Deleted"));
      },
    }
  );

  const onSubmit = (e: Omit<TAbsentModalProps, "id" | "fk_employee_id">) => {
    mutation.mutate(e);
  };

  const initialValues = {
    start_date: props.start_date
      ? format(new Date(props.start_date), "yyyy-MM-dd")
      : "",
    end_date: props.end_date
      ? format(new Date(props.end_date), "yyyy-MM-dd")
      : "",
    reason: props.reason || "",
  };

  const validationSchema = Yup.object({
    start_date: Yup.string().required(),
    end_date: Yup.string().required(),
    reason: Yup.string().required(),
  });

  const formik = useFormik<Omit<TAbsentModalProps, "id" | "fk_employee_id">>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <form className="flex flex-col space-y-4" onSubmit={formik.handleSubmit}>
        <Field
          name="start_date"
          label={tr("Start Date")}
          formik={formik}
          type="date"
          autoFocus={props.id === "new"}
        />
        <Field name="end_date" label="End Date" formik={formik} type="date" />
        <Field
          name="reason"
          label={tr("Reason")}
          formik={formik}
          type="textarea"
          minRows={4}
        />
        <div className="flex justify-end space-x-4">
          {props.id !== "new" && (
            <SubtleButton
              className="!text-red-600"
              onClick={() => areYouSure.activate(() => deleteM.mutate())}
            >
              <RiDeleteBin2Line /> {tr("Delete")}
            </SubtleButton>
          )}

          <Button
            primary
            type="submit"
            isLoading={mutation.isLoading}
            disabled={!formik.isValid || !formik.dirty}
          >
            {props.id === "new" ? tr("Create Absence") : tr("Save Changes")}
          </Button>
        </div>
      </form>
    </div>
  );
};
