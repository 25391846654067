import { useDivisions } from "features/production/hooks/useDivisions";
import { useSelectedDivision } from "features/production/hooks/useSelectedDivision";
import { IBusinessDivision } from "features/production/types/IBusinessDivision";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const tBase = "features.production";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { data: divisionsData, isLoading, error } = useDivisions();
  const { selectedDivisionId } = useSelectedDivision();

  let title;

  if (isLoading) {
    title = "Loading...";
  } else if (error) {
    title = (error as { message: string })?.message;
  } else if (divisionsData) {
    const selectedDivision = divisionsData.data.find(
      (division: IBusinessDivision) => division.id === selectedDivisionId
    );

    title = selectedDivision?.label;
  }

  return (
    <div className="flex items-center justify-between p-4">
      <h1 className="text-highlight">{tr("Production")}</h1>
      <h2 className="mx-auto text-highlight">{title}</h2>
    </div>
  );
};

export { Header };
