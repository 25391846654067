import React, { useState } from "react";
import { ITags } from "./interfaces/ITags";
import { Tag } from "./Tag";
import {IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useTranslation } from 'react-i18next';

type Props = {
  tags: ITags[];
};

const TagList = ({ tags }: Props) => {
  const [showArchived, setShowArchived] = useState(false);
  const { t } = useTranslation();
  const tBase = "views.admin.tags.components.TagList";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const activeTags = tags.filter(
    (tag) => tag.active
  );

  const archivedTags = tags.filter(
    (tag) => !tag.active
  );

  return (
    <div>
      {/* Appointment Types */}
      <div>
        {activeTags?.map((tag: ITags) => (
          <Tag
            key={tag.id}
            tag={tag}
          />
        ))}
      </div>
      {/* Archived */}
      <div className="pt-3">
        <div className="flex items-center space-x-2">
          <h1>{tr("Archived")}</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className="flex items-center"
          >
            {showArchived 
              ? <IoIosArrowDown size={20} className="hover:text-primary" /> 
              : <IoIosArrowUp size={20} className="hover:text-primary" />
            }
          </button>
        </div>
        <div>
          {!showArchived && archivedTags?.map((tag: ITags) => (
            <Tag
              key={tag.id}
              tag={tag}
              archived={true}
            />
          ))}
        </div>
      </div>
      <div className="h-[100px]"></div>
    </div>
  );
};

export { TagList };
