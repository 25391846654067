import axios from "axios";
import { Button } from "components/button";
import { useSnackbarContext } from "components/snackbar";
import TableComponent from "components/table";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from 'react-i18next';

export default function FailedList() {
  const { t } = useTranslation();
  const tBase = "views.admin.quickbooks.failedList";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const snackbar = useSnackbarContext();

  const query = useQuery(
    "qb failed sync",
    async () =>
      await axios
        .get(
          `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/quickbooks/failed
    `
        )
        .then((res) => res.data)
  );

  const clearSync = useMutation(
    async () =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/quickbooks/clear
  `
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        snackbar.showSnackbar("QB Sync cleared!");
        query.refetch();
      },
    }
  );

  const columns = {
    type: {
      label: tr("Type"),
    },
    id: {
      label: "Id #",
    },
    failed_msg: {
      label: tr("Failed Message"),
      width: "100%",
    },
  };

  return (
    <div>
      <div className="flex justify-between py-2">
        <h1>{tr("Failed Sync")}</h1>
        <Button
          primary
          isLoading={clearSync.isLoading}
          onClick={() => clearSync.mutate()}
        >
          {tr("Clear")}
        </Button>
      </div>
      <TableComponent columns={columns} data={query.data} />
    </div>
  );
}
