import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatTime = (inputTime) => {
  try {
    console.log("Input Time: " + inputTime);
    console.log("Client Time Zone: " + clientTz);

    // Match the expected input format "HH:MM:SS+ZZ"
    const match = inputTime.match(/(\d{2}:\d{2}):\d{2}([+-]\d{2})/);
    if (!match) throw new Error("Invalid time format");

    const timePart = match[1];
    const offsetPart = match[2];

    // Get the current date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Construct a UTC date string and parse it directly
    const utcDateString = `${year}-${month}-${day}T${timePart}:00${offsetPart}:00`;
    console.log("UTC Date String: " + utcDateString);

    const utcDate = new Date(utcDateString);
    console.log("UTC Date: " + utcDate.toISOString());

    // Convert UTC date to the specified client time zone
    const zonedDate = utcToZonedTime(utcDate, clientTz);
    console.log("Zoned Date: " + zonedDate);

    // Format the date in the client's local time zone
    const formattedTime = format(zonedDate, "hh:mm a", { timeZone: clientTz });
    console.log("Formatted Time: " + formattedTime);

    return formattedTime;
  } catch (error) {
    console.error("Error in formatTime:", error);
    return inputTime; // Fallback to original input time in case of an error
  }
};
