import axios from "api/axios";
import { Button, OutlineButton } from "components/button";
import GroupedButton from "components/button/grouped";
import { useModalContext } from "components/modal";
import { SContent } from "components/segment";
import { useSnackbarContext } from "components/snackbar";
import Spinner from "components/spinner";
import Parts from "containers/parts";
import { useFormik } from "formik";
import { cloneDeep } from "lodash";
import Switch from "rc-switch";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { TItemsContainer } from "types/items-container";
import { TTargetProduct } from "types/product";
import { TTargetType } from "types/target-type";
import EditLayout from "./editLayout";
import ReadLayout from "./readLayout";
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  parentId?: string;
  parentType?: "project" | "request";
  type: TTargetType;
  style?: {};
  triggerRef?: any;
  onClick?: (p: {
    products?: TTargetProduct[] | undefined;
    price_type: "sale" | "install";
  }) => void;
  view?: "read" | "edit";
  formik?: any;
  getChild?: boolean;
  readOnly?: boolean;
  title?: string;
};

export default function LineItemsContainer(props: Props) {
  const { t } = useTranslation();
  const tBase = "containers.lineItems.index";
  const tr = (key: string) => t(`${tBase}.${key}`);
  const [view, setView] = React.useState("read");
  const { showSnackbar } = useSnackbarContext(),
    modal = useModalContext(),
    queryClient = useQueryClient();

  React.useEffect(() => {
    if (props.view) setView(props.view);
  }, [props.triggerRef, props.view]);

  const query = useQuery<TItemsContainer>(
    ["products", props.id, props.type],
    async () =>
      props.id !== "new"
        ? await axios
            .get(
              `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/${props.type}/${props.id}?get_child=${props.getChild}&parent_type=${props.parentType}
    `
            )
            .then((res) => res.data)
        : [],
    {
      refetchOnWindowFocus: view === "read",
    }
  );

  const mutation = useMutation(
    async (e: { products?: TTargetProduct[] }) =>
      await axios
        .post(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/company/products/${props.type}/${props.id}
  `,
          { ...e, parentId: props.parentId, parentType: props.parentType }
        )
        .then((res) => res.data),
    {
      onSuccess: () => {
        showSnackbar(tr("Products Updated!"));
        setView("read");
        queryClient.invalidateQueries();
      },
    }
  );

  const handleSubmit = (e: {
    products?: TTargetProduct[] | undefined;
    price_type: "sale" | "install";
  }) => {
    let temp = cloneDeep(e);

    if (temp.products) {
      let products = temp.products;

      products = products.map((product) => ({
        ...product,
        unit_price:
          typeof product.unit_price === "number"
            ? product.unit_price * 100
            : null,
        list_price:
          typeof product.list_price === "number"
            ? product.list_price * 100
            : null,
        install_price:
          typeof product.install_price === "number"
            ? product.install_price * 100
            : null,
      }));
      temp.products = products;
    }
    mutation.mutate(temp);
  };

  const containerFormik = useFormik<{
    products?: TTargetProduct[];
    price_type: "sale" | "install";
    hide_empty: boolean;
  }>({
    initialValues: {
      products:
        query.data?.products?.map((product) => ({
          ...product,
          unit_price:
            typeof product.unit_price === "number"
              ? product.unit_price / 100
              : "",
          list_price:
            typeof product.list_price === "number"
              ? product.list_price / 100
              : "",
          install_price:
            typeof product.install_price === "number"
              ? product.install_price / 100
              : "",
        })) || [],
      price_type: query.data?.price_type || "install",
      hide_empty: query.data?.hide_empty || false,
    },
    onSubmit: (e) => {
      if (props.onClick) {
        setView("read");
        queryClient.setQueryData(["products", props.id, props.type], e);
        props.onClick(e);
      } else {
        handleSubmit(e);
      }
    },
    enableReinitialize: true,
  });

  const formik = props.formik || containerFormik;

  if (query.isLoading) return <Spinner />;

  const handleClick = () => {
    if (view === "read") {
      setView("edit");
    } else {
      formik.handleSubmit();
    }
  };

  const handleCostChange = (type: "sale" | "install") => {
    formik.setValues((v: any) => ({ ...v, price_type: type }));
  };

  return (
    <div style={props.style} className="space-y-4">
      <div className="flex items-center justify-between border-b py-4">
        <h2>{props.title || tr("Line Items")}</h2>
        {!props.readOnly && (
          <div>
            <OutlineButton
              type="button"
              onClick={handleClick}
              isLoading={mutation.isLoading}
              primary
            >
              {view === "read" ? tr("Edit") : tr("Save Changes")}
            </OutlineButton>
            {view !== "read" && (
              <Button
                onClick={() => setView("read")}
                style={{ marginLeft: "0.5rem" }}
              >
                {tr("Cancel")}
              </Button>
            )}
          </div>
        )}
      </div>
      {view === "read" ? (
        <ReadLayout data={query.data} id={props.id} type={props.type} />
      ) : (
        <DynamicForm>
          <Prompt
            when={formik.dirty}
            message={tr("Changes have not been saved. Are you sure you want to leave?")}
          />
          <div className="self-end space-x-2">
            <label className="text-slate-600">{tr("Hide Empty")}</label>
            <Switch
              checked={formik.values.hide_empty}
              onChange={() =>
                formik.setValues((v: any) => ({
                  ...v,
                  hide_empty: !v.hide_empty,
                }))
              }
            />
          </div>

          <GroupedButton style={{ alignSelf: "flex-end", margin: "0.5rem 0" }}>
            {/* <Button
              onClick={() => handleCostChange("list")}
              $selected={formik.values.price_type === "list"}
            >
              List
            </Button> */}
            <Button
              onClick={() => handleCostChange("sale")}
              $selected={formik.values.price_type === "sale"}
            >
              {tr("Sale")}
            </Button>
            <Button
              onClick={() => handleCostChange("install")}
              $selected={formik.values.price_type === "install"}
            >
              {tr("Install")}
            </Button>
          </GroupedButton>
          <EditLayout
            data={query.data}
            formik={formik}
            id={props.id}
            type={props.type}
          />
        </DynamicForm>
      )}
    </div>
  );
}

const DynamicForm = ({
  children,
  isForm,
}: {
  isForm?: boolean;
  children: React.ReactNode;
}) => {
  if (isForm)
    return (
      <form style={{ display: "flex", flexDirection: "column" }}>
        {children}
      </form>
    );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
  );
};
const Con = styled(SContent)``;
