import * as React from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDListElement>,
  HTMLDListElement
> & {
  title: React.ReactNode;
  classNames?: {
    title?: string;
    children?: string;
  };
};
export default function DataLabel(props: Props) {
  const { title, className, classNames, ...rest } = props;
  return (
    <dl className={`${className}`} {...rest}>
      <dt
        className={`mb-1 font-medium text-slate-500 text-sm m-0 p-0 ${classNames?.title} `}
      >
        {props.title}
      </dt>
      <dd className={` ${classNames?.children}`}>{props.children}</dd>
    </dl>
  );
}
