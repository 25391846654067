import { cloneDeep } from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { Avatar } from "components/avatar";
import { Button } from "components/button";
import { updatedDiff } from "deep-object-diff";
import { useSnackbarContext } from "components/snackbar";
import { useTranslation } from 'react-i18next';

const people = [
  {
    name: "John Doe",

    category: [
      {
        label: "cat1",
        status: true,
      },
      {
        label: "cat2",
        status: true,
      },
      {
        label: "cat3",
        status: true,
      },
    ],
  },
];

type Category = {
  id: number;
  label: string;
  status: boolean;
};

type Data = {
  name: string;
  user_id: string;
  category: Category[];
}[];

export default function Notifications() {
  const [data, setData] = React.useState<Data | null>(null);
  const [updated, setUpdated] = React.useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const tBase = "views.admin.notifications.index";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { showSnackbar } = useSnackbarContext();

  const fetchData = useQuery(
    "requestEmailList",
    async () =>
      await axios
        .get(
          `
  ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/email
  `
        )
        .then((res) => res.data)
  );

  const updateData = useMutation(
    async (body) =>
      await axios.post(
        `
        ${process.env.REACT_APP_SERVER_URL}/api/v1/eserv-business/admin/email
        `,
        body
      ),
    {
      onSuccess: () => {
        setUpdated(false);
        queryClient.invalidateQueries("requestEmailList");
        showSnackbar(tr("Email updated!"));
      },
    }
  );

  React.useEffect(() => {
    if (fetchData.isSuccess) {
      setData(fetchData.data);
    }
  }, [fetchData.isSuccess]);

  const handleChange = (e: any, personId: string) => {
    const { name, checked } = e.target;
    setData((data) => {
      let tempData = cloneDeep(data);
      let index = data
        ? data.findIndex(({ user_id }) => user_id === personId)
        : -1;

      let categoryIndex =
        data && index !== -1
          ? data[index].category.findIndex((cat) => cat.id === +name)
          : -1;

      if (tempData && categoryIndex !== -1 && index !== -1) {
        tempData[index]["category"][categoryIndex].status = checked;
      }

      return tempData;
    });
    setUpdated(true);
  };

  const handleSubmit = () => {
    if (data) {
      let changes = updatedDiff(fetchData.data, data);
      let updates = Object.entries(changes).reduce<any>(
        (prev, [key, value]) => {
          let temp = cloneDeep(prev);
          Object.entries(value.category).forEach(
            ([keys, values]: [string, any]) => {
              if (values) {
                let change = {
                  user: data[+key].user_id,
                  category: data[+key].category[+keys].id,
                };
                if (values.status) {
                  temp["added"]["user"].push(change.user);
                  temp["added"]["category"].push(change.category);
                } else {
                  temp["deleted"]["user"].push(change.user);
                  temp["deleted"]["category"].push(change.category);
                }
              }
            }
          );
          return temp;
        },
        {
          deleted: { user: [], category: [] },
          added: { user: [], category: [] },
        }
      );

      updateData.mutate(updates);
    }
  };

  const populateTable = data?.map((person) => (
    <div key={person.user_id}>
      <Avatar user_id={person.user_id} showLabel>{person.name} </Avatar>
      {person.category?.map((cat) => (
        <div key={cat.id}>
          <Checkbox
            name={cat.id + ""}
            type="checkbox"
            checked={cat.status}
            onChange={(e) => handleChange(e, person.user_id)}
          />
        </div>
      ))}
    </div>
  ));

  const categoryCounts = (data && data[0]?.category?.length) || 0;

  return (
    <Con>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>{tr("Notifications")} </h1>
        <span style={{ color: "grey", marginLeft: "1rem" }}>
          {tr("Set who to receive notifications")}
        </span>
        <Button
          style={{ marginLeft: "auto" }}
          onClick={handleSubmit}
          size="large"
          primary
          disabled={!updated}
        >
          {tr("Save Changes")}
        </Button>
      </div>

      <Table numberOfCols={categoryCounts}>
        <div>
          <div>{tr("Name")}</div>
          {data &&
            data[0]?.category?.map((cat) => (
              <div key={cat.id}>{cat.label}</div>
            ))}
        </div>
        {populateTable}
      </Table>
    </Con>
  );
}

const Con = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Checkbox = styled.input`
  width: 15px;
  height: 15px;
`;

const Table = styled.div<{ numberOfCols: number }>`
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  overflow: auto;
  > div {
    background: white;
    display: grid;
    grid-template-columns: 1fr repeat(${(props) => props.numberOfCols}, 1fr);

    align-items: center;
    justify-items: center;
    > div {
      padding: 0.5rem;
    }
    > div:first-child {
      flex: 1;
      border-right: 1px solid #c3c3c3;
      min-width: 300px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #c3c3c3;
    }
    &:first-child {
      position: sticky;
      top: 30;
      border-bottom: 1px solid black;
    }
  }
`;
