import * as React from "react";
import { SCategory } from "../style";
import type { QuoteCategory } from "../index";
import Item from "./item";
import { Button, SubtleButton, SubtleIconButton } from "components/button";
import AddItem from "./addItem";
import { useModalContext } from "components/modal";
import {
  RiArrowDownLine,
  RiArrowDownSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import Select from "components/selectv2";
import Popper, { usePopper } from "components/react-popperv2";
import { Input } from "components/input";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';

type Props = {
  catId: string;
  category: QuoteCategory;
  handleChange: (e: any) => void;
  id: string;
  expandAll: number;
  setExpandAll: (e: 3) => void;
  editable: boolean;
};

export default function Category({
  expandAll,
  id,
  catId,
  category,
  handleChange,
  setExpandAll,
  editable,
}: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.category";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();

  const [isHidden, setIsHidden] = React.useState(false);

  const [inputVal, setVal] = React.useState("");

  React.useEffect(() => {
    if (expandAll === 1) {
      setIsHidden(false);
    } else if (expandAll === 2) {
      setIsHidden(true);
    }
  }, [expandAll]);

  let hasItem = false;

  const populateItem = Object.entries(category.items).map(
    ([itemId, item], i) => {
      if (!editable && !item.quantity) return null;

      hasItem = true;

      return (
        <Item
          key={itemId}
          editable={editable}
          data={item}
          categoryId={catId}
          {...{ isHidden, type: category.type }}
          onChange={(e) =>
            handleChange({
              ...e,
              projectId: id,
              catId,
              id: itemId,
            })
          }
        />
      );
    }
  );

  const selectData: any = [];
  Object.entries(category.items)
    .filter(([itemId, item]) =>
      item.label.toLowerCase().includes(inputVal.toLowerCase())
    )
    .forEach(([itemId, item]) => {
      if (!item.quantity) selectData.push({ itemId, ...item });
    });

  if (!hasItem && !editable) return null;

  return (
    <SCategory>
      <div>
        {editable && category.type === "list" && (
          <SubtleIconButton
            onClick={() => {
              setExpandAll(3);
              setIsHidden((s) => !s);
            }}
          >
            {isHidden ? <RiArrowRightSLine /> : <RiArrowDownSLine />}
          </SubtleIconButton>
        )}

        <h3>{category.label}</h3>
        {editable && (
          <>
            {category.type === "dropdown" && (
              <Popper
                reference={({ open, popperProps }) => (
                  // <Button onClick={toggle}>ok</Button>
                  <Input
                    value={inputVal}
                    onChange={(e: any) => {
                      setVal(e.target.value);
                      popperProps.update();
                    }}
                    onFocus={(e) => {
                      e.target.select();
                      open();
                    }}
                    placeholder={tr("Add Item")}
                    // onBlur={toggle}
                    style={{ marginLeft: "1rem", width: "300px" }}
                  />
                )}
                // options={{ disable: true }}
                container={(props: any) => (
                  <Select
                    onClick={(e) => {
                      handleChange({
                        projectId: id,
                        type: "quantity",
                        catId,
                        id: e.itemId,
                        value: 1,
                      });
                      setVal("");
                      props.close();
                    }}
                    value={inputVal}
                    data={selectData}
                    rowFormat={(row) => <div>{row.label}</div>}
                  />
                )}
              />
            )}

            <SubtleButton
              onClick={() =>
                setModal({
                  component: <AddItem categoryId={catId} />,
                  label: tr("Add Item"),
                })
              }
            >
              {tr("Add Item")}
            </SubtleButton>
          </>
        )}
      </div>
      <div>{populateItem}</div>
    </SCategory>
  );
}
