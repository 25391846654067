import { usePopper } from "react-popper";
import * as React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { OutlineButton, SubtleIconButton } from "components/button";
import { RiCloseFill } from "react-icons/ri";
import type { Placement } from "@popperjs/core";

type Props = {
  refs: Element | null;
  index: number;
  length: number;
  content: React.ReactNode;
  next: () => void;
  close: () => void;
  placement?: Placement;
};
export default function Tooltip({
  refs: ref,
  content,
  index,
  length,
  placement,
  close,
  next,
}: Props) {
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = React.useState<HTMLElement | null>(
    null
  );

  const { styles, attributes } = usePopper(ref, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "arrow",
        options: {
          element: arrowElement,
        },
      },
    ],
  });

  return (
    <SToolTip
      style={{ ...styles.popper, background: "green" }}
      ref={setPopperElement}
      {...attributes.popper}
    >
      <div ref={setArrowElement} style={styles.arrow} id="arrow" />
      <SCon>
        <div>
          {content}
          <SubtleIconButton
            onClick={close}
            size="small"
            style={{ color: "white" }}
          >
            <RiCloseFill />
          </SubtleIconButton>
        </div>
        <div>
          <span style={{ color: "#f0f0f0" }}>
            {`${index + 1} / ${length}`}{" "}
          </span>
          <OutlineButton
            size="small"
            style={{ borderColor: "white", color: "white" }}
            onClick={() => next()}
          >
            {length - 1 === index ? "Done" : "Next"}
          </OutlineButton>
        </div>
      </SCon>
    </SToolTip>
  );
}

const SToolTip = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  z-index: ${theme.zIndex.tour};

  #arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    &:after {
      content: " ";
      background-color: ${theme.color.main};
      position: absolute;
      top: 0; // padding + popper height
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^="top"] > #arrow {
    bottom: -5px;
  }
  &[data-popper-placement^="bottom"] > #arrow {
    top: -5px;
  }
  &[data-popper-placement^="right"] > #arrow {
    left: -5px;
  }
`;

const SCon = styled.div`
  background: ${theme.color.main};
  color: white;
  padding: 0.5rem;
  width: 300px;
  border-radius: 5px;
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  > div:first-child {
    justify-content: space-between;
    display: flex;
  }
`;

const SArrow = styled.div``;
