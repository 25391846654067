/* Created by Hannah Green for ES-171 on 6/30/2021 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, OutlineIconButton as IconButton } from "components/button";
import Axios from "axios";

import Select from "components/react-select";
import { GrAdd } from "react-icons/gr";
import { useSnackbarContext } from "components/snackbar";
import { useQuery } from "react-query";
import { dollarFormatter } from "utils";
import EditMode from "./editMode";
import { cloneDeep } from "lodash";
import { useTranslation } from 'react-i18next';

const Con = styled.div`
  display: flex;
  font-size: 0.9rem;
  flex-direction: column;
`;

const LineItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.8rem 0 0.8rem 0;
  font-weight: 600;
  > div {
    text-align: left;
    display: grid;
    border-bottom: 1px solid #f0f0f0;
    padding: 0.5em 0;
    align-items: center;
    grid-template-columns: ${(props) =>
      props.mode === "view"
        ? "2fr 2fr 1fr 1fr 1fr 1fr"
        : "0.3fr 1.5fr 1.5fr 0.6fr 0.6fr 0.6fr 0.6fr 0.6fr"};

    &:first-child {
      font-weight: 800;
      font-size: 0.9rem;
      color: grey;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    /* > div:last-child {
      text-align: right;
    } */
  }
`;

export default function RateTable(props) {
  const { t } = useTranslation();
  const tBase = "views.services.components.rates.rate-table";
  const tr = (key) => t(`${tBase}.${key}`);

  const [mode, setMode] = useState("view");
  const rates = props.rates;
  const setRates = props.setRates;
  const formType = props.formType;
  const id = props.id;

  const { showSnackbar } = useSnackbarContext();

  const getRates = useQuery(
    "getRates",
    async () =>
      await Axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/getRatesByServiceId?service_id=${id}`
      ).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (res) {
          setRates(res);
        }
      },
    }
  );

  const submitData = () => {
    Axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/updateRates`,
      rates,
      {
        onSuccess: () => {
          showSnackbar(tr("Rates Updated!"));
          getRates.refetch();
        },
      }
    );
  };

  const handleSubmit = () => {
    submitData();
    setMode("view");
  };

  const buttonState =
    mode === "view" ? (
      <Button onClick={() => setMode("edit")}>Edit</Button>
    ) : (
      <Button
        primary
        onClick={() => {
          if (formType === "add") {
            setMode("view");
          } else {
            handleSubmit();
          }
        }}
      >
        Save
      </Button>
    );

  const populateContainer = () => {
    if (mode !== "view") {
      return <Editable rates={rates} setRates={setRates} id={id} />;
    } else {
      return <ReadOnly rates={rates} />;
    }
  };

  return (
    <>
      <div>{buttonState}</div>
      <Con>{populateContainer()}</Con>
    </>
  );
}

export const Editable = (props) => {
  const { t } = useTranslation();
  const tBase = "views.services.components.rates.rate-table";
  const tr = (key) => t(`${tBase}.${key}`);

  const rates = props.rates;
  const setRates = props.setRates;
  const id = props.id;
  const [options, setOptions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/company/services/allRates`
        );
        setOptions(res.data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const selectOptions = options?.map((o) => {
    return { label: o.rate_name, value: o.rate_id };
  });

  const populateRates =
    rates &&
    rates.length > 0 &&
    rates.map((r, i) => {
      if (r.deleted) {
        return null;
      }
      return <EditMode key={i} rate={r} i={i} setRates={setRates} />;
    });

  const handleAddRate = ({ value, label }) => {
    let newRate = options.find((r) => {
      return r.rate_id === value;
    });

    setRates((state) => {
      const tempRates = cloneDeep(state);
      newRate.service_id = id;
      newRate.deleted = false;
      newRate.new_product = false;
      newRate.add = true;
      tempRates.push(newRate);
      return tempRates;
    });
  };

  const selectInput = (
    <div>
      <div>
        <IconButton
          size="small"
          square
          onClick={() => {
            setRates((state) => {
              const tempRates = cloneDeep(state);
              tempRates.push({
                rate_name: "",
                rate_desc: "",
                qty_hrs: 0,
                reg_rate: 0,
                internal_cost: 0,
                member_rate: 0,
                service_id: id,
                new_product: true,
                deleted: false,
                add: false,
              });
              return tempRates;
            });
          }}
        >
          <GrAdd />
        </IconButton>
      </div>
      <div>
        <Select
          style={{ width: "85%" }}
          options={selectOptions}
          value={false}
          onChange={handleAddRate}
        />
      </div>
      <div />
      <div />
      <div />
    </div>
  );

  return (
    <>
      <LineItem mode="editable">
        <div>
          <div></div>
          <div>{tr("NAME")}</div>
          <div>{tr("DESCRIPTION")}</div>
          <div>QTY/HRS</div>
          <div>{tr("REG RATE")}</div>
          <div>{tr("INTERNAL COST")}</div>
          <div>{tr("MEMBER RATE")}</div>
        </div>
        {populateRates}
        {selectInput}
      </LineItem>
    </>
  );
};

export const ReadOnly = (props) => {
  const { t } = useTranslation();
  const tBase = "views.services.components.rates.rate-table";
  const tr = (key) => t(`${tBase}.${key}`);

  const rates = props.rates;
  const populateRates =
    rates &&
    rates.length > 0 &&
    rates.map((r, i) => {
      if (r.deleted) {
        return null;
      }
      return (
        <div key={i}>
          <div>{r.rate_name}</div>
          <div>{r.rate_desc}</div>
          <div>{r.qty_hrs}</div>
          <div>{dollarFormatter(r.reg_rate)}</div>
          <div>{dollarFormatter(r.internal_cost)}</div>
          <div>{dollarFormatter(r.member_rate)}</div>
        </div>
      );
    });

  return (
    <>
      <LineItem mode="view">
        <div>
          <div>{tr("NAME")}</div>
          <div>{tr("DESCRIPTION")}</div>
          <div>QTY/HRS</div>
          <div>{tr("REG RATE")}</div>
          <div>{tr("INTERNAL COST")}</div>
          <div>{tr("MEMBER RATE")}</div>
        </div>
        {populateRates}
      </LineItem>
    </>
  );
};
