import axios from "axios";
import { useFormik } from "formik";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import Form from "components/form2";
import { Input } from "components/input";
import { Button } from "components/button";
import { useRifm } from "rifm";
import currency from "utils/currency";
import { useModalContext } from "components/modal";
import { useSnackbarContext } from "components/snackbar";
import * as Yup from "yup";
import { QuoteItem } from "..";
import NumberFormat from "react-number-format";
import { useTranslation } from 'react-i18next';

type Props = {
  categoryId: string;
  id?: number;
  data?: QuoteItem;
};
export default function AddItem(props: Props) {
  const { t } = useTranslation();
  const tBase = "views.projects.views.project.views.quote.components.addItem";
  const tr = (key: string) => t(`${tBase}.${key}`);

  const { setModal } = useModalContext();
  const { showSnackbar } = useSnackbarContext();

  const queryClient = useQueryClient();

  const addItem = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/item/${props.categoryId}/${props.id}
    `,
        body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote"]);
        setModal();
        showSnackbar(tr("Item created!"));
      },
    }
  );

  const deleteItem = useMutation(
    async () =>
      await axios.delete(
        `
    ${process.env.REACT_APP_SERVER_URL}/api/v1/company/quotes/item/${props.id}
    `
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote"]);
        setModal();
        showSnackbar(tr("Item deleted!"));
      },
    }
  );

  const initialValues = {
    label: props.data?.label || "",
    list_price: props.data ? props.data.list_price / 100 : "",
    sale_price: props.data ? props.data?.sale_price / 100 : "",
  };

  const validationSchema = Yup.object({
    label: Yup.string().required(),
    list_price: Yup.number().required(),
    sale_price: Yup.number().required(),
  });

  const onSubmit = (e: any) => {
    // addItem.mutate({
    //   ...e,
    //   list_price: currency.parse(e.list_price),
    //   sale_price: currency.parse(e.sale_price),
    // });

    addItem.mutate({
      ...e,
      list_price: e.list_price * 100,
      sale_price: e.sale_price * 100,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema,
  });

  // const list_price = useRifm({
  //   value: formik.values.list_price,
  //   onChange: (e) => formik.setFieldValue("list_price", e),
  //   format: (e) => currency.format(e),
  //   accept: /[\d\.]/g,
  // });

  // const sale_price = useRifm({
  //   value: formik.values.sale_price,
  //   onChange: (e) => formik.setFieldValue("sale_price", e),
  //   format: (e) => currency.format(e),
  //   accept: /[\d\.]/g,
  // });

  return (
    <Con>
      <Form formik={formik}>
        <Input name="label" label={tr("Label")} />
        <NumberFormat
          name="list_price"
          fixedDecimalScale
          decimalScale={2}
          allowNegative={false}
          label={tr("List Price")}
          customInput={Input}
          prefix={"$"}
          onValueChange={(e) =>
            formik.setValues((v) => ({ ...v, list_price: e.floatValue || "" }))
          }
          value={formik.values.list_price}
        />
        <NumberFormat
          name="sale_price"
          fixedDecimalScale
          decimalScale={2}
          allowNegative={false}
          label={tr("Sale Price")}
          customInput={Input}
          prefix={"$"}
          onValueChange={(e) =>
            formik.setValues((v) => ({ ...v, sale_price: e.floatValue || "" }))
          }
          value={formik.values.sale_price}
        />
        {/* <Input
          name="list_price"
          label="List Price"
          value={list_price.value}
          onChange={list_price.onChange}
        /> */}
        {/* <Input
          name="sale_price"
          label="Sale Price"
          value={sale_price.value}
          onChange={sale_price.onChange}
        /> */}
        {props.id && (
          <Button
            isLoading={deleteItem.isLoading}
            onClick={() => deleteItem.mutate()}
            type="button"
            style={{ background: "red", color: "white", marginRight: "1rem" }}
          >
            {tr("Delete Item")}
          </Button>
        )}
        <Button
          isLoading={addItem.isLoading}
          type="submit"
          disabled={!formik.isValid}
        >
          {props.id ? tr("Update Item") : tr("Add Item")}
        </Button>
      </Form>
    </Con>
  );
}

const Con = styled.div`
  > form {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;
