import { FC, createContext, useEffect, useState } from "react";

import { ILocation } from "../types/ILocation";
import { ILocationContext } from "../types/ILocationContext";

export const LocationContext = createContext<ILocationContext>({
  location: null,
  setLocation: () => {},
  maxDistance: null,
  setMaxDistance: () => {},
});

export const LocationProvider: FC = ({ children }) => {
  const [location, setLocation] = useState<ILocation | null>(null);
  const [maxDistance, setMaxDistance] = useState<number | null>(null);

  return (
    <LocationContext.Provider
      value={{
        location,
        setLocation,
        maxDistance,
        setMaxDistance,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
